import React, {useCallback} from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {responsive} from '../../../../core/style'
import Button from '../../../share/Button'
import {removeFromFundsCompare} from '../../../../ducks/fund'
import Tooltip from '../../../share/Tooltip'

const Container = styled.div`
  position: sticky;
  display: ${props => (props.isShow ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: #e5e5e5;
  height: 100px;
  bottom: 0;
  z-index: 5;
  ${props =>
    responsive.mobile(css`
      justify-content: space-between;
      padding: 0 30px;
    `)};
`
const CustomButton = styled(Button)`
  font-size: 12px;
  height: 40px;
  margin: 0 10px;
`
const MobileCompare = styled.div`
  display: none;
  text-decoration: underline;
  font-size: 12px;
  color: black;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`
const FundSlotCard = styled.div`
  width: 140px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  opacity: 0.5;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const FundCompareWrapper = styled(FundSlotCard)`
  position: relative;
  display: flex;
  opacity: 1;
  padding: 10px;
`
const RemoveFundIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: -10px;
  font-size: 25px;
  cursor: pointer;
  color: #7f7f7f;
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;
`
const FundLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`
const FundLogo = styled.img`
  width: 20px;
  height: 20px;
`
const SymbolName = styled.p`
  margin: 0;
  font-size: 12px;
  max-width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const FundDetailWrapper = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Nav = styled.p`
  margin: 0;
  font-size: 12px;
  max-width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FundCompareCard = props => {
  const {removeFromFundsCompare, refId, symbol, fundCompany, latestNav} = props
  const fundCompanyThumbLogo =
    fundCompany && fundCompany.logoThumb ? fundCompany.logoThumb : ''
  const nav = latestNav || 'N/A'
  return (
    <FundCompareWrapper>
      <RemoveFundIcon
        icon={['fas', 'times-circle']}
        onClick={() => removeFromFundsCompare(refId)}
      />
      <FundLogoWrapper>
        <FundLogo src={fundCompanyThumbLogo} />
      </FundLogoWrapper>
      <FundDetailWrapper>
        <Tooltip placement="right" title={symbol}>
          <SymbolName>{symbol}</SymbolName>
        </Tooltip>
        <Tooltip placement="right" title={`${nav} บาท/หน่วย`}>
          <Nav>{nav} บาท/หน่วย</Nav>
        </Tooltip>
      </FundDetailWrapper>
    </FundCompareWrapper>
  )
}

export default connect(
  state => {
    const fundsCompare = state.fund.fundsCompare
    return {fundsCompare}
  },
  {removeFromFundsCompare},
)(props => {
  const defaultSlots = [{}, {}, {}, {}, {}]
  const {fundsCompare, removeFromFundsCompare} = props

  const handleCompareClick = useCallback(() => {
    window.open(
      `/fund/compare?` +
        fundsCompare.map((fund, index) => `refIds=${fund.refId}`).join('&'),
      '_blank',
    )
  }, [fundsCompare])

  return (
    <Container isShow={fundsCompare.length !== 0}>
      <MobileCompare onClick={handleCompareClick}>
        ดูรายการเปรียบเทียบ
      </MobileCompare>
      {defaultSlots.map((slot, index) => {
        return fundsCompare[index] ? (
          <FundCompareCard
            {...fundsCompare[index]}
            removeFromFundsCompare={removeFromFundsCompare}
          />
        ) : (
          <FundSlotCard key={index} />
        )
      })}
      <CustomButton
        disabled={fundsCompare.length === 1}
        onClick={handleCompareClick}
        icons={['fa', 'search']}>
        เปรียบเทียบ
      </CustomButton>
    </Container>
  )
})
