import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {reduxForm, FieldArray} from 'redux-form'
import {compose, withState, lifecycle} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import avatar02 from '../../static/images/avatar-02.svg'
import Button from '../share/Button'
import Field from '../share/Field'
import Loading from '../share/Loading'
import {sendLeadAgain, getLead} from '../../ducks/profile'
import {displayNumber, isBlank} from '../../core/helper'
import {responsive} from '../../core/style'

const Container = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin: 20px 0 30px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const HeaderSub = styled.span`
  font-size: 16px;
  font-weight: ${props => props.selectedSubHeader && 'bold'};
  cursor: ${props => !props.selectedSubHeader && 'pointer'};
  margin: 0 5px;
`
const Or = styled.span`
  font-size: 16px;
  margin: 0 10px;

  ${props =>
    responsive.mobile(css`
      margin: 0;
    `)};
`
const ContainerBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  margin: 10px 0;

  ${props =>
    responsive.tablet(css`
      width: 100%;
      align-items: center;
    `)};
`
const ContainerBoxSideBar = styled.div`
  display: flex;
  flex-direction: row;
`

const OrangeSideBar = styled.div`
  width: 10px;
  height: 100px;
  background-color: ${props => props.theme.ORANGE};
  border-radius: 5px 0 0 5px;
`

const Box = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 590px;
  height: 100px;
  background-color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  ${props =>
    responsive.tablet(css`
      width: 490px;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 280px;
    `)};
`

const CompanyLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin: 20px 31px 20px 24px;

  ${props =>
    responsive.mobile(css`
      width: 35px;
      height: 35px;
    `)};
`
const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const DetailInfo = styled(BoxInfo)`
  margin-bottom: 20px;
  margin-left: 21px;
`

const Info = styled.div`
  font-size: 12px;
`

const ArrowIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 24px;
  font-size: 30px;
  color: ${props => props.theme.GREY10};
  cursor: pointer;
`
const StatusIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 17px;
  right: 192px;
  font-size: 12px;
  color: ${props =>
    props.status ? props.theme.EXTRALIGHTBLUE : props.theme.GREEN};

  ${props =>
    responsive.mobile(css`
      top: 77px;
      right: 176px;
    `)};
`
const InfoStatus = styled.div`
  position: absolute;
  right: 80px;
  top: 14px;
  font-size: 12px;
  color: ${props =>
    props.status ? props.theme.EXTRALIGHTBLUE : props.theme.GREEN};

  ${props =>
    responsive.mobile(css`
      right: 65px;
      top: 75px;
    `)};
`

const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 590px;
  height: 160px;
  background-color: ${props => props.theme.GREY10};
  margin-left: 10px;
  border-radius: 0 0 5px 5px;

  ${props =>
    responsive.tablet(css`
      width: 490px;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 280px;
      height: 100%;
      padding: 35px 25px;
    `)};
`
const Profile = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 22px;
`
const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 123px; */
  height: fit-content;
  margin-right: 27px;

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
    `)};
`

const CustomButton = styled(Button)`
  width: 150px;
  font-size: 12px;
`
const WhiteButton = styled(CustomButton)`
  background-color: white;
  :hover {
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
  }
  :focus {
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
  }
`
const GreyButton = styled(WhiteButton)`
  color: white;
  background-color: ${props => props.theme.GREY50};
  :hover {
    color: white;
    background-color: rgba(127, 127, 127, 0.5);
    border: none;
  }
  :focus {
    color: white;
    background-color: rgba(127, 127, 127, 0.5);
    border: none;
  }
`

const DetailInfoContainer = styled.div`
  display: flex;
  margin-left: 35px;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.YELLOW};
  width: 17px;
  height: 15px;
`
const ContainerStarIcon = styled.div`
  display: flex;
`

const Selector = styled(Field)`
  margin: 0;
  margin-left: 10px;
  width: 140px;
  flex-direction: column;
  .input-selector-container {
    margin: 0;

    .Select {
      width: 100%;
      height: 40px;
      background-color: white;
      border-radius: 5px;
      text-align: center;
      font-size: 12px;

      .Select-option {
        font-size: 12px;
      }
    }
  }
  .label {
    justify-content: flex-start;
    margin: 10px 0;
  }

  ${props =>
    responsive.tablet(css`
      margin-left: 0;
    `)};
`
const ContainerTimeContact = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${props =>
    responsive.tablet(css`
      flex-direction: column;
      align-items: flex-start;
    `)};
`

const InfoSelector = styled.div`
  position: absolute;
  right: 2px;
  bottom: -20px;
  font-size: 12px;
  color: ${props => props.theme.BLUE};
`

const optionsTime = [
  {label: '08.00-12.00', value: '08.00-12.00'},
  {label: '12.00-16.00', value: '12.00-16.00'},
  {label: '16.00-20.00', value: '16.00-20.00'},
]

const renderStar = noonStar => {
  const arrayStar = []
  for (var i = 1; i <= noonStar; i++) {
    arrayStar.push(<CustomIcon key={i} icon={['fas', 'star']} />)
  }
  return arrayStar
}

const renderCard = ({fields, item, handleSubmit, sendLeadAgain}) => {
  return fields.map((value, index) => (
    <BoxOnce
      key={value}
      lead={value}
      item={item}
      index={index}
      handleSubmit={handleSubmit}
      sendLeadAgain={sendLeadAgain}
      type={fields.name}
    />
  ))
}

const displayName = input => (isBlank(input) ? '-' : input)

const DetailOnce = ({item, lead, index, sendLeadAgain, handleSubmit, type}) => (
  <DetailContainer>
    <DetailInfoContainer>
      <Profile
        src={item[index].agent && (item[index].agent.avatar || avatar02)}
      />
      <DetailInfo>
        <Info>{`เลขประจำตัว ${
          item[index].agent ? displayName(item[index].agent.licenseNo) : '-'
        }`}</Info>
        <Info>{`ชื่อ - สกุล ${
          item[index].agent ? displayName(item[index].agent.name) : '-'
        }`}</Info>
        <ContainerStarIcon>
          {renderStar(parseInt(item[index].star))}
        </ContainerStarIcon>
        <ContainerTimeContact>
          <Info>เวลาที่สะดวกติดต่อ</Info>
          <Selector
            name={`${lead}.personalInfoContactTime`}
            type="selector"
            options={optionsTime}
            themeColor="orange"
            placeholder="เลือก"
          />
          {/* <InfoSelector>อัพเดทข้อมูลแล้ว</InfoSelector> */}
        </ContainerTimeContact>
      </DetailInfo>
    </DetailInfoContainer>
    <ContainerButton>
      <CustomButton
        onClick={handleSubmit(value => {
          if (type === 'leadMotor') {
            sendLeadAgain(value.leadMotor[index])
          }
          if (type === 'leadLife') {
            sendLeadAgain(value.leadLife[index])
          }
          if (type === 'leadHealth') {
            sendLeadAgain(value.leadHealth[index])
          }
        })}
        disabled={item[index].state !== 'leaded'}
        icons={['fa', 'user-tie']}>
        ให้ตัวแทนติดต่อกลับ
      </CustomButton>
      {/* <GreyButton icons={['fa', 'times-circle']}>ยกเลิก</GreyButton>
      <WhiteButton icons={['fa', 'thumbs-up']}>รีวิวตัวแทน</WhiteButton> */}
    </ContainerButton>
  </DetailContainer>
)

const enhancerBox = compose(withState('selectedBox', 'setSelectedBox', false))

const BoxOnce = enhancerBox(
  ({
    selectedBox,
    setSelectedBox,
    item,
    lead,
    index,
    sendLeadAgain,
    handleSubmit,
    type,
  }) => (
    <ContainerBox key={lead.quotationId}>
      <ContainerBoxSideBar onClick={() => setSelectedBox(!selectedBox)}>
        <OrangeSideBar />
        <Box>
          <CompanyLogo
            src={
              item[index].insuranceCompany &&
              item[index].insuranceCompany.officialLogoUrl
            }
          />
          <BoxInfo>
            <Info>{`เลขที่ใบสั่งซื้อ ${item[index].quotationId}`}</Info>
            <Info>{`${item[index].insuranceCompany &&
              item[index].insuranceCompany.name}`}</Info>
            <Info>{`เบี้ยประกันปีละ ${displayNumber(
              parseInt(item[index].premium),
            )} บาท/ปี`}</Info>
          </BoxInfo>
          <ArrowIcon icon={['fas', selectedBox ? 'angle-down' : 'angle-up']} />
          <StatusIcon
            status={item[index].state === 'leaded'}
            icon={[
              'fas',
              item[index].state === 'leaded' ? 'user-clock' : 'check-circle',
            ]}
          />
          {item[index].state === 'leaded' ? (
            <InfoStatus status={item[index].state === 'leaded'}>
              รอตัวแทนติดต่อกลับ
            </InfoStatus>
          ) : (
            <InfoStatus>ได้รับการติดต่อแล้ว</InfoStatus>
          )}
        </Box>
      </ContainerBoxSideBar>
      {selectedBox && (
        <DetailOnce
          item={item}
          lead={lead}
          index={index}
          sendLeadAgain={sendLeadAgain}
          handleSubmit={handleSubmit}
          type={type}
        />
      )}
    </ContainerBox>
  ),
)

const LeadProfile = ({
  selectedSubHeader,
  setSelectedSubHeader,
  handleSubmit,
  sendLeadAgain,
  leadMotor,
  leadLife,
  leadHealth,
  isLoading,
}) => (
  <Loading isLoading={isLoading}>
    <Container>
      <Header>แบบประกันที่สนใจซื้อ</Header>
      <HeaderOption>
        <HeaderSub
          selectedSubHeader={selectedSubHeader === 'motor'}
          onClick={() => setSelectedSubHeader('motor')}>
          ประกันรถยนต์
        </HeaderSub>
        <Or>|</Or>
        <HeaderSub
          selectedSubHeader={selectedSubHeader === 'life'}
          onClick={() => setSelectedSubHeader('life')}>
          ประกันชีวิต
        </HeaderSub>
        <Or>|</Or>
        <HeaderSub
          selectedSubHeader={selectedSubHeader === 'health'}
          onClick={() => setSelectedSubHeader('health')}>
          ประกันสุขภาพ
        </HeaderSub>
      </HeaderOption>
      {selectedSubHeader === 'motor' &&
        (leadMotor.length !== 0 ? (
          <FieldArray
            name="leadMotor"
            component={renderCard}
            item={leadMotor}
            sendLeadAgain={sendLeadAgain}
            handleSubmit={handleSubmit}
          />
        ) : (
          'ยังไม่มีแบบประกันที่สนใจ'
        ))}
      {selectedSubHeader === 'life' &&
        (leadLife.length !== 0 ? (
          <FieldArray
            name="leadLife"
            component={renderCard}
            item={leadLife}
            sendLeadAgain={sendLeadAgain}
            handleSubmit={handleSubmit}
          />
        ) : (
          'ยังไม่มีแบบประกันที่สนใจ'
        ))}
      {selectedSubHeader === 'health' &&
        (leadHealth.length !== 0 ? (
          <FieldArray
            name="leadHealth"
            component={renderCard}
            item={leadHealth}
            sendLeadAgain={sendLeadAgain}
            handleSubmit={handleSubmit}
          />
        ) : (
          'ยังไม่มีแบบประกันที่สนใจ'
        ))}
    </Container>
  </Loading>
)

const enhancer = compose(
  connect(
    state => ({
      isLoading: state.profile.isLoading,
      leadMotor: state.profile.leadMotor,
      leadLife: state.profile.leadLife,
      leadHealth: state.profile.leadHealth,
      initialValues: {
        leadMotor: state.profile.leadMotor,
        leadLife: state.profile.leadLife,
        leadHealth: state.profile.leadHealth,
      },
    }),
    {sendLeadAgain, getLead},
  ),
  withState('selectedSubHeader', 'setSelectedSubHeader', 'motor'),
  reduxForm({
    form: 'LeadProfile',
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      const {getLead} = this.props
      getLead()
    },
  }),
)

export default enhancer(LeadProfile)
