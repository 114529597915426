import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'

import Coverage from './Coverage'
import Premium from './Premium'
import EvaluationForm from './EvaluationForm'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`

const Topics = styled.div`
  display: flex;
`

const Topic = styled.h1`
  line-height: 24px;
  font-size: 16px;
  font-family: Prompt;
  font-weight: ${props => (props.isActived ? 700 : 400)};
  color: ${props => (props.isActived ? '#265FA7' : '#6A94CB')};

  cursor: pointer;
`

const Seperator = styled.span`
  font-size: 16px;
  margin: 0 5px;
  color: #6a94cb;
`

const InsurancePort = ({user, topic, setTopic}) =>
  user.hasAnswer ? (
    <Container>
      <Header>พอร์ตแบบประกัน</Header>
      <Topics>
        <Topic
          isActived={topic === 'coverage'}
          onClick={() => setTopic('coverage')}>
          สรุปความคุ้มครอง
        </Topic>
        {/* <Seperator>|</Seperator>
        <Topic
          isActived={topic === 'premium'}
          onClick={() => setTopic('premium')}>
          สรุปเบี้ยประกัน
        </Topic> */}
      </Topics>
      {topic === 'coverage' && <Coverage />}
      {topic === 'premium' && <Premium />}
    </Container>
  ) : (
    <EvaluationForm />
  )

const enhancer = compose(
  withState('topic', 'setTopic', 'coverage'),
  connect(
    state => ({
      user: state.auth.user,
    }),
    {},
  ),
)

export default enhancer(InsurancePort)
