import {takeLatest, put, select} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {httpGet, httpPost, API_HOST, httpDelete} from '../../core/httpClient'
import humps from 'humps'
import {removefilterObj} from '../../core/helper'
import {
  LifeCheckboxesHeaderName,
  motorCheckboxesHeaderName,
  healthCheckboxesHeaderName,
} from '../product/cardDetails'
import moment from 'moment'
import {setAgentInfo} from '../product'

const SEND_LEAD = 'SEND_LEAD'
const SET_DATA = 'SET_DATA'
const FETCH_LIFE_PRODUCT_LIST = 'FETCH_LIFE_PRODUCT_LIST'
const FETCH_HEALTH_PRODUCT_LIST = 'FETCH_HEALTH_PRODUCT_LIST'
const FETCH_PENSION_PRODUCT_LIST = 'FETCH_PENSION_PRODUCT_LIST'
const SET_LIFE_INSURANCE_PRODUCT_LIST = 'SET_LIFE_INSURANCE_PRODUCT_LIST'
const SET_HEALTH_INSURANCE_PRODUCT_LIST = 'SET_HEALTH_INSURANCE_PRODUCT_LIST'
const SET_PENSION_INSURANCE_PRODUCT_LIST = 'SET_PENSION_INSURANCE_PRODUCT_LIST'
const SET_FILTER_LIST = 'SET_FILTER_LIST'
const SEND_LEAD_POLICY = 'SEND_LEAD_POLICY'

export const sendLead = Creator(SEND_LEAD, 'data')
export const sendLeadPolicy = Creator(SEND_LEAD_POLICY, 'data')

export const setData = Creator(SET_DATA, 'data')
export const setLifeInsuranceProductList = Creator(
  SET_LIFE_INSURANCE_PRODUCT_LIST,
  'productList',
)
export const setHeathInsuranceProductList = Creator(
  SET_HEALTH_INSURANCE_PRODUCT_LIST,
  'productList',
)
export const setPensionInsuranceProductList = Creator(
  SET_PENSION_INSURANCE_PRODUCT_LIST,
  'productList',
)
export const fetchLifeProductList = Creator(
  FETCH_LIFE_PRODUCT_LIST,
  'category',
  'filter',
)
export const fetchHealthProductList = Creator(
  FETCH_HEALTH_PRODUCT_LIST,
  'category',
  'filter',
)
export const fetchPensionProductList = Creator(
  FETCH_PENSION_PRODUCT_LIST,
  'category',
  'filter',
)
export const setFilterList = Creator(SET_FILTER_LIST, 'filterList')

const filterCompanyId = filter => {
  if (filter.companyIds) {
    if (
      filter.companyIds[0] === 'all' ||
      filter.companyIds[0] === '' ||
      isNaN(filter.companyIds[0])
    ) {
      const modifiredCompany = filter.companyIds.slice(1)
      return (newData = {...newData, company_ids: modifiredCompany.toString()})
    } else {
      return (newData = {...newData, company_ids: filter.companyIds.toString()})
    }
  }
  if (filter.insuranceType) {
    return (newData = {
      ...newData,
      insurance_type: filter.insuranceType.toString(),
    })
  }

  return filter
}

export function* sendLeadSaga({payload: {data}}) {
  yield put(setData({isLoading: true}))
  // try {

  // } catch(error) {
  //   yield put(setData({}))
  // }
  // yield put(setData({errorMessage: {email: 'อีเมลถูกลงทะเบียนแล้ว'}}))
  yield put(setData({isLoading: false}))
}

export function* sendLeadPolicySaga({payload: {data}}) {
  yield put(setData({isLoading: true}))

  const insurancePolicyId = data.insurancePolicyId
  try {
    const idResponseLife = yield httpPost(`${API_HOST}insurance_leads/life`, {
      personalInfoFullname: `${data.firstName} ${data.lastname}`,
      personalInfoGender: data.gender,
      personalInfoPhoneNumber: data.telnumber,
      personalInfoBirthDate: `${moment().date()}/${moment().month() +
        1}/${moment().year() - data.age}`,
      personalInfoAge: data.age,
      personalInfoContactTime: data.time,
      personalInfoEmail: data.email,
      consent: true,
      urgentLevel: 2,
      premium: data.premium,
      paramsEncode: data.paramsEncode,
      insurancePolicyId,
    })

    yield put(
      setAgentInfo(
        insurancePolicyId,
        idResponseLife.data.data.lifeInsuranceLead.agent,
      ),
    )
  } catch (error) {
    console.error(error.response || error)
  } finally {
    yield put(setData({isLoading: false}))
  }
}

export function* fetchLifeProductListSaga({payload: {category, filter}}) {
  let newData = humps.decamelizeKeys(filter)
  newData = filterCompanyId(filter)

  yield put(setFilterList(LifeCheckboxesHeaderName))
  yield put(setData({isLoading: true}))
  const urlLife = `${API_HOST}life_insurance_policies/search_life_products`

  try {
    const response = yield httpGet(urlLife, newData)
    yield put(setLifeInsuranceProductList(response.data.data.lifeProducts))
    yield put(
      setData({
        favoriteProduct: response.data.data.lifeProducts.reduce((mem, cur) => {
          mem[cur.id] = cur.favorite
          return mem
        }, {}),
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setData({isLoading: false}))
}

export function* fetchPensionProductListSaga({payload: {category, filter}}) {
  let newData = humps.decamelizeKeys(filter)
  newData = filterCompanyId(filter)

  yield put(setFilterList(LifeCheckboxesHeaderName))
  yield put(setData({isLoading: true}))
  const urlLife = `${API_HOST}life_insurance_policies/search_life_products`

  try {
    const response = yield httpGet(urlLife, newData)
    yield put(setPensionInsuranceProductList(response.data.data.lifeProducts))
    yield put(
      setData({
        favoriteProduct: response.data.data.lifeProducts.reduce((mem, cur) => {
          mem[cur.id] = cur.favorite
          return mem
        }, {}),
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setData({isLoading: false}))
}

export function* fetchHealthProductListSaga({payload: {category, filter}}) {
  let newData = humps.decamelizeKeys(filter)
  newData = filterCompanyId(filter)

  yield put(setFilterList(healthCheckboxesHeaderName))
  yield put(setData({isLoading: true}))
  const urlHealth = `${API_HOST}health_insurance_policies/search_health_products`
  let healthData = {...newData}

  if (newData.insurance_type === 'hs') {
    healthData = {
      ...healthData,
      hs_type: 2,
      ci_type: 3,
      hs_amount: healthData.search_value,
    }
    healthData = removefilterObj(healthData, [
      'ci_diseases',
      'ci_roles',
      'ci_amount',
      'insurance_type',
      'search_value',
      'weight_type',
      'sort_by',
    ])
  } else if (newData.insurance_type === 'ci') {
    healthData = {
      ...healthData,
      hs_type: 3,
      ci_type: 2,
      ci_amount: healthData.search_value,
    }
    healthData = removefilterObj(healthData, [
      'hs_goal',
      'hs_amount',
      'insurance_type',
      'search_value',
      'weight_type',
      'sort_by',
    ])
  }
  try {
    const response = yield httpGet(urlHealth, healthData)
    yield put(setHeathInsuranceProductList(response.data.data.healthProducts))
    yield put(
      setData({
        favoriteProduct: response.data.data.healthProducts.reduce(
          (mem, cur) => {
            mem[cur.id] = cur.favorite
            return mem
          },
          {},
        ),
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setData({isLoading: false}))
}

export function* watchTaxcalSaga() {
  yield takeLatest(SEND_LEAD, sendLeadSaga)
  yield takeLatest(SEND_LEAD_POLICY, sendLeadPolicySaga)
  yield takeLatest(FETCH_LIFE_PRODUCT_LIST, fetchLifeProductListSaga)
  yield takeLatest(FETCH_HEALTH_PRODUCT_LIST, fetchHealthProductListSaga)
  yield takeLatest(FETCH_PENSION_PRODUCT_LIST, fetchPensionProductListSaga)
}

const initial = {
  isLoading: false,
  errorMessage: '',
  lifeInsuranceProductList: [],
  healthInsuranceProductList: [],
  pensionInsuranceProductList: [],
  filterList: [],
}

export default createReducer(initial, state => ({
  [SET_DATA]: ({data}) => ({...state, ...data}),
  [SET_LIFE_INSURANCE_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    lifeInsuranceProductList: [...productList],
  }),
  [SET_HEALTH_INSURANCE_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    healthInsuranceProductList: [...productList],
  }),
  [SET_PENSION_INSURANCE_PRODUCT_LIST]: ({productList}) => ({
    ...state,
    pensionInsuranceProductList: [...productList],
  }),
  [SET_FILTER_LIST]: ({filterList}) => ({
    ...state,
    filterList: [...filterList],
  }),
}))
