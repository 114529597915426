import styled from 'react-emotion'

import CustomField from '../../../../share/Field'

export const CustomSelector = styled(CustomField)`
  margin-bottom: 8px;
  flex-direction: column;
  height: unset;

  text-align: left;
  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .Select-control > .Select-multi-value-wrapper > .Select-placeholder {
    font-weight: 400;
    font-size: 16px;
    text-align: left !important;
    max-width: calc(100% - 40px);
  }
`
export const CustomCheckboxs = styled(CustomField)`
  margin: unset;
  flex-direction: column;

  height: ${props => (props.collapseActive ? '0' : '100%')};
  transform: ${props => (props.collapseActive ? 'scaleY(0)' : 'scaleY(1)')};
  transform-origin: center top;
  transition: height 0.25s, transform 0.25s ease-in-out;
  overflow: ${props => (props.collapseActive ? 'unset' : 'hidden')};

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .ant-checkbox-group-item:last-child {
    margin-right: 0px;
  }
  .ant-checkbox-group-item {
    margin-right: 0px;
  }
  .ant-checkbox-group {
    width: 100%;
  }

  .label {
    margin-left: unset;
  }
  .ant-checkbox + span,
  .ant-checkbox-wrapper + span {
    font-weight: 400 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
`
export const CustomCheckbox = styled(CustomField)`
  height: unset;

  .checkboxContainer {
    margin: 0;
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ant-checkbox + span,
  span,
  .ant-checkbox-wrapper + span {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .label {
    margin: 0;
  }
`
export const CustomRadios = styled(CustomField)`
  margin: unset;
  height: unset;

  height: ${props => (props.collapseActive ? '0' : '100%')};
  transform: ${props => (props.collapseActive ? 'scaleY(0)' : 'scaleY(1)')};
  transform-origin: center top;
  transition: height 0.25s, transform 0.25s ease-in-out;
  overflow: ${props => (props.collapseActive ? 'unset' : 'hidden')};

  .ant-radio-group {
    height: unset !important;
  }
  .ant-radio-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0px 20px 0 !important;
  }
`
export const TitleSideBar = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #265fa7;
  min-width: fit-content;
  margin-bottom: 20px;
`
export const GroupSideBar = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: 30px 21.62px 30px 28px;
`
