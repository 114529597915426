import React from 'react'
import styled, {injectGlobal} from 'react-emotion'
import {WhiteCard, Tax} from '../../components'
import DetailPanel from './DetailPanel'
import Button from '../../../share/Button'

injectGlobal`
  .ant-modal-confirm-btns {
    text-align: right;
  }

  .ant-modal-confirm-btns > button {
    margin-left: 10px;
  }
`

const MainLayout = styled(WhiteCard)`
  flex: 1;
  max-width: 500px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  background-color: #d9d9dc;
  border-bottom: 1px solid #d9d9de;
`
const CustomTax = styled(Tax)`
  > div:last-child {
    > div {
      > div {
        font-size: 20px;
        font-weight: unset;
      }
    }
  }
`
const Body = styled.div`
  flex: 10;
`

const Footer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  background: #d9d9dc;
  cursor: pointer;
`

const ProfileDetail = props => (
  <MainLayout>
    <Header>
      <CustomTax
        answerName="tax"
        valueColor="#333"
        positivePrefix="จ่ายเพิ่ม"
        negativePrefix="ได้คืน"
      />
    </Header>
    <Body>
      <DetailPanel answerName="profile_details" />
    </Body>
    <Footer>
      <Button onClick={() => console.log('click me')}>Planning</Button>
    </Footer>
  </MainLayout>
)

export default ProfileDetail
