import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {navigate} from '@reach/router'
import {compose, withState, lifecycle} from 'recompose'

import Register from './Register'
import Login from './Login'
import Interest from './Interest'
import ConfirmEmail from './ConfirmEmail'
import ForgetPassword from './ForgetPassword'
import ForgetPasswordResendEmail from './ForgetPasswordResendEmail'
import ResetPassword from './ResetPassword'
import Success from './Success'
import ResendEmail from './ResendEmail'
import Loading from '../share/Loading'

const Container = styled.div`
  position: relative;
  flex-direction: column;
`

const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  margin-top: 50px;
`
const StatusbarBackGround = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 7px;
  background-color: rgba(255, 217, 0, 0.1);
`

const Statusbar = styled.div`
  display: flex;
  justify-content: flex-start;
  width: ${props => props.item * 20}%;
  height: 7px;
  background-color: ${props => props.theme.YELLOW};
  transition: width 1s;
`

const Header = styled.span`
  font-size: 20px;
  font-weight: ${props => props.isRegister && 'bold'};
  cursor: ${props => !props.isRegister && 'pointer'};
  color: ${props =>
    props.isRegister ? props.theme.BLUE : props.theme.EXTRALIGHTBLUE};
  margin: 0 5px;
`
const Or = styled.span`
  font-size: 20px;
  margin: 0 10px;
  color: ${props => props.theme.EXTRALIGHTBLUE};
`

const Auth = ({
  modal,
  category,
  item,
  setItem,
  onHeaderRegister = () => navigate(`/authorize/register`),
  onHeaderLogin = () => navigate(`/authorize/login`),
  onSetConfirmEmail,
  isLoading,
  location,
  closeModal,
  initialized,
  ...otherProps
}) => {
  return (
    initialized && (
      <Loading isLoading={isLoading}>
        <Container>
          {category === 'register' && (
            <React.Fragment>
              <StatusbarBackGround />
              <Statusbar item={item.length || 0} />
            </React.Fragment>
          )}

          {(category === 'register') | (category === 'login') ? (
            <HeaderOption>
              <Header
                onClick={() => onHeaderLogin()}
                isRegister={category === 'login'}>
                เข้าสู่ระบบ
              </Header>
              <Or>|</Or>
              <Header
                onClick={() => onHeaderRegister()}
                isRegister={category === 'register'}>
                ลงทะเบียน
              </Header>
            </HeaderOption>
          ) : null}

          {category === 'confirmEmail' && (
            <ConfirmEmail onHeaderLogin={onHeaderLogin} modal={modal} />
          )}
          {category === 'confirmEmailSuccess' && (
            <Success
              name={'ยืนยันอีเมลสำเร็จ'}
              type={'email'}
              location={location}
              modal={modal}
            />
          )}
          {category === 'register' && (
            <Register
              setItem={setItem}
              item={item}
              onSetConfirmEmail={onSetConfirmEmail}
              modal={modal}
            />
          )}
          {category === 'login' && (
            <Login modal={modal} closeModal={closeModal} />
          )}
          {category === 'interest' && <Interest {...otherProps} />}
          {category === 'forgetPassword' && <ForgetPassword />}
          {category === 'forgetPasswordResendEmail' && (
            <ForgetPasswordResendEmail />
          )}
          {category === 'resetPassword' && (
            <ResetPassword location={location} />
          )}
          {category === 'resetPasswordSuccess' && (
            <Success name={'เปลี่ยนรหัสผ่านสำเร็จ'} location={location} />
          )}
          {category === 'resendEmail' && <ResendEmail />}
          {category === 'resendEmailSuccess' && (
            <Success name={'ส่งอีเมลสำเร็จกรุณาตรวจสอบ'} location={location} />
          )}
        </Container>
      </Loading>
    )
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.auth.isLoading,
      isLogin: state.auth.isLogin,
      isInterest: state.auth.isInterest,
      initialized: state.auth.initialized,
    }),
    {},
  ),
  withState('item', 'setItem', []),
  lifecycle({
    componentWillReceiveProps(np) {
      if (!np.modal) {
        if (np.isLogin) {
          if (!(np.isInterest || np.category === 'interest')) {
            navigate('/')
          }
        } else {
          if (np.isInterest || np.category === 'interest') {
            navigate('/')
          }
        }
      }
    },
  }),
)

export default enhancer(Auth)
