import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {compose, withState} from 'recompose'
import styled, {css} from 'react-emotion'
import {navigate, Link} from '@reach/router'
import withSEO from '../../../share/withSEO'

import Banner from '../../../../static/images/Banner.png'
import BannerMobile from '../../../../static/images/BannerMobile.jpg'

import Navigator from '../../Navigator'
import Button from '../../../share/Button'
import Tooltip from '../../../share/Tooltip'
import {fadeIn, responsive} from '../../../../core/style'
import {url2Obj} from '../../../../core/helper'
import Form from './Form'

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: transparent;
        border-bottom-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 260px;
        background-color: #7f7f7f;
      }
    }
  }
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  min-width: 397px;
  top: 80px;
  left: calc(50%);

  ${props =>
    responsive.tablet(css`
      left: calc(40%);
    `)};

  ${props =>
    responsive.mobile(css`
      top: 10%;
      min-width: unset;
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 9px;
    `)};
`

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Banner});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const ContainerBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  min-height: 295px;
  margin-top: 35px;
  margin-bottom: 70px;
  ${props =>
    responsive.mobile(css`
      display: none;
      max-width: 300px;
      min-height: 450px;
    `)};
`

const ContainerBoxMobile = styled.div`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: flex;
      flex-direction: column;
    `)};
`

const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.expand && props.theme.GREY80};
  animation: ${fadeIn} 2s;
`

const BoxOnce = styled.div`
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  width: ${props => (props.expand ? '100%' : '190px')};

  height: ${props => (props.expand ? '450px' : '140px')};
  z-index: ${props => (props.expand ? 1 : 0)};
  top: ${props => (props.expand ? '0' : props.top)};
  left: ${props => (props.expand ? '0' : props.left)};

  transition: all
      ${props =>
        props.expand
          ? props.transition
            ? props.expanding
              ? '0.5s'
              : '0s'
            : '0.5s'
          : '0.5s'},
    z-index
      ${props => (!props.transition ? (props.expand ? '1ms' : '1s') : '0s')},
    background 0s, color 0s;

  border-radius: 5px;
  border: ${props =>
    props.expand && `1px solid ${props.theme.EXTRALIGHTORANGE}`};
  background-color: ${props => (props.expand ? 'white' : '#6A94CB')};
  .shield-alt {
    right: ${props => props.expand && -75}px;
  }
  .coins {
    right: ${props => props.expand && -64}px;
  }
  .hand-holding-heart {
    right: ${props => (props.expand ? -92 : -45)}px;
  }
  .child {
    right: -13px;
  }
  .briefcase {
    right: ${props => props.expand && -56}px;
  }
  .piggy-bank {
    right: ${props => (props.expand ? -90 : -48)}px;
  }
  :hover {
    cursor: ${props => !props.expand && 'pointer'};
    background-color: ${props => !props.expand && '#265FA7'};
    color: ${props => !props.expand && 'white'};
    .shield-alt,
    .coins,
    .hand-holding-heart,
    .child,
    .briefcase,
    .piggy-bank {
      color: ${props => !props.expand && 'rgba(178, 117, 0, 0.3)'};
    }
  }

  ${props =>
    responsive.mobile(css`
      width: ${props.expand ? '100%' : '140px'};
      height: ${props.expand ? '100%' : '140px'};
      top: ${props.expand ? '0' : props.topMobile};
      left: ${props.expand ? '0' : props.leftMobile};

      .shield-alt {
        right: ${props.expand && -90}px;
      }
      .coins {
        right: ${props.expand && -110}px;
      }
      .hand-holding-heart {
        right: ${props.expand && -100}px;
        top: ${props.expand && 95}px;
      }
      .child {
        right: ${props.expand && -45}px;
      }
      .briefcase {
        right: ${props.expand && -106}px;
      }
      .piggy-bank {
        right: ${props.expand && -116}px;
      }
    `)};
`

const IconContainer = styled.div`
  position: absolute;
  top: ${props => (props.expand ? 38 : 25)}px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s, top 0s;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const IconContainerMobile = styled(IconContainer)`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 16px;
    `)};
`
const ArrowLeft = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 5px;
`
const InfoTop = styled.div`
  margin-top: 10px;
  font-size: ${props => (props.expand ? 16 : 12)}px;
  ${props =>
    responsive.mobile(css`
      margin: 0;
    `)};
`
const InfoMiddle = styled.div`
  font-size: ${props => (props.expand ? 16 : 12)}px;
`
const InfoBottom = styled.div`
  font-size: ${props => (props.expand ? 16 : 12)}px;
`
const ContainerHastag = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`
const HeaderHastag = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  margin-top: 130px;
  font-size: 12px;
  ${props =>
    responsive.mobile(css`
      margin-top: 65px;
    `)};
`

const Hastag = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 80px;
  padding: 4px 15px 3px;
  border-radius: 5px;
  margin: 7px 5px 0;
  background-color: ${props => props.theme.GREY10};
  color: ${props => props.theme.GREY50};
  font-size: 12px;
`
const ContainerChevron = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: absolute;
  width: 290px;
  height: 20px;
  left: 55px;
  top: 76px;
  animation: ${fadeIn} 2s;

  ${props =>
    responsive.mobile(css`
      width: 228px;
      left: 37px;
    `)};
`
const ChevronArrow = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => props.theme.ORANGE};
  cursor: pointer;
`

const ContainerButton = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 22px;
`

const CustomButton = styled(Button)`
  width: 150px;
  height: 30px;
  border-radius: 15px;
  font-size: 12px;
  border: unset;
  margin: 0 15px;
`

const Back = styled.div`
  display: ${props => (props.expand ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 26px;
  font-size: 16px;
  color: ${props => props.theme.GREY50};
  position: absolute;
  left: 18px;
  top: 14px;
  z-index: 1;
  cursor: pointer;
  animation: ${fadeIn} 2s;
`

const BoxList = [
  {
    iconTag: 'fas',
    iconName: 'shield-alt',
    info: 'วางแผนปกป้องภาระที่อาจจะตกถึงครอบครัว',
    infoTop: 'วางแผนปกป้อง',
    infoBottom: 'ภาระที่อาจจะตกถึงครอบครัว',
    infoTopTablet: 'วางแผนปกป้องภาระ',
    infoBottomTablet: 'ที่อาจจะตกถึงครอบครัว',
    infoTopMobile: 'วางแผนปกป้องภาระ',
    infoBottomMobile: 'ที่อาจจะตกถึงครอบครัว',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'ชั่วระยะเวลา'}],
    type: 'DebtProtection',
    analyticName: 'dept-protection',
  },
  {
    iconTag: 'fas',
    iconName: 'coins',
    info: 'วางแผนเพื่อสร้างความคุ้มครองด้านรายได้ของครอบครัว',
    infoTop: 'วางแผนเพื่อสร้างความคุ้มครอง',
    infoBottom: 'ด้านรายได้ของครอบครัว',
    infoTopTablet: 'วางแผนเพื่อสร้างความ',
    infoMidTablet: 'ความคุ้มครองด้าน',
    infoBottomTablet: 'รายได้ของครอบครัว',
    infoTopMobile: 'วางแผนเพื่อสร้างความ',
    infoMidMobile: 'ความคุ้มครองด้าน',
    infoBottomMobile: 'รายได้ของครอบครัว',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'สะสมทรัพย์'}, {name: 'ชั่วระยะเวลา'}],
    type: 'FamilyIncomeProtection',
    left: '205px',
    leftMobile: '155px',
    analyticName: 'family-income-protection',
  },
  {
    iconTag: 'fas',
    iconName: 'hand-holding-heart',
    info: 'วางแผนเงินเกษียณที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า',
    infoTop: 'วางแผนเงินเกษียณ',
    infoBottom: 'ที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า',
    infoTopTablet: 'วางแผนเงินเกษียณที่ใช้อยู่',
    infoBottomTablet: 'ดูแลเราไปจนแก่เฒ่า',
    infoTopMobile: 'วางแผนเงินเกษียณ',
    infoMidMobile: 'ที่ใช้อยู่ดูแลเรา',
    infoBottomMobile: 'ไปจนแก่เฒ่า',
    hasTag: [{name: 'สะสมทรัพย์'}, {name: 'บำนาญ'}],
    type: 'Retirement',
    analyticName: 'retirement',

    top: 'calc(100% - 140px)',
    topMobile: 'calc(100% - 295px)',
    leftMobile: '0',
  },
  {
    iconTag: 'fas',
    iconName: 'child',
    info: 'วางแผนคุ้มครองค่าใช้จ่ายการศึกษาบุตร',
    infoTop: 'วางแผนคุ้มครอง',
    infoBottom: 'ค่าใช้จ่ายการศึกษาบุตร',
    infoTopTablet: 'วางแผนคุ้มครอง',
    infoBottomTablet: 'ค่าใช้จ่ายการศึกษาบุตร',
    infoTopMobile: 'วางแผนคุ้มครอง',
    infoBottomMobile: 'ค่าใช้จ่ายการศึกษาบุตร',
    hasTag: [{name: 'สะสมทรัพย์'}],
    type: 'Child',
    analyticName: 'child',

    top: 'calc(100% - 140px)',
    left: '205px',
    topMobile: 'calc(100% - 295px)',
    leftMobile: '155px',
  },
  {
    iconTag: 'fas',
    iconName: 'briefcase',
    info: 'วางแผนลดหย่อนภาษีด้วยประกัน',
    infoTop: 'วางแผนลดหย่อน',
    infoBottom: 'ภาษีด้วยประกัน',
    infoTopTablet: 'วางแผนลดหย่อนภาษี',
    infoBottomTablet: 'ด้วยประกัน',
    infoTopMobile: 'วางแผนลดหย่อน',
    infoBottomMobile: 'ภาษีด้วยประกัน',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'สะสมทรัพย์'}, {name: 'บำนาญ'}, ,],
    type: 'TaxReduction',
    analyticName: 'tax-reduxtion',

    top: 'calc(100% + 15px)',

    topMobile: 'calc(100% - 140px)',
    leftMobile: '0',
  },
  {
    iconTag: 'fas',
    iconName: 'piggy-bank',
    info: 'วางแผนสะสมเงินพร้อมทุนประกัน',
    infoTop: 'วางแผนสะสมเงิน',
    infoBottom: 'พร้อมทุนประกัน',
    infoTopTablet: 'วางแผนสะสมเงิน',
    infoBottomTablet: 'พร้อมทุนประกัน',
    infoTopMobile: 'วางแผนสะสมเงิน',
    infoBottomMobile: 'พร้อมทุนประกัน',
    hasTag: [{name: 'สะสมทรัพย์'}],
    type: 'Saving',
    analyticName: 'saving',

    left: '205px',
    top: 'calc(100% + 15px)',
    topMobile: 'calc(100% - 140px)',
    leftMobile: '155px',
  },
]

const Box = ({
  id,
  iconName,
  infoTop,
  infoTopMobile,
  infoBottom,
  infoBottomMobile,
  infoMidMobile,
  iconTag,
  hasTag,
  setExpand,
  expand,
  type,
  top,
  topMobile,
  left,
  leftMobile,
  transition,
  setTransition,
  expanding,
  setExpanding,
}) => (
  <BoxOnce
    id={id}
    top={top}
    topMobile={topMobile}
    left={left}
    leftMobile={leftMobile}
    className={iconName}
    transition={transition}
    expanding={expanding}
    expand={expand === iconName}
    onClick={() => {
      setExpand(iconName)
      setTransition(true)
      setExpanding(true)
    }}>
    <InfoContainerBox expand={expand === iconName}>
      <IconContainer expand={expand === iconName}>
        <CustomIcon icon={[iconTag, iconName]} />
        <InfoTop expand={expand === iconName}>{infoTop}</InfoTop>
        <InfoBottom expand={expand === iconName}>{infoBottom}</InfoBottom>
      </IconContainer>
      <IconContainerMobile expand={expand === iconName}>
        <CustomIcon icon={[iconTag, iconName]} />
        <InfoTop expand={expand === iconName}>
          {iconName === 'shield-alt' || iconName === 'coins'
            ? expand === iconName
              ? infoTop
              : infoTopMobile
            : infoTop}
        </InfoTop>
        <InfoMiddle expand={expand === iconName}>
          {expand !== iconName && iconName === 'coins' && infoMidMobile}
        </InfoMiddle>
        <InfoBottom expand={expand === iconName}>
          {iconName === 'shield-alt' || iconName === 'coins'
            ? expand === iconName
              ? infoBottom
              : infoBottomMobile
            : infoBottom}
        </InfoBottom>
      </IconContainerMobile>
      <HeaderHastag expand={expand === iconName}>
        ประเภทของประกันที่เกี่ยวข้อง
      </HeaderHastag>
      <ContainerHastag expand={expand === iconName}>
        {hasTag.map(i => (
          <Hastag expand={expand === iconName} key={i.name}>
            {i.name}
          </Hastag>
        ))}
      </ContainerHastag>
      <ContainerButton expand={expand === iconName}>
        <CustomTooltip
          placement="bottom"
          title={'ช่องทางสำหรับมือใหม่ซื้อแบบไม่มากไม่น้อยเกินไป'}>
          <CustomButton
            onClick={() =>
              navigate(`/smart/InsuranceCategory/life/starter?type=${type}`)
            }
            icons={['fa', 'search']}>
            คำนวณความคุ้มครอง
          </CustomButton>
        </CustomTooltip>
      </ContainerButton>
    </InfoContainerBox>
  </BoxOnce>
)

const BoxMobileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 170px;
  min-height: 50px;
  background-color: #265fa7;
  border-radius: 5px;
  margin: 10px 0;
  color: white;
  overflow: hidden;
  padding: 10px;
`

const BoxMobileIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  z-index: 1;
`

const BoxMobileText = styled.div`
  margin-left: 15px;
  z-index: 1;

  font-size: 12px;
`

const BoxMobile = props => {
  return (
    <BoxMobileContainer
      id={`noon-smart-insurance-life-starter-${props.analyticName}`}
      key={props.analyticName}
      onClick={() =>
        navigate(`/smart/InsuranceCategory/life/starter?type=${props.type}`)
      }>
      <BoxMobileIcon icon={[props.iconTag, props.iconName]} />
      <BoxMobileText>{props.info}</BoxMobileText>
    </BoxMobileContainer>
  )
}

const LifeStartLanding = ({
  type,
  expand,
  expanding,
  setExpand,
  transition,
  setExpanding,
  setTransition,
  location: {search},
}) => {
  const BoxIndex = BoxList.findIndex(i => i.iconName === expand)
  if (type === '') {
    return (
      <NoonSmartWrapper>
        <NoonSmartBanner>
          <Container>
            <NoonSmartHeader>แบบประกันชีวิต</NoonSmartHeader>
            <NoonSubSmartHeader>
              วางแผนคุ้มครองทุกความเสี่ยงด้วยประกันชีวิต
            </NoonSubSmartHeader>
            <Navigator id="noon-smart-insurance-life-starter-back" />
            <ContainerBoxMobile>
              {BoxList.map((i, index) => (
                <BoxMobile {...i} key={index} />
              ))}
            </ContainerBoxMobile>
            <ContainerBox>
              {BoxList.map((i, index) => (
                <Box
                  id={`noon-smart-insurance-life-starter-${i.analyticName}`}
                  key={index}
                  {...i}
                  expand={expand}
                  setExpand={setExpand}
                  transition={transition}
                  setTransition={setTransition}
                  expanding={expanding}
                  setExpanding={setExpanding}
                />
              ))}
              <Back
                expand={expand}
                onClick={() => {
                  setExpand('')
                  setTransition(false)
                  setExpanding(false)
                }}>
                <ArrowLeft icon={['fas', 'angle-left']} />
                กลับ
              </Back>
              <ContainerChevron expand={expand}>
                <ChevronArrow
                  onClick={() =>
                    BoxIndex !== 0
                      ? setExpanding(false) ||
                        setExpand(BoxList[BoxIndex - 1].iconName)
                      : setExpanding(false) ||
                        setExpand(BoxList[BoxList.length - 1].iconName)
                  }
                  icon={['fas', 'chevron-circle-left']}
                />
                <ChevronArrow
                  onClick={() =>
                    BoxIndex !== BoxList.length - 1
                      ? setExpanding(false) ||
                        setExpand(BoxList[BoxIndex + 1].iconName)
                      : setExpanding(false) || setExpand(BoxList[0].iconName)
                  }
                  icon={['fas', 'chevron-circle-right']}
                />
              </ContainerChevron>
            </ContainerBox>
          </Container>
        </NoonSmartBanner>
      </NoonSmartWrapper>
    )
  } else {
    return <Form type={type} BoxList={BoxList} search={search} />
  }
}

const enhancer = compose(
  withSEO(
    'noon Smart Life - ค้นหาประกันชีวิตที่เหมาะสมสำหรับคุณ',
    'noon Smart-Life ค้นหาแบบประกันชีวิตง่ายๆ รายละเอียดครบถ้วน ตามวัตถุประสงค์ที่คุณต้องการ หมดปัญหากับข้อมูลที่ไม่ครบถ้วน เรามีระบบที่ช่วยบอกรายละเอียดเพื่อใช้เป็นเครื่องมือในการหาข้อมูล ก่อนการตัดสินใจซื้อประกันชีวิต เพื่อความคุ้มค่าและความคุ้มครองที่สูงสุด',
    'เครื่องมือค้นหาประกันชีวิต วางแผนประกันชีวิต เลือกซื้อประกันชีวิต,ประกันชีวิต,สะสมทรัพย์,ตลอดชีพ,บำนาญ,ชั่วระยะเวลา,เปรียบเทียบประกันชีวิต',
  ),
  connect(
    (state, props) => ({
      type: url2Obj(props.location.search).type || '',
    }),
    {},
  ),
  withState('expand', 'setExpand', ''),
  withState('transition', 'setTransition', false),
  withState('expanding', 'setExpanding', false),
)

export default enhancer(LifeStartLanding)
