import React from 'react'
import {compose, withProps, withHandlers} from 'recompose'
import styled from 'react-emotion'
import Modal from '../../share/Modal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Button} from '../components'
import {navigate} from '@reach/router'

const CustomModal = styled(Modal)``
const WrapContentModal = styled.div`
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const WrapperTitle = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    color: #ffc107;
    font-size: 40px;
  }
`
const Title = styled.div`
  margin: 20px 0px 0px 0px;
  font-size: 20px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;
  text-align: center;
`
const MobileCutWord = styled.br`
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`
const Description = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`
const RowDescription = styled.div`
  max-width: 200px;

  display: flex;
  margin-bottom: 15px;
  > svg:first-child {
    width: 30px;
    align-items: center;
    color: #dc3545;
    margin-top: 5px;
  }
  > div:last-child {
    display: flex;
    align-items: center;
  }
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
`
const Text = styled.div`
  min-width: 250px;
  max-width: 250px;
  color: #333333;
  display: flex;
  font-size: 16px;
  margin-bottom: 20px;
`
const SeeProductButton = styled(Button)`
  min-width: 250px;
  max-width: 250px;
  min-height: 38px;
`

const NotFoundProductModal = props => (
  <CustomModal isShowModal={props.isShowModal} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <WrapperTitle>
        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
        <Title>
          ไม่พบแบบประกันลดหย่อนภาษี <MobileCutWord />
          ที่เหมาะกับคุณเนื่องจาก...
        </Title>
      </WrapperTitle>
      <Description>
        {props.errorLists.length > 0 ? (
          <>
            {props.errorLists.map(item => (
              <RowDescription>
                <FontAwesomeIcon icon={['fas', 'times-circle']} />
                <div>{item}</div>
              </RowDescription>
            ))}
          </>
        ) : null}
      </Description>
      <Footer>
        <Text>
          แต่คุณสามารถซื้อประกันอื่นเพื่อสร้างความคุ้มครองและออมเงินได้
        </Text>
        <SeeProductButton onClick={props.onSeeProduct}>
          ดูแบบประกันอื่นๆ
        </SeeProductButton>
      </Footer>
    </WrapContentModal>
  </CustomModal>
)

const enhancer = compose(
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
    onSeeProduct: props => () => {
      navigate(`/smart/InsuranceCategory/life/intermediate`)
      props.setIsShowModal(false)
    },
  }),
)

export default enhancer(NotFoundProductModal)
