import React from 'react'
import {connect} from 'react-redux'
import {compose, withState, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import Modal from '../../share/Modal'
import CoverageTable from './CoverageTable'
import EditPortDetail from './EditPortDetail'
import Eva from './EvaluateAgent/index'
import ShowEva from './EvaluateAgent/ShowEvaluateAgent'
import {responsive} from '../../../core/style'
import {
  getEvaluateData,
  getAgentData,
  setLoading,
  getOptionsPort,
} from '../../../ducks/profile'
import {getCoverageTable} from '../../../ducks/product/'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const TableLayout = styled.div`
  overflow: auto;
  width: 100%;
`

const HeaderTabContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #ffb933;
  justify-content: center;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  font-size: 20px;
  font-weight: bold;
`

const SubHeaderTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff6e4;
  width: 100%;
  height: 30px;
`

const TextHeaderTab = styled.div`
  font-size: 14px;
  font-weight: ${props => (props.isActive ? 'bold' : 'unset')};
  cursor: pointer;
`
const ContentContainer = styled.div`
  width: 100%;
`

const Table = styled.div`
  padding: 30px 26px 33px;
`

const Field = styled.div`
  padding: 30px 0 13px 0;
`
const CustomModal = styled(Modal)`
  width: 600px;

  @media only screen and (max-width: 990px) {
    &.ant-modal {
    }
    .ant-modal-content {
      width: 600px;
      margin: 0px calc((100vw - 600px) / 2);
    }
  }

  ${props =>
    responsive.mobile(css`
      &.ant-modal {
      }
      .ant-modal-content {
        width: 320px;
        margin: 0px calc((100vw - 340px) / 2);
      }
    `)};
`

const premiumPay = [
  {label: 'ไม่ระบุ', value: ''},
  {label: '1 ปี', value: '1'},
  {label: 'ไม่เกิน 5 ปี', value: '5'},
  {label: '6 - 10 ปี', value: '6-10'},
  {label: '11 - 15 ปี', value: '11-15'},
  {label: '16 - 20 ปี', value: '16-20'},
  {label: '20 ปีขึ้นไป', value: '20'},
]

const InsurancePortModal = ({
  isShowModal,
  setIsShowModal,
  isActive,
  setActive,
  fullBenefit,
  getCoverageTable,
  id,
  getEvaluateData,
  portData,
  getAgentData,
  getOptionsPort,
  status,
}) => (
  <CustomModal
    isShowModal={isShowModal}
    setIsShowModal={setIsShowModal}
    id={id}
    width="600px">
    <Container>
      <HeaderTabContainer>กรมธรรม์ที่ถืออยู่</HeaderTabContainer>
      <SubHeaderTabContainer>
        <TextHeaderTab
          onClick={() => {
            setActive(0)
            getOptionsPort()
          }}
          isActive={isActive === 0}>
          แก้ไขรายละเอียด
        </TextHeaderTab>
        <TextHeaderTab
          onClick={() => {
            getCoverageTable(
              portData.insurancePolicyId,
              portData.age,
              portData.gender,
              portData.sumInsured,
            ),
              setActive(1)
          }}
          isActive={isActive === 1}>
          ดูตารางความคุ้มครอง
        </TextHeaderTab>
        <TextHeaderTab
          onClick={() => {
            getAgentData(id)
            getEvaluateData(id)
            setActive(2)
          }}
          isActive={isActive === 2}>
          ติดต่อตัวแทน
        </TextHeaderTab>
      </SubHeaderTabContainer>
      {isActive === 0 && (
        <Field>
          <EditPortDetail
            portData={portData}
            form={`editPortDetail${id}`}
            status={status}
          />
        </Field>
      )}

      {isActive === 1 && (
        <TableLayout>
          <Table>
            <CoverageTable fullBenefit={fullBenefit} />
          </Table>
        </TableLayout>
      )}

      <ContentContainer />

      <ContentContainer>
        {isActive === 2 ? <Eva id={id} /> : ''}
      </ContentContainer>
    </Container>
  </CustomModal>
)

const enhancer = compose(
  withState('isActive', 'setActive', 0),
  connect(
    state => ({
      fullBenefit: state.product.fullBenefit,
    }),
    {
      getCoverageTable,
      getEvaluateData,
      getAgentData,
      getOptionsPort,
    },
  ),
)
export default enhancer(InsurancePortModal)
