import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'
import {reduxForm, reset, formValueSelector} from 'redux-form'
import withSEO from '../../../share/withSEO'

import SearchPolicy from './SearchPolicy'
import Button from '../../../share/Button'
import Field from '../../../share/Field'
import {
  displayNumber,
  numberParser,
  inRange,
  url2Obj,
  obj2Url,
} from '../../../../core/helper'
import validate from './validate'
import PersonalForm from '../../../share/PersonalForm'
import {savePlanning} from '../../../../ducks/product'
import {responsive} from '../../../../core/style'

const Container = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
`

const CustomButton = styled(Button)`
  width: 150px;
  height: 30px;
  font-size: 12px;
  margin-bottom: 33px;
  margin-top: 22px;
`

const CustomContainerPersonalForm = styled(PersonalForm)`
  flex-direction: column;
  margin-top: 23px;
  margin-bottom: 0;
  .property {
    margin-bottom: 35px;
  }
  .gender {
    .label {
      min-width: unset;
    }
  }
  .age {
    width: 100%;
    > div {
      width: unset;
    }
  }

  ${props =>
    responsive.mobile(css`
      margin-top: 15px;

      .property {
        margin-bottom: 0;
      }
      .gender {
        margin-bottom: 30px;
    }
    .age {
    width: 100%;
    > div {
      width: 280px;
    }
  }

    input{
      width: 280px;
    }
  }

    `)};
`

const Radios = styled(Field)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;

  .input-radios-container > .ant-radio-group {
    justify-content: center;
    flex-wrap: unset;
    .ant-radio-wrapper {
      margin-right: 30px;
      margin-left: 30px;
    }
  }

  ${props =>
    responsive.mobile(css`
      .input-radios-container {
        justify-content: center;
      }

      .input-radios-container > .ant-radio-group {
        flex-direction: column;
        height: 100%;
        width: unset;
        .ant-radio-wrapper {
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 17px;
          width: 100%;
        }
      }
    `)};
`

const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: unset;

  margin: 0;
  margin-left: 20px;
  .label {
    margin: 0;
    margin-right: 25px;
    min-width: 0;
  }
  .input-text-container {
    width: unset;
  }

  input {
    width: 180px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin-left: 0;
      .label {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
        justify-content: flex-start;
      }
      input {
        width: 280px;
      }
    `)};
`

const LongTextInput = styled(TextInput)`
  margin-left: 0;
  margin-right: 30px;
  .input-selector-container {
    width: 300px;
    text-align: center;
  }

  .Select-menu-outer {
    z-index: 999 !important;
  }
  ${props =>
    responsive.mobile(css`
      margin-bottom: 23px;
      margin-right: 0;
      flex-direction: column;
      .label {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
        justify-content: flex-start;
      }
      .input-selector-container {
        width: 280px;
      }
    `)};
`

const SmallTextInput = styled(LongTextInput)`
  input {
    width: 100px;
  }

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      flex-direction: column;
      .label {
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
        justify-content: flex-start;
      }
      input {
        width: 280px;
        text-align: left;
      }
    `)};
`

const Unit = styled.span`
  font-size: 16px;
  margin-left: 22px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const FieldContainerTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  margin-top: 30px;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin: 0 77px 0 0;

  ${props =>
    responsive.mobile(css`
      margin: 30px 0 30px 0;
    `)};
`

const date = new Date()

export const LifeAdvance = ({
  handleSubmit,
  target,
  reset,
  savePlanning,
  companyIds,
  onSubmit,
  level,
}) => (
  <Container
    onSubmit={
      onSubmit ||
      handleSubmit(value => {
        savePlanning(
          {
            age: value.age,
            gender: value.gender,
            user_level: 'advance',
            target: value.target,
            life_insurance_policy_id: value.lifeInsurancePolicyId,
            sum_insurance: value.searchValue,
            buy_year: value.year,
          },
          'advance',
          'life',
        )
        window.open(
          `/smart/life/advance/product?${obj2Url({
            ...value,
            searchBy: 'sum_insured',
            sortBy: 'max_noon_score',
            companyIds: companyIds,
            level: level,
          })}`,
          '_self',
        )
      })
    }>
    <Header>มาดูกันว่าประกันของคุณเจ๋งแค่ไหน</Header>
    <Radios
      name="target"
      type="radios"
      options={[
        {label: 'ถือแบบประกันนี้อยู่', value: 'holding'},
        {label: 'สนใจอยากทำแบบประกันนี้', value: 'interest'},
      ]}
    />
    <FieldContainerTop>
      <SearchPolicy />
      {target === 'holding' && (
        <SmallTextInput
          name="buyYear"
          label="ปีที่ซิ้อ"
          type="text"
          placeholder="ปีที่ซื้อ พ.ศ."
          normalize={inRange(0, date.getFullYear() + 543)}
          parse={numberParser}
        />
      )}
    </FieldContainerTop>
    <CustomContainerPersonalForm />
    <FieldContainer>
      <TextInput
        name="searchValue"
        label="ทุนประกันที่สนใจ"
        type="text"
        placeholder="0"
        format={displayNumber}
        parse={numberParser}
      />
      <Unit>บาท</Unit>
    </FieldContainer>
    <CustomButton htmlType="submit" icons={['fa', 'search']}>
      ค้นหาแบบประกัน
    </CustomButton>
  </Container>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  withSEO(
    'noon Smart Life - ค้นหาประกันชีวิตที่เหมาะสมสำหรับคุณ',
    'noon Smart-Life ค้นหาแบบประกันชีวิตง่ายๆ รายละเอียดครบถ้วน ตามวัตถุประสงค์ที่คุณต้องการ หมดปัญหากับข้อมูลที่ไม่ครบถ้วน เรามีระบบที่ช่วยบอกรายละเอียดเพื่อใช้เป็นเครื่องมือในการหาข้อมูล ก่อนการตัดสินใจซื้อประกันชีวิต เพื่อความคุ้มค่าและความคุ้มครองที่สูงสุด',
    'เครื่องมือค้นหาประกันชีวิต วางแผนประกันชีวิต เลือกซื้อประกันชีวิต,ประกันชีวิต,สะสมทรัพย์,ตลอดชีพ,บำนาญ,ชั่วระยะเวลา,เปรียบเทียบประกันชีวิต',
  ),
  withState('timeOut', 'initTimeOut', null),
  connect(
    (state, props) => ({
      initialValues: {
        target: 'holding',
        weightType: 1,
        ...url2Obj(props.location.search),
        lifeInsurancePolicyId: url2Obj(props.location.search)
          .lifeInsurancePolicyId,
      },
      target: selector(state, 'target'),
      companyIds: url2Obj(props.location.search).companyIds,
    }),
    {reset, savePlanning},
  ),
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  }),
)

export default enhancer(LifeAdvance)
