import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import HeaderDetail from '../HeaderDetail'
// import FooterDetail from '../FooterDetail'
import HealthHS from './HealthHS'
import HealthCI from './HealthCI'
import Button from '../../../../share/Button'
import TableLife from '../Life/TableLife'
import Modal from '../../../../share/Modal'
import { displayNumber } from '../../../../../core/helper'
import { responsive } from '../../../../../core/style'
import RiderCard from '../RiderCard'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
const HeaderBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(106, 148, 203, 0.2);
  height: 40px;
  width: 100%;
  margin: 10px 0;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 666px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  ${props =>
    responsive.mobile(css`
      max-width: 280px;
    `)};
`

const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 666px;
  width: 100%;
  font-size: 16px;
  ${props =>
    responsive.mobile(css`
      max-width: 280px;
      padding: 10px 0;
    `)};
`
const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  ${props =>
    responsive.mobile(css`
      max-width: 150px;
      height: unset;
      align-items: unset;
      line-height: 24px;
      text-align: left;
    `)};
`

const DetailRight = styled(Detail)`
  ${props =>
    responsive.mobile(css`
      text-align: right;
      max-width: 130px;
    `)};
`

const ContainerButtonDetail = styled(DetailContainer)`
  ${props =>
    responsive.mobile(css`
      display: flex;
      justify-content: center;
    `)};
`

const LifeButton = styled(Button)`
  font-size: 12px;
  width: 160px;
`

const HealthButton = styled(LifeButton)`
  width: 200px;
`

const CustomModalLife = styled(Modal)`
  .ant-modal-content {
    padding: 20px;
  }
`

const CustomModalHalth = styled(Modal)`
  .ant-modal-content {
    padding: 40px 0;
  }
  @media (max-width: 320px) {
    .ant-modal-content{
      margin: 0 !important;
    }
    &.ant-modal {
      margin-left: 10px;
      margin-right: 0;
    } 
  }
`

const DetailBody = ({
  selectedLife,
  setSelectedLife,
  selectedHealth,
  setSelectedHealth,
  isShowModalLife,
  setIsShowModalLife,
  isShowModalHealth,
  setIsShowModalHealth,
  item,
  category,
  params,
}) => (
    <Container>
      <RiderCard policies={item.policies || []} params={params} />
      <HeaderBackground>
        <HeaderContainer>รายละเอียด</HeaderContainer>
      </HeaderBackground>
      <DetailContainer>
        <Detail>ชื่อบริษัท</Detail>
        <DetailRight>{item.companyName || '-'}</DetailRight>
      </DetailContainer>
      <DetailContainer>
        <Detail>ประเภท</Detail>
        <DetailRight>
          {item.category === 'non_life'
            ? 'ซื้อเฉพาะประกันสุขภาพ'
            : 'ซื้อประกันสุขภาพพร้อมประกันชีวิต'}
        </DetailRight>
      </DetailContainer>
      <DetailContainer>
        <Detail>เบี้ยประกันรวม</Detail>
        <DetailRight>
          {displayNumber(parseInt(item.totalPremium))} บาท/ปี
      </DetailRight>
      </DetailContainer>
      {item.category === 'life' && (
        <React.Fragment>
          <HeaderDetail
            selected={selectedLife}
            setSelected={setSelectedLife}
            header={'ประกันชีวิต'}
          />
          {selectedLife && (
            <React.Fragment>
              <DetailContainer>
                <Detail>ชื่อบริษัท</Detail>
                <Detail>-</Detail>
              </DetailContainer>
              <DetailContainer>
                <Detail>แบบประกันชีวิต</Detail>
                <Detail>-</Detail>
              </DetailContainer>
              <DetailContainer>
                <Detail>เบี้ยประกันชีวิต</Detail>
                <Detail>-</Detail>
              </DetailContainer>
              <DetailContainer>
                <Detail>ระยะเวลาจ่ายเบี้ยประกันชีวิต</Detail>
                <Detail>-</Detail>
              </DetailContainer>
              <DetailContainer>
                <Detail>ทุนประกันชีวิต</Detail>
                <Detail>-</Detail>
              </DetailContainer>
              <DetailContainer>
                <Detail>ระยะเวลาคุ้มครอง</Detail>
                <Detail>-</Detail>
              </DetailContainer>
              <DetailContainer>
                <Detail> </Detail>
                <Detail>
                  <LifeButton
                    onClick={() => setIsShowModalLife(true)}
                    icons={['fa', 'search']}>
                    ดูตารางผลประโยชน์
                </LifeButton>
                </Detail>
                <CustomModalLife
                  isShowModal={isShowModalLife}
                  setIsShowModal={setIsShowModalLife}
                  width={'800px'}>
                  <TableLife />
                </CustomModalLife>
              </DetailContainer>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <HeaderDetail
        selected={selectedHealth}
        setSelected={setSelectedHealth}
        header={
          item.policies && item.policies[0].subType === 'hs'
            ? 'ประกันสุขภาพ (HS)'
            : 'ประกันสุขภาพ (CI)'
        }
      />
      {selectedHealth && (
        <React.Fragment>
          <DetailContainer>
            <Detail>ชื่อบริษัท</Detail>
            <DetailRight>{item.companyName || '-'}</DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>แบบประกันสุขภาพ</Detail>
            <DetailRight>
              {(item.policies && item.policies[0].name) || '-'}
            </DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>เบี้ยประกันสุขภาพ</Detail>
            <DetailRight>
              {`${displayNumber(parseInt(item.totalPremium))} บาท/ปี` || '-'}
            </DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>ระยะเวลาจ่ายเบี้ยประกันสุขภาพ</Detail>
            <DetailRight>
              {(item.policies && item.policies[0].payPeriod) || '-'}
            </DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>วงเงินคุ้มครอง</Detail>
            <DetailRight>
              {(item.policies &&
                `${displayNumber(
                  parseInt(item.policies[0].sumInsuredDescription),
                )} บาท/ปี`) ||
                '-'}
            </DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>ระยะเวลาคุ้มครอง</Detail>
            <DetailRight>
              {(item.policies && item.policies[0].protectionPeriod) || '-'}
            </DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>ค่าห้อง</Detail>
            <DetailRight>
              {`${displayNumber(parseInt(item.totalRoom))} บาท/คืน` || '-'}
            </DetailRight>
          </DetailContainer>
          <DetailContainer>
            <Detail>คุ้มครองโรค</Detail>
            <DetailRight>
              {displayNumber(parseInt(item.totalCi)) || '-'}
            </DetailRight>
          </DetailContainer>
          <ContainerButtonDetail>
            <Detail> </Detail>
            <Detail>
              <HealthButton
                onClick={() => setIsShowModalHealth(true)}
                icons={['fa', 'search']}>
                ดูรายละเอียดความคุ้มครอง
            </HealthButton>
            </Detail>
            <CustomModalHalth
              isShowModal={isShowModalHealth}
              setIsShowModal={setIsShowModalHealth}
              width={'1000px'}>
              {item.policies && item.policies[0].subType === 'hs' ? (
                <HealthHS
                  item={item.policies && item.policies[0].protectionDetail}
                />
              ) : (
                  <HealthCI item={item.totalDiseases} />
                )}
            </CustomModalHalth>
          </ContainerButtonDetail>
        </React.Fragment>
      )}
      {/* <FooterDetail category={category} /> */}
    </Container>
  )

const enhancer = compose(
  withState('selectedLife', 'setSelectedLife', true),
  withState('selectedHealth', 'setSelectedHealth', true),
  withState('isShowModalLife', 'setIsShowModalLife', false),
  withState('isShowModalHealth', 'setIsShowModalHealth', false),
)

export default enhancer(DetailBody)
