import React from 'react'
import styled from 'react-emotion'
import {InputNumber} from 'antd'

const CustomField = styled(InputNumber)`
  width: 100%;
  height: auto;

  border: ${props =>
    props.disabled ? '1px solid rgba(255,212,130,0.5)' : '1px solid #ffd482'};
  border-radius: 5px;

  color: black;

  > .ant-input-number-handler-wrap {
    display: none;
  }

  > .ant-input-number-input-wrap {
    > input.ant-input-number-input {
      height: 40px;
      padding: 0 10px;
      text-align: center;

      ::-webkit-input-placeholder {
        color: black;
        opacity: 0.6;
      }
      :-ms-input-placeholder {
        color: black;
        opacity: 0.6;
      }
      ::-moz-placeholder {
        color: black;
        opacity: 0.6;
      }
      :-moz-placeholder {
        color: black;
        opacity: 0.6;
      }
    }
  }

  :hover,
  :focus {
    border: ${props =>
      props.disabled ? '1px solid rgba(255,212,130,0.5)' : '1px solid #ffd482'};
    box-shadow: none;
    outline: none;
  }
`

const Currency = props => <CustomField {...props} />

export default Currency
