import React from 'react'
import styled from 'react-emotion'

import {
  Form,
  Topic,
  ButtonContainer,
  PreviousButton,
  SubmitButton,
} from './share'
import {TOPIC} from './utils'
import {displayNumber} from '../../../../core/helper'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
  padding: 0 20px;

  text-align: left;
`

const Text = styled.div`
  margin-bottom: 20px;
`

const Label = styled.h1`
  font-size: 16px;
  color: ${props => props.theme.GREY80};
  line-height: 24px;
`

const Value = styled.h2`
  font-size: 16px;
  color: ${props => props.theme.BLUE};
  line-height: 19px;
`

const CustomSubmitButton = styled(SubmitButton)`
  > svg.icon {
    transform: rotate(0deg);
  }
`

const findValueFromOption = (arr, value) => {
  var found
  arr.forEach(option => {
    if (option.value === value) {
      found = option.label
    }
  })
  return found
}

const showValue = (formValues, key, ...args) => {
  switch (key) {
    case 'investmentRisk':
      return findValueFromOption(args[0], formValues['investmentRisk'])
    case 'hasSocialSecurity':
      return findValueFromOption(args[1], formValues['hasSocialSecurity'])
    case 'hospitalId':
      return findValueFromOption(args[2], formValues['hospitalId'])
    default:
      return displayNumber(formValues[key])
  }
}

const Summary = ({
  onSubmit,
  previousPage,
  formValues,
  investmentRiskOptions = [],
  hasSocialSecurity = [],
  hospitalsOptions = [],
}) => (
  <Form>
    <Topic>ประเมินความคุ้มครองที่แนะนำ</Topic>
    <Wrapper>
      {TOPIC.map(topic => (
        <Text key={topic.key}>
          <Label>{topic.label}</Label>
          <Value>
            {showValue(
              formValues,
              topic.key,
              investmentRiskOptions,
              hasSocialSecurity,
              hospitalsOptions,
            )}
          </Value>
        </Text>
      ))}
    </Wrapper>
    <ButtonContainer>
      <PreviousButton onClick={previousPage} icons={['fas', 'angle-left']}>
        ย้อนกลับ
      </PreviousButton>
      <CustomSubmitButton onClick={onSubmit} icons={['fas', 'check-circle']}>
        ยืนยัน
      </CustomSubmitButton>
    </ButtonContainer>
  </Form>
)

export default Summary
