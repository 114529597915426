import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withState} from 'recompose'
import {reduxForm} from 'redux-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import jump from 'jump.js'
import withSEO from '../share/withSEO'

import iphoneCar from '../../static/images/iPhoneXS-Car-Noon.jpg'
import fintechLogo from '../../static/images/Fintech Logo.svg'
import OICLogo from '../../static/images/OIC logo.svg'
import appStore from '../../static/images/app-store.png'
import Button from '../share/Button'
import CustomField from '../share/Field'
import oic from '../../static/images/OIC-01.svg'
import {responsive} from '../../core/style'

import Content from './Content'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const IphoneCarImage = styled.div`
  position: relative;
  background-image: url(${iphoneCar});
  width: 100%;
  height: calC(100vh - 80px);
  background-size: cover;
  overflow: hidden;
  background-position-x: center;
`

const AppstoreContainer = styled.div`
  position: absolute;
  top: 23vh;
  left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  ${props =>
    responsive.tablet(css`
      display: none;
    `)};
`
const AppStoreImage = styled.img`
  width: 169px;
  height: 121px;
`

const AppStoreIcon = styled(FontAwesomeIcon)`
  font-size: 32px;
  color: #ffd900;
  margin-right: 12px;
`

const AppStoreWording = styled.div`
  font-size: 24px;
  color: white;
  padding-right: 10px;
  &.heading {
    display: flex;
    align-items: center;
    color: #ffd900;
    font-weight: bold;
    font-size: 32px;
  }
`

const RegisterContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100vw;
  display: flex;
  height: 244px;
  padding-top: 70px;
  background-image: linear-gradient(
    rgba(53, 74, 102, 0),
    rgba(66, 92, 126, 0.6),
    rgba(79, 111, 152, 0.8),
    rgba(106, 148, 203, 1)
  );

  ${props =>
    responsive.mobile(css`
      padding-top: 0;
    `)};
`

const FintechContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 5vh;
  right: ${props => (props.isHover ? '0' : '-150px')};
  width: 300px;
  height: 160px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px 0 0 10px;
  transition: 0.5s;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;

  ${props =>
    responsive.mobile(css`
      top: 1vh;
      height: 110px;
      width: 250px;
    `)};
`

const OICContainer = styled(FintechContainer)`
  top: calc(5vh + 170px);

  ${props =>
    responsive.mobile(css`
      top: calc(1vh + 120px);
    `)};
`

const FintechLogo = styled.img`
  width: 110px;
  ${props =>
    responsive.mobile(css`
      width: 80px;
    `)};
`

const CustomText = styled.span`
  font-size: 12px;
`

const FintechText = styled.div`
  width: 160px;
  font-size: 16px;
  padding-left: 10px;
  opacity: ${props => (props.isHover ? '1' : '0')};
  transition: 0.5s;
`

const RegisterText = styled.div`
  font-size: fit-content;
  color: white;
  margin-bottom: 15px;

  ${props =>
    responsive.mobile(css`
      max-width: 146px;
      text-align: center;
      text-indent: 3px;
    `)};
`

const GoogleInput = styled(CustomField)`
  flex-direction: column;
  justify-content: center;
  margin: unset;
  .input-googleInput-container {
    input {
      width: 200px;
      height: 30px;
      border: none;
      margin: 0 10px;
      :focus ~ label,
      :valid ~ label {
        color: #093771;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
      }
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 20px;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      justify-content: center;
      margin: 7.5px;

      .input-googleInput-container {
        input {
          width: 280px;
        }
      }
    `)};
`

const RegisterInputContainer = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin-bottom: 30px;
    `)};
`

const CustomButton = styled(Button)`
  width: 150px;
  height: 30px;
  margin-left: 10px;
  span {
    font-size: 12px;
  }

  ${props =>
    responsive.mobile(css`
      margin-top: 7.5px;
    `)};
`

const ChevronDownIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  cursor: pointer;
  color: #ffd900;
  opacity: 0.8;
  margin-top: 10px;
`

const RegisterFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  background-color: #6a94cb;
  margin-top: 40px;

  ${props =>
    responsive.mobile(css`
      height: 100%;
    `)};
`

const RegisterFooterTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const RegisterFooterText = styled.div`
  color: white;

  &.percent {
    font-size: 60px;
    margin-right: 21px;
    font-weight: bold;
  }
  &.interest {
    font-size: 24px;
    margin-right: 27px;
  }
  &.survey {
    font-style: italic;
    font-size: 16px;
    color: #e5e5e5;
  }

  ${props =>
    responsive.mobile(css`
      &.percent {
        margin-right: 0;
      }
      &.interest {
        margin-right: 0;
      }
    `)};
`
const Oic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  width: 100%;
  height: 80px;
  background-color: #e5e5e5;
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      padding: 0 20px;
    `)};
`

const Img = styled.img`
  width: 170px;
  height: 125px;
  margin-right: 20px;

  ${props =>
    responsive.mobile(css`
      width: 135px;
    `)};
`

const NoonLab = ({
  isHoverFinTech,
  setHoverFinTech,
  isHoverOic,
  setHoverOic,
}) => (
  <Container>
    <IphoneCarImage>
      <AppstoreContainer>
        <AppStoreWording className="heading">
          <AppStoreIcon id="content" icon={['fas', 'cloud-download-alt']} />
          โหลดแอพนี้
        </AppStoreWording>
        <AppStoreWording>ขับรถดีมีรางวัล</AppStoreWording>
        <AppStoreImage src={appStore} />
      </AppstoreContainer>
      <FintechContainer
        isHover={isHoverFinTech}
        onMouseOver={() => setHoverFinTech(true)}
        onMouseOut={() => setHoverFinTech(false)}>
        <FintechLogo src={fintechLogo} />
        <FintechText isHover={isHoverFinTech}>
          <CustomText>ได้รับรางวัล</CustomText> Rising Star FinTech 2017
        </FintechText>
      </FintechContainer>
      <OICContainer
        isHover={isHoverOic}
        onMouseOver={() => setHoverOic(true)}
        onMouseOut={() => setHoverOic(false)}>
        <FintechLogo src={OICLogo} />
        <FintechText isHover={isHoverOic}>
          ภายใต้การกำกับดูแลของ Insurance Regulatory Sandbox
        </FintechText>
      </OICContainer>
      <RegisterContainer>
        <RegisterText>ทดลองใช้ก่อนใคร ลงทะเบียนล่วงหน้าที่นี่</RegisterText>
        <RegisterInputContainer>
          <GoogleInput name="name" type="googleInput" placeholder="ชื่อ" />
          <GoogleInput name="email" type="googleInput" placeholder="อีเมล" />
          <CustomButton id="lab-register-header" icons={['fa', 'pen']}>
            ลงทะเบียนล่วงหน้า
          </CustomButton>
        </RegisterInputContainer>
        <ChevronDownIcon
          id="contents"
          icon={['fas', 'angle-down']}
          onClick={() => jump('#contents')}
        />
      </RegisterContainer>
    </IphoneCarImage>
    <Content />
    <RegisterFooterContainer>
      <RegisterFooterTextContainer>
        <RegisterFooterText className="percent">85%</RegisterFooterText>
        <RegisterFooterText className="interest">
          อยากใช้แอพฯ ของเรา
        </RegisterFooterText>
        <RegisterFooterText className="survey">
          *จากแบบสำรวจ Thailand Startup 2017
        </RegisterFooterText>
      </RegisterFooterTextContainer>
      <RegisterInputContainer>
        <GoogleInput name="name" type="googleInput" placeholder="ชื่อ" />
        <GoogleInput name="email" type="googleInput" placeholder="อีเมล" />
        <CustomButton id="lab-register-footer" icons={['fa', 'pen']}>
          ลงทะเบียนล่วงหน้า
        </CustomButton>
      </RegisterInputContainer>
    </RegisterFooterContainer>
    <Oic>
      <Img src={oic} />
      ภายใต้การดูแลของ Insurance Regulatory Sandbox
    </Oic>
  </Container>
)

const enhancer = compose(
  withSEO(
    'นวัตกรรมทางด้านประกันภัยเพื่อตอบโจทย์ ไลฟ์สไตล์ของพฤติกรรมผู้บริโภคในยุคดิจิตัล',
    'noon Lab นวัตกรรมทางด้านประกันภัยเพื่อผู้บริโภคยุคดิจิตัล ด้วยเทคโนโลยี ผสานกับการวิเคราะห์ความต้องการในการออกแบบแบบประกันภัยที่ตอบโจทย์ผู้บริโภคมากยิ่งขึ้น',
    'เทคโนโลยี,ประกวด,รางวัล,ชนะ,อันดับหนึ่ง,นวัตกรรม,UBI,usage based insurance,พฤติกรรม,วิเคราะห์,ผู้บริโภค,ใหม่,Fintech Challenge,insuretech,ขับดี จ่ายน้อย',
  ),
  withState('isHoverFinTech', 'setHoverFinTech', false),
  withState('isHoverOic', 'setHoverOic', false),
  reduxForm({
    form: 'Lab',
  }),
)
export default enhancer(NoonLab)
