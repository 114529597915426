import {compose, withHandlers, defaultProps} from 'recompose'
import CustomSelect from '../../share/Field/Selector'
import {withField, withCaption, omitProps} from '../enhancers'
import styled from 'react-emotion'

const Dropdown = styled(CustomSelect)`
  width: 168px;
  text-align: center;

  > .Select-control {
    > .Select-multi-value-wrapper {
      > .Select-input {
        left: 0;
      }
    }
  }
`

const enhance = compose(
  withField(),
  withCaption(),
  defaultProps({
    searchable: false,
    themeColor: 'orange',
    multi: false,
  }),
  withHandlers({
    onChange: props => value => {
      props.onChange && props.onChange(value && value.value)
      props.onBlur && props.onBlur(value && value.value)
    },
  }),
  omitProps('onBlur'),
)

export default enhance(Dropdown)
