import React, {Fragment} from 'react'
import {compose, withProps, withState} from 'recompose'

import {displayNumber} from '../../../../core/helper'
import ModalFavorite from '../../../share/ModalFavorite'
import ModalCatalogue from '../ModalCatalogue'
import {pathUrlHealth} from './Hs'
import {
  Layout,
  ActionWrapper,
  HeartIcon,
  DescriptionWrapper,
  Body,
  Title,
  Logo,
  CompanyLogo,
  WrapperTitle,
  ProductName,
  CompanyName,
  RowInfo,
  Content,
  ContentRegular,
  Premium,
  Footer,
  CustomTagA,
  CustomButton,
} from './Life'

const Ci = props => (
  <Layout>
    {props.tag ? <Fragment>{props.tag}</Fragment> : null}

    <ActionWrapper>
      {props.SpecialActionComponent ? (
        <props.SpecialActionComponent {...props} />
      ) : (
        <div />
      )}

      {/* <HeartIcon
        onClick={() => {
          props.isLogin
            ? props.onFavorite(
                !props.localFavorite,
                props.data.id,
                'life',
                props.data.paramsEncode,
              )
            : props.setIsShowModalFavorite(!props.isShowModalFavorite)
        }}
        icon={props.localFavorite ? ['fas', 'heart'] : ['far', 'heart']}
      /> */}
    </ActionWrapper>

    <DescriptionWrapper>
      <Body>
        <Title>
          <Logo>
            <CompanyLogo src={props.data.companyOfficialLogoUrl} />
          </Logo>
          <WrapperTitle>
            <ProductName>{props.data.policies[0].name}</ProductName>
            <CompanyName>{props.data.companyName}</CompanyName>
          </WrapperTitle>
        </Title>

        <RowInfo>
          <Content>เบี้ยประกัน ณ ปีปัจจุบัน</Content>
          <Premium>
            <span>{displayNumber(parseInt(props.data.totalPremium))}</span>
            &nbsp;&nbsp;บาท/ปี
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>เสียชีวิตจากโรคร้ายแรง</Content>
          <ContentRegular>
            {`${displayNumber(props.data.totalDeadBenefit)} บาท/ปี`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>
            ผลประโยชน์ระยะ
            <br />
            เริ่มต้น-ปานกลาง
          </Content>
          <ContentRegular>
            {`${displayNumber(props.data.totalBeginingMiddleBenefit)} บาท`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ผลประโยชน์ระยะรุนแรง</Content>
          <ContentRegular>
            {`${displayNumber(props.data.totalSevereBenefit)} บาท/คืน`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>จำนวนโรคที่คุ้มครอง</Content>
          <ContentRegular>
            {`${displayNumber(props.data.totalDiseasesCount)} โรค`}
          </ContentRegular>
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA href={props.pathUrl} target="_blank">
          <CustomButton type="border" icons={['fa', 'search']}>
            รายละเอียด
          </CustomButton>
        </CustomTagA>
        <CustomButton
          onClick={() => {
            props.setIsShowModal(true)
          }}
          icons={['fa', 'shopping-cart']}>
          สนใจซื้อ
        </CustomButton>
      </Footer>
    </DescriptionWrapper>

    <ModalFavorite
      isShowModal={props.isShowModalFavorite}
      setIsShowModal={props.setIsShowModalFavorite}
      isReload
    />
    <ModalCatalogue
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      category="health"
      params={props.params}
      premium={props.data.policies[0].premium}
      id={props.data.id}
      packageId={props.data.packageId}
      paramsEncode={props.data.paramsEncode}
      productName={props.data.policies[0].name}
    />
  </Layout>
)

const enhance = compose(
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withState('isShowModal', 'setIsShowModal', false),
  withProps(props => {
    // let totalBenefits = props.data.policies[0].protectionDetail.general.find(
    //   item => item.key === 'total_benefits',
    // ).displayValue[0]

    return {
      pathUrl: `${pathUrlHealth(props)}`,
      // totalBenefits: totalBenefits,
    }
  }),
)
export default enhance(Ci)
