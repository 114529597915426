import styled from 'react-emotion'
import {Slider} from 'antd'

const CustomSlider = styled(Slider)`
  width: 100%;
  .ant-slider-handle {
    margin-top: -8px;
    width: 20px;
    height: 20px;
    border: unset;
    background-color: ${props => props.theme.BLUE};
    :focus {
      box-shadow: none;
    }
  }
  .ant-slider-step {
    background-color: ${props => props.theme.LIGHTBLUE};
    border-radius: 5px;
    opacity: 0.8;
    height: 5px;
  }
`

export default CustomSlider
