import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {reduxForm, Field} from 'redux-form'
import {compose, lifecycle, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FieldNoon from '../../../share/Field'
import Button from '../../../share/Button'
import windowSize from '../../../../core/react-window-size'
import Modal from '../../../share/Modal'
import {setLoading} from '../../../../ducks/profile'
import {responsive} from '../../../../core/style'
import img1 from '../../../../static/images/review_1.svg'
import img2 from '../../../../static/images/review_2.svg'
import img3 from '../../../../static/images/review_3.svg'
import img4 from '../../../../static/images/review_4.svg'
import img5 from '../../../../static/images/review_5.svg'

const DesContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  width: 100%;
  height: 100%;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      width: 320px;
      margin-top: 28px;
      margin-bottom: 46px;
      padding: 18px;
      overflow-x: hidden;
    `)};
`

const TextHead = styled.div`
  font-size: 14px;
  margin-top: 28px;
  font-family: Prompt;
  font-weight: bold;
`

const TextAgent = styled.div`
  font-size: 14px;
`

const TextAgentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const AgentImage = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 20px;
  border-radius: 50%;
  object-fit: cover;
`

const NoonStarContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${props => (props.collapseActive ? '0' : 'fit-content')};
  transition: 0.5s;
`
const EmojiAllContainer = styled.div`
  padding: 0px 6px 6px 6px;
  margin-top: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 285px;

  ${props =>
    responsive.mobile(css`
      padding: 0px 24px;
    `)}
`

const FilterIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
`

const StarIcon = styled(FilterIcon)`
  color: ${props =>
    props.isSelected || props.isHoverStar ? '#ffd900' : '#e5e5e5'};

  :hover {
    color: #ffd900;
  }
`

const EmoIcon = styled.img`
  width: 49px;
  height: 49px;
  margin: 10px 16.12px 0px 0;

  opacity: ${props => (props.isSelected || props.isHoverStar ? '1' : '0.3')};

  ${props =>
    responsive.mobile(css`
      width: 42px;
      height: 42px;
    `)}
`

const NoonStarScoreContainer = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
`

const Hr = styled.div`
  border: 1px solid #e5e5e5;
  width: 320px;
  margin-top: 20.5px;
  @media only screen and (max-width: 320px) {
    width: 310px;
  }
`
const TextEmoji = styled.div`
  margin-top: 20.5px;
  font-size: 12px;
  display: flex;
  align-self: flex-start;
`
const TextEmojiFirst = styled.div`
  font-size: 12px;
  display: flex;
  align-self: flex-start;
`

const TextArea = styled(FieldNoon)`
  height: fit-content;
  width: 100%;
`

const EmojiContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 45px;
`

const SummitButton = styled(Button)`
  margin-top: 30px;
`
const GobackButton = styled(Button)`
  margin-top: 30px;
  margin-bottom: 46px;
`

const ContainerTextEmoji = styled.div`
  display: flex;
  flex-direction: row;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 6px;
  color: ${props => props.theme.PINKRED};
  margin-top: ${props =>
    props.Text == 'ความรวดเร็วในการติดต่อกลับ' ? '0px' : '20.5px'};
`
const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: fit-content;
`
const ContainerConfirm = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px;
  flex-direction: column;
`

const ContainerConfirmButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

const ConfirmText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const CustomModal = styled(Modal)`
  width: 150px;
  top: 300px;
  margin: 0px calc((100vw - 320px) / 2);

  ${props => responsive.mobile(css``)}

  .ant-modal {
    width: 150px;
  }
`

const noonstar = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}]

const Emoji = [
  {name: img1, id: 1, color: '#FA5562'},
  {name: img2, id: 2, color: '#FA834F'},
  {name: img3, id: 3, color: '#FBCF3F'},
  {name: img4, id: 4, color: '#9FD77A'},
  {name: img5, id: 5, color: '#6BCA6C'},
]

const StarEnhancer = compose(withState('hoveredStar', 'setHoveredStar', 0))
const EmoEnhancer = compose(withState('hoveredEmo', 'setHoveredEmo', 0))

const ConfirmDialogEnhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
)

const Star = StarEnhancer(
  ({input, hoveredStar, setHoveredStar, collapseActive, ...props}) => (
    <NoonStarContainer collapseActive={collapseActive}>
      <NoonStarScoreContainer>
        {noonstar.map((value, index) => (
          <StarIcon
            isHoverStar={index < hoveredStar}
            onClick={() => {
              input.onChange(value.id)
              props.setStep(2)
            }}
            onMouseOver={() => setHoveredStar(value.id)}
            onMouseOut={() => setHoveredStar(0)}
            isSelected={index < input.value}
            key={value.id}
            icon={['fas', 'star']}
          />
        ))}
      </NoonStarScoreContainer>
    </NoonStarContainer>
  ),
)

const Emo = EmoEnhancer(({input, hoveredEmo, setHoveredEmo, meta, Text}) => (
  <>
    <ContainerTextEmoji>
      {Text === 'ความรวดเร็วในการติดต่อกลับ' ? (
        <>
          <TextEmojiFirst>{Text}</TextEmojiFirst>
          {meta.error && meta.submitFailed && (
            <ExclamationCircle
              icon={['fas', 'exclamation-circle']}
              Text="ความรวดเร็วในการติดต่อกลับ"
            />
          )}
        </>
      ) : (
        <>
          <TextEmoji>{Text}</TextEmoji>
          {meta.error && meta.submitFailed && (
            <ExclamationCircle icon={['fas', 'exclamation-circle']} />
          )}
        </>
      )}
    </ContainerTextEmoji>
    <EmojiContainer>
      {Emoji.map((value, index) => (
        <EmoIcon
          src={value.name}
          isHoverStar={index + 1 == hoveredEmo}
          color={value.color}
          onClick={() => input.onChange(value.id)}
          onMouseOver={() => setHoveredEmo(value.id)}
          isSelected={index + 1 == input.value}
          onMouseOut={() => setHoveredEmo(input.value)}
        />
      ))}
    </EmojiContainer>
  </>
))

const validate = values => {
  const errors = {}

  if (!values.Activeness) {
    errors.Activeness = 'ต้องเลือกข้อมูลในช่องนี้'
  }
  if (!values.Informative) {
    errors.Informative = 'ต้องเลือกข้อมูลในช่องนี้'
  }
  if (!values.Professions) {
    errors.Professions = 'ต้องเลือกข้อมูลในช่องนี้'
  }
  if (!values.Convenience) {
    errors.Convenience = 'ต้องเลือกข้อมูลในช่องนี้'
  }
  if (!values.Impression) {
    errors.Impression = 'ต้องเลือกข้อมูลในช่องนี้'
  }
  if (!values.AfterSales) {
    errors.AfterSales = 'ต้องเลือกข้อมูลในช่องนี้'
  }

  return errors
}

const StepTwoCompose = compose(
  withState('value', 'setValue', []),
  connect(
    state => ({}),
    {setLoading},
  ),
  reduxForm({
    form: 'StepOneSelectAgent',

    validate,
  }),
  lifecycle({
    componentDidMount() {},
  }),
)

const ConfirmDialog = windowSize(
  ConfirmDialogEnhancer(({Submit, value, isShowModal, setIsShowModal}) => {
    return (
      <CustomModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        width="300px">
        <ContainerConfirm>
          <ConfirmText>ยืนยันข้อมูลใช่หรือไม่</ConfirmText>
          <br />
          <ContainerConfirmButton>
            <Button
              onClick={() => {
                setIsShowModal(false)
              }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsShowModal(false)
                Submit(value)
              }}>
              OK
            </Button>
          </ContainerConfirmButton>
        </ContainerConfirm>
      </CustomModal>
    )
  }),
)

const StepTwo = StepTwoCompose(
  ({
    setConfirm,
    confirm,
    isShowModal,
    setIsShowModal,
    agent,
    handleSubmit,
    value,
    setValue,
    id,
    ...props
  }) => (
    <DesContainer
      onSubmit={handleSubmit(value => {
        setIsShowModal(true)
        setValue({...value, vipMemberId: agent.vipMemberId, id: id})
      })}
      noValidate>
      <TextHead>ความคิดเห็นของคุณหลังจากได้รับการบริการ</TextHead>
      <AgentImage />
      <TextAgentContainer>
        <TextAgent>เลขประจำตัว {agent.vipMemberId}</TextAgent>

        <TextAgent>ชื่อ - สกุล {agent.name}</TextAgent>
      </TextAgentContainer>
      <Field component={Star} name="star" setStep={props.setStep} />
      <Hr />
      {props.Step == 2 && (
        <>
          <EmojiAllContainer>
            <div>
              <Field
                component={Emo}
                name="Activeness"
                Text="ความรวดเร็วในการติดต่อกลับ"
                hideError
              />

              <Field
                component={Emo}
                name="Informative"
                Text="การให้ข้อมูลครบถ้วน/ให้คำแนะนำเพิ่มเติม"
                hideError
              />

              <Field
                component={Emo}
                name="Professions"
                Text="ความเชี่ยวชาญ/มีความรู้ในผลิตภัณฑ์"
                hideError
              />

              <Field
                component={Emo}
                name="Convenience"
                Text="การอำนวยความสะดวกในเรื่องเอกสารและการชำระเงิน"
                hideError
              />

              <Field
                component={Emo}
                name="Impression"
                Text="ความประทับใจจากการขาย(ไม่ตื้อ ไม่ยัดเยียด)"
                hideError
              />

              <Field
                component={Emo}
                name="AfterSales"
                Text="การบริการหลังการขาย"
              />

              <TextEmoji>ความคิดเห็นเพิ่มเติม</TextEmoji>

              <TextArea type="textarea" name="Remark">
                dfdfds
              </TextArea>
            </div>
            <ContainerButton>
              <GobackButton
                onClick={() => {
                  props.setStep(0)
                }}>
                ย้อนกลับ
              </GobackButton>
              <SummitButton htmlType="submit">ยืนยัน</SummitButton>
            </ContainerButton>
          </EmojiAllContainer>

          <ConfirmDialog
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}
            confirm={confirm}
            setConfirm={setConfirm}
            value={value}
            Submit={props.Summit}
          />
        </>
      )}
      {props.Step == 1 ? (
        <GobackButton
          onClick={() => {
            props.setStep(0)
          }}>
          ย้อนกลับ
        </GobackButton>
      ) : (
        ''
      )}
    </DesContainer>
  ),
)

export default StepTwo
