import React from 'react'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 420px;
  margin: 0 10px 20px 10px;
  background-color: white;
  border: 1px solid rgba(127, 127, 127, 0.2);
  border-radius: 5px;
  min-width: 300px;
  margin-bottom: 20px;

  background-color: rgba(127, 127, 127, 0.2);
`
const NoProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > svg {
    color: #7f7f7f;
    font-size: 30px;
    opacity: 1;
    margin-bottom: 15px;
  }
  > div {
    text-align: center;
    color: #7f7f7f;
    opacity: 1;
  }
`

const NoProductCard = props => {
  return (
    <Layout>
      <FontAwesomeIcon
        icon={['fas', 'spinner']}
        spin
        hidden={!props.loading}
        style={{color: '#7f7f7f', fontSize: 25}}
      />
      <NoProduct hidden={props.loading}>
        <FontAwesomeIcon icon={['far', 'sad-tear']} style={{}} />
        <div>ยังไม่เจอประกัน</div>
        <div>ที่เหมาะกับคุณ</div>
      </NoProduct>
    </Layout>
  )
}

export default NoProductCard
