import {css} from 'react-emotion'
import {compose, withProps} from 'recompose'
import Chart from '../../NoonFund/components/Chart'
import {safe} from '../common/helper'
import {normalize} from 'path'

const AreaStyle = css`
  path {
    fill: rgba(38, 95, 167, 1) !important;
  }
`

const enhancer = compose(
  withProps(props => {
    const keyLabels = {
      lifeSumInsuranced: 'ชีวิต',
      decrepitudeSumInsuranced: 'ทุพพลภาพ',
      roomOrFoodSumInsuranced: 'ค่าห้อง/ ค่าอาหาร',
      totalMedicalFeeSumInsuranced: 'ค่ารักษาพยาบาล เหมาจ่าย',
      diseaseSumInsuranced: 'โรคร้ายแรง',
      accidentSumInsuranced: 'อุบัติเหตุ',
      outpationSumInsuranced: 'ผู้ป่วยนอก',
      compensationSumInsuranced: 'ค่าชดเชยรายได้',
    }

    const normalizedData = Object.keys(keyLabels).reduce((memo, key) => {
      const have = safe(() => parseFloat(props.data[key]) || 0, 0)
      const suggest = safe(() => parseFloat(props.data.recommend[key]) || 0, 0)

      let nomalizeValue = 0

      if (suggest > 0) {
        if (have < suggest) {
          nomalizeValue = (have * 100) / suggest
        } else if (have >= suggest) {
          nomalizeValue = 100
        }
      }

      return [...memo, nomalizeValue]
    }, [])

    const config = {
      chart: {
        polar: true,
        backgroundColor: 'transparent',
        width: 400,
      },
      title: {
        text: '',
      },
      pane: {
        size: '70%',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: Object.values(keyLabels),
        tickmarkPlacement: 'on',
        lineWidth: 0,
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 100,
      },

      tooltip: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      series: [
        {
          type: 'area',

          className: AreaStyle,
          data: normalizedData,
          pointPlacement: 'on',
          color: 'rgba(38, 95, 167, 1)',
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              width: 300,
              maxWidth: 300,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
              },
              pane: {
                size: '60%',
              },
            },
          },
        ],
      },
    }

    return {config}
  }),
)

export default enhancer(Chart)
