import React from 'react'
import styled from 'react-emotion'

import UserImage from '../../../static/images/userIcon.png'

import {phoneFormatter} from '../../../core/helper'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 0;
  padding: 30px 25px;

  border-radius: 5px;
  border: 1px solid #c1c1c1;
`

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${props => props.theme.BLUE};
  text-align: center;
`

const Avatar = styled.img`
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  margin: 20px auto 0;

  border-radius: 50%;
  object-fit: cover;
`

const Label = styled.label`
  margin-top: 15px;

  font-size: 12px;
  line-height: 24px;
  color: ${props => props.theme.GREY80};
`

const Value = styled.div`
  position: relative;

  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${props => props.theme.GREY80};
`

const ChangeTime = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;

  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: ${props => props.theme.GREY50};
  text-decoration: underline;

  cursor: pointer;
`

const Agent = ({tel, name, time, avatar, licenseNo, vipMemberId}) => (
  <Container>
    <Header>
      ตัวแทนประกัน
      <br />
      ที่จะทำการติดต่อกลับ
    </Header>
    <Avatar alt="agent" src={avatar || UserImage} />
    <Label>ชื่อ-สกุล</Label>
    <Value>{name || '-'}</Value>
    <Label>เบอร์ติดต่อ</Label>
    <Value>{phoneFormatter(tel) || '-'}</Value>
    <Label>รหัสตัวแทนประกัน</Label>
    <Value>{vipMemberId || '-'}</Value>
    <Label>เลขที่ใบอนุญาติ</Label>
    <Value>{licenseNo || '-'}</Value>
    <Label>ช่วงเวลาที่สะดวกให้ติดต่อกลับ</Label>
    <Value>{time ? `${time} น.` : '-'}</Value>
  </Container>
)

export default Agent
