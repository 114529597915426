import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import {reduxForm} from 'redux-form'
import CustomField from '../share/Field'
import Button from '../share/Button'
import {isPasswordNoon} from '../../core/helper'
import {changePassword, setDataProfile} from '../../ducks/profile'
import Loading from '../share/Loading'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`

const GoogleInput = styled(CustomField)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  font-size: 12px;
  margin-top: 20px;
`

const ChangePassword = ({
  handleSubmit,
  changePassword,
  errorMessage,
  reset,
  isLoading,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={handleSubmit(value => changePassword(value, reset))}
      noValidate>
      <Header>เปลี่ยนรหัสผ่าน</Header>
      <GoogleInput
        name="oldPassword"
        type="googleInput"
        mode="password"
        placeholder="รหัสผ่านเดิม"
        className="password"
        hideError
      />
      <GoogleInput
        name="newPassword"
        type="googleInput"
        mode="password"
        placeholder="รหัสผ่านใหม่"
        className="password"
        hideError
      />
      <GoogleInput
        name="newPasswordConfirmation"
        type="googleInput"
        mode="password"
        placeholder="ยืนยันรหัสผ่านใหม่"
        className="password"
        hideError
      />
      <CustomButton
        htmlType="submit"
        errorMessage={errorMessage}
        icons={['fa', 'check-circle']}>
        ยืนยัน
      </CustomButton>
    </Container>
  </Loading>
)

const validate = values => {
  const errors = {}

  if (!values.oldPassword) {
    errors.oldPassword = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPasswordNoon(values.oldPassword)) {
    errors.oldPassword = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  }

  if (!values.newPassword) {
    errors.newPassword = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPasswordNoon(values.newPassword)) {
    errors.newPassword = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  } else if (values.oldPassword === values.newPassword) {
    errors.newPassword = 'รหัสผ่านซ้ำกับรหัสผ่านเดิม'
  }

  if (!values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.newPasswordConfirmation !== values.newPassword) {
    errors.newPasswordConfirmation = 'รหัสผ่านไม่ตรงกัน'
  } else if (!isPasswordNoon(values.newPasswordConfirmation)) {
    errors.newPasswordConfirmation = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  }

  return errors
}

const enhancer = compose(
  connect(
    (state, props) => ({
      errorMessage: state.profile.errorMessage,
      isLoading: state.profile.isLoading,
    }),
    {changePassword, setDataProfile},
  ),
  reduxForm({
    form: 'changePassword',
    validate,
  }),
  lifecycle({
    componentDidMount() {
      const {setDataProfile} = this.props
      setDataProfile({errorMessage: ''})
    },
  }),
)

export default enhancer(ChangePassword)
