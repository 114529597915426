import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {compose, withProps, lifecycle, withHandlers, withState} from 'recompose'
import Modal from '../../../share/Modal'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import shield from '../../../../static/images/security-on.svg'
import {Button, Collapse} from '../../components'
import {navigate} from '@reach/router'
import {withAnswers} from '../../enhances'
import GraphImg from './GraphImg'
import moment from 'moment'

import {reduxForm} from 'redux-form'
import {toCurrency} from '../../core/helper'
import Field from '../../../share/Field'
import {
  phoneFormatter,
  formatParser,
  isPhoneNumber,
  isEmail,
} from '../../../../core/helper'
import {sendLeadPolicy} from '../../../../ducks/taxcal'
import itaxApi from '../../common/api'
import {httpPost, API_HOST} from '../../../../core/httpClient'
import numeral from 'numeral'

const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const CustomModalAntd = css`
  padding: 0 20px;
  width: 100%;
`
const Form = styled.form``
const Content = styled.div`
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: #333333;
  font-family: 'Prompt', 'Roboto', sans-serif;
  padding: 48px 9px 0 9px;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
`
const Mid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
`
const WrapTopInfo = styled.div`
  display: flex;
`
const Footer = styled.div`
  margin-bottom: 30px;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 2;
`
const Label = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`
const Value = styled.div`
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceValue = styled.div`
  font-size: 42px;
  color: #265fa7;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const SmallText = styled.div`
  font-size: 14px;
  color: #6a94cb;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceText = styled.div`
  color: #6a94cb;
  font-size: 16px;
  font-weight: 700;
`
const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Symbol = styled.div`
  font-size: 12px;
`
const Img = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 5px;
`
const Shield = styled.img`
  width: 50px;
  height: 50px;
`
const WrapText = styled.div`
  padding: 0 10px;
`
const MoneySymbol = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #265fa7;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 3px;
  margin-right: 5px;
`
const Wrap = styled.div`
  position: relative;
`
const Point = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  font-family: 'Prompt', 'Roboto', sans-serif;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 20px;
  left: 0;
`
const LabelTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`
const Line = styled.div`
  border-top: 1px solid #e5e5e5;
  margin: 20px 0;
`
const NextButton = styled(Button)`
  min-width: 250px;
  max-width: 250px;
  min-height: 38px;
  margin-top: 14px;
`
const CustomsCollapse = css``
const CustomsTitleCollapse = css`
  width: fit-content;
  border-color: #333333;
  margin-top: 3px;
  margin-bottom: 0px;
  div {
    font-size: 12px;
    color: #333333;
    font-weight: 400;
  }
`
const TitleLead = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #265fa7;
  font-family: 'Prompt', 'Roboto', sans-serif;
  :nth-child(even) {
    margin-bottom: 30px;
  }
`
const WrapLead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 11px;
  padding-bottom: 20px;
  min-width: 260px;
  max-width: 260px;
`
const Caption = styled.span`
  margin-bottom: 1em;
  margin-top: 1em;
  font-size: 18px;
`
const CheckboxLayout = styled.div`
  width: 300px;
`
const WrapField = styled.div`
  display: flex;
`
const ClassNameInput = css`
  margin-right: 10px;
`
const CustomInputField = styled(Field)`
  margin: 0px 0 20px;
  input {
    width: 260px;
  }
`
const LinkToTabel = styled.div`
  color: #ffb933;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`
const Span = styled.span`
  text-decoration: underline;
`
const CustomFieldSelector = styled(Field)`
  .Select {
    width: 260px;
  }
  .Select-menu-outer {
    left: 0;
  }
  label {
    padding-right: 20px;
    width: -moz-max-content;
  }
`
const optionsTime = [
  {label: '08.00-12.00', value: '08.00-12.00'},
  {label: '12.00-16.00', value: '12.00-16.00'},
  {label: '16.00-20.00', value: '16.00-20.00'},
]

const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'กรุณาระบุชื่อ'
  }
  if (!values.lastName) {
    errors.lastName = 'กรุณาระบุนามสกุล'
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'กรุณาระบุเบอร์โทร'
  } else if (!isPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (values.email !== '' && !isEmail(values.email)) {
    errors.email = 'กรุณาระบุอีเมล'
  }

  if (!values.time) {
    errors.time = 'กรุณาเลือกช่วงเวลาที่สะดวกให้ติดต่อกลับ'
  }

  errors._error = Object.keys(errors).length
  return errors
}

const LeadModal = props => {
  const {handleSubmit, onHandleSubmit, error, productLifeDetail} = props

  let totalBenefit = 0
  if (productLifeDetail.insuranceType === 'saving') {
    const irr = productLifeDetail.irr || 0
    const protectionPeriod = productLifeDetail.protectionPeriod || 0
    const yieldToMaturity = productLifeDetail.yieldToMaturity || 0
    const sumInsured = productLifeDetail.sumInsured || 0
    totalBenefit =
      ((irr / 100) * protectionPeriod + parseFloat(yieldToMaturity) / 100) *
        sumInsured || 0
  }
  const payPeriod =
    productLifeDetail.paidPremiumType === 'period'
      ? `จ่าย ${toCurrency(productLifeDetail.payPeriod, {prefix: ''})} ปี`
      : `จ่ายถึงอายุ ${toCurrency(
          productLifeDetail.payPeriod
            ? productLifeDetail.payPeriod
            : productLifeDetail.minAgeAnnuity - 1,
          {prefix: ''},
        )} ปี`
  return (
    <CustomModal
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      className={CustomModalAntd}>
      <Content>
        <Top>
          <WrapTopInfo>
            <Img src={productLifeDetail.companyLogoUrl} />
            <WrapText>
              <Name>{productLifeDetail.name}</Name>
              <Symbol>{productLifeDetail.companyName}</Symbol>
            </WrapText>
          </WrapTopInfo>
          <Wrap>
            <Shield src={shield} />
            <Point>{numeral(productLifeDetail.score).format('0[.]0')}</Point>
          </Wrap>
        </Top>

        <Collapse
          className={CustomsCollapse}
          customTitle={CustomsTitleCollapse}
          caption={props.toggleCollapse ? 'ซ่อนรายละเอียด' : 'ดูรายละเอียด'}
          setIsOpen={open => props.setToggleCollapse(!props.toggleCollapse)}
          isOpen={props.toggleCollapse}
          hideIcon
          titleToBottom>
          {productLifeDetail.insuranceType === 'annuity' ? (
            <>
              <Mid>
                <InsuranceText>
                  เงินที่ต้องจ่ายต่อปี (เบี้ยประกัน)
                </InsuranceText>
                <InsuranceValue>
                  <MoneySymbol>฿</MoneySymbol>
                  {toCurrency(productLifeDetail.premium, {prefix: ''})}
                </InsuranceValue>
                <SmallText>{payPeriod}</SmallText>
              </Mid>
              <Footer>
                <LabelTitle>ผลประโยชน์</LabelTitle>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'shield-alt']} />
                    ได้เงินคืนหลังเกษียณต่อปี
                  </Label>
                  <MoneySymbol>
                    ฿{' '}
                    {toCurrency(productLifeDetail.annuityRefundPerYear, {
                      prefix: '',
                    })}
                    {/* ฿ {toCurrency(productLifeDetail.sumInsured, {prefix: ''})} */}
                  </MoneySymbol>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'undo-alt']} />
                    ได้เงินคืนตั้งแต่อายุ
                  </Label>
                  <Value>
                    {productLifeDetail.minAgeAnnuity}
                    {' - '}
                    {productLifeDetail.maxAgeAnnuity} ปี
                  </Value>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'hand-holding-usd']} />
                    ได้รับเงินเมื่อเสียชีวิต
                  </Label>
                  <Value>
                    เริ่มที่ ฿{' '}
                    {toCurrency(productLifeDetail.sumInsured, {
                      prefix: '',
                    })}
                  </Value>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'calculator']} />
                    ลดภาษีปีนี้ได้
                  </Label>
                  <Value>
                    ฿{toCurrency(productLifeDetail.premium, {prefix: ''})}
                  </Value>
                </Row>
                {productLifeDetail.graphImageUrl && (
                  <LinkToTabel>
                    <Icon icon={['fas', 'table']} />
                    <Span onClick={() => props.setOpenLightBox(true)}>
                      ดูตารางผลประโยชน์
                    </Span>
                  </LinkToTabel>
                )}
                <GraphImg
                  openLightBox={props.openLightBox}
                  setOpenLightBox={props.setOpenLightBox}
                  imgPath={productLifeDetail.graphImageUrl}
                />
                <Line />
                <LabelTitle>เงื่อนไข</LabelTitle>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'check']} />
                    อายุที่รับสมัคร
                  </Label>
                  <Value>
                    {productLifeDetail.minAgeInsured}
                    {' - '}
                    {productLifeDetail.maxAgeInsured} ปี
                  </Value>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'check']} />
                    ระยะเวลาคุ้มครอง
                  </Label>
                  <Value>คุ้มครองจนถึงอายุ 64 ปี</Value>
                </Row>
              </Footer>
            </>
          ) : null}

          {productLifeDetail.insuranceType === 'saving' ? (
            <>
              <Mid>
                <InsuranceText>
                  เงินที่ต้องจ่ายต่อปี (เบี้ยประกัน)
                </InsuranceText>
                <InsuranceValue>
                  <MoneySymbol>฿</MoneySymbol>{' '}
                  {toCurrency(productLifeDetail.premium, {prefix: ''})}
                </InsuranceValue>
                <SmallText>{payPeriod}</SmallText>
              </Mid>
              <Footer>
                <LabelTitle>ผลประโยชน์</LabelTitle>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'shield-alt']} />
                    ได้เงินคืนทั้งหมด
                  </Label>
                  <MoneySymbol>
                    {toCurrency(totalBenefit, {prefix: '฿'})}
                  </MoneySymbol>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'undo-alt']} />
                    ปันผลเฉลี่ยปีละ
                  </Label>
                  <Value>
                    {toCurrency(
                      productLifeDetail.sumInsured *
                        (productLifeDetail.irr / 100),
                      {
                        prefix: '฿',
                      },
                    )}{' '}
                  </Value>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'coins']} />
                    รับเงินก้อนเมื่อครบสัญญา
                  </Label>
                  {toCurrency(productLifeDetail.yieldToMaturityAmount, {
                    prefix: '฿',
                  })}
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'hand-holding-usd']} />
                    ได้รับเงินเมื่อเสียชีวิต
                  </Label>
                  เริ่มที่{' '}
                  {toCurrency(productLifeDetail.sumInsured, {prefix: '฿'})}
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'calculator']} />
                    ลดภาษีปีนี้ได้
                  </Label>
                  <Value>
                    {toCurrency(productLifeDetail.premium, {prefix: '฿'})}
                  </Value>
                </Row>
                {/* <LinkToTabel>
                <Icon icon={['fas', 'table']} />
                <Span onClick={() => props.setOpenLightBox(true)}>
                  ดูตารางผลประโยชน์
                </Span>
              </LinkToTabel> */}
                <GraphImg
                  openLightBox={props.openLightBox}
                  setOpenLightBox={props.setOpenLightBox} // productLifeDetail.graphImageUrl ||
                  imgPath={productLifeDetail.graphImageUrl}
                />
                <Line />
                <LabelTitle>เงื่อนไข</LabelTitle>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'check']} />
                    อายุที่รับสมัคร
                  </Label>
                  <Value>
                    {productLifeDetail.minAgeInsured}
                    {' - '}
                    {productLifeDetail.maxAgeInsured} ปี
                  </Value>
                </Row>
                <Row>
                  <Label>
                    <Icon icon={['fas', 'check']} />
                    ระยะเวลาคุ้มครอง
                  </Label>
                  <Value>คุ้มครองจนถึงอายุ 64 ปี</Value>
                </Row>
              </Footer>
            </>
          ) : null}
        </Collapse>
      </Content>
      <Line />
      <Form>
        <WrapLead>
          <TitleLead>กรอกข้อมูลเพื่อให้</TitleLead>
          <TitleLead>ตัวแทนประกันติดต่อกลับ</TitleLead>
          <CustomInputField
            name="firstName"
            type="googleInput"
            placeholder="ชื่อ"
            hideError
          />
          <CustomInputField
            name="lastName"
            type="googleInput"
            placeholder="นามสกุล"
            hideError
          />
          <CustomInputField
            name="phoneNumber"
            type="googleInput"
            placeholder="เบอร์โทร"
            format={phoneFormatter}
            parse={formatParser}
            hideError
          />
          <CustomInputField
            name="email"
            type="googleInput"
            placeholder="อีเมล"
            hideError
          />
          <CustomFieldSelector
            name="time"
            type="googleSelector"
            placeholder="ช่วงเวลาที่สะดวกให้ติดต่อกลับ"
            options={optionsTime}
            hideError
          />
          <NextButton onClick={handleSubmit(onHandleSubmit)}>ส่ง</NextButton>
        </WrapLead>
      </Form>
    </CustomModal>
  )
}

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    age: 'age.value',
    gender: 'gender.value',
  }),
  withState('toggleCollapse', 'setToggleCollapse', false),
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
  }),
  connect((state, props) => ({
    user: state.auth.user,
  })),
  reduxForm({form: 'TaxLeadPolicy', validate}),
  withHandlers({
    onHandleSubmit: props => async value => {
      const {productLifeDetail} = props
      const response = await httpPost(`${API_HOST}insurance_leads/life`, {
        age: props.age,
        gender: props.gender,
        premium: productLifeDetail.premium,
        insurancePolicyId: productLifeDetail.id,
        urgentLevel: 2,
        consent: true,
        noonScore: productLifeDetail.score,
        personalInfoFullname: `${value.firstName} ${value.lastName}`,
        personalInfoGender: props.gender,
        personalInfoPhoneNumber: value.phoneNumber,
        personalInfoBirthDate: `${moment().date()}/${moment().month() +
          1}/${moment().year() - props.age}`,
        personalInfoAge: props.age,
        personalInfoContactTime: value.time,
        personalInfoEmail: value.email,
        personalInfoProvince: 1,
      })
      await props.setAgent(response.data.data.lifeInsuranceLead.agent)
      props.setCustomerInfo(value)
      props.setIsShowModal(false)
      props.setOpenSuccessModal(true)
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {user} = this.props
      await this.props.initialize({
        firstName: user.firstNameTh || user.firstNameEn,
        lastName: user.lastNameTh || user.lastNameEn,
        email: user.email,
        phoneNumber: user.phoneNumber,
      })
    },
  }),
)

export default enhancer(LeadModal)
