import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {responsive} from '../../core/style'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PolicyHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 40px;
`

const PolicyContent = styled.div`
  font-size: 16px;
  width: 745px;
  margin-bottom: 95px;

  ${props =>
    responsive.tablet(css`
      width: 100%;
      padding: 0 40px;
    `)};
`

const Policy = () => (
  <Container>
    <PolicyHeader>นโยบายความเป็นส่วนตัว</PolicyHeader>
    <PolicyContent>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัท ทีคิวแอลดี จำกัด
      ได้ปฏิบัติตามระเบียบความปลอดภัยอย่างเข้มงวดในขณะที่ ทำการส่ง จัดเก็บ
      หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน โดยก่อนที่จะมีการเปิดเผยข้อมูลใดๆ
      ของท่านบนเวปไซต์ของเรา จะต้องได้รับความยินยอมของท่านก่อนเสมอ การส่ง
      จัดเก็บ
      และเปิดเผยข้อมูลจะเป็นไปตามพระราชบัญญัติว่าด้วยการกระทำความผิดที่เกี่ยวกับคอมพิวเตอร์
      พ.ศ.2550
      <br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทุกครั้งที่ท่านได้เข้ามาเยี่ยมชมเวปไซต์
      noon.in.th ทางเซิร์ฟเวอร์ของเรา
      จะทำการเก็บบันทึกข้อมูลเบราว์เซอร์ของท่านโดยอัตโนมัติ
      โดยจะมีการเก็บข้อมูลดังต่อไปนี้
      <br />
      <br />
      <ol>
        <li>หมายเลข IP address ของคอมพิวเตอร์ท่าน</li>
        <li>ประเภทของโปรแกรมเบราว์เซอร์ที่ท่านใช้</li>
        <li>
          เวปไซต์ที่ท่านเข้าใช้ก่อนเข้าเยี่ยมชมเวปไซต์ http://www.noon.in.th
        </li>
        <li>วันที่ เวลา ระยะเวลาทั้งหมดที่ท่านเข้าเยี่ยมชมเวปไซต์</li>
        <li>ข้อมูลที่ท่านค้นหาภายในเวปไซต์</li>
        <li>ผลิตภัณฑ์ประกันทุกประเภทที่ท่านได้เปรียบเทียบในเวปไซต์ของเรา</li>
        <li>ข้อมูลและสถิติอื่นๆที่เกี่ยวข้อง</li>
      </ol>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อมูลเหล่านี้จะถูกเก็บรวบรวมไว้เพื่อนำไปใช้ในการวิเคราะห์
      ประเมินผล เพื่อนำไปพัฒนา ปรับปรุงเวปไซต์ ผลิตภัณฑ์
      และบริการของเราในเวลาต่อไป โดยข้อมูลเหล่านี้จะถูกนำไปใช้ร่วมกับ
      ข้อมูลส่วนบุคคลอื่นๆ ในระบบด้วยเช่นกัน
      ซึ่งข้อมูลที่ท่านกรอกเพื่อค้นหาข้อมูลบนหน้าเวปไซต์
      ของเราจะถูกเก็บไว้เพื่ออำนวยความสะดวก
      ให้กับท่านเมื่อเข้ามาใช้งานในครั้งถัดไป
      <br />
      <br />
      <ins>วัตถุประสงค์ในการรวบรวมข้อมูล</ins>
      <br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในการให้บริการเปรียบเทียบผลิตภัณฑ์ประกันประเภทต่างๆ
      บนเวปไซต์ของเรานั้น จำเป็นจะต้องรวบรวมข้อมูลพื้นฐานส่วนบุคคลของท่าน
      เพื่อนำมาใช้วิเคราะห์ คัดเลือก แนะนำ
      แบบประกันที่มีความเหมาะสมตามความต้องการและวัตถุประสงค์การทำประกันของท่าน
      เราอาจจะนำข้อมูลส่วนบุคคลของท่านไปใช้ในวัตถุประสงค์ดังนี้ <br />
      <br />
      <ol>
        <li>เพื่อจัดส่งผลิตภัณฑ์ ที่ท่านสั่งซื้อผ่านเวปไซต์ของเรา</li>
        <li>
          เพื่ออัพเดทข้อมูลการส่งผลิตภัณฑ์ และข้อมูลอื่นๆ
          กรณีที่ลูกค้าต้องการเพิ่มเติม
        </li>
        <li>เพื่อให้ข้อมูลเกี่ยวกับบริการ และผลิตภัณฑ์ที่เกี่ยวข้อง </li>
        <li>เพื่อดำเนินการจัดส่งผลิตภัณฑ์ตามคำสั่งซื้อของลูกค้า </li>
        <li>
          เพื่อให้ข้อมูลเกี่ยวกับผลิตภัณฑ์
          และการให้ข้อมูลผ่านเวปไซต์ที่ท่านต้องการทราบเพิ่มเติมเกี่ยวกับผลิตภัณฑ์นอกเหนือจากข้อมูลที่มีให้บนเวปไซต์ของเรา
        </li>
      </ol>
      <ins>การเปิดเผยข้อมูลส่วนบุคคล</ins>
      <br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในบางกรณีเราอาจจะต้องเปิดเผยข้อมูลส่วนตัวของท่านให้กับบุคคลที่สามที่เกี่ยวข้อง
      เพื่อให้บริการอำนวยความสะดวกกับท่านในการซื้อ
      และ/หรือกรณีที่ท่านต้องการสอบถามข้อมูล
      เกี่ยวกับผลิตภัณฑ์ประกันประเภทต่างๆเพิ่มเติม
      หรือเป็นการเปิดเผยข้อมูลตามที่กฎหมายกำหนด
      โดยเมื่อท่านได้ทำการกรอกข้อมูลผ่านหน้าเวปไซต์ของเรา ท่านได้ยินยอมให้บริษัท
      ทีคิวแอลดี จำกัด
      สามารถเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลที่สามดังนี้ <br />
      <br />
      <ol>
        <li>
          บุคคลที่สาม เช่น นายหน้า ตัวแทน โบรคเกอร์ ประกันชีวิตหรือประกันภัย
          ซึ่งรวมถึงแต่ไม่จำกัดเพียงบริษัทประกันชีวิต หรือประกันภัย
          ที่ถูกรวบรวมไว้ บนเวปไซต์ของเรา
          เพื่อที่จะอำนวยความสะดวกให้กับท่านในการให้ข้อมูลที่จำเป็น
          และ/หรือซื้อผลิตภัณฑ์จากเวปไซต์ของเรา
        </li>
        <li>
          บุคคลที่สามบางประเภท เช่น ผู้รับประกันภัย เป็นต้น
          ซึ่งเราได้เปิดเผยข้อมูลของท่าน เพื่อนำข้อมูลดังกล่าวนี้ไปใช้ในการวิจัย
          เช่น สถิติลูกค้า หรือเทรนด์การตลาด เนื่องจาก
          สิ่งเหล่านี้จะช่วยให้เราสามารถจัดหาผลิตภัณฑ์และการให้บริการที่พัฒนาขึ้นเพื่อตอบโจทย์กับความต้องการของท่านมากขึ้น
        </li>
        <li>
          บุคคลที่สาม เช่น หน่วยงานผู้มีอำนาจในการดูแลเกี่ยวกับการประกันชีวิต
          และการประกันภัย หรือกฎหมายหมายอื่นๆ
          เพื่อวัตถุประสงค์ในการตรวจสอบความถูก ต้องตามกฎหมาย{' '}
        </li>
        <li>
          บุคคลที่สามอื่นๆ ที่มีผลิตภัณฑ์และบริการที่น่าสนใจที่จะเสนอแก่ท่าน
        </li>
      </ol>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เราจะไม่เปิดเผยส่วนหนึ่งส่วนใดของข้อมูลส่วนบุคคลของท่าน
      หรือรายละเอียดอื่นๆ ให้แก่บุคคลที่สามโดยไม่ได้รับความยินยอมจากท่าน
      เว้นแต่จะมีเหตุตามที่กำหนดไว้ในนโยบาย ฉบับนี้
      หรือมีกฎหมายกำหนดให้ต้องกระทำบังคับ ซึ่งอาจเกิดขึ้นในกรณีที่มีคำสั่งศาล
      หรือเพื่อหลีกเลี่ยงการฉ้อโกงและการก่ออาชญากรรมอื่นๆ
      โดยการชดเชยค่าเสียหายที่เกิดขึ้น จากการที่ บริษัท ทีคิวแอลดี จำกัด
      เข้าไปเกี่ยวข้อง หรือถูกเรียกร้องจากการเปิดเผยข้อมูลของท่าน
      ท่านได้ยอมรับโดยไม่อาจเพิกถอนได้ ที่จะชดเชยค่าเสียหายให้แก่ บริษัท
      ทีคิวแอลดี จำกัด จากความรับผิด ข้อเรียกร้อง ความเสียหาย ค่าใช้จ่าย
      หรือค่าธรรมเนียมทางกฎหมายทั้งหมด <br />
      <br />
      <ins>การเก็บรักษาข้อมูล</ins>
      <br />
      <br />{' '}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เมื่อท่านสร้างบัญชีผู้ใช้งานกับเวปไซต์
      www.noon.in.th ข้อมูลส่วนบุคคลที่ท่านให้ไว้กับเรา
      จะมีการเก็บรักษาไว้ตราบเท่าที่ยังมีการนำข้อมูลไปใช้ตามวัตถุประสงค์ที่ระบุไว้
      โดยท่านมีหน้าที่รับผิดชอบในการเก็บรักษารหัสผู้ใช้งานและรหัสผ่านไว้เป็นความลับอยู่ตลอด
      และจะต้องปกป้องข้อมูลบนอุปกรณ์ของท่าน เช่น การใช้โปรแกรมป้องกันไวรัส,
      การอัพเดทซอฟต์แวร์, เปลี่ยนรหัสผ่าน
      และไม่ให้บุคคลอื่นเข้าถึงอุปกรณ์ของท่านได้ไม่ว่าทางใด ก็ตาม
      <br />
      <br />
      <ins>ความปลอดภัยของข้อมูล</ins>
      <br />
      <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อมูลส่วนบุคคลใดๆ
      ที่ได้ถูกรวบรวมผ่านทางเวปไซต์ของเรา เราได้ดำเนินการอย่างเข้มงวด
      ในการรักษาความปลอดภัยและป้องกันการนำข้อมูลของท่านไปใช้
      โดยไม่ได้รับอนุญาตด้วยมาตรฐานขั้นสูง
      เราทำการทดสอบและปรับปรุงเทคโนโลยีรักษาความ
      ปลอดภัยของเราอย่างต่อเนื่องและสม่ำเสมอ
      เพื่อให้คุณมั่นใจว่าข้อมูลส่วนตัวของคุณได้รับ
      การดูแลด้วยมาตรการรักษาความปลอดภัยสูงสุด
      <br />
      <br />
      <ins>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวของบริษัท</ins>
      <br />
      <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัท ทีคิวแอลดี
      จำกัด มีสิทธิ์ในการเปลี่ยนแปลง แก้ไขนโยบายความเป็นส่วนตัวที่ใช้อยู่
      ได้ทุกเมื่อ โดยเราจะนำเสนอข้อมูลที่แก้ไขเพิ่มเติมแล้วในเวปไซต์นี้
      และการเปลี่ยนแปลงจะมีผลทันที
      ที่มีการเผยแพร่ลงบนเวปไซต์หรือแจ้งให้ท่านทราบด้วยวิธีอื่น
      ซึ่งนโยบายการคุ้มครองข้อมูล และความเป็นส่วนตัว
      มิได้มีวัตถุประสงค์หรือก่อให้เกิดสิทธิทางสัญญา หรือสิทธิทางกฎหมายใดๆ
      อีกทั้งมิได้ก่อให้เกิดภาระผูกพันแก่เรา
      ที่เกี่ยวกับบุคคลอื่นหรือในนามของบุคคลอื่นใดทั้งสิ้น
    </PolicyContent>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
)
export default enhancer(Policy)
