import React from 'react'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'
import windowSize from '../../../../core/react-window-size'
import withSEO from '../../../share/withSEO'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {reduxForm, Field as CustomField, formValueSelector} from 'redux-form'

import Button from '../../../share/Button'
import {fadeIn, responsive, breakpoints} from '../../../../core/style'
import Field from '../../../share/Field'
import {
  displayNumber,
  numberParser,
  obj2Url,
  url2Obj,
} from '../../../../core/helper'
import PersonalForm from '../../../share/PersonalForm'
import {savePlanning} from '../../../../ducks/product'

const Container = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
const ContainerSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 45px;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
      flex-direction: column;
      min-width: 320px;
      padding: 0 20px;
      margin-top: 15px;
    `)};
`
const ContainerSliderInfo = styled.div`
  width: 200px;
`

const HeaderSlider = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderSmall = styled.div`
  font-size: 12px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const ContainerBox = styled.div`
  display: flex;
  width: 785px;
  justify-content: space-between;
  margin-top: 30px;

  ${props =>
    responsive.tablet(css`
      max-width: 420px;
      flex-wrap: wrap;
      justify-content: center;
    `)};

  ${props =>
    responsive.mobile(css`
      max-width: 320px;
      flex-wrap: wrap;
      justify-content: center;
    `)};
`

const BgIcon = styled(FontAwesomeIcon)`
  position: absolute;
  color: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  right: -35px;
  top: 20px;
  font-size: 100px;
`
const Label = styled.div`
  font-size: 16px;
  margin-right: 25px;

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      width: 100%;
      margin-bottom: 15px;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;
`
const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin: ${props => (props.isSelected ? '0 77px 0 0' : '24px 77px 0 0')};

  ${props =>
    responsive.mobile(css`
      margin: 0;
      max-width: 320px;
      padding: 0 20px;
      margin-top: 15px;
      margin-bottom: 65px;
    `)};
`
const Selector = styled(Field)`
  display: flex;
  align-items: center;
  margin: 0;
  width: unset;
  .Select-control {
    text-align: center;
  }
  .input-selector-container {
    width: 180px;
    height: 40px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 100%;

      .label {
        width: 100%;
        margin-right: 0;
        justify-content: flex-start;
        margin-bottom: 15px;
      }

      .input-selector-container {
        width: 100%;
      }
    `)};
`
const Slider = styled(Field)`
  .ant-slider {
    margin: 14px 0 0;
    padding: 0;
  }
`

const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: unset;

  margin: 0;
  margin-left: ${props =>
    props.selectedSearchBy === 'premium' ||
    props.selectedSearchBy === 'annuity_refund'
      ? '137'
      : '112'}px;
  margin-right: 25px;
  .label {
    margin: 0;
    margin-right: 25px;
    min-width: 0;
  }
  .input-text-container {
    width: unset;
  }

  input {
    width: 180px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      margin: 0;
      flex-direction: column;
      width: 100%;

      .label {
        width: 100%;
        margin-right: 0;
        justify-content: flex-start;
        margin-bottom: 15px;
      }

      .input-text-container {
        width: 100%;
        input {
          width: 100%;
        }
      }
    `)};
`

const BoxOnce = styled.div`
  display: flex;
  position: relative;
  width: 190px;
  height: 140px;
  border-radius: 5px;
  background-color: ${props =>
    props.isSelected ? props.theme.ORANGE : props.theme.BLUE};
  color: ${props => (props.isSelected ? 'black' : 'white')};
  overflow: hidden;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.ORANGE};
    color: black;
    .umbrella,
    .coins,
    .clock,
    .blind {
      color: rgba(178, 117, 0, 0.3);
    }
  }
  .umbrella {
    left: 120px;
  }
  .man-with-cane {
    left: 120px;
  }

  ${props =>
    responsive.tablet(css`
      margin: 7.5px;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 140px;
      margin: 7.5px;
      :hover {
        background-color: ${props.isSelected
          ? props.theme.ORANGE
          : props.theme.BLUE};
        color: ${props.isSelected ? 'black' : 'white'};
      }
    `)};
`
const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  animation: ${fadeIn} 2s;
`
const CustomContainerPersonalForm = styled(PersonalForm)`
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 10px;
  .property {
    margin-bottom: 35px;
  }

  ${props =>
    responsive.mobile(css`
      margin-top: 30px;

      .property {
        margin-bottom: 0;
      }

      .gender {
        margin-bottom: 30px;
      }
    `)};
`
const Info = styled.div`
  font-size: 12px;
`
const Unit = styled.span`
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const CustomButton = styled(Button)`
  width: 150px;
  height: 30px;
  font-size: 12px;
  margin-bottom: 55px;
  margin-top: 39px;

  ${props =>
    responsive.mobile(css`
      margin-top: 0;
    `)};
`

const WLoptions = [
  {label: 'เบี้ยประกันที่สนใจ', value: 'premium'},
  {label: 'ทุนประกันที่สนใจ', value: 'sum_insured'},
]

const TMoptions = [
  {label: 'เบี้ยประกันที่สนใจ', value: 'premium'},
  {label: 'ทุนประกันที่สนใจ', value: 'sum_insured'},
]

const ANoptions = [
  {label: 'เบี้ยประกันที่สนใจ', value: 'premium'},
  {label: 'บำนาญที่สนใจ', value: 'annuity_refund'},
]
const SVoptions = [
  {label: 'เบี้ยประกันที่สนใจ', value: 'premium'},
  {label: 'เงินคืนทั้งหมดที่สนใจ', value: 'annuity_refund_total'},
  {label: 'ทุนประกันที่สนใจ', value: 'sum_insured'},
]

export const LifeIntermediate = ({
  isSelected,
  change,
  handleSubmit,
  closeModal = () => {},
  savePlanning,
  companyIds,
  selectedSearchBy,
  windowWidth,
  onSubmit,
  formType,
  weightType,
}) => (
  <Container
    onSubmit={
      onSubmit ||
      handleSubmit(value => {
        closeModal()
        savePlanning(
          {
            age: value.age,
            gender: value.gender,
            user_level: 'intermediate',
            sub_types: value.insuranceType,
            focus_value: value.weightType,
            coverage_type: value.searchBy,
            sum_insurance: value.searchValue,
          },
          'intermediate',
          'life',
        )
        window.open(
          `/smart/life/intermediate/product?${obj2Url({
            ...value,
            sortBy: 'min_premium',
            noonStar: 3,
            companyIds: companyIds,
          })}`,
          '_self',
        )
      })
    }>
    <CustomContainerPersonalForm />
    {formType !== 'term' && (
      <ContainerSlider>
        <Label>เน้นด้าน</Label>
        <ContainerSliderInfo>
          <HeaderSlider>
            <HeaderSmall>ความคุ้มค่า</HeaderSmall>
            <HeaderSmall>ความคุ้มครอง</HeaderSmall>
          </HeaderSlider>
          <Slider type="slider" name="weightType" min={1} max={5} />
        </ContainerSliderInfo>
      </ContainerSlider>
    )}
    <FieldContainer isSelected={formType === 'term'}>
      <Selector
        label="เลือกความคุ้มครองจาก"
        name="searchBy"
        type="selector"
        options={
          formType === 'whole_life'
            ? WLoptions
            : formType === 'saving'
            ? SVoptions
            : formType === 'term'
            ? TMoptions
            : ANoptions
        }
        themeColor="orange"
      />
    </FieldContainer>
    <FieldContainer>
      <TextInput
        name="searchValue"
        label={
          windowWidth < breakpoints.mobile
            ? selectedSearchBy === 'premium' ||
              selectedSearchBy === 'annuity_refund'
              ? `เป็นจำนวนเงิน (บาท/ปี)`
              : `เป็นจำนวนเงิน (บาท)`
            : `เป็นจำนวนเงิน`
        }
        type="text"
        placeholder="0"
        format={displayNumber}
        parse={numberParser}
        selectedSearchBy={selectedSearchBy}
      />
      <Unit>
        {selectedSearchBy === 'premium' || selectedSearchBy === 'annuity_refund'
          ? `บาท / ปี`
          : `บาท`}
      </Unit>
    </FieldContainer>
    <CustomButton id="noon-smart-insurance-life-intermediate-search" htmlType="submit" icons={['fa', 'search']}>
      เลือกแบบประกัน
    </CustomButton>
  </Container>
)

const selector = formValueSelector('Planning')

const switchWeigthType = type => {
  switch (type) {
    case 'whole_life':
      return 5
    case 'saving':
      return 1
    case 'term':
      return 5
    case 'annuity':
      return 3
  }
}

const enhancer = compose(
  withSEO(
    'noon Smart Life - ค้นหาประกันชีวิตที่เหมาะสมสำหรับคุณ',
    'noon Smart-Life ค้นหาแบบประกันชีวิตง่ายๆ รายละเอียดครบถ้วน ตามวัตถุประสงค์ที่คุณต้องการ หมดปัญหากับข้อมูลที่ไม่ครบถ้วน เรามีระบบที่ช่วยบอกรายละเอียดเพื่อใช้เป็นเครื่องมือในการหาข้อมูล ก่อนการตัดสินใจซื้อประกันชีวิต เพื่อความคุ้มค่าและความคุ้มครองที่สูงสุด',
    'เครื่องมือค้นหาประกันชีวิต วางแผนประกันชีวิต เลือกซื้อประกันชีวิต,ประกันชีวิต,สะสมทรัพย์,ตลอดชีพ,บำนาญ,ชั่วระยะเวลา,เปรียบเทียบประกันชีวิต',
  ),
  connect(
    (state, props) => ({
      initialValues: {
        searchBy: 'premium',
        // url2Obj(props.location.search).insuranceType === 'whole_life' ||
        // url2Obj(props.location.search).insuranceType === 'term'
        //   ? 'sum_insured'
        //   : url2Obj(props.location.search).insuranceType === 'annuity'
        //   ? 'annuity_refund'
        //   : url2Obj(props.location.search).insuranceType === 'saving'
        //   ? 'annuity_refund_total'
        //   : 'sum_insured',
        gender: url2Obj(props.location.search).gender || '',
        age: url2Obj(props.location.search).age,
        weightType:
          url2Obj(props.location.search).weightType ||
          switchWeigthType(url2Obj(props.location.search).insuranceType) ||
          props.weightType ||
          5,
        searchValue: url2Obj(props.location.search).searchValue || '',
        insuranceType:
          url2Obj(props.location.search).insuranceType || 'whole_life',
      },
      companyIds: url2Obj(props.location.search).companyIds,
      isSelected: selector(state, 'insuranceType'),
      selectedSearchBy: selector(state, 'searchBy'),
    }),
    {savePlanning},
  ),
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default windowSize(enhancer(LifeIntermediate))
