import React from 'react'
import SimpleForm from './SimpleForm'
import AdvanceForm from './AdvanceForm'
import Header from '../HeaderOptionsForm'

const RetirementForm = ({windowWidth}) => (
  <Header>
    <SimpleForm windowWidth={windowWidth} />
    <AdvanceForm windowWidth={windowWidth} />
  </Header>
)

export default RetirementForm
