import styled, {css} from 'react-emotion'

import {responsive} from '../../../../core/style'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  ${props =>
    responsive.tablet(css`
      width: 100%;
      padding: 0 50px;
    `)};
  ${props =>
    responsive.mobile(css`
      width: 100%;
      padding: 0 10px;
    `)};
`
