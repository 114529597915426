import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import {Button} from '../../components'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
`
const SendButton = styled(Button)`
  margin-top: 25px;
`
const Title = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
`
const Code = styled.div`
  font-size: 30px;
`
const Description = styled.div``

const SendCompletedPanel = props => (
  <Layout>
    <Title>ดำเนินการสำเร็จ</Title>
    <Code>{props.code}</Code>
    <Description>
      กรุณานำรหัสตัวเลขด้านบนไปกรอกในแอพลิเคชั่น RD Smart Tax
    </Description>
    <SendButton onClick={props.onSubmit}>ปิด</SendButton>
  </Layout>
)

const enhancer = compose()

export default enhancer(SendCompletedPanel)
