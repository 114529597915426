import React from 'react'
import {compose, lifecycle, withState} from 'recompose'
import ReactDom from 'react-dom'
import _ from 'lodash'

const AppearanceComponent = ({
  children,
  componentDidDisappear,
  componentDidAppear,
}) => children

const enhance = compose(
  withState('checkAppearInScreen', 'setCheckAppearInScreen', {}),
  lifecycle({
    componentDidMount(props) {
      // setTimeout(() => this.props.componentDidAppear())
      const elementBoundingClientRect = ReactDom.findDOMNode(
        this,
      ).getBoundingClientRect()
      if (elementBoundingClientRect.top >= window.innerHeight) {
        setTimeout(() => this.props.componentDidDisappear())
      }
      const checkAppearInScreen = _.throttle(() => {
        const elementBoundingClientRect = ReactDom.findDOMNode(
          this,
        ).getBoundingClientRect()
        if (
          elementBoundingClientRect.top < window.innerHeight &&
          elementBoundingClientRect.bottom > 55
        ) {
          this.props.componentDidAppear()
        } else this.props.componentDidDisappear()
      }, 150)
      this.props.setCheckAppearInScreen({callback: checkAppearInScreen})
      window.addEventListener('scroll', checkAppearInScreen)
      window.addEventListener('resize', checkAppearInScreen)
    },
    componentWillUnmount() {
      window.removeEventListener(
        'scroll',
        this.props.checkAppearInScreen.callback,
      )
      window.removeEventListener(
        'resize',
        this.props.checkAppearInScreen.callback,
      )
    },
  }),
)

export default enhance(AppearanceComponent)
