import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withState, withHandlers} from 'recompose'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import jump from 'jump.js'
import {Link} from '@reach/router'
import TrackVisibility from 'react-on-screen'
import windowSize from '../../core/react-window-size'

import Field from '../share/Field'
import Button from '../share/Button'
import Loading from '../share/Loading'
import ModalConsent from './ModalConsent'
import CoronaBanner from '../../static/images/corona-banner.jpg'
import CoronaBannerMobile from '../../static/images/corona-banner-mobile.png'

import {buyCorona} from '../../ducks/product'

import {
  isEmail,
  isPhoneNumber,
  phoneFormatter,
  formatParser,
} from '../../core/helper'
import {responsive, breakpoints} from '../../core/style'

const Container = styled.div`
  background-color: white;
`

const Banner = styled.div`
  width: 100%;
  height: 688px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${CoronaBanner}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${props =>
    responsive.tablet(css`
      background-position: left;
    `)};

  ${props =>
    responsive.mobile(css`
      height: 367px;

      background-image: url("${CoronaBannerMobile}");
  `)};
`

const BannerWrap = styled.div`
  transform: translateX(250px);

  ${props =>
    responsive.tablet(css`
      padding: 50px;

      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 50px;

      transform: translateX(0);
    `)};

  ${props =>
    responsive.mobile(css`
      padding: 48px 30px;

      border-radius: 5px;
    `)};
`

const BannerText = styled.div`
  font-size: 112px;
  font-weight: bold;
  line-height: 133px;
  text-align: center;

  color: ${props => props.theme.GREY80};

  > span {
    color: #d62c3b;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 55px;
      line-height: 65px;
    `)};
`

const Price = styled.div`
  font-size: 38px;
  font-weight: normal;
  text-align: center;
  line-height: 115px;
  color: ${props => props.theme.GREY80};

  > span {
    font-family: Prompt, Roboto, sans-serif;
    font-size: 76px;
    font-weight: bold;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 19px;
      line-height: 29px;

      height: 60px;

      > span {
        font-size: 38px;
        line-height: 57px;
      }
    `)};
`

const BuyButton = styled(Button)`
  min-width: 300px;
  height: 80px;

  font-size: 38px;
  line-height: 57px;

  border-radius: 48px;

  ${props =>
    responsive.mobile(css`
      min-width: 139px;

      font-size: 19px;
      line-height: 29px;

      height: 38px;
    `)};
`

const Header = ({onPriceClick}) => (
  <Banner>
    <BannerWrap>
      <BannerText>
        ประกันภัย
        <br />
        <span>ไวรัสโคโรนา</span>
        <br />
        <Price>
          เพียง <span>299</span> บาท/ปี
        </Price>
      </BannerText>
      <BuyButton
        aid="corona-insurance-product-campaign-banner-action-button"
        onClick={onPriceClick}>
        ซื้อเลย
      </BuyButton>
    </BannerWrap>
  </Banner>
)

const DetailContainer = styled.div`
  width: 100%;
  padding: 100px 20px;

  color: ${props => props.theme.GREY80};
  background-color: white;

  ${props =>
    responsive.mobile(css`
      padding: 50px 20px;
    `)};
`

const Topic = styled.div`
  margin-bottom: 44px;

  font-size: 30px;
  line-height: 46px;
  text-align: center;
  color: ${props => props.theme.GREY80};

  ${props =>
    responsive.mobile(css`
      margin-bottom: 30px;

      font-size: 16px;
      line-height: 24px;

      > b {
        font-size: 20px;
      }
    `)};
`

const Return = styled.div`
  margin-bottom: 40px;

  font-size: 30px;
  line-height: 46px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.GREEN};

  > span {
    padding: 0 5px;

    font-size: 50px;
    font-family: Prompt, Roboto, sans-serif;
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 30px;

      font-size: 22px;
      line-height: 33px;

      > span {
        font-size: 36px;
      }
    `)};
`

const Content = styled.div`
  width: 100%;
  max-width: 1016px;
  margin: 0 auto;

  font-size: 20px;
  line-height: 30px;

  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      line-height: 21px;
    `)};
`

const Detail = ({onDetailSectionVisible}) => (
  <DetailContainer aid="corona-insurance-detail-section">
    <Topic>
      <b>ประกันภัยอุบัติเหตุส่วนบุคคลและประกันสุขภาพเฉพาะโรค "เจอจ่ายจบ"</b>
      <br />
      หากได้รับการวินิจฉัยว่า ติดเชื้อไวรัสโคโรนาสายพันธุ์ใหม่ 2019
    </Topic>
    <Return>
      รับทันที <span>50,000</span> บาท
    </Return>
    <Content>
      สถานการณ์แพร่ระบาดของเชื้อไวรัสโคโรน่าสายพันธุ์ใหม่ 2019
      กำลังทำให้เรากลายเป็นบ้า เมื่อตัวเลขผู้ติดเชื้อเพิ่มขึ้นเรื่อยๆ
      จำนวนผู้เสียชีวิตก็ขยับขึ้นจนน่าใจหาย
      และประเทศไทยก็ไม่ได้ปลอดภัยอย่างที่คิด เมื่อข้อมูลจาก{' '}
      <a href="http://bit.ly/2v9wl0p" target="__blank">
        worldometers
      </a>{' '}
      รายงานว่าประเทศไทยพบผู้ติดเชื้อไวรัสโคโรนาสายพันธุ์ 2019 มากเป็นอันดับที่
      4 ของโลก (ข้อมูล ณ วันที่ 7 กุมภาพันธ์ 2563)
      <br />
      <TrackVisibility once>
        {({isVisible}) => isVisible && onDetailSectionVisible()}
      </TrackVisibility>
      <br />
      เพื่อคลายความกังวลจากวิกฤตดังกล่าว ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ และ
      กรุงเทพประกันภัยจึงร่วมมือกันสร้างความอุ่นใจและส่งต่อความห่วงใยให้กับคนไทยทุกคนด้วย
      “ประกันภัยไวรัสโคโรนา” ในราคาเบาๆ แต่คุ้มครองประดุจญาติมิตร
    </Content>
  </DetailContainer>
)

const CoverageContainer = styled.ol`
  width: 100%;
  padding: 60px 20px;
  margin: 0;

  color: ${props => props.theme.GREY80};
  background-color: rgba(106, 148, 203, 0.2);
`

const CoverageHeader = styled.div`
  margin: 0 0 40px;

  font-size: 26px;
  font-weight: bold;
  line-height: 39px;
  text-align: center;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
      line-height: 30px;
    `)};
`

const CoverageRow = styled.li`
  width: 100%;
  max-width: 880px;
  margin: 0 auto 16px;

  font-size: 20px;
  line-height: 30px;

  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      margin-left: 15px;
      padding-right: 15px;
    `)};
`

const Coverage = ({onCoverageSectionVisible}) => (
  <CoverageContainer>
    <CoverageHeader>รายละเอียดความคุ้มครอง</CoverageHeader>
    <TrackVisibility once>
      {({isVisible}) => isVisible && onCoverageSectionVisible()}
    </TrackVisibility>
    <CoverageRow>
      หากได้รับการวินิจฉัยว่า “ติดเชื้อไวรัสโคโรนาสายพันธุ์ใหม่2019”
      ประกันจะจ่ายให้ทันที 50,000 บาท
    </CoverageRow>
    <CoverageRow>
      คุ้มครองการเสียชีวิต สูญเสียอวัยวะสายตา
      ทุพพลภาพถาวรสิ้นเชิงด้วยวงเงินสูงสุดถึง 100,000 บาท
    </CoverageRow>
  </CoverageContainer>
)

const ConditionContainer = styled.div`
  width: 100%;
  padding: 100px 20px;

  color: ${props => props.theme.GREY80};
  background-color: white;

  ${props =>
    responsive.mobile(css`
      padding: 70px 20px;
    `)};
`

const ConditionHeader = styled(CoverageHeader)``

const ConditionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  > div:last-child {
    margin-bottom: 0;
  }

  ${props =>
    responsive.mobile(css`
      max-width: 300px;
    `)}
`

const ConditionRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 20px;
    `)};
`

const ConditionIcon = styled(FontAwesomeIcon)`
  margin-right: 26px;

  font-size: 20px;
  color: ${props => props.theme.GREEN};

  ${props =>
    responsive.mobile(css`
      margin-right: 24px;
    `)};
`

const ConditionText = styled.div`
  font-size: 20px;

  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      line-height: 30px;
    `)};
`

const Condition = ({onConditionSectionVisible}) => (
  <ConditionContainer>
    <ConditionHeader>เงื่อนไขการประกันภัย</ConditionHeader>
    <TrackVisibility once>
      {({isVisible}) => isVisible && onConditionSectionVisible()}
    </TrackVisibility>
    <ConditionContent>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>จ่ายเบี้ยเพียง 299 บาท/ปี</ConditionText>
      </ConditionRow>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>ไม่มีระยะเวลารอคอย</ConditionText>
      </ConditionRow>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>ไม่ต้องตรวจสุขภาพ</ConditionText>
      </ConditionRow>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>ระยะเวลาคุ้มครอง 1 ปีนับตั้งแต่วันที่ซื้อ</ConditionText>
      </ConditionRow>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>สามารถซื้อผ่านช่องทางออนไลน์ได้</ConditionText>
      </ConditionRow>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>อายุที่รับประกัน 1 - 70 ปี</ConditionText>
      </ConditionRow>
      <ConditionRow>
        <ConditionIcon icon={['fas', 'check-circle']} />
        <ConditionText>ผู้ซื้อประกันต้องมีสัญชาติไทย</ConditionText>
      </ConditionRow>
    </ConditionContent>
  </ConditionContainer>
)

const LeadContainer = styled.div`
  padding: 100px 20px;

  color: ${props => props.theme.GREY80};
  background-color: rgba(229, 229, 229, 0.2);

  ${props =>
    responsive.mobile(css`
      padding: 65px 20px 103px;
    `)};
`

const LeadForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
`

const LeadTopic = styled.div`
  margin-bottom: 57px;

  font-size: 26px;
  line-height: 39px;
  text-align: center;

  > span {
    font-size: 40px;
    font-weight: bold;
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 52px;

      font-size: 16px;
      line-height: 24px;

      > span {
        font-size: 30px;
        line-height: 24px;
      }
    `)};
`

const LeadField = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:last-child {
    width: 100%;
  }

  ${props =>
    responsive.mobile(css`
      > div {
        width: 100% !important;
      }
    `)};
`

const CustomField = styled(Field)`
  flex-direction: column;
  margin-bottom: 30px;

  > span {
    justify-content: flex-start;
    margin: 0 0 10px;

    font-size: 20px;
  }

  > div {
    input {
      padding: 0 20px;
      height: 48px;

      text-align: left;
      font-size: 20px;
    }
  }

  > div {
    > div {
      width: 100%;
      > input {
        width: 100%;
      }
    }
  }

  &.gender {
    > div {
      > div {
        width: 100%;

        > div.male {
          width: 100%;
          height: 48px;
          border-radius: 30px 0 0 30px;
        }
        > div.female {
          width: 100%;
          height: 48px;
          border-radius: 0 30px 30px 0;
        }
      }
    }
  }

  &.birthdate {
    > div {
      > div {
        width: 100%;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      > span {
        font-size: 16px;
        line-height: 24px;
      }

      &.gender {
        > div {
          > div {
            > div.male {
              height: 40px;
              border-radius: 20px 0 0 20px;
            }
            > div.female {
              height: 40px;
              border-radius: 0 20px 20px 0;
            }
          }
        }
      }
    `)};
`

const CustomGoogle = styled(CustomField)`
  > div.error-container {
    display: none;
  }
`

const Consent = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`

const CustomCheckbox = styled(Field)`
  height: 30px;
  width: 30px;
  margin: 0;

  > div {
    display: flex;
  }

  > div.error-container {
    display: block;
  }
`

const ConsentText = styled.div`
  font-size: 20px;
  line-height: 30px;

  ${props =>
    responsive.mobile(css`
      font-size: 14px;
      line-height: 21px;
    `)};
`

const Link2 = styled.span`
  text-decoration: underline;

  cursor: pointer;
`

const SubmitButton = styled(Button)`
  margin-top: 25px;
  width: 100% !important;
  height: 48px;

  font-size: 20px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      line-height: 24px;
    `)};
`

const Lead = ({onSubmit, onOpenModal, onLeadFormVisible}) => (
  <LeadContainer id="corona_lead" aid="corona-insurance-lead-form">
    <LeadForm onSubmit={onSubmit} noValidate>
      <LeadTopic>
        <span>ซื้อประกันภัยไวรัสโคโรนา</span>
        <TrackVisibility once>
          {({isVisible}) => isVisible && onLeadFormVisible()}
        </TrackVisibility>
        กรอกข้อมูลเพื่อซื้อประกัน
      </LeadTopic>
      <LeadField>
        <CustomField
          className="gender"
          name="gender"
          type="switch"
          label="เพศ"
        />
        <CustomGoogle
          className="firstName"
          name="firstName"
          placeholder="ชื่อ"
          type="googleInput"
        />
        <CustomGoogle
          className="lastName"
          name="lastName"
          placeholder="นามสกุล"
          type="googleInput"
        />
        <CustomField
          className="birthdate"
          name="birthdate"
          type="datepicker"
          placeholder="วันเกิด"
        />
        <CustomGoogle
          className="telnumber"
          name="telnumber"
          placeholder="เบอร์โทรศัพท์"
          format={phoneFormatter}
          parse={formatParser}
          type="googleInput"
        />
        <CustomGoogle
          className="email"
          name="email"
          placeholder="อีเมล"
          type="googleInput"
        />
        <Consent>
          <CustomCheckbox name="consent" type="checkbox" />
          <ConsentText>
            ข้าพเจ้าฯ ตกลงยินยอมรับ{' '}
            <Link2 onClick={onOpenModal}>ข้อตกลงและเงื่อนไข</Link2>
          </ConsentText>
        </Consent>
        <SubmitButton
          htmlType="submit"
          aid="corona-insurance-lead-form-submit-button">
          ซื้อเลย
        </SubmitButton>
      </LeadField>
    </LeadForm>
  </LeadContainer>
)

const Corona = props => {
  const {
    isLoading,
    isShowModal,
    onOpenModal,
    onCloseModal,
    onSubmit,
    onPriceClick,
    onDetailSectionVisible,
    onCoverageSectionVisible,
    onConditionSectionVisible,
    onLeadFormVisible,
  } = props
  return (
    <Loading isLoading={isLoading}>
      <Container>
        <Header onPriceClick={onPriceClick} />
        <Detail onDetailSectionVisible={onDetailSectionVisible} />
        <Coverage onCoverageSectionVisible={onCoverageSectionVisible} />
        <Condition onConditionSectionVisible={onConditionSectionVisible} />
        <Lead
          onSubmit={onSubmit}
          onOpenModal={onOpenModal}
          onLeadFormVisible={onLeadFormVisible}
        />
        <ModalConsent isShow={isShowModal} onClose={onCloseModal} />
      </Container>
    </Loading>
  )
}

const validate = values => {
  const errors = {}

  if (!values.gender) {
    errors.gender = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.firstName) {
    errors.firstName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastName) {
    errors.lastName = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }

  if (!values.telnumber) {
    errors.telnumber = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.telnumber)) {
    errors.telnumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (values.email !== '' && !isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  if (!values.consent) {
    errors.consent = 'กรุณาเลือกยอมรับ'
  }
  errors._error = Object.keys(errors).length

  return errors
}

const enhancer = compose(
  connect(
    state => ({
      isLoading: state.product.isLoading,
    }),
    {buyCorona},
  ),
  reduxForm({form: 'corona_form', validate}),
  withState('isShowModal', 'setShowModal', false),
  withHandlers({
    onPriceClick: ({windowWidth}) => () => {
      dataLayer.push({
        event: 'call_to_action',
        actionButtonName: 'corona_insurance_banner',
      })

      if (windowWidth > breakpoints.tablet) {
        jump('#corona_lead', {offset: -80})
      } else {
        jump('#corona_lead')
      }
    },
    onOpenModal: ({setShowModal}) => () => {
      dataLayer.push({
        event: 'open_modal',
        modalName: 'corona_insurance_consent',
      })

      setShowModal(true)
    },
    onCloseModal: ({setShowModal}) => () => {
      dataLayer.push({
        event: 'close_modal',
        modalName: 'corona_insurance_consent',
      })

      setShowModal(false)
    },
    onSubmit: ({handleSubmit, buyCorona}) => {
      dataLayer.push({
        event: 'submit_data_from_lead_form',
        leadFormName: 'corona_insurance',
      })

      return handleSubmit(values => {
        buyCorona(values)
      })
    },
    onConditionSectionVisible: _props => () =>
      dataLayer.push({
        event: 'section_appear',
        sectionName: 'corona_insurance_condition',
      }),
    onLeadFormVisible: _props => () =>
      dataLayer.push({
        event: 'lead_form_appear',
        leadFormName: 'corona_insurance',
      }),
    onDetailSectionVisible: _props => () =>
      dataLayer.push({
        event: 'section_appear',
        sectionName: 'corona_insurance_detail',
      }),
    onCoverageSectionVisible: _props => () =>
      dataLayer.push({
        event: 'section_appear',
        sectionName: 'corona_insurance_coverage',
      }),
    onConditionSectionVisible: _props => () =>
      dataLayer.push({
        event: 'section_appear',
        sectionName: 'corona_insurance_condition',
      }),
  }),
)

export default windowSize(enhancer(Corona))

const BannerContainer = styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  left: 50%;

  width: 100%;
  max-width: 773px;
  height: auto;

  background-color: white;

  transform: translatex(-50%);
`

const BannerImg = styled.img`
  width: 100%;

  animation: blink 0.2s infinite;

  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.8;
    }
  }
`

const Close = styled.div`
  position: absolute;
  z-index: 99;
  right: 0;
  top: 0;

  width: 25px;
  height: 25px;

  font-size: 16px;
  text-align: center;
  color: rgba(127, 127, 127, 0.5);
  background-color: white;

  cursor: pointer;
`

const bannerEnhancer = compose(
  withHandlers({
    onCloseAd: () => () => {
      const corona = document.getElementById('corona_ad')
      corona.style.display = 'none'
    },
  }),
)

export const CoranaBanner = bannerEnhancer(({onCloseAd}) => (
  <BannerContainer id="corona_ad">
    <Link to="/corona">
      <BannerImg
        alt="corona_ad"
        src="https://www.tqm.co.th/media_file/images/page_promotion/upload/index_slide_394.webp?v=20200205110459"
      />
    </Link>
    <Close onClick={onCloseAd}>X</Close>
  </BannerContainer>
))
