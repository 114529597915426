import React, {useCallback, useMemo} from 'react'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import CustomField from '../../share/Field'
import {getFormValues, submit, initialize} from 'redux-form'
import {navigate} from '@reach/router'

import {responsive} from '../../../core/style'
import Button from '../../share/Button'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 1150px;
  ${props =>
    responsive.mobile(css`
      justify-content: center;
    `)};
`
const SearchBox = styled(CustomField)`
  font-weight: 400;
  margin: 0;
  width: unset;
  height: 40px;

  input {
    font-size: 16px;
    color: #265fa7;
    text-align: left;
    background-color: white;
    width: 293px;
    height: 40px;
    border: unset;
    border-radius: 5px 0 0 5px;
    ::-webkit-input-placeholder {
      color: #265fa7;
      opacity: 0.6;
      display: none;
      padding-left: 10px;
    }
    :-ms-input-placeholder {
      color: #265fa7;
      opacity: 0.6;
      display: none;
      padding-left: 10px;
    }
    ::-moz-placeholder {
      color: #265fa7;
      display: none;
      opacity: 0.6;
      padding-left: 10px;
    }
    :-moz-placeholder {
      display: none;
      color: #265fa7;
      opacity: 0.6;
      padding-left: 10px;
    }
    ::placeholder {
      padding-left: 10px;
      color: white;
    }
    ${props =>
      responsive.desktop(css`
        ::placeholder {
          color: white;
        }
      `)};
    ${props =>
      responsive.mobile(css`
        width: 149px;
        font-size: 14px;
        height: 40px;
        padding-left: 0px;
        ::placeholder {
          color: #265fa7;
          padding-left: 10px;
        }
      `)};
  }
`
const FieldTitle = styled.p`
  font-size: 16px;
  margin: 0 20px 0 0;
  color: #fff;
  font-weight: 400;
  ${props =>
    responsive.mobile(css`
      display: none;
      font-size: 12px;
      margin: 0 5px 0 0;
    `)};
`
const SubmitButton = styled(Button)`
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  min-width: 67px !important;
  height: 40px;
  background-color: #e5e5e5;
  padding: 0 20px;
  ${props =>
    responsive.mobile(css`
      height: 40px;
      padding: 0px;
    `)};
`
const ParamsClearCustomButton = styled(Button)`
  font-size: 12px;
  width: 67px;
  height: 30px;
  margin-left: 20px;
  border-radius: 15px;
  background: #ffffff;
  :focus {
    background: #ffffff;
  }
  ${props =>
    responsive.mobile(css`
      margin-left: 15px;
    `)};
`
const ParamsClearButton = connect(
  null,
  {initialize},
)(props => {
  const {routeParams, initialize, formId} = props

  const hideButton = useMemo(
    () =>
      Object.keys(routeParams).length === 0 ||
      (Object.keys(routeParams).length === 1 &&
        Object.keys(routeParams)[0] === 'returnPeriod'),
    [routeParams],
  )

  const handleClick = useCallback(() => {
    initialize(formId, {})
    navigate('/fund')
  }, [])

  if (hideButton) return <div />

  return (
    <ParamsClearCustomButton onClick={handleClick} disabled={hideButton}>
      รีเซต
    </ParamsClearCustomButton>
  )
})
const SearchFund = connect(
  (state, props) => {
    const {formId} = props
    const formValues = getFormValues(formId)(state)
    return {formValues}
  },
  {submit},
)(props => {
  const {submit, formId, routeParams} = props
  const qFieldName = 'q'
  return (
    <Container>
      <FieldTitle>ค้นหากองทุน</FieldTitle>
      <SearchBox
        name={qFieldName}
        autoComplete="off"
        placeholder="ค้นหากองทุน"
        type="fundSearch"
        onKeyPress={e => {
          if (e.key === 'Enter') {
            submit(formId)
            e.preventDefault()
          }
        }}
      />
      <SubmitButton onClick={() => submit(formId)} icons={['fa', 'search']}>
        ค้นหา
      </SubmitButton>
      <ParamsClearButton routeParams={routeParams} formId={formId} />
    </Container>
  )
})

export default SearchFund
