import React from 'react'
import styled from 'react-emotion'
import _ from 'lodash'
import {compose, withState} from 'recompose'

import {Label, ErrorText} from './index'
import CustomSelect from './Selector'

const AddressRequireField = [
  {key: 'subDistrict', label: 'แขวง / ตำบล'},
  {key: 'district', label: 'เขต / อำเภอ'},
  {key: 'province', label: 'จังหวัด'},
  {key: 'postcode', label: 'รหัสไปรษณีย์'},
]

const AddressContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-bottom: 16px;

  .Select-option {
    padding: 10px 0;
  }
  .Select-option:not(:last-child) {
    border-bottom: 1px solid white;
  }
`
const Address = compose(withState('filterOptions', 'setFilterOptions', []))(
  ({
    input,
    options,
    onBlur,
    searchable,
    error,
    errorLabelColored,
    errorColor,
    disabled,
    readOnly,
    errorOpacity,
    type,
    filterOptions,
    setFilterOptions,
    didChange = () => {},
  }) =>
    AddressRequireField.map(address => (
      <AddressContainer key={address.key} className={address.key}>
        <Label className="label">{address.label}</Label>
        <CustomSelect
          {...input}
          onChange={e => {
            input.onChange({...input.value, ...e.value})
            setTimeout(() => didChange({...input.value, ...e.value}))
          }}
          onBlur={onBlur}
          onBlurResetsInput={false}
          placeholder={address.label}
          options={filterOptions}
          noResultsText={`กรุณาพิมพ์เพื่อค้นหา "${address.label}"`}
          valueRenderer={opt =>
            opt[address.key] ? opt[address.key] : address.label
          }
          filterOptions={(filterOptions, filter, currentValues) => {
            if (filter !== '')
              setTimeout(() =>
                setFilterOptions(
                  _.filter(options, o =>
                    o.value[address.key].startsWith(filter),
                  ),
                ),
              )
            return filterOptions
          }}
          autoload={false}
          searchable={searchable}
          error={error}
          errorLabelColored={errorLabelColored}
          errorColor={errorColor}
          readOnly={readOnly}
          disabled={disabled}
          errorOpacity={errorOpacity}
        />
        {error && (
          <ErrorText
            className="error"
            type={type}
            errorOpacity={errorOpacity}
            errorColor={errorColor}>
            {error}
          </ErrorText>
        )}
      </AddressContainer>
    )),
)

export default Address
