import {INCOME_FIELDS, HISTORY_FIELDS, PERSONAL_FIELDS} from './utils'
import {isBlank} from '../../../../core/helper'

export default values => {
  const errors = {}
  const requiredFields = [
    ...INCOME_FIELDS,
    ...HISTORY_FIELDS,
    ...PERSONAL_FIELDS,
  ].map(field => field.name)

  requiredFields.forEach(field => {
    if (isBlank(values[field]) || Number.isNaN(values[field])) {
      errors[field] = 'ต้องใส่ข้อมูลในช่องนี้'
    }
    if (parseInt(values.retireAge) <= parseInt(values.age)) {
      errors.age = 'อายุปัจจุบันต้องน้อยกว่าอายุเกษียณ'
      errors.retireAge = 'อายุเกษียณต้องมากกว่าอายุปัจจุบัน'
    }
  })

  return errors
}
