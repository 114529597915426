import React from 'react'
import {hot} from 'react-hot-loader'
import store from '../ducks'
import {compose, lifecycle, withState} from 'recompose'
import {Provider, connect} from 'react-redux'
import {ThemeProvider} from 'emotion-theming'
import ReactDom from 'react-dom'
import Router from './route'
import {Location} from '@reach/router'
import {theme} from '../core/style'
import styled from 'react-emotion'
import {getUserInfo} from '../ducks/auth'
import './FontAwesome'
import './style'
import Background from './Background'
import WebFontLoader from './WebFontLoader'

import Footer from './Footer'
import Header from './Header'

const webFontConfig = {
  google: {
    families: [
      'Prompt:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap',
      'Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap',
      //'Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap&subset=thai',
    ],
  },
}
const Container = styled.div`
  position: relative;
`
const CustomHeader = styled(Header)``

const bodyEnhancer = compose(
  connect(
    state => ({}),
    {getUserInfo},
  ),
  withState('isOnTop', 'setIsOnTop', true),
  withState('isFooterAppear', 'setFooterAppear', false),
  withState('initialized', 'setInitialized', false),
  lifecycle({
    async componentDidMount() {
      await this.props.getUserInfo()
      this.props.setInitialized(true)
      // this.onTopTracking = () => {
      //   const elementBoundingClientRect = ReactDom.findDOMNode(
      //     this,
      //   ).getBoundingClientRect()

      //   if (elementBoundingClientRect.top === 0) {
      //     this.props.setIsOnTop(true)
      //   } else {
      //     if (this.props.isOnTop) this.props.setIsOnTop(false)
      //   }
      //   if (elementBoundingClientRect.bottom - 146 <= window.screen.height) {
      //     if (!this.props.isFooterAppear) this.props.setFooterAppear(true)
      //   } else {
      //     if (this.props.isFooterAppear) this.props.setFooterAppear(false)
      //   }
      // }
      // this.onTopTracking.bind(this)
      // window.addEventListener('scroll', this.onTopTracking)
      // this.onTopTracking()
    },
    // componentWillUnmount() {
    //   window.removeEventListener('scroll', this.onTopTracking)
    //   clearInterval()
    // },
    componentWillReceiveProps(np) {
      if (this.props.location.pathname !== np.location.pathname) {
        window.scrollTo(0, 0)
      }
    },
  }),
)

const Body = bodyEnhancer(({isOnTop, isFooterAppear, initialized}) => (
  <ThemeProvider theme={theme}>
    <Container>
      <link
        href="https://fonts.googleapis.com/css?family=Sarabun:400,500,700&display=swap&subset=thai"
        rel="stylesheet"></link>
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"></link>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"></link>
      <CustomHeader isOnTop={isOnTop} />
      {/* <CustomIpadHeader isOnTop={isOnTop} /> */}
      {initialized && (
        <Router isOnTop={isOnTop} isFooterAppear={isFooterAppear} />
      )}
      <Background />
      <Footer />
    </Container>
  </ThemeProvider>
))

const App = () => (
  <Provider store={store}>
    <WebFontLoader config={webFontConfig}>
      <Location>{({location}) => <Body location={location} />}</Location>
    </WebFontLoader>
  </Provider>
)

export default hot(module)(App)
