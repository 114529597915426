import React from 'react'
import styled, {css} from 'react-emotion'
import ProductCard from '../Card/index'
import {responsive} from '../../../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid #e5e5e5;
  margin-top: 40px;
`
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 30px 0;
`
const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => (props.customLength ? '100%' : 'fit-content')};
  justify-content: ${props => (props.customLength ? 'center' : 'unset')};

  ${props =>
    responsive.tablet(css`
      overflow-x: scroll;
      width: auto;
      min-width: 100%;
      -webkit-overflow-scrolling: touch;
    `)};
`
const ContainerWrapper = styled.div`
  overflow: auto;
  ${props =>
    responsive.tablet(css`
      width: 100%;
      padding: 0 10px;
    `)};
`

const RecommendProduct = ({item, params, category, windowWidth}) => (
  <Container>
    <Header>แบบประกันที่ใกล้เคียง</Header>
    <ContainerWrapper>
      <ContainerCard customLength={item.length === 1}>
        {item.map(value => {
          return <ProductCard key={value.id} data={value} params={params} />
        })}
      </ContainerCard>
    </ContainerWrapper>
  </Container>
)

export default RecommendProduct
