import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {displayNumber} from '../../../../../core/helper'
import Favorite from '../Favorite'
import BuyCatalogue from '../BuyCatalogue'
import {responsive} from '../../../../../core/style'

import {
  ContainerInfo,
  ContainerInfoMobile,
  ContainerDetailIcon,
  CompanyLogo,
  InfoIcon,
} from '../TopInfo'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: row;
`

const CustomIcon = styled(FontAwesomeIcon)`
  color: #333333;
  font-size: 40px;
  width: 40px;
  height: 40px;
`

const SmallText = styled.div`
  font-weight: bold;
  font-size: 20px;
`

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`
const HashtagContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Hashtag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 25px;
  padding: 5px 15px 5px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #e5e5e5;
  font-size: 12px;
  color: black;
`

const ContainerFavorite = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const ContainerSecond = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 150px;
  width: 100%;
  ${props =>
    responsive.mobile(css`
      width: 290px;
      justify-content: space-between;
      margin-top: 35px;
    `)};
`
const InsureLevel = styled.div`
  font-size: 16px;
`

const CustomContainerDetailIcon = styled(ContainerDetailIcon)`
  margin-right: ${props => (props.isLast ? '0' : '70px')};
  ${props =>
    responsive.tablet(css`
      margin-right: ${props.isLast ? '0' : '70px'};
    `)};

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
    `)};
`

function dangerText(value) {
  return {__html: value}
}

const IconList = ({item}) => {
  return (
    <React.Fragment>
      <CustomContainerDetailIcon key={'hand-holding-usd'}>
        <CustomIcon icon={['fas', 'hand-holding-usd']} />
        <InfoIcon
          dangerouslySetInnerHTML={dangerText(
            `ทุนประกัน<br/>${displayNumber(parseInt(item && item.cover))}`,
          )}
        />
      </CustomContainerDetailIcon>
      <CustomContainerDetailIcon key={'wrench'}>
        <CustomIcon icon={['fas', 'wrench']} />
        <InfoIcon
          dangerouslySetInnerHTML={dangerText(
            `${item && item.typeGarage === '0' ? 'ซ่อม อู่' : 'ซ่อม ห้าง'}`,
          )}
        />
      </CustomContainerDetailIcon>
      <CustomContainerDetailIcon key={'money-bag'} isLast>
        <CustomIcon icon={['fas', 'money-bag']} />
        <InfoIcon
          dangerouslySetInnerHTML={dangerText(
            `เบี้ยประกันรายปี<br/>ปีละ ${displayNumber(
              `${displayNumber(parseInt(item && item.amountNet))}`,
            )} บาท`,
          )}
        />
      </CustomContainerDetailIcon>
    </React.Fragment>
  )
}

const DetailTop = ({
  item = {},
  params,
  category,
  paramsEncode,
  id,
  isLoading,
  favorite,
}) => (
  <Container>
    <ContainerDetail>
      <CompanyLogo src={item.companyOfficialLogoUrl} />
      <ContainerInfo>
        {/* <Header>{item.companyId}</Header> */}
        <SmallText>{item.companyName}</SmallText>
        <InsureLevel>
          ประกันชั้น{' '}
          {item.insureLevel === '20'
            ? '2+'
            : item.insureLevel === '30'
            ? '3+'
            : item.insureLevel}
        </InsureLevel>
        <HashtagContainer>
          {/* {item.hashTag.map(value => (
            <Hashtag key={value.name}>{value.name}</Hashtag>
          ))} */}
        </HashtagContainer>
      </ContainerInfo>
      <ContainerFavorite>
        {!isLoading && (
          <Favorite
            category={category}
            id={id}
            paramsEncode={paramsEncode}
            favorite={item.favorite}
          />
        )}
        <BuyCatalogue
          params={params}
          category={category}
          productName={item.companyName}
        />
      </ContainerFavorite>
    </ContainerDetail>
    <ContainerInfoMobile>
      {/* <Header>{item.companyId}</Header> */}
      <SmallText>{item.companyName}</SmallText>
      <InsureLevel>
        ประกันชั้น{' '}
        {item.insureLevel === '20'
          ? '2+'
          : item.insureLevel === '30'
          ? '3+'
          : item.insureLevel}
      </InsureLevel>
      <HashtagContainer>
        {/* {item.hashTag.map(value => (
            <Hashtag key={value.name}>{value.name}</Hashtag>
          ))} */}
      </HashtagContainer>
    </ContainerInfoMobile>
    <ContainerSecond>
      <IconList item={item} />
    </ContainerSecond>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
)

export default enhancer(DetailTop)
