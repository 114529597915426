import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'

import OrderCard from './OrderCard'
import Loading from '../../share/Loading'

import {FILTER_OPTIONS} from './utils'
import {responsive} from '../../../core/style'

const CustomLoading = styled(Loading)`
  width: 100%;
  max-width: 340px;
`

const Container = styled.div`
  padding: 0 20px;
`

const NotFound = styled.h1`
  margin-top: 30px;

  font-size: 12px;
  text-align: center;
`
const ProductType = styled.h1`
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
`
const OrderList = ({isLoading, orders, filter}) => {
  const renderOrders = {
    all: orders,
    whole_life: orders.filter(
      order =>
        order.policyType === 'LifeInsurancePolicy' &&
        order.insuranceType === 'whole_life',
    ),
    term: orders.filter(
      order =>
        order.policyType === 'LifeInsurancePolicy' &&
        order.insuranceType === 'term',
    ),
    annuity: orders.filter(
      order =>
        order.policyType === 'LifeInsurancePolicy' &&
        order.insuranceType === 'annuity',
    ),
    saving: orders.filter(
      order =>
        order.policyType === 'LifeInsurancePolicy' &&
        order.insuranceType === 'saving',
    ),
    health: orders.filter(
      order => order.policyType === 'HealthInsurancePolicy',
    ),
    motor: orders.filter(order => order.policyType === 'AutoInsurancePolicy'),
  }
  return (
    <CustomLoading isLoading={isLoading}>
      <Container>
        {orders.length === 0 ? (
          <NotFound>ไม่มีประวัติการส่งคำสั่งซื้อ</NotFound>
        ) : (
          <React.Fragment>
            {renderOrders['annuity'].length > 0 && (
              <>
                <ProductType>ประกันบำนาญ</ProductType>
                {renderOrders['annuity'].map(order => (
                  <OrderCard key={order.quotationId} {...order} order={order} />
                ))}
              </>
            )}
            {renderOrders['saving'].length > 0 && (
              <>
                <ProductType>ประกันออมทรัพย์</ProductType>
                {renderOrders['saving'].map(order => (
                  <OrderCard key={order.quotationId} {...order} order={order} />
                ))}
              </>
            )}
          </React.Fragment>
        )}
      </Container>
      {/* <Container>
        {orders.length === 0 ? (
          <NotFound>ไม่มีประวัติการส่งคำสั่งซื้อ</NotFound>
        ) : renderOrders[filter].length > 0 ? (
          renderOrders[filter].map(order => (
            <OrderCard key={order.quotationId} {...order} order={order} />
          ))
        ) : (
          <NotFound>
            ไม่มีประวัติการส่งคำสั่งซื้อ
            {FILTER_OPTIONS.find(opt => opt.value === filter).label}
          </NotFound>
        )}
      </Container> */}
    </CustomLoading>
  )
}

const enhancer = connect(
  state => ({
    isLoading: state.profile.isLoading,
    orders: state.profile.orders,
  }),
  {},
)

export default enhancer(OrderList)
