import {
  customManwithCane,
  customMoneyBag,
  starter,
  intermediate,
  advance,
} from '../core/customIcon'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  fab,
  faFacebookF,
  faGoogle,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import {
  faHeart,
  faSearch,
  faBookOpen,
  faMale,
  faFemale,
  faCar,
  faBriefcaseMedical,
  faUserShield,
  faShieldAlt,
  faCoins,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faChild,
  faBriefcase,
  faPiggyBank,
  faAngleLeft,
  faChevronCircleRight,
  faChevronCircleLeft,
  faInfoCircle,
  faFilter,
  faPlusCircle,
  faStar,
  faTimes,
  faTimesCircle,
  faUmbrella,
  faClock,
  faBlind,
  faEdit,
  faExchangeAlt,
  faCheckCircle,
  faShareAlt,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faAngleDown,
  faAdjust,
  faExclamationCircle,
  faProcedures,
  faCarCrash,
  faWrench,
  faUserSlash,
  faBed,
  faThumbsUp,
  faUserCog,
  faAngleDoubleDown,
  faEye,
  faCalculator,
  faListOl,
  faChartArea,
  faBolt,
  faUserTie,
  faBookReader,
  faFire,
  faMobileAlt,
  faIdCard,
  faNotesMedical,
  faCrutch,
  faTable,
  faFolderOpen,
  faKey,
  faSignOutAlt,
  faLightbulb,
  faUserClock,
  faCamera,
  faTrashAlt,
  faBars,
  faCloudDownloadAlt,
  faDice,
  faPen,
  faUserCircle,
  faShoppingCart,
  faHome,
  faSave,
  faCircle,
  faCaretUp,
  faCaretDown,
  faFileDownload,
  faSpinner,
  faSadTear,
  faSpider,
  faSpiderWeb,
  faGrinBeam,
  faArrowAltCircleLeft,
  faCog,
  faCopy,
  faPlus,
  faQuestion,
  faComment,
  faExclamationTriangle,
  faUndoAlt,
  faCheck,
  faHistory,
} from '@fortawesome/free-solid-svg-icons'
import {
  faHeart as farHeart,
  faBell as farBell,
  faLightbulb as farLightbulb,
  faStar as farStar,
  faCommentAlt as farCommentAlt,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faThumbsUp as farThumbsUp,
  faTrashAlt as farTrashAlt,
  faEdit as farEdit,
  faSadTear as farSadTear,
  faCircle as farCircle,
  faFilePdf as farFilePdf,
  faCopy as farCopy,
  faCheckSquare as farCheckSquare,
  faSquare as farSquare,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  fab,
  faInfoCircle,
  farHeart,
  faHeart,
  farBell,
  faSearch,
  faBookOpen,
  farLightbulb,
  farStar,
  faMale,
  faFemale,
  faCar,
  faBriefcaseMedical,
  faUserShield,
  faShieldAlt,
  faCoins,
  faHandHoldingHeart,
  faChild,
  faBriefcase,
  faPiggyBank,
  faAngleLeft,
  faChevronCircleRight,
  faChevronCircleLeft,
  faFilter,
  faPlusCircle,
  faStar,
  faHandHoldingUsd,
  faTimes,
  faTimesCircle,
  faUmbrella,
  faClock,
  faBlind,
  faEdit,
  faExchangeAlt,
  faCheckCircle,
  faShareAlt,
  faChevronUp,
  faAngleUp,
  faAngleDown,
  farCommentAlt,
  customManwithCane,
  faAdjust,
  faExclamationCircle,
  faProcedures,
  faCarCrash,
  faWrench,
  faUserSlash,
  faBed,
  faThumbsUp,
  farThumbsUp,
  faUserCog,
  faAngleDoubleDown,
  faEye,
  faCalculator,
  faListOl,
  faChartArea,
  faBolt,
  faUserTie,
  faBookReader,
  farEye,
  farEyeSlash,
  faFire,
  faMobileAlt,
  faCrutch,
  faIdCard,
  faNotesMedical,
  faTable,
  faFolderOpen,
  faKey,
  faSignOutAlt,
  faLightbulb,
  customMoneyBag,
  intermediate,
  starter,
  advance,
  faFacebookF,
  faUserClock,
  faCamera,
  farTrashAlt,
  faTrashAlt,
  farEdit,
  faBars,
  farSadTear,
  faChevronRight,
  faChevronLeft,
  faCloudDownloadAlt,
  faDice,
  faPen,
  faUserCircle,
  faShoppingCart,
  faHome,
  faSave,
  faCircle,
  farCircle,
  faCaretUp,
  faCaretDown,
  faFileDownload,
  faSpinner,
  faSpider,
  faGoogle,
  faTwitter,
  faYoutube,
  faGrinBeam,
  faArrowAltCircleLeft,
  farFilePdf,
  faSadTear,
  faCog,
  faCopy,
  farCopy,
  faPlus,
  faQuestion,
  faComment,
  faExclamationTriangle,
  faUndoAlt,
  faCheck,
  faUndoAlt,
  faHistory,
  farSquare,
  farCheckSquare,
)
