import React from 'react'
import styled from 'react-emotion'
import {compose, lifecycle, withState, defaultProps} from 'recompose'
import {withForm} from './enhancers'
import {
  WizardState,
  Field,
  Currency,
  Button,
  Dropdown,
  Percent,
} from './components'
import {Layout, Title, Content, FooterLayout} from './Questions1'
import * as path from './common/path'
import {httpGet, API_HOST} from '../../core/httpClient'

const CustomTitle = styled(Title)`
  @media (max-width: 425px) {
    white-space: normal;
    text-align: center;
    min-width: 300px;
  }
`
const CustomContent = styled(Content)`
  max-width: 510px;
  min-width: 510px;
`

const Questions3 = props => (
  <Layout>
    <WizardState step={3} />
    <CustomTitle>
      กรอกข้อมูล เพื่อคำนวณความคุ้มครองชีวิตและสุขภาพที่เหมาะสม
    </CustomTitle>
    <CustomContent>
      <Field
        name="hasSocialSecurity"
        caption="สิทธิการรักษาพยาบาลประกันสังคม"
        component={Dropdown}
        placeholder="กรุณาเลือก"
        options={props.hasSocialSecurityOptions}
        marginBottom="16px"
      />
      <Field
        name="providentFund"
        caption="อัตราเงินสะสมกองทุนสำรองเลี้ยงชีพ (%) "
        component={Percent}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="hospitalId"
        caption="โรงพยาบาลที่รักษาเป็นประจำ"
        component={Dropdown}
        placeholder="กรุณาเลือก"
        options={props.hospitalOptions}
        marginBottom="16px"
        searchable
      />
      <Field
        name="injuredFrequency"
        caption="ประวัติการเจ็บป่วยโดยไม่เข้า รพ. (จำนวนครั้ง/ ปี)"
        component={Currency}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="investmentRisk"
        caption="ระดับความเสี่ยงในการลงทุนที่รับได้"
        component={Dropdown}
        options={props.investmentRiskOptions}
        placeholder="กรุณาเลือก"
        marginBottom="30px"
      />
      <FooterLayout>
        <Button.PrevButton to={path.questions2Path()} />
        <Button.CalculateButton
          to={path.summaryPath()}
          disabled={!props.valid}
        />
        {/* <Button.NextButton to={path.summaryPath()} disabled={!props.valid} /> */}
      </FooterLayout>
    </CustomContent>
  </Layout>
)

const enhancer = compose(
  withForm({
    form: 'NoonQuestion',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: values => {
      const errors = {}

      if (!values.hasSocialSecurity && values.hasSocialSecurity !== false) {
        errors.hasSocialSecurity = 'กรุณาระบุสิทธิประกันสังคม'
      }
      if (!values.providentFund && values.providentFund !== 0) {
        errors.providentFund = 'กรุณาระบุอัตราเงินสะสมกองทุนเลี้ยงชีพ (%)'
      }
      if (!values.hospitalId) {
        errors.hospitalId = 'กรุณาระบุ รพ. ที่เลือกใช้บริการ'
      }
      if (!values.injuredFrequency && values.injuredFrequency !== 0) {
        errors.injuredFrequency = 'กรุณาระบุประวัติการเจ็บป่วยโดยไม่เข้า รพ.'
      }
      if (!values.investmentRisk) {
        errors.investmentRisk = 'กรุณาระบุความเสี่ยงในการลงทุนที่รับได้'
      }
      if (!values.insuranceType) {
        errors.insuranceType = 'กรุณาเลือกแบบความคุ้มครอง'
      }

      return errors
    },
  }),
  withState('hospitalOptions', 'setHospitalsOptions', []),
  withState('investmentRiskOptions', 'setInvestmentRiskOptions', []),
  defaultProps({
    hasSocialSecurityOptions: [
      {label: 'มี', value: true},
      {label: 'ไม่มี', value: false},
    ],
  }),
  lifecycle({
    async componentDidMount() {
      const response = await httpGet(`${API_HOST}noon_questions/options`)

      const hospitalOptions = response.data.data.hospitals.map(hospital => ({
        value: hospital.id,
        label: hospital.name,
      }))
      const investmentRiskOptions = Object.entries(
        response.data.data.investmentRisk,
      ).map(obj => ({
        label: obj[1].label,
        value: obj[0],
      }))

      this.props.setHospitalsOptions(hospitalOptions)
      this.props.setInvestmentRiskOptions(investmentRiskOptions)
    },
  }),
)

export default enhancer(Questions3)
