import React from 'react'
import styled from 'react-emotion'
import {spidusStore} from '../core/spidus'
import {Tooltip} from '../components'
import Icon from '../components/Icon'

const Layout = styled.div`
  display: flex;
  width: 100%;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const Caption = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Sarabun', sans-serif !important;
  color: #333333;
`

const Info = styled.div`
  color: #333;
  opacity: 0.9;
  font-size: 0.9em;
`

const RedStar = styled.div`
  color: red;
  margin-left: 5px;
`

const TooltipBody = styled.div``

const TooltipSubInfo = styled.div``

const DeleteIcon = styled(Icon)`
  font-size: 16px;
  color: #333333;
  margin-left: 10px;
  cursor: pointer;

  :hover {
    color: red;
  }
`

const withCaption = (configs = {}) => Component => ({
  className,
  noCaption,
  caption,
  info,
  tooltip,
  required,
  deleteAnswerName,
  ...restProps
}) => {
  const parentProps = {}
  const childrenProps = {}

  Object.entries(restProps).forEach(([key, value]) => {
    if (key.startsWith('data-')) {
      parentProps[key] = value
    } else {
      childrenProps[key] = value
    }
  })

  if (childrenProps.style && childrenProps.style instanceof Function) {
    childrenProps.style = childrenProps.style(restProps)
  }

  deleteAnswerName =
    deleteAnswerName === true ? `use_${restProps.answerName}` : deleteAnswerName

  return (
    <Layout
      className={`${className || ''} ${configs.className || ''}`}
      {...parentProps}>
      {!noCaption && (caption || info) && (
        <Left>
          {caption && (
            <Caption>
              {caption instanceof Function ? caption(restProps) : caption}
              {required && <RedStar>*</RedStar>}
              {deleteAnswerName && (
                <DeleteIcon
                  icon={['far', 'trash-alt']}
                  onClick={() =>
                    spidusStore.updateAnswer(
                      restProps.configName,
                      deleteAnswerName,
                      false,
                    )
                  }
                />
              )}
            </Caption>
          )}
          {tooltip && (
            <Tooltip
              placement="left"
              title={
                tooltip instanceof Array ? (
                  <TooltipBody>
                    {tooltip.map((text, index) => (
                      <TooltipSubInfo key={index}>
                        {tooltip.length > 1 ? '- ' : ''}
                        {text}
                      </TooltipSubInfo>
                    ))}
                  </TooltipBody>
                ) : (
                  tooltip
                )
              }
              style={{marginLeft: 8}}
            />
          )}
          {info && <Info>{info}</Info>}
        </Left>
      )}
      <Right>{Component && <Component {...childrenProps} />}</Right>
    </Layout>
  )
}

withCaption.Layout = Layout
withCaption.Left = Left
withCaption.Right = Right

export default withCaption
