import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field, getFormValues} from 'redux-form'
import {
  compose,
  withProps,
  withHandlers,
  renameProp,
  branch,
  renderNothing,
  defaultProps,
  getContext,
} from 'recompose'
import objectPath from 'object-path'
import {omitProps} from '../../enhances/index'
import spidusStore from './spidusStore'

const withSpidus = () =>
  compose(
    defaultProps({
      fastAccess: {},
    }),
    getContext({
      configName: PropTypes.string,
      autoSaveFromContext: PropTypes.bool,
    }),
    Component => props => (
      <Field
        {...props}
        name={`answers.${props.configName}.${props.answerName}.value`}
        component={Component}
      />
    ),
    connect((state, props) => ({
      ...objectPath.get(
        getFormValues(spidusStore.STORE_NAME)(state),
        `answers.${props.configName}.${props.answerName}`,
        {initialized: false},
      ),
    })),
    branch(
      props =>
        props.initialized == false ||
        props.visibled === false ||
        props.visibility === false,
      renderNothing,
    ),
    branch(props => !!props.choices, renameProp('choices', 'options')),
    withProps(props => ({
      value: props.input.value,
      disabled: props.disabled !== undefined ? props.disabled : props.loading,
      'data-answer-name': props.answerName,
      autoSave:
        props.autoSave !== undefined
          ? !!props.autoSave
          : props.autoSaveFromContext !== undefined
          ? !!props.autoSaveFromContext
          : true,
    })),
    withHandlers({
      onChange: props => value => {
        if (value !== props.value) {
          props.fastAccess.changed = true
          props.input.onChange(value)
        }
      },
      onBlur: props => value => {
        if (props.onAfterBlur) {
          props.onAfterBlur(value)
        }
        if (props.autoSave) {
          if (props.fastAccess.changed) {
            props.fastAccess.changed = false
            spidusStore.updateAnswers(props.configName, props.answerName)
          }
        }
      },
    }),
    omitProps(
      'dispatch',
      'input',
      'meta',
      'autoSave',
      'onAfterBlur',
      'visibility',
    ),
  )

export default withSpidus
