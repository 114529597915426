import React from 'react'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../core/style'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import FavoriteButton from '../components/FavoriteButton'
import Button from '../../share/Button'
import {getThaiDate, displayCurrency} from '../../../core/helper'
import RiskLevel from '../components/RiskLevel'

const Wrapper = styled.div`
  width: 100vw;
`
const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  font-family: 'Prompt';
  color: #333333;
  max-width: 900px;
  padding-top: 20px;
  padding-bottom: 30px;

  @media (max-width: 1024px) {
    max-width: 746px;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }
`
const WraperFundInfo = styled.div`
  display: flex;
`
const CompanyLogo = styled.img`
  width: 100px;
  height: 100px;
  ${props =>
    responsive.mobile(css`
      margin-right: 20px;
      width: 100px;
      height: 100px;
    `)};
`
const FundWrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 30px;

  @media (max-width: 800px) {
    justify-content: space-between;
    margin: 0;
    padding: 0 10px;
  }
`
const ButtonWrapper = styled.div`
  width: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 540px) {
    margin-top: 20px;
    align-items: center;
  }
`
const ItemWrapper = styled.div`
  display: flex;
  margin: 5px 0px;
  @media (max-width: 540px) {
  }
`
const ItemWrapperFaverite = styled(ItemWrapper)`
  @media (max-width: 560px) {
    margin: 5px 0px;
    display: none;
  }
`
const WrapFavMobile = styled(ItemWrapper)`
  display: none;
  @media (max-width: 560px) {
    margin: 5px 0px;
    display: block;
  }
`

const Nav = styled.span`
  font-size: 18px;
  margin-right: 5px;
  font-weight: ${props => props.theme.BOLD};
  @media (max-width: 425px) {
    font-size: 12px;
  }
`
const Header = styled.div`
  font-size: 20px;
  font-weight: ${props => props.theme.BOLD};
`
const NameFund = styled.div`
  font-size: 16px;
  @media (max-width: 500px) {
    white-space: nowrap;
    width: 159px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    :hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  .tooltip {
    position: absolute;
    bottom: 30px;
    background-color: #555;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
  }
`
const NormalText = styled(NameFund)`
  font-size: 14px;
`
const SmallText = styled.div`
  font-size: 12px;
  margin-left: 7px;
`
const HashTag = styled.div`
  font-size: 12px;
  color: #265fa7;
`
const CustomButton = styled(Button)`
  min-width: 100px;
  margin: 0 0 0 13px;
  font-size: 12px;
`
const BenefitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 117px;
  background-color: rgba(106, 148, 203, 0.1);
`
const WrapFundInfo = styled.div`
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 21px 0;
  @media (max-width: 1360px) {
    width: calc(100vw - 30%);
  }
  @media (max-width: 1024px) {
    width: 746px;
  }
  @media (max-width: 500px) {
    width: 320px;
    align-items: flex-start;
  }
  @media (max-width: 425px) {
    padding: 15px 0;
  }
`
const FundDetails = styled.div`
  flex: 1;
  padding-right: 40px;
  padding-left: 40px;
  font-size: 16px;
  :last-child {
    padding-left: 40px;
    padding-right: 40px;
    border-left: 1px solid #6a94cb;
  }
  @media (max-width: 1024px) {
    font-size: 15px;
    padding-right: 20px;
    padding-left: 0;
    :last-child {
      padding-left: 20px;
      padding-right: 0;
    }
  }
  @media (max-width: 800px) {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    :last-child {
      padding-left: 28px;
    }
  }
  @media (max-width: 425px) {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 12px;
    :last-child {
      border-left: none;
      padding-right: 0px;
      padding-left: 10px;
    }
  }
  @media (max-width: 320px) {
    padding-left: 10px;
    :last-child {
      padding-left: 5px;
    }
  }
`
const RowDetails = styled.div`
  display: flex;
  align-items: center;
  :first-child {
    margin-top: 7px;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
    height: 40px;
  }
  @media (max-width: 425px) {
    :first-child {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    :not(:last-child) {
      margin-bottom: 13px;
    }
  }
  @media (max-width: 320px) {
    height: 36px;
  }
`
const ColDetails = styled.div`
  width: 40%;
  font-weight: ${props => props.theme.NORMAL};
  display: flex;
  align-items: center;
  :first-child {
    width: 60%;
    font-weight: ${props => props.theme.SEMI};
  }
  @media (max-width: 520px) {
    width: 100%;
    :first-child {
      width: 100%;
    }
  }
`
const ContainerBenefitInfo = styled.div`
  display: flex;
`
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 42px;
  margin-right: 42px;

  ${props =>
    responsive.tablet(css`
      margin-left: 30px;
      margin-right: 30px;
    `)};

  ${props =>
    responsive.mobile(css`
      margin-left: 10px;
      margin-right: 10px;
    `)};
`
const CheckIconInfo = styled.div`
  font-size: 16px;
  color: ${props =>
    props.isbenefit === 'true' ? props.theme.GREEN : props.theme.GREY80};
  opacity: ${props => (props.isbenefit === 'true' ? 1 : 0.3)};
  margin-top: 15px;
  display: flex;
  align-items: center;
  ${props =>
    responsive.mobile(css`
      margin-top: 0;
    `)};
`
const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: ${props =>
    props.isbenefit === 'true' ? props.theme.GREEN : props.theme.GREY80};
  opacity: ${props => (props.isbenefit === 'true' ? 1 : 0.3)};
  margin-right: 10px;

  @media (max-width: 500px) {
    margin-bottom: 0px;
  }
`
const CheckIconPDF = styled(CheckIcon)`
  color: #333333;
  opacity: 1;
  font-size: 16px;
`
const CustomTagA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  overflow: hidden;
  border-radius: 20px;
  border: #ffb933 1px solid;
  color: black;
  background-color: white;
  width: fit-content;
  font-size: 12px;
  height: 30px;
  :hover,
  :active {
    color: black;
    text-decoration: unset;
  }
  @media (max-width: 800px) {
    margin: 0;
    padding: 0 10px;
  }
`
const UpdatedAtDesktop = styled.div`
  font-size: 12px;
  margin: 5px 0;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const UpdatedAtMobile = styled.div`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: block;
      font-size: 12px;
    `)};
`
const WrapReturn = styled.span`
  color: ${props => (props.neg ? '#FF0034' : props.theme.GREEN)};
`
const HideOnMoblie = styled.div`
  margin-right: 5px;
  @media (max-width: 500px) {
    display: none;
  }
`

const Benefitinfo = ({fund}) => (
  <ContainerBenefitInfo>
    <CheckContainer>
      <CheckIcon
        isbenefit={
          fund.taxSavingType && fund.taxSavingType !== '' ? 'true' : 'false'
        }
        icon={['fas', 'check-circle']}
      />
      <CheckIconInfo
        isbenefit={
          fund.taxSavingType && fund.taxSavingType !== '' ? 'true' : 'false'
        }>
        ลดหย่อนภาษี
      </CheckIconInfo>
    </CheckContainer>
    <CheckContainer>
      <CheckIcon
        isbenefit={(fund.yieldPolicy === 'จ่าย').toString()}
        icon={['fas', 'check-circle']}
      />
      <CheckIconInfo isbenefit={(fund.yieldPolicy === 'จ่าย').toString()}>
        จ่ายปันผล
      </CheckIconInfo>
    </CheckContainer>
    <CheckContainer>
      <CheckIcon
        isbenefit={(fund.aimcSubcategory === 'ต่างประเทศ').toString()}
        icon={['fas', 'check-circle']}
      />
      <CheckIconInfo
        isbenefit={(fund.aimcSubcategory === 'ต่างประเทศ').toString()}>
        ลงทุนต่างประเทศ
      </CheckIconInfo>
    </CheckContainer>
  </ContainerBenefitInfo>
)

export default props => {
  const {fund} = props
  const {fundCompany} = fund
  const fundCompanyNameTh = fundCompany ? fundCompany.shortNameTh : ''
  const fundCompanyLogo = fundCompany ? fundCompany.logoThumb : ''

  const {
    nameEn,
    nameTh,
    aimcCategory,
    taxSavingType,
    latestNavDate,
    refId,
    factsheetUrl,
    yieldPolicy,
    aimcSubcategory,
    latestNav,
    riskLevel,
    dailyReturn,
    valuation,
    changeRate,
    symbol,
  } = fund
  return (
    <Wrapper>
      <InfoWrapper>
        <WraperFundInfo>
          <CompanyLogo src={fundCompanyLogo} />
          <FundWrapper>
            <Header>{symbol}</Header>
            <NameFund>
              <div className="tooltip">{nameTh}</div>
              {nameTh}
            </NameFund>
            <NormalText>{fundCompanyNameTh}</NormalText>
            {/* <HashTag>
              {aimcCategory ? `#${aimcCategory} ` : ''}
              &nbsp;
              {taxSavingType ? `#${taxSavingType.toUpperCase()}` : ''}
            </HashTag> */}
            <UpdatedAtMobile>
              ข้อมูล ณ วันที่ {getThaiDate(latestNavDate, 'LL')}
            </UpdatedAtMobile>
          </FundWrapper>
          <WrapFavMobile>
            <FavoriteButton refId={refId} />
          </WrapFavMobile>
        </WraperFundInfo>
        <ButtonWrapper>
          <ItemWrapperFaverite>
            <FavoriteButton refId={refId} />
            <SmallText>บันทึกเป็นรายการโปรด</SmallText>
          </ItemWrapperFaverite>
          <ItemWrapper>
            <CustomTagA href={factsheetUrl} target="_blank">
              <CheckIconPDF icon={['far', 'file-pdf']} />
              <HideOnMoblie>ดาวน์โหลด </HideOnMoblie> Fund Fact
            </CustomTagA>
            {/* <CustomButton type="border">ดาวน์โหลด Fund Fact</CustomButton> */}
            {/* TODO::
              When have sell/buy order funds
              <CustomButton>สนใจซื้อ</CustomButton> 
            */}
          </ItemWrapper>
          <UpdatedAtDesktop>
            ข้อมูล ณ วันที่ {getThaiDate(latestNavDate, 'LL')}
          </UpdatedAtDesktop>
        </ButtonWrapper>
      </InfoWrapper>
      {/* <BenefitWrapper>{<Benefitinfo fund={fund} />}</BenefitWrapper> */}
      <BenefitWrapper>
        <WrapFundInfo>
          <FundDetails>
            <RowDetails>
              <ColDetails>มูลค่าหน่วยลงทุนล่าสุด (NAV)</ColDetails>
              <ColDetails>
                <Nav>{displayCurrency(latestNav, 4)}</Nav> บาท/หน่วย
              </ColDetails>
            </RowDetails>
            <RowDetails>
              <ColDetails>วันที่อัพเดท</ColDetails>
              <ColDetails>
                ณ วันที่ {getThaiDate(latestNavDate, 'll')}
              </ColDetails>
            </RowDetails>
            <RowDetails>
              <ColDetails>ผลตอบแทนล่าสุด</ColDetails>
              <ColDetails>
                <WrapReturn neg={dailyReturn < 0}>
                  {displayCurrency(dailyReturn, 4) || 'N/A'} (
                  {displayCurrency(changeRate, 4) || 'N/A'}%)
                </WrapReturn>
              </ColDetails>
            </RowDetails>
            <RowDetails>
              <ColDetails>ขนาดกองทุน</ColDetails>
              <ColDetails>
                {displayCurrency(valuation / 1000000, 2) || 'N/A'} ล้านบาท
              </ColDetails>
            </RowDetails>
          </FundDetails>
          <FundDetails>
            <RowDetails>
              <ColDetails>ระดับความเสี่ยง</ColDetails>
              <ColDetails>
                <RiskLevel risk={riskLevel} details />
              </ColDetails>
            </RowDetails>
            <RowDetails>
              <ColDetails>นโยบายการจ่ายเงินปันผล</ColDetails>
              <ColDetails>
                <CheckIcon
                  isbenefit={(yieldPolicy === 'จ่าย').toString()}
                  icon={[
                    'fas',
                    yieldPolicy === 'จ่าย' ? 'check-circle' : 'times-circle',
                  ]}
                />
                {yieldPolicy}
              </ColDetails>
            </RowDetails>
            <RowDetails>
              <ColDetails>สิทธิลดหย่อนภาษี</ColDetails>
              <ColDetails>
                <CheckIcon
                  isbenefit={taxSavingType ? 'true' : 'false'}
                  icon={[
                    'fas',
                    taxSavingType ? 'check-circle' : 'times-circle',
                  ]}
                />
                {taxSavingType
                  ? `จ่าย ประเภท ${taxSavingType.toUpperCase()}`
                  : 'ไม่จ่าย'}
              </ColDetails>
            </RowDetails>
            <RowDetails>
              <ColDetails>นโยบายลงทุน</ColDetails>
              <ColDetails>
                {aimcSubcategory
                  ? aimcSubcategory == 'ในประเทศ'
                    ? 'ภายในประเทศ'
                    : aimcSubcategory
                  : 'N/A'}
              </ColDetails>
            </RowDetails>
          </FundDetails>
        </WrapFundInfo>
      </BenefitWrapper>
    </Wrapper>
  )
}
