import React from 'react'
import styled, {css} from 'react-emotion'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const ImageGraph = styled.img`
  filter: hue-rotate(-65deg) saturate(3);
  width: 100%;
  max-width: 780px;
`
const LinkToTabel = styled.div`
  color: #ffb933;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`
const CustomLightBox = css`
  background: white;
`
export default props => {
  const {imgPath, openLightBox, setOpenLightBox} = props
  return (
    <div>
      {openLightBox && (
        <Lightbox
          wrapperClassName={CustomLightBox}
          mainSrc={imgPath}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </div>
  )
}
