import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm, formValueSelector} from 'redux-form'
import {compose, withHandlers, lifecycle, withState} from 'recompose'
import {connect} from 'react-redux'
import withSEO from '../share/withSEO'

import Button from '../share/Button'
import Field from '../share/Field'
import {FacebookLoginButton} from './FacebookAuth'
import ModalConsent from './ModalConsent'
import GoogleAuth from './GoogleAuth'

import {register, setData, facebookLogin} from '../../ducks/auth'
import {responsive} from '../../core/style'
import {isEmail, isPasswordNoon} from '../../core/helper'

const CustomButton = styled(Button)`
  min-width: 200px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;

  ${props =>
    responsive.tablet(css`
      height: 40px;
    `)}
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }
`

const Password = styled(GoogleInput)`
  margin: 0 0 15px;
`

const ContainerField = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 30px;
`

const Register = ({
  handleSubmit,
  modal,
  onSetConfirmEmail,
  setStatusBar,
  errorMessage,
  facebookLogin,
  isShowModal,
  setIsShowModal,
  valid,
}) => {
  return (
    <ContainerField>
      <GoogleInput
        name="firstNameTh"
        type="googleInput"
        placeholder="ชื่อ"
        hideError
        didChange={value => setStatusBar(value, 1)}
      />
      <GoogleInput
        name="lastNameTh"
        type="googleInput"
        placeholder="นามสกุล"
        hideError
        didChange={value => setStatusBar(value, 2)}
      />
      <GoogleInput
        name="email"
        type="googleInput"
        placeholder="อีเมล"
        hideError
        didChange={value => setStatusBar(value, 3, 'email')}
      />
      <GoogleInput
        name="password"
        type="googleInput"
        mode="password"
        placeholder="รหัสผ่าน"
        className="password"
        hideError
        didChange={value => setStatusBar(value, 4, 'password')}
      />
      <Password
        name="passwordConfirmation"
        type="googleInput"
        mode="password"
        placeholder="ยืนยันรหัสผ่าน"
        className="password"
        hideError
        didChange={value => setStatusBar(value, 5, 'passwordConfirmation')}
      />
      <CustomButton
        id="register-email"
        onClick={() => setIsShowModal(true)}
        disabled={!valid}
        errorMessage={errorMessage}
        icons={['fa', 'user-circle']}>
        ลงทะเบียน
      </CustomButton>
      <FacebookLoginButton
        id="register-facebook"
        name={'ลงทะเบียนผ่าน Facebook'}
        facebookLogin={facebookLogin}
      />
      <GoogleAuth id="register-google" name="ลงทะเบียนผ่าน Google" />
      <ModalConsent
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        modal={modal}
        onSetConfirmEmail={onSetConfirmEmail}
        handleSubmit={handleSubmit}
      />
    </ContainerField>
  )
}

const validate = values => {
  const errors = {}
  if (!values.firstNameTh) {
    errors.firstNameTh = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastNameTh) {
    errors.lastNameTh = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  if (!values.password) {
    errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPasswordNoon(values.password)) {
    errors.password = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'รหัสผ่านไม่ตรงกัน'
  } else if (!isPasswordNoon(values.passwordConfirmation)) {
    errors.passwordConfirmation = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  }

  return errors
}

const selector = formValueSelector('register')

const enhancer = compose(
  withSEO(
    'เข้าสู่ระบบ - วางแผน คัดเลือก หาข้อมูลแบบประกันภัยเฉพาะคุณ',
    'ลงทะเบียนเพื่อเริ่มทำการวางแผนการเลือกซื้อแบบประกันที่เหมาะสมของคุณที่นี่',
    'ลงทะเบียน,เข้าสู่ระบบ,เลือกประกัน,ซื้อประกัน',
  ),
  connect(
    (state, props) => ({
      name: selector(state, 'name'),
      lastname: selector(state, 'lastname'),
      email: selector(state, 'email'),
      password: selector(state, 'password'),
      passwordConfirmation: selector(state, 'passwordConfirmation'),
      errorMessage: state.auth.errorMessage,
    }),
    {register, setData, facebookLogin},
  ),
  reduxForm({
    form: 'register',
    validate,
    onChange: (values, _, props) => {
      props.errorMessage !== '' && props.setData({errorMessage: ''})
    },
  }),
  withState('isShowModal', 'setIsShowModal', false),
  withHandlers({
    setStatusBar: ({setItem, item, password, passwordConfirmation}) => (
      value,
      index,
      type = 'normal',
    ) => {
      const addItem = () => !item.includes(index) && setItem([...item, index])
      const deleteItem = () => {
        const buffer = item.filter(i => i !== index)
        setItem(buffer)
      }

      const condition = func => {
        if (func) {
          addItem()
        } else {
          deleteItem()
        }
      }

      if (value) {
        switch (type) {
          case 'normal':
            addItem()
            break
          case 'email':
            condition(isEmail(value))
            break
          case 'password':
            condition(isPasswordNoon(value))
            break
          case 'passwordConfirmation':
            condition(passwordConfirmation === password)
        }
      } else {
        deleteItem()
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {setItem, setData} = this.props
      setItem([])
      setData({errorMessage: ''})
    },
    componentWillUnmount() {
      const {setData} = this.props
      setData({errorMessage: ''})
    },
  }),
)

export default enhancer(Register)
