import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {
  compose,
  branch,
  renderNothing,
  withState,
  withHandlers,
  lifecycle,
  withProps,
} from 'recompose'
import {uniq} from 'lodash'
import {navigate} from '@reach/router'
import {withAnswers} from '../enhances'
import Product from '../TaxCalculator/Product/index'
import taxHeader from '../assets/images/landing-header.png'
import {setDataProduct} from '../../../ducks/product'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  SpidusManager,
  Currency,
  ButtonGroup,
  SliderBar,
  Dropdown,
  Tax,
  DatePicker,
  Collapse,
  PenCurrency,
  TextInput,
} from '../components/index'
import Button from '../../share/Button'
import spidusStore from '../core/spidus/spidusStore'
import RdSmartTaxButton from './RdSmartTaxButton'

const MainSpidusManager = styled(SpidusManager)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleWord = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
`

const BasicInfo = styled(FlexColumnCenter)`
  height: 450px;
  position: relative;
  padding: 40px 20px;
  border-radius: 5px;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    padding: 25px 0px;
  }
`
const ImgHeader = styled.img`
  position: absolute;
  top: -80px;
  height: 530px;

  @media (max-width: 1000px) {
    left: -420px;
  }
`
const WrapperBoxInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 920px;
  justify-content: flex-end;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 0px 30px 0px;
    width: 400px;
  }

  @media (max-width: 600px) {
    width: 320px;
  }
`
const PlaningTaxButton = styled(Button)`
  width: 200px !important;
`

const TopicText = styled.div`
  font-size: 30px;
  font-family: 'Prompt';
  font-weight: 700;
  color: #333333;
  text-align: center;
`

const InferTax = styled(FlexColumnCenter)`
  padding: 30px 0px;
  width: 100%;
  top: 45px;
  background: white;
  position: sticky;
  z-index: 1;
  top: -20px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    > div:first-child {
      margin-bottom: 25px;
    }
  }

  @media (max-width: 767px) {
    position: unset;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    > div {
      > div:first-child {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }
  }
`
const AdditionalTax = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  > div {
    flex: 1;
    padding: 0px 5px;
  }
`

const Description = styled(FlexColumnCenter)`
  background-color: rgba(106, 148, 203, 0.2);
  border-radius: 0px;
  padding: 25px 10px 0 10px;
  width: 100%;
`
const SelectionDeduct = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 15px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`
const WrapperMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 507px;
  }
`
const HeaderMenu = styled.div`
  display: none;
  width: 100%;
  > div {
    margin-right: 5px;
  }

  @media (max-width: 1024px) {
    display: flex;
  }
`
const Menu = styled.div`
  background-color: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 15px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  > svg {
    color: #265fa7;
    font-size: 30px;
  }
  > div {
    margin-left: 20px;
    margin-right: 13px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: #265fa7;
  }

  ${props =>
    props.isNotFocus &&
    css`
      opacity: 0.5;
    `}

  ${props =>
    props.disabled &&
    css`
      filter: grayscale(100%);
      pointer-events: none;
    `}

  @media (max-width: 550px) {
    > div {
      display: none;
    }
  }
`

const BodyMenu = styled.div`
  display: flex;
  border-radius: 0px 5px 5px 5px;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0px;

  @media (max-width: 1024px) {
    background-color: #ffffff;
    justify-content: center;
  }
`

const SelectionSliderBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    ${props =>
      props.isNotFocus &&
      css`
        display: none;
      `}
  }
  ${props =>
    props.disabled &&
    css`
      filter: grayscale(100%);
      pointer-events: none;
      > div:nth-child(3) {
        > div:last-child {
          > svg {
            display: none;
          }
        }
      }
    `}
`
const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 40px !important;
`

const CustomLayoutImg = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  margin-bottom: 6px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    text-align: center;
    font-size: 30px;
    color: #265fa7;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
const TitleSlider = styled.div`
  text-align: center;
  font-weight: 600;
  color: #333333;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;

  @media (max-width: 1024) {
    margin: 0px;
  }
`
const CustomStyledSliderBar = css`
  flex-direction: column;
  align-items: center;
  > div:first-child {
    > div:first-child {
      font-weight: 600;
      margin-bottom: 5px;
      color: #265fa7;
    }
    > div {
      text-align: center;
    }
  }
  > div:last-child {
    width: 100%;
  }
`
const CustomStyledCurrency = css`
  > div {
    > input {
      text-align: center;
      font-size: 16px;
    }
  }
`

const CustomStyledCard = css`
  max-width: 300px;
  margin-top: 30px;
  > div:last-child {
    width: unset;
    margin-top: 10px;
    margin-bottom: 5px;
    > div {
      margin-bottom: 0px;
    }
  }
`
const ButtonCollapse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  > div {
    margin-right: 10px;
  }
  @keyframes rollUp {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  @keyframes rollDown {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  > svg {
    ${props =>
      props.collapseOpen
        ? css`
            transform: rotate(0deg);
            animation: rollUp 0.5s;
          `
        : css`
            transform: rotate(180deg);
            animation: rollDown 0.5s;
          `}
  }
`
const WrapperCollapse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  transition: 0.5s;
  padding: 0px 60px;
  ${props =>
    props.collapseOpen
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  @media (max-width: 500px) {
    justify-content: center;
    padding: 0px 20px;
  }
`

const WrapperBasicInfo = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  > div:first-child {
    margin-bottom: 10px;
    > div {
      display: none;
      align-items: center;
      color: #707070;
    }
    > input {
      text-align: center;
      font-size: 16px;
      color: #707070;
    }
  }
  > div:nth-child(2) {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > input {
      text-align: center;
      font-size: 16px;
      color: #707070;
    }
  }
`

const StyleCountdown = css`
  margin-top: 30px;
`

const LayoutFooter = styled(FlexColumnCenter)`
  background: #ffffff;
  width: 100%;
  padding-top: 40px;
`

const CustomCollapse = css`
  max-width: 600px;
  min-width: 253px;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    max-width: 253px;

    > div:last-child {
      > div {
        flex-direction: column;
        > div:last-child {
          max-width: unset;
        }
      }
    }
  }
`
const CustomCurrency = css`
  width: 100%;
  margin-bottom: 20px;
  > div:first-child {
    > div {
      text-align: left;
      font-size: 16px;
      color: #7f7f7f;
    }
    > div:nth-child(2) {
      font-size: 10px;
    }
  }
  > div:last-child {
    max-width: 253px;
    > input {
      text-align: center;
    }
  }
`
export const CustomDatePicker = css`
  width: 100%;
  margin-bottom: 20px;
  > div:first-child {
    > div {
      text-align: left;
      font-size: 16px;
      color: #7f7f7f;
    }
  }
  > div:last-child {
    max-width: 253px;
    > div {
      > div {
        > input {
          text-align: center;
        }
      }
    }
  }
`
export const CustomDropdown = css`
  width: 100%;
  margin-bottom: 20px;
  > div:first-child {
    > div {
      text-align: left;
      font-size: 16px;
      color: #7f7f7f;
    }
  }
  > div:last-child {
    max-width: 253px;
    width: 100%;
    > div {
      text-align: center;
    }
  }
`
export const CustomTextInput = css`
  width: 100%;
  margin-bottom: 20px;
  > div:first-child {
    > div {
      font-size: 16px;
      color: #7f7f7f;
    }
    > div:nth-child(2) {
      font-size: 10px;
    }
  }
  > div:last-child {
    max-width: 253px;
    > input {
      text-align: center;
    }
  }
`

const RdSmartTaxRemark = styled.div`
  color: rgb(255, 0, 52);
  font-size: 12px;
  margin: 10px 0px 30px 0px;
  max-width: 366px;
`

const TaxCalculator = props => (
  <MainSpidusManager configName="noontax_config_web_calculator">
    <BasicInfo>
      <ImgHeader src={taxHeader} />
      <WrapperBoxInfo>
        <div style={{zIndex: '1'}}>
          <TopicText style={{marginBottom: '5px'}}>วางแผนภาษี</TopicText>
          <div
            style={{
              marginBottom: '25px',
              color: '#333333',
              fontSize: '14px',
            }}>
            จ่ายเงินเดือนเท่านี้จะลดหย่อนภาษีได้เท่าไหร่
          </div>
          <ButtonGroup className={WrapperBasicInfo} answerName="gender" />
          <Currency
            answerName="age"
            className={WrapperBasicInfo}
            placeholder="อายุ"
            maxLength={3}
          />
          <Currency
            answerName="total_salary"
            className={WrapperBasicInfo}
            placeholder="เงินเดือนของคุณ"
          />
          <PlaningTaxButton onClick={props.onClickPlanningTaxButton}>
            เริ่มวางแผนภาษี
          </PlaningTaxButton>
        </div>
      </WrapperBoxInfo>
    </BasicInfo>

    <InferTax>
      <div>
        <TopicText>สรุปภาษี</TopicText>
        <AdditionalTax>
          <Tax
            caption="ภาษีก่อนวางแผน"
            answerName="before_plan_tax"
            positivePrefix="จ่ายเพิ่ม"
            negativePrefix="ได้คืน"
            positiveColor="#FF0034"
            negativeColor="#9FD134"
          />
          <Tax
            caption="ภาษีหลังวางแผน"
            answerName="after_plan_tax"
            positivePrefix="จ่ายเพิ่ม"
            negativePrefix="ได้คืน"
            positiveColor="#FF0034"
            negativeColor="#9FD134"
          />
          <Tax
            caption="วางแผนแบบนี้ประหยัดภาษีได้"
            shortCaption="ประหยัดภาษีได้"
            answerName="saving_plan_tax"
            valueColor="#FFB933"
          />
        </AdditionalTax>
      </div>
    </InferTax>

    <Description>
      <SelectionDeduct>
        <TopicText style={{marginBottom: '20px'}}>
          ลดหย่อนภาษีด้วยแบบประกัน
        </TopicText>
        <WrapperMenu>
          <HeaderMenu>
            <Menu
              isNotFocus={props.interestedInsure !== 'life'}
              onClick={() => props.setInterestedInsure('life')}
              disabled={
                props.additoinalInsuranceLife.min >=
                props.additoinalInsuranceLife.maxCap
              }>
              <FontAwesomeIcon icon={['fas', 'user-shield']} />
              <div hidden={props.interestedInsure !== 'life'}>
                ซื้อประกันชีวิตแบบทั่วไปเพิ่ม
              </div>
            </Menu>
            <Menu
              isNotFocus={props.interestedInsure !== 'pension'}
              onClick={() => props.setInterestedInsure('pension')}
              disabled={
                props.additoinalInsurancePension.min >=
                props.additoinalInsurancePension.maxCap
              }>
              <CustomFontAwesomeIcon icon={['fas', 'man-with-cane']} />
              <div hidden={props.interestedInsure !== 'pension'}>
                ซื้อประกันชีวิตแบบบำนาญเพิ่ม
              </div>
            </Menu>
            <Menu
              isNotFocus={props.interestedInsure !== 'health'}
              onClick={() => props.setInterestedInsure('health')}
              disabled={
                props.additoinalInsuranceHealth.min >=
                props.additoinalInsuranceHealth.maxCap
              }>
              <FontAwesomeIcon icon={['fas', 'briefcase-medical']} />
              <div hidden={props.interestedInsure !== 'health'}>
                ซื้อประกันสุขภาพตนเองเพิ่ม
              </div>
            </Menu>
          </HeaderMenu>
          <BodyMenu>
            <div>
              <SelectionSliderBar
                isNotFocus={props.interestedInsure !== 'life'}
                disabled={
                  props.additoinalInsuranceLife.min >=
                  props.additoinalInsuranceLife.maxCap
                }>
                <CustomLayoutImg>
                  <FontAwesomeIcon icon={['fas', 'user-shield']} />
                </CustomLayoutImg>
                <TitleSlider>ซื้อประกันชีวิตแบบทั่วไปเพิ่ม</TitleSlider>

                <PenCurrency
                  answerName="planning_deduct_life_insurance"
                  onChange={() => props.setIsTryWholelife(false)}
                />
                <SliderBar
                  className={CustomStyledSliderBar}
                  caption="ซื้อประกันชีวิตแบบทั่วไปเพิ่ม"
                  info="(ยังใช้สิทธิ์ขาดอยู่ ฿99,999)"
                  answerName="planning_deduct_life_insurance"
                  onChange={() => props.setIsTryWholelife(false)}
                />

                <Product
                  className={CustomStyledCard}
                  type="wholelife"
                  age={props.age}
                  amount={props.additoinalInsuranceLife.value}
                  limit={1}
                  delay={0.5}
                  maxCap={props.additoinalInsuranceLife.maxCap}
                  isTryThis={props.isTryWholelife}
                  onTryThisPremium={values => {
                    props.setIsTryWholelife(values.toggleValue)
                    props.onTryThis(
                      'planning_deduct_life_insurance',
                      values.value,
                    )
                  }}
                />
              </SelectionSliderBar>
            </div>
            <div>
              <SelectionSliderBar
                isNotFocus={props.interestedInsure !== 'pension'}
                disabled={
                  props.additoinalInsurancePension.min >=
                  props.additoinalInsurancePension.maxCap
                }>
                <CustomLayoutImg>
                  <CustomFontAwesomeIcon icon={['fas', 'man-with-cane']} />
                </CustomLayoutImg>
                <TitleSlider>ซื้อประกันชีวิตแบบบำนาญเพิ่ม</TitleSlider>

                <PenCurrency
                  answerName="planning_deduct_pension_insurance"
                  onChange={() => props.setIsTryAnnuity(false)}
                />
                <SliderBar
                  className={CustomStyledSliderBar}
                  caption="ซื้อประกันชีวิตแบบบำนาญเพิ่ม"
                  info="(ยังใช้สิทธิ์ขาดอยู่ ฿99,498)"
                  answerName="planning_deduct_pension_insurance"
                  onChange={() => props.setIsTryAnnuity(false)}
                />

                <Product
                  className={CustomStyledCard}
                  type="annuity"
                  age={props.age}
                  amount={props.additoinalInsurancePension.value}
                  limit={1}
                  delay={0.5}
                  maxCap={props.additoinalInsurancePension.maxCap}
                  isTryThis={props.isTryAnnuity}
                  onTryThisPremium={values => {
                    props.setIsTryAnnuity(values.toggleValue)
                    props.onTryThis(
                      'planning_deduct_pension_insurance',
                      values.value,
                    )
                  }}
                />
              </SelectionSliderBar>
            </div>
            <div>
              <SelectionSliderBar
                isNotFocus={props.interestedInsure !== 'health'}
                disabled={
                  props.additoinalInsuranceHealth.min >=
                  props.additoinalInsuranceHealth.maxCap
                }>
                <CustomLayoutImg>
                  <FontAwesomeIcon icon={['fas', 'briefcase-medical']} />
                </CustomLayoutImg>
                <TitleSlider>ซื้อประกันสุขภาพตนเองเพิ่ม</TitleSlider>

                <PenCurrency
                  answerName="planning_deduct_health_insurance"
                  onChange={() => props.setIsTryHealth(false)}
                />
                <SliderBar
                  className={CustomStyledSliderBar}
                  caption="ซื้อประกันสุขภาพตนเองเพิ่ม"
                  info="(ยังใช้สิทธิ์ขาดอยู่ ฿99,498)"
                  answerName="planning_deduct_health_insurance"
                  onChange={() => props.setIsTryHealth(false)}
                />

                <Product
                  className={CustomStyledCard}
                  type="health"
                  age={props.age}
                  amount={props.additoinalInsuranceHealth.value}
                  limit={1}
                  delay={0.5}
                  maxCap={props.additoinalInsuranceHealth.maxCap}
                  isTryThis={props.isTryHealth}
                  onTryThisPremium={values => {
                    props.setIsTryHealth(values.toggleValue)
                    props.onTryThis(
                      'planning_deduct_health_insurance',
                      values.value,
                    )
                  }}
                />
              </SelectionSliderBar>
            </div>
          </BodyMenu>
        </WrapperMenu>
      </SelectionDeduct>
    </Description>

    <LayoutFooter>
      <ButtonCollapse
        onClick={props.onCollapseOpen}
        collapseOpen={props.collapseOpen}>
        <TitleWord style={{fontWeight: 'normal'}}>
          ใส่ค่าลดหย่อนอย่างละเอียด
        </TitleWord>
        <FontAwesomeIcon icon={['fas', 'chevron-up']} />
      </ButtonCollapse>

      <WrapperCollapse collapseOpen={props.collapseOpen}>
        <Collapse
          className={CustomCollapse}
          caption="ตัวผู้เสียภาษี"
          setIsOpen={open => props.toggleCollapse(1, open)}
          isOpen={props.collapseOpenList.includes(1)}>
          <Currency
            className={CustomCurrency}
            answerName="deduct_personal"
            disabled
          />
          <DatePicker
            className={CustomDatePicker}
            answerName="birthdate"
            maxDate={new Date()}
            placeholder="วว/ดด/ปปปป"
          />
          <Dropdown
            className={CustomDropdown}
            answerName="handicap_status"
            placeholder="ระบุ"
          />
          <Dropdown
            className={CustomDropdown}
            answerName="married_status"
            placeholder="ระบุ"
            onAfterBlur={() => props.toggleCollapse(2, true, true)}
          />
        </Collapse>
        <Collapse
          className={CustomCollapse}
          caption="เงินเดือนตาม 40(1)"
          setIsOpen={open => props.toggleCollapse(2, open)}
          isOpen={props.collapseOpenList.includes(2)}>
          <Currency className={CustomCurrency} answerName="total_income" />
          <Currency
            className={CustomCurrency}
            answerName="withholding_tax"
            onAfterBlur={() => props.toggleCollapse(3, true, true)}
          />
        </Collapse>

        {/* <Collapse
          className={CustomCollapse}
          caption="ครอบครัว"
          setIsOpen={open => props.toggleCollapse(3, open)}
          isOpen={props.collapseOpenList.includes(3)}>
          <Dropdown
            className={CustomDropdown}
            answerName="deduct_spouse"
            placeholder="ระบุ"
          />
          <Dropdown
            className={CustomDropdown}
            answerName="deduct_parent_amount"
            placeholder="ระบุ"
          />
          <Dropdown
            className={CustomDropdown}
            answerName="deduct_children_amount"
            placeholder="ระบุ"
          />
          <Dropdown
            className={CustomDropdown}
            answerName="deduct_handicap_amount"
            placeholder="ระบุ"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_pregnancy"
            onAfterBlur={() => props.toggleCollapse(4, true, true)}
          />
        </Collapse> */}

        <Collapse
          className={CustomCollapse}
          caption="ไลฟ์สไตล์"
          setIsOpen={open => props.toggleCollapse(4, open)}
          isOpen={props.collapseOpenList.includes(4)}>
          <Currency
            className={CustomCurrency}
            answerName="deduct_travel_secondary"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_travel_primary"
          />
          <Currency className={CustomCurrency} answerName="deduct_shopping" />
          <Currency
            className={CustomCurrency}
            answerName="deduct_shopping_sport_and_edu"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_shopping_otop"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_shopping_ebook"
            onAfterBlur={() => props.toggleCollapse(5, true, true)}
          />
        </Collapse>

        <Collapse
          className={CustomCollapse}
          caption="ประกัน"
          setIsOpen={open => props.toggleCollapse(5, open)}
          isOpen={props.collapseOpenList.includes(5)}>
          <Currency
            className={CustomCurrency}
            answerName="deduct_life_insurance"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_pension_insurance"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_health_insurance"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_parent_health_insurance"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_sso"
            onAfterBlur={() => props.toggleCollapse(6, true, true)}
          />
        </Collapse>

        <Collapse
          className={CustomCollapse}
          caption="กองทุน"
          button={{
            name: 'ดูกองทุนเพื่อลดหย่อน',
            onClick: () => {
              window.open(`/fund`, '_blank')
            },
          }}
          setIsOpen={open => props.toggleCollapse(6, open)}
          isOpen={props.collapseOpenList.includes(6)}>
          <Currency className={CustomCurrency} answerName="deduct_ltf" />
          <Currency className={CustomCurrency} answerName="deduct_rmf" />
          <Currency
            className={CustomCurrency}
            answerName="deduct_provident_fund"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_nsf"
            onAfterBlur={() => props.toggleCollapse(7, true, true)}
          />
        </Collapse>

        <Collapse
          className={CustomCollapse}
          caption="บริจาค"
          setIsOpen={open => props.toggleCollapse(7, open)}
          isOpen={props.collapseOpenList.includes(7)}>
          <Currency
            className={CustomCurrency}
            answerName="deduct_special_donate"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_general_donate"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_donate_party"
            onAfterBlur={() => props.toggleCollapse(8, true, true)}
          />
        </Collapse>

        <Collapse
          className={CustomCollapse}
          caption="อื่นๆ"
          setIsOpen={open => props.toggleCollapse(8, open)}
          isOpen={props.collapseOpenList.includes(8)}>
          <Currency
            className={CustomCurrency}
            answerName="deduct_homeloan_interest"
          />
          <Currency className={CustomCurrency} answerName="deduct_startup" />
          <Currency
            className={CustomCurrency}
            answerName="deduct_cure_flood_damage_house"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_cure_flood_damage_car"
          />
          <Currency
            className={CustomCurrency}
            answerName="deduct_debit_card"
            onAfterBlur={() => props.toggleCollapse(8, false, true)}
          />
          <TextInput
            className={CustomCurrency}
            answerName="pgd_personal_firstname"
          />
          <TextInput
            className={CustomCurrency}
            answerName="pgd_personal_lastname"
          />
          <TextInput className={CustomTextInput} answerName="pgd_personal_id" />
          <TextInput
            className={CustomTextInput}
            answerName="pgd_payer_id_40_1_2"
          />
          <TextInput
            className={CustomTextInput}
            answerName="pgd_address_house_no"
          />
          <TextInput
            className={CustomTextInput}
            answerName="pgd_address_sub_district"
          />
          <TextInput
            className={CustomTextInput}
            answerName="pgd_address_district"
          />
          <TextInput
            className={CustomTextInput}
            answerName="pgd_address_province"
          />
          <TextInput
            className={CustomTextInput}
            answerName="pgd_address_zip_code"
          />
        </Collapse>
      </WrapperCollapse>

      {/* <Text className={StyleCountdown} answerName="countdown" /> */}

      {!props.hiddenButton && (
        <>
          <RdSmartTaxButton
            isOpen={props.isOpenRdSmartTaxModal}
            onClose={props.closeRdSmartTaxModal}>
            เตรียมยื่นภาษี
          </RdSmartTaxButton>
          <RdSmartTaxRemark>
            รองรับเฉพาะการยื่นแบบ ภ.ง.ด.91 และไม่รองรับค่าลดย่อนบ้านหลังแรก,
            ค่าลดหย่อนบุคคล เช่น พ่อ, แม่, คู่สมรส, ลูก หรือผู้พิการ
          </RdSmartTaxRemark>
        </>
      )}
    </LayoutFooter>
  </MainSpidusManager>
)

const enhance = compose(
  connect(
    state => ({
      initialized: state.auth.initialized,
    }),
    {setDataProduct},
  ),
  branch(props => !props.initialized, renderNothing),
  withAnswers('noontax_config_web_calculator', {
    age: 'age.value',
    gender: 'gender.value',
    totalSalary: 'total_salary.value',
    additoinalInsuranceLife: 'planning_deduct_life_insurance',
    additoinalInsurancePension: 'planning_deduct_pension_insurance',
    additoinalInsuranceHealth: 'planning_deduct_health_insurance',
  }),
  withProps(props => ({
    additoinalInsuranceLife: props.additoinalInsuranceLife || {},
    additoinalInsurancePension: props.additoinalInsurancePension || {},
    additoinalInsuranceHealth: props.additoinalInsuranceHealth || {},
  })),
  lifecycle({
    componentDidMount() {
      this.props.setDataProduct({noOpacityHeader: true})
    },
  }),
  withState('collapseOpen', 'setCollapseOpen', false),
  withState('interestedInsure', 'setInterestedInsure', 'life'),
  withState('isTryWholelife', 'setIsTryWholelife', false),
  withState('isTryAnnuity', 'setIsTryAnnuity', false),
  withState('isTryHealth', 'setIsTryHealth', false),
  withState('collapseOpenList', 'setCollapseOpenList', [1]),
  withHandlers({
    onCollapseOpen: props => () => {
      props.setCollapseOpen(!props.collapseOpen)
    },
    onTryThis: () => (field, value) => {
      value = parseInt(value)
      spidusStore.changeAnswerValue(
        'noontax_config_web_calculator',
        field,
        value,
      )
      spidusStore.updateAnswer('noontax_config_web_calculator', field, value)
    },
    toggleCollapse: props => (key, open, only) => {
      let resolvedOpenList = props.collapseOpenList

      if (only) {
        resolvedOpenList = []
      }

      if (open) {
        resolvedOpenList = uniq([...resolvedOpenList, key])
      } else {
        resolvedOpenList = resolvedOpenList.filter(k => k !== key)
      }
      props.setCollapseOpenList(resolvedOpenList)
    },
    onClickPlanningTaxButton: ({gender, age, totalSalary}) => () => {
      dataLayer.push({
        event: 'start_tax_planning',
        taxPlanningGender: gender,
        taxPlanningAge: age,
        taxPlanningTotalSalary: totalSalary,
      })
    },
  }),
  withProps(props => ({
    hiddenButton:
      window.location.origin === 'https://www.noon.in.th' ? true : false,
  })),
)

export default enhance(TaxCalculator)
