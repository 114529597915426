import React from 'react'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '../share/Button'
import {navigate} from '@reach/router'
import {responsive} from '../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 36px;
  color: ${props => props.theme.BLUE};
`

const IconContainer = styled.div`
  display: flex;
  width: 660px;
  justify-content: space-between;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const InfoIconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: ${props => props.theme.BLUE};
`

const Topic = styled.h1`
  margin: 10px 0 0;

  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.BLUE};
`

const Info = styled.h2`
  margin: 10px 0 0;
  padding: 0 15px;

  text-align: center;
  color: #6a94cb;
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      max-width: 225px;
    `)};
`

const CustomButton = styled(Button)`
  min-width: 120px;
  height: 30px;
  margin-top: 14px;
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 30px;
    `)};
`
const ContainerIcon = styled.div`
  position: relative;
`

const CustomIconBolt = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => props.theme.BLUE};
  position: absolute;
  top: -10px;
  right: -8px;
`

const InfoItem = ({
  iconName,
  topicText,
  infoText,
  buttonText,
  iconTag,
  link,
  inconBolt,
  onClick = () => {},
}) => (
  <InfoIconContainer>
    <ContainerIcon>
      <CustomIcon icon={[iconTag, iconName]} />
      {inconBolt && <CustomIconBolt icon={[iconTag, inconBolt]} />}
    </ContainerIcon>
    <Topic>{topicText}</Topic>
    <Info>{infoText}</Info>
    <CustomButton
      onClick={() => {
        onClick()
        navigate(`/${link}`)
      }}
      icons={[iconTag, iconName]}>
      {buttonText}
    </CustomButton>
  </InfoIconContainer>
)

const Noon = () => (
  <Container>
    <IconContainer>
      <InfoItem
        iconName="search"
        iconTag="fas"
        topicText="NOON SMART"
        inconBolt="bolt"
        infoText="อยากรู้ไหมแบบประกันไหนเหมาะสมกับคุณ"
        buttonText="เช็คแบบประกัน"
        link="smart"
        onClick={() =>
          dataLayer.push({
            event: 'click_goto_noon_smart_button_on_landing',
          })
        }
      />
      <InfoItem
        iconName="book-open"
        iconTag="fas"
        topicText="NOON SQUARE"
        infoText="หลากหลายสิ่งที่น่าสนใจที่ไม่ใช่แค่เรื่องประกัน"
        buttonText="อ่านบทความ"
        link="square"
        onClick={() =>
          dataLayer.push({
            event: 'click_goto_noon_square_button_on_landing',
          })
        }
      />
      <InfoItem
        iconName="lightbulb"
        iconTag="far"
        topicText="NOON LAB"
        infoText="นวัตกรรมใหม่ทางด้านประกันที่คุณไม่ควรพลาด"
        buttonText="ข้อมูลเพิ่มเติม"
        link="lab"
        onClick={() =>
          dataLayer.push({
            event: 'click_goto_noon_lab_button_on_landing',
          })
        }
      />
    </IconContainer>
  </Container>
)
export default Noon
