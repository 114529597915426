import React from 'react'
import styled from 'react-emotion'
import {compose, withHandlers} from 'recompose'
import {Icon} from '../components'

const Layout = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #265fa7;
  border-radius: 5px;
  padding: 20px;
  max-width: 420px;
  cursor: pointer;
  background: white;
  transition: all 0.4s;

  :hover {
    background: #e5e5e5;
  }

  @media screen and (min-width: 500px) {
    min-height: 150px;
  }
`

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 500px) {
    flex-direction: row;
  }
`

const MainIcon = styled(Icon)`
  font-size: 50px;
  color: #265fa7;

  @media screen and (min-width: 500px) {
    margin-right: 20px;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #265fa7;
  margin-top: 14px;

  @media screen and (min-width: 500px) {
    margin-top: 0;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  }
`

const Description = styled.div`
  font-size: 12px;
  font-family: 'Sarabun', sans-serif;
  color: #265fa7;
  margin-top: 5px;
`

const ArrowRight = styled(Icon)`
  font-size: 30px;
  color: #265fa7;
  margin-left: 16px;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const ServiceCard = props => (
  <Layout id={props.id} onClick={props.onClick}>
    <Body>
      <MainIcon icon={props.icon} />
      <Section>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </Section>
    </Body>
    <ArrowRight icon="chevron-right" />
  </Layout>
)

const enhancer = compose(
  withHandlers({
    onClick: props => () => {
      props.onClick && props.onClick()
      if (props.to) {
        window.location.href = props.to
      }
    },
  }),
)

export default enhancer(ServiceCard)
