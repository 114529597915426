import React from 'react'
import styled from 'react-emotion'
import {Button} from 'antd'
import {SpidusManager} from '../components'
import ProfileDetail from './ProfileDetail'
import SearchConsole from './SearchConsole'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const MainSpidusManager = styled(SpidusManager)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`

const ActionButton = styled(Button)`
  margin: 0 30px;
  width: 56px;
  height: 56px;
`

const AddButton = props => (
  <ActionButton onClick={props.show} shape="circle">
    <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} size={52} />
  </ActionButton>
)

const TaxPro = () => (
  <MainSpidusManager configName="noontax_config_web_pro">
    <ProfileDetail />
    <AddButton />
    <SearchConsole />
  </MainSpidusManager>
)

export default TaxPro
