import React from 'react'
import {Tooltip} from 'antd'
import styled from 'react-emotion'

const CustomStyled = styled(Tooltip)`
  .ant-tooltip-content {
    width: fit-content;
    font-size: 12px;
    .ant-tooltip-arrow {
      border-right-color: #7f7f7f;
    }
    .ant-tooltip-inner {
      background-color: #7f7f7f;
    }
  }
`

const CustomTooltip = ({className, ...otherProps}) => (
  <CustomStyled overlayClassName={className} {...otherProps} />
)

export default CustomTooltip
