import React, {useEffect, useState, useMemo} from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Loading from '../../share/Loading'
import {setDataProduct} from '../../../ducks/product'

import fundeeApi from '../../../core/fundeeApi'
import {getThaiDate, displayCurrency, url2Obj} from '../../../core/helper'
import {responsive} from '../../../core/style'

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 0 50px;
  justify-content: center;

  ${props =>
    responsive.tablet(css`
      justify-content: flex-start;

      width: 100%;
      margin-top: 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    `)};
`

const RowContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 99.91px;

  > div:first-child {
    border-radius: 5px 0 0 0;
    overflow: hidden;
  }

  > div:last-child {
    border-radius: 0 0 0 5px;
    margin-bottom: 27px;
    overflow: hidden;
  }

  /* ${props =>
    responsive.tablet(css`
      padding-left: 20px;
    `)}; */
`

const RowHeadContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  min-height: 100px;
  color: white;
  background-color: #265fa7;
  margin: 0 0 2px 0;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;

  &.insurerRight,
  &.insurerWrong {
    padding: 0 40px;
  }
  &.passengerAndDriverAmountCoverage,
  &.passengerAndDriverAccident {
    padding: 0 1px;
  }
  &.passengerAndDriverMedicalFee {
    padding: 0 1px;
  }

  /* ${props =>
    responsive.mobile(css`
      width: 120px;
    `)}; */
`

const RowContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147.8px;
  min-height: 100px;
  color: black;
  background-color: #e5e5e5;
  margin: 0 0 2px 0;
  margin-right: 5.32px;
  text-align: center;
  white-space: pre-wrap;
  padding: 0 17px;
  font-size: ${props => (props.smallFont ? '10px' : '12px')};
  flex-direction: ${props => (props.flexColumn ? 'column' : 'row')};
  cursor: ${props => (props.isCursor ? 'pointer' : 'unset')};

  &.lookForDetails {
    text-decoration: underline;
  }
`

const CustomColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.opacityActive ? 'unset' : '0.5')};

  > div:nth-child(odd) :not(:first-child) {
    background-color: rgba(229, 229, 229, 0.7);
  }
`

const HeadColumnContainer = styled.div`
  display: flex;
`

const HeadColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 147.91px;
  min-height: 100px;
  background-color: #265fa7;
  opacity: ${props => (props.opacityActive ? '1' : '0.5')};
  margin-right: 4.89px;
  border-radius: 5px 5px 0 0;
`

const CompanyImage = styled.img`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  width: 20px;
  height: 20px;
  margin-bottom: 6.44px;
`

const HeadColumnText = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 0 27px;
`
const CheckIcon = styled(FontAwesomeIcon)`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  font-size: 12px;
  color: #9fd134;
`

const UnCheckIcon = styled(FontAwesomeIcon)`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  font-size: 12px;
  color: #ff0034;
`

const Unit = styled.span`
  display: ${props => (props.unitActive ? 'flex' : 'none')};
  font-size: 12px;
  text-align: center;
  margin: ${props => (props.isMargin ? '0 5px' : '0')};
`

const Wrapper = styled.div`
  display: ${props => (props.unitActive ? 'flex' : 'none')};
`

const rowHeadContent = [
  {id: 1, headName: 'ชื่อ บลจ.', key: 'fundCompanyNameTh'},
  {id: 2, headName: 'ชื่อกองทุน', key: 'nameTh'},
  {id: 3, headName: 'ประเภทกองทุน', key: 'aimcCategory'},
  {id: 4, headName: 'ราคาล่าสุด', key: 'latestNav'},
  {id: 5, headName: 'ลดหย่อนภาษี', key: 'isTaxSaving'},
  {id: 6, headName: 'จ่ายปันผล', key: 'hasDividend'},
  {id: 7, headName: 'ลงทุนในต่างประเทศ', key: 'isForignInvestment'},
  {id: 8, headName: 'ระดับความเสี่ยง', key: 'riskLevel'},
  {id: 9, headName: 'ผลตอบแทนย้อนหลัง 1 ปี', key: 'oneYearReturn'},
  {id: 10, headName: 'ผลตอบแทนย้อนหลัง 3 ปี', key: 'threeYearsReturn'},
  {id: 11, headName: 'ผลตอบแทนย้อนหลัง 5 ปี', key: 'fiveYearsReturn'},
  {id: 12, headName: 'ค่าธรรมเนียมต่อปี', key: 'managementFee'},
  {
    id: 13,
    headName: 'จำนวนเงินขั้นต่ำในการซื้อครั้งแรก',
    key: 'minInitPurchase',
  },
  {
    id: 14,
    headName: 'จำนวนเงินขั้นต่ำในการซื้อครั้งต่อไป',
    key: 'minSubseqPurchase',
  },
  {id: 15, headName: 'สินทรัพย์ลงทุน 5 ลำดับแรก', key: 'holdings'},
]

const Check = ({checkStatus, isShow}) =>
  checkStatus ? (
    <CheckIcon icon={['fas', 'check-circle']} isShow={isShow} />
  ) : (
    <UnCheckIcon icon={['fas', 'times-circle']} isShow={isShow} />
  )

export default connect(
  state => {},
  {setDataProduct},
)(props => {
  const {setDataProduct} = props
  const [fundData, setFundData] = useState([
    {fundCompany: {}, topHoldings: []},
    {fundCompany: {}, topHoldings: []},
    {fundCompany: {}, topHoldings: []},
    {fundCompany: {}, topHoldings: []},
    {fundCompany: {}, topHoldings: []},
  ])
  const [fetching, setFetching] = useState(true)

  const refIds = useMemo(() => {
    const params = url2Obj(location.search)
    return params.refIds
  }, [location.search])

  useEffect(() => {
    async function getFund() {
      setFetching(true)
      const include = ['top_holdings_updated_date', 'top_holdings']
      const response = await fundeeApi.get('/funds', {include, refIds})
      const fundData = response.data.funds

      const dataSource = Array.from({length: 5}, (x, i) =>
        i < fundData.length ? fundData[i] : {fundCompany: {}, topHoldings: []},
      )
      setFundData(dataSource)
      setFetching(false)
    }
    getFund(refIds)
  }, [refIds])

  useEffect(() => {
    setDataProduct({noOpacityHeader: true})
    return () => {
      setDataProduct({noOpacityHeader: false})
    }
  }, [])

  return (
    <Loading isLoading={fetching}>
      <TableContainer>
        <RowContentContainer>
          {rowHeadContent.map(row => (
            <RowHeadContent key={row.id}>{row.headName}</RowHeadContent>
          ))}
        </RowContentContainer>
        <HeadColumnContainer>
          {fundData.map((fund = {}) => (
            <CustomColumnContent
              key={fund.refId}
              opacityActive={fund.fundCompany.refId}>
              <HeadColumn opacityActive={fund.fundCompany.refId}>
                <CompanyImage
                  src={fund.fundCompany.logoThumb}
                  isShow={fund.fundCompany.refId}
                />
                <HeadColumnText>{fund.fundCompany.symbol}</HeadColumnText>
                <HeadColumnText>{fund.fundCompany.shortNameTh}</HeadColumnText>
              </HeadColumn>
              <RowContent>{fund.fundCompany.nameTh}</RowContent>
              <RowContent>{fund.nameEn}</RowContent>
              <RowContent>{fund.aimcCategory}</RowContent>
              <RowContent flexColumn={true}>
                <Wrapper unitActive={fund.latestNav}>
                  {displayCurrency(fund.latestNav)}
                  <Unit isMargin={true}>บาท</Unit>
                </Wrapper>
                <Wrapper unitActive={fund.latestNav}>
                  {`(ข้อมูล ณ วันที่\n${getThaiDate(fund.latestNavDate)})`}
                </Wrapper>
              </RowContent>
              <RowContent>
                <Check
                  checkStatus={fund.taxSavingType && fund.taxSavingType !== ''}
                  isShow={fund.fundCompany.refId}
                />
              </RowContent>
              <RowContent>
                <Check
                  checkStatus={fund.yieldPolicy === 'จ่าย'}
                  isShow={fund.fundCompany.refId}
                />
              </RowContent>
              <RowContent>
                <Check
                  checkStatus={fund.aimcSubcategory === 'ต่างประเทศ'}
                  isShow={fund.fundCompany.refId}
                />
              </RowContent>
              <RowContent>{fund.riskLevel}</RowContent>
              <RowContent>
                {displayCurrency(fund.oneYearReturn)}
                <Unit unitActive={fund.oneYearReturn} isMargin={true}>
                  %
                </Unit>
              </RowContent>
              <RowContent>
                {displayCurrency(fund.threeYearsReturn)}
                <Unit unitActive={fund.threeYearsReturn} isMargin={true}>
                  %
                </Unit>
              </RowContent>
              <RowContent>
                {displayCurrency(fund.fiveYearsReturn)}
                <Unit unitActive={fund.fiveYearsReturn} isMargin={true}>
                  %
                </Unit>
              </RowContent>
              <RowContent>
                {displayCurrency(fund.managementFee)}
                <Unit unitActive={fund.managementFee} isMargin={true}>
                  %
                </Unit>
              </RowContent>
              <RowContent>
                {displayCurrency(fund.minInitPurchase)}
                <Unit unitActive={fund.minInitPurchase} isMargin={true}>
                  บาท
                </Unit>
              </RowContent>
              <RowContent>
                {displayCurrency(fund.minSubseqPurchase)}
                <Unit unitActive={fund.minSubseqPurchase} isMargin={true}>
                  บาท
                </Unit>
              </RowContent>
              <RowContent smallFont={true}>
                {fund.topHoldings
                  .slice(0, 5)
                  .map(
                    (holding = {}) =>
                      `${holding.name} (${displayCurrency(
                        holding.percentage,
                      )} %)\n`,
                  )}
              </RowContent>
            </CustomColumnContent>
          ))}
        </HeadColumnContainer>
      </TableContainer>
    </Loading>
  )
})
