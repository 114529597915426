import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import {formValueSelector, change, Field as CustomField} from 'redux-form'
import Tooltip from '../../../../../share/Tooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  displayNumber,
  numberParser,
  inRange,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import Field from '../../../../../share/Field'
import Panel from '../PanelForm'
import {responsive, breakpoints} from '../../../../../../core/style'
import {Unit, TextInput, CustomButton} from '../ShareFieldForm'
import SearchFactor from '../SearchFactor'

const FooterFormContainer = styled.div`
  padding-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 261px;
  background-color: #6a94cb;
  ${props =>
    responsive.mobile(css`
      height: 380px;
    `)};
`
const FieldContainer = styled.div`
  display: ${props => (props.refund ? 'none' : 'flex')};
  align-items: center;
  height: 40px;
  margin-left: 23px;
  margin-bottom: 30px;
  ${props =>
    responsive.mobile(css`
      position: relative;
      flex-direction: column;
      margin: 0;
      height: 100%;
    `)};
`

const Selector = styled(Field)`
  display: flex;
  align-items: center;
  margin: 0;
  width: unset;
  .Select-control {
    text-align: center;
  }
  .input-selector-container {
    width: 160px;
    height: 40px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      margin-bottom: 15px;
      .input-selector-container {
        width: 240px;
      }
    `)};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #7f7f7f;
          }
        }
      }
    `)};
`

const TextFooter = styled.div`
  font-size: 16px;
  color: white;
  text-align: center;
  ${props =>
    responsive.mobile(css`
      height: unset;
    `)};
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 30px;
  color: #7f7f7f;
  border-right-color: #7f7f7f;
  ${props =>
    responsive.mobile(css`
      position: absolute;
      top: 2px;
      right: 20px;
    `)};
`
const LabelSelector = styled.div`
  width: 320px;
  font-size: 16px;
  color: black;
  ${props =>
    responsive.mobile(css`
      width: 240px;
      margin-bottom: 15px;
    `)};
`

const CustomText = styled.b`
  font-size: 20px;
`

const SummaryInsurance = ({totalList, firstSummary}) => (
  <CustomText>
    {totalList.getMoneyWhenDead
      ? `${
          Number.isNaN(firstSummary)
            ? 0
            : displayNumber(Math.round(firstSummary))
        } บาท`
      : 'ไม่กำหนด'}
  </CustomText>
)
const Summaryrebate = ({secondSummary}) => (
  <CustomText>
    {Number.isNaN(secondSummary) ? 0 : displayNumber(Math.round(secondSummary))}{' '}
    บาท
  </CustomText>
)

const SavingForm = ({
  onSummary,
  totalList,
  firstSummary,
  secondSummary,
  windowWidth,
}) => (
  <Panel>
    <div>
      <Header>สมมติฐาน</Header>
      <FieldContainer>
        <TextInput
          name="inflationRate"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ณ อัตราเงินเฟ้อ (ต่อปี)'
              : 'ณ อัตราเงินเฟ้อ'
          }`}
          type="percent"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />

        <Unit>ต่อปี</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="expectedSaving"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินที่ต้องการเก็บออม (บาท)'
              : 'จำนวนเงินที่ต้องการเก็บออม'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="spendTime"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จะใช้เงินออมนี้ในอีก (ปีข้างหน้า)'
              : 'จะใช้เงินออมนี้ในอีก'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>ปีข้างหน้า</Unit>
      </FieldContainer>
      <FieldContainer>
        <LabelSelector>
          {windowWidth <= breakpoints.mobile ? (
            <React.Fragment>
              ถ้าตายวันนี้ อยากได้เงินออมดังกล่าวทันทีเลยหรือไม่
            </React.Fragment>
          ) : (
            <React.Fragment>
              ถ้าตายวันนี้
              <br />
              อยากได้เงินออมดังกล่าวทันทีเลยหรือไม่
            </React.Fragment>
          )}
        </LabelSelector>
        <Selector
          name="getMoneyWhenDead"
          type="selector"
          options={[
            {label: 'ใช่', value: true},
            {label: 'ไม่ใช่', value: false},
          ]}
          didChange={onSummary}
          themeColor="orange"
        />
        <CustomTooltip
          placement={`${
            windowWidth <= breakpoints.mobile ? 'bottom' : 'right'
          }`}
          title={`${
            totalList.getMoneyWhenDead
              ? 'เพื่อกำหนดจำนวนเงินเอาประกันภัยเริ่มต้น ณ ปีแรก ให้เท่ากับจำนวนเงินที่ต้องการเก็บออมที่ได้กรอกไว้'
              : 'เพื่อกำหนดจำนวนเงินเอาประกันภัยสูงสุด ณ ปีใดปีหนึ่ง ให้เท่ากับจำนวนเงินที่ต้องการเก็บออมที่ได้กรอกไว้ และไม่จำเป็นต้องให้จำนวนเงินเอาประกันภัยเริ่มต้น ณ ปีแรก เท่ากับจำนวนเงินที่ต้องการเก็บออม'
          }`}>
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltip>
      </FieldContainer>
      <FieldContainer>
        <LabelSelector>ต้องการเงินคืนระหว่างทาง</LabelSelector>
        <Selector
          name="getRebate"
          type="selector"
          options={[
            {label: 'ใช่', value: true},
            {label: 'ไม่ใช่', value: false},
          ]}
          didChange={onSummary}
          themeColor="orange"
        />
        <CustomTooltip
          placement={`${
            windowWidth <= breakpoints.mobile ? 'bottom' : 'right'
          }`}
          title={`${
            totalList.getRebate
              ? 'จำนวนเงินที่ต้องการเก็บออมที่ได้กรอกไว้ เท่ากับผลรวมของเงินคืนรายงวดที่ได้ในแต่ละปี กับเงินที่ได้รับเมื่อครบกำหนดสัญญาประกันภัย'
              : 'จำนวนเงินที่ต้องการเก็บออมที่ได้กรอกไว้ จะได้ครั้งเดียวก็ต่อเมื่อครบสัญญาประกันภัย'
          }`}>
          <CustomIcon icon={['fas', 'info-circle']} />
        </CustomTooltip>
      </FieldContainer>
      <FieldContainer refund={!totalList.getRebate}>
        <TextInput
          name="rebateMoney"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินคืนระหว่างทางที่ต้องการ (ภายในอีก 15 ปีข้างหน้า)'
              : 'จำนวนเงินคืนระหว่างทางที่ต้องการ'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
          disabled={!totalList.getRebate}
        />
        <Unit>ภายในอีก 15 ปีข้างหน้า</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <TextFooter>
        <SearchFactor
          type="Saving"
          windowWidth={windowWidth}
          searchValue={secondSummary}
        />
        <CustomField
          name="sumInsured"
          component={SummaryInsurance}
          totalList={totalList}
          firstSummary={firstSummary}
        />
        <br />
        <br />
        รวมเงินคืนที่ต้องการทั้งหมด
        <br />
        <CustomField
          name="searchValue"
          component={Summaryrebate}
          secondSummary={secondSummary}
        />
      </TextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={secondSummary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(
        state,
        'inflationRate',
        'expectedSaving',
        'spendTime',
        'getMoneyWhenDead',
        'getRebate',
        'rebateMoney',
      ),
    }),
    {change},
  ),
  withState('firstSummary', 'setFirstSummary', 0),
  withState('secondSummary', 'setSecondSummary', 0),
  withHandlers({
    onSummary: ({
      totalList,
      setFirstSummary,
      setSecondSummary,
      change,
    }) => () => {
      const {
        inflationRate = '0',
        expectedSaving = '0',
        spendTime = '0',
        getMoneyWhenDead,
        getRebate,
        rebateMoney = '0',
      } = totalList
      let first = convertNaNtoZero(inflationRate)
      let second = convertNaNtoZero(expectedSaving)
      let third = convertNaNtoZero(spendTime)
      let float = convertNaNtoZero(rebateMoney)
      let summary = second * Math.pow(1 + first / 100, third)
      let totalSummary = getRebate ? summary + float : summary
      setFirstSummary(second)
      setSecondSummary(convertNaNtoZero(totalSummary))
      change('Planning', 'sumInsured', getMoneyWhenDead ? second : 0)
      change('Planning', 'searchValue', convertNaNtoZero(totalSummary))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onSummary()
    },
  }),
)

export default enhancer(SavingForm)
