import React from 'react'
import {compose, withState, withHandlers} from 'recompose'
import styled from 'react-emotion'
import {Button, Icon} from '../../components'
import DeductSelectorModal from './DeductSelectorModal'

const AddButton = styled(Button)`
  width: 175px !important;
  height: 32px;
  padding: 0;
  background: #ffb933;
  border-radius: 50px;
  font-family: Prompt;
  font-size: 16px;
  color: #333333;

  &:hover,
  &:focus {
    color: #333333 !important;
  }
`

const AddDeductButton = props => (
  <>
    <AddButton onClick={props.openDeductSelectorModal}>
      เลือกค่าลดหย่อน
      <Icon icon="plus" style={{marginLeft: 20, fontSize: 14}} />
    </AddButton>
    <DeductSelectorModal
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      onAdd={props.onAdd}
    />
  </>
)

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  withHandlers({
    openDeductSelectorModal: props => () => {
      dataLayer.push({event: 'edit_tax_deduction'})
      props.setIsShowModal(true)
    },
    onAdd: props => () => {
      dataLayer.push({event: 'close_edit_tax_deduction_modal'})
      props.setIsShowModal(false)
    },
  }),
)

export default enhancer(AddDeductButton)
