import React from 'react'
import styled, {css} from 'react-emotion'
import {withField, withCaption, withOptions} from '../enhancers'
import {compose, defaultProps} from 'recompose'

const Layout = styled.div`
  display: flex;
`

const ChoiceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  color: black;
  background-color: #ffb933;
  margin-right: 1px;
  font-size: 16px;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }

  &:first-child {
    border-radius: 20px 0 0 20px;
  }

  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  ${props =>
    props.isActive &&
    css`
      opacity: 1;
    `}

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: none;
      cursor: not-allowed;
    `}
`

const SwitchComponent = props => (
  <Layout>
    {props.options.map((option, index) => (
      <ChoiceButton
        key={index}
        isActive={option.value === props.value}
        disabled={props.disabled}
        onClick={() => props.onChange(option.value)}>
        {option.label}
      </ChoiceButton>
    ))}
  </Layout>
)

const enhancer = compose(
  withField(),
  withCaption(),
  withOptions(),
)

const Switch = enhancer(SwitchComponent)

Switch.Gender = defaultProps({
  options: [{label: 'ชาย', value: 'male'}, {label: 'หญิง', value: 'female'}],
})(Switch)

export default Switch
