import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled from 'react-emotion'
import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {
  displayNumber,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import {breakpoints} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'

import Panel from '../PanelForm'
import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
} from '../ShareFieldForm'

const CustomText = styled.b`
  font-size: 20px;
`

const Summary = ({summary}) => (
  <CustomText>{displayNumber(Math.round(summary))} บาท</CustomText>
)

const DebtProtectionForm = ({summary, onCalSummary, windowWidth}) => (
  <Panel>
    <div>
      <FieldContainer>
        <TextInput
          name="loadControl"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ภาระที่ต้องการความคุ้มครอง (บาท)'
              : 'ภาระที่ต้องการความคุ้มครอง'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="liquidityAsset"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'สินทรัพย์สภาพคล่องอื่นๆ ที่เตรียมไว้ชำระหนี้ (บาท)'
              : 'สินทรัพย์สภาพคล่องอื่นๆ ที่เตรียมไว้ชำระหนี้'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="sumInsurance"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ทุนประกันชีวิตที่มีอยู่แล้ว (บาท)'
              : 'ทุนประกันชีวิตที่มีอยู่แล้ว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <TextFooter>
        <SearchFactor
          type="DebProtection"
          windowWidth={windowWidth}
          searchValue={summary}
        />
        <CustomField name="searchValue" component={Summary} summary={summary} />
      </TextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={summary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      initialValues: {},
      totalList: selector(
        state,
        'loadControl',
        'liquidityAsset',
        'sumInsurance',
      ),
    }),
    {change},
  ),
  withState('summary', 'setSummary', 0),
  withHandlers({
    onCalSummary: ({totalList, setSummary, change}) => () => {
      const {
        loadControl = '0',
        liquidityAsset = '0',
        sumInsurance = '0',
      } = totalList

      let first = convertNaNtoZero(loadControl)

      let second = convertNaNtoZero(liquidityAsset)

      let third = convertNaNtoZero(sumInsurance)

      let summary = first - second - third
      setSummary(summary)
      change('Planning', 'searchValue', summary)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onCalSummary()
    },
  }),
)

export default enhancer(DebtProtectionForm)
