import {DatePicker} from 'antd'
import styled from 'react-emotion'

const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;

  .ant-calendar {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .ant-calendar-range-picker-input {
    font-weight: bold;
  }

  > .ant-input {
    border: 1px solid #a1a1a1;
    border-radius: 5;

    color: black;
    background-color: ${props => (props.disabled ? '#d6d5d5' : 'white')};
    height: 40px;

    font-size: 20px;
    font-weight: bold;
    font-family: ${props => props.theme.headerFont};

    *::-webkit-input-placeholder {
      color: #a1a1a1;
      opacity: 0.5;
    }
    :-ms-input-placeholder {
      color: #a1a1a1;
      opacity: 0.5;
    }
    ::-moz-placeholder {
      color: #a1a1a1;
      opacity: 0.5;
    }
    :-moz-placeholder {
      color: #a1a1a1;
      opacity: 0.5;
    }

    > .ant-calendar-range-picker-input {
    }
  }
`

export default RangePicker
