import React from 'react'
import styled from 'react-emotion'
import {compose, withProps} from 'recompose'
import {withForm} from './enhancers'
import {WizardState, Field, Currency, Button, Percent} from './components'
import {Layout, Title, Content, FooterLayout} from './Questions1'
import * as path from './common/path'

const CustomContent = styled(Content)`
  max-width: 510px;
  min-width: 510px;
`

const Questions2 = props => (
  <Layout>
    <WizardState step={2} />
    <Title>กรอกข้อมูล เพื่อคำนวณความคุ้มครองชีวิตและสุขภาพที่เหมาะสม</Title>
    <CustomContent>
      <Field
        name="salary"
        caption="รายได้/ เดือน"
        component={Currency}
        placeholder="กรุณากรอก"
        marginBottom="16px"
      />
      <Field
        name="revenueGrowth"
        caption="อัตราการเพิ่มขึ้นของรายได้/ ปี (%)"
        component={Percent}
        placeholder="กรุณากรอก"
        marginBottom="16px"
      />
      <Field
        name="expense"
        caption="ค่าใช้จ่ายส่วนตัว/ เดือน"
        component={Currency}
        placeholder="กรุณากรอก"
        marginBottom="16px"
      />
      <Field
        name="liabilities"
        caption="หนี้สินทั้งหมดที่มีอยู่ในปัจจุบัน"
        component={Currency}
        placeholder="กรุณากรอก"
        marginBottom="30px"
      />
      <FooterLayout>
        <Button.PrevButton to={path.questions1Path()} />
        <Button.NextButton to={path.questions3Path()} disabled={!props.valid} />
      </FooterLayout>
    </CustomContent>
  </Layout>
)

const enhancer = compose(
  withForm({
    form: 'NoonQuestion',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: values => {
      const errors = {}

      if (!values.salary && values.salary !== 0) {
        errors.salary = 'กรุณาระบุรายได้ต่อเดือน'
      }
      if (!values.revenueGrowth && values.revenueGrowth !== 0) {
        errors.revenueGrowth = 'กรุณาระบุอัตราการเพิ่มขึ้นของรายได้ต่อปี (%)'
      }
      if (!values.expense && values.expense !== 0) {
        errors.expense = 'กรุณาระบุค่าใช้จ่ายส่วนตัวต่อเดือน'
      }
      if (!values.liabilities && values.liabilities !== 0) {
        errors.liabilities = 'กรุณาระบุหนี้สินทั้งหมดที่มีอยู่'
      }

      return errors
    },
  }),
)

export default enhancer(Questions2)
