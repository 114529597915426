import React from 'react'
import styled, {css} from 'react-emotion'
import {Radio} from 'antd'

import {responsive} from '../../../core/style'

const RadioGroup = ({input, ...otherProps}) => (
  <Radio.Group {...input} {...otherProps} />
)
export const CustomRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 35px;
  align-items: center;

  .ant-radio {
    color: black;
    width: 20px;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    margin-right: 25px;
    min-width: 91px;

    color: black;
    font-size: 16px;
  }
  .ant-radio-wrapper-checked span {
    font-weight: bold;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: black;
  }

  .ant-radio-inner {
    background-color: transparent;
    border: 2px solid black;
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  .ant-radio-inner:after {
    background-color: black;
    width: 10px;
    height: 10px;
    left: 3px;
    top: 3px;
    transition: all 0.1s;
  }

  .ant-radio-focused .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner {
    border-color: black;
  }

  .ant-radio-checked:after {
    border-radius: 50%;
    border-color: black;
  }
`
export const RadioGroupContainer = styled.div`
  margin-right: 18px;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${props =>
    responsive.mobile(css`
      flex-direction: row;
      align-items: baseline;
    `)};
`

export const RadioInput = styled.input`
  position: absolute;
  margin-right: 11px;
  top: 3px;
  width: 18px;
  height: 18px;
  opacity: 0;
  z-index: 5;

  :hover {
    cursor: pointer;
  }
`

export const CustomRadioInput = styled.div`
  position: absolute;
  top: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid ${props => (props.disabled ? '#a1a1a1' : 'black')};

  ::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props =>
      props.checked ? (props.disabled ? '#a1a1a1' : 'black') : 'transparent'};
  }
`

const Label = styled.span`
  display: flex;
  align-items: center;
  margin-left: 17px;
  min-width: 80px;

  font-size: 16px;
  font-family: ${props => props.theme.headerFont};

  color: black;
`

export const RadioButtonLabel = styled(Label)`
  margin-left: 35px;
`
