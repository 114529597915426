import React, {useCallback} from 'react'
import {getFormValues, change} from 'redux-form'
import {connect} from 'react-redux'

import {CustomCheckboxs} from './StyleComponents'

export default connect(
  (state, props) => {
    const {formId, name} = props
    const formValues = getFormValues(formId)(state)
    const reduxValue = formValues && formValues[name] ? formValues[name] : []
    return {reduxValue, name}
  },
  {change},
)(props => {
  const {reduxValue, formId, change, name, options, collapseActive} = props
  const handleChange = useCallback(value => {
    let newValue = []

    if (typeof reduxValue === 'string') {
      newValue = value.filter(checkValue => reduxValue !== checkValue)
    } else if (reduxValue instanceof Array) {
      if (reduxValue.length !== 0) {
        newValue = value.filter(checkValue => reduxValue[0] !== checkValue)
      } else {
        newValue = value
      }
    }
    change(formId, name, [...newValue])
  })
  return (
    <CustomCheckboxs
      name={name}
      type="checkboxes"
      options={options}
      collapseActive={collapseActive}
      didChange={value => {
        handleChange(value)
      }}
    />
  )
})
