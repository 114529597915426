import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {compose, lifecycle, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import withSEO from '../share/withSEO'

import Field from '../share/Field'
import Button from '../share/Button'
import GoogleAuth from './GoogleAuth'
import {FacebookLoginButton} from './FacebookAuth'

import {isEmail} from '../../core/helper'
import {responsive} from '../../core/style'
import {login, setData, facebookLogin} from '../../ducks/auth'

const ContainerField = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      .input-googleInput-container {
        input {
          width: 280px;
        }
      }
    `)};
`

const LoginButton = styled(Button)`
  min-width: 200px;
  height: 30px;
  margin: 0 0 20px;
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      min-width: 280px;
      height: 40px;
      font-size: 16px;
    `)}
`

const CustomTagA = styled.a`
  color: ${props => props.theme.BLUE};
  text-decoration: underline;
  font-size: 12px;
`

const CutomResendEmail = styled(CustomTagA)`
  margin-top: 10px;
  margin-left: 5px;
`

const DividerContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 250px;
  margin: 24px auto 10px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 12px 0;

  background: #e8e8e8;
`

const DividerText = styled.span`
  width: fit-content;
  padding: 0 6px;

  font-size: 16px;
  color: #c1c1c1;
`

const Login = ({
  modal,
  valid,
  onSubmit,
  className,
  closeModal,
  errorMessage,
  facebookLogin,
}) => (
  <ContainerField className={className} onSubmit={onSubmit} noValidate>
    <GoogleInput
      name="email"
      type="googleInput"
      placeholder="อีเมล"
      hideError
    />
    <GoogleInput
      name="password"
      type="googleInput"
      mode="password"
      placeholder="รหัสผ่าน"
      className="password"
      hideError
    />
    {errorMessage === 'ยังไม่ได้ทำการยืนยันอีเมล' && (
      <CutomResendEmail href={`/authorize/resendEmail`} target="_blank">
        กรุณาทำการยืนยันอีเมล
      </CutomResendEmail>
    )}
    <LoginButton
      id="login-email"
      htmlType="submit"
      disabled={!valid}
      errorMessage={errorMessage}
      icons={['fa', 'user-circle']}>
      เข้าสู่ระบบ
    </LoginButton>
    <CustomTagA href={`/authorize/forgetPassword`} target="_blank">
      ลืมรหัสผ่าน
    </CustomTagA>
    <DividerContainer>
      <Divider />
      <DividerText>หรือ</DividerText>
      <Divider />
    </DividerContainer>
    <FacebookLoginButton
      id="login-facebook"
      name={'เข้าสู่ระบบด้วย Facebook'}
      facebookLogin={facebookLogin}
      modal={modal}
      closeModal={closeModal}
    />
    <GoogleAuth
      id="login-google"
      name="เข้าสู่ระบบด้วย Google"
      modal={modal}
      closeModal={closeModal}
    />
  </ContainerField>
)

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'อีเมลไม่ถูกต้อง'
  }
  if (!values.password) {
    errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  return errors
}

const enhancer = compose(
  withSEO(
    'เข้าสู่ระบบ - วางแผน คัดเลือก หาข้อมูลแบบประกันภัยเฉพาะคุณ',
    'ลงทะเบียนเพื่อเริ่มทำการวางแผนการเลือกซื้อแบบประกันที่เหมาะสมของคุณที่นี่',
    'ลงทะเบียน,เข้าสู่ระบบ,เลือกประกัน,ซื้อประกัน',
  ),
  connect(
    (state, props) => ({
      errorMessage: state.auth.errorMessage,
    }),
    {login, setData, facebookLogin},
  ),
  reduxForm({
    form: 'login',
    validate,
    onChange: (values, _, props) => {
      props.errorMessage !== '' && props.setData({errorMessage: ''})
    },
  }),
  withHandlers({
    onSubmit: ({handleSubmit, login, modal, closeModal}) =>
      handleSubmit(value => {
        login(value, modal, closeModal)
      }),
  }),
  lifecycle({
    componentDidMount() {
      this.props.setData({errorMessage: ''})
    },
    componentWillUnmount() {
      this.props.setData({errorMessage: ''})
    },
  }),
)

export default enhancer(Login)
