import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import {connect} from 'react-redux'

import Card from '../Card'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #e5e5e5;
  width: 100%;
  margin-bottom: 20px;
`

const Header = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 30px 0 20px;
`

const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
`

const SimilarContent = ({nearlyDetail = []}) =>
  nearlyDetail.length > 0 && (
    <Container>
      <Header>บทความที่คล้ายกัน</Header>
      <ContainerCard>
        {nearlyDetail.map(value => (
          <Card key={value.id} value={value} />
        ))}
      </ContainerCard>
    </Container>
  )

const enhancer = compose(
  connect(state => ({
    nearlyDetail: state.noonSquare.article.nearlyDetail,
  })),
)

export default enhancer(SimilarContent)
