import React from 'react'
import {connect} from 'react-redux'
import {compose, withState, lifecycle} from 'recompose'
import styled from 'react-emotion'
import Modal from '../../share/Modal'
import CreatePortDetail from './CreatePortDetail'
import {getOptionsPort} from '../../../ducks/profile'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const HeaderTabContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #ffb933;
  justify-content: center;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  font-size: 20px;
  font-weight: bold;
`

const Field = styled.div`
  padding: 30px 0 13px 0;
`

const InsuranceCreatePortModal = ({isShowModal, setIsShowModal, portData}) => (
  <Modal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
    <Container>
      <HeaderTabContainer>เพิ่มแบบประกัน</HeaderTabContainer>
      <Field>
        <CreatePortDetail portData={portData} form={`createPortDetail`} />
      </Field>
    </Container>
  </Modal>
)

const enhancer = compose(
  withState('isActive', 'setActive', 0),
  connect(
    () => {},
    {
      getOptionsPort,
    },
  ),
)
export default enhancer(InsuranceCreatePortModal)
