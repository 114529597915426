import React from 'react'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {compose, lifecycle, withState} from 'recompose'
import {responsive} from '../../core/style'
import Button from '../share/Button'
import {interest} from '../../ducks/auth'

const CustomButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  margin: 20px 0;
  font-size: 12px;
`

const HeaderOptionInterest = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`
const HeaderInterest = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const ContainerInterest = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const SubHeader = styled.div`
  font-size: 16px;
  ${props =>
    responsive.mobile(css`
      width: 270px;
      text-align: center;
    `)};
`
const Atlest = styled.div`
  font-size: 12px;
`
const ContainerCircle = styled.div`
  width: 100%;
  height: 350px;
  ${props =>
    responsive.mobile(css`
      height: 420px;
    `)};
`

const Circle = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  font-size: 12px;
  color: ${props => (props.selected ? 'white' : 'black')};
  background-color: ${props => (props.selected ? props.theme.ORANGE : 'white')};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
  cursor: pointer;
  ${props =>
    responsive.mobile(css`
      left: ${props.leftMobile};
      top: ${props.topMobile ? props.topMobile : props.top};
    `)};
`

const ArrayCircle = [
  {
    label: 'ไลฟ์สไตล์',
    style: {
      top: '205px',
      left: 'calc(50% - 215px)',
      leftMobile: 'calc(50% - 150px)',
      width: 88,
      height: 88,
    },
    id: 3,
  },
  {
    label: 'ข่าว',
    style: {
      top: '240px',
      left: 'calc(50% - 94px)',
      leftMobile: 'calc(50% - 40px)',
      width: 56,
      height: 56,
    },
    id: 4,
  },
  {
    label: 'OIC',
    style: {
      top: '180px',
      left: 'calc(50% - 36px)',
      leftMobile: 'calc(50% + 22px)',
      width: 72,
      height: 72,
    },
    id: 5,
  },
  {
    label: 'CIT',
    style: {
      top: '280px',
      left: 'calc(50% + 30px)',
      leftMobile: 'calc(50% + 70px)',
      width: 61,
      height: 61,
    },
    id: 6,
  },
  {
    label: 'ท่องเที่ยว',
    style: {
      top: '205px',
      topMobile: '450px',
      left: 'calc(50% + 100px)',
      leftMobile: 'calc(50% - 85px)',
      width: 82,
      height: 82,
    },
    id: 9,
  },
  {
    label: 'กฏหมาย',
    style: {
      top: '265px',
      topMobile: '420px',
      left: 'calc(50% + 200px)',
      leftMobile: 'calc(50% + 0px)',
      width: 60,
      height: 60,
    },
    id: 10,
  },
  {
    label: 'ตัวแทนขายประกัน',
    style: {
      top: '315px',
      topMobile: '475px',
      left: 'calc(50% + 106px)',
      leftMobile: 'calc(50% + 45px)',
      width: 105,
      height: 105,
    },
    id: 8,
  },
  {
    label: 'โบรกเกอร์',
    style: {
      top: '393px',
      left: 'calc(50% + 30px)',
      leftMobile: 'calc(50% + 75px)',
      width: 70,
      height: 70,
    },
    id: 7,
  },
  {
    label: 'Fintech',
    style: {
      top: '320px',
      left: 'calc(50% - 80px)',
      leftMobile: 'calc(50% - 25px)',
      width: 85,
      height: 85,
    },
    id: 1,
  },
  {
    label: 'ธุรกิจ',
    style: {
      top: '345px',
      left: 'calc(50% - 200px)',
      leftMobile: 'calc(50% - 140px)',
      width: 92,
      height: 92,
    },
    id: 2,
  },
]

const Interest = ({itemInterest, setItemInterest, interest, initialized}) => {
  return (
    initialized && (
      <ContainerInterest>
        <HeaderOptionInterest>
          <HeaderInterest>เลือกความสนใจของคุณ</HeaderInterest>
          <SubHeader>
            เพื่อเพิ่มประสิทธิภาพในหน้านูนพลาซ่า ของคุณ
            เราจะแสดงแต่สิ่งที่คุณสนใจ
          </SubHeader>
          <Atlest>(เลือกขั้นต่ำ 3 ความสนใจ)</Atlest>
        </HeaderOptionInterest>
        <ContainerCircle>
          {ArrayCircle.map(item => (
            <Circle
              key={item.id}
              {...item.style}
              onClick={() => {
                const buffer = [...itemInterest]
                if (itemInterest.includes(item.id)) {
                  const newBuffer = buffer.filter(i => i !== item.id)
                  setItemInterest(newBuffer)
                } else {
                  buffer.push(item.id)
                  setItemInterest(buffer)
                }
              }}
              selected={itemInterest.includes(item.id)}>
              {item.label}
            </Circle>
          ))}
        </ContainerCircle>
        <CustomButton
          onClick={() => interest(itemInterest)}
          disabled={itemInterest.length < 3}
          icons={['fa', 'check-circle']}>
          เสร็จสิ้น
        </CustomButton>
      </ContainerInterest>
    )
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      initialized: state.auth.initialized,
      isLogin: state.auth.isLogin,
    }),
    {interest},
  ),
  withState('itemInterest', 'setItemInterest', []),
)

export default enhancer(Interest)
