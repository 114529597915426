export const rootPath = () => '/tax'
export const prepareTaxPath = () => '/tax/prepare'
export const prepareTaxStep1Path = () => '/tax/prepare/step1'
export const prepareTaxStep2Path = () => '/tax/prepare/step2'
export const prepareTaxStep3Path = () => '/tax/prepare/step3'
export const prepareTaxSuccessPath = code => `/tax/prepare/success/${code}`
export const calculatorPath = () => '/tax/calculator'
export const proPath = () => '/tax/pro'

export const lifeInsuranceProductsPath = () =>
  '/smart/InsuranceCategory/life/intermediate'
export const fundProductsPath = () => '/fund'
