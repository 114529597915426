import React from 'react'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../core/style'
import FundCard from '../components/FundCard'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  padding: 31px 0px 25px;
  font-family: 'Prompt';
  color: #333333;

  /* ${props =>
    responsive.mobile(css`
      flex-direction: column;
      padding: 0px 20px;
    `)}; */
`

const Header = styled.div`
  font-size: 20px;
  padding-bottom: 35px;
`

const FundCardContainer = styled.ul`
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 0;
  list-style-type: none;

  ${props =>
    responsive.mobile(css`
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      height: 100%;
      width: 100%;
    `)}

  li {
    margin-right: 20px;
    margin-bottom: 0px;
    &:nth-of-type(3n) {
      margin-right: 0px;
    }

    ${props =>
      responsive.tablet(css`
        margin-right: 20px;
        margin-bottom: 0px;
        &:nth-of-type(2n) {
          margin-right: 20px;
        }
        &:nth-of-type(3n) {
          margin-right: 20px;
        }
      `)};
    ${props =>
      responsive.mobile(css`
        margin-right: 0px;
        margin-bottom: 0px;
        &:nth-of-type(1) {
          padding-left: 47px;
          padding-right: 10px;
        }
        &:nth-of-type(2) {
          padding-left: 17px;
          padding-right: 47px;
        }
      `)};
  }
`

export default props => {
  const {fund} = props
  const {similarFunds} = fund

  return (
    <Wrapper>
      <Header>กองทุนที่ใกล้เคียง</Header>
      <FundCardContainer>
        {(similarFunds ? similarFunds : []).slice(0, 2).map(fund => (
          <li key={`${fund.refId}`}>
            <FundCard {...fund} comparable={false} />
          </li>
        ))}
      </FundCardContainer>
    </Wrapper>
  )
}
