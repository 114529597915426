import React from 'react'
import styled from 'react-emotion'
import {Icon} from '../components'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
`

const IconLayout = styled.div`
  position: relative;
`

const MainIcon = styled(Icon)`
  font-size: 50px;
  color: #333333;
`

const CheckIconLayout = styled.div`
  position: absolute;
  right: -5px;
  top: 0px;
  border-radius: 50%;
  padding: 1px;
  background: white;
  line-height: 1;
`

const CheckedIcon = styled(Icon)`
  font-size: 20px;
  color: #9fd134;
`

const IconDescription = styled.div`
  margin-top: 14px;
  font-size: 16px;
  font-family: Prompt;
  color: #333333;
  text-align: center;
`

const BenefitCard = props => (
  <Layout className={props.className}>
    <IconLayout>
      <MainIcon icon={props.icon} />
      <CheckIconLayout>
        <CheckedIcon icon="check-circle" />
      </CheckIconLayout>
    </IconLayout>
    <IconDescription>{props.description}</IconDescription>
  </Layout>
)

export default BenefitCard
