import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import {connect} from 'react-redux'

import {
  fetchFavoriteFundRefIds,
  toggleFundFavorite,
  setIsShowModalFavorite,
} from '../../../ducks/fund'

const Icon = styled(FontAwesomeIcon)`
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  cursor: pointer;
`

const FavoriteButton = connect(
  state => ({
    isLogin: state.auth.isLogin,
    favoriteRefIds: state.fund.favoriteRefIds,
  }),
  {fetchFavoriteFundRefIds, toggleFundFavorite, setIsShowModalFavorite},
)(props => {
  const {
    isLogin,
    refId,
    favoriteRefIds,
    activeIcon,
    inactiveIcon,
    color,
    fontSize,
    setIsShowModalFavorite,
    toggleFundFavorite,
  } = props

  const favorited = isLogin && favoriteRefIds.includes(refId)

  return (
    <Icon
      onClick={() =>
        isLogin
          ? toggleFundFavorite(refId, !favorited)
          : setIsShowModalFavorite(true)
      }
      icon={favorited ? activeIcon : inactiveIcon}
      color={color}
      fontSize={fontSize}
    />
  )
})

FavoriteButton.propTypes = {
  favorited: PropTypes.bool,
  color: PropTypes.string,
  fontSize: PropTypes.string,
}

FavoriteButton.defaultProps = {
  favorited: false,
  activeIcon: ['fas', 'heart'],
  inactiveIcon: ['far', 'heart'],
  color: 'red',
  fontSize: '20px',
}

export default FavoriteButton
