import React from 'react'
import styled from 'react-emotion'
import {Button as AntButton} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CustomButton = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  width: fit-content !important;
  padding: 0 25px;
  overflow: hidden;
  border-radius: 20px;
  border: ${props =>
    props.type === 'border' ? `${props.theme.ORANGE} 1px solid` : 'unset'};
  color: black;
  background-color: ${props =>
    props.type === 'border' ? 'white' : props.theme.ORANGE};

  &[disabled] {
    background-color: ${props =>
      props.type === 'border' ? 'white' : props.theme.ORANGE};
    opacity: 0.5;
    cursor: not-allowed;
    :hover {
      background-color: ${props => props.theme.ORANGE};
      opacity: 0.5;
    }
  }

  :hover {
    color: black;
    background-color: ${props =>
      props.type === 'border' ? 'white' : props.theme.DARKORANGE};
    border: ${props =>
      props.type === 'border'
        ? `${props.theme.DARKORANGE} 1px solid`
        : 'unset'};
    .icon {
      width: 12px !important;
      margin-left: 10px;

      opacity: 1;
    }
  }
  :focus {
    color: black;
    background-color: ${props =>
      props.type === 'border' ? 'white' : props.theme.DARKORANGE};
    border: ${props =>
      props.type === 'border'
        ? `${props.theme.DARKORANGE} 1px solid`
        : 'unset'};
  }
`

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  pointer-events: ${props => (props.disabled ? 'none' : '')};
`

const ContainerErrorMessage = styled.div`
  color: ${props => props.theme.PINKRED};
  font-size: 12px;
  margin-top: 15px;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => props.theme.PINKRED};
  margin-right: 5px;
`

const ButtonIcon = styled(FontAwesomeIcon)`
  width: 0 !important;

  margin-left: 0;
  font-size: 12px;
  color: black;

  opacity: 0;
  transition: all 0.5s;
`

const Button = props => (
  <ContainerButton disabled={props.disabled}>
    {props.errorMessage && (
      <ContainerErrorMessage>
        <ExclamationCircle icon={['fas', 'exclamation-circle']} />
        {props.errorMessage}
      </ContainerErrorMessage>
    )}
    <CustomButton {...props}>
      {props.children}
      {props.icons && <ButtonIcon className="icon" icon={props.icons} />}
    </CustomButton>
  </ContainerButton>
)

export default Button
