import styled, {css} from 'react-emotion'
import {responsive} from '../../../../core/style'

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 150px;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

export const ContainerInfoMobile = styled(ContainerInfo)`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 290px;
  margin: 0;
  margin-top: 10px;
  line-height: 30px;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

export const ContainerDetailIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: ${props => (props.isLast ? '0' : '54px')};
  ${props =>
    responsive.tablet(css`
      margin-right: ${props.isLast ? '0' : '32px'};
    `)};

  ${props =>
    responsive.mobile(css`
      width: 125px;
      margin-right: 0;
    `)};
`

export const CompanyLogo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  ${props =>
    responsive.mobile(css`
      width: 60px;
      height: 60px;
      margin-right: 75px;
    `)};
`
export const InfoIcon = styled.div`
  text-align: center;
  width: fit-content;
  font-weight: bold;
  height: 48px;
  font-size: 16px;
  margin-top: 15px;

  ${props =>
    responsive.mobile(css`
      font-size: 12px;
    `)};
`
