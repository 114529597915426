import {compose, branch, withHandlers, defaultProps} from 'recompose'
import Select from '../../share/Field/Selector'
import {
  withCaption,
  withSpidus,
  withValue,
  withDisabled,
  omitProps,
} from '../enhances/index'
import styled, {css} from 'react-emotion'

const disabledStyled = css`
  opacity: 1;
  background-color: #e5e5e5;
`

const CustomSelect = styled(Select)`
  .Select-value-label,
  div {
    font-family: 'Sarabun', sans-serif !important;
    font-size: 16px !important;
    color: #333333 !important;
  }

  .Select-value-label {
    margin-left: -10px;
  }
`

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption(),
  withDisabled(disabledStyled),
  defaultProps({
    searchable: false,
    themeColor: 'orange',
    multi: false,
  }),
  withHandlers({
    onChange: props => value => {
      props.onChange && props.onChange(value.value)
      props.onBlur && props.onBlur(value.value)
    },
  }),
  omitProps('onBlur'),
)

export default enhance(CustomSelect)
