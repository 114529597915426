import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'

import ProfileLogo from '../../static/images/avatar-02.svg'
import {logout} from '../../ducks/auth'
import {responsive} from '../../core/style'

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 300px;
  min-height: calC(100vh - 294px);

  background-color: ${props => props.theme.BLUE};
  color: white;

  ${props =>
    responsive.tablet(css`
      min-width: 218px;
    `)};

  ${props =>
    responsive.mobile(css`
      position: absolute;
      z-index: 5;

      width: 100%;
      height: 100%;
      min-height: 500px;

      transition: 0.5s;
      transform: ${props.hideActive ? 'translateX(0px)' : 'translateX(-100vw)'};
    `)};
`

const ProfileLogoImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
  margin-top: 36px;

  border-radius: 50%;
  object-fit: cover;

  ${props =>
    responsive.mobile(css`
      width: 80px;
      height: 80px;
      margin: 30px 0;
    `)};
`

const ProfileMenuListPart = styled.div`
  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

const ProfileMenuListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;

  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      height: 50px;
      margin: 0;
      padding: 0 30px;

      background-color: ${props.isActive
        ? props.theme.EXTRALIGHTBLUE
        : 'transparent'};
    `)};
`

const ProfileMenuListIcon = styled(FontAwesomeIcon)`
  margin-right: 12px;
  min-width: 20px;

  font-size: 16px !important;
`

const ProfileMenuListText = styled.div`
  font-size: 16px;
  font-family: 'Prompt';
  font-weight: ${props => (props.isActive ? 700 : 400)};
  line-height: 24px;

  ${props =>
    responsive.mobile(css`
      font-weight: 400;
    `)};
`

const HideMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${props => (props.hideActive ? '0' : '-30px')};

  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.EXTRALIGHTBLUE};
  width: 30px;
  height: 60px;

  border-radius: ${props =>
    props.hideActive ? '30px 0 0 30px' : '0 30px 30px 0'};
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const HideIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`

const getMenuByUser = user =>
  user.loginWith === 'email'
    ? [
        {
          id: 0,
          text: 'ข้อมูลส่วนตัว',
          icon: 'user-circle',
          link: 'user-profile',
          analyticName: 'user-profile',
        },
        {
          id: 1,
          text: 'รายการโปรด',
          icon: 'heart',
          link: 'favorite',
          analyticName: 'favorite',
        },
        // {id: 2, text: 'พอร์ตแบบประกัน', icon: 'chart-area', link: 'port', analyticName: 'insurance-port'},
        {
          id: 3,
          text: 'เปรียบเทียบแบบประกัน',
          icon: 'exchange-alt',
          link: 'compare',
          analyticName: 'insurance-product-compare',
        },
        {
          id: 4,
          text: 'แบบประกันที่สนใจ',
          icon: 'folder-open',
          link: 'interest',
          analyticName: 'insurance-product-interested',
        },
        {
          id: 5,
          text: 'ประวัติการส่งคำสั่งซื้อ',
          icon: 'history',
          link: 'order',
        },
        {
          id: 6,
          text: 'เปลี่ยนรหัสผ่าน',
          icon: 'key',
          link: 'changed-password',
          analyticName: 'change-password',
        },
        {
          id: 7,
          text: 'ออกจากระบบ',
          icon: 'sign-out-alt',
          link: 'logout',
          analyticName: 'logout',
        },
      ]
    : [
        {
          id: 0,
          text: 'ข้อมูลส่วนตัว',
          icon: 'user-circle',
          link: 'user-profile',
          analyticName: 'user-profile',
        },
        {
          id: 1,
          text: 'รายการโปรด',
          icon: 'heart',
          link: 'favorite',
          analyticName: 'favorite',
        },
        // {id: 2, text: 'พอร์ตแบบประกัน', icon: 'chart-area', link: 'port', analyticName: 'insurance-product'},
        {
          id: 3,
          text: 'เปรียบเทียบแบบประกัน',
          icon: 'exchange-alt',
          link: 'compare',
          analyticName: 'insurance-product-compare',
        },
        {
          id: 4,
          text: 'แบบประกันที่สนใจซื้อ',
          icon: 'folder-open',
          link: 'interest',
          analyticName: 'insurance-product-interested',
        },
        {
          id: 5,
          text: 'ประวัติการส่งคำสั่งซื้อ',
          icon: 'history',
          link: 'order',
        },
        {
          id: 7,
          text: 'ออกจากระบบ',
          icon: 'sign-out-alt',
          link: 'logout',
          analyticName: 'logout',
        },
      ]

const ProfileMenu = ({
  logout,
  user,
  hideActive,
  setHideActive,
  activeMenu = '',
}) => {
  const profileMenuList = getMenuByUser(user)
  return (
    <Container hideActive={hideActive}>
      <HideMenuContainer
        hideActive={hideActive}
        onClick={() => setHideActive(!hideActive)}>
        <HideIcon
          icon={['fas', hideActive ? 'chevron-left' : 'chevron-right']}
        />
      </HideMenuContainer>
      <ProfileLogoImage src={user.avatar || ProfileLogo} />
      <ProfileMenuListPart>
        {profileMenuList.map(value => (
          <ProfileMenuListContainer
            className={value.analyticName}
            key={value.id}
            isActive={activeMenu === value.link}
            onClick={() => {
              value.link === 'logout'
                ? logout()
                : navigate(`/profile/${value.link}`)
              setHideActive(!hideActive)
            }}>
            <ProfileMenuListIcon icon={['fas', value.icon]} />
            <ProfileMenuListText isActive={activeMenu === value.link}>
              {value.text}
            </ProfileMenuListText>
          </ProfileMenuListContainer>
        ))}
      </ProfileMenuListPart>
    </Container>
  )
}

const enhancer = compose(
  withState('hideActive', 'setHideActive', false),
  connect(
    () => ({}),
    {logout},
  ),
)
export default enhancer(ProfileMenu)
