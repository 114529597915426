import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {reduxForm, Field} from 'redux-form'
import {compose, lifecycle, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FieldNoon from '../../../share/Field'
import Button from '../../../share/Button'
import Modal from '../../../share/Modal'
import {setLoading} from '../../../../ducks/profile'
import windowSize from '../../../../core/react-window-size'
import {responsive} from '../../../../core/style'
import img1 from '../../../../static/images/review_1.svg'
import img2 from '../../../../static/images/review_2.svg'
import img3 from '../../../../static/images/review_3.svg'
import img4 from '../../../../static/images/review_4.svg'
import img5 from '../../../../static/images/review_5.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      width: 100%;
      margin-top: 28px;
      margin-bottom: 46px;
      padding: 18px;
      overflow-x: hidden;
    `)};
`
const DesContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const TextHead = styled.div`
  font-size: 14px;
  font-family: Prompt;
  font-weight: bold;
`

const TextAgent = styled.div`
  font-size: 14px;
`

const TextAgentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const AgentImage = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 20px;
  border-radius: 50%;
  object-fit: cover;
`

const NoonStarContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${props => (props.collapseActive ? '0' : 'fit-content')};
  transition: 0.5s;
`
const EmojiAllContainer = styled.div`
  padding: 0px 6px 6px 6px;
  margin-top: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 285px;
  ${props =>
    responsive.mobile(css`
      padding: 0px 24px;
    `)}
`

const FilterIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
`

const StarIcon = styled(FilterIcon)`
  color: ${props =>
    props.isSelected || props.isHoverStar ? '#ffd900' : '#e5e5e5'};
`

const EmoIcon = styled.img`
  width: 49px;
  height: 49px;

  opacity: ${props => (props.isSelected || props.isHoverStar ? '1' : '0.3')};
  ${props =>
    responsive.mobile(css`
      width: 42px;
      height: 42px;
    `)}
`

const NoonStarScoreContainer = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
`

const Hr = styled.div`
  border: 1px solid #e5e5e5;
  width: 320px;
  margin-top: 20.5px;
  @media only screen and (max-width: 320px) {
    width: 310px;
  }
`
const TextEmoji = styled.div`
  margin-top: 20.5px;
  font-size: 12px;
  display: flex;
  align-self: flex-start;
`
const TextEmojiFirst = styled.div`
  font-size: 12px;
  display: flex;
  align-self: flex-start;
`

const TextArea = styled(FieldNoon)`
  height: 100px;
  width: 100%;
`

const EmojiContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 45px;
`

const ContainerTextEmoji = styled.div`
  display: flex;
  flex-direction: row;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 6px;
  color: ${props => props.theme.PINKRED};
  margin-top: ${props =>
    props.Text == 'ความรวดเร็วในการติดต่อกลับ' ? '0px' : '20.5px'};
`

const noonstar = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}]

const Emoji = [
  {name: img1, id: 1, color: '#FA5562'},
  {name: img2, id: 2, color: '#FA834F'},
  {name: img3, id: 3, color: '#FBCF3F'},
  {name: img4, id: 4, color: '#9FD77A'},
  {name: img5, id: 5, color: '#6BCA6C'},
]

const StarEnhancer = compose(withState('hoveredStar', 'setHoveredStar', 0))
const EmoEnhancer = compose(withState('hoveredEmo', 'setHoveredEmo', 0))

const Star = StarEnhancer(
  ({
    evaluateAgentData,
    input,
    hoveredStar,
    setHoveredStar,
    collapseActive,
    ...props
  }) => {
    input.value = evaluateAgentData.star
    return (
      <NoonStarContainer collapseActive={collapseActive}>
        <NoonStarScoreContainer>
          {noonstar.map((value, index) => (
            <StarIcon
              isHoverStar={index < hoveredStar}
              isSelected={index < input.value}
              key={value.id}
              icon={['fas', 'star']}
            />
          ))}
        </NoonStarScoreContainer>
      </NoonStarContainer>
    )
  },
)

const Emo = EmoEnhancer(
  ({
    evaluateAgentData,
    input,
    hoveredEmo,
    setHoveredEmo,
    meta,
    Text,
    submitting,
  }) => {
    input.value = evaluateAgentData[input.name]
    return (
      <>
        <ContainerTextEmoji>
          {Text === 'ความรวดเร็วในการติดต่อกลับ' ? (
            <>
              <TextEmojiFirst>{Text}</TextEmojiFirst>
              {meta.error && meta.submitFailed && (
                <ExclamationCircle
                  icon={['fas', 'exclamation-circle']}
                  Text="ความรวดเร็วในการติดต่อกลับ"
                />
              )}
            </>
          ) : (
            <>
              <TextEmoji>{Text}</TextEmoji>
              {meta.error && meta.submitFailed && (
                <ExclamationCircle icon={['fas', 'exclamation-circle']} />
              )}
            </>
          )}
        </ContainerTextEmoji>
        <EmojiContainer>
          {Emoji.map((value, index) => (
            <EmoIcon
              src={value.name}
              isHoverStar={index + 1 == hoveredEmo}
              isSelected={index + 1 == input.value}
              onMouseOut={() => setHoveredEmo(input.value)}
            />
          ))}
        </EmojiContainer>
      </>
    )
  },
)

const ShowEvaluateAgent = ({evaluateAgentData, agent = {}, ...props}) => {
  return (
    <DesContainer>
      <Container>
        <TextHead>ความคิดเห็นของคุณหลังจากได้รับการบริการ</TextHead>
        <AgentImage />
        <TextAgentContainer>
          <TextAgent>เลขประจำตัว {evaluateAgentData.licenseNo}</TextAgent>
          <TextAgent>ชื่อ - สกุล {evaluateAgentData.name}</TextAgent>
        </TextAgentContainer>
        <Field
          component={Star}
          name="star"
          evaluateAgentData={evaluateAgentData}
        />
        <Hr />

        <>
          <EmojiAllContainer>
            <Field
              component={Emo}
              name="activeness"
              Text="ความรวดเร็วในการติดต่อกลับ"
              evaluateAgentData={evaluateAgentData}
              hideError
            />

            <Field
              component={Emo}
              name="information"
              Text="การให้ข้อมูลครบถ้วน/ให้คำแนะนำเพิ่มเติม"
              evaluateAgentData={evaluateAgentData}
              hideError
            />

            <Field
              component={Emo}
              name="profession"
              Text="ความเชี่ยวชาญ/มีความรู้ในผลิตภัณฑ์"
              evaluateAgentData={evaluateAgentData}
              hideError
            />

            <Field
              component={Emo}
              name="convenience"
              Text="การอำนวยความสะดวกในเรื่องเอกสารและการชำระเงิน"
              evaluateAgentData={evaluateAgentData}
              hideError
            />

            <Field
              component={Emo}
              name="impression"
              Text="ความประทับใจจากการขาย(ไม่ตื้อ ไม่ยัดเยียด)"
              evaluateAgentData={evaluateAgentData}
              hideError
            />

            <Field
              component={Emo}
              name="afterSalesService"
              Text="การบริการหลังการขาย"
              evaluateAgentData={evaluateAgentData}
            />

            <TextEmoji>ความคิดเห็นเพิ่มเติม</TextEmoji>
            <TextArea type="textarea" name="reviewRemark" disabled />
          </EmojiAllContainer>
        </>
      </Container>
    </DesContainer>
  )
}

const enhancer = compose(
  connect(
    state => ({}),
    {setLoading},
  ),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(ShowEvaluateAgent)
