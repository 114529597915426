import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {navigate} from '@reach/router'
import windowSize from '../../core/react-window-size'
import {compose, withHandlers, lifecycle} from 'recompose'

import Button from '../share/Button'
import BG from '../../static/images/404_bg.png'
import PlaneImg from '../../static/images/404_plane_2.png'
import CloudSkyImg from '../../static/images/404_cloud_sky.png'
import CloudBlueImg from '../../static/images/404_cloud_blue.png'
import CloudWhiteImg from '../../static/images/404_cloud_white.png'
import WingtipsSkyImg from '../../static/images/404_wingtips_vortices_sky.png'
import WingtipsWhiteImg from '../../static/images/404_wingtips_vortices_white.png'

import {setData} from '../../ducks/404'
import {cloudFly, textFly, fly} from './style'
import {responsive, breakpoints} from '../../core/style'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin-top: -80px;
  overflow: hidden;
`

const Background = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  background: url(${BG}) center;
  transform-origin: center center;
  transform: scale(1.1);
`

const Cloud = styled.img`
  position: absolute;
  left: -10px;
  height: 100%;

  animation: ${cloudFly} 2s linear 0s 1 normal;
  transform-origin: center center;

  ${props =>
    responsive.tablet(css`
      height: 100%;
    `)};

  ${props =>
    responsive.mobile(css`
      left: -60%;
      animation: none;
    `)};
`

const CloudBlue = styled(Cloud)`
  z-index: 1;
`

const CloudSky = styled(Cloud)`
  z-index: 2;
`

const CloudWhite = styled(Cloud)`
  z-index: 4;
`

const PlaneContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: calc(50% - 65px);

  transform: translateX(-100vw);
  animation: ${fly} 4s linear 0s 1 normal;

  ${props =>
    responsive.mobile(css`
      animation: none;
    `)};
`

const PlaneWrapper = styled.div`
  position: relative;
`

const AirPlane = styled.img``

const WingtipsSky = styled.img`
  position: absolute;
  z-index: -1;
  top: 17px;
  left: -283px;
`

const WingtipsWhite = styled.img`
  position: absolute;
  left: -347px;
  bottom: -4px;
`

const Plane = () => (
  <PlaneContainer>
    <PlaneWrapper>
      <WingtipsSky src={WingtipsSkyImg} />
      <AirPlane src={PlaneImg} />
      <WingtipsWhite src={WingtipsWhiteImg} />
    </PlaneWrapper>
  </PlaneContainer>
)

const ContainerText = styled.div`
  position: absolute;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 670px;
  height: 100%;

  animation: ${textFly} 2s ease-out 0s 1 normal;

  ${props =>
    responsive.mobile(css`
      animation: none;
    `)};
`

const Error = styled.h1`
  font-size: 64px;
  font-weight: bold;
  color: ${props => props.theme.GREY80};
  font-family: 'Roboto';
  line-height: 64px;
  text-align: center;
`

const SmallText = styled.h2`
  font-size: 24px;
  color: ${props => props.theme.GREY80};
  margin: 0;
`

const CustomButton = styled(Button)`
  height: 30px;
  margin-top: 34px;
`

const Text = () => (
  <ContainerText id="cloud-text">
    <Error>ERROR 404</Error>
    <SmallText>ไม่พบหน้าที่คุณค้นหา</SmallText>
    <CustomButton onClick={() => navigate(`/`)} icons={['fa', 'home']}>
      กลับสู่หน้าหลัก
    </CustomButton>
  </ContainerText>
)

const Notfound = () => (
  <Container>
    <Background>
      <CloudBlue id="cloud-blue" src={CloudBlueImg} />
      <CloudSky id="cloud-sky" src={CloudSkyImg} />
      <CloudWhite id="cloud-white" src={CloudWhiteImg} />
      <Plane />
      <Text />
    </Background>
  </Container>
)

const enhancer = compose(
  connect(
    () => ({}),
    {setData},
  ),
  withHandlers({
    parallax: () => (e, target, offset) => {
      var x = e.clientX / offset
      var y = e.clientY / offset
      target.style.transform = `translateX(${x}px) translateY(${y}px)`
    },
  }),
  lifecycle({
    componentDidMount() {
      const {setData, parallax, windowWidth} = this.props
      setData({isNoHeader: true, isNoFooter: true})
      const blue = document.getElementById('cloud-blue')
      const sky = document.getElementById('cloud-sky')
      const white = document.getElementById('cloud-white')
      const text = document.getElementById('cloud-text')
      setTimeout(() => {
        if (windowWidth >= breakpoints.tablet) {
          window.addEventListener('mousemove', e => {
            parallax(e, blue, 50)
            parallax(e, sky, 200)
            parallax(e, white, 500)
            parallax(e, text, 500)
          })
        }
      }, 2000)
    },
    componentWillUnmount() {
      const {setData} = this.props
      setData({isNoHeader: false, isNoFooter: false})
      clearTimeout()
      window.removeEventListener('mousemove', () => {})
    },
  }),
)

export default windowSize(enhancer(Notfound))
