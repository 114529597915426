import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {reduxForm} from 'redux-form'

import CustomField from '../../share/Field'
import {addReplyComment} from '../../../ducks/noonSquare'
import {isBlank} from '../../../core/helper'

const CommentReplyBox = styled(CustomField)`
  margin: 0;
  padding: 0 0 35px 60px;
  height: unset;
  textarea {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 7px 20px;
  }
`

const Reply = ({article, addReplyComment, commentId, reset}) => {
  const replyName = `replyComment${commentId}`
  return (
    <CommentReplyBox
      onEnter={comment =>
        !isBlank(comment) &&
        addReplyComment(article.id, commentId, comment, () => reset())
      }
      name={replyName}
      placeholder="แสดงความคิดเห็น..."
      type="autotextarea"
    />
  )
}

const enhancer = compose(
  connect(
    state => ({
      article: state.noonSquare.article.article || {},
    }),
    {addReplyComment},
  ),
  reduxForm({
    form: 'reply',
  }),
)

export default enhancer(Reply)
