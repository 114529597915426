import React from 'react'
import {compose, withHandlers} from 'recompose'
import styled from 'react-emotion'
import Modal from '../../../../share/Modal'
import {Button} from '../../../components'

const CustomModal = styled(Modal)``
const WrapContentModal = styled.div`
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`
const WrapperTitle = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    color: #ffc107;
    font-size: 40px;
  }
`
const Title = styled.div`
  margin: 0px 0px 0px 0px;
  font-size: 20px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;
  text-align: center;
`
const Description = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  font-family: Prompt;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
`
const AgreeButton = styled(Button)`
  min-height: 38px;
  max-height: 38px;
  min-width: 250px;
  max-width: 250px;
`

const TooltipBudgetModal = props => (
  <CustomModal isShowModal={props.isShowModal} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <WrapperTitle>
        <Title>งบประมาณที่จะใช้ซื้อประกัน</Title>
      </WrapperTitle>
      <Description>
        ใช้สำหรับค้นหาแบบประกันที่ราคาใกล้เคียงกับงบประมาณของคุณ
      </Description>
      <Footer>
        <AgreeButton onClick={props.onAgreeProduct}>ตกลง</AgreeButton>
      </Footer>
    </WrapContentModal>
  </CustomModal>
)

const enhancer = compose(
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
    onAgreeProduct: props => () => {
      props.setIsShowModal(false)
    },
  }),
)

export default enhancer(TooltipBudgetModal)
