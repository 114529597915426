import {compose, withState, lifecycle, withHandlers} from 'recompose'

const withValue = () =>
  compose(
    withState('value', 'setValue', props => props.value),
    withHandlers({
      onChange: props => value => {
        props.setValue(value)
        if (props.onChange) {
          props.onChange(value)
        }
      },
    }),
    lifecycle({
      componentWillReceivedProps(nextProps) {
        if (props.value !== nextProps.value) {
          nextProps.onChange(nextProps.value)
        }
      },
    }),
  )

export default withValue
