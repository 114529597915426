import {compose, withState, lifecycle} from 'recompose'

const isServer = typeof window === 'undefined'

export default compose(
  // Always render children when build
  withState('isRenderChildren', 'setIsRenderChildren', isServer),
  lifecycle({
    componentDidMount() {
      const {setIsRenderChildren, config = {}} = this.props

      if (!isServer) {
        // Load fonts on client side
        const WebFontLoader = require('webfontloader')
        WebFontLoader.load({
          ...config,
          active: () => setIsRenderChildren(true),
        })
      }
    },
  }),
)(({isRenderChildren, children}) => isRenderChildren && children)
