import React from 'react'
import {compose, withProps, withHandlers} from 'recompose'
import styled from 'react-emotion'
import Modal from '../../../share/Modal'
import {Button} from '../../components'
import {withAnswers} from '../../enhances'
import {toCurrency} from '../../core/helper'

const CustomModal = styled(Modal)``
const WrapContentModal = styled.div`
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`
const WrapperTitle = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    color: #ffc107;
    font-size: 40px;
  }
`
const Title = styled.div`
  margin: 0px 0px 0px 0px;
  font-size: 20px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;
  text-align: center;
`
const MobileCutWord = styled.br`
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`
const Description = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  font-family: Prompt;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
`
const AgreeButton = styled(Button)`
  min-height: 38px;
  max-height: 38px;
  min-width: 250px;
  max-width: 250px;
`

const TooltipReduceTaxModal = props => (
  <CustomModal isShowModal={props.isShowModal} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <WrapperTitle>
        <Title>
          สามารถลดภาษี <MobileCutWord />
          ด้วยประกันได้สูงสุด
        </Title>
      </WrapperTitle>
      <Description>
        หากซื้อประกันเกิน {toCurrency(props.maxInsuranceInvest, {prefix: ''})}{' '}
        บาท จะยังได้รับความคุ้มครองจากประกันตามปกติ แต่เงินที่จ่ายส่วนเกินจาก{' '}
        {toCurrency(props.maxInsuranceInvest, {prefix: ''})} บาท
        จะไม่ถูกนำมาคำนวณเพื่อลดหย่อนภาษี
      </Description>
      <Footer>
        <AgreeButton onClick={props.onAgreeProduct}>ตกลง</AgreeButton>
      </Footer>
    </WrapContentModal>
  </CustomModal>
)

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    maxInsuranceInvest: 'summary_data.value.maxInsuranceInvest',
  }),
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
    onAgreeProduct: props => () => {
      props.setIsShowModal(false)
    },
  }),
)

export default enhancer(TooltipReduceTaxModal)
