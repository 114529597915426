import {takeLatest, put} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {message} from 'antd'
import {httpPost, API_HOST} from '../../core/httpClient'

const SET_DATA = 'SET_DATA'
const CONTACT_US = 'CONTACT_US'
const SALE_CONTACT_US = 'SALE_CONTACT_US'

export const setData = Creator(SET_DATA, 'data')
export const contactUs = Creator(CONTACT_US, 'data', 'callback')
export const saleContactUs = Creator(SALE_CONTACT_US, 'data', 'callback')

export function* contactUsSaga({payload: {data, callback}}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}contact_us`
  try {
    const response = yield httpPost(url, data)
    yield put(setData({isLoading: false}))
    message.success('ส่งข้อมูลสำเร็จแล้ว', 5)
    callback()
  } catch (error) {
    switch (error.response.status) {
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
    yield put(setData({isLoading: false}))
  }
}

export function* saleContactUsSaga({payload: {data, callback}}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}sale_registers`
  try {
    const response = yield httpPost(url, data)
    yield put(setData({isLoading: false}))
    message.success('ส่งข้อมูลสำเร็จแล้ว', 5)
    callback()
  } catch (error) {
    switch (error.response.status) {
      case 403:
        yield put(setData({errorMessage: 'อีเมลถูกลงทะเบียนแล้ว'}))
        break
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
    yield put(setData({isLoading: false}))
  }
}

export function* watchContactUsSaga() {
  yield takeLatest(CONTACT_US, contactUsSaga)
  yield takeLatest(SALE_CONTACT_US, saleContactUsSaga)
}

const initial = {
  isLoading: false,
  errorMessage: '',
}

export default createReducer(initial, state => ({
  [SET_DATA]: ({data}) => ({...state, ...data}),
}))
