import React from 'react'
import styled from 'react-emotion'
import {reduxForm} from 'redux-form'
import {compose, withState} from 'recompose'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Button from '../../share/Button'
import Field from '../../share/Field'
import {sendEmailNews} from '../../../ducks/noonSquare'

const Container = styled.form`
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 110px;
  width: 250px;
  height: 345px;
  background-color: ${props => props.theme.BACKGROUNDPOPUP};
  border: 1px solid ${props => props.theme.BORDERPOPUP};
  border-radius: 5px;
  margin: 30px 0 45px 60px;
`

const CustomButton = styled(Button)`
  width: 80px;
  height: 30px;
  border-radius: 15px;
  font-size: 12px;
`
const GoogleInput = styled(Field)`
  height: unset;

  .input-googleInput-container {
    input {
      width: 200px;
      border: 1px solid ${props => props.theme.BORDERFIELD};
      background-color: ${props => props.theme.BACKGROUNDPOPUP};
      color: ${props => props.theme.TEXTINPUT};
      :focus ~ label {
        background-color: ${props => props.theme.BACKGROUNDPOPUP};
      }
    }
    label {
      color: ${props => props.theme.LABELINPUT};
      background-color: ${props => props.theme.BACKGROUNDPOPUP};
      background: unset;
    }
  }
`
const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: ${props => props.sendEmail && '20px'};
`
const Content = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 5px 0 26px;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 20px;
  top: -13px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(127, 127, 127, 0.5);
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;
`

const News = ({
  handleSubmit,
  isShowPopUp,
  setShowPopup,
  sendEmailNews,
  sendEmail,
}) =>
  isShowPopUp && (
    <Container onSubmit={handleSubmit(value => sendEmailNews(value))}>
      <DeleteIcon
        icon={['fas', 'times-circle']}
        onClick={() => setShowPopup(false)}
      />
      {sendEmail ? (
        <React.Fragment>
          <Header sendEmail={sendEmail}>เรียบร้อยแล้ว</Header>
          <Content>
            รอรับข่าวสาร
            <br />
            และสิทธิพิเศษจากเรา
            <br />
            ในอินบ็อกซ์ของคุณได้เลย!
          </Content>
          <CustomButton
            onClick={() => setShowPopup(false)}
            icons={['fa', 'times-circle']}>
            ปิด
          </CustomButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header>รับข่าวสารจากเรา</Header>
          <Content>
            สมัครเลยวันนี้
            <br />
            พร้อมรับสิทธิพิเศษมากมาย
            <br />
            ก่อนใคร
          </Content>
          <GoogleInput name="name" type="googleInput" placeholder="ชื่อ" />
          <GoogleInput name="email" type="googleInput" placeholder="อีเมล" />
          <CustomButton htmlType="submit" icons={['fa', 'check-circle']}>
            ยืนยัน
          </CustomButton>
        </React.Fragment>
      )}
    </Container>
  )

const enhancer = compose(
  connect(
    state => ({
      sendEmail: state.noonSquare.sendEmail,
    }),
    {sendEmailNews},
  ),
  withState('isShowPopUp', 'setShowPopup', true),
  reduxForm({
    form: 'NewsPopup',
  }),
)

export default enhancer(News)
