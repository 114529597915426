import React from 'react'
import Modal from '../../share/Modal'
import {compose, withProps, withHandlers} from 'recompose'
import styled from 'react-emotion'
import CustomField from '../../share/Field'
import {obj2Url, displayNumber} from '../../../core/helper'
import Button from '../../share/Button'
import {withForm} from '../enhancers'
import {Field, PenCurrency} from '../components/index'

const WrapContentModal = styled.form`
  padding: 60px;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const Title = styled.h2`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`
const Slider = styled(CustomField)`
  height: unset;
  width: 280px;
  margin: 0;
  .ant-slider {
    width: 100%;
    margin-left: unset;
  }
`

const WrapTextSlider = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
const ConfirmButton = styled(Button)`
  margin-top: 20px;
  &:focus,
  &:active {
    color: #333333;
  }
`
const SliderValue = styled.div`
  font-size: 24px;
`
const WrapInfo = styled.div``
const Span = styled.span`
  color: #265fa7;
`
const WrapValue = styled.div``
const lable = {
  female: 'หญิง',
  male: 'ชาย',
}
const BuyHealthModal = props => (
  <Modal isShowModal={props.isShowModal} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <Title>ลองมาเลือกดูประกันสุขภาพ</Title>
      <WrapInfo>
        เพศ <Span>{lable[props.gender] || ''}</Span> อายุ{' '}
        <Span>{props.age || ''}</Span> ปี
      </WrapInfo>

      <Field
        name="sumInsured"
        component={PenCurrency}
        marginBottom="16px"
        min={0}
        max={props.max}
        minValue={0}
      />
      <Slider type="slider" name="sumInsured" min={0} max={props.max} />
      <WrapTextSlider>
        <WrapValue>0</WrapValue>
        <WrapValue>{displayNumber(props.max)}</WrapValue>
      </WrapTextSlider>
      <div>เลือกดูจากแบบ คุ้มครองค่ารักษาพยาบาล</div>
      <a href={props.pathUrl} target="_blank">
        <ConfirmButton disabled={props.invalid}>ดูแบบประกัน</ConfirmButton>
      </a>
    </WrapContentModal>
  </Modal>
)

const enhancer = compose(
  withProps(props => ({
    initialValues: {
      sumInsured: props.values ? props.values.sumInsured : 0,
    },
  })),
  withForm({
    form: 'NoonQuestionBuyHealthModalForm',
  }),
  withProps(props => ({
    sumInsured: props.values.sumInsured || 0,
    invalid: !props.values.sumInsured || props.values.sumInsured === 0,
    pathUrl: `/smart/health/intermediate/product?${obj2Url({
      age: props.age,
      gender: props.gender,
      insuranceType: 'hs',
      searchValue: props.values.sumInsured,
      sortBy: 'min_premium',
      hsGoal: 'room',
    })}`,
  })),
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
      props.reset()
    },
  }),
)

export default enhancer(BuyHealthModal)
