import React from 'react'
import styled from 'react-emotion'
import {compose, lifecycle, branch, renderNothing, withState} from 'recompose'
import {Switch, Route, Redirect} from 'react-router-dom'
import * as path from '../common/path'

import Landing from './Landing'
import Planning from './planning/index'
import EditPlanningDetails from './EditPlanningDetails'
import ProductDetail from './productDetail/index'
import {SpidusManager} from '../components/index'
import {spidusStore} from '../core/spidus'

const MainSpidusManager = styled(SpidusManager)`
  background: white;
`
const Tax = props => (
  <MainSpidusManager configName="noontax_config_web_tax_planning">
    <Switch>
      <Route
        path={path.productDetailPath(':policyType', ':policyId')}
        component={ProductDetail}
      />
      <Route path={path.editPlanningPath()} component={EditPlanningDetails} />
      <Route path={path.planningPath()} component={Planning} />
      <Route path={path.landingPath()} component={Landing} />
      <Redirect to={path.landingPath()} />
    </Switch>
  </MainSpidusManager>
)

const enhancer = compose(
  withState('initialized', 'setInitialized', false),
  lifecycle({
    async componentDidMount() {
      const names = [
        'age',
        'gender',
        'income_salary',
        'summary_data',
        'planning_deduct_pension_insurance',
        'planning_deduct_life_insurance',
        'deduct_life_insurance',
        'deduct_pension_insurance',
        'deduct_health_insurance',
        'deduct_ltf',
        'deduct_rmf',
        'deduct_special_donate',
        'deduct_general_donate',
        'deduct_sso',
        'deduct_provident_fund',
        'deduct_homeloan_interest',
      ]

      await spidusStore.loadAnswers('noontax_config_web_tax_planning', ...names)

      this.props.setInitialized(true)
    },
  }),
  branch(props => !props.initialized, renderNothing),
)

export default enhancer(Tax)
