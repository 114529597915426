import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, lifecycle, withHandlers} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ThemeProvider} from 'emotion-theming'
import windowSize from '../../../core/react-window-size'

import SimilarContent from './SimilarContent'
import Comment from '../Comment'
import NewsPopup from './NewsPopup'
import Favorite from '../Favorite'
import ModalShareSocial from './ModalShareSocial'
import Loading from '../../share/Loading'
import avatar13 from '../../../static/images/avatar-13.svg'

import {getArticle} from '../../../ducks/noonSquare'
import {getThaiDate} from '../../../core/helper'
import {
  lightTheme,
  nightTheme,
  responsive,
  breakpoints,
} from '../../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: -80px;

  background-color: ${props => props.theme.BACKGROUND};
  color: ${props => props.theme.FONT};
  transition: background-color 0.5s, color 0.5s;
`

const BackgroundImage = styled.img`
  width: 100%;
  max-width: 972px;
  max-height: 693px;

  object-fit: cover;
`

const HeaderTopic = styled.h1`
  margin: 0;

  color: ${props => props.theme.FONT};
  font-family: 'Prompt';
  font-weight: 700;
  font-size: 32px;
  word-break: normal;

  ${props =>
    responsive.tablet(css`
      font-size: 28px;
    `)};
  ${props =>
    responsive.mobile(css`
      font-size: 24px;
    `)};
`

const ContainerBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  line-height: 34px;
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
`

const DateAuthor = styled.h2`
  font-size: 12px;
  color: #7f7f7f;
  margin: 10px 0;
`

const TagsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 13px;
    `)};
`

const Tag = styled.div`
  font-size: 14px;
  color: #265fa7;
  margin-right: 5px;
`

const ContainerIcon = styled.div`
  display: flex;
  padding-top: 5px;
`

const ContainerHeaderIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin-bottom: 30px;
    `)};
`

const HeaderWriter = styled.div`
  font-size: 16px;
`

const StyledWriter = styled.div`
  font-size: 12px;
`

const Profile = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 23px;
`

const ContainerWriter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 57px;
  ${props =>
    responsive.mobile(css`
      justify-content: flex-start;
    `)};
`

const ContainerInfoWriter = styled.div`
  flex-direction: column;
`

const Content = styled.div`
  h1 {
    color: ${props => props.theme.FONT};
    font-size: 22px;
  }
  h2 {
    color: ${props => props.theme.FONT};
    font-size: 20px;
  }
  h3 {
    color: ${props => props.theme.FONT};
    font-size: 18px;
  }
  strong {
    color: ${props => props.theme.FONT};
    font-weight: bold;
  }
  p {
    color: ${props => props.theme.FONT};
    font-size: 16px;
  }
`

const ContainerTopBody = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  max-width: 998px;
  padding: 0 24px;

  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

function getDangerText(windowWidth, article) {
  return {__html: article.articleNormalSize}
}

const NotFound = styled.h2`
  margin: 30px 0;

  text-align: center;
  font-size: 14px;
`

const SquareDetail = ({
  error,
  hrefId,
  article,
  isLoading,
  windowWidth,
  isNightMode,
  toggleNightMode,
  isShowModalShareSocial,
  setIsShowModalShareSocial,
}) => (
  <Loading isLoading={isLoading}>
    <ThemeProvider theme={isNightMode ? nightTheme : lightTheme}>
      {article.id ? (
        <Container>
          <BackgroundImage src={article.imageArticle} />
          <ContainerTopBody>
            <ContainerBody>
              <ContainerHeaderIcon>
                <div>
                  <HeaderTopic>{article.topicName}</HeaderTopic>
                  <DateAuthor>
                    {getThaiDate(article.createdAt)} บทความโดย{' '}
                    {article.signatureSynonym}
                  </DateAuthor>
                </div>
                <ContainerIcon>
                  <Favorite article={article} type="detail" from="square" />
                  <CustomIcon
                    id={`square-article-share-${article.id}`}
                    onClick={() =>
                      setIsShowModalShareSocial(!isShowModalShareSocial)
                    }
                    icon={['fas', 'share-alt']}
                  />
                  <CustomIcon
                    id={
                      isNightMode
                        ? 'square-article-light-mode'
                        : 'square-article-dark-mode'
                    }
                    onClick={toggleNightMode}
                    icon={['fas', 'adjust']}
                  />
                  <ModalShareSocial
                    isShowModal={isShowModalShareSocial}
                    setIsShowModal={setIsShowModalShareSocial}
                    hrefId={hrefId}
                    article={article}
                  />
                </ContainerIcon>
              </ContainerHeaderIcon>
              <TagsContainer>
                {article.tags &&
                  article.tags.map((tag, index) => (
                    <Tag key={index}>#{tag.name}</Tag>
                  ))}
              </TagsContainer>
              <Content
                className="square-content"
                dangerouslySetInnerHTML={getDangerText(windowWidth, article)}
              />
              <TagsContainer>
                {article.hashtags &&
                  article.hashtags.map((tag, index) => (
                    <Tag key={index}>
                      {tag.name[0] === '#' ? tag.name : `#${tag.name}`}
                    </Tag>
                  ))}
              </TagsContainer>
              <ContainerWriter>
                <Profile src={avatar13} />
                <ContainerInfoWriter>
                  <HeaderWriter>{article.signatureSynonym}</HeaderWriter>
                  <StyledWriter>Lifestyle Writer</StyledWriter>
                </ContainerInfoWriter>
              </ContainerWriter>
              <Comment />
            </ContainerBody>
            <NewsPopup />
          </ContainerTopBody>
          <SimilarContent />
        </Container>
      ) : error ? (
        <NotFound>{error}</NotFound>
      ) : (
        ''
      )}
    </ThemeProvider>
  </Loading>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.noonSquare.isLoading,
      article: state.noonSquare.article.article || {},
      hrefId: props.location.href,
      error: state.noonSquare.error,
    }),
    {getArticle},
  ),
  withState('isNightMode', 'setNightMode', false),
  withState('isShowModalShareSocial', 'setIsShowModalShareSocial', false),
  withHandlers({
    toggleNightMode: ({isNightMode, setNightMode}) => () => {
      setNightMode(!isNightMode)
    },
  }),
  lifecycle({
    componentDidMount() {
      const {getArticle, id} = this.props
      getArticle(id)
    },
  }),
)

export default windowSize(enhancer(SquareDetail))
