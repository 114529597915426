import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, lifecycle, withHandlers} from 'recompose'
import {reduxForm, getFormValues} from 'redux-form'
import Modal from '../../share/Modal'
import {ThaiDateFormatter, displayNumber} from '../../../core/helper'
import CustomField from '../../share/Field'
import Button from '../../share/Button'
import {
  getOptionsPort,
  setPortDetailData,
  deletePort,
  updatePortDetailData,
  setDataProfile,
} from '../../../ducks/profile/'
import SearchField from './InsuranceSearch'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 246px;
`

const InfoFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 246px;
`

const TextInput = styled(CustomField)`
  display: flex;
  flex-direction: column;
  font-size: 16px;

  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  input {
    width: 246px;
    text-align: left;
    padding: 0 20px;
  }
`

const TextareaInput = styled(CustomField)`
  display: flex;
  flex-direction: column;

  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  textarea {
    text-indent: 0;
  }
`

const Selector = styled(CustomField)`
  display: flex;
  flex-direction: column;
  width: 246px;
  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
`

const FixText = styled.div`
  color: #265fa7;
`

const Topic = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`

const CustomSaveButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 20px;
  padding: 6px 45px;
`

const CustomDeleteButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 20px;
  padding: 6px 47px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;

  :hover {
    background: #e5e5e5;
    border: 1px solid #e5e5e5;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
`

const ContainerConfirm = styled.div`
  padding: 20px;
`
const ConfirmText = styled.div``
const ContainerConfirmButton = styled.div`
  display: flex;
  justify-content: space-around;
`

const ConfirmDialog = props => (
  <Modal
    isShowModal={props.isShowModal}
    setIsShowModal={props.setIsShowModal}
    width="300px">
    <ContainerConfirm>
      <ConfirmText>ต้องการลบข้อมูลใช่หรือไม่</ConfirmText>
      <br />
      <ContainerConfirmButton>
        <Button
          type="text"
          onClick={() => {
            props.setIsShowModal(false)
          }}>
          Cancel
        </Button>
        <Button
          type="text"
          onClick={() => {
            props.onDelete()
            props.setIsShowModal(false)
          }}>
          OK
        </Button>
      </ContainerConfirmButton>
    </ContainerConfirm>
  </Modal>
)

const validate = values => {
  const error = {}
  if (!values.age) {
    error.age = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.policyType) {
    error.policyType = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.insuranceType) {
    error.insuranceType = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.insuranceCompanyName) {
    error.insuranceCompanyName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.policiesSearch) {
    error.policiesSearch = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.policyNo) {
    error.policyNo = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.startPolicyAt) {
    error.startPolicyAt = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.endPolicyAt) {
    error.endPolicyAt = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.remark) {
    error.remark = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.sumInsured) {
    error.sumInsured = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.premium) {
    error.premium = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.payPeriodType) {
    error.payPeriodType = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.yieldToMaturityRate) {
    error.yieldToMaturityRate = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.paymentType) {
    error.paymentType = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.payPeriodValue) {
    error.payPeriodValue = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  return error
}

const EditPortDetail = props => {
  const {
    policyType,
    insuranceType,
    insuranceCompanyName,
    paymentType,
    payPeriodType,
    handleSubmit,
    portData,
    updatePortDetailData,
    defaultValue,
    defaultLabel,
  } = props
  let policyName = portData.name

  return (
    <Container
      onSubmit={handleSubmit(values => {
        {
          props.insuranceSearchList.length !== 0
            ? (policyName = props.insuranceSearchList.find(
                item => item.value === values.policiesSearch,
              ).label)
            : (policyName = portData.name)
        }
        updatePortDetailData({values, policyName})
      })}>
      <InfoFieldsContainer>
        <Topic>
          เจ้าของกรมธรรม์
          <FixText>{portData.firstName + ' ' + portData.lastName}</FixText>
        </Topic>

        <Topic>
          เพศ<FixText>{portData.gender === 'male' ? 'ชาย' : 'หญิง'}</FixText>
        </Topic>
      </InfoFieldsContainer>

      <TextInput name="age" label="อายุ" type="text" />
      <Selector
        name="policyType"
        label="ประเภทประกัน"
        type="selector"
        placeholder="ประเภทประกัน"
        options={policyType}
        themeColor="orange"
      />
      <Selector
        name="insuranceType"
        label="ประเภทกรมธรรม์"
        type="selector"
        placeholder="ประเภทกรมธรรม์"
        options={insuranceType}
        themeColor="orange"
      />
      <Selector
        name="insuranceCompanyName"
        label="บริษัทประกัน"
        type="selector"
        placeholder="บริษัทประกัน"
        options={insuranceCompanyName}
        themeColor="orange"
      />
      <SearchField
        insuranceType={props.values.insuranceType}
        insuranceCompanyName={props.values.insuranceCompanyName}
        defaultValue={defaultValue}
        defaultLabel={defaultLabel}
      />
      <TextInput name="policyNo" label="หมายเลขกรมธรรม์" type="text" />
      <TextInput
        name="startPolicyAt"
        label="วันที่เริ่มสัญญา"
        type="text"
        format={ThaiDateFormatter}
      />
      <TextInput
        name="endPolicyAt"
        label="วันที่ครบกำหนดสัญญา (คุ้มครองถึง)"
        type="text"
        format={ThaiDateFormatter}
      />
      <TextareaInput
        name="remark"
        label="รายละเอียดเพิ่มเติม"
        type="textarea"
      />
      <TextInput
        name="sumInsured"
        label="ทุนประกัน (บาท)"
        type="text"
        format={displayNumber}
      />
      <TextInput
        name="premium"
        label="เบี้ยประกัน (ต่อปี)"
        type="text"
        format={displayNumber}
      />
      <Selector
        name="payPeriodType"
        label="รูปแบบการจ่าย"
        type="selector"
        placeholder="รูปแบบการจ่าย"
        options={payPeriodType}
        themeColor="orange"
      />
      <TextInput
        name="yieldToMaturityRate"
        label="เงินคืนเมื่อครบสัญญา (%)"
        type="text"
      />
      <Selector
        name="paymentType"
        label="วิธีนับระยะเวลาจ่ายเบี้ย"
        type="selector"
        placeholder="จำนวนปีที่จ่าย"
        options={paymentType}
        themeColor="orange"
      />
      <TextInput
        name="payPeriodValue"
        label="จำนวนปีที่จ่ายเบี้ย"
        type="text"
      />
      <ConfirmDialog
        isShowModal={props.isShowModal}
        setIsShowModal={props.setIsShowModal}
        onDelete={props.onDelete}
      />
      <ButtonGroup>
        <CustomDeleteButton
          onClick={() => {
            props.setIsShowModal(true)
          }}>
          ลบ
        </CustomDeleteButton>
        <CustomSaveButton htmlType="submit">บันทึก</CustomSaveButton>
      </ButtonGroup>
    </Container>
  )
}

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  connect(
    (state, props) => ({
      policyType: state.profile.policyType,
      insuranceType: state.profile.insuranceType,
      insuranceCompanyName: state.profile.insuranceCompany,
      paymentType: state.profile.paymentType,
      payPeriodType: state.profile.payPeriodType,
      insuranceSearchList: state.profile.insuranceSearchList,

      defaultValue: props.portData.insurancePolicyId,
      defaultLabel: props.portData.name,

      insurancePolicyNameList: state.product.insurancePolicyNameList,
      initialValues: {
        ...props.portData,
        policiesSearch: props.portData.name,
        insuranceCompanyName: props.portData.insuranceCompanyId,
        startPolicyAt:
          props.portData.startPolicyAt.substring(8, 10) +
          props.portData.startPolicyAt.substring(5, 7) +
          props.portData.startPolicyAt.substring(0, 4),
        endPolicyAt:
          props.portData.endPolicyAt.substring(8, 10) +
          props.portData.endPolicyAt.substring(5, 7) +
          props.portData.endPolicyAt.substring(0, 4),
      },
    }),
    {
      getOptionsPort,
      setPortDetailData,
      deletePort,
      updatePortDetailData,
      setDataProfile,
    },
  ),
  reduxForm({
    validate,
  }),
  connect((state, props) => ({
    values: getFormValues(`editPortDetail${props.portData.id}`)(state) || {},
  })),
  withHandlers({
    onDelete: props => {
      props.deletePort(props.portData.id)
      window.location.reload()
    },
  }),
  lifecycle({
    componentDidMount() {
      const {getOptionsPort} = this.props
      getOptionsPort()
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.values.insuranceType !== this.props.values.insuranceType ||
        prevProps.values.insuranceCompanyName !==
          this.props.values.insuranceCompanyName
      ) {
        this.props.change('policiesSearch', '')
        this.props.setDataProfile({insuranceSearchList: []})
      }
    },
  }),
)

export default enhancer(EditPortDetail)
