import {Field as ReduxFormField} from 'redux-form'
import {compose, withProps} from 'recompose'
import validator from '../common/validator'

const Field = compose(
  withProps(props => ({
    required: props.validate && props.validate.includes('required'),
    validate: props.validate && validator(props.validate),
  })),
)(ReduxFormField)

export default Field
