import React from 'react'
import {Checkbox as AntdCheckbox} from 'antd'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, branch, lifecycle, withProps} from 'recompose'
import {
  withError,
  withSpidus,
  withValue,
  omitProps,
  withCaption,
} from '../enhances/index'
import classnames from 'classnames'
import Icon from './Icon'

const CustomCheckbox = styled(AntdCheckbox)`
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      ::after {
        top: 2.5px;
        left: 8px;
        width: 7px;
        height: 17px;
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: #9fd134;
      background-color: #9fd134;
    }
    :after {
      width: 25px;
      height: 25px;
    }
  }
`
const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
`
const Caption = styled.label`
  font-size: 16px;
  color: #333333;
  font-family: 'Sarabun', sans-serif;
`

const validateErrorStyles = css`
  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: red;
    }
  }
`
const CustomCheckboxWithCaption = ({className, caption, ...checkboxProps}) => (
  <Layout className={className}>
    <CustomCheckbox {...checkboxProps} />
    <Caption>{caption}</Caption>
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption({className: 'checkbox'}),
  withError(validateErrorStyles),
  withHandlers({
    onChange: props => e => {
      props.onChange && props.onChange(e.target.checked)
      props.onBlur && props.onBlur(e.target.checked)
      props.onSelect && props.onSelect(e.target.checked)
    },
  }),
  withProps(props => {
    const className = classnames({
      [props.customStyle]: !!props.customStyle,
      [props.className]: !!props.className,
    })
    return {...props, ...className, className}
  }),
  withProps(props => ({checked: props.value})),
  lifecycle({
    componentWillReceivedProps(nextProps) {
      if (props.value !== nextProps.value) {
        nextProps.onChange(nextProps.value)
      }
    },
  }),
  omitProps('onBlur'),
)

const Checkbox = enhance(CustomCheckbox)

Checkbox.Circle = enhance(props => (
  <Layout
    className={props.className}
    style={{display: 'flex', alignItems: 'center', ...props.style}}>
    <Icon
      id={props.id}
      onClick={() => props.onChange({target: {checked: !props.value}})}
      icon={props.value ? 'check-circle' : ['far', 'circle']}
      style={{
        fontSize: 21,
        color: props.value ? '#9FD134' : '#7F7F7F',
        cursor: 'pointer',
      }}
    />
    <Caption
      onClick={() => props.onChange({target: {checked: !props.value}})}
      style={{
        fontSize: 16,
        color: props.value ? '#333333' : '#7F7F7F',
        marginLeft: 15,
        marginTop: -2,
        cursor: 'pointer',
      }}>
      {props.caption || props.children}
    </Caption>
  </Layout>
))

export default Checkbox
