import React from 'react'
import {compose, withState} from 'recompose'
import styled from 'react-emotion'
import {Icon} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Box1 from '../../static/images/Product-Online-Box-1.jpg'
import Box2 from '../../static/images/Product-Online-Box-2.jpg'
import Box3 from '../../static/images/Product-Online-Box-3.jpg'
import Box4 from '../../static/images/Product-Online-Box-4.jpg'
import Box5 from '../../static/images/Product-Online-Box-5.jpg'
import Box6 from '../../static/images/Product-Online-Box-6.jpg'
import {navigate} from '@reach/router'

const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;

  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;
`

const TopBanner = styled.div`
  position: relative;
  width: 100%;
  height: ${props => (props.isOnTop ? '163px' : '36px')};
  transition: height 0.5s;
  background-color: rgba(38, 95, 167, 0.5);
  margin-top: 15px;
  :hover {
    height: 163px;
  }
`

const Info = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 9px 0;
`
const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 150px;
  height: 88px;
  border-radius: 5px 5px 0 0;
  background-color: white;
  overflow: hidden;
`
const ContainerBox = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0 auto;
`
const InfoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 32px;
  border-radius: 0 0 5px 5px;
  background-color: white;
  font-size: 10px;
  text-align: center;
  white-space: pre-wrap;
`
const ContainerBoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  cursor: pointer;
`

const Arry = [
  {
    picture: Box1,
    label: `สะสมทรัพย์ดีๆ
  ลดหย่อนภาษีสุดคุ้ม`,
    url: 'saving-tax',
  },
  {
    picture: Box2,
    label: `คุ้มครองภาระหนี้สินได้
  เบี้ยจ่ายไม่แพง`,
    url: 'term-life',
  },
  {
    picture: Box3,
    label: `ลดหย่อนสองแสนหลัง
  ด้วยแบบบำนาญ`,
    url: 'tax-annuity',
  },
  {picture: Box4, label: `เกษียณสบาย มีเงินใช้แน่นอน`, url: 'saving-annuity'},
  {picture: Box5, label: `ส่งต่อมรดก ด้วยประกันชีวิต`, url: 'whole-life'},
  {
    picture: Box6,
    label: `สร้างทุนการศึกษามหาลัย
  พร้อมความคุ้มครอง`,
    url: 'scholarship-saving',
  },
]

const CustomPicture = styled.img``

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 40px;
  top: -12px;
  z-index: 1;

  font-size: 25px;
  color: rgba(38, 95, 167, 0.5);
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;

  cursor: pointer;
`

const Overlay = styled.div`
  overflow-x: auto;
`

const Banner = ({isOnTop, isShow, setIsShow}) =>
  isShow && (
    <Container>
      <React.Fragment>
        <TopBanner isOnTop={isOnTop}>
          <CloseIcon
            id="landing-noon-close-ads"
            className="closeIcon"
            isOnTop={isOnTop}
            onClick={() => {
              setIsShow(false)
              const footer = document.getElementById('footer')
              footer.style.padding = '0'
            }}
            icon={['fas', 'times-circle']}
          />
          <Info>แนะนำประกันสำหรับคุณ</Info>
          <Overlay>
            <ContainerBox>
              {Arry.map((e, i) => (
                <ContainerBoxInfo
                  key={i}
                  onClick={() => {
                    dataLayer.push({
                      event: 'click_button_noon_ads',
                      adsName: e.url,
                    })

                    return navigate(`/suggestionProductDetails/${e.url}`)
                  }}>
                  <Box>
                    <CustomPicture src={e.picture} />
                  </Box>
                  <InfoBox>{e.label}</InfoBox>
                </ContainerBoxInfo>
              ))}
            </ContainerBox>
          </Overlay>
          )
        </TopBanner>
      </React.Fragment>
    </Container>
  )

const enhancer = compose(withState('isShow', 'setIsShow', true))

export default enhancer(Banner)
