import React from 'react'
import styled, {css} from 'react-emotion'
import {
  compose,
  branch,
  withProps,
  withHandlers,
  defaultProps,
  withState,
} from 'recompose'
import {displayNumber, numberParser} from '../../../core/helper'
import {
  withSpidus,
  withValue,
  withCaption,
  withDisabled,
  omitProps,
  withReadOnly,
} from '../enhances/index'
import TextInput from '../../share/Field/TextInput'

const CustomTextInput = styled(TextInput)`
  font-size: 16px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
  text-align: right;
`

const disabledStyled = css`
  background-color: #e5e5e5;
`

const ReadOnlyInput = styled.div`
  font-size: 16px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
  text-align: right;
`

const enhance = compose(
  defaultProps({
    precision: 2,
  }),
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption(),
  withProps(props => ({
    value: props.absolute && props.value ? Math.abs(props.value) : props.value,
  })),
  withReadOnly(props => (
    <ReadOnlyInput style={props.style}>
      {props.value === undefined || props.value === null || props.value === ''
        ? ''
        : `฿ ${displayNumber(props.value, props.precision)}`}
    </ReadOnlyInput>
  )),
  withDisabled(disabledStyled),
  defaultProps({maxLength: 8, readOnly: false}),
  // withHandlers({
  //   onChange: props => e => {
  //     const value = e.target.value ? numberParser(e.target.value) : null
  //     if (!value || value < 10 ** props.maxLength) {
  //       props.onChange && props.onChange(value)
  //     }
  //   },
  //   onBlur: props => e => {
  //     props.onBlur && props.onBlur(props.value)
  //   },
  // }),
  // withProps(props => ({
  //   value:
  //     props.value === undefined || props.value === null
  //       ? ''
  //       : displayNumber(props.value),
  // })),
  withState('displayValue', 'setDisplayValue', props =>
    displayNumber(props.value, props.precision),
  ),
  withState('active', 'setActive', false),
  withHandlers({
    onFocus: props => e => {
      props.setDisplayValue(displayNumber(props.value, props.precision))
      props.onFocus && props.onFocus(props.value)
      props.setActive(true)
    },
    onChange: props => e => {
      try {
        const currentToLastLength =
          e.target.value.length - e.target.selectionStart
        const element = e.target

        window.requestAnimationFrame(() => {
          element.selectionStart = element.value.length - currentToLastLength
          element.selectionEnd = element.value.length - currentToLastLength
        })
      } catch (e) {}

      e.target.value = e.target.value.replace(/฿/, '')

      let value = e.target.value ? numberParser(e.target.value) : null

      if (value && value >= 10 ** props.maxLength) {
        return
      }

      let prevValue = value
      if (value !== null && value !== undefined) {
        if (props.min) {
          value = Math.max(value, props.min)
        }
        if (props.max) {
          value = Math.min(value, props.max)
        }
        if (!value && value !== 0 && value !== '0') {
          value = null
        }
      }

      if (prevValue !== value && !isNaN(prevValue) && !isNaN(value)) {
        props.setDisplayValue(value)
      } else if (/^0(\d)$/.test(e.target.value)) {
        const [_, digit] = e.target.value.match(/^0(\d)$/)
        props.setDisplayValue(digit)
      } else if (/^([\d,]*)?(\.[\d]{0,2})?$/.test(e.target.value)) {
        let [_, digit = '', precision = ''] = e.target.value.match(
          /^([\d,]*)?(\.[\d]*)?$/,
        )
        digit = parseInt(digit.replace(/,/g, '')) || 0

        const displayValue = `${displayNumber(
          digit,
          props.precision,
        )}${precision}`
        props.setDisplayValue(displayValue)
      } else {
        return
      }

      props.onChange && props.onChange(value)
    },
    onBlur: props => e => {
      props.setDisplayValue(displayNumber(props.value, props.precision))
      props.onBlur && props.onBlur(props.value)
      props.setActive(false)
    },
  }),
  withProps(props => {
    let value = null
    if (props.active) {
      value = `${props.withPrefix ? '฿' : ''}${props.displayValue}`
    } else {
      value = `${props.withPrefix ? '฿' : ''}${displayNumber(
        props.value,
        props.precision,
      )}`
    }

    return {value}
  }),
  omitProps('maxLength'),
)

export default enhance(CustomTextInput)
