import React from 'react'
import { connect } from 'react-redux'
import { Link } from '@reach/router'
import { compose, lifecycle, withState, withProps, withHandlers } from 'recompose'
import styled from 'react-emotion'
import * as path from '../common/path'
import { withRouter } from '../enhancers'
import { isClient } from '../common/helper'

const TabNoonQuestion = styled.div`
  height: 80px;
  background: #ffb933;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 425px) {
    height: 146px;
  }
`
const WrapContent = styled.div`
  max-width: 965px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 965px;
  justify-content: space-around;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const TextDetails = styled.div`
  color: #ffffff;
  font-size: 28px;
  @media (max-width: 920px) {
    font-size: 20px;
  }
  @media (max-width: 425px) {
    text-align: center;
    margin-top: 10px;
  }
`
const ButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 20px;
  border: unset;
  color: black;
  background: #265fa7;
  position: relative;
  width: 220px !important;
  height: 40px;
  font-size: 24px;
  border-radius: 20px;
  z-index: 998;
  color: #ffffff;
  :hover,
  :active,
  :focus {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    z-index: 99;
    width: 150px !important;
    font-size: 20px;
  }
  @media (max-width: 425px) {
    width: 220px !important;
    margin-bottom: 20px;
  }
`
const ButtonHide = styled(ButtonLink)`
  background: #ffffff;
  color: #ffb933;
  :hover,
  :active,
  :focus {
    color: #ffb933;
  }
`
const Tritangle = styled.div`
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 23px solid #ffffff;
  bottom: 0;
  position: absolute;
`
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
`
const Br = styled.br`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`
const SignNew = styled.div`
  position: absolute;
  right: 0px;
  top: -15px;
  font-size: 10px;
  background: red;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = props => (
  <TabNoonQuestion>
    <WrapContent>
      <TextDetails>
        ลองดูไหมว่าคุณจะต้องมี
        <Br />
        ประกันชีวิตและสุขภาพอะไรบ้าง?
      </TextDetails>
      {props.show ? (
        <Wrap>
          <ButtonHide to={props.closePath} onClick={props.close}>
            ซ่อน
          </ButtonHide>
          <Tritangle />
        </Wrap>
      ) : (
          <ButtonLink to={props.openPath} onClick={props.open}>
            <SignNew>New</SignNew>ไปเลย!
        </ButtonLink>
        )}
    </WrapContent>
  </TabNoonQuestion>
)

const enhancer = compose(
  connect(
    (state) => ({ isLogin: state.auth.isLogin }),
    {},
  ),
  withState('oldAutoHeaderDisplayValue', 'setOldAutoHeaderDisplayValue', null),
  withProps(props => ({
    openPath:
      (isClient && localStorage.getItem('NoonQuestionResumedPath')) ||
      path.rootPath(),
    closePath: '/',
  })),
  withHandlers({
    open: props => () => { },
    close: props => () => {
      console.log('close nw', props)
      localStorage.setItem('NoonQuestionResumedPath', window.location.pathname)
    },
    clearResumePath: props => () => {
      localStorage.setItem('NoonQuestionResumedPath', '')
    },
  }),
  lifecycle({
    componentDidMount() {
      const $autoHeader = document.getElementById('auto-header')
      if ($autoHeader) {
        this.props.setOldAutoHeaderDisplayValue($autoHeader.style.display)
        $autoHeader.style.display = 'none'
      }
      window.addEventListener(
        'beforeunload',
        (window.beforeunloadClearResumeCallback = this.props.clearResumePath),
      )
    },
    componentWillUnmount() {
      const $autoHeader = document.getElementById('auto-header')
      if ($autoHeader) {
        $autoHeader.style.display = this.props.oldAutoHeaderDisplayValue
      }
      window.removeEventListener(
        'beforeunload',
        window.beforeunloadClearResumeCallback,
      )
    },
  }),
)

export default enhancer(Header)
