import React from 'react'
import styled, {css} from 'react-emotion'
import Field from '../../../../../share/Field'
import {compose, withState, lifecycle} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Tooltip from '../../../../../share/Tooltip'
import {displayNumber} from '../../../../../../core/helper'
import {responsive, breakpoints} from '../../../../../../core/style'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 700px;
  padding: 0 71px;
  ${props =>
    responsive.mobile(css`
      padding: 0;
      padding-left: 41px;
      align-items: flex-start;
      width: unset;
    `)};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.tablet(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #7f7f7f;
          }
        }
      }
    `)};
`

const CheckBox = styled(Field)`
  margin: 0;
  width: 120px;

  .label {
    color: black;
    margin-left: 17px;
    min-width: 85px;
  }
  .checkboxContainer {
    margin-right: 0;
  }
`
const Selector = styled(Field)`
  margin: 0;
  width: 160px;
  margin-left: 30px;

  .input-selector-container {
    margin: 0;

    .Select {
      width: 160px;
      height: 40px;
      background-color: white;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
    }
  }

  ${props =>
    responsive.mobile(css`
      margin: 15px 0 15px 35px;
      display: ${props.disabled ? 'none' : 'flex'};
    `)};
`
const ContainerField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: ${props => (props.last ? '30px' : '0')};
  width: 100%;

  ${props =>
    responsive.mobile(css`
      position: relative;
      flex-direction: column;
      width: 200px;
      align-items: unset;
    `)};
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 17px;
  cursor: pointer;
  color: #7f7f7f;
  border-right-color: #7f7f7f;
  ${props =>
    responsive.mobile(css`
      position: absolute;
      top: 0;
      right: 0;
    `)};
`
const Price = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  min-width: 110px;
  text-align: right;
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
      text-align: left;
      justify-content: flex-start;
      margin-left: 35px;
    `)};
`
const Tuition = styled.span`
  min-width: 70px;
  margin-left: 30px;
  font-size: 16px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
      margin-left: 35px;
      margin-bottom: 10px;
    `)};
`

const UnitTuition = styled.span`
  font-size: 16px;
`
const SummaryPrice = styled.span`
  margin-right: 5px;
  color: ${props => props.theme.EXTRALIGHTBLUE};
`

const FieldArray = [
  {
    label: 'ประถม',
    info: '6-11 ปี',
  },
  {
    label: 'มัธยมต้น',
    info: '12-14 ปี',
  },
  {
    label: 'มัธยมปลาย',
    info: '15-17 ปี',
  },
  {
    label: 'ปริญญาตรี',
    info: '18-21 ปี',
  },
  {
    label: 'ปริญญาโท',
    info: '22-23 ปี',
  },
  {
    label: 'ปริญญาเอก',
    info: '24-25 ปี',
  },
]
const disabledEnhancer = compose(
  withState('isDisabled', 'setDisabled', props => !props.isDisabledList),
)

const ContainerFieldForm = disabledEnhancer(
  ({
    item,
    index,
    last,
    isDisabled,
    setDisabled,
    onSummary,
    listSummary,
    onCheckSummary,
    onFinalSummary,
    windowWidth,
  }) => (
    <ContainerField last={last}>
      <CheckBox
        name={`checkbox${index}`}
        type="checkbox"
        label={item.label}
        didChange={() => {
          onCheckSummary()
          onFinalSummary()
          onSummary()
          setDisabled(!isDisabled)
        }}
      />
      <Selector
        name={`selector${index}`}
        type="selector"
        options={[
          {
            label: 'ภาคปกติ',
            value: 'normal',
          },
          {
            label: 'ภาคอินเตอร์',
            value: 'national',
          },
          {
            label: 'ต่างประเทศ',
            value: 'abroad',
          },
        ]}
        disabled={isDisabled}
        didChange={() => {
          onSummary()
          onCheckSummary()
          onFinalSummary()
        }}
        themeColor="orange"
      />
      <Tuition disabled={isDisabled}>ค่าเทอม</Tuition>
      <Price disabled={isDisabled}>
        {Number.isNaN(listSummary[index]) ? (
          <React.Fragment>
            <SummaryPrice>0</SummaryPrice>
            <UnitTuition>บาท</UnitTuition>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SummaryPrice>
              {displayNumber(Math.round(listSummary[index]))}
            </SummaryPrice>
            <UnitTuition>บาท</UnitTuition>
          </React.Fragment>
        )}
      </Price>
      <CustomTooltip
        placement={`${windowWidth <= breakpoints.tablet ? 'bottom' : 'right'}`}
        title={`${item.info}`}>
        <CustomIcon icon={['fas', 'info-circle']} />
      </CustomTooltip>
    </ContainerField>
  ),
)

const SubFormNoon = ({
  onSummary,
  listSummary,
  onCheckSummary,
  onFinalSummary,
  totalList,
  windowWidth,
}) => (
  <Container>
    {FieldArray.map((item, index) => (
      <ContainerFieldForm
        item={item}
        index={index}
        last={FieldArray.length === index + 1}
        key={item.label}
        onSummary={onSummary}
        listSummary={listSummary}
        onCheckSummary={onCheckSummary}
        onFinalSummary={onFinalSummary}
        isDisabledList={totalList.noon[`checkbox${index}`]}
        windowWidth={windowWidth}
      />
    ))}
  </Container>
)

export default SubFormNoon
