import React from 'react'
import {connect} from 'react-redux'
import {compose, withProps, withHandlers} from 'recompose'
import {reduxForm, Field as ReduxFormField} from 'redux-form'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '../../share/Button'
import Field from '../../share/Field'
import Modal from '../../share/Modal'
import Tooltip from '../../share/Tooltip'
import windowSize from '../../../core/react-window-size'
import Loading from '../../share/Loading'
import {navigate} from '@reach/router'
import {DatePicker} from '../../NoonTax/components'
import {toDate} from '../../NoonTax/components/DatePicker'

import {
  phoneFormatter,
  formatParser,
  ThaiDateFormatter,
  normalizeDate,
  isPhoneNumber,
  isEmail,
} from '../../../core/helper'
import {buyProduct} from '../../../ducks/product'
import avatar02 from '../../../static/images/avatar-02.svg'
import {responsive, breakpoints} from '../../../core/style'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 98px;
  margin-bottom: 10px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
    `)};
`

const SubHeader = styled.div`
  font-size: 16px;
  ${props =>
    responsive.mobile(css`
      font-size: 14px;
    `)};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #7f7f7f;
          }
        }
      }
    `)};
`

const HeaderSale = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 92px;
  margin-bottom: 22px;
`

const CustomCheckbox = styled(Field)`
  margin: unset;
  width: 100%;
  max-width: 250px;
  margin: 15px 0;

  .checkboxContainer {
    margin-right: unset;
    justify-content: center;
  }

  .ant-checkbox {
    margin-top: 5px;
  }
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .label {
    margin-left: 10px;
    font-size: 12px;
  }
`

const Selector = styled(Field)`
  margin: 15px 0;
  width: 250px;

  .input-googleSelector-container {
    margin: 0;

    .Select {
      border-radius: 5px;
      text-align: center;
      font-size: 16px;

      > .Select-menu-outer {
        width: 250px;
        margin-top: 11px;
        left: -1px;
        .Select-menu {
          border-radius: 0 0 5px 5px;
          background-color: white;
          border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
          border-top: 0;
          padding: 0;

          .Select-option {
            color: rgba(51, 51, 51, 0.5);
            font-size: 16px;
            padding: 8px 0 8px 15px;
            width: 250px;
            height: 40px;
            :hover {
              background-color: rgba(255, 212, 130, 0.5);
              color: black;
            }
          }
        }
      }
    }
  }
`

const GenderButton = styled(Field)`
  width: 100%;
  > div {
    > div {
      justify-content: center;
      .male {
        width: 125px;
      }
      .female {
        width: 125px;
      }
    }
  }
  .label {
    min-width: 100px;
  }

  ${props =>
    responsive.mobile(css`
      .input-switch-container {
        justify-content: center;
      }
    `)};
`
const GoogleInput = styled(Field)`
  margin: 15px 0;
`

const SubmitButton = styled(Button)`
  min-width: 80px;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 100px;
  font-size: 12px;
`

const Profile = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 22px;
`
const Info = styled.div`
  font-size: 16px;
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.YELLOW};
  width: 17px;
  height: 15px;
`

const CustomInfoIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: -35px;
  top: 26px;
  color: #7f7f7f;
  border-right-color: #7f7f7f;
  font-size: 18px;
  ${props =>
    responsive.mobile(css`
      position: absolute;
      top: -5px;
      right: 0px;
    `)};
`
const ContainerStarIcon = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 68px;
`
const ContainerMotorResponse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 273px;
`
const MotorResponseHeader = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const MotorResponseH1 = styled.span`
  font-size: 16px;
  line-height: 2;
`
const MotorResponseH2 = styled.span`
  font-size: 12px;
  color: #7f7f7f;
  margin: 0px 8px;
`

const ContainerInfoDate = styled.div`
  position: relative;
`
const CustomLoading = styled(Loading)`
  .loading-container {
    position: absolute;
  }
`

const optionsTime = [
  {label: '08.00-12.00', value: '08.00-12.00'},
  {label: '12.00-16.00', value: '12.00-16.00'},
  {label: '16.00-20.00', value: '16.00-20.00'},
]

const optionsUrgent = [
  {label: 'มาก (ภายใน 24 ชม.)', value: 1},
  {label: 'ปานกลาง (ภายใน 3 วัน)', value: 2},
  {label: 'น้อย (ภายใน 1 สัปดาห์)', value: 3},
]

const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastName) {
    errors.lastName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }

  if (!values.telnumber) {
    errors.telnumber = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.telnumber)) {
    errors.telnumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (values.email !== '' && !isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  if (!values.time) {
    errors.time = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.urgent) {
    errors.urgent = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.consent) {
    errors.consent = 'กรุณาเลือกยอมรับ'
  }
  errors._error = Object.keys(errors).length
  return errors
}

const LabelContainer = styled.div`
  display: flex;
  align-content: center;
  margin-top: 5px;
`

const LabelLink = styled.div`
  color: blue;
  text-decoration: underline;
`

const CustomDatePicker = ({input, ...props}) => (
  <DatePicker {...input} {...props} />
)

const BirthDatePicker = styled(Field)`
  margin: 15px 0;
  width: 250px;

  > div > div {
    width: 250px !important;

    > div:first-child {
      width: 250px !important;
    }
  }

  color: white;

  .css-93qorp input {
    text-align: center;
    font-size: 16px;
  }
`

const CustomLabel = () => (
  <LabelContainer>
    ยอมรับ
    <LabelLink onClick={() => window.open(`/policy`, '_blank')}>
      นโยบายและเงื่อนไขการใช้งาน
    </LabelLink>
  </LabelContainer>
)
const MotorResponse = () => (
  <ContainerMotorResponse>
    <MotorResponseHeader>ขอบคุณที่ใช้บริการของ NOON</MotorResponseHeader>

    <MotorResponseH1>เจ้าหน้าที่จะติดต่อกลับในเวลาที่ระบุไว้</MotorResponseH1>
    <MotorResponseH2>
      ดำเนินการโดย บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด
    </MotorResponseH2>
  </ContainerMotorResponse>
)

const ModalCatalogue = ({
  isShowModal,
  setIsShowModal,
  handleSubmit,
  saleRep,
  category,
  params,
  premium,
  id,
  buyProduct,
  promotionCode,
  packageId,
  vat,
  amount,
  amountNet,
  insureLevel,
  companyId,
  paramsEncode,
  productName,
  windowWidth,
  cover,
  isLoading,
  error,
  isLogin,
}) => (
  <Modal
    isShowModal={isShowModal}
    onCancel={() => {}}
    setIsShowModal={setIsShowModal}>
    {!(saleRep[id] || saleRep[packageId] || saleRep[promotionCode]) ? (
      <CustomLoading isLoading={isLoading}>
        <Container
          noValidate
          onSubmit={handleSubmit(value => {
            dataLayer.push({
              event: 'noon_send_quotation',
              buyInsuranceProductId: id,
              buyBirthDate:
                value.birthdate.getDate() +
                '/' +
                (value.birthdate.getMonth() + 1) +
                '/' +
                value.birthdate.getFullYear(),
              buyContactTime: value.time,
              buyUrgent: value.urgent,
              buyGender: value.gender,
              buyInsuranceProductName: productName,
              buyInsuranceProductType: category,
              buyAge: params.age,
            })

            buyProduct(
              {
                premium,
                value,
                promotionCode,
                packageId,
                id,
                params,
                vat,
                amount,
                amountNet,
                insureLevel,
                companyId,
                paramsEncode,
                cover,
              },
              category,
            )
          })}>
          <Header>กรอกข้อมูลเพื่อให้ตัวแทนติดต่อกลับ</Header>
          <SubHeader>
            สำหรับแบบประกันแผน <b>{productName}</b>
          </SubHeader>
          {category !== 'motor' && (
            <SubHeader>
              สำหรับเพศ <b>{params.gender === 'male' ? 'ชาย' : 'หญิง'}</b> อายุ{' '}
              <b>{params.age}</b> ปี
            </SubHeader>
          )}

          {/* <GenderButton name="gender" type="switch" /> */}
          <GoogleInput
            name="firstName"
            type="googleInput"
            placeholder="ชื่อ"
            hideError
          />
          <GoogleInput
            name="lastName"
            type="googleInput"
            placeholder="นามสกุล"
            hideError
          />
          <ContainerInfoDate>
            {/* <ReduxFormField
              mode="picker"
              name="birthdate"
              viewMode="months"
              viewDate={
                new Date((new Date().getFullYear() - params.age).toString())
              }
              component={BirthDatePicker}
            /> */}
            <BirthDatePicker
              name="birthdate"
              type="datepicker"
              viewMode="months"
              viewDate={
                new Date((new Date().getFullYear() - params.age).toString())
              }
              hideError
              placeholder="วันเกิด"
            />

            <CustomTooltip
              placement={`${
                windowWidth <= breakpoints.mobile ? 'bottom' : 'right'
              }`}
              title={`ข้อมูลที่ท่านกรอก จะถูกนำไปใช้ในการวิเคราะห์ข้อมูลลูกค้าเท่านั้น โดยไม่ได้มีผลกับค่าเบี้ยประกันแต่อย่างใด`}>
              <CustomInfoIcon icon={['fas', 'info-circle']} />
            </CustomTooltip>
          </ContainerInfoDate>
          <GoogleInput
            name="telnumber"
            type="googleInput"
            placeholder="เบอร์โทร"
            format={phoneFormatter}
            parse={formatParser}
            hideError
          />
          <GoogleInput
            name="email"
            type="googleInput"
            placeholder="อีเมล"
            hideError
          />
          <Selector
            name="time"
            type="googleSelector"
            placeholder="เวลาที่สะดวกให้ติดต่อ"
            options={optionsTime}
            hideError
          />

          <Selector
            name="urgent"
            type="googleSelector"
            placeholder="ความเร่งด่วนในการติดต่อกลับ"
            options={optionsUrgent}
            hideError
          />
          <CustomCheckbox
            name="consent"
            label={<CustomLabel />}
            type="checkbox"
          />
          <SubmitButton
            id="noon-send-quotation"
            htmlType="submit"
            disabled={error}
            icons={['fa', 'check-circle']}>
            ยืนยัน
          </SubmitButton>
        </Container>
      </CustomLoading>
    ) : saleRep[promotionCode] === 'motorAgent' ? (
      <MotorResponse />
    ) : (
      <Container>
        <HeaderSale>ตัวแทนของคุณ</HeaderSale>
        <Profile
          src={
            category === 'health'
              ? saleRep[packageId].avatar || avatar02
              : saleRep[id].avatar || avatar02
          }
        />
        {category === 'health'
          ? saleRep[packageId].avatar && (
              <Info>
                เลขประจำตัว{' '}
                {category === 'health'
                  ? saleRep[packageId].avatar
                  : saleRep[id].licenseNo}
              </Info>
            )
          : saleRep[id].licenseNo && (
              <Info>
                เลขประจำตัว{' '}
                {category === 'health'
                  ? saleRep[packageId].avatar
                  : saleRep[id].licenseNo}
              </Info>
            )}
        <Info>
          {category === 'health' ? saleRep[packageId].name : saleRep[id].name}
        </Info>
        <ContainerStarIcon>
          {[1, 2, 3, 4, 5].map(item => (
            <CustomIcon key={item} icon={['fas', 'star']} />
          ))}
        </ContainerStarIcon>
      </Container>
    )}
  </Modal>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.product.isLoadingModal,
      birthdate: state.auth.user,
      initialValues: {
        gender: props.params.gender || 'male',
        email: state.auth.user.email,
        firstName: state.auth.user.firstNameTh,
        lastName: state.auth.user.lastNameTh,
        telnumber: state.auth.user.phoneNumber,
        birthdate: toDate(state.auth.user.birthdate),
      },
      saleRep: state.product.saleRep,
    }),
    {buyProduct},
  ),
  reduxForm({
    form: 'ModalCatalogue',
    destroyOnUnmount: false,
    validate,
    enableReinitialize: true,
  }),
  withHandlers({
    onCancelModal: ({category}) => () => {
      dataLayer.push({
        event: `close_${category}_catalogue_modal`,
      })
    },
  }),
)
export default windowSize(enhancer(ModalCatalogue))
