import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import Tooltip from '../../../share/Tooltip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'
import {reduxForm, formValueSelector} from 'redux-form'

import Button from '../../../share/Button'
import CustomField from '../../../share/Field'
import Loading from '../../../share/Loading'

import {
  carFixing,
  assignParties,
  fixing,
  firstDamage,
  otherDamage,
} from '../../options'
import {displayNumber, numberParser, obj2Url} from '../../../../core/helper'
import {savePlanning} from '../../../../ducks/product'
import {responsive} from '../../../../core/style'

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }
`

const Radios = styled(CustomField)`
  width: fit-content;

  .label {
    min-width: 230px;
  }
  .ant-radio-wrapper {
    min-width: 95px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      .label {
        justify-content: flex-start;
        margin-bottom: 15px;
      }
      .ant-radio-group {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        padding-left: 15px;
      }

      .ant-radio-wrapper {
        margin-bottom: 15px;
      }
    `)};
`

const Checkboxes = styled(CustomField)`
  width: fit-content;
  padding-top: 5px;
  margin: unset;
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 230px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      .label {
        min-width: unset;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
      .ant-checkbox-group {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        padding-left: 15px;
      }

      .ant-checkbox-group-item {
        margin-bottom: 15px;
        margin-right: unset;
      }
    `)};
`

const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ChoosingCoverageFormHeader = styled.div`
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;

  ${props =>
    responsive.mobile(css`
      max-width: 164px;
      text-align: center;
    `)};
`
const Panel = styled.div`
  width: 700px;
  border: 1px solid #6a94cb;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 56px;

  ${props =>
    responsive.mobile(css`
      max-width: 280px;
    `)};
`

const FormContainer = styled.div`
  margin: 35px 0 0 46px;

  ${props =>
    responsive.mobile(css`
      padding: 30px 32px 32px 21px;
      margin: unset;
    `)};
`
const FooterFormContainer = styled.div`
  padding-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  background-color: #6a94cb;
  ${props =>
    responsive.mobile(css`
      padding: 30px 19px 0 19px;
    `)};
`
const CarValueContainer = styled.div`
  display: flex;
`
const Unit = styled.span`
  font-size: 16px;
  padding-top: 8px;
  margin-left: 18px;
`
const TextInput = styled(CustomField)`
  width: 430px;

  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 230px;
  }
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-top: 9px;
  margin-left: 25px;
  cursor: pointer;
  color: #7f7f7f;
`
const TextFooter = styled.div`
  width: fit-content;
  font-size: 16px;
  color: white;
  text-align: center;
`

const CustomButton = styled(Button)`
  min-width: 150px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 30px;
`

export const ChoosingCoverageForm = ({
  handleSubmit,
  coverCarSelfRepair,
  isLoading,
  savePlanning,
  buttonText,
  specifyOtherDriver,
  onSubmit,
  change,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={
        onSubmit ||
        handleSubmit(value => {
          window.open(
            `/smart/motor/starter/product?${obj2Url({
              ...value,
              sortBy: 'min_premium',
            })}`,
            '_self',
          )
          if (value.repairServiceType) {
            if (value.repairServiceType.length !== 0) {
              value.repairServiceType.shift()
            }
          }
          if (value.otherDamage) {
            if (value.otherDamage.length !== 0) {
              value.otherDamage.shift()
            }
          }
          savePlanning(
            {
              user_level: 'starter',
              vehicle_year: value.vehicleYear,
              vehicle_brand: value.vehicleBrandId,
              vehicle_model: value.vehicleModelId,
              vehicle_sub_brand: value.idKey,
              vehicle_size: value.engineSize,
              using: value.carUsage,
              red_license: value.redLabel,
              vehicle_value: value.cover,
              car_fixing_type: value.coverCarSelfRepair,
              assign_parties: value.specifyOtherDriver,
              fixing: value.repairServiceType,
              first_damage: value.deduct,
              other_damage: value.otherDamage,
              using_duration: value.driverBirthYear,
            },
            'intermediate',
            'auto',
          )
        })
      }>
      <ChoosingCoverageFormHeader>
        เลือกความคุ้มครองที่ต้องการเลย
      </ChoosingCoverageFormHeader>
      <Panel>
        <FormContainer>
          <Radios
            name="coverCarSelfRepair"
            label="คุ้มครองค่าซ่อมแซมรถเราเองด้วย"
            type="radios"
            options={carFixing}
            didChange={value => {
              value
                ? value && specifyOtherDriver
                  ? change('insuranceLevel', ['1', '20', '30'])
                  : change('insuranceLevel', ['1'])
                : change('insuranceLevel', ['2', '3'])
            }}
          />
          {coverCarSelfRepair && (
            <Radios
              name="specifyOtherDriver"
              label="การระบุคู่กรณี"
              type="radios"
              options={assignParties}
              didChange={value => {
                coverCarSelfRepair
                  ? coverCarSelfRepair && value
                    ? change('insuranceLevel', ['1', '20', '30'])
                    : change('insuranceLevel', ['1'])
                  : change('insuranceLevel', ['2', '3'])
              }}
            />
          )}
          <Checkboxes
            name="repairServiceType"
            label="การจัดซ่อม"
            type="checkboxes"
            options={fixing}
          />
          <Radios
            name="deduct"
            label="ความเสียหายส่วนแรก"
            type="radios"
            options={firstDamage}
          />
          <Checkboxes
            name="otherDamage"
            label="ความคุ้มครองเพิ่มเติม"
            type="checkboxes"
            options={otherDamage}
          />
        </FormContainer>
        <FooterFormContainer>
          <TextFooter>
            คุณสามารถใช้แบบประกัน{' '}
            <b>
              {coverCarSelfRepair
                ? coverCarSelfRepair && specifyOtherDriver
                  ? 'ประเภท 1 หรือ ประเภท 2+ หรือ ประเภท 3+'
                  : 'ประเภท 1'
                : 'ประเภท 2 หรือ ประเภท 3'}
            </b>
            <br />
          </TextFooter>
          <CustomButton htmlType="submit" icons={['fa', 'search']}>
            {buttonText}
          </CustomButton>
        </FooterFormContainer>
      </Panel>
    </Container>
  </Loading>
)

const select = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      coverCarSelfRepair: select(state, 'coverCarSelfRepair'),
      specifyOtherDriver: select(state, 'specifyOtherDriver'),
      isLoading: state.options.isLoading,
    }),
    {savePlanning},
  ),
  reduxForm({
    form: 'Planning',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(ChoosingCoverageForm)
