import {compose, withProps} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const enhancer = compose(
  withProps(props => ({
    icon: typeof props.icon === 'string' ? ['fas', props.icon] : props.icon,
  })),
)

export default enhancer(FontAwesomeIcon)
