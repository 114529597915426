import React from 'react'
import styled from 'react-emotion'
import {Icon} from 'antd'

import TextInput from './TextInput'

export const PasswordInput = styled(TextInput)``
export const ShowPassword = styled.div`
  display: ${props => (props.isShowPassword ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;

  font-size: 18px;
  color: #a1a1a1;

  cursor: pointer;
`

const PasswordIcon = <Icon type="caret-down" theme="outlined" />
export const ShowPasswordIcon = styled(PasswordIcon)`
  margin: 0 5px 2px 0;
`
