import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withState, withHandlers} from 'recompose'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {reduxForm, formValueSelector, Field} from 'redux-form'
import {navigate} from '@reach/router'
import ReactDOM from 'react-dom'

import {responsive} from '../../../core/style'
import Button from '../../share/Button'
import Loading from '../../share/Loading'
import ProductCard from './Card/index'
import ProductCompare from './ProductCompare'
import {
  setDataProduct,
  fetchProductList,
  savePlanning,
  getProductName,
} from '../../../ducks/product'
import {
  getCompaniesOption,
  getDiseasesOption,
  getVehicleYearOption,
  getVehicleBrandOption,
  getVehicleModelOption,
  getVehicleSubModelOption,
  getVehicleEngineSizeOption,
} from '../../../ducks/options'

import FilterSidebar from './FilterSidebar'

import {displayNumber, url2Obj, obj2Url} from '../../../core/helper'
import {
  gender as genderOptions,
  planing as planingOptions,
  vehicleBrand as vehicleBrandOptions,
  HSOptions,
  CIOptions,
} from '../options'

import Modal from '../../share/Modal'
import {Form as LifeForm} from '../../NoonSmart/Life/Starter/Form'
import {LifeIntermediate} from '../Life/Intermediate'
import {LifeAdvance} from '../Life/Advance'
import CarInfoForm from '../../NoonSmart/Motor/Starter/CarInfoForm'
import {ChoosingCoverageForm} from '../../NoonSmart/Motor/Starter/ChoosingCoverageForm'
import MotorIntermediateForm from '../../NoonSmart/Motor/Intermediate'
import {HealthIntermediate as HealthForm} from '../../NoonSmart/Health/Intermediate'

import TagContaniner from '../../NoonSmart/Product/Card/TagContaniner'

const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const HeaderTab = styled.div`
  display: flex;
  width: 100vw;
  height: 80px;
  align-items: center;
  justify-content: space-around;
  background-color: #093771;
  z-index: 7;
  padding: 0 35px;
`

const HeaderText = styled.div`
  display: flex;
  width: fit-content;
  font-size: 16px;
  color: white;
  flex-wrap: wrap;

  ${props =>
    responsive.mobile(css`
      &.CustomHealthWord {
        font-size: 12px;
      }
    `)};
`

const CustomButton = styled(Button)`
  background-color: white;
  width: 80px;
  justify-content: center;

  display: ${props => (props.isEdit ? 'none' : 'flex')};
`

const FilterIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  margin: 33px 11px 31px 0;
`

const SortButton = styled(Button)`
  width: fit-content;
  height: 25px;
  border-radius: 5px;
  background-color: ${props =>
    props.isActive ? props.theme.ORANGE : '#e5e5e5'};
  cursor: pointer;
  margin: 31px 5px;
  font-size: 12px;
`

const FilterAndSortPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    responsive.extra(css`
      cursor: pointer;
    `)};
`

const FilterAndSortText = styled.span`
  color: black;
  font-size: 12px;
  margin-right: 10px;
  @media (max-width: 320px) {
    display: none;
  }
  ${props =>
    responsive.mobile(css`
      &.sort {
        display: none;
      }
    `)};
`

const FilterAndSortContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  margin-top: 80px;

  ${props =>
    responsive.mobile(css`
      padding: 0 calC((100vw - 95%) / 2);
    `)};
`

const NoResultText = styled.span`
  width: 100%;
  text-align: center;
`

const ProductCardContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  padding: 0 32px;
  width: 100%;
  max-width: 1050px;
  margin-bottom: 100px;
  @media (max-width: 425px) {
    justify-content: center;
  }
  @media (max-width: 320px) {
    padding: 0px;
  }
`

const ProductContentContainer = styled.div`
  display: flex;
  width: 100%;
`
const LayoutProductCardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const getOptionLabel = (value, options) => {
  const isMatch = option => {
    return value === option.value
  }

  const match = options.find(isMatch)
  if (match) {
    return match.label
  } else return ''
}

const BoxList = [
  {
    iconTag: 'fas',
    iconName: 'shield-alt',
    infoTop: 'วางแผนปกป้อง',
    infoBottom: 'ภาระที่อาจจะตกถึงครอบครัว',
    hasTag: [{name: 'ตลอดชีพ'}, {name: 'ชั่วระยะเวลา'}, {name: 'ควบการลงทุน'}],
    type: 'DebtProtection',
    searchBy: 'sum_insured',
  },
  {
    iconTag: 'fas',
    iconName: 'coins',
    infoTop: 'วางแผนเพื่อสร้างความคุ้มครอง',
    infoBottom: 'ด้านรายได้ของครอบครัว',
    hasTag: [
      {name: 'ตลอดชีพ'},
      {name: 'สะสมทรัพย์'},
      {name: 'ชั่วระยะเวลา'},
      {name: 'ควบการลงทุน'},
    ],
    type: 'FamilyIncomeProtection',
    searchBy: 'sum_insured',
    left: '205px',
  },
  {
    iconTag: 'fas',
    iconName: 'hand-holding-heart',
    infoTop: 'วางแผนเงินเกษียณ',
    infoBottom: 'ที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า',
    hasTag: [{name: 'สะสมทรัพย์'}, {name: 'บำนาญ'}],
    type: 'Retirement',
    searchBy: 'annuity_refund',
    left: '410px',
  },
  {
    iconTag: 'fas',
    iconName: 'child',
    infoTop: 'วางแผนคุ้มครอง',
    infoBottom: 'ค่าใช้จ่ายการศึกษาบุตร',
    hasTag: [{name: 'สะสมทรัพย์'}, {name: 'ควบการลงทุน'}],
    type: 'Child',
    searchBy: 'sum_insured',
    top: 'calc(100% - 140px)',
  },
  {
    iconTag: 'fas',
    iconName: 'briefcase',
    infoTop: 'วางแผนลดหย่อน',
    infoBottom: 'ภาษีด้วยประกัน',
    hasTag: [
      {name: 'ตลอดชีพ'},
      {name: 'สะสมทรัพย์'},
      {name: 'บำนาญ'},
      {name: 'ควบการลงทุน'},
    ],
    type: 'TaxReduction',
    searchBy: 'premium',
    left: '205px',
    top: 'calc(100% - 140px)',
  },
  {
    iconTag: 'fas',
    iconName: 'piggy-bank',
    infoTop: 'วางแผนสะสมเงิน',
    infoBottom: 'พร้อมทุนประกัน',
    hasTag: [{name: 'สะสมทรัพย์'}, {name: 'ควบการลงทุน'}],
    type: 'Saving',
    searchBy: 'annuity_refund_total',
    left: '410px',
    top: 'calc(100% - 140px)',
  },
]

const CustomModal = styled(Modal)`
  &.ant-modal {
    width: unset !important;
    display: flex;
    justify-content: center;
    margin: 0 calc((100vw - 800px) / 2);
  }
  .ant-modal-content {
    width: 100%;
    padding: 50px 0 0 0;
  }

  ${props =>
    responsive.tablet(css`
      &.ant-modal {
        margin: 0 20px;
      }
    `)};

  ${props =>
    responsive.tablet(css`
      .ant-modal-content {
        width: 100%;
        padding: 50px 50px 0 50px;
      }
    `)};
`
const MobileSpan = styled.span`
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const MobileBr = styled.br`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const CustomSort = ({
  input,
  category,
  params,
  fetchProductList,
  setIsShowModal,
}) => (
  <React.Fragment>
    <SortButton
      onClick={() => {
        input.onChange('min_premium')
        fetchProductList(category, {...params, sortBy: 'min_premium'})
      }}
      isActive={input.value === 'min_premium'}>
      ราคาต่ำสุด
    </SortButton>
    {category === 'life' && (
      <SortButton
        onClick={() => {
          input.onChange('max_noon_score')
          fetchProductList(category, {...params, sortBy: 'max_noon_score'})
        }}
        isActive={input.value === 'max_noon_score'}>
        คะแนนสูงสุด
      </SortButton>
    )}
  </React.Fragment>
)

const Header = ({
  category,
  level,
  params,
  setIsShowModal,
  vehicleModelOptions,
  diseasesOptions,
}) =>
  document.getElementById('auto-header')
    ? ReactDOM.createPortal(
        <HeaderTab>
          {category === 'life' && level === 'starter' && (
            <HeaderText id="header-params">
              <MobileSpan>สำหรับ</MobileSpan> เพศ{' '}
              {getOptionLabel(params.gender, genderOptions)} อายุ {params.age}{' '}
              ปี&nbsp;
              {params.searchBy === 'premium' && (
                <MobileSpan>
                  โดยวางแผนว่าต้องการชำระเบี้ยประกันประมาณ
                </MobileSpan>
              )}
              {params.searchBy === 'annuity_refund_total' && (
                <MobileSpan>
                  โดยวางแผนว่าต้องการเงินคืนทั้งหมดจากกรมธรรม์
                </MobileSpan>
              )}
              {params.searchBy === 'annuity_refund' && (
                <MobileSpan>โดยวางแผนว่าต้องการเงินบำนาญต่อปี</MobileSpan>
              )}
              {params.searchBy === 'sum_insured' && (
                <MobileSpan>โดยวางแผนว่าต้องการทุนประกัน</MobileSpan>
              )}
              <MobileBr />
              &nbsp;
              {displayNumber(Math.round(params.searchValue))} บาท <br />
            </HeaderText>
          )}
          {category === 'life' && level === 'intermediate' && (
            <HeaderText id="header-params">
              <MobileSpan>สำหรับ</MobileSpan> เพศ{' '}
              {getOptionLabel(params.gender, genderOptions)} อายุ {params.age}{' '}
              ปี&nbsp;
              {params.searchBy === 'premium' && (
                <MobileSpan>
                  โดยวางแผนว่าต้องการชำระเบี้ยประกันประมาณ
                </MobileSpan>
              )}
              {params.searchBy === 'annuity_refund_total' && (
                <MobileSpan>
                  โดยวางแผนว่าต้องการเงินคืนทั้งหมดจากกรมธรรม์
                </MobileSpan>
              )}
              {params.searchBy === 'annuity_refund' && (
                <MobileSpan>โดยวางแผนว่าต้องการเงินบำนาญต่อปี</MobileSpan>
              )}
              {params.searchBy === 'sum_insured' && (
                <MobileSpan>โดยวางแผนว่าต้องการทุนประกัน</MobileSpan>
              )}
              <MobileBr />
              &nbsp;
              {displayNumber(Math.round(params.searchValue))} บาท <br />
            </HeaderText>
          )}

          {category === 'life' && level === 'advance' && (
            <HeaderText id="header-params">
              <MobileSpan>สำหรับ</MobileSpan> เพศ{' '}
              {getOptionLabel(params.gender, genderOptions)} อายุ {params.age}{' '}
              ปี&nbsp;
              {params.searchBy === 'sum_insured' && (
                <MobileSpan>โดยวางแผนว่าต้องการทุนประกัน</MobileSpan>
              )}
              <MobileBr />
              &nbsp;
              {displayNumber(Math.round(params.searchValue))} บาท <br />
            </HeaderText>
          )}
          {category === 'motor' && (
            <HeaderText id="header-params">
              <MobileSpan>สำหรับ</MobileSpan>รถยนต์{' '}
              {getOptionLabel(params.vehicleBrandId, vehicleBrandOptions)}
              &nbsp;
              <MobileSpan>
                รุ่น&nbsp;
                {getOptionLabel(params.vehicleModelId, vehicleModelOptions)}
              </MobileSpan>
              &nbsp; มูลค่าประมาณ{' '}
              {displayNumber(params.carValue || params.cover)} บาท
            </HeaderText>
          )}
          {category === 'health' && params.insuranceType === 'hs' && (
            <HeaderText id="header-params" className="CustomHealthWord">
              <MobileSpan>สำหรับ</MobileSpan>คุ้มครองค่ารักษาพยาบาล ประเภท{' '}
              {getOptionLabel(params.hsGoal, HSOptions)}
              <br />
              <MobileSpan>โดยวางแผนว่าต้องการ</MobileSpan>วงเงินอยู่ที่{' '}
              {displayNumber(params.searchValue)} บาท
            </HeaderText>
          )}
          {category === 'health' && params.insuranceType === 'ci' && (
            <HeaderText id="header-params" className="CustomHealthWord">
              <MobileSpan>สำหรับ</MobileSpan>คุ้มครองโรคร้ายแรง ระยะคุ้มครอง{' '}
              {getOptionLabel(params.ciRoles, CIOptions)} โรค{' '}
              {getOptionLabel(params.ciDiseases, diseasesOptions || [])}
              <br />
              <MobileSpan>โดยวางแผนว่าต้องการ</MobileSpan>วงเงินอยู่ที่{' '}
              {displayNumber(params.searchValue)} บาท
            </HeaderText>
          )}
          <CustomButton
            id={`noon-${category}-edit`}
            onClick={() => {
              setIsShowModal(true)
              if (category === 'motor') {
              }
            }}
            icons={['fa', 'wrench']}>
            แก้ไข
          </CustomButton>
        </HeaderTab>,
        document.getElementById('auto-header'),
      )
    : null

const Product = ({
  setFilterActive,
  isFilterActive,
  isShowModal,
  setIsShowModal,
  typeIndex,
  setTypeIndex,
  params,
  productList,
  change,
  handleSubmit,
  category,
  level,
  isLoading,
  location,
  isSelected,
  diseasesOptions,
  savePlanning,
  vehicleYearOption,
  vehicleBrandOptions,
  vehicleModelOptions,
  vehicleSubModelOptions,
  vehicleEngineSizeOptions,
  page,
  nextPage,
  optionsList,
  coverCarSelfRepair,
  specifyOtherDriver,
  setPage,
  reset,
  favoriteProduct,
  fetchProductList,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={handleSubmit(() => setIsShowModal(false) || setPage('1'))}>
      <Header
        category={category}
        level={level}
        params={params}
        setIsShowModal={setIsShowModal}
        vehicleModelOptions={vehicleModelOptions}
        diseasesOptions={diseasesOptions}
      />
      <FilterAndSortContainer>
        <FilterAndSortPart onClick={() => setFilterActive(true)}>
          <FilterIcon icon={['fas', 'filter']} />
          <FilterAndSortText>ปรับตัวเลือก</FilterAndSortText>
        </FilterAndSortPart>
        <FilterAndSortPart>
          <FilterAndSortText className="sort">เรียงตาม</FilterAndSortText>
          <Field
            name="sortBy"
            component={CustomSort}
            label="คะแนนสูงสุด"
            category={category}
            params={params}
            fetchProductList={fetchProductList}
          />
        </FilterAndSortPart>
      </FilterAndSortContainer>
      <ProductContentContainer>
        <FilterSidebar
          setFilterActive={setFilterActive}
          isFilterActive={isFilterActive}
          level={level}
          category={category}
          params={params}
        />
        <LayoutProductCardContainer>
          <ProductCardContainer>
            {productList.length !== 0 ? (
              !isLoading ? (
                productList.map(value => {
                  if (category === 'life') {
                    return (
                      <ProductCard
                        key={value.id + value.favorite}
                        data={{...value, level}}
                        params={params}
                        specialAction="compare"
                        tag={
                          value.target === 'holding' ? (
                            <TagContaniner
                              caption="แบบประกันที่คุณถืออยู่"
                              bgColor="#E5E5E5"
                            />
                          ) : value.target === 'interest' ? (
                            <TagContaniner
                              caption="แบบประกันที่คุณสนใจ"
                              bgColor="#9FD134"
                            />
                          ) : null
                        }
                      />
                    )
                  } else if (category === 'health') {
                    return (
                      <ProductCard
                        key={value.id + value.favorite}
                        data={{...value, level}}
                        params={params}
                        // specialAction="compare"
                      />
                    )
                  } else if (category === 'motor') {
                    return (
                      <ProductCard
                        key={value.promotionCode + value.favorite}
                        data={{...value, level}}
                        params={params}
                        specialAction="compare"
                      />
                    )
                  }
                })
              ) : (
                ''
              )
            ) : !isLoading ? (
              <NoResultText>ไม่พบแบบประกันที่คุณค้นหา</NoResultText>
            ) : (
              ''
            )}
          </ProductCardContainer>
        </LayoutProductCardContainer>
      </ProductContentContainer>
      <ProductCompare category={category} params={params} level={level} />
      <CustomModal
        onClose={() => {
          const valueParams = {
            ...params,
            searchValue:
              params.searchValue < 100000 ? 100000 : params.searchValue,
            loadControl:
              params.searchValue < 100000 ? 100000 : params.loadControl,
          }

          if (category === 'life' && level === 'starter') {
            navigate(
              `/smart/life/starter/product?${obj2Url({
                ...valueParams,
                sortBy: 'min_premium',
                noonStar: 3,
              })}`,
              {replace: true},
            )
          } else {
            fetchProductList(category, params)
          }
        }}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}>
        {category === 'life' && level === 'starter' && (
          <LifeForm
            change={change}
            type={params.type}
            BoxList={BoxList}
            hideAvatar={false}
            onBackClick={() => {
              const newTypeIndex =
                typeIndex !== 0 ? typeIndex - 1 : BoxList.length - 1
              setTypeIndex(newTypeIndex)
              change('type', BoxList[newTypeIndex].type)
              change('searchBy', BoxList[newTypeIndex].searchBy)
            }}
            onNextClick={() => {
              const newTypeIndex =
                typeIndex !== BoxList.length - 1 ? typeIndex + 1 : 0
              setTypeIndex(newTypeIndex)
              change('type', BoxList[newTypeIndex].type)
              change('searchBy', BoxList[newTypeIndex].searchBy)
            }}
            onSubmit={handleSubmit(value => {
              const valueForm = {
                ...value,
                searchValue:
                  value.searchValue < 100000 ? 100000 : value.searchValue,
                loadControl:
                  value.searchValue < 100000 ? 100000 : value.loadControl,
              }
              window.open(
                `/smart/life/starter/product?${obj2Url({
                  ...valueForm,
                  sortBy: 'min_premium',
                  noonStar: 3,
                })}`,
                '_self',
              )
            })}
          />
        )}
        {category === 'life' && level === 'intermediate' && (
          <LifeIntermediate
            location={location}
            isSelected={isSelected}
            formType={isSelected}
            change={change}
            onSubmit={handleSubmit(value => fetchProductList(category, value))}
            isLoading={isLoading}
            closeModal={() => {
              setIsShowModal(false)
            }}
            savePlanning={savePlanning}
          />
        )}
        {category === 'life' && level === 'advance' && (
          <LifeAdvance
            location={location}
            formType={isSelected}
            isSelected={isSelected}
            change={change}
            onSubmit={handleSubmit(value => fetchProductList(category, value))}
            isLoading={isLoading}
            closeModal={() => {
              setIsShowModal(false)
            }}
            savePlanning={savePlanning}
            reset={reset}
            target={params.target}
          />
        )}
        {category === 'motor' &&
          level === 'starter' &&
          (page === '1' ? (
            <CarInfoForm
              buttonText="เลือกความคุ้มครอง"
              vehicleYearOption={vehicleYearOption}
              getVehicleBrandOption={getVehicleBrandOption}
              vehicleBrandOptions={vehicleBrandOptions}
              getVehicleModelOption={getVehicleModelOption}
              vehicleModelOptions={vehicleModelOptions}
              getVehicleSubModelOption={getVehicleSubModelOption}
              vehicleSubModelOptions={vehicleSubModelOptions}
              getVehicleEngineSizeOption={getVehicleEngineSizeOption}
              vehicleEngineSizeOptions={vehicleEngineSizeOptions}
              optionsList={optionsList}
              nextPage={nextPage}
              change={change}
              location={location}
            />
          ) : (
            <ChoosingCoverageForm
              onSubmit={handleSubmit(value =>
                fetchProductList(category, value),
              )}
              coverCarSelfRepair={coverCarSelfRepair}
              isLoading={isLoading}
              savePlanning={savePlanning}
              buttonText="เลือกแบบประกัน"
              specifyOtherDriver={specifyOtherDriver}
              change={change}
            />
          ))}
        {category === 'motor' && level === 'intermediate' && (
          <MotorIntermediateForm
            buttonText="เลือกแบบประกัน"
            onSubmit={handleSubmit(value => fetchProductList(category, value))}
            isLoading={isLoading}
            savePlanning={savePlanning}
            location={location}
            change={change}
          />
        )}
        {category === 'health' && (
          <HealthForm
            onSubmit={handleSubmit(value => fetchProductList(category, value))}
            buttonText="เลือกแบบประกัน"
            type={params.insuranceType}
            diseasesOptions={diseasesOptions}
          />
        )}
      </CustomModal>
    </Container>
  </Loading>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  withState('isFilterActive', 'setFilterActive', false),
  withState('page', 'setPage', 1),
  withHandlers({
    nextPage: ({page, setPage}) => () => {
      setPage(page + 1)
    },
  }),
  connect(
    (state, props) => {
      const search = url2Obj(props.location.search)
      let modifiredCompany = []
      if (typeof search.companyIds === 'object') {
        modifiredCompany = search.companyIds
          ? search.companyIds.map(company =>
              company !== 'all'
                ? company === ''
                  ? ''
                  : parseInt(company)
                : 'all',
            )
          : []
      } else {
        const newCompanyIds = parseInt(search.companyIds)
        modifiredCompany.push(Number.isNaN(newCompanyIds) ? '' : newCompanyIds)
      }
      return {
        vehicleYearOption: state.options.vehicleYearOption,
        vehicleBrandOptions: state.options.vehicleBrandOptions,
        vehicleModelOptions: state.options.vehicleModelOptions,
        vehicleSubModelOptions: state.options.vehicleSubModelOptions,
        vehicleEngineSizeOptions: state.options.vehicleEngineSizeOptions,
        isLoading: state.product.isLoading,
        compareList: state.product.compareList,
        productList: state.product.productList,
        favoriteProduct: state.product.favoriteProduct,
        diseasesOptions: state.options.diseasesOptions,
        coverCarSelfRepair: selector(state, 'coverCarSelfRepair'),
        specifyOtherDriver: selector(state, 'specifyOtherDriver'),
        params: {
          ...search,
          companyIds: modifiredCompany || [],
          age: parseInt(search.age) || null,
          sumInsured: parseInt(search.sumInsured) || null,
          premium: parseInt(search.premium) || null,
          annuityRefund: parseInt(search.annuityRefund) || null,
          payPeriodMin: parseInt(search.payPeriodMin) || null,
          payPeriodMax: parseInt(search.payPeriodMax) || null,
          protectionPeriodMin: parseInt(search.protectionPeriodMin) || null,
          protectionPeriodMax: parseInt(search.protectionPeriodMax) || null,
          noonStar: parseInt(search.noonStar) || null,
        },

        initialValues: {
          custom: 0,
          noon: 0,
          ...search,
          companyIds: modifiredCompany,
          sortBy: search.sortBy || 'min_premium',
          // searchBy:
          //   search.insuranceType === 'whole_life' ||
          //   search.insuranceType === 'term'
          //     ? 'sum_insured'
          //     : search.insuranceType === 'annuity'
          //     ? 'annuity_refund'
          //     : search.insuranceType === 'saving'
          //     ? 'annuity_refund'
          //     : 'sum_insured',
          // gender: search.gender || 'male',
          // age: search.age || 25,
          weightType: search.weightType || 1,
          // searchValue: search.searchValue || 0,
          // insuranceType: search.insuranceType || 'whole_life',
        },
        optionsList: selector(
          state,
          'vehicleYear',
          'vehicleBrandId',
          'vehicleModelId',
          'vehicleSubModel',
        ),
        isSelected: selector(state, 'insuranceType'),
      }
    },
    {
      setDataProduct,
      fetchProductList,
      getCompaniesOption,
      getDiseasesOption,
      savePlanning,
      getVehicleYearOption,
      getVehicleBrandOption,
      getVehicleModelOption,
      getVehicleSubModelOption,
      getVehicleEngineSizeOption,
      getProductName,
    },
  ),
  withState('typeIndex', 'setTypeIndex', props =>
    BoxList.findIndex(value => value.type === props.params.type),
  ),

  reduxForm({
    form: 'Planning',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onChange: (values, _, props) => {
      let newValues = {...values}
      if (values.others) {
        if (values.others.includes('has_dividend'))
          newValues = {...newValues, has_dividend: true}
        else if (!values.others.includes('has_dividend')) {
          newValues = {...newValues, has_dividend: false}
        }
        if (values.others.includes('is_tax_saving'))
          newValues = {...newValues, is_tax_saving: true}
        else if (!values.others.includes('is_tax_saving')) {
          newValues = {...newValues, is_tax_saving: false}
        }

        // if (values.others.includes('has_health'))
        //   newValues = {...newValues, has_health: true}
        // else if (!values.others.includes('has_health')) {
        //   newValues = {...newValues, has_health: false}
        // }

        // if (values.others.includes('has_agent_promotion'))
        //   newValues = {...newValues, has_agent_promotion: true}
        // else if (!values.others.includes('has_agent_promotion')) {
        //   newValues = {...newValues, has_agent_promotion: false}
        // }
      }

      if (values.noonStar === true) {
        newValues = {...values, noonStar: 0}
      } else if (values.noonStar === false) {
        newValues = {...values, noonStar: 0}
      }
      switch (values.payPeriod) {
        case '1':
          if (values.payPeriodMin) {
            delete values.payPeriodMin
          }
          newValues = {...values, payPeriodMax: 1}
          break
        case '5':
          if (values.payPeriodMin) {
            delete values.payPeriodMin
          }
          newValues = {...values, payPeriodMax: 5}
          break
        case '6-10':
          newValues = {...values, payPeriodMin: 6, payPeriodMax: 10}
          break
        case '11-15':
          newValues = {...values, payPeriodMin: 11, payPeriodMax: 15}
          break
        case '16-20':
          newValues = {...values, payPeriodMin: 16, payPeriodMax: 20}
          break
        case '20':
          if (values.payPeriodMax) {
            delete values.payPeriodMax
          }
          newValues = {...values, payPeriodMin: 20}
          break
        case '':
          if (values.payPeriodMax) {
            delete values.payPeriodMax
          }
          if (values.payPeriodMin) {
            delete values.payPeriodMin
          }
          newValues = {...values}
          break
        default:
          break
      }

      const {fetchProductList, category} = props
      setTimeout(
        navigate(
          `/smart/${props.category}/${props.level}/product?${obj2Url(
            newValues,
          )}`,
          {replace: true},
        ),
      )
      // fetchProductList(category, newValues)
    },
  }),

  lifecycle({
    componentDidMount() {
      const $autoHeader = document.getElementById('auto-header')
      if ($autoHeader) {
        $autoHeader.style.display = 'flex'
        $autoHeader.style.marginTop = '80px'
      }

      const {
        setDataProduct,
        fetchProductList,
        category,
        level,
        location: {search},
        getCompaniesOption,
        getDiseasesOption,
        setPage,
        getVehicleYearOption,
        getVehicleBrandOption,
        getVehicleModelOption,
        getProductName,
        params,
      } = this.props
      getCompaniesOption(category)
      if (category === 'health') {
        getDiseasesOption()
      } else if (category === 'life' && level === 'advance') {
        getProductName((url2Obj(search) || {}).lifeInsurancePolicyId)
      }
      fetchProductList(category, url2Obj(search))

      setDataProduct({noOpacityHeader: true})
      setPage('1')

      if (category === 'motor') {
        getVehicleYearOption()
        getVehicleBrandOption(params.vehicleYear)
        getVehicleModelOption(params.vehicleYear, params.vehicleBrandId)
      }
    },
    componentWillUnmount() {
      const $autoHeader = document.getElementById('auto-header')
      if ($autoHeader) {
        $autoHeader.style.display = 'none'
      }

      const {setDataProduct} = this.props
      setDataProduct({noOpacityHeader: false})
    },
  }),
)

export default enhancer(Product)
