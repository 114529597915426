import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled from 'react-emotion'
import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {
  displayNumber,
  numberParser,
  inRange,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import Panel from '../PanelForm'
import {breakpoints} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'

import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
  Summary,
  Label,
  LabelSummary,
} from '../ShareFieldForm'

const CustomTextFooter = styled(TextFooter)`
  width: 655px;
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`

const CustomText = styled.b`
  font-size: 20px;
`

const SummaryAdvance = ({total}) => (
  <CustomText>{displayNumber(total)} บาท</CustomText>
)

const Nothing = () => <div />

const FamilyIncomeProtectionForm = ({
  onSummary,
  beforeSummary,
  afterSummary,
  total,
  windowWidth,
}) => (
  <Panel>
    <div>
      <Header>ด้านรายได้</Header>
      <FieldContainer>
        <TextInput
          name="inflationRate"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ณ อัตราเงินเฟ้อ (ต่อปี)'
              : 'ณ อัตราเงินเฟ้อ'
          }`}
          type="percent"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>ต่อปี</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="familyCost"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ค่าใช้จ่ายในการเลี้ยงดูครอบครัว (บาท/เดือน)'
              : 'ค่าใช้จ่ายในการเลี้ยงดูครอบครัว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>บาท/เดือน</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="careYears"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จำนวนปีที่อยากจะดูแลค่าใช้จ่ายหากเสียชีวิต (ปี)'
              : 'จำนวนปีที่อยากจะดูแลค่าใช้จ่ายหากเสียชีวิต'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>ปี</Unit>
      </FieldContainer>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินที่ต้องเตรียมก่อนปรับเงินเฟ้อ (บาท)'
              : 'จำนวนเงินที่ต้องเตรียมก่อนปรับเงินเฟ้อ'}
          </Label>
          <LabelSummary>
            {displayNumber(Math.round(beforeSummary))}
          </LabelSummary>
        </Summary>
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินที่ต้องเตรียมหลังปรับเงินเฟ้อ (บาท)'
              : 'จำนวนเงินที่ต้องเตรียมหลังปรับเงินเฟ้อ'}
          </Label>
          <LabelSummary>{displayNumber(Math.round(afterSummary))}</LabelSummary>
        </Summary>
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="sumInsurance"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ทุนประกันชีวิตที่มีอยู่แล้ว (ปี)'
              : 'ทุนประกันชีวิตที่มีอยู่แล้ว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <CustomTextFooter>
        <SearchFactor
          type="FamilyIncomeProtection"
          windowWidth={windowWidth}
          searchValue={total}
        />
        <CustomField
          name="searchValue"
          component={SummaryAdvance}
          total={total}
        />
        <CustomField name="moneyBeforeInflationMerged" component={Nothing} />
        <CustomField name="moneyAfterInflationMerged" component={Nothing} />
      </CustomTextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={total < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(
        state,
        'inflationRate',
        'familyCost',
        'careYears',
        'sumInsurance',
      ),
    }),
    {change},
  ),
  withState('beforeSummary', 'setBeforeSummary', 0),
  withState('afterSummary', 'setAfterSummary', 0),
  withState('total', 'setTotal', 0),
  withHandlers({
    onSummary: ({
      totalList,
      setBeforeSummary,
      setAfterSummary,
      setTotal,
      change,
    }) => () => {
      const {
        inflationRate = '0',
        familyCost = '0',
        careYears = '0',
        sumInsurance = '0',
      } = totalList

      let first = convertNaNtoZero(inflationRate)
      let second = convertNaNtoZero(familyCost)
      let third = convertNaNtoZero(careYears)
      let forth = convertNaNtoZero(sumInsurance)

      let summary = second * 12 * third
      setBeforeSummary(summary)
      let i = 0
      let buff = 1 + first / 100 / 12
      let summary2 = 0
      while (i < third * 12) {
        summary2 += second * Math.pow(buff, i)
        i++
      }
      setAfterSummary(summary2.toFixed(0))
      setTotal((summary2 - forth).toFixed(0))
      change('Planning', 'moneyBeforeInflationMerged', summary)
      change(
        'Planning',
        'moneyAfterInflationMerged',
        convertNaNtoZero(summary2.toFixed(0)),
      )
      change(
        'Planning',
        'searchValue',
        convertNaNtoZero((summary2 - forth).toFixed(0)),
      )
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onSummary()
    },
  }),
)

export default enhancer(FamilyIncomeProtectionForm)
