import React from 'react'
import {connect} from 'react-redux'
import {compose, withProps, withHandlers, withState} from 'recompose'
import Modal from '../../../share/Modal'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import shield from '../../../../static/images/security-on.svg'
import {Button, Collapse} from '../../components'
import {navigate} from '@reach/router'
import {withAnswers} from '../../enhances'
import avatarDefault from '../../../../static/images/avatar-13.svg'
import TextInput from '../../components/TextInput'
import Checkbox from '../../components/Checkbox'
import {Field, reduxForm} from 'redux-form'
import {validator, phoneFormatter} from '../../../../core/helper'

const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
`
const Content = styled.div`
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: #333333;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Label = styled.div`
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
`
const SmallText = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  :nth-child(odd) {
    font-weight: 600;
  }
`
const Value = styled.div`
  font-family: 'Prompt', 'Roboto', sans-serif;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
`
const Img = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin-top: 20px;
`
const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 3px;
  margin-right: 5px;
`
const LabelTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #265fa7;
`
const Line = styled.div`
  border-top: 1px solid #e5e5e5;
  margin: 20px 0;
  width: 100%;
`
const NextButton = styled(Button)`
  min-width: 250px;
  max-width: 250px;
  min-height: 38px;
  margin: 20px;
  margin-top: 7px;
`
const TitleLead = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #265fa7;
  font-family: 'Prompt', 'Roboto', sans-serif;
  :last-child {
    margin-bottom: 30px;
  }
`
const WrapLead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`
const CheckIcon = styled(Icon)`
  font-size: 40px;
  color: #9fd134;
  margin: 31px 0 20px 0;
`
const WrapContact = styled.div`
  border: 1px solid #6a94cb;
  width: 100%;
  min-width: 230px;
  max-width: 230px;
  min-height: 144px;
  max-height: 144px;
  border-radius: 5px;
  padding: 9px 12px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 30px;
  color: #6a94cb;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div:first-child {
    font-size: 16px;
  }
`
const GoHome = styled(NextButton)`
  background: white;
  border: 1px solid #ffd482;
  margin-bottom: 20px;
  margin: 20px;
  margin-top: 0;
`
const Email = styled.a`
  color: #6a94cb;
  text-decoration: underline;
  :hover {
    color: #6a94cb;
  }
`

const LeadSuccessModal = props => {
  const {customerInfo, productLifeDetail, agent} = props
  if (!productLifeDetail) return null
  return (
    <CustomModal
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}>
      <Content>
        <WrapLead>
          <CheckIcon icon={['fas', 'check']} />
          <TitleLead>ส่งแบบประกันที่คุณสนใจ</TitleLead>
          <TitleLead>ให้กับตัวแทนแล้ว</TitleLead>
          <SmallText style={{marginTop: '25px'}}>รายละเอียดส่งไปที่</SmallText>
          <SmallText style={{fontSize: '16px'}}>{customerInfo.email}</SmallText>
          <SmallText style={{marginBottom: '25px'}}>เรียบร้อยแล้ว</SmallText>

          <WrapContact>
            <div>โปรดรอตัวแทนติดต่อกลับ ภายใน 7 วันทำการ</div>
            <div>
              หากไม่มีตัวแทนติดต่อกลับหรือมีข้อสงสัย <br />
              ติดต่อเราได้ที่ <br />
              063-065-6919 |{' '}
              <Email href="mailto:customers@tqld.co.th">
                customers@tqld.co.th
              </Email>
            </div>
          </WrapContact>
          <LabelTitle>ข้อมูลของคุณ</LabelTitle>
          <div>
            <Label>ชื่อ-สกุล</Label>
            <Value>
              คุณ {customerInfo.firstName} {customerInfo.lastName}
            </Value>
            <Label>เบอร์ติดต่อ</Label>
            <Value>{phoneFormatter(customerInfo.phoneNumber)}</Value>
            <Label>ช่วงเวลาที่สะดวกให้ติดต่อกลับ</Label>
            <Value>{customerInfo.time} น.</Value>
          </div>
        </WrapLead>
        <Line />
        <WrapLead>
          <LabelTitle>ตัวแทนประกัน</LabelTitle>
          <LabelTitle>ที่จะทำการติดต่อกลับ</LabelTitle>
          <Img src={agent.avatar || avatarDefault} />
          <div>
            <Label>ชื่อ-สกุล</Label>
            <Value>คุณ {agent.name}</Value>
            <Label>เบอร์ติดต่อ</Label>
            <Value>{phoneFormatter(agent.tel)}</Value>
            <Label>รหัสตัวแทนประกัน</Label>
            <Value>{agent.id}</Value>
            <Label>เลขที่ใบอนุญาติ</Label>
            <Value>{agent.licenseNo}</Value>
          </div>
        </WrapLead>
        {props.isLogin && (
          <NextButton
            onClick={() => {
              // props.setOpenSuccessModal(false)
              navigate('/profile/order')
            }}>
            ประวัติการสั่งซื้อ
          </NextButton>
        )}
        <GoHome onClick={() => navigate('/')}>ไปหน้าแรก</GoHome>
      </Content>
    </CustomModal>
  )
}

const enhancer = compose(
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
  }),
  connect((state, props) => ({
    isLogin: state.auth.isLogin,
    saleRep: state.product.saleRep,
    productLifeDetail: state.product.productLifeDetail,
  })),
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
  }),
)

export default enhancer(LeadSuccessModal)
