import React from 'react'
import styled, {css} from 'react-emotion'
import {TextInput} from '../../components/index'
import {compose} from 'recompose'
import {withSpidus} from '../../enhances'

const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const SearchInput = styled(TextInput)`
  width: 100%;
  text-align: left;
  border-radius: 0px;
`
const customStyle = css`
  text-align: left;
  border: none;
`
const customIconStyle = css`
  border-top: none;
  border-right: none;
  border-bottom: none;
`

const SearchBox = ({value, ...rest}) => (
  <Layout>
    <SearchInput
      icon="search"
      value={value}
      customStyle={customStyle}
      customIconStyle={customIconStyle}
      {...rest}
    />
  </Layout>
)

const enhance = compose(withSpidus())

export default enhance(SearchBox)
