import React from 'react'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import GoogleLogin from 'react-google-login'
import {connect} from 'react-redux'

import Button from '../share/Button'
import {responsive} from '../../core/style'

import {googleLogin} from '../../ducks/auth'

const GoogleButton = styled(Button)`
  min-width: 200px;
  height: 30px;
  border-radius: 20px;
  background-color: #e53935;
  color: white;
  margin: 0 0 20px;
  font-size: 12px;
  :hover {
    background-color: rgba(229, 57, 53, 0.5);
    color: white;
  }
  :focus {
    background-color: rgba(229, 57, 53, 0.5);
    color: white;
  }

  ${props =>
    responsive.tablet(css`
      min-width: 280px;
      height: 40px;

      font-size: 16px;
    `)}
`

const FacebookIcon = styled(FontAwesomeIcon)`
  font-size: 13px;
  color: white;
  margin-right: 5px;
`

const GoogleAuth = ({id, name, googleLogin = () => {}, modal, closeModal}) => (
  <GoogleLogin
    clientId={process.env.GOOGLE_CLIENT_ID}
    render={renderProps => (
      <GoogleButton
        id={id}
        onClick={renderProps.onClick}
        disabled={renderProps.disabled}>
        <FacebookIcon icon={['fab', 'google']} /> {name}
      </GoogleButton>
    )}
    buttonText="Login"
    onSuccess={res => googleLogin(res)}
    onFailure={err => console.error('onFailure', err)}
    cookiePolicy={'single_host_origin'}
  />
)

export default connect(
  state => ({}),
  {googleLogin},
)(GoogleAuth)
