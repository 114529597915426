import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Button from '../share/Button'
import {responsive} from '../../core/style'

const CustomLoginFacebook = styled(Button)`
  min-width: 200px;
  height: 30px;
  border-radius: 20px;
  background-color: #3c5a99;
  color: white;
  margin: 20px 0;
  font-size: 12px;
  :hover {
    background-color: rgba(60, 90, 153, 0.5);
    color: white;
  }
  :focus {
    background-color: rgba(60, 90, 153, 0.5);
    color: white;
  }

  ${props =>
    responsive.tablet(css`
      min-width: 280px;
      height: 40px;
      font-size: 16px;
    `)}
`

const FacebookIcon = styled(FontAwesomeIcon)`
  font-size: 13px;
  color: white;
  margin-right: 5px;
`

export const FacebookLoginButton = ({
  id,
  name,
  facebookLogin,
  modal,
  closeModal,
}) => (
  <FacebookLogin
    appId={process.env.FACEBOOK_APP_ID}
    isMobile={false}
    autoLoad={false}
    fields="name,email,picture,token_for_business"
    onClick={null}
    callback={response => {
      facebookLogin(response, modal, closeModal)
    }}
    render={renderProps => (
      <CustomLoginFacebook id={id} onClick={renderProps.onClick}>
        <FacebookIcon icon={['fab', 'facebook']} /> {name}
      </CustomLoginFacebook>
    )}
  />
)
