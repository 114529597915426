import Box from '../../static/images/ideabox_box.svg'
import Balloon from '../../static/images/people_action_5landing.svg'
import phone from '../../static/images/UBI-Vector1.svg'
import discount from '../../static/images/UBI-Vector2.svg'
import car from '../../static/images/UBI-Vector4.svg'

export const HeaderList = [
  {
    id: 0,
    name: 'user',
    text: 'ผู้ใช้ได้อะไร',
  },
  {
    id: 1,
    name: 'insurer',
    text: 'บริษัทประกันได้อะไร',
  },
]

export const userDetailsList = [
  {
    id: 0,
    iconType: 'far',
    icon: 'thumbs-up',
    text: 'เพิ่มความสามารถในการจ่ายเงิน สำหรับคนขับที่มีความเสี่ยงต่ำ',
  },
  {
    id: 1,
    iconType: 'fas',
    icon: 'user-cog',
    text: 'ให้ความสามารถในการควบคุม พรีเมี่ยมของพวกเขา',
  },
  {
    id: 2,
    iconType: 'fas',
    icon: 'car',
    moreIcon: 'angle-double-down',
    text: 'ลดไมล์ที่ขับเคลื่อนด้วยและใช้นิสัยการขับขี่ที่ปลอดภัยยิ่งขึ้น',
  },
]

export const insurerDetailsList = [
  {
    id: 0,
    iconType: 'fas',
    icon: 'car-crash',
    text: 'ช่วยให้บริษัทประกันสามารถประเมินความเสียหาย ได้มากขึ้น',
  },
  {
    id: 1,
    iconType: 'fas',
    icon: 'eye',
    text:
      'ลดการฉ้อโกงด้วยการทำให้ บริษัทประกันภัยสามารถวิเคราะห์ข้อมูลการขับขี่ได้',
  },
  {
    id: 2,
    iconType: 'fas',
    icon: 'calculator',
    text: 'การลดต้นทุนการเคลม',
  },
  {
    id: 3,
    iconType: 'fas',
    icon: 'list-ol',
    text: 'การจัดการความจำเป็น ในการให้ความคุ้มครอง ต่อเนื่อง',
  },
  {
    id: 4,
    iconType: 'fas',
    icon: 'chart-area',
    text: 'การสร้างแถลงการณ์ ล่วงหน้าของค่าเบี้ย ประกันภัย',
  },
]

export const contentDetails = [
  {
    id: 0,
    img: phone,
    head: 'UBI คืออะไร',
    text: `Usage Based Insurance (UBI) การคิดค่าเบี้ยประกันตามการใช้งานจริง ตามไลฟ์สไตล์การใช้ชีวิตของคุณ ไม่ว่าจะเป็นการขับรถ การออกกำลังกาย การท่องเที่ยว เป็นต้น พฤติกรรมต่างๆเหล่านี้จะช่วยบอกค่าความเสี่ยงของคุณได้มากกว่าเพียงแค่ข้อมูลส่วนตัว`,
  },
  {
    id: 1,
    img: car,
    head: 'Pay How You Drive',
    text: `ระยะแรกเราได้นำ UBI มาประยุกต์ใช้กับประกันรถยนต์ โดยไลฟ์สไตล์การขับขี่ของคุณจะถูกนำมาเป็นส่วนหนึ่งในการคำนวณค่าเบี้ยประกัน ซึ่งหากคุณเป็นผู้ที่มีไลฟ์สไตล์การขับรถที่ปลอดภัย คุณก็จะมีโอกาสได้โบนัสเป็นส่วนลดค่าเบี้ยประกันในปีถัดไปนั่นเอง`,
  },
  {
    id: 2,
    img: discount,
    head: 'ทำอย่างไรจึงจะได้รับส่วนลด',
    text: `คุณสามารถได้รับส่วนลดง่ายๆ เพียงแค่ติดตั้ง noon Application ไว้บนโทรศัพท์มือถือที่เป็นสมาร์ทโฟนของคุณ และทำการลงทะเบียน และทำตามขั้นตอนการใช้งาน เพียงเท่านี้ คุณก็มีสิทธิ์ที่จะได้รับส่วนลดโบนัสค่าเบี้ยประกันในปีถัดไป`,
  },
  {
    id: 3,
    img: Balloon,
    head: 'Noon Application มีอะไร',
    text: `ภารกิจสุดท้าทายที่ทั้งผู้ขับและผู้ที่ไม่ได้ขับรถ จะสามารถร่วมสนุกเพื่อแลกของรางวัลสุดพรีเมี่ยมได้ง่ายๆ แค่เพียงกดเข้าร่วม และทำภารกิจ แค่นี้ก็ได้รับคะแนนเพื่อเอาไปแลกรางวัลมากมายที่เราจัดเตรียมไว้ให้คุณแล้ว`,
  },
  {
    id: 4,
    img: Box,
    head: 'Insurance Regulatory Sandbox',
    text:
      'สำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย หรือ คปภ. ได้ดำเนินการจัดทำโครงการ Insurance Regulatory Sandbox ขึ้นเพื่อให้ภาคธุรกิจประกันภัยสามารถทดสอบนวัตกรรม โดยอยู่ภายใต้กรอบดูแลของหน่วยงาน ซึ่งช่วยสร้างความมั่นใจให้กับผู้ใช้งานในการใช้ผลิตภัณฑ์ที่อยู่ภายใต้โครงการดังกล่าว',
  },
]
