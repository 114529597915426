import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {compose, lifecycle} from 'recompose'
import {connect} from 'react-redux'
import Button from '../../share/Button'
import Field from '../../share/Field'
import Loading from '../../share/Loading'
import {
  isEmail,
  isPhoneNumber,
  phoneFormatter,
  formatParser,
} from '../../../core/helper'
import {saleContactUs} from '../../../ducks/contactUs'
import {getCompaniesOption} from '../../../ducks/options'
import {responsive} from '../../../core/style'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;

  ${props =>
    responsive.mobile(css`
      max-width: 150px;
    `)};
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  font-size: 12px;
  margin-bottom: 30px;
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 20px;
      }
    }
  }
`

const Selector = styled(Field)`
  margin: 0;
  width: 250px;
  margin-bottom: 30px;

  .input-googleSelector-container {
    margin: 0;

    .Select {
      border-radius: 5px;
      text-align: center;
      font-size: 16px;

      > .Select-menu-outer {
        width: 250px;
        margin-top: 11px;
        left: -1px;
        .Select-menu {
          border-radius: 0 0 5px 5px;
          background-color: white;
          border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
          border-top: 0;
          padding: 0;

          .Select-option {
            color: rgba(51, 51, 51, 0.5);
            font-size: 16px;
            padding: 8px 0 8px 15px;
            width: 250px;
            height: 40px;
            :hover {
              background-color: rgba(255, 212, 130, 0.5);
              color: black;
            }
          }
        }
      }
    }
  }
`

const validate = values => {
  const errors = {}

  if (!values.companyName) {
    errors.companyName = 'ต้องเลือกข้อมูลในช่องนี้'
  }

  if (!values.licenseNo) {
    errors.licenseNo = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.firstName) {
    errors.firstName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastName) {
    errors.lastName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  // if (!values.password) {
  //   errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  // } else if (!isPasswordNoon(values.password)) {
  //   errors.password = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  // }
  // if (!values.confirmpassword) {
  //   errors.confirmpassword = 'ต้องใส่ข้อมูลในช่องนี้'
  // } else if (values.confirmpassword !== values.password) {
  //   errors.confirmpassword = 'รหัสผ่านไม่ตรงกัน'
  // } else if (!isPasswordNoon(values.confirmpassword)) {
  //   errors.confirmpassword = 'ต้องมีความยาวไม่ต่ำกว่า 6 ตัวอักษร'
  // }

  if (!values.tel) {
    errors.tel = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.tel)) {
    errors.tel = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  return errors
}

const ContactUsSaleRep = ({
  handleSubmit,
  reset,
  saleContactUs,
  companiesOption,
  isLoading,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={handleSubmit(value => saleContactUs(value, reset))}
      noValidate>
      <Header>สมัครเป็นนายหน้าหรือตัวแทนกับเรา</Header>
      <FieldContainer>
        <Selector
          name="insuranceCompanyId"
          type="googleSelector"
          placeholder="บริษัทประกัน"
          options={companiesOption}
          optionsType="object"
          hideError
        />
        <GoogleInput
          name="licenseNo"
          type="googleInput"
          placeholder="เลขที่ใบอนุญาต"
          hideError
        />
        <GoogleInput
          name="firstName"
          type="googleInput"
          placeholder="ชื่อ"
          hideError
        />
        <GoogleInput
          name="lastName"
          type="googleInput"
          placeholder="นามสกุล"
          hideError
        />
        <GoogleInput
          name="email"
          type="googleInput"
          placeholder="อีเมล"
          hideError
        />
        <GoogleInput
          name="tel"
          type="googleInput"
          placeholder="เบอร์ติดต่อ"
          format={phoneFormatter}
          parse={formatParser}
          hideError
        />
        {/* <GoogleInput
        name="password"
        type="googleInput"
        placeholder="รหัสผ่าน"
        mode="password"
        hideError
      />
      <GoogleInput
        name="confirmpassword"
        type="googleInput"
        placeholder="ยืนยันรหัสผ่าน"
        mode="password"
        hideError
      /> */}

        <CustomButton htmlType="submit" icons={['fa', 'pen']}>
          ลงทะเบียน
        </CustomButton>
      </FieldContainer>
    </Container>
  </Loading>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.contactUs.isLoading,
      companiesOption: state.options.companiesOption,
    }),
    {saleContactUs, getCompaniesOption},
  ),
  reduxForm({
    form: 'ContactUsSaleRep',
    validate,
  }),
  lifecycle({
    componentDidMount() {
      const {getCompaniesOption} = this.props
      getCompaniesOption()
    },
  }),
)

export default enhancer(ContactUsSaleRep)
