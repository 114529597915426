import React from 'react'
import moment from 'moment'
import styled, {css} from 'react-emotion'
import {compose, withState, withHandlers, lifecycle} from 'recompose'

import Modal from '../share/Modal'
import CoronaBanner from '../../static/images/corona-landing.png'
import CoronaBannerMobile from '../../static/images/corona-landing-mobile.png'

import {responsive} from '../../core/style'

const CustomModal = styled(Modal)`
  width: 100% !important;
  max-width: 1366px;
  padding: 0 20px;

  ${props =>
    responsive.mobile(css`
      padding: 0;
    `)};
`

const Banner = styled.img`
  width: 100%;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const BannerMobile = styled.img`
  display: none;
  width: 100%;

  ${props =>
    responsive.mobile(css`
      display: block;
    `)};
`

const CoronaAd = ({isShow, onClose}) => (
  <CustomModal
    isShowModal={isShow}
    onCancel={onClose}
    setIsShowModal={() => {}}>
    <a href="http://bit.ly/2H40Fwd" onClick={onClose}>
      <Banner src={CoronaBanner} />
      <BannerMobile src={CoronaBannerMobile} />
    </a>
  </CustomModal>
)

const enhancer = compose(
  withState('isShow', 'setShow', false),
  withHandlers({
    onClose: ({setShow}) => () => {
      localStorage.setItem('show-corona-ad', new Date())
      setShow(false)
    },
    checkAdHandler: ({setShow}) => () => {
      const currentShowAdDate = localStorage.getItem('show-corona-ad')
      if (currentShowAdDate) {
        const diff = moment(new Date()).diff(
          moment(new Date(currentShowAdDate)),
          'days',
        )
        if (diff > 0) {
          setShow(true)
        }
      } else {
        setShow(true)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.checkAdHandler()
    },
  }),
)

export default enhancer(CoronaAd)
