import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'
import {responsive} from '../../core/style'

import {contentDetails} from './ContentDetails'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentDetailsPart = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(even) div {
    flex-direction: row-reverse;

    ${props =>
      responsive.mobile(css`
        flex-direction: column;
      `)};
  }
`

const ContentContainer = styled.div`
  margin-bottom: 20px;
`

const ContentDetailsHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;

  ${props =>
    responsive.mobile(css`
      text-align: center;
    `)};
`

const ContentDetailsContainer = styled.div`
  display: flex;
  width: 730px;
  align-items: center;
  justify-content: space-between;

  ${props =>
    responsive.mobile(css`
      justify-content: center;
      flex-direction: column;
      margin-bottom: 60px;
    `)};
`

const ContentDetailsImage = styled.img`
  width: 200px;
  height: 205px;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
      margin-bottom: 30px;
    `)};
`

const ContentDetailsText = styled.div`
  width: 323px;
  font-size: 16px;
  white-space: pre-wrap;

  ${props =>
    responsive.mobile(css`
      width: 280px;
    `)};
`

const Content = ({setHeaderActive, isHeaderActive}) => (
  <Container>
    <ContentDetailsPart>
      {contentDetails.map(value => (
        <ContentContainer key={value.id}>
          <ContentDetailsHeader>{value.head}</ContentDetailsHeader>
          <ContentDetailsContainer>
            <ContentDetailsImage src={value.img} />
            <ContentDetailsText>{value.text}</ContentDetailsText>
          </ContentDetailsContainer>
        </ContentContainer>
      ))}
    </ContentDetailsPart>
  </Container>
)

const enhancer = compose(
  withState('isHeaderActive', 'setHeaderActive', 0),
  connect(
    (state, props) => ({}),
    {},
  ),
)
export default enhancer(Content)
