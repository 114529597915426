import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'

import Button from '../share/Button'
import Field from '../share/Field'

import {isEmail} from '../../core/helper'
import {responsive} from '../../core/style'
import {forgetPassword, setData} from '../../ducks/auth'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  padding: 0 0 30px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.BLUE};
`

const Content = styled.div`
  margin-top: 20px;

  font-size: 12px;
  line-height: 18px;
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 30px 0 0;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }

  ${props =>
    responsive.mobile(css`
      .input-googleInput-container {
        input {
          width: 280px;
        }
      }
    `)}
`

const CustomButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  font-size: 12px;
  margin-top: 20px;

  ${props =>
    responsive.mobile(css`
      min-width: 280px;
      height: 40px;
      font-size: 16px;
    `)}
`

const ForgetPassword = ({onSubmit, errorMessage}) => (
  <Container onSubmit={onSubmit} noValidate>
    <Header>ลืมรหัสผ่าน</Header>
    <Content>กรุณาระบุอีเมลที่เคยลงทะเบียนกับเรา</Content>
    <GoogleInput
      name="email"
      type="googleInput"
      placeholder="อีเมล"
      hideError
    />
    <CustomButton
      htmlType="submit"
      errorMessage={errorMessage}
      icons={['fa', 'check-circle']}>
      ยืนยัน
    </CustomButton>
  </Container>
)

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  return errors
}

const enhancer = compose(
  connect(
    (state, props) => ({
      errorMessage: state.auth.errorMessage,
    }),
    {forgetPassword, setData},
  ),
  reduxForm({
    form: 'forgetPassword',
    validate,
    onChange: (values, _, props) => {
      props.errorMessage !== '' && props.setData({errorMessage: ''})
    },
  }),
  withHandlers({
    onSubmit: ({modal, handleSubmit, forgetPassword, onSetForgetPassword}) =>
      handleSubmit(values => {
        forgetPassword(values, modal)
        modal && onSetForgetPassword()
      }),
  }),
)

export default enhancer(ForgetPassword)
