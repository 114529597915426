import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import * as path from './common/path'
import {Field, WizardState, Button, Switch, Currency} from './components/index'
import {withForm} from './enhancers'

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  max-width: 510px;
  @media (max-width: 425px) {
    max-width: 278px;
  }
`
export const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  white-space: nowrap;
  @media (max-width: 425px) {
    font-size: 20px;
    white-space: normal;
    text-align: center;
    min-width: 300px;
  }
`
export const Content = styled.div`
  min-height: 200px;
  width: 100%;
  /* min-width: 510px; */

  max-width: 470px;
  min-width: 470px;

  > div:not(:last-child) {
    > div:last-child {
      max-width: 250px;
    }
  }

  @media (max-width: 425px) {
    min-width: 250px;
    max-width: 250px;
    > div:not(:last-child) {
      flex-direction: column;
      > div:first-child {
        margin-bottom: 5px;
        /* min-height: 50px; */
        > div:first-child {
          padding: 0px;
        }
      }
    }
  }
`
export const FooterLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 220px;
  margin: 0 auto;

  > div {
    width: 100%;

    button {
      width: 100% !important;
    }

    :nth-child(even) {
      margin-left: 5px;
    }
  }
`

const Questions1 = props => (
  <Layout>
    <WizardState step={1} />
    <Title>กรอกข้อมูลพื้นฐาน เพื่อคำนวณค่าเบี้ยประกัน</Title>
    <Content>
      <Field
        name="gender"
        caption="เพศ"
        validate="required"
        component={Switch.Gender}
        marginBottom="16px"
      />
      <Field
        name="age"
        caption="อายุ"
        component={Currency}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="retireAge"
        caption="คาดว่าจะเกษียณเมื่ออายุ"
        component={Currency}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="30px"
      />
      <FooterLayout>
        <Button.NextButton to={path.questions2Path()} disabled={!props.valid} />
      </FooterLayout>
    </Content>
  </Layout>
)

const enhancer = compose(
  withForm({
    form: 'NoonQuestion',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: values => {
      const errors = {}

      if (!values.gender) {
        errors.gender = 'กรุณาระบุเพศ'
      }
      if (!values.age && values.age !== 0) {
        errors.age = 'กรุณาระบุอายุ'
      }
      if (!values.retireAge && values.retireAge !== 0) {
        errors.retireAge = 'กรุณาระบุอายุที่คาดว่าเกษียณ'
      }
      if (values.age > values.retireAge) {
        errors.retireAge = 'อายุเกษียณต้องมากกว่าอายุปัจจุบัน'
      }

      return errors
    },
  }),
)

export default enhancer(Questions1)
