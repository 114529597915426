import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import useWindowSize from '@rehooks/window-size'
import DateLabel from '../DateLabel'
import Table from '../../components/Table'
import {displayCurrency, displayPercentage} from '../../../../core/helper'

const Wrapper = styled.div`
  padding-bottom: 10.5px;
  color: #333333;
  @media (max-width: 1024px) {
    padding-bottom: 7.5px;
  }
  @media (max-width: 425px) {
    padding-bottom: 10.5px;
  }
`
const ContentWrapper = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  // padding: 0px 15px 9px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    width: 746px;
  }
  @media (max-width: 425px) {
    width: 320px;
    padding: 0px 15px 4px;
  }
`
const DateWrapper = styled.div`
  font-size: 12px;
  @media (max-width: 425px) {
    div {
      margin-bottom: 15px;
    }
  }
`
const TableWrapper = styled.div`
  padding: 0 30px;
  margin-bottom: 30px;
  font-size: 14px;
  @media (max-width: 1024px) {
    padding: 0px 1px 30px;
  }
  @media (max-width: 425px) {
    padding: 0px 7px 20px;
    font-size: 12px;
    div {
      min-height: 40px;
      height: auto;
    }
  }
`
const RemarkWrapper = styled.div`
  color: #444444;
  padding: 0px 19px 30px;
  font-size: 14px;
  @media (max-width: 1024px) {
    font-size: 11px;
    padding: 0px 1px 30px;
  }
  @media (max-width: 425px) {
    padding: 0px 7px 30px;
  }
`
const Row = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  padding: 0px 34px;
  margin-bottom: 15px;
  div:first-child {
    font-weight: 600;
  }
  :last-child {
    margin-bottom: 25px;
  }
  div {
    flex-basis: 50%;
    flex-grow: 0;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    padding: 0px 7px;
  }
`
const SectionHeader = styled.div`
  font-size: 16px;
  font-weight: ${props => props.theme.SEMI};
  padding: 0 30px;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    padding-left: 1px;
  }
  @media (max-width: 425px) {
    padding-left: 7px;
  }
`
const Remark = styled.div`
  display: flex;
  div:first-child {
    margin-right: 5px;
  }
`
const classNameHeader = css`
  font-weight: 600;
`
const flexRow = css`
  flex: 2;
  justify-content: flex-start;
  padding-left: 36px;
  font-weight: 600;
  @media (max-width: 1024px) {
    padding-left: 24px;
  }
  @media (max-width: 425px) {
    margin: 11px 0px;
    padding-left: 31px;
    padding-right: 17px;
    max-width: 130px;
  }
`
const flexStart = css`
  justify-content: flex-start;
`

const columns = [
  {
    title: 'รายการ',
    dataIndex: 'name',
    key: 'name',
    className: flexRow,
  },
  {
    title: 'สูงสุดไม่เกิน',
    dataIndex: 'remain',
    key: 'remain',
    className: flexStart,
  },
  {
    title: 'เก็บจริง',
    dataIndex: 'value',
    key: 'value',
    className: flexStart,
  },
]

export default props => {
  if (!props.fees) return null
  const {fund, fees} = props
  const {updatedAt, minSubseqPurchase, minInitPurchase} = fund
  const {
    sellUnitFee,
    managementFee,
    redempUnitFee,
    switchingInFee,
    switchingOutFee,
    transferFee,
    documentFee,
    benefitFee,
    registrarFee,
    adviserFee,
    distributeFee,
    otherFee,
    holderOtherFee,
    expensesFee,
    sellUnitActual,
    managementActual,
    redempUnitActual,
    switchingInActual,
    switchingOutActual,
    transferActual,
    documentActual,
    benefitActual,
    registrarActual,
    adviserActual,
    distributeActual,
    otherActual,
    holderOtherActual,
    expensesActual,
    secFeeLastUpdAt,
  } = fees

  const windowSize = useWindowSize()
  const mobile = windowSize.innerWidth <= 425
  const feeDataSource = [
    {
      key: '1',
      name: mobile ? 'การจัดการ' : 'ค่าธรรมเนียมการจัดการ',
      remain: displayPercentage(managementFee) || 'N/A',
      value: displayPercentage(managementActual) || 'N/A',
    },
    {
      key: '2',
      name: mobile ? 'ผู้ดูแล' : 'ค่าธรรมเนียมผู้ดูแลผลประโยชน์',
      remain: displayPercentage(benefitFee) || 'N/A',
      value: displayPercentage(benefitActual) || 'N/A',
    },
    {
      key: '3',
      name: mobile ? 'นายทะเบียน' : 'ค่าธรรมเนียมนายทะเบียน',
      remain: displayPercentage(registrarFee) || 'N/A',
      value: displayPercentage(registrarActual) || 'N/A',
    },
    {
      key: '4',
      name: mobile ? 'ค่าใช่จ่าย' : 'ค่าใช้จ่ายอื่น',
      remain: displayPercentage(otherFee) || 'N/A',
      value: displayPercentage(otherActual) || 'N/A',
    },
    {
      key: '5',
      name: mobile ? 'รวมทั้งหมด' : 'รวมค่าใช้จ่าย',
      remain: displayPercentage(expensesFee) || 'N/A',
      value: displayPercentage(expensesActual) || 'N/A',
    },
  ]
  const feePercentDataSource = [
    {
      key: '1',
      name: mobile ? 'การขาย' : 'ค่าธรรมเนียมการขาย',
      remain: displayPercentage(sellUnitFee) || 'N/A',
      value: displayPercentage(sellUnitActual) || 'N/A',
    },
    {
      key: '2',
      name: mobile ? 'การรับซื้อคืน' : 'ค่าธรรมเนียมการรับซื้อคืน',
      remain: displayPercentage(redempUnitFee) || 'N/A',
      value: displayPercentage(redempUnitActual) || 'N/A',
    },
    {
      key: '3',
      name: mobile
        ? 'การสับเปลี่ยนหน่วยลงทุนเข้า'
        : 'ค่าธรรมเนียมการสับเปลี่ยนหน่วยลงทุนเข้า',
      remain: displayPercentage(switchingInFee) || 'N/A',
      value: displayPercentage(switchingInActual) || 'N/A',
    },
    {
      key: '4',
      name: mobile
        ? 'การสับเปลี่ยนหน่วยลงทุนออก'
        : 'ค่าธรรมเนียมการสับเปลี่ยนหน่วยลงทุนออก',
      remain: displayPercentage(switchingOutFee) || 'N/A',
      value: displayPercentage(switchingOutActual) || 'N/A',
    },
    // {
    //   key: '5',
    //   name: mobile
    //     ? 'การซื้อขายหลักทรัพย์กรณีขายคืน'
    //     : 'ค่าธรรมเนียมในการซื้อขายหลักทรัพย์ กรณีขายคืน',
    //   remain: displayPercentage() || 'N/A',
    //   value: displayPercentage() || 'N/A',
    // },
    {
      key: '6',
      name: mobile ? 'การโอนหน่วย' : 'ค่าธรรมเนียมการโอนหน่วย',
      remain: displayPercentage(transferFee) || 'N/A',
      value: displayPercentage(transferActual) || 'N/A',
    },
    {
      key: '7',
      name: mobile
        ? 'การออกเอกสารแสดงสิทธิในหน่วยลงทุน'
        : 'ค่าธรรมเนียมการออกเอกสารแสดงสิทธิในหน่วยลงทุน',
      remain: displayPercentage(documentFee) || 'N/A',
      value: displayPercentage(documentActual) || 'N/A',
    },
    {
      key: '8',
      name: 'ค่าใช้จ่ายอื่นๆ',
      remain: displayPercentage(holderOtherFee) || 'N/A',
      value: displayPercentage(holderOtherFee) || 'N/A',
    },
  ]

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <DateLabel date={secFeeLastUpdAt} />
          <SectionHeader>
            ค่าธรรมเนียมกองทุน (อัตราตามโครงการ และอัตราที่จ่ายจริง)
          </SectionHeader>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={feeDataSource}
              classNameHeader={classNameHeader}
            />
          </TableWrapper>
          {/* <RemarkWrapper>
            <div>หมายเหตุ: </div>
            <Remark>
              <div>1.</div>
              <div>
                ค่าใช้จ่ายอื่น เป็นข้อมูลของรอบบัญชีล่าสุด (รวมภาษีมูลค่าเพิ่ม)
              </div>
            </Remark>
            <Remark>
              <div>2.</div>
              <div>
                สามารถดูข้อมูลค่าธรรมเนียม และค่าใช้จ่ายย้อนหลัง 3 ปี
                ของกองทุนในหนังสือชี้ชวนส่วนสรุปข้อมูลกองทุนรวมได้ที่
                www.one-asset.com
              </div>
            </Remark>
          </RemarkWrapper> */}
          <SectionHeader>
            ค่าธรรมเนียมที่เรียกเก็บจากผู้ถือหน่วย (% ของมูลค่าซื้อขาย)
          </SectionHeader>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={feePercentDataSource}
              classNameHeader={classNameHeader}
            />
          </TableWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <DateLabel date={updatedAt} />
          <Row>
            <div>มูลค่าซื้อขั้นต่ำในครั้งแรก</div>
            <div>{displayCurrency(minInitPurchase, 0)} บาท</div>
          </Row>
          <Row>
            <div>มูลค่าซื้อขั้นต่ำในครั้งถัดไป</div>
            <div>{displayCurrency(minSubseqPurchase, 0)} บาท</div>
          </Row>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  )
}
