import React from 'react'
import {compose} from 'recompose'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'
import {reduxForm, Field} from 'redux-form'

import Navigator from '../../Navigator'
import Banner from '../../../../static/images/Banner.png'
import BannerMobile from '../../../../static/images/BannerMobile.jpg'
import {responsive} from '../../../../core/style'

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  min-height: 460px;
  top: 80px;
  left: calc(50%);

  ${props =>
    responsive.tablet(css`
      left: calc(40%);
    `)};

  ${props =>
    responsive.mobile(css`
      top: 10%;
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 9px;
    `)};
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Banner});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const CardContainer = styled.div`
  display: flex;
  margin-bottom: 52px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 430px;
  justify-content: flex-start;

  ${props =>
    responsive.mobile(css`
      max-width: 185px;
    `)};
`

const CardStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 140px;
  background-color: ${props => props.theme.EXTRALIGHTBLUE};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 7.5px;
  overflow: hidden;

  :hover {
    background-color: ${props => props.theme.BLUE};
    color: white;
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 35px;
      background-color: ${props.theme.BLUE};
    `)};
`

const TextNumber = styled.div`
  font-size: 40px;
  font-weight: bold;

  ${props =>
    responsive.mobile(css`
      &.MobileDisplay {
        display: none;
      }
    `)};
`

const TextType = styled.div`
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
    `)};
`

const CardInfo = [
  {number: '1', details: 'ประกันชั้น 1', value: '1', realValue: '1'},
  {number: '2+', details: 'ประกันชั้น 2+', value: '20', realValue: '20'},
  {number: '2', details: 'ประกันชั้น 2', value: '2', realValue: '2'},
  {number: '3+', details: 'ประกันชั้น 3+', value: '30', realValue: '30'},
  {number: '3', details: 'ประกันชั้น 3', value: '3', realValue: '3'},
]

const Card = ({input}) => (
  <CardContainer>
    {CardInfo.map(value => (
      <CardStyle
        id={`noon-smart-insurance-motor-level-${value.realValue}`}
        key={value.number}
        onClick={() => {
          input.onChange(value.value)
          navigate(
            '/smart/InsuranceCategory/motor/intermediate/motorForm' +
              `?insuranceLevel=${value.value}`,
          )
        }}>
        <TextNumber className="MobileDisplay">{value.number}</TextNumber>
        <TextType>{value.details}</TextType>
      </CardStyle>
    ))}
  </CardContainer>
)

const CustomNavigator = styled(Navigator)`
  margin-bottom: 24px;
`

const MotorBannerCover = () => (
  <NoonSmartWrapper>
    <NoonSmartBanner>
      <Container>
        <NoonSmartHeader>แบบประกันรถยนต์</NoonSmartHeader>
        <NoonSubSmartHeader>
          อุ่นใจทุกการเดินทางเมื่อมีประกันรถยนต์เคียงข้าง
        </NoonSubSmartHeader>
        <CustomNavigator id="noon-smart-insurance-motor-level-back" />
        <Field name="insuranceLevel" component={Card} />
      </Container>
    </NoonSmartBanner>
  </NoonSmartWrapper>
)

const enhancer = compose(
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default enhancer(MotorBannerCover)
