import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, branch, withProps, defaultProps} from 'recompose'
import {withSpidus, withValue} from '../enhances'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {toCurrency} from '../core/helper'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    text-align: center;
  }
`
const Value = styled.div`
  display: flex;
  min-height: 39px;
  align-items: center;
  justify-content: center;
`
const Caption = styled.div`
  color: #333333;
  font-size: 16px;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 14px;
    ${props =>
      props.shortCaption &&
      css`
        > span {
          display: none;
        }
        &:after{
          content: '${props.shortCaption}';
        }
      `}
  }
`
const WrapperTax = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const CurrencyStyle = styled.div`
  font-family: Prompt;
  text-align: center;
  display: flex;
  align-items: baseline;
  > div:first-child {
    margin-right: 5px;
  }
`
const TaxValue = styled.div`
  font-size: 42px;
`

const TaxInput = props => (
  <Container className={props.className}>
    <Caption shortCaption={props.shortCaption}>
      <span>{props.caption}</span>
    </Caption>
    <Value>
      <FontAwesomeIcon
        icon={['fas', 'spinner']}
        spin
        hidden={!props.loading}
        style={{color: '#333333', fontSize: 26}}
      />
      <WrapperTax>
        <CurrencyStyle style={{color: props.valueColor}} hidden={props.loading}>
          <div>฿</div>
          <TaxValue> {props.displayValue}</TaxValue>
        </CurrencyStyle>
      </WrapperTax>
    </Value>
  </Container>
)

const enhance = compose(
  withProps(props => {
    const {positiveColor, negativeColor, fixValueColor} = props
    let displayValue = toCurrency(props.value, {prefix: ''})
    let valueColor = fixValueColor || '#333333'

    if (!fixValueColor) {
      if (displayValue.charAt(0) !== '-') {
        valueColor = positiveColor
      }
      if (displayValue.charAt(0) === '-') {
        valueColor = negativeColor
      }
    }

    return {
      displayValue: displayValue,
      valueColor: valueColor,
    }
  }),
)
export default enhance(TaxInput)
