import React from 'react'
import moment from 'moment'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {navigate} from '@reach/router'
import {compose, withState, lifecycle} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Lightbox from 'react-image-lightbox'

import Agent from './Agent'
import Loading from '../../share/Loading'
import shield from '../../../static/images/security-on.svg'
import defaultLogo from '../../../static/images/default-company-logo.png'

import {getOrder} from '../../../ducks/profile'
import {getProductType} from './utils'
import {toCurrency} from '../../NoonTax/core/helper'

const Layout = styled.div`
  margin-top: 15px;

  background-color: white;
`
const CustomLoading = styled(Loading)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const Header = styled.div`
  margin-top: 20px;

  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: ${props => props.theme.BLUE};
  text-align: center;
`
const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin: 20px auto 0;

  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.GREY80};
`
const Divider = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  border-bottom: 1px solid ${props => props.theme.GREY10};
`
const Card = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 20px 25px;
  min-height: 400px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Mid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px auto 40px;
`
const Footer = styled.div``
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`
const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
`
const Value = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceValue = styled.div`
  font-size: 42px;
  color: #265fa7;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const SmallText = styled.div`
  font-size: 12px;
  color: #6a94cb;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceText = styled.div`
  color: #6a94cb;
  font-size: 16px;
  font-weight: 700;
`
const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Symbol = styled.div`
  font-size: 12px;
`
const Img = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 5px;
`
const Shield = styled.img`
  width: 34px;
  height: 40px;
`
const WrapText = styled.div`
  padding: 0 10px;
  flex: 1;
`
const MoneySymbol = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #265fa7;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 3px;
  margin-right: 5px;
`
const Wrap = styled.div`
  position: relative;
`
const Point = styled.div`
  width: 34px;
  height: 40px;
  position: absolute;
  font-family: 'Prompt', 'Roboto', sans-serif;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 20px;
  left: 0;
`
const LabelTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`
const Line = styled.div`
  border-top: 1px solid #e5e5e5;
  margin: 20px 0;
`
const LinkToTabel = styled.div`
  color: #ffb933;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`
const Span = styled.span`
  text-decoration: underline;

  cursor: pointer;
`
const WrapNav = styled.div`
  width: 100%;
  max-width: 300px;

  font-size: 16px;
  line-height: 24px;
  color: #c1c1c1;

  cursor: pointer;
`
const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;

  > span {
    margin-top: 30px;

    text-align: center;
  }
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
`

const Table = styled.img`
  width: 100%;
  margin-top: 20px;
`

const Annuity = ({product, onShow}) => (
  <Footer>
    <LabelTitle>ผลประโยชน์</LabelTitle>
    <Row>
      <Label>
        <Icon icon={['fas', 'shield-alt']} />
        ได้เงินคืนหลังเกษียณต่อปี
      </Label>
      <MoneySymbol>
        ฿{' '}
        {toCurrency(product.annuityRefundPerYear, {
          prefix: '',
        })}
      </MoneySymbol>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'undo-alt']} />
        ได้เงินคืนตั้งแต่อายุ
      </Label>
      <Value>
        {product.minAgeAnnuity} - {product.maxAgeAnnuity} ปี
      </Value>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'hand-holding-usd']} />
        ได้รับเงินเมื่อเสียชีวิต
      </Label>
      <Value>เริ่มที่ ฿ {toCurrency(product.sumInsured, {prefix: ''})}</Value>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'calculator']} />
        ลดภาษีปีนี้ได้
      </Label>
      <Value>฿{toCurrency(product.premium, {prefix: ''})}</Value>
    </Row>
    {product.graphImageUrl && (
      <LinkToTabel>
        <Icon icon={['fas', 'table']} />
        <Span onClick={onShow}>ดูตารางผลประโยชน์</Span>
      </LinkToTabel>
    )}
    <Line />
    <LabelTitle style={{marginBottom: '10px'}}>เงื่อนไข</LabelTitle>
    <Row>
      <Label>
        <Icon icon={['fas', 'check']} />
        ได้เงินคืนตั้งแต่อายุ
      </Label>
      <Value>
        {product.minAgeAnnuity} - {product.maxAgeAnnuity} ปี
      </Value>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'check']} />
        ได้รับเงินเมื่อเสียชีวิต
      </Label>
      <Value>เริ่มที่ ฿ {toCurrency(product.sumInsured, {prefix: ''})}</Value>
    </Row>
  </Footer>
)

const calcTotalBenefit = product => {
  const irr = product.irr || 0
  const protectionPeriod = product.protectionPeriod || 0
  const yieldToMaturity = product.yieldToMaturity || 0
  const sumInsured = product.sumInsured || 0
  return (
    ((irr / 100) * protectionPeriod + parseFloat(yieldToMaturity) / 100) *
      sumInsured || 0
  )
}

const Saving = ({product, onShow}) => (
  <Footer>
    <LabelTitle>ผลประโยชน์</LabelTitle>
    <Row>
      <Label>
        <Icon icon={['fas', 'shield-alt']} />
        ได้เงินคืนทั้งหมด
      </Label>
      <MoneySymbol>
        {toCurrency(calcTotalBenefit(product), {prefix: '฿'})}
      </MoneySymbol>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'undo-alt']} />
        ปันผลเฉลี่ยปีละ
      </Label>
      <Value>
        {toCurrency(product.sumInsured * (product.irr / 100), {
          prefix: '฿',
        })}{' '}
      </Value>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'coins']} />
        รับเงินก้อนเมื่อครบสัญญา
      </Label>
      {toCurrency(product.yieldToMaturityAmount, {
        prefix: '฿',
      })}
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'hand-holding-usd']} />
        ได้รับเงินเมื่อเสียชีวิต
      </Label>
      เริ่มที่ {toCurrency(product.sumInsured, {prefix: '฿'})}
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'calculator']} />
        ลดภาษีปีนี้ได้
      </Label>
      <Value>{toCurrency(product.premium, {prefix: '฿'})}</Value>
    </Row>
    {product.graphImageUrl && (
      <LinkToTabel>
        <Icon icon={['fas', 'table']} />
        <Span onClick={onShow}>ดูตารางผลประโยชน์</Span>
      </LinkToTabel>
    )}
    <Line />
    <LabelTitle style={{marginBottom: '10px'}}>เงื่อนไข</LabelTitle>
    <Row>
      <Label>
        <Icon icon={['fas', 'check']} />
        อายุที่รับสมัคร
      </Label>
      <Value>
        {product.minAgeInsured}
        {' - '}
        {product.maxAgeInsured} ปี
      </Value>
    </Row>
    <Row>
      <Label>
        <Icon icon={['fas', 'check']} />
        ระยะเวลาที่ต้องจ่าย (เบี้ยประกัน)
      </Label>
      <Value>{product.payPeriod} ปี</Value>
    </Row>
  </Footer>
)
const CustomLightBox = css`
  background: white;
`
const ProductDetail = props => {
  const {order, isLoading, isShowTable, setShowTable} = props
  const {product = {}} = order

  const onShowCoverageTable = () => setShowTable(true)
  return (
    <Layout>
      <CustomLoading isLoading={isLoading}>
        {order && order.quotationId && isShowTable && (
          <Lightbox
            wrapperClassName={CustomLightBox}
            mainSrc={product.graphImageUrl}
            onCloseRequest={() => setShowTable(false)}
          />
        )}
        {order && order.quotationId && (
          <React.Fragment>
            <WrapNav onClick={() => navigate('/profile/order')}>
              <FontAwesomeIcon
                style={{marginRight: '5px'}}
                icon={['fas', 'chevron-left']}
              />
              กลับ
            </WrapNav>
            <Header>
              {getProductType(order.policyType, order.insuranceType)}
            </Header>
            <SubHeader>
              <span>วันที่ทำรายการ</span>
              <span>{moment(order.createdAt).xformat('DD MMM YY')}</span>
            </SubHeader>
            <Divider />
            <Card>
              <Top>
                <Img
                  src={order.insuranceCompany.officialLogoUrl || defaultLogo}
                />
                <WrapText>
                  <Name>{order.insuranceName}</Name>
                  <Symbol>{order.insuranceCompany.shortName}</Symbol>
                </WrapText>
                <Wrap>
                  <Shield src={shield} />
                  <Point>{order.noonScore}</Point>
                </Wrap>
              </Top>
              <Mid>
                <InsuranceText>
                  เงินที่ต้องจ่ายต่อปี (เบี้ยประกัน)
                </InsuranceText>
                <InsuranceValue>
                  <MoneySymbol>฿</MoneySymbol>{' '}
                  {toCurrency(product.premium, {prefix: ''})}
                </InsuranceValue>
                <SmallText>
                  {order.insuranceType === 'annuity'
                    ? product.payPeriodText
                    : `จ่าย ${toCurrency(product.payPeriod, {prefix: ''}) ||
                        'N/A'} ปี`}
                </SmallText>
              </Mid>
              {order.insuranceType === 'annuity' ? (
                <Annuity product={product} onShow={onShowCoverageTable} />
              ) : (
                <Saving product={product} onShow={onShowCoverageTable} />
              )}
              <Agent {...order.agent} time={order.personalInfoContactTime} />
            </Card>
          </React.Fragment>
        )}
        {!isLoading && !order.quotationId && (
          <React.Fragment>
            <NotFound>
              <WrapNav onClick={() => navigate('/profile/order')}>
                <FontAwesomeIcon
                  style={{marginRight: '5px'}}
                  icon={['fas', 'chevron-left']}
                />
                กลับ
              </WrapNav>
              <span>ไม่มีประวัติการสั่งซื้อที่ค้นหา</span>
            </NotFound>
          </React.Fragment>
        )}
      </CustomLoading>
    </Layout>
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.profile.isLoading,
      order: state.profile.order,
    }),
    {getOrder},
  ),
  withState('isShowTable', 'setShowTable', false),
  lifecycle({
    componentDidMount() {
      this.props.getOrder(this.props.id)
    },
  }),
)

export default enhancer(ProductDetail)
