import React from 'react'
import {compose, withProps, withHandlers, withState} from 'recompose'
import styled from 'react-emotion'
import Modal from '../../share/Modal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Step1 from '../../../static/images/Mask_Group_80.svg'
import Step2 from '../../../static/images/Group_638.svg'
import Step3 from '../../../static/images/Mask_Group_81.svg'
import {isClient} from '../../NoonQuestion/common/helper'
import {Button} from '../components'

const CustomModal = styled(Modal)``
const WrapContentModal = styled.div`
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const WrapperTitle = styled.div`
  margin-top: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    color: #ffc107;
    font-size: 40px;
  }
`
const Title = styled.div`
  font-size: 20px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;
  text-align: center;
`
const MobileCutWord = styled.br`
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`
const Description = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`

const StepBackground = styled.div`
  position: absolute;
  z-index: 0;
  top: 64px;
  left: 39px;

  width: 3px;
  height: 200px;

  background-color: ${props => props.theme.GREY10};
`

const RowDescription = styled.div`
  z-index: 1;

  max-width: 240px;
  min-width: 240px;

  display: flex;
  margin-bottom: 15px;
  > img:first-child {
    width: 82px;
    align-items: center;
    color: #dc3545;
    margin-top: 10px;
    margin-right: 16px;
    font-size: 82px;
    border-radius: 50%;
    background-color: ${props => props.theme.GREY10};
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #333333;
  }
`

const StepImg = styled.img``

const SubSpan = styled.span`
  font-size: 12px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`

const UnderstandButton = styled(Button)`
  min-width: 250px;
  max-width: 250px;
  min-height: 38px;
`
const NotShowAgain = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  color: #7f7f7f;
  margin-top: 14px;
  font-size: 12px;
  > svg {
    margin-right: 12px;
    font-size: 16px;
  }
`

const StepCalculateTaxModal = props => (
  <CustomModal isShowModal={props.isShowModal} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <WrapperTitle>
        <Title>
          คำนวณลดภาษีด้วยประกัน <MobileCutWord />
          ใน 3 ขั้นตอนง่ายๆ
        </Title>
      </WrapperTitle>
      <Description>
        <StepBackground />
        <RowDescription>
          <StepImg alt="step_1" src={Step1} />
          <div>กรอกข้อมูลส่วนตัว</div>
        </RowDescription>

        <RowDescription>
          <StepImg alt="step_2" src={Step2} />
          <div>
            เลือกประกัน
            <SubSpan>บำนาญ vs ออมทรัพย์</SubSpan>
          </div>
        </RowDescription>

        <RowDescription>
          <StepImg alt="step_3" src={Step3} />
          <div>รอตัวแทนติดต่อกลับ</div>
        </RowDescription>
      </Description>
      <Footer>
        <UnderstandButton onClick={props.onClose}>เข้าใจแล้ว</UnderstandButton>
        <NotShowAgain onClick={props.onClickCheckbox}>
          {props.valueCheckbox ? (
            <FontAwesomeIcon icon={['far', 'check-square']} />
          ) : (
            <FontAwesomeIcon icon={['far', 'square']} />
          )}
          ไม่ต้องแสดงข้อความนี้อีก
        </NotShowAgain>
      </Footer>
    </WrapContentModal>
  </CustomModal>
)

const enhancer = compose(
  withState('valueCheckbox', 'setValueCheckbox', props => props.value),
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
    },
    onClickCheckbox: props => () => {
      isClient &&
        window.localStorage.setItem(
          'Noon-Is-Close-Step-Calculate',
          !props.valueCheckbox,
        )
      props.setValueCheckbox(!props.valueCheckbox)
    },
  }),
)

export default enhancer(StepCalculateTaxModal)
