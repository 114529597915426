import React from 'react'
import {Button} from '../../NoonTax/components'
import {compose, withHandlers, branch} from 'recompose'
import {withRouter} from '../enhancers'
import styled from 'react-emotion'

const CustomButton = styled(Button)`
  height: 40px;
`

const enhancer = compose(
  withRouter(),
  branch(
    props => props.to,
    withHandlers({
      onClick: props => () => {
        props.history.push(props.to)
      },
    }),
  ),
)

const Link = enhancer(CustomButton)

Link.NextButton = props => <Link {...props}>ถัดไป</Link>
Link.PrevButton = props => <Link {...props}>ก่อนหน้า</Link>
Link.CalculateButton = props => <Link {...props}>ประมวลผล</Link>

export default Link
