import React, {useCallback} from 'react'
import styled from 'react-emotion'

const Wrap = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 10px;
`
const Block = styled.div`
  width: ${props => (props.details ? '18px' : '15px')};
  height: ${props => (props.details ? '20px' : '10px')};
  flex: 1;
  background: ${props => (props.disabled ? '#E5E5E5' : props.color)} 0% 0%
    no-repeat padding-box;
  border-right: 0.30000001192092896px solid #ffffff;
  display: flex;
  opacity: 1;
  align-items: center;
  justify-content: center;
  p {
    margin: 12px 0;
    display: ${props => (props.disabled ? 'none' : 'inline-block')};
    font-size: 12px;
  }
  :last-child {
    border-right: none;
  }
`
const BlockComponent = props => {
  const {details = false} = props
  const disabled = details
    ? !(props.number === props.risk)
    : props.number > props.risk

  return (
    <Block {...props} color={props.color} disabled={disabled}>
      {details && <p>{props.risk}</p>}
    </Block>
  )
}
export default props => {
  return (
    <Wrap>
      <BlockComponent {...props} number={1} color="#0E5A50" />
      <BlockComponent {...props} number={2} color="#1C7851" />
      <BlockComponent {...props} number={3} color="#279864" />
      <BlockComponent {...props} number={4} color="#30C277" />
      <BlockComponent {...props} number={5} color="#ECCC45" />
      <BlockComponent {...props} number={6} color="#EA992E" />
      <BlockComponent {...props} number={7} color="#E75C2D" />
      <BlockComponent {...props} number={8} color="#C32832" />
    </Wrap>
  )
}
