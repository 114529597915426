import React from 'react'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Form, Topic} from './share'
import Button from '../../../share/Button'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 47px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 114px;
  color: ${props => props.theme.BLUE};
  opacity: 0.2;
`

const Text = styled.p`
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 0;

  font-size: 18px;
  line-height: 28px;
  color: ${props => props.theme.GREY80};
  text-align: left;
`

const SubmitButton = styled(Button)`
  min-width: 200px;
  height: 30px;

  > svg.icon {
    transform-origin: center;
    transform: rotate(180deg);
  }
`

const WelcomePage = ({onSubmit}) => (
  <Form>
    <Topic>ประเมินความคุ้มครองที่แนะนำ</Topic>
    <Wrapper>
      <Icon icon={['fas', 'search']} />
      <Text>
        noon กำลังวิเคราะห์
        <br />
        แบบประกันที่ดีที่สุดสำหรับคุณ
      </Text>
    </Wrapper>
    <SubmitButton onClick={onSubmit} icons={['fas', 'angle-left']}>
      ถัดไป
    </SubmitButton>
  </Form>
)

export default WelcomePage
