import React from 'react'
import styled from 'react-emotion'
import AdvanceForm from './AdvanceForm'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const DebtProtectionForm = ({windowWidth}) => (
  <Container>
    <AdvanceForm windowWidth={windowWidth} />
  </Container>
)

export default DebtProtectionForm
