import React from 'react'
import styled, { css } from 'react-emotion'
import Button from '../../../share/Button'
import { obj2Url, url2Obj } from '../../../../core/helper'

const Card = styled.div`
  margin: 25px 0;
  width: 660px;
  background: #265fa7;
  height: 87px;
  color: white;
  padding: 20px 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  @media (max-width: 425px) {
    max-width: calc(100vw - 5%);
    padding: 0 10px;
  }
`
const Title = styled.div`
  font-size: 14px;
`
const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
`
const Content = styled.div`
  flex: 1;
`
const WrapButton = styled.div``
const DetailButton = styled.a`
  background: white;
  color: #333333;
  font-size: 12px;
  height: 30px;

  display: flex;

  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
  border: unset;
  padding: 0 20px;
  :focus,
  :hover,
  :active {
    text-decoration: none;
    background: #ffb933;
    color: #333333;
  }
`
const WeightType = {
  whole_life: 5,
  saving: 1,
  term: 5,
  annuity: 1,
}
export default props => {
  const { policies, params } = props
  if (policies.length < 2) return null
  const rider = policies[1]

  return (
    <Card>
      <Content>
        <Title>แบบประกันชีวิตที่พ่วงกับแบบประกันนี้</Title>
        <Name>{rider.name}</Name>
      </Content>
      <WrapButton>
        <DetailButton
          target="_blank"
          href={`/smart/life/starter/product/${rider.id}?${obj2Url({
            age: params.age,
            gender: params.gender,
            sumInsured: parseInt(rider.sumInsured),
            searchValue: parseInt(rider.sumInsured),
            searchBy: 'sum_insured',
            insuranceType: rider.subType,
            weightType: WeightType[rider.subType],
            id: rider.id,
          })}`}>
          ดูรายละเอียด
        </DetailButton>
      </WrapButton>
    </Card>
  )
}
