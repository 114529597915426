import React from 'react'
import styled from 'react-emotion'
import {Steps} from 'antd'
import {compose} from 'recompose'

const {Step} = Steps

const CustomSteps = styled(Steps)`
  &,
  .ant-steps {
    width: unset !important;
  }

  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot,
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot,
  .ant-steps-item-finish > .ant-steps-item-tail:after {
    background: #ffb933;
  }
`

const WizardState = props => (
  <CustomSteps progressDot size="small" current={props.step - 1}>
    <Step />
    <Step />
    <Step />
  </CustomSteps>
)

const enhancer = compose()

export default enhancer(WizardState)
