import {takeLatest, put, select} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {httpGet, API_HOST} from '../../core/httpClient'

const SET_DATA_OPTIONS = 'SET_DATA_OPTIONS'
const GET_COMPANIES = 'GET_COMPANIES'
const GET_VEHICLE_YEAR_OPTIONS = 'GET_VEHICLE_YEAR_OPTIONS'
const GET_VEHICLE_BRAND_OPTIONS = 'GET_VEHICLE_BRAND_OPTIONS'
const GET_VEHICLE_MODEL_OPTIONS = 'GET_VEHICLE_MODEL_OPTIONS'
const GET_VEHICLE_SUB_MODEL_OPTIONS = 'GET_VEHICLE_SUB_MODEL_OPTIONS'
const GET_VEHICLE_ENGINE_SIZE_OPTIONS = 'GET_VEHICLE_ENGINE_SIZE_OPTIONS'
const GET_DISEASES_OPTIONS = 'GET_DISEASES_OPTIONS'

export const setDataOptions = Creator(SET_DATA_OPTIONS, 'data')
export const getCompaniesOption = Creator(GET_COMPANIES, 'type')
export const getVehicleYearOption = Creator(GET_VEHICLE_YEAR_OPTIONS)
export const getVehicleBrandOption = Creator(GET_VEHICLE_BRAND_OPTIONS, 'id')
export const getVehicleModelOption = Creator(
  GET_VEHICLE_MODEL_OPTIONS,
  'year',
  'brand',
)
export const getVehicleSubModelOption = Creator(
  GET_VEHICLE_SUB_MODEL_OPTIONS,
  'year',
  'brand',
  'model',
)

export const getVehicleEngineSizeOption = Creator(
  GET_VEHICLE_ENGINE_SIZE_OPTIONS,
  'year',
  'brand',
  'model',
)
export const getDiseasesOption = Creator(GET_DISEASES_OPTIONS)

const modifiedOptionCompanies = (result, item) => {
  if (item)
    result.push({label: item.name, value: item.id, logo: item.officialLogoUrl})
  return result
}

const modifiedOptionYear = (result, item) => {
  if (item) result.push({label: item, value: item})
  return result
}

const modifiedOptionBrand = (result, item) => {
  if (item) result.push({label: item.descriptionEn, value: item.id})
  return result
}

const modifiedOptionModel = (result, item) => {
  if (item) result.push({label: item.nameEn, value: item.id})
  return result
}

const modifiedOptionSubModel = (result, item) => {
  if (item)
    result.push({
      label: item.subModelName,
      value: item.idKey,
      price: item.newPrice,
      avgPrice: item.avgRetail,
    })
  return result
}

const modifiedOptionEngineSize = (result, item) => {
  if (item)
    result.push({label: item.engineDescription, value: item.engineDescription})
  return result
}

const modifiedOptionDiseases = (result, item) => {
  if (item)
    result.push({
      label: item.label,
      value: item.childs.map(value => value.value).toString(),
    })
  return result
}

export function* getCompaniesOptionSaga({payload: {type}}) {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}insurance_companies`
  try {
    const response = yield httpGet(url, {
      policyType: type === 'motor' ? 'auto' : type,
    })
    const options = response.data.data.insuranceCompanies.reduce(
      modifiedOptionCompanies,
      [],
    )
    yield put(setDataOptions({companiesOption: options}))
  } catch (error) {
    console.error('error', error)
  }
  yield put(setDataOptions({isLoading: false}))
}

export function* getVehicleYearOptionSaga() {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}auto_insurance_policies/vehicle_year_options`
  try {
    const response = yield httpGet(url)
    const options = response.data.data.vehicleYearOptions.reduce(
      modifiedOptionYear,
      [],
    )
    yield put(setDataOptions({vehicleYearOption: options}))
  } catch (error) {
    console.error('error', error)
  }
  yield put(setDataOptions({isLoading: false}))
}

export function* getVehicleBrandOptionSaga({payload: {id}}) {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}auto_insurance_policies/vehicle_brand_options`
  try {
    const response = yield httpGet(url, {vehicle_year: id})
    const options = response.data.data.vehicleBrandOptions.reduce(
      modifiedOptionBrand,
      [],
    )
    yield put(setDataOptions({vehicleBrandOptions: options}))
  } catch (error) {
    console.error('error', error)
  }
  yield put(setDataOptions({isLoading: false}))
}

export function* getVehicleModelOptionSaga({payload: {year, brand}}) {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}auto_insurance_policies/vehicle_model_options`
  try {
    const response = yield httpGet(url, {
      vehicle_year: year,
      vehicle_brand_id: brand,
    })
    const options = response.data.data.vehicleModelOptions.reduce(
      modifiedOptionModel,
      [],
    )
    yield put(setDataOptions({vehicleModelOptions: options}))
  } catch (error) {
    console.error('error', error)
  }
  yield put(setDataOptions({isLoading: false}))
}

export function* getVehicleSubModelOptionSaga({payload: {year, brand, model}}) {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}auto_insurance_policies/vehicle_sub_model_options`
  try {
    const response = yield httpGet(url, {
      vehicle_year: year,
      vehicle_brand_id: brand,
      vehicle_model_id: model,
    })
    const options = response.data.data.vehicleSubModelOptions.reduce(
      modifiedOptionSubModel,
      [],
    )
    yield put(setDataOptions({vehicleSubModelOptions: options}))
  } catch (error) {
    console.error('error', error)
  }
  yield put(setDataOptions({isLoading: false}))
}

export function* getVehicleEngineSizeOptionSaga({
  payload: {year, brand, model},
}) {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}auto_insurance_policies/vehicle_engine_size_options`
  try {
    const response = yield httpGet(url, {
      vehicle_year: year,
      vehicle_brand_id: brand,
      vehicle_model_id: model,
    })
    const options = response.data.data.engineSizeOptions.reduce(
      modifiedOptionEngineSize,
      [],
    )

    yield put(
      setDataOptions({carValue: response.data.data.vehicleSubModelOptions}),
    )
    yield put(setDataOptions({vehicleEngineSizeOptions: options}))
  } catch (error) {
    console.error('error', error)
  }

  yield put(setDataOptions({isLoading: false}))
}

export function* getDiseasesOptionSaga() {
  yield put(setDataOptions({isLoading: true}))

  const url = `${API_HOST}health_insurance_policies/diseases_options`
  try {
    const response = yield httpGet(url)
    const options = response.data.data.diseases.reduce(
      modifiedOptionDiseases,
      [],
    )
    yield put(setDataOptions({diseasesOptions: options}))
  } catch (error) {
    console.error('error', error)
  }
  yield put(setDataOptions({isLoading: false}))
}

export function* watchOptionsSaga() {
  yield takeLatest(GET_COMPANIES, getCompaniesOptionSaga)
  yield takeLatest(GET_VEHICLE_YEAR_OPTIONS, getVehicleYearOptionSaga)
  yield takeLatest(GET_VEHICLE_BRAND_OPTIONS, getVehicleBrandOptionSaga)
  yield takeLatest(GET_VEHICLE_MODEL_OPTIONS, getVehicleModelOptionSaga)
  yield takeLatest(GET_VEHICLE_SUB_MODEL_OPTIONS, getVehicleSubModelOptionSaga)
  yield takeLatest(
    GET_VEHICLE_ENGINE_SIZE_OPTIONS,
    getVehicleEngineSizeOptionSaga,
  )
  yield takeLatest(GET_DISEASES_OPTIONS, getDiseasesOptionSaga)
}

const initial = {
  companiesOption: [],
  vehicleYearOption: [],
  vehicleBrandOptions: [],
  vehicleModelOptions: [],
  vehicleSubModelOptions: [],
  vehicleEngineSizeOptions: [],
  diseasesOptions: [],
  isLoading: false,
}

export default createReducer(initial, state => ({
  [SET_DATA_OPTIONS]: ({data}) => ({...state, ...data}),
}))
