import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form'
import {compose, lifecycle, withState} from 'recompose'

import {
  getEvaluateData,
  getAgentData,
  setEvaluateAgent,
  setLoading,
} from '../../../../ducks/profile'

import ShowEvaluateAgent from './ShowEvaluateAgent.js'
import Evaluate from './EvaluateAgentPage'
import SelectAgent from './SelectAgent'
import Loading from '../../../share/Loading'
import {isNull} from 'util'
import {get} from 'https'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const CustomLoading = styled(Loading)`
  .loading-container {
    position: absolute;
  }
`

const EvaluateAgent = ({
  setEvaluateAgent,
  Step,
  setStep,
  agent,
  setAgent,
  agentProfile,
  setIsShowModal,
  isShowModal,
  confirm,
  setConfirm,
  evaluateAgentData,
  id,
  loading,
}) => {
  const StepOneSubmit = props => {
    setStep(1)
    setAgent(agentProfile[id][props.AgentName - 1]) // real Version
  }

  const StepTwoSubmit = props => {
    setEvaluateAgent(props)
  }

  return (
    <CustomLoading isLoading={loading}>
      {evaluateAgentData[id] &&
      (evaluateAgentData[id] &&
        Object.keys(evaluateAgentData[id]).length != 0) ? (
        <>
          <ShowEvaluateAgent
            evaluateAgentData={evaluateAgentData[id]}
            agent={agent}
          />
        </>
      ) : (
        <Container>
          {Step == 0 && (
            <SelectAgent
              setStep={setStep}
              agentProfile={agentProfile}
              Summit={StepOneSubmit}
              id={id}
            />
          )}
          {(Step == 1 || Step == 2) && (
            <Evaluate
              setStep={setStep}
              Step={Step}
              Summit={StepTwoSubmit}
              agent={agent}
              isShowModal={isShowModal}
              setIsShowModal={setIsShowModal}
              confirm={confirm}
              setConfirm={setConfirm}
              id={id}
            />
          )}
        </Container>
      )}
    </CustomLoading>
  )
}

const enhancer = compose(
  withState('Step', 'setStep', 0),
  withState('agent', 'setAgent', []),
  withState('isShowModal', 'setIsShowModal', false),
  withState('confirm', 'setConfirm', false),
  connect(
    (state, props) => ({
      agentProfile: state.profile.agentData || null,
      evaluateAgentData: state.profile.evaluateAgent || false,
      initialValues: state.profile.evaluateAgent[props.id] && {
        reviewRemark: state.profile.evaluateAgent[props.id].reviewRemark,
      },
      loading: state.profile.isLoading,
    }),
    {getAgentData, setEvaluateAgent, getEvaluateData, setLoading},
  ),
  reduxForm({
    form: 'SelectAgent',
    enableReinitialize: true,
  }),
  lifecycle({
    componentWillMount() {},
    componentDidMount() {},
    componentDidUpdate() {},
  }),
)

export default enhancer(EvaluateAgent)
