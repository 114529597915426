import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import {navigate} from '@reach/router'
import Button from '../share/Button'
import Loading from '../share/Loading'
import {checkToken} from '../../ducks/auth'
import {url2Obj} from '../../core/helper'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`
const SubHeader = styled.div`
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 12px;
`

const SuccessFreeForm = () => (
  <Container>
    <Header>ลองเช็คอีเมล์ของคุณสิ</Header>
    <SubHeader>ถ้าสนใจปรึกษากับนูน ติดต่อเราได้เลยนะ</SubHeader>
    <CustomButton
      onClick={() => {
        navigate(`/contact-us`)
      }}
      icons={['fa', 'pen']}>
      ติดต่อเรา
    </CustomButton>
  </Container>
)

export default SuccessFreeForm
