import React from 'react'
import {compose} from 'recompose'
import {reduxForm, Field} from 'redux-form'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Navigator from '../../Navigator'
import Banner from '../../../../static/images/Banner.png'
import BannerMobile from '../../../../static/images/BannerMobile.jpg'
import {responsive, fadeIn} from '../../../../core/style'

const NoonSmartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 80px;
  left: calc(50%);
  width: fit-content;

  ${props =>
    responsive.tablet(css`
      left: calc(40%);
    `)};

  ${props =>
    responsive.mobile(css`
      top: 10%;
    `)};
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 9px;
    `)};
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Banner});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calC(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const InfoContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  animation: ${fadeIn} 2s;

  ${props =>
    responsive.mobile(css`
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 29px;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  margin-bottom: 12px;

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      margin-bottom: 0;
    `)};
`

const Info = styled.div`
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      max-width: 81px;
      margin-left: 17px;
      white-space: pre;
    `)};
`

const BoxOnce = styled.div`
  display: flex;
  position: relative;
  width: 200px;
  height: 140px;
  margin: 0 5px;
  border-radius: 5px;
  background-color: #6a94cb;
  color: ${props => (props.isSelected ? 'white' : 'white')};
  overflow: hidden;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    background-color: ${props => (props.disabled ? '#6a94cb' : '#265fa7')};

    color: white;
    .briefcase-medical,
    .procedures {
      color: rgba(178, 117, 0, 0.3);
    }
  }

  @media (max-width: 450px) {
    margin-top: 15px;
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 50px;
      background-color: #265fa7;
      margin: 10px 0;
    `)};
`
const ContainerBox = styled.div`
  display: flex;
  width: 395px;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      width: 100%;
    `)};
`

const BoxList = [
  {
    iconTag: 'fas',
    iconName: 'briefcase-medical',
    info: `คุ้มครอง
ค่ารักษาพยาบาล`,
    id: 'hs',
  },
  {
    iconTag: 'fas',
    iconName: 'procedures',
    info: `คุ้มครอง
โรคร้ายแรง`,
    id: 'ci',
  },
]

const Box = ({BoxList, input, reset}) => (
  <ContainerBox>
    {BoxList.map(item => (
      <BoxOnce
        id={`noon-smart-insurance-health-${item.id}`}
        key={item.id}
        onClick={() => {
          input.onChange(item.id)
          navigate(
            `/smart/InsuranceCategory/health/intermediate/Healthform/${item.id}`,
          )
        }}>
        <InfoContainerBox>
          <CustomIcon icon={[item.iconTag, item.iconName]} />
          <Info>{item.info}</Info>
        </InfoContainerBox>
      </BoxOnce>
    ))}
  </ContainerBox>
)

const HealthBannerCover = () => (
  <NoonSmartWrapper>
    <NoonSmartBanner>
      <Container>
        <NoonSmartHeader>แบบประกันสุขภาพ</NoonSmartHeader>
        <NoonSubSmartHeader>
          สร้างหลักประกันสุขภาพที่ดีวันนี้เพื่อความยั่นยืนในวันข้างหน้า
        </NoonSubSmartHeader>
        <Navigator id="noon-smart-insurance-health-back" />
        <Field name="insuranceType" component={Box} BoxList={BoxList} />
      </Container>
    </NoonSmartBanner>
  </NoonSmartWrapper>
)

const enhancer = compose(
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default enhancer(HealthBannerCover)
