import React, {useEffect, useState, useMemo} from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {responsive} from '../../../core/style'
import fundeeApi from '../../../core/fundeeApi'
import Info from './Info'
import Similar from './Similar'
import Loading from '../../share/Loading'
import {setDataProduct} from '../../../ducks/product'
import {
  fetchFavoriteFundRefIds,
  setIsShowModalFavorite,
} from '../../../ducks/fund'
import ModalFavorite from '../../share/ModalFavorite'
import Overview from './Overview'
import Performance from './Performance'
import Portfolio from './Portfolio'
import FeesAndCond from './FeesAndCond'
import Management from './Management'

const Container = styled.div`
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: black;
  overflow: hidden;
  transition: 0.5s;
  width: 100vw;

  ${props =>
    responsive.desktop(css`
      display: flex;
      flex-direction: column;
    `)};

  ${props =>
    responsive.tablet(css`
      display: flex;
      flex-direction: column;
    `)};

  ${props =>
    responsive.mobile(css`
      display: flex;
      flex-direction: column;
    `)};
`

const Divider = styled.div`
  background: #e5e5e5;
  height: 1px;
  width: 100vw;
`
const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`
const Tab = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tab {
    overflow: hidden;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 722px;
    height: 16px;
  }

  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    color: #6a94cb;
    border-right: 1px solid #6a94cb;
    :last-child {
      border-right: none;
    }
  }

  .tab button:hover {
    color: #265fa7;
    font-weight: ${props => props.theme.BOLD}; //bold
  }

  .tab button.active {
    color: #265fa7;
    font-weight: ${props => props.theme.BOLD}; //bold
  }

  .tabcontent {
    display: none;
    padding: 6px 12px;
    padding-top: 40px;
    width: 100vw;
    min-height: 200px;
  }
  @media (max-width: 500px) {
    .tabcontent {
      // width: 100vw;
    }
  }
`
const WrapHeader = styled.div`
  width: 100vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    align-items: flex-start;
  }
`
const WrapTab = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const TabChild = props => {
  const {handleClickTabs, title = 'tab', active = false} = props
  const classActive = active ? 'tablinks active' : 'tablinks'
  return (
    <button className={classActive} onClick={e => handleClickTabs(e, title)}>
      {title}
    </button>
  )
}
const Panel = props => {
  const {title = 'tab', children} = props
  return (
    <div id={title} className="tabcontent">
      <WrapTab>{children}</WrapTab>
    </div>
  )
}

export default connect(
  state => ({
    isLogin: state.auth.isLogin,
    isShowModalFavorite: state.fund.isShowModalFavorite,
  }),
  {setDataProduct, fetchFavoriteFundRefIds, setIsShowModalFavorite},
)(props => {
  const {
    setDataProduct,
    isLogin,
    fetchFavoriteFundRefIds,
    setIsShowModalFavorite,
    isShowModalFavorite,
  } = props
  const [fund, setFund] = useState({})
  const [fetching, setFetching] = useState(true)
  const {location} = props
  const fundSymbol = useMemo(() => {
    const pathArray = location.pathname.split('/')
    return pathArray[pathArray.length - 1]
  }, [location.pathname])

  useEffect(() => {
    async function getFund() {
      setFetching(true)
      const include = [
        'prices',
        'return_period',
        'top_holdings_updated_date',
        'top_holdings',
        'similar_funds',
        'performance',
        'portfolio',
        'fees',
        'assets',
        'sec_related_persons',
        'fund_classes',
      ]
      const response = await fundeeApi.get(`/funds/${fundSymbol}`, {include})
      setFund(response.data.fund)
      setFetching(false)
    }
    getFund(fundSymbol)
  }, [fundSymbol])

  useEffect(() => {
    setDataProduct({noOpacityHeader: true})
    return () => {
      setDataProduct({noOpacityHeader: false})
    }
  }, [])

  useEffect(() => {
    isLogin && fetchFavoriteFundRefIds()
  }, [isLogin])

  const [active, setActive] = useState('Overview')
  const handleClickTabs = (e, value) => {
    // setActive(value)
    const tabcontent = document.getElementsByClassName('tabcontent')
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    const tablinks = document.getElementsByClassName('tablinks')
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }

    document.getElementById(value).style.display = 'block'
    e.currentTarget.className += ' active'
    setActive(value)
  }

  useEffect(() => {
    document.getElementById(active).style.display = 'block'
  }, [active])

  return (
    <Loading isLoading={fetching}>
      <Container>
        <Info fund={fund} />
        <Wrapper>
          <Tab>
            <WrapHeader>
              <div className="tab">
                <TabChild
                  handleClickTabs={handleClickTabs}
                  title="Overview"
                  active
                />
                <TabChild
                  handleClickTabs={handleClickTabs}
                  title="Performance"
                />
                <TabChild handleClickTabs={handleClickTabs} title="Portfolio" />
                <TabChild
                  handleClickTabs={handleClickTabs}
                  title="Fees & Conditions"
                />
                <TabChild
                  handleClickTabs={handleClickTabs}
                  title="Management"
                />
              </div>
            </WrapHeader>
            <Panel title="Overview">
              <Overview fund={fund} />
            </Panel>
            <Panel title="Performance">
              <Performance
                symbol={fund.symbol}
                performance={fund.performance}
              />
            </Panel>
            <Panel title="Portfolio">
              <Portfolio
                fund={fund}
                portfolio={fund.portfolio}
                assets={fund.assets}
              />
            </Panel>
            <Panel title="Fees & Conditions">
              <FeesAndCond fund={fund} fees={fund.fees} />
            </Panel>
            <Panel title="Management">
              <Management
                secRelatedPersons={fund.secRelatedPersons}
                fundClasses={fund.fundClasses}
              />
            </Panel>
          </Tab>
        </Wrapper>
        <Divider />
        <Similar fund={fund} />
      </Container>
      <ModalFavorite
        isShowModal={isShowModalFavorite}
        setIsShowModal={setIsShowModalFavorite}
        isReload
      />
    </Loading>
  )
})
