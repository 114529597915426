import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import * as paths from '../common/paths'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Success from './Success'
import styled from 'react-emotion'
import {compose, branch, renderNothing, lifecycle, withState} from 'recompose'
import {connect} from 'react-redux'
import {spidusStore} from '../core/spidus'

const MainLayout = styled.div`
  border-top: 1px solid #ffb933;
  background: white;
`

const PrepareTax = props => (
  <MainLayout>
    <Switch>
      <Route path={paths.prepareTaxStep1Path()} component={Step1} />
      <Route path={paths.prepareTaxStep2Path()} component={Step2} />
      <Route path={paths.prepareTaxStep3Path()} component={Step3} />
      <Route path={paths.prepareTaxSuccessPath(':code')} component={Success} />
      <Redirect to={paths.prepareTaxStep1Path()} />
    </Switch>
  </MainLayout>
)

const enhancer = compose(
  connect(states => ({
    initialized: states.auth.initialized,
  })),
  branch(props => !props.initialized, renderNothing),
)

export default enhancer(PrepareTax)
