import React from 'react'
import styled, {css} from 'react-emotion'
import Field from '../../../../../share/Field'
import {compose, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Tooltip from '../../../../../share/Tooltip'
import {responsive, breakpoints} from '../../../../../../core/style'
import {
  displayNumber,
  inRange,
  numberParser,
} from '../../../../../../core/helper'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 700px;
  padding: 0 20px;
  ${props =>
    responsive.mobile(css`
      padding: 0;
      margin: 0 auto;
      align-items: flex-start;
      width: unset;
    `)};
`

const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }

  ${props =>
    responsive.tablet(css`
      &.ant-tooltip {
        > .ant-tooltip-content {
          > .ant-tooltip-arrow {
            border-right-color: transparent;
            border-bottom-color: #7f7f7f;
          }
        }
      }
    `)};
`

const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  .label {
    margin: 0;
    min-width: 0;
  }
  .input-text-container {
    width: unset;
  }
  .input-percent-container {
    width: unset;
    .css-juf10i {
      left: 100px;
    }
  }

  input {
    width: 120px;
    font-size: 16px;
  }
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      input {
        width: 240px;
        text-align: center;
        padding: 0 20px;
      }
      .input-percent-container {
        .css-juf10i {
          left: 130px;
        }
      }
      .label {
        width: 240px;
        justify-content: flex-start;
        margin-bottom: 15px;
      }
    `)};
`

const MiddleTextInput = styled(TextInput)`
  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
      margin: 10px 0;
      input {
        width: 160px;
        text-align: center;
        padding: 0 20px;
      }
    `)};
`
const SmallTextInput = styled(TextInput)`
  width: unset;
  margin: 0 10px;
  input {
    width: 42px;
    text-align: center;
  }

  ${props =>
    responsive.mobile(css`
      input {
        width: 42px;
        text-align: center;
        padding: 0;
      }
    `)};
`

const CheckBox = styled(Field)`
  margin: 0;
  width: 120px;

  .label {
    color: black;
    margin-left: 17px;
    min-width: 85px;
  }
  .checkboxContainer {
    margin-right: 0;
  }

  ${props =>
    responsive.mobile(css`
      width: 220px;
    `)};
`

const Label = styled.div`
  font-size: 16px;
  padding: 1px 0 0;
  margin-left: 80px;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  padding-right: 120px;
  ${props =>
    responsive.mobile(css`
      padding: 0;
    `)};
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 17px;
  cursor: pointer;
  color: #7f7f7f;
  border-right-color: #7f7f7f;

  ${props =>
    responsive.mobile(css`
      position: absolute;
      right: 18px;
      top: 10px;
    `)};
`

const ContainerField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: ${props => (props.last ? '30px' : '0')};
  width: 100%;
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const Price = styled.span`
  font-size: 16px;
  min-width: 155px;
  text-align: center;
  color: ${props => props.theme.EXTRALIGHTBLUE};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
      flex-direction: row;
      margin: 10px 0 0 10px;
    `)};
`
const Tuition = styled.span`
  min-width: 80px;
  font-size: 16px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  margin-left: 15px;
  margin-right: 10px;
  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
      width: 160px;
      margin-left: 15px;
      margin-bottom: 10px;
      margin-right: 0px;
    `)};
`
const Unit = styled.span`
  width: 35px;
  font-size: 16px;
  margin-left: 15px;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const UnitSmallText = styled(Unit)`
  width: 20px;
  margin-left: 10px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`
const UnitFuture = styled(UnitSmallText)`
  width: 50px;
  color: black;
  ${props =>
    responsive.mobile(css`
      display: flex;
      width: 100%;
    `)};
`

const TopicColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 66px;
  margin-top: 30px;
  width: 100%;
`
const Equal = styled.div`
  margin-left: 15px;
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const ContainerSmallText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
      margin: 20px 0 20px 20px;
    `)};
`
const UnitSpan = styled.span`
  font-size: 16px;
`

const UnitHeaderSpan = styled.span`
  font-size: 16px;
  width: 160px;
  padding-left: 8px;
  ${props =>
    responsive.mobile(css`
      display: ${props.disabled ? 'none' : 'flex'};
    `)};
`

const FieldArray = [
  {
    label: 'ประถม',
  },
  {
    label: 'มัธยมต้น',
  },
  {
    label: 'มัธยมปลาย',
  },
  {
    label: 'ปริญญาตรี',
  },
  {
    label: 'ปริญญาโท',
  },
  {
    label: 'ปริญญาเอก',
  },
]
const disabledEnhancer = compose(
  withState('isDisabled', 'setDisabled', props => !props.isDisabledList),
)

const ContainerFieldForm = disabledEnhancer(
  ({
    item,
    index,
    last,
    isDisabled,
    setDisabled,
    onAdvanceSummary,
    onFinalSummary,
    listSummary,
    change,
    windowWidth,
    initTimeOut,
    timeOut,
  }) => (
    <ContainerField last={last}>
      <CheckBox
        name={`checkboxAdvance${index}`}
        type="checkbox"
        label={item.label}
        didChange={() => {
          setDisabled(!isDisabled)
          onAdvanceSummary()
          onFinalSummary()
        }}
        disabled={isDisabled}
      />
      <ContainerSmallText disabled={isDisabled}>
        {windowWidth <= breakpoints.mobile && <UnitSpan>อายุ</UnitSpan>}
        <SmallTextInput
          name={`smallTextLeft${index}`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 99)}
          parse={numberParser}
          disabled={isDisabled}
          didChange={value => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                index === 0 &&
                  change(
                    'Planning',
                    `custom.smallTextRight${index}`,
                    parseInt(value) + 5,
                  )
                index > 0 &&
                  index < 3 &&
                  change(
                    'Planning',
                    `custom.smallTextRight${index}`,
                    parseInt(value) + 2,
                  )
                onAdvanceSummary()
                onFinalSummary()
              }, 500),
            )
          }}
        />
        -
        <SmallTextInput
          name={`smallTextRight${index}`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 99)}
          parse={numberParser}
          disabled={index <= 2 ? true : isDisabled}
          didChange={() => {
            if (timeOut) clearTimeout(timeOut)
            initTimeOut(
              setTimeout(() => {
                onAdvanceSummary()
                onFinalSummary()
              }, 500),
            )
          }}
        />
        {windowWidth <= breakpoints.mobile && <UnitSpan>ปี</UnitSpan>}
      </ContainerSmallText>
      <UnitSmallText disabled={isDisabled}>ปี </UnitSmallText>
      <Tuition disabled={isDisabled}>
        {windowWidth <= breakpoints.mobile
          ? 'ค่าเทอมปีละ (บาท/ปี)'
          : 'ค่าเทอมปีละ'}
      </Tuition>
      {windowWidth <= breakpoints.mobile && (
        <UnitHeaderSpan disabled={isDisabled}>ปัจจุบัน</UnitHeaderSpan>
      )}
      <MiddleTextInput
        name={`textAdvance${index}`}
        type="text"
        placeholder="0"
        format={displayNumber}
        parse={numberParser}
        disabled={isDisabled}
        didChange={() => {
          if (timeOut) clearTimeout(timeOut)
          initTimeOut(
            setTimeout(() => {
              onAdvanceSummary()
              onFinalSummary()
            }, 500),
          )
        }}
      />
      <Equal>=</Equal>
      {windowWidth <= breakpoints.mobile && (
        <UnitHeaderSpan disabled={isDisabled}>อนาคต</UnitHeaderSpan>
      )}
      <Price disabled={isDisabled}>
        {Number.isNaN(listSummary[index])
          ? 0
          : displayNumber(Math.round(listSummary[index]))}
        <UnitFuture>บาท/ ปี</UnitFuture>
      </Price>
    </ContainerField>
  ),
)

const SubFormNoon = ({
  onAdvanceSummary,
  onFinalSummary,
  listSummary,
  change,
  totalList,
  windowWidth,
  initTimeOut,
  timeOut,
}) => (
  <Container>
    <FieldContainer>
      <TextInput
        name="inflationRate"
        label={`${
          windowWidth <= breakpoints.mobile
            ? 'ณ อัตราเงินเฟ้อ (ต่อปี)'
            : 'ณ อัตราเงินเฟ้อ'
        }`}
        type="percent"
        placeholder="0"
        normalize={inRange(0, 100)}
        parse={numberParser}
        didChange={() => {
          if (timeOut) clearTimeout(timeOut)
          initTimeOut(
            setTimeout(() => {
              onAdvanceSummary()
              onFinalSummary()
            }, 500),
          )
        }}
      />
      <Unit>ต่อปี</Unit>
    </FieldContainer>
    <TopicColumn>
      <Label>ปัจจุบัน</Label>
      <Label>อนาคต</Label>
      <CustomTooltip
        placement={`${windowWidth <= breakpoints.tablet ? 'bottom' : 'right'}`}
        title={`คำนวณเป็นมูลค่าในอนาคต ด้วยอัตราเงินเฟ้อที่กำหนด`}>
        <CustomIcon icon={['fas', 'info-circle']} />
      </CustomTooltip>
    </TopicColumn>
    {FieldArray.map((item, index) => (
      <ContainerFieldForm
        item={item}
        index={index}
        last={FieldArray.length === index + 1}
        key={item.label}
        onAdvanceSummary={onAdvanceSummary}
        onFinalSummary={onFinalSummary}
        listSummary={listSummary}
        change={change}
        isDisabledList={totalList.custom[`checkboxAdvance${index}`]}
        windowWidth={windowWidth}
        initTimeOut={initTimeOut}
        timeOut={timeOut}
      />
    ))}
  </Container>
)

export default SubFormNoon
