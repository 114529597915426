import {compose, mapProps, withHandlers, branch} from 'recompose'

const withFields = () =>
  compose(
    branch(
      props => (props.input && props.input.onChange) || props.onChange,
      withHandlers({
        onChange: props => value => {
          props.input && props.input.onChange && props.input.onChange(value)
          props.onChange && props.onChange(value)
        },
      }),
    ),
    mapProps(({input, meta, onChange, ...restProps}) => ({
      ...restProps,
      ...meta,
      ...input,
      onChange,
    })),
  )

export default withFields
