import React from 'react'
import {compose, withState, withProps, withHandlers} from 'recompose'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'

import Content from './Content'
import Free from './Free'
import SearchBox from './SearchBox'
import Noon from './Noon'
import CountNumber from './CountNumber'
import Background from './Background'
import SuggestProduct from './SuggestProduct'
import withSEO from '../share/withSEO'
import {Header as NoonQuestionHeader} from '../NoonQuestion/components'
import {isClient} from '../NoonQuestion/common/helper'
import CoronaAd from '../Corona/CoronaAd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  min-height: 607px;
  margin: 30px auto 0;
`

const TaxPlanning = styled.div`
  background-color: #4375b5;
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 23px 0px 22px 0px;
`

const TitleTaxPlanning = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: space-between;
  min-width: 240px;
  > svg:first-child {
    font-size: 30px;
  }
  > div {
    margin: 0px 10px;
    font-family: Prompt;
  }
`

const Description = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: Prompt;
  font-size: 14px;
  text-align: center;
  @media (max-width: 767px) {
    max-width: 240px;
  }
`
const WebCutWord = styled.br`
  display: flex;
  @media (max-width: 767px) {
    display: none;
  }
`
const Landing = ({
  gender,
  isOnTop,
  onTaxUx,
  location,
  setGender,
  showNoonQuestionHeader,
}) => (
  <Container>
    <Background gender={gender} />
    {showNoonQuestionHeader && <NoonQuestionHeader show={false} />}
    <TopContainer>
      <SearchBox gender={gender} setGender={setGender} location={location} />
      <TaxPlanning onClick={onTaxUx}>
        <TitleTaxPlanning>
          <FontAwesomeIcon icon={['fas', 'calculator']} />
          <div>คำนวณลดภาษีด้วยประกัน</div>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </TitleTaxPlanning>
        <Description>
          คำนวณลดหย่อนด้วยประกันได้ตามใจชอบ <WebCutWord /> เลือกประกันบำนาญ
          ประกันออมทรัพย์ เพื่อใช้คำนวณลดหย่อนภาษี
        </Description>
      </TaxPlanning>
      <Noon />
    </TopContainer>
    {/* <SuggestProduct isOnTop={isOnTop} /> */}
    <CountNumber />
    <Content />
    <Free />
    <CoronaAd />
  </Container>
)

const enhancer = compose(
  withSEO(
    'noon Insure With Accuracy - ค้นหาแบบประกันภัยที่ใช่ ตามไลฟ์สไตล์คุณ',
    'noon เครื่องมือในการวางแผนและเลือกซื้อแบบประกันภัยที่เหมาะสม ตอบโจทย์ทุกไลฟ์สไตล์ของคุณ คิดเรื่องประกันไม่ออกให้บอกนูน เพราะนูนรับประกันความเที่ยงตรง',
    'แบบประกันภัยครบวงจร,ซื้อประกันอะไรดี,ทุกเรื่องครบ จบเกี่ยวกับการประกันภัย',
  ),
  withState('gender', 'setGender', 'male'),
  withProps(props => ({
    showNoonQuestionHeader: true,
  })),
  withHandlers({
    onTaxUx: props => () => {
      navigate(`/tax/v2`)
    },
  }),
)

export default enhancer(Landing)
