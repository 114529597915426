import React from 'react'
import styled from 'react-emotion'
import moment from '../../../core/moment-buddhist'

moment.locale('th')

const PeriodInfo = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
  padding: 15px 15px 0 0;
`

const Period = ({period}) => {
  var periodLabel = 'N/A'
  if (period) {
    var d = new Date(
      parseInt(period.substr(0, 4)) + 543,
      parseInt(period.substr(4, 2)) - 1,
      1,
    )
    periodLabel = moment(d).format('MMMM YYYY')
  }

  return <PeriodInfo>ข้อมูล ณ {periodLabel}</PeriodInfo>
}

export default Period
