import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import {reduxForm} from 'redux-form'
import styled, {css} from 'react-emotion'
import Button from '../share/Button'
import Field from '../share/Field'
import Loading from '../share/Loading'
import {ThaiDateFormatter, normalizeDate, isEmail} from '../../core/helper'
import {responsive} from '../../core/style'
import {postFreeForm, setData} from '../../ducks/landing'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${props =>
    responsive.mobile(css`
      width: 250px;
      margin: 0 auto;
    `)};
`
const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  ${props =>
    responsive.mobile(css`
      width: 206px;
      text-align: center;
    `)};
`
const SubHeader = styled.div`
  margin-bottom: 35px;
  font-size: 12px;
  ${props =>
    responsive.mobile(css`
      text-align: center;
    `)};
`

const GenderButton = styled(Field)`
  width: 100%;
  > div {
    > div {
      justify-content: center;
      .male {
        width: 125px;
      }
      .female {
        width: 125px;
      }
    }
  }
  .label {
    min-width: 100px;
  }
  ${props =>
    responsive.mobile(css`
      margin-bottom: 10px;
    `)};
`
const GoogleInput = styled(Field)`
  margin: 15px 0;
  ${props =>
    responsive.mobile(css`
      margin: 10px 0;
    `)};
`

const SubmitButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 100px;
  font-size: 12px;
`

const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastName) {
    errors.lastName = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }
  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  if (!values.password) {
    errors.password = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  return errors
}

const FreeForm = ({handleSubmit, postFreeForm, isLoading, errorMessage}) => (
  <Loading isLoading={isLoading}>
    <Container onSubmit={handleSubmit(value => postFreeForm(value))} noValidate>
      <Header>ฟรี! สรุปแบบประกันชีวิตและสุขภาพปี 2019 สำหรับคุณโดยเฉพาะ</Header>
      <SubHeader>
        ข้อมูลของคุณด้านล่างจะช่วยให้เราสามารถคัดเลือกประกันที่เหมาะสมสำหรับคุณได้ง่ายขึ้น
      </SubHeader>
      <GenderButton name="gender" type="switch" />
      <GoogleInput
        name="firstName"
        type="googleInput"
        placeholder="ชื่อ"
        hideError
      />
      <GoogleInput
        name="lastName"
        type="googleInput"
        placeholder="นามสกุล"
        hideError
      />
      <GoogleInput
        name="birthdate"
        type="googleInput"
        placeholder="วันเกิด (วว/ดด/ปปปป)"
        normalize={normalizeDate}
        format={ThaiDateFormatter}
        hideError
      />
      <GoogleInput
        name="email"
        type="googleInput"
        placeholder="อีเมล"
        hideError
      />
      <SubmitButton
        htmlType="submit"
        errorMessage={errorMessage}
        icons={['fa', 'pen']}>
        ลงทะเบียน
      </SubmitButton>
    </Container>
  </Loading>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      initialValues: {gender: 'male'},
      isLoading: state.landing.isLoading,
      errorMessage: state.landing.errorMessage,
    }),
    {postFreeForm, setData},
  ),
  reduxForm({
    form: 'FreeForm',
    validate,
  }),
  lifecycle({
    componentDidMount() {
      const {setData} = this.props
      setData({errorMessage: ''})
    },
  }),
)

export default enhancer(FreeForm)
