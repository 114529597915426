import queryString from 'query-string'
import {pick, get, set} from 'lodash'

export const isServer = typeof window === 'undefined'
export const isClient = !isServer

export const url2Obj = params => queryString.parse(params)
export const obj2Url = params => queryString.stringify(params)

export const safe = (block, safeValue) => {
  try {
    return block()
  } catch (e) {
    return safeValue
  }
}

export const pickByKeys = (arr, ...keys) => {
  if (arr) {
    return arr.map(a => pick(a, keys))
  } else {
    return []
  }
}

export const formatFullName = nameInfo =>
  `${nameInfo.prefixName || ''}${nameInfo.firstName ||
    ''} ${nameInfo.lastName || ''}`

export const cleanQueryParams = (params, paths) => {
  paths = paths.filter(path => !!get(params, path))

  params = pick(params, paths)

  paths.forEach(path => {
    const value = get(params, path)
    if (/^\d+(.\d+)?$/.test(value)) {
      set(params, path, parseFloat(value))
    }
  })

  return params
}

export const cleanQueryParamss = params => {
  if (params instanceof Array) {
    return params.map(param => cleanQueryParams(param))
  } else if (params instanceof Object) {
    return Object.entries(params).reduce((memo, [key, value]) => ({
      ...memo,
      [key]: cleanQueryParams(value),
    }))
  } else if (typeof params === 'string') {
    if (/^\d+(\.\d*)?$/.test(params)) {
    }
  } else {
    return params
  }
}

export const prepareOptions = options => {
  if (options) {
    if (typeof options[0] === 'string') {
      return options.map(label => ({label, value: label}))
    } else {
      return options
    }
  } else {
    return []
  }
}

export const getOptionLabel = (options, targetValue) => {
  options = prepareOptions(options)
  const option = options.find(({value}) => value === targetValue)
  return option ? option.label : null
}

export const displayNumber = value => {
  let nums = ('' + value).split('.')
  const onlyNums = nums[0].replace(/[^-\d]/g, '')
  let rgx = /(\d+)(\d{3})/
  let x1 = onlyNums
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  if (nums.length > 1) x1 = x1 + '.' + nums[1]
  return x1
}

export const toCurrency = value => {
  return new Intl.NumberFormat().format(value)
}
