export const OurServiceIconList = [
  {
    id: 0,
    icon: 'search',
    moreIcon: 'bolt',
    text: 'ระบบค้นหาที่ทันสมัย และครบถ้วน',
  },
  {
    id: 1,
    icon: 'user-tie',
    text: 'ผู้เชี่ยวชาญด้านประกันภัย คอยให้คำปรึกษาอย่าง ใกล้ชิด 24 ชั่วโมง',
  },
  {
    id: 2,
    icon: 'hand-holding-usd',
    text: 'ประหยัดเวลาและจ่ายเบี้ยประกันในราคาเหมาะสม',
  },
  {
    id: 3,
    icon: 'book-reader',
    text:
      'เหมาะสำหรับผู้ที่ไม่มีความรู้ด้านประกันภัย จนถึงผู้ที่มีความรู้อยู่แล้ว',
  },
]

export const NoonAppContentDetails = [
  {
    id: 0,
    text: 'คอยวัดระดับความปลอดภัยในการขับขี่ รถยนต์ของผู้ใช้',
  },
  {
    id: 1,
    text: 'บันทึกประวัติการขับขี่เพื่อนำมาลดเบี้ยประกันรถยนต์',
  },
  {
    id: 2,
    text: 'มีการสะสมคะแนนเพื่อนำมาแลกรับของรางวัล',
  },
  {
    id: 3,
    text: 'เตรียมพร้อมขยายเพื่อรองรับฟังก์ชั่นอื่นๆ อีกในอนาคต',
  },
]

export const TeamNoonMoblieDetails = [
  [
    {
      id: 0,
      name: 'Rat Phetcharat',
      position: 'CEO',
      phrase: '"ทำวันนี้ให้ดีที่สุด ต้องหาเงินเข้าบริษัทต่อไป"',
      imageColor: 'Color/Rat@2x.png',
      imageBlackWhite: 'BlackWhite/Rat@2x.png',
    },
    {
      id: 1,
      name: 'Maneeratch Sriparivatin',
      position: 'CBDO',
      phrase: '"Everybody has a diamond inside of them"',
      imageColor: 'Color/Yuii@2x.png',
      imageBlackWhite: 'BlackWhite/Yuii@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Kasidis Suwanampai',
      position: 'AFPT, COO',
      phrase: '"ขยันวันนี้ สบายวันหน้า ขี้เกียจวันนี้ สบายวันนี้เลย"',
      imageColor: 'Color/Ice@2x.png',
      imageBlackWhite: 'BlackWhite/Ice@2x.png',
    },
    {
      id: 0,
      name: 'Chanatakanchn Thongchai',
      position: 'Senior Digital Marketing Designer',
      phrase: '"Que Sera, Sera"',
      imageColor: 'Color/Ginger@2x.png',
      imageBlackWhite: 'BlackWhite/Ginger@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Nantita Udomwannachai',
      position: 'Business Co-ordinator',
      phrase: 'สร้างชาตินี้ ใช้ยันชาติหน้า (หนี้)',
      imageColor: 'Color/Joy@2x.png',
      imageBlackWhite: 'BlackWhite/Joy@2x.png',
    },
    {
      id: 0,
      name: 'Ekkachai Sinthunawa',
      position: 'Senior Digital Marketing and Data Analytic',
      phrase: "I'm not lazy, I'm on power saving mode",
      imageColor: 'Color/Benz@2x.png',
      imageBlackWhite: 'BlackWhite/Benz@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Rungarun Khuntachareon',
      position: 'Content Creator',
      phrase: 'กินวันนี้ให้ดีที่สุด แล้วไปหยุดที่มาม่า',
      imageColor: 'Color/Meen@2x.png',
      imageBlackWhite: 'BlackWhite/Meen@2x.png',
    },
    {
      id: 0,
      name: 'Khunat Iamkaew',
      position: 'Software Engineer',
      phrase: 'ผมโสด แอด Tinder มาได้นะ',
      imageColor: 'Color/Boy@2x.png',
      imageBlackWhite: 'BlackWhite/Boy@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Narongrit Wongwisit',
      position: 'Data Scientist & Software Engineer',
      phrase: 'The empty set is a subset of every set',
      imageColor: 'Color/Zam@2x.png',
      imageBlackWhite: 'BlackWhite/Zam@2x.png',
    },
    {
      id: 0,
      name: 'Krisada Kanchaleaw',
      position: 'Software Engineer',
      phrase: 'Make it work Make it right Make it fat',
      imageColor: 'Color/Bright@2x.png',
      imageBlackWhite: 'BlackWhite/Bright@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Anubud Kholoet',
      position: 'Software Engineer',
      phrase: 'ความจริงมีเพียง หนึ่งเดียวเท่านั้น',
      imageColor: 'Color/Mee@2x.png',
      imageBlackWhite: 'BlackWhite/Mee@2x.png',
    },
    {
      id: 0,
      name: 'Anantapon Sangwarosakul',
      position: 'Software Engineer',
      phrase: 'เห็นงานเป็นลม เห็นนมสู้ตายยยย',
      imageColor: 'Color/Mewshi@2x.png',
      imageBlackWhite: 'BlackWhite/Mewshi@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Chaiyasit Khotsombat',
      position: 'Software Engineer',
      phrase: 'ไม่ต้องเขียนคิ้วให้คม พี่ดูนม ไม่ได้ดูหน้า',
      imageColor: 'Color/Nueng@2x.png',
      imageBlackWhite: 'BlackWhite/Nueng@2x.png',
    },
    {
      id: 0,
      name: 'Supanut Boonsuebpan',
      position: 'Software Engineer',
      phrase:
        'หีบมากมายหลายหีบยกหีบหนี หีบมากมีหนีหีบหนีบหนีหาย เห็นหนีหีบหนีบหนีกันมากมาย เห็นหีบหายหลายหีบหนีบหนีเอย',
      imageColor: 'Color/Fluke@2x.png',
      imageBlackWhite: 'BlackWhite/Fluke@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Piyabud Chobsungnoen',
      position: 'Software Engineer',
      phrase: 'โถ่แมงกุดจี้',
      imageColor: 'Color/Nueng-1.svg',
      imageBlackWhite: 'BlackWhite/Nueng.svg',
    },
    {
      id: 0,
      name: 'Natthawat lertpakawanich',

      position: 'Software Engineer',
      phrase: 'แค่หน้าก็ยังงง',
      imageColor: 'Color/Nueng-1.svg',
      imageBlackWhite: 'BlackWhite/Rat@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Wattanun Saekang',
      position: 'Software Engineer',
      phrase: 'ยิ้มหวานแบบหมี',
      imageColor: 'Color/Nueng-1.svg',
      imageBlackWhite: 'BlackWhite/Rat@2x.png',
    },
    {
      id: 0,
      name: 'Gulvara Chaowattanakit',
      position: 'System Analyst',
      phrase: 'The passion drive your life',
      imageColor: 'Color/Nueng-1.svg',
      imageBlackWhite: 'BlackWhite/Rat@2x.png',
    },
  ],
  [
    {
      id: 0,
      name: 'Malai Somsri',
      position: 'Housekeeper',
      phrase: 'ทำวันนี้ ให้สะอาดที่สุด',
      imageColor: 'Color/Nueng-1.svg',
      imageBlackWhite: 'BlackWhite/Rat@2x.png',
    },
  ],
]
