import React from 'react'
import {Tooltip as AntdTooltip} from 'antd'
import styled from 'react-emotion'
import Icon from './Icon'

const TooltipIcon = styled(Icon)`
  font-size: 16px;
  color: #333333;
`

const Tooltip = props => (
  <AntdTooltip placement={props.placement} title={props.title}>
    <TooltipIcon style={props.style} icon="info-circle" />
  </AntdTooltip>
)

export default Tooltip
