import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {compose, withState} from 'recompose'
import {connect} from 'react-redux'

import Button from '../share/Button'
import Field from '../share/Field'
import map from '../../static/images/TQLD-Map.jpg'
import Modal from '../share/Modal'
import Loading from '../share/Loading'
import {contactUs} from '../../ducks/contactUs'
import {
  isEmail,
  isPhoneNumber,
  phoneFormatter,
  formatParser,
} from '../../core/helper'
import {responsive} from '../../core/style'
import {url2Obj} from '../../core/helper'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;

  ${props =>
    responsive.tablet(css`
      margin-bottom: 20px;
    `)};
`
const ContainerBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1024px;
  width: 100%;

  ${props =>
    responsive.tablet(css`
      flex-direction: column;
    `)};
`
const CustomImage = styled.img`
  width: 450px;
  height: 300px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 5px 0px #7f7f7f;
  cursor: pointer;

  ${props =>
    responsive.tablet(css`
      box-shadow: unset;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 250px;
      height: 180px;
    `)};
`

const Selector = styled(Field)`
  margin: 0;
  width: 400px;
  flex-direction: column;
  .input-selector-container {
    margin: 0;

    .Select {
      width: 100%;
      height: 40px;
      background-color: white;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
    }
  }
  .label {
    justify-content: flex-start;
    margin: 10px 0;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

const TextArea = styled(Field)`
  height: unset;
  flex-direction: column;
  textarea {
    width: 400px;
    height: 100px;
    text-align: left;
  }
  .label {
    justify-content: flex-start;
    margin: 10px 0;
    min-width: 0;
    width: 100%;
  }

  ${props =>
    responsive.mobile(css`
      textarea {
        width: 100%;
      }
    `)};
`

const TextInput = styled(Field)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px 0;
  .label {
    justify-content: flex-start;
    margin: 10px 0;
    min-width: 0;
    width: 100%;
  }
  .input-text-container {
    width: 400px;
  }

  input {
    font-size: 16px;
    text-align: left;
  }

  ${props =>
    responsive.mobile(css`
      .input-text-container {
        width: 100%;
      }
    `)};
`
const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;
`

const Map = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 421px;
`
const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  ${props =>
    responsive.tablet(css`
      margin-bottom: 30px;
    `)};

  ${props =>
    responsive.mobile(css`
      padding: 0 20px;
      width: 100%;
    `)};
`

const TqldHeader = styled.div`
  font-size: 20px;
`

const TqldContent = styled.div`
  font-size: 12px;
  text-align: center;
`

const ModalImg = styled.img`
  border-radius: 5px;
  width: 100%;
`

const validate = values => {
  const errors = {}
  if (!values.detail) {
    errors.detail = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  if (!values.phone) {
    errors.phone = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.phone)) {
    errors.phone = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  return errors
}

const ContactUs = ({
  handleSubmit,
  isShowModal,
  setIsShowModal,
  contactUs,
  isLoading,
  reset,
}) => (
  <Loading isLoading={isLoading}>
    <Container onSubmit={handleSubmit(value => contactUs(value, reset))}>
      <Header>ติดต่อเรา</Header>
      <ContainerBody>
        <Map>
          <CustomImage src={map} onClick={() => setIsShowModal(!isShowModal)} />
          <Modal
            width={'800px'}
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}>
            <ModalImg src={map} />
          </Modal>
          <TqldHeader>บริษัท ทีคิวแอลดี จำกัด</TqldHeader>
          <TqldContent>
            เลขที่ 92/25 อาคารสาธรธานี 2 ชั้น 11
            <br />
            แขวงสีลม เขตบางรัก กรุงเทพมหานคร 10500 <br />
            <br />
            ติดต่อ (+66) 63 065 6919 <br />
            <a href="mailto:customers@tqld.co.th">customers@tqld.co.th</a>
          </TqldContent>
        </Map>
        <FieldContainer>
          <Selector
            name={'subject'}
            type="selector"
            options={[
              {
                label: 'สอบถามข้อมูลการสั่งซื้อ',
                value: 'สอบถามข้อมูลการสั่งซื้อ',
              },
              {
                label: 'สอบถามรายละเอียดเพิ่มเติม',
                value: 'สอบถามรายละเอียดเพิ่มเติม',
              },
              {
                label: 'ติดต่อทางธุรกิจ',
                value: 'ติดต่อทางธุรกิจ',
              },
              {
                label: 'ข้อเสนอแนะ',
                value: 'ข้อเสนอแนะ',
              },
              {
                label: 'ข้อร้องเรียน',
                value: 'ข้อร้องเรียน',
              },
              {
                label: 'นายหน้า/ ตัวแทน',
                value: 'นายหน้า/ ตัวแทน',
              },
              {
                label: 'ทิ้งคำถามไว้กับนูน',
                value: 'ทิ้งคำถามไว้กับนูน',
              },
            ]}
            themeColor="orange"
            placeholder="เลือก"
            label="เรื่องที่ต้องการจะติดต่อ"
          />
          <TextArea name="detail" label="รายละเอียด" type="textarea" />
          <TextInput name="name" label="ชื่อผู้ติดต่อ" type="text" />
          <TextInput name="email" label="อีเมล" type="text" />
          <TextInput
            name="phone"
            label="เบอร์ที่สะดวกให้ติดต่อกลับ"
            type="text"
            format={phoneFormatter}
            parse={formatParser}
          />
          <CustomButton htmlType="submit" icons={['fa', 'pen']}>
            ส่งอีเมล
          </CustomButton>
        </FieldContainer>
      </ContainerBody>
    </Container>
  </Loading>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.contactUs.isLoading,
      initialValues: {subject: url2Obj(props.location.search).subject || null},
    }),
    {contactUs},
  ),
  withState('isShowModal', 'setIsShowModal', false),
  reduxForm({
    form: 'ContactUs',
    enableReinitialize: true,
    validate,
  }),
)

export default enhancer(ContactUs)
