import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import {reducer as formReducer} from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import {all} from 'redux-saga/effects'

import auth, {watchAuthSaga} from './auth'
import landing, {watchLandingSaga} from './landing'
import product, {watchProductSaga} from './product'
import noonSquare, {watchNoonSquareSaga} from './noonSquare'
import notFound, {watch404Saga} from './404'
import contactUs, {watchContactUsSaga} from './contactUs'
import options, {watchOptionsSaga} from './options'
import profile, {watchProfileSaga} from './profile'
import fund, {watchFundSaga} from './fund'
import eventRegistering, {watchEventRegisteringSaga} from './eventRegistering'
import taxcal, {watchTaxcalSaga} from './taxcal'
import noonQuestion, {watchNoonQuestionSaga} from './noonQuestion'

const saga = createSagaMiddleware()

export function createMainReducer(asyncReducers) {
  return combineReducers({
    auth,
    landing,
    product,
    noonSquare,
    notFound,
    contactUs,
    options,
    profile,
    fund,
    taxcal,
    form: formReducer,
    eventRegistering,
    noonQuestion,
    ...asyncReducers,
  })
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const store = createStore(
  createMainReducer(),
  composeEnhancers(applyMiddleware(saga)),
)

function* rootSaga() {
  yield all([
    watchAuthSaga(),
    watchLandingSaga(),
    watchProductSaga(),
    watchNoonSquareSaga(),
    watch404Saga(),
    watchContactUsSaga(),
    watchOptionsSaga(),
    watchProfileSaga(),
    watchFundSaga(),
    watchEventRegisteringSaga(),
    watchTaxcalSaga(),
    watchNoonQuestionSaga(),
  ])
}

saga.run(rootSaga)

export default store
