import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {displayNumber} from '../../../core/helper'
import {Link} from '@reach/router'
import ModalCatalogue from '../../NoonSmart/Product/ModalCatalogue'

import Button from '../../share/Button'

import CompanyLogo from '../../../static/images/company-logo.jpg'

const RowContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147.8px;
  min-height: 50px;
  color: black;
  background-color: #e5e5e5;
  margin: 0 0 2px 0;
  font-size: 12px;
  margin-right: 5.32px;
  text-align: center;
  white-space: pre-wrap;
  padding: 0 10px;
  cursor: ${props => (props.isCursor ? 'pointer' : 'unset')};

  &.lifeCoverage {
    flex-direction: column;
  }

  &.dailyCommutation {
    flex-direction: column;
    padding: 0;
  }

  &.coverAcc {
    flex-direction: column;
  }

  &.cover3rd {
    flex-direction: column;
    padding: 0;
  }

  &.cover3rdAsset {
    flex-direction: column;
  }
`

const CustomLifeCoverage = styled.div`
  display: flex;
  &.coverMed {
    flex-direction: column;
    align-items: center;
  }
`

const CustomColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.opacityActive ? 'unset' : '0.5')};

  > div:nth-child(odd) :not(:first-child, :last-child) {
    background-color: rgba(229, 229, 229, 0.7);
  }
`

const HeadColumnContainer = styled.div`
  display: flex;
`

const HeadColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 147.91px;
  min-height: 100px;
  background-color: #265fa7;
  opacity: ${props => (props.opacityActive ? '1' : '0.5')};
  margin-right: 4.89px;
  border-radius: 5px 5px 0 0;
`

const CompanyImage = styled.img`
  display: ${props => (props.isShow ? 'flex' : 'none')};
  width: 20px;
  height: 20px;
  margin-bottom: 6.44px;
`

const HeadColumnText = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 0 27px;
`

const CloseIcon = styled(FontAwesomeIcon)`
  display: ${props => (props.closeActive ? 'block' : 'none')};
  position: absolute;
  right: 5px;
  top: -10px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(38, 95, 167, 0.5);
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;
`

const Unit = styled.span`
  display: ${props => (props.unitActive ? 'flex' : 'none')};
  font-size: 12px;
  text-align: center;
  margin: ${props => (props.isMargin ? '0 5px' : '0')};
`

const CustomButton = styled(Button)`
  margin: 15px;
`

const coverStatus = value => {
  if (value === 'Y') {
    return 'คุ้มครอง'
  } else {
    return value === 'N' ? 'ไม่คุ้มครอง' : ''
  }
}

const typeGarageStatus = value => {
  if (value === '0') {
    return 'ซ่อมอู่'
  } else {
    return value === '1' ? 'ซ่อมศูนย์' : ''
  }
}

const MotorColumnContent = ({
  productDetailsCompareList = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ],
  category,
  level,
  params,
  showModalId,
  setShowModalId,
}) => (
  <HeadColumnContainer>
    {productDetailsCompareList.map((value = {}) => (
      <CustomColumnContent
        key={value.id}
        opacityActive={value.companyOfficialLogoUrl}>
        <HeadColumn opacityActive={value.companyId}>
          {/* <CloseIcon
            icon={['fas', 'times-circle']}
            closeActive={value.companyOfficialLogoUrl}
          /> */}
          <CompanyImage
            src={value.companyOfficialLogoUrl}
            isShow={value.companyOfficialLogoUrl}
          />
          <HeadColumnText>{value.companyId}</HeadColumnText>
          {/* <HeadColumnText>{value.promotionCode}</HeadColumnText> */}
        </HeadColumn>
        <RowContent>{value.companyName}</RowContent>
        {/* <RowContent>{value.id ? value.promotionName || '-' : ''}</RowContent> */}
        <RowContent>
          {value.insureLevel === '30'
            ? '3+'
            : value.insureLevel === '20'
            ? '2+'
            : value.insureLevel}
        </RowContent>
        <RowContent>{typeGarageStatus(value.typeGarage)}</RowContent>
        <RowContent>
          {value.driver}
          <Unit unitActive={value.driver} isMargin={true}>
            คน
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.amountNet)}
          <Unit unitActive={value.companyId} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.cover)}
          <Unit unitActive={value.companyId} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.cover)}
          <Unit unitActive={value.companyId} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.coverLostFire)}
          <Unit unitActive={value.companyId} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>{coverStatus(value.coverFlood)}</RowContent>
        <RowContent>{coverStatus(value.coverTerrorism)}</RowContent>
        <RowContent>
          {value.id ? value.passengerAndDriverAmountCoverage || '-' : ''}
        </RowContent>
        <RowContent>
          {value.id ? value.passengerAndDriverAccident || '-' : ''}
        </RowContent>
        <RowContent>
          {value.id ? value.driver + '/' + value.passenger || '-' : ''}
          <Unit unitActive={value.driver} isMargin={true}>
            คน
          </Unit>
        </RowContent>
        <RowContent className="coverAcc">
          {value.id
            ? displayNumber(value.coverAcc) +
                '/' +
                displayNumber(value.coverAccPass) || '-'
            : ''}
          <Unit unitActive={value.coverAcc} isMargin={true}>
            บาท/ ครั้ง
          </Unit>
        </RowContent>
        <RowContent>
          <CustomLifeCoverage className="coverMed">
            {value.id
              ? displayNumber(value.coverMed) +
                  '/' +
                  displayNumber(value.coverMedPass) || '-'
              : ''}
            <Unit unitActive={value.coverMed} isMargin={true}>
              บาท/ คน
            </Unit>
          </CustomLifeCoverage>
        </RowContent>
        <RowContent className="coverAcc">
          {displayNumber(value.coverLegal)}
          <Unit unitActive={value.coverLegal} isMargin={true}>
            บาท/ ครั้ง
          </Unit>
        </RowContent>
        <RowContent className="cover3rd">
          <CustomLifeCoverage>
            {displayNumber(value.cover3rd)}
            <Unit unitActive={value.cover3rd} isMargin={true}>
              บาท/ คน
            </Unit>
          </CustomLifeCoverage>
          <CustomLifeCoverage>
            {displayNumber(value.cover3rdTime)}
            <Unit unitActive={value.cover3rdTime} isMargin={true}>
              บาท/ ครั้ง
            </Unit>
          </CustomLifeCoverage>
        </RowContent>
        <RowContent className="cover3rdAsset">
          {displayNumber(value.cover3rdAsset)}
          <Unit unitActive={value.cover3rdAsset} isMargin={true}>
            บาท/ ครั้ง
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.coverAcc2)}
          <Unit unitActive={value.coverAcc2} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>
          {value.coverAcc2Pass}
          <Unit unitActive={value.coverAcc2Pass} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        <RowContent>
          {displayNumber(value.deduct)}{' '}
          <Unit unitActive={value.deduct} isMargin={true}>
            บาท
          </Unit>
        </RowContent>
        {value.id && (
          <React.Fragment>
            <CustomButton
              onClick={() => {
                setShowModalId(value.id)
              }}
              icons={['fa', 'shopping-cart']}>
              สนใจซื้อ
            </CustomButton>
            <ModalCatalogue
              isShowModal={showModalId === value.id}
              setIsShowModal={() => setShowModalId('')}
              {...params}
              params={params}
              category={category}
              productName={value.companyName}
              {...value}
            />
          </React.Fragment>
        )}
      </CustomColumnContent>
    ))}
  </HeadColumnContainer>
)

const enhancer = compose(
  withState('showModalId', 'setShowModalId', ''),
  connect(
    (state, props) => ({
      productDetailsCompareList: state.product.productDetailsCompareList,
    }),
    {},
  ),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(MotorColumnContent)
