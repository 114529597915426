import {takeLatest, put, select} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {
  API_HOST,
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
} from '../../core/httpClient'
import {setDataProfile, getFavorite} from '../profile'

const SET_LOADING = 'SET_LOADING_ARTICLES'
const FETCH_ARTICLES = 'FETCH_ARTICLES'
const GET_ARTICLE = 'GET_ARTICLE'
const SET_DATA = 'SET_ARTICLES'
const ADD_COMMENT = 'ADD_COMMENT'
const ADD_REPLY_COMMENT = 'ADD_REPLY_COMMENT'
const EDIT_COMMENT = 'EDIT_COMMENT'
const DELETE_COMMENT = 'DELETE_COMMENT'
const SEND_EMAIL_NEWS = 'SEND_EMAIL_NEWS'
const FAVORITE_ARTICLE = 'FAVORITE_ARTICLE'
const UNFAVORITE_ARTICLE = 'UNFAVORITE_ARTICLE'
const SHARE_ARTICLE = 'SHARE_ARTICLE'

export const setLoading = Creator(SET_LOADING, 'isLoading')
export const fetchArticles = Creator(FETCH_ARTICLES, 'params')
export const setData = Creator(SET_DATA, 'data')
export const getArticle = Creator(GET_ARTICLE, 'id')
export const addComment = Creator(ADD_COMMENT, 'id', 'comment', 'callback')
export const addReplyComment = Creator(
  ADD_REPLY_COMMENT,
  'id',
  'commentId',
  'replyComment',
  'callback',
)
export const editComment = Creator(
  EDIT_COMMENT,
  'id',
  'commentId',
  'comment',
  'callback',
)
export const deleteComment = Creator(DELETE_COMMENT, 'id', 'commentId')
export const sendEmailNews = Creator(SEND_EMAIL_NEWS, 'data')
export const favoriteArticle = Creator(FAVORITE_ARTICLE, 'id', 'type', 'from')
export const unFavoriteArticle = Creator(
  UNFAVORITE_ARTICLE,
  'id',
  'type',
  'from',
)
export const shareArticle = Creator(SHARE_ARTICLE, 'id', 'shareType')

export function* fetchArticlesSaga({payload: {params = {}}}) {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}articles`
    const response = yield httpGet(url, {sortBy: 'new', ...params})
    yield put(setData({articles: response.data.data.articles || []}))
  } catch (error) {
    console.error('fetchArticlesSaga error', error)
  } finally {
    yield put(setLoading(false))
  }
}

const setSEO = article => {
  if (document) {
    document.title = article.topicName
    const metas = document.getElementsByTagName('meta')
    Object.values(metas).forEach(tag => {
      if (tag.outerHTML.search(`og:title`) !== -1) {
        tag.outerHTML = `<meta property="og:title" content="${article.topicName}">`
      }
      if (tag.outerHTML.search(`og:type`) !== -1) {
        tag.outerHTML = `<meta property="og:type" content="article">`
      }
      if (tag.outerHTML.search(`og:description`) !== -1) {
        tag.outerHTML = `<meta property="og:description" content="${article.description}">`
      }
      if (tag.outerHTML.search(`og:image`) !== -1) {
        tag.outerHTML = `<meta property="og:image" content="${article.imageArticle}">`
      }
      if (tag.outerHTML.search(`og:url`) !== -1) {
        tag.outerHTML = `<meta property="og:url" content="${
          window ? window.location.href : ''
        }">`
      }
      if (tag.outerHTML.search(`twitter:title`) !== -1) {
        tag.outerHTML = `<meta name="twitter:title" content="${article.topicName}">`
      }
      if (tag.outerHTML.search(`twitter:description`) !== -1) {
        tag.outerHTML = `<meta name="twitter:description" content="${article.description}">`
      }

      if (tag.name === 'description') {
        tag.content = article.description
      }
      if (tag.name === 'keywords') {
        tag.content = article.hashtags
          .map(hashtag =>
            hashtag.name[0] === '#'
              ? hashtag.name.replace('#', '')
              : hashtag.name,
          )
          .join(',')
      }
    })
  }
}

export function* getArticleSaga({payload: {id}}) {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}articles/${id}`
    const response = yield httpGet(url)
    yield put(setData({article: response.data.data}))
    setSEO(response.data.data.article)
  } catch (error) {
    console.error('getArticleSaga error')
    switch (error.response.status) {
      case 404:
        yield put(setData({error: 'ไม่มีบทความนี้'}))
        break
      default:
        yield put(setData({error: 'แย่จัง เหมือนว่าจะมีปัญหา ลองใหม่อีกทีนะ'}))
        break
    }
  } finally {
    yield put(setLoading(false))
  }
}

export function* addCommentSaga({payload: {id, comment, callback}}) {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}articles/${id}/comment`
    const response = yield httpPost(url, {comment: comment})
    yield put(setData({article: response.data.data}))
    callback()
  } catch (error) {
    console.error('addCommentSaga error', error)
  } finally {
    yield put(setLoading(false))
  }
}

export function* addReplyCommentSaga({
  payload: {id, commentId, replyComment, callback},
}) {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}articles/${id}/comment`
    const response = yield httpPost(url, {
      comment: replyComment,
      commentId: commentId,
    })
    yield put(setData({article: response.data.data}))
    callback()
  } catch (error) {
    console.error('addReplyCommentSaga error', error)
  } finally {
    yield put(setLoading(false))
  }
}

export function* editCommentSaga({
  payload: {id, commentId, comment, callback},
}) {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}articles/${id}/${commentId}`
    const response = yield httpPut(url, {comment: comment})
    yield put(setData({article: response.data.data}))
    callback()
  } catch (error) {
    console.error('editCommentSaga error', error)
  } finally {
    yield put(setLoading(false))
  }
}

export function* deleteCommentSaga({payload: {id, commentId}}) {
  yield put(setLoading(true))
  try {
    const url = `${API_HOST}articles/${id}/${commentId}`
    const response = yield httpDelete(url)
    yield put(setData({article: response.data.data}))
  } catch (error) {
    console.error('deleteCommentSaga error', error)
  } finally {
    yield put(setLoading(false))
  }
}

export function* sendEmailNewsSaga({payload: {data}}) {
  yield put(setData({sendEmail: true}))
}

export function* favoriteArticleSaga({payload: {id, type, from}}) {
  try {
    const url = `${API_HOST}favorites/${id}`
    yield httpPost(url, {favoritableType: 'Article'})
    if (from === 'square') {
      if (type === 'card') {
        const articles = yield select(state => state.noonSquare.articles)
        const modifiedArticles = articles.map(a =>
          a.id === id ? {...a, favorite: true} : a,
        )
        yield put(setData({articles: modifiedArticles}))
      } else if (type === 'detail') {
        const article = yield select(state => state.noonSquare.article)
        const modifiedArticle = {
          ...article,
          article: {
            ...article.article,
            favorite: true,
          },
        }
        yield put(setData({article: modifiedArticle}))
      }
    } else if (from === 'profile') {
      const articles = yield select(state => state.profile.articles)
      const modifiedArticles = articles.map(a =>
        a.id === id ? {...a, favorite: true} : a,
      )
      yield put(setDataProfile({articles: modifiedArticles}))
    }
    yield put(getFavorite())
  } catch (error) {
    console.error('favoriteArticleSaga error', error)
  }
}

export function* unFavoriteArticleSaga({payload: {id, type, from}}) {
  try {
    const url = `${API_HOST}favorites/${id}`
    yield httpDelete(url, {favoritableType: 'Article'})
    if (from === 'square') {
      if (type === 'card') {
        const articles = yield select(state => state.noonSquare.articles)
        const modifiedArticles = articles.map(a =>
          a.id === id ? {...a, favorite: false} : a,
        )
        yield put(setData({articles: modifiedArticles}))
      } else if (type === 'detail') {
        const article = yield select(state => state.noonSquare.article)
        const modifiedArticle = {
          ...article,
          article: {
            ...article.article,
            favorite: false,
          },
        }
        yield put(setData({article: modifiedArticle}))
      }
    } else if (from === 'profile') {
      const articles = yield select(state => state.profile.articles)
      const modifiedArticles = articles.map(a =>
        a.id === id ? {...a, favorite: false} : a,
      )
      yield put(setDataProfile({articles: modifiedArticles}))
    }

    yield put(getFavorite())
  } catch (error) {
    console.error('unFavoriteArticleSaga error', error)
  }
}

export function* shareArticleSaga({payload: {id, shareType}}) {
  try {
    const url = `${API_HOST}articles/${id}/share`
    yield httpPost(url, {shareType})
  } catch (error) {
    console.error('shareArticleSaga error', error)
  }
}

export function* watchNoonSquareSaga() {
  yield takeLatest(FETCH_ARTICLES, fetchArticlesSaga)
  yield takeLatest(GET_ARTICLE, getArticleSaga)
  yield takeLatest(ADD_COMMENT, addCommentSaga)
  yield takeLatest(ADD_REPLY_COMMENT, addReplyCommentSaga)
  yield takeLatest(EDIT_COMMENT, editCommentSaga)
  yield takeLatest(DELETE_COMMENT, deleteCommentSaga)
  yield takeLatest(SEND_EMAIL_NEWS, sendEmailNewsSaga)
  yield takeLatest(FAVORITE_ARTICLE, favoriteArticleSaga)
  yield takeLatest(UNFAVORITE_ARTICLE, unFavoriteArticleSaga)
  yield takeLatest(SHARE_ARTICLE, shareArticleSaga)
}

const initial = {
  isLoading: false,
  articles: [],
  article: {},
  sendEmail: false,
}

export default createReducer(initial, state => ({
  [SET_LOADING]: ({isLoading}) => ({
    ...state,
    isLoading: isLoading,
  }),
  [SET_DATA]: ({data}) => ({...state, ...data}),
}))
