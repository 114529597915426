import React from 'react'
import styled from 'react-emotion'
import {Switch as AntdSwitch} from 'antd'

const CustomSwitch = styled(AntdSwitch)``

const GenderButtonContainer = styled.div`
  display: flex;
`

const GenderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  color: black;
  background-color: #ffb933;
  margin-right: 1px;
  font-size: 16px;
  opacity: ${props => (props.isActive ? '1' : '0.5')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    opacity: ${props => (props.disabled ? 'none' : '1')};
  }

  &.male {
    border-radius: 20px 0 0 20px;
  }

  &.female {
    border-radius: 0 20px 20px 0;
  }
`

const Switch = ({value, onChange, disabled, lang}) => (
  <GenderButtonContainer>
    <GenderButton
      disabled={disabled}
      isActive={value === 'male'}
      className="male"
      onClick={() => (disabled ? '' : onChange('male'))}>
      {lang === 'th' ? 'ชาย' : 'Male'}
    </GenderButton>
    <GenderButton
      disabled={disabled}
      isActive={value === 'female'}
      className="female"
      onClick={() => (disabled ? '' : onChange('female'))}>
      {lang === 'th' ? 'หญิง' : 'Female'}
    </GenderButton>
  </GenderButtonContainer>
)

export default Switch
