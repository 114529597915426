import React from 'react'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FundPricesChart from './FundPricesChart'
import {getThaiDate, displayCurrency} from '../../../../core/helper'
import DateLable from '../DateLabel'

const WrapDetails = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`
const WraperChart = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 30px;
  align-items: center;
  @media (max-width: 1024px) {
    width: 746px;
  }
  @media (max-width: 500px) {
    width: calc(100vw - 10%);
  }
`
const ColDetails = styled.div`
  padding: 0 20px;
  :first-child {
    font-weight: 600;
  }
  color: #333333;
  font-size: 16px;
  @media (max-width: 500px) {
    width: 100%;
  }
`
const LabelDetails = styled.div`
  margin-bottom: 15px;
  width: 276px;
  :first-child {
    font-weight: 600;
    margin-right: 100px;
  }
  @media (max-width: 1024px) {
    width: 276px;
    :first-child {
      margin-right: 50px;
    }
  }
  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 0;
    margin-right: 0;
    :last-child {
      margin-bottom: 15px;
    }
  }
`
const DownloadIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`
const CustomTagA = styled.a`
  color: #333333;
`

export default props => {
  const {fund} = props
  const {
    secPolicyType,
    fundType,
    aimcCategory,
    registeredDate,
    factsheetUrl,
    buyNav,
    sellNav,
    updatedAt,
  } = fund
  return (
    <WraperChart>
      <DateLable date={updatedAt} />
      <FundPricesChart fund={fund} />
      <ColDetails>
        <WrapDetails>
          <LabelDetails>ประเภทกองทุนตามนโยบายกองทุน</LabelDetails>
          <LabelDetails>{secPolicyType || 'N/A'}</LabelDetails>
        </WrapDetails>
        <WrapDetails>
          <LabelDetails>ประเภทกองทุน</LabelDetails>
          <LabelDetails>{fundType || 'N/A'}</LabelDetails>
        </WrapDetails>
        <WrapDetails>
          <LabelDetails>ประเภทกองทุน (AIMC)</LabelDetails>
          <LabelDetails>{aimcCategory || 'N/A'}</LabelDetails>
        </WrapDetails>
        <WrapDetails>
          <LabelDetails>วันที่จดทะเบียนกองทุน</LabelDetails>
          <LabelDetails>{getThaiDate(registeredDate, 'LL')}</LabelDetails>
        </WrapDetails>
        <WrapDetails>
          <LabelDetails>ข้อมูลสำคัญการลงทุน (Fund Factsheet)</LabelDetails>
          <LabelDetails>
            <CustomTagA href={factsheetUrl} target="_blank">
              <DownloadIcon icon={['fa', 'file-download']} />
              ดาวน์โหลด
            </CustomTagA>
          </LabelDetails>
        </WrapDetails>
        <WrapDetails>
          <LabelDetails>ราคาขาย (Offer)</LabelDetails>
          <LabelDetails>
            {sellNav ? displayCurrency(sellNav, 4) : 'N/A'}
          </LabelDetails>
        </WrapDetails>
        <WrapDetails>
          <LabelDetails>ราคารับซื้อคืน (Bid)</LabelDetails>
          <LabelDetails>
            {buyNav ? displayCurrency(buyNav, 4) : 'N/A'}
          </LabelDetails>
        </WrapDetails>
      </ColDetails>
    </WraperChart>
  )
}
