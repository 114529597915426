import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled from 'react-emotion'
import {breakpoints} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'

import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {
  displayNumber,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'

import Panel from '../PanelForm'
import _ from 'lodash'
import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
  Summary,
  Label,
  LabelSummary,
} from '../ShareFieldForm'

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #6a94cb;
  margin-bottom: 30px;
`

const CustomText = styled.b`
  font-size: 20px;
`

const SummaryAdvance = ({summary}) => (
  <CustomText>{displayNumber(Math.round(summary))} บาท</CustomText>
)
const Nothing = () => <div />

const DebtProtectionForm = ({
  onDebtSummary,
  debtSummary,
  onPropertySummary,
  propertySummary,
  windowWidth,
}) => (
  <Panel>
    <div>
      <Header>ด้านภาระหนี้สิน</Header>
      <FieldContainer>
        <TextInput
          name="houseRemain"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ภาระสินเชื่อบ้านที่อยู่อาศัยคงค้าง (บาท)'
              : 'ภาระสินเชื่อบ้านที่อยู่อาศัยคงค้าง'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onDebtSummary}
        />

        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="autoRemain"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ภาระสินเชื่อรถยนต์คงค้าง (บาท)'
              : 'ภาระสินเชื่อรถยนต์คงค้าง'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onDebtSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="personRemain"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ภาระสินเชื่อส่วนบุคคลคงค้าง (บาท)'
              : 'ภาระสินเชื่อส่วนบุคคลคงค้าง'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onDebtSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="businessRemain"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ภาระสินเชื่อธุรกิจคงค้าง (บาท)'
              : 'ภาระสินเชื่อธุรกิจคงค้าง'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onDebtSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="etcRemain"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ภาระหนี้สินอื่นๆ คงค้าง (บาท)'
              : 'ภาระหนี้สินอื่นๆ คงค้าง'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onDebtSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'รวมหนี้สิน (บาท)'
              : 'รวมหนี้สิน'}
          </Label>
          <LabelSummary>{displayNumber(Math.round(debtSummary))}</LabelSummary>
        </Summary>
        <Unit>บาท</Unit>
      </FieldContainer>
      <Divider />
      <Header>ด้านสินทรัพย์</Header>
      <FieldContainer>
        <TextInput
          name="deposit"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เงินฝากออมทรัพย์/ ฝากประจำ/ เงินฝากอื่นๆ (บาท)'
              : 'เงินฝากออมทรัพย์/ ฝากประจำ/ เงินฝากอื่นๆ'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onPropertySummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="liquidityAsset"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'สินทรัพย์สภาพคล่องอื่นๆที่เตรียมไว้ชำระหนี้ (บาท)'
              : 'สินทรัพย์สภาพคล่องอื่นๆที่เตรียมไว้ชำระหนี้'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onPropertySummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="sumInsurance"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ทุนประกันชีวิตที่มีอยู่แล้ว (บาท)'
              : 'ทุนประกันชีวิตที่มีอยู่แล้ว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onPropertySummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'รวมสินทรัพย์เพื่อการชำระหนี้ (บาท)'
              : 'รวมสินทรัพย์เพื่อการชำระหนี้'}
          </Label>
          <LabelSummary>
            {displayNumber(Math.round(propertySummary))}
          </LabelSummary>
        </Summary>
        <Unit>บาท</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <TextFooter>
        <SearchFactor
          type="DebProtection"
          windowWidth={windowWidth}
          searchValue={debtSummary - propertySummary}
        />
        <CustomField name="summaryLoads" component={Nothing} />
        <CustomField name="summaryAssets" component={Nothing} />
        <CustomField
          name="searchValue"
          component={SummaryAdvance}
          summary={debtSummary - propertySummary}
        />
      </TextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={debtSummary - propertySummary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      debt: selector(
        state,
        'houseRemain',
        'autoRemain',
        'personRemain',
        'businessRemain',
        'etcRemain',
      ),
      property: selector(state, 'deposit', 'liquidityAsset', 'sumInsurance'),
      summaryLoads: selector(state, 'summaryLoads'),
      summaryAssets: selector(state, 'summaryAssets'),
    }),
    {change},
  ),
  withState('debtSummary', 'setDebtSummary', 0),
  withState('propertySummary', 'setPropertySummary', 0),
  withHandlers({
    onDebtSummary: ({
      debt,
      setDebtSummary,
      change,
      summaryLoads,
      summaryAssets,
    }) => () => {
      let summary = 0
      _.mapValues(debt, num => (summary += convertNaNtoZero(num)))
      setDebtSummary(summary)
      change('Planning', 'summaryLoads', summary)
      change('Planning', 'searchValue', summary - summaryAssets)
    },
    onPropertySummary: ({
      property,
      setPropertySummary,
      change,
      summaryLoads,
      summaryAssets,
    }) => () => {
      let summary = 0
      _.mapValues(property, num => (summary += convertNaNtoZero(num)))
      setPropertySummary(summary)

      change('Planning', 'summaryAssets', summary)
      change('Planning', 'searchValue', summaryLoads - summary)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onDebtSummary()
      this.props.onPropertySummary()
    },
  }),
)

export default enhancer(DebtProtectionForm)
