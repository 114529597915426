import React from 'react'
import Modal from '../../../share/Modal'
import {Layout, Title, SendButton} from './TermAndConditionModal'
import styled from 'react-emotion'

const CustomModal = styled(Modal)`
  max-width: 500px;
`

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  font-size: 14px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
`

const ErrorModal = props => (
  <CustomModal
    isShowModal={props.isShowModal}
    setIsShowModal={props.setIsShowModal}>
    <Layout>
      <Title>ข้อมูลไม่ถูกต้อง</Title>
      <Body>{props.children}</Body>
      <SendButton onClick={props.onConfirm}>กลับไปแก้ไข</SendButton>
    </Layout>
  </CustomModal>
)

export default ErrorModal
