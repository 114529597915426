import React from 'react'
import {
  withCaption,
  withDisabled,
  withSpidus,
  withValue,
  omitProps,
} from '../enhances/index'
import styled, {css} from 'react-emotion'
import {compose, branch, withHandlers, defaultProps, withProps} from 'recompose'
import classnames from 'classnames'

const WarperOptions = styled.div`
  display: flex;
`
const CustomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  color: black;
  background-color: #ffb933;
  margin-right: 1px;
  font-size: 16px;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }

  :first-child {
    border-radius: 20px 0 0 20px;
  }

  :last-child {
    border-radius: 0 20px 20px 0;
  }
`

const disabledStyled = css`
  pointer-events: none;
  opacity: 0.8;
`

const isActiveStyled = css`
  opacity: 1;
`

const ReadOnlyButtonGroup = styled.div`
  text-align: right;
`

const ButtonGroup = props =>
  props.readOnly ? (
    <ReadOnlyButtonGroup>{props.value}</ReadOnlyButtonGroup>
  ) : (
    <WarperOptions>
      {props.options.map(({label, value}) => (
        <CustomButton
          key={`${label}-${value}`}
          className={classnames({
            [props.className]: !!props.className,
            [isActiveStyled]: value === props.value,
          })}
          onClick={() => props.onChange(value)}>
          {label}
        </CustomButton>
      ))}
    </WarperOptions>
  )

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption(),
  withDisabled(disabledStyled),
  defaultProps({
    options: [],
  }),
  withProps(props => {
    const className = classnames({
      [props.customStyle]: !!props.customStyle,
    })
    return {...props, ...className, className}
  }),
  withHandlers({
    onChange: props => value => {
      if (props.deselect === true && value === props.value) {
        value = null
      }
      props.onChange && props.onChange(value)
      props.onCategorySelected && props.onCategorySelected(value)
      props.onBlur && props.onBlur(value)
    },
  }),
  omitProps('customStyle'),
)
export default enhance(ButtonGroup)
