import React from 'react'
import Modal from '../../share/Modal'
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  defaultProps,
} from 'recompose'
import styled from 'react-emotion'
import {httpGet, API_HOST} from '../../../core/httpClient'
import {withForm} from '../enhancers'
import {
  Field,
  Button,
  Switch,
  Currency,
  Dropdown,
  Percent,
} from '../components/index'

const WrapContentModal = styled.form`
  padding: 60px;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 425px) {
    padding: 30px;
  }
`
const Title = styled.h2`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
`
const ConfirmButton = styled(Button)`
  margin-top: 20px;
`

const EditModal = props => (
  <Modal isShowModal={props.show} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <Title>แก้ไขข้อมูล</Title>
      <Field
        name="gender"
        caption="เพศ"
        validate="required"
        component={Switch.Gender}
        marginBottom="16px"
      />
      <Field
        name="age"
        caption="อายุ"
        component={Currency}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="retireAge"
        caption="คาดว่าจะเกษียณเมื่ออายุ"
        component={Currency}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />

      <Field
        name="salary"
        caption="รายได้/ เดือน"
        component={Currency}
        placeholder="กรุณากรอก"
        marginBottom="16px"
      />
      <Field
        name="revenueGrowth"
        caption="อัตราการเพิ่มขึ้นของรายได้/ ปี (%)"
        component={Percent}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="expense"
        caption="ค่าใช้จ่ายส่วนตัว/ เดือน"
        component={Currency}
        placeholder="กรุณากรอก"
        marginBottom="16px"
      />
      <Field
        name="liabilities"
        caption="หนี้สินทั้งหมดที่มีอยู่ในปัจจุบัน"
        component={Currency}
        placeholder="กรุณากรอก"
        marginBottom="16px"
      />

      <Field
        name="hasSocialSecurity"
        caption="สิทธิการรักษาพยาบาลประกันสังคม"
        component={Dropdown}
        placeholder="กรุณาเลือก"
        options={props.hasSocialSecurityOptions}
        marginBottom="16px"
      />
      <Field
        name="providentFund"
        caption="อัตราเงินสะสมกองทุนสำรองเลี้ยงชีพ (%) "
        component={Percent}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="hospitalId"
        caption="โรงพยาบาลที่รักษาเป็นประจำ"
        component={Dropdown}
        placeholder="กรุณาเลือก"
        options={props.hospitalOptions}
        marginBottom="16px"
        searchable
      />
      <Field
        name="injuredFrequency"
        caption="ประวัติการเจ็บป่วยโดยไม่เข้า รพ. (จำนวนครั้ง/ ปี)"
        component={Currency}
        placeholder="กรุณากรอก"
        min={0}
        max={100}
        marginBottom="16px"
      />
      <Field
        name="investmentRisk"
        caption="ระดับความเสี่ยงในการลงทุนที่รับได้"
        component={Dropdown}
        options={props.investmentRiskOptions}
        placeholder="กรุณาเลือก"
        marginBottom="16px"
      />
      <ConfirmButton onClick={props.onSubmit} disabled={props.invalid}>
        ประมวลผล
      </ConfirmButton>
    </WrapContentModal>
  </Modal>
)

const enhancer = compose(
  withState('hospitalOptions', 'setHospitalsOptions', []),
  withState('investmentRiskOptions', 'setInvestmentRiskOptions', []),
  defaultProps({
    hasSocialSecurityOptions: [
      {label: 'มี', value: true},
      {label: 'ไม่มี', value: false},
    ],
  }),
  withForm({
    form: 'EditNoonQuestionForm',
    validate: values => {
      const errors = {}

      if (!values.gender) {
        errors.gender = 'กรุณาระบุเพศ'
      }
      if (!values.age && values.age !== 0) {
        errors.age = 'กรุณาระบุอายุ'
      }
      if (!values.retireAge && values.retireAge !== 0) {
        errors.retireAge = 'กรุณาระบุอายุที่คาดว่าเกษียณ'
      }
      if (values.age > values.retireAge) {
        errors.retireAge = 'อายุเกษียณต้องมากกว่าอายุปัจจุบัน'
      }
      if (!values.salary && values.salary !== 0) {
        errors.salary = 'กรุณาระบุรายได้ต่อเดือน'
      }
      if (!values.revenueGrowth && values.revenueGrowth !== 0) {
        errors.revenueGrowth = 'กรุณาระบุอัตราการเพิ่มขึ้นของรายได้ต่อปี (%)'
      }
      if (!values.expense && values.expense !== 0) {
        errors.expense = 'กรุณาระบุค่าใช้จ่ายส่วนตัวต่อเดือน'
      }
      if (!values.liabilities && values.liabilities !== 0) {
        errors.liabilities = 'กรุณาระบุหนี้สินทั้งหมดที่มีอยู่'
      }
      if (!values.hasSocialSecurity && values.hasSocialSecurity !== false) {
        errors.hasSocialSecurity = 'กรุณาระบุสิทธิประกันสังคม'
      }
      if (!values.providentFund && values.providentFund !== 0) {
        errors.providentFund = 'กรุณาระบุอัตราเงินสะสมกองทุนเลี้ยงชีพ (%)'
      }
      if (!values.hospitalId) {
        errors.hospitalId = 'กรุณาระบุ รพ. ที่เลือกใช้บริการ'
      }
      if (!values.injuredFrequency && values.injuredFrequency !== 0) {
        errors.injuredFrequency = 'กรุณาระบุประวัติการเจ็บป่วยโดยไม่เข้า รพ.'
      }
      if (!values.investmentRisk) {
        errors.investmentRisk = 'กรุณาระบุความเสี่ยงในการลงทุนที่รับได้'
      }
      if (!values.insuranceType) {
        errors.insuranceType = 'กรุณาเลือกแบบความคุ้มครอง'
      }

      return errors
    },
  }),
  withHandlers({
    onClose: props => () => {
      props.onClose()
      props.reset()
    },
  }),
  withHandlers({
    onSubmit: props =>
      props.handleSubmit(async values => {
        await props.onSubmit(values)
        props.onClose()
      }),
  }),
  lifecycle({
    async componentDidMount() {
      const response = await httpGet(`${API_HOST}noon_questions/options`)

      const hospitalOptions = response.data.data.hospitals.map(hospital => ({
        value: hospital.id,
        label: hospital.name,
      }))
      const investmentRiskOptions = Object.entries(
        response.data.data.investmentRisk,
      ).map(obj => ({
        label: obj[1].label,
        value: obj[0],
      }))

      this.props.setHospitalsOptions(hospitalOptions)
      this.props.setInvestmentRiskOptions(investmentRiskOptions)
    },
  }),
)

export default enhancer(EditModal)
