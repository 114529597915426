import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import {Button} from '../../components'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
`
const SendButton = styled(Button)`
  margin-top: 25px;
`
const Title = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
`
const Body = styled.div``

const TermAndCondition = props => (
  <Layout>
    <Title>ข้อตกลงการใช้งาน</Title>
    <Body>
      <div style={{fontSize: 14}}>
        <h4 style={{fontSize: 18}}>
          การใช้บริการเตรียมข้อมูลสำหรับยื่นภาษีผ่าน Noon
          ผู้ใช้งานต้องยอมรับข้อตกลงและเงื่อนไขการใช้บริการดังต่อไปนี้
        </h4>
        <div>
          <u>
            (Noon
            จะไม่ส่งข้อมูลให้กรมสรรพากรจนกว่าคุณจะกดยอมรับข้อตกลงและเงื่อนไขการใช้งาน)
          </u>
        </div>
        <br />
        <div>
          1. ผู้ใช้งานยอมรับว่า Noon
          เป็นเพียงผู้เตรียมข้อมูลสำหรับการยื่นภาษีให้ผู้ใช้งาน Noon
          ไม่สามารถยืนยันความถูกต้องของข้อมูลที่ผู้ใช้งานกรอกเองได้
          ผู้ใช้งานต้องตรวจสอบความถูกต้องของข้อมูลด้วยตนเองทุกครั้งก่อนยื่นภาษี
        </div>
        <br />
        <div>
          2.
          ผู้ใช้งานต้องลงทะเบียนใช้งานระบบการยื่นภาษีผ่านระบบอิเล็กทรอนิกส์ของกรมสรรพากร
          (RD E-Filing) และยอมรับข้อตกลงการยื่นภาษีและเงื่อนไขการใช้บริการของแอป
          RD Smart Tax ด้วย
        </div>
        <br />
        <div>
          3. ผู้ใช้งานยินยอมให้ Noon เปิดเผยข้อมูลที่กรอกไว้ในระบบ Noon
          ให้เข้าสู่ระบบกรมสรรพากรเพื่อประโยชน์สำหรับการยื่นภาษีผ่านระบบของกรมสรรพากร
        </div>
        <br />
        <div>
          4.
          ผู้ใช้งานยอมรับว่าข้อมูลที่ปรากฏเมื่อยื่นภาษีจริงบนระบบของกรมสรรพากรอาจแตกต่างจากที่ปรากฏอยู่บนระบบ
          Noon ซึ่งอาจเกิดจากการตรวจสอบเงื่อนไขการคำนวณภาษีของระบบกรมสรรพากร
          อาทิ การตรวจสอบอายุบิดามารดาจากทะเบียนราษฏร์ กรมการปกครอง
          กระทรวงมหาดไทย รวมถึงข้อมูลอื่นๆ
          ที่ปรากฏอยู่ในเครือข่ายฐานข้อมูลของกรมสรรพากร
        </div>
        <br />
        <div>
          5. ผู้ใช้งานยอมรับการใช้งานบนระบบ Noon
          ยังไม่ใช่การยื่นภาษีที่สมบูรณ์ตามกฎหมาย ผู้ใช้งานยังต้องนำตัวเลข 6
          หลักที่แสดงบนระบบ Noon ไปยืนยันการยื่นภาษีด้วยตนเองบนแอป RD Smart Tax
          และดำเนินการตามกระบวนการในแอป RD Smart Tax
          จึงจะเป็นการยื่นภาษีที่สมบูรณ์ตามกฎหมาย
        </div>
        <br />
        <div>
          6. ในกรณีที่เกิดเหตุขัดข้อง หรือเหตุสุดวิสัย
          จนไม่สามารถใช้บริการเตรียมข้อมูลสำหรับยื่นภาษีผ่าน Noon ได้
          ผู้ใช้งานตกลงที่จะดำเนินการยื่นภาษีด้วยวิธีอื่นตามช่องทางที่กรมสรรพากรกำหนดด้วยตนเองต่อไป
        </div>
        <br />
        <div>
          7. Noon
          ขอสงวนสิทธิ์ในการระงับการให้บริการเตรียมข้อมูลสำหรับยื่นภาษีผ่าน Noon
          นี้ให้แก่ผู้ใช้งานรายใดรายหนึ่งหรือทุกรายเมื่อพ้นกำหนดการยื่นภาษี
          หรือด้วยเหตุจำเป็นอื่นใดตามที่ Noon กำหนดโดยไม่ได้แจ้งให้ทราบล่วงหน้า
        </div>
      </div>
    </Body>
    <SendButton onClick={props.onSubmit}>ยอมรับข้อตกลง</SendButton>
  </Layout>
)

const enhancer = compose()

export default enhancer(TermAndCondition)
