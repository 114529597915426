import {withProps} from 'recompose'

const transformNonStandardProps = ({dispatch, ...rest}) =>
  Object.entries(rest || {}).reduce(
    (memo, [key, value]) => ({
      ...memo,
      [key.toLowerCase()]: typeof value === 'boolean' ? (value ? 1 : 0) : value,
    }),
    {},
  )

const withField = () =>
  withProps(({input = {}, meta = {}, ...rest}) => {
    const {form, ...restMeta} = meta || {}
    return {
      ...transformNonStandardProps(restMeta),
      ...rest,
      ...input,
      'data-input-name': input.name || rest.name,
    }
  })

export default withField
