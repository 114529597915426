import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Tooltip from '../../share/Tooltip'

import {responsive} from '../../../core/style'

const RowContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 99.91px;

  > div:first-child {
    border-radius: 5px 0 0 0;
    overflow: hidden;
  }

  > div:last-child {
    border-radius: 0 0 0 5px;
    margin-bottom: 27px;
    overflow: hidden;
  }

  ${props =>
    responsive.tablet(css`
      padding-left: 20px;
    `)};
`
const RowHeadContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  min-height: 50px;
  color: white;
  background-color: #265fa7;
  margin: 0 0 2px 0;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;

  &.insurerRight,
  &.insurerWrong {
    padding: 0 40px;
  }
  &.passengerAndDriverAmountCoverage {
    padding: 0 30px;
  }
  &.passengerAndDriverAccident {
    padding: 0 25px;
  }
  &.passengerAndDriverMedicalFee {
    padding: 0 30px;
  }

  &.driverCoverage {
    padding: 0 20px;
  }
  &.passengerCoverage {
    padding: 0 20px;
  }

  ${props =>
    responsive.mobile(css`
      width: 160px;

      &.passengerAndDriverAmountCoverage,
      &.passengerAndDriverAccident,
      &.passengerAndDriverMedicalFee,
      &.driverCoverage,
      &.passengerCoverage {
        padding: 0;
      }
    `)};
`
const CustomIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  color: #6a94cb;
  right: 0;
  margin-right: 15px;
`
const CustomTooltip = styled(Tooltip)`
  &.ant-tooltip {
    /* max-width: unset; */
    min-width: 465px;
    > .ant-tooltip-content {
      > .ant-tooltip-arrow {
        border-right-color: #7f7f7f;
      }
      > .ant-tooltip-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #7f7f7f;
      }
    }
  }
`
const lifeRowHeadContent = [
  {id: 1, headName: 'NOON STAR', key: 'noonStar', tooltip: ''},
  {id: 2, headName: 'ชื่อบริษัทประกัน', key: 'companyName', tooltip: ''},
  {id: 3, headName: 'ชื่อแบบประกัน', key: 'productName', tooltip: ''},
  {id: 4, headName: 'ชนิดแบบประกัน', key: 'productType', tooltip: ''},
  {id: 5, headName: 'จำนวนเงินเอาประกันภัย', key: 'budget', tooltip: ''},
  {id: 6, headName: 'เบี้ยประกันรายปี', key: 'yearPremium', tooltip: ''},
  {id: 7, headName: 'ระยะเวลาชำระเบี้ย', key: 'payPeriod', tooltip: ''},
  {id: 8, headName: 'ระยะเวลาคุ้มครอง', key: 'coveragePeriod', tooltip: ''},
  {
    id: 9,
    headName: 'รายละเอียดเงื่อนไขความคุ้มครอง',
    key: 'coverageDetails',
    tooltip: '',
  },
  {
    id: 10,
    headName: 'เงินคืนระหว่างสัญญา',
    key: 'cashbackDuringContact',
    tooltip: '',
  },
  {
    id: 11,
    headName: 'เงินคืนเมื่อครบสัญญา',
    key: 'cashbackEndContact',
    tooltip: '',
  },
  {id: 12, headName: 'เงินปันผล', key: 'dividend', tooltip: ''},
  {id: 13, headName: 'สิทธิในการลดหย่อนภาษี', key: 'taxReduce', tooltip: ''},
  {id: 14, headName: 'NOON SCORE', key: 'noonScore', tooltip: ''},
  {
    id: 15,
    headName: 'E[DCR]',
    key: 'EDCR',
    tooltip: 'อัตราส่วนความคุ้มครองคาดหวังเทียบอัตรามรณะ',
  },
  {
    id: 16,
    headName: 'E[DCR] Percentile',
    key: 'EDCRP',
    tooltip: 'เทียบกับแบบประกันชนิดเดียวกัน',
  },
  {id: 17, headName: 'CR', key: 'CR', tooltip: 'อัตราส่วนความคุ้มครองทั่วไป'},
  {
    id: 18,
    headName: 'MBMIRR',
    key: 'MBMIRR',
    tooltip: 'อัตราผลตอบแทนภายในคาดหวังเทียบอัตรามรณะ',
  },
  {
    id: 19,
    headName: 'MBMIRR Percentile',
    key: 'MBMIRRP',
    tooltip: 'เทียบกับแบบประกันชนิดเดียวกัน',
  },
  {
    id: 20,
    headName: 'MIRR',
    key: 'MIRR',
    tooltip: 'อัตราผลตอบแทนภายในที่ได้รับการแก้ไข',
  },
  {id: 21, headName: 'IRR', key: 'IRR', tooltip: 'อัตราผลตอบแทนภายในทั่วไป'},
  {id: 22, headName: 'INSURER SCORE', key: 'insurerScore'},
  {id: 23, headName: 'CAR SCORE', key: 'carScore'},
  {id: 24, headName: 'ILR SCORE', key: 'ileScore'},
  {id: 25, headName: 'ปี (พ.ศ.)', key: 'year'},
  {id: 26, headName: 'ไตรมาส', key: 'quarter'},
]

const motorRowHeadContent = [
  {id: 1, headName: 'ชื่อบริษัทประกัน', key: 'companyName', tooltip: ''},
  // {id: 2, headName: 'ชื่อแบบประกัน', key: 'productName', tooltip: ''},
  {id: 3, headName: 'ชนิดแบบประกัน', key: 'productType', tooltip: ''},
  {id: 4, headName: 'เงื่อนไขการซ่อม', key: 'fixCondition', tooltip: ''},
  {id: 5, headName: 'ระบุผู้ขับขี่', key: 'driverSpecify', tooltip: ''},
  {id: 6, headName: 'เบี้ยประกันรายปี', key: 'yearPremium', tooltip: ''},
  {id: 7, headName: 'ทุนประกันภัย', key: 'insuranceBudget', tooltip: ''},
  {
    id: 8,
    headName: 'คุ้มครองความเสียหายรถยนต์',
    key: 'carDamageCoverage',
    tooltip: '',
  },
  {
    id: 9,
    headName: 'คุ้มครองกรณีสูญหายและไฟไหม้',
    key: 'carDamageAndFireCoverage',
    tooltip: '',
  },
  {
    id: 19,
    headName: 'คุ้มครองน้ำท่วม',
    key: 'floodingCoverage',
  },
  {
    id: 20,
    headName: 'คุ้มครองภัยก่อการร้าย',
    key: 'criminalCoverage',
  },
  {
    id: 10,
    headName: 'คุ้มครองกรณีผู้ขับขี่เสียชีวิต',
    key: 'driverDeathCoverage',
    tooltip: '',
  },
  {
    id: 11,
    headName: 'คุ้มครองกรณีผู้โดยสารเสียชีวิต',
    key: 'passengerDeathCoverage',
    tooltip: '',
  },
  {
    id: 12,
    className: 'passengerAndDriverAmountCoverage',
    headName: 'จำนวนผู้ขับขี่/ ผู้โดยสาร ที่คุ้มครอง',
    key: 'passengerAndDriverAmountCoverage',
    tooltip: '',
  },
  {
    id: 13,
    className: 'passengerAndDriverAccident',
    headName: 'อุบัติเหตุส่วนบุคคลผู้ขับขี่/ ผู้โดยสาร',
    key: 'passengerAndDriverAccident',
    tooltip: '',
  },
  {
    id: 14,
    className: 'passengerAndDriverMedicalFee',
    headName: 'ค่ารักษาพยาบาลผู้ขับขี่/ ผู้โดยสาร',
    key: 'passengerAndDriverMedicalFee',
    tooltip: '',
  },
  {
    id: 15,
    headName: 'ประกันตัวผู้ขับขี่',
    key: 'driverBail',
    tooltip: '',
  },
  {
    id: 16,
    headName: 'คุ้มครองความรับผิดต่อชีวิต ร่างกาย',
    key: 'lifeCoverage',
    tooltip: '',
  },
  {
    id: 17,
    headName: 'คุ้มครองทรัพย์สินบุคคล ภายนอก',
    key: 'assetCoverage',
    tooltip: '',
  },
  {
    id: 21,
    className: 'driverCoverage',
    headName: 'คุ้มครอง ทุพลภาพชั่วคราว ผู้ขับขี่',
    key: 'driverCoverage',
  },
  {
    id: 23,
    className: 'passengerCoverage',
    headName: 'คุ้มครอง ทุพลภาพชั่วคราว ผู้โดยสาร',
    key: 'passengerCoverage',
  },
  {
    id: 22,
    headName: 'ค่าเสียหายส่วนแรก',
    key: 'firstDamaged',
  },
]

const RowTopic = ({category}) => (
  <RowContentContainer>
    {category === 'life' &&
      lifeRowHeadContent.map(value => (
        <RowHeadContent key={value.id}>
          {value.headName}
          {value.tooltip ? (
            <CustomTooltip placement="right" title={value.tooltip}>
              <CustomIcon icon={['fas', 'info-circle']} />
            </CustomTooltip>
          ) : (
            ''
          )}
        </RowHeadContent>
      ))}
    {category === 'motor' &&
      motorRowHeadContent.map(value => (
        <RowHeadContent key={value.id} className={value.className}>
          {value.headName}
          {value.tooltip ? (
            <CustomTooltip placement="right" title={value.tooltip}>
              <CustomIcon icon={['fas', 'info-circle']} />
            </CustomTooltip>
          ) : (
            ''
          )}
        </RowHeadContent>
      ))}
  </RowContentContainer>
)

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(RowTopic)
