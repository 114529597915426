import React from 'react'
import {compose, withState} from 'recompose'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'
import {obj2Url, url2Obj} from '../../core/helper'
import CustomField from '../share/Field'

import {responsive} from '../../core/style'
import {sort} from './options'

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 0 15px;

  ${props =>
    responsive.mobile(css`
      padding: 0 20px;
      width: 100%;
    `)};
`

const SearchBox = styled(CustomField)`
  margin: 0;

  input {
    padding-left: 61px;
    font-size: 16px;
    color: #265fa7;
    text-align: left;
    background-color: white;
    border-radius: 5px 0 0 5px;
    width: 488px;
    height: 50px;
    border: none;

    ::-webkit-input-placeholder {
      color: #265fa7;
      opacity: 0.6;
    }
    :-ms-input-placeholder {
      color: #265fa7;
      opacity: 0.6;
    }
    ::-moz-placeholder {
      color: #265fa7;
      opacity: 0.6;
    }
    :-moz-placeholder {
      color: #265fa7;
      opacity: 0.6;
    }
  }

  ${props =>
    responsive.mobile(css`
      input {
        width: 100%;
        padding-left: 35px;
      }
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 17px;
  font-size: 16px;
  color: #265fa7;
  z-index: 1;
  margin-left: 30px;
`

const SelectorBox = styled(CustomField)`
  margin: 0;

  input {
    background-color: #0e4791;
    border: unset;
    height: 30px;
    width: 70px;
    text-align: center;
    color: white;
  }
  div > .Select-menu-outer {
    z-index: 3;
  }
  div > .Select-menu-outer > .Select-menu {
    max-height: 250px;
    background-color: ${props => props.theme.BLUE};
    .Select-option {
      padding-left: 26px;
      padding-right: 0;
      opacity: 0.5;
    }
    .Select-option:hover {
      opacity: 1;
    }
  }

  .input-selector-container {
    margin: 0;

    .Select {
      width: 122px;
      height: 50px;
      background-color: #f2f2f2;
      border: 0;
      border-radius: 0 5px 5px 0 !important;
    }

    .is-open {
      border-radius: 0 5px 0 0 !important;
      color: white;
      background-color: #0e4791;

      .Select-value-label {
        color: white;
      }
      .Select-control > .Select-arrow-zone {
        color: white;
      }
    }

    .Select-control {
      color: #7f7f7f;
      > .Select-multi-value-wrapper {
        > .Select-placeholder {
          font-size: 16px;
          color: #7f7f7f;
          opacity: 1;
          text-align: left;
          padding-left: 26px;
        }
      }

      > .Select-arrow-zone {
        color: #7f7f7f;
        padding-right: 12px;
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      width: unset;
    `)};
`

const SearchAndSortBox = ({params, timeOut, initTimeOut, fetchArticles}) => (
  <Container>
    <CustomIcon icon={['fas', 'search']} />
    <SearchBox
      name="userQuery"
      placeholder="ค้นหา..."
      type="text"
      didChange={value => {
        if (timeOut) clearTimeout(timeOut)
        initTimeOut(
          setTimeout(() => {
            navigate(`/square?${obj2Url({...params, userQuery: value})}`)
            fetchArticles({...params, userQuery: value})
          }, 1000),
        )
      }}
    />
    <SelectorBox
      name="sortBy"
      placeholder="จัดเรียง"
      type="selector"
      options={sort}
      didChange={value => {
        navigate(`/square?${obj2Url({...params, sortBy: value})}`)
        fetchArticles({...params, sortBy: value})
      }}
    />
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      params: url2Obj(props.location.search) || null,
      initialValues: {
        userQuery: url2Obj(props.location.search).userQuery || null,
        sortBy: url2Obj(props.location.search).sortBy || 'new',
      },
    }),
    {},
  ),
  withState('timeOut', 'initTimeOut', null),
  reduxForm({
    form: 'noonSquareSearch',
  }),
)
export default enhancer(SearchAndSortBox)
