import {withProps} from 'recompose'

const withErrorMessage = () =>
  withProps(props => {
    const {error, ...rest} = props.meta || {}
    const errorMessage = {}
    switch (error) {
      case 'The value field is required.':
        errorMessage.error = 'Field ต้องไม่เว้นว่างไว้'
        break
      case 'The value format is invalid.':
        errorMessage.error = 'รูปแบบ (Format) ไม่ถูกต้อง'
        break
    }
    return {
      meta: {error: errorMessage.error || error, ...rest},
    }
  })

export default withErrorMessage
