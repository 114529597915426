import React from 'react'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../../core/style'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const DetailBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor};
  height: 40px;
  width: 100%;
  margin: 10px 0;
  cursor: pointer;
`

const HeaderDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${props => props.maxWidth};
  width: 100%;
  font-size: ${props => (props.fontSizeTopic ? props.fontSizeTopic : 16)}px;
  font-weight: bold;
  ${props =>
    responsive.mobile(css`
      max-width: 280px;
    `)};
`

const ArrowIcon = styled(FontAwesomeIcon)``

const HeaderDetail = ({
  selected,
  setSelected,
  header,
  bgColor = 'rgba(255, 185, 51, 0.2)',
  maxWidth = '666px',
  fontSizeTopic,
}) => (
  <DetailBackground bgColor={bgColor} onClick={() => setSelected(!selected)}>
    <HeaderDetailContainer maxWidth={maxWidth} fontSizeTopic={fontSizeTopic}>
      {header}
      <ArrowIcon icon={['fas', selected ? 'angle-up' : 'angle-down']} />
    </HeaderDetailContainer>
  </DetailBackground>
)

export default HeaderDetail
