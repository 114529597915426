import React from 'react'
import styled, {css} from 'react-emotion'
import useWindowSize from '@rehooks/window-size'
import ReactDOM from 'react-dom'

import {responsive, breakpoints} from '../../../../core/style'
import Button from '../../../share/Button'
import DividendRadios from './components/DividendRadios'
import FundCompaniesCheckboxs from './components/FundCompaniesCheckboxs'
import TaxSavingRadios from './components/TaxSavingRadios'
import CategorySelector from './components/CategorySelector'
import {formId} from '../components/Form'

const Container = styled.div`
  position: relative;

  ${props =>
    responsive.mobile(css`
      margin-top: 80px;
      width: 100%;
    `)}
`

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  color: black;
  width: 250px;
  padding: 18px 0;
  transition: 0.5s;
  z-index: 6;
  border-radius: 0 0 5px 5px;

  ${props =>
    responsive.extra(css`
      width: ${props.isactive ? '300px' : '0'};
      display: ${props.isactive ? 'block' : 'flex'};
      padding: 15px;
      position: fixed;
      top: 80px;
      background-color: white;
      left: ${props.isactive ? '0' : '-300px'};
      height: ${props.isactive ? 'calc(100vh - 140px)' : 'unset'};
      overflow-y: ${props.isactive ? 'scroll' : 'unset'};
      -webkit-overflow-scrolling: touch;
    `)};

  ${props =>
    responsive.mobile(css`
      position: absolute;
      top: 0;
      left: ${props.isactive ? '0' : '-342px'};
      height: ${props.isactive ? 'calc(100vh - 80px)' : 'unset'};
    `)};
`

const SubmitButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
`
const SubmitButton = styled(Button)`
  width: 150px;
  height: 30px;
  font-size: 12px;
  margin: 20px 0;
  ${props =>
    responsive.extra(css`
      margin: 10px;
    `)};
`

const withPortal = id => Component => props => {
  const windowSize = useWindowSize()
  console.log(document.getElementById(id))
  return windowSize.innerWidth < breakpoints.mobile &&
    document.getElementById(id) ? (
    ReactDOM.createPortal(<Component {...props} />, document.getElementById(id))
  ) : (
    <Component {...props} />
  )
}

export default withPortal('auto-header')(props => {
  const {activeSideBar, handleFilterChange, routeParams} = props
  return (
    <Container>
      <SidebarContainer isactive={activeSideBar}>
        <CategorySelector
          formId={formId}
          handleFilterChange={handleFilterChange}
          routeParams={routeParams}
        />
        <TaxSavingRadios
          formId={formId}
          handleFilterChange={handleFilterChange}
        />
        <DividendRadios
          formId={formId}
          handleFilterChange={handleFilterChange}
        />
        <FundCompaniesCheckboxs handleFilterChange={handleFilterChange} />
        <SubmitButtonWrapper>
          <SubmitButton htmlType="submit" icons={['fa', 'search']}>
            แสดงผล
          </SubmitButton>
        </SubmitButtonWrapper>
      </SidebarContainer>
    </Container>
  )
})
