import { takeLatest, put, select } from 'redux-saga/effects'

import { createReducer, Creator } from '../helper'
import { httpGet, httpDelete, httpPost, API_HOST } from '../../core/httpClient'

const SET_LOADING = 'SET_LOADING_QUESTIONS'
const QUESTIONS_GET_OPTIONS = 'QUESTIONS_GET_OPTIONS'
const QUESTIONS_SET_OPTIONS = 'QUESTIONS_SET_OPTIONS'
const QUESTIONS_GET_RECOMMEND = 'QUESTIONS_GET_RECOMMEND'
const QUESTIONS_SET_RECOMMEND = 'QUESTIONS_SET_RECOMMEND'

export const setQuestionsLoading = Creator(SET_LOADING, 'isLoading')

const setQuestionsOptions = Creator(QUESTIONS_SET_OPTIONS)
export const getQuestionsOptions = Creator(QUESTIONS_GET_OPTIONS)

const setQuestionsRecommend = Creator(QUESTIONS_SET_RECOMMEND)
export const getQuestionsRecommend = Creator(QUESTIONS_GET_RECOMMEND)

const obj2ArrOption = object =>
  Object.entries(object).map(obj => ({
    label: obj[1].label,
    value: obj[0],
  }))

const hospitals2Options = hospitals =>
  hospitals.map(hospital => ({ value: hospital.id, label: hospital.name }))

export function* getQuestionsOptionsSaga() {
  try {
    const url = `${API_HOST}noon_questions/options`
    const response = yield httpGet(url)
    yield put(
      setQuestionsOptions({
        hospitals: hospitals2Options(response.data.data.hospitals),
        investmentRisk: obj2ArrOption(response.data.data.investmentRisk),
      }),
    )
  } catch (error) {
    console.error(error.response || error)
  }
}

export function* getRecommendSaga({ payload }) {
  try {
    const url = `${API_HOST}noon_questions/recommend`
    const { data } = yield httpGet(url, {
      age: payload.age,
      retireAge: payload.retireAge,
      salary: payload.salary,
      revenueGrowth: payload.revenueGrowth,
      expense: payload.expense,
      hasSocialSecurity: payload.hasSocialSecurity,
      providentFund: payload.providentFund,
      hospitalId: payload.hospitalId,
      injuredFrequency: payload.injuredFrequency,
      investmentRisk: payload.investmentRisk,
      liabilities: payload.liabilities,
      gender: payload.gender,
    })
    yield put(
      setQuestionsRecommend({
        recommend: data.data.recommend,
      }),
    )
  } catch (error) {
    console.error(error.response || error)
  }
}

export function* watchNoonQuestionSaga() {
  yield takeLatest(QUESTIONS_GET_OPTIONS, getQuestionsOptionsSaga)
  yield takeLatest(QUESTIONS_GET_RECOMMEND, getRecommendSaga)
}

const initial = {
  isLoading: false,
  hospitals: [],
  investmentRisk: [],
  recommend: {},
}

export default createReducer(initial, state => ({
  [SET_LOADING]: ({ isLoading }) => ({ ...state, isLoading: isLoading }),
  [QUESTIONS_SET_OPTIONS]: data => ({ ...state, ...data }),
  [QUESTIONS_SET_RECOMMEND]: data => ({ ...state, ...data }),
}))
