import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withProps, withHandlers, withState, lifecycle} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Switch, Route, Redirect} from 'react-router-dom'

import * as path from '../../common/path'
import Product from './Product'
import Saving from './saving/index'
import Annuity from './annuity/index'
import TooltipReduceTaxModal from './TooltipReduceTaxModal'
import {withAnswers} from '../../enhances'
import {spidusStore} from '../../core/spidus'
import {toCurrency} from '../../core/helper'
import {TaxInput} from '../../components/index'

const Layout = styled.div`
  width: 100%;
`
const Header = styled.div`
  background-color: #093771;
  padding: 18px 0px;
`
const DetailsUser = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
`
const MobileCutWord = styled.br`
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`
const TextEditDetails = styled.div`
  color: #ffb933;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
`
const DescriptionHeader = styled.div`
  font-size: 12px;
  color: #ffffff;
  text-align: center;
`
const AdditionalTax = styled.div`
  padding-bottom: 10px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    > div:first-child {
      border-bottom: 1px solid #e5e5e5;
    }
  }
`
const TaxContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  min-width: 280px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TaxTopic = styled.div`
  color: #333333;
  font-size: 16px;
  white-space: nowrap;
`
const TaxCurrency = styled.div`
  display: flex;
  align-items: baseline;
  > div:first-child {
    margin-right: 5px;
  }
  color: ${props => props.color};
`
const TaxDescription = styled.div`
  display: flex;
  align-items: center;
  color: #888888;
  > svg {
    margin-left: 6px;
    color: #c1c1c1;
  }
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px 0px 0px;
`

const Planning = props => (
  <Layout>
    <Header>
      <DetailsUser>
        เพศ {props.genderThai} อายุ {props.age} ปี <MobileCutWord />
        รายได้ {toCurrency(props.valueIncome, {prefix: ''})} บาท/
        {props.periodThai}
      </DetailsUser>
      <TextEditDetails onClick={props.onEditDetails}>
        แก้ไขและกรอกละเอียด
      </TextEditDetails>
      <DescriptionHeader>
        กรอกละเอียดเพื่อความแม่นยำในการคำนวณภาษีของคุณ
      </DescriptionHeader>
    </Header>
    <AdditionalTax>
      <TaxContainer>
        <TaxInput
          caption="ภาษีที่ต้องจ่าย"
          positiveColor="#96B25B"
          negativeColor="#DC3545"
          fixValueColor="#DC3545"
          value={props.tax}
        />
      </TaxContainer>
      <TaxContainer>
        <TaxInput
          caption="คุณสามารถลดภาษีด้วยการซื้อประกันได้อีก"
          positiveColor="#96B25B"
          negativeColor="#DC3545"
          value={props.maxInsuranceReducedTax}
        />
        <TaxDescription>
          เมื่อซื้อประกันด้านล่างเป็นเงิน{' '}
          {toCurrency(props.maxInsuranceInvest, {prefix: ''})} บาท
          <FontAwesomeIcon
            onClick={props.onTooltipReduceTax}
            icon={['fas', 'info-circle']}
          />
        </TaxDescription>
      </TaxContainer>
    </AdditionalTax>

    <Body>
      <Switch>
        <Route path={path.annuityPlanningPath()} component={Annuity} />
        <Route path={path.savingPlanningPath()} component={Saving} />
        <Route path={path.planningPath()} component={Product} />
        <Redirect to={path.planningPath()} />
      </Switch>
    </Body>

    <TooltipReduceTaxModal
      isShowModal={props.tooltipReduceTax}
      setIsShowModal={props.setTooltipReduceTax}
    />
  </Layout>
)

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    age: 'age.value',
    gender: 'gender.value',
    summaryData: 'summary_data.value',
    valueIncome: 'summary_data.value.incomeSalary.value',
    periodIncome: 'summary_data.value.incomeSalary.period',
    tax: 'summary_data.value.tax',
    maxInsuranceReducedTax: 'summary_data.value.maxInsuranceReducedTax',
    maxInsuranceInvest: 'summary_data.value.maxInsuranceInvest',
    maxAnnuityInsuranceReducedTax:
      'summary_data.value.maxAnnuityInsuranceReducedTax',
    maxAnnuityInsuranceInvest: 'summary_data.value.maxAnnuityInsuranceInvest',
    maxSavingInsuranceReducedTax:
      'summary_data.value.maxSavingInsuranceReducedTax',
    maxSavingInsuranceInvest: 'summary_data.value.maxSavingInsuranceInvest',
  }),
  withState('tooltipReduceTax', 'setTooltipReduceTax', false),
  withProps(props => {
    return {
      genderThai: {
        female: 'หญิง',
        male: 'ชาย',
      }[props.gender],
      periodThai: {
        month: 'เดือน',
        year: 'ปี',
      }[props.periodIncome],
    }
  }),
  withHandlers({
    onEditDetails: props => () => {
      props.history.push(path.editPlanningPath())
    },
    onTooltipReduceTax: props => () => {
      props.setTooltipReduceTax(true)
    },
  }),
  lifecycle({
    async componentDidMount() {
      const names = [
        'age',
        'gender',
        'income_salary',
        'summary_data',
        'planning_deduct_pension_insurance',
        'planning_deduct_life_insurance',
        'deduct_life_insurance',
        'deduct_pension_insurance',
        'deduct_health_insurance',
        'deduct_ltf',
        'deduct_rmf',
        'deduct_special_donate',
        'deduct_general_donate',
        'deduct_sso',
        'deduct_provident_fund',
        'deduct_homeloan_interest',
      ]
      // await spidusStore.loadAnswers('noontax_config_web_tax_planning', ...names)
    },
  }),
)

export default enhancer(Planning)
