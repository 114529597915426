import React from 'react'
import styled, { css } from 'react-emotion'
import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { displayNumber } from '../../../../../core/helper'
import Favorite from '../Favorite'
import BuyCatalogue from '../BuyCatalogue'
import { responsive } from '../../../../../core/style'
import {
  ContainerInfo as CustomContainerInfo,
  ContainerInfoMobile,
  CompanyLogo,
  InfoIcon,
  ContainerDetailIcon as CustomContainerDetailIcon,
} from '../TopInfo'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: row;
`
const StarContainer = styled.div`
  display: flex;
`
const StarIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: #ffd900;
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: #333333;
  font-size: 40px;
  width: 40px;
  height: 40px;
`

const ContainerInfo = styled(CustomContainerInfo)`
  ${props =>
    responsive.tablet(css`
      margin-right: 40px;
    `)};
`
const SmallText = styled.div`
  font-size: 12px;
`

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`
const HashtagContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Hashtag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 25px;
  padding: 5px 15px 5px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #e5e5e5;
  font-size: 12px;
  color: black;
`

const ContainerFavorite = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const ContainerSecond = styled.div`
  position: relative;
  display: flex;

  flex-direction: row;
  height: 150px;
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin-top: 35px;
      width: 290px;
      height: unset;
    `)};
`

const ContainerDetailIcon = styled(CustomContainerDetailIcon)`
  ${props =>
    responsive.tablet(css`
      margin-right: ${props.isLast ? '0' : '54px'};
    `)};

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      width: 135px;
    `)};
`

const ContainerIconMobile = styled.div`
  display: flex;
  ${props =>
    responsive.mobile(css`
      justify-content: space-between;
      margin-top: 10px;
    `)};
`

const IconList = ({ item }) => {
  return (
    <React.Fragment>
      <ContainerIconMobile>
        <ContainerDetailIcon key={'shield-alt'}>
          <CustomIcon icon={['fas', 'shield-alt']} />
          <InfoIcon
            dangerouslySetInnerHTML={dangerText(
              `วงเงินประกันสุขภาพสูงสุด<br>${displayNumber(
                parseInt(item.totalSumInsured),
              )} บาท/ ปี`,
            )}
          />
        </ContainerDetailIcon>
        <ContainerDetailIcon key={'bed'}>
          <CustomIcon icon={['fas', 'bed']} />
          <InfoIcon
            dangerouslySetInnerHTML={dangerText(
              `วงเงินค่าห้อง<br> ${displayNumber(
                parseInt(item.totalRoom),
              )} บาท/ คืน`,
            )}
          />
        </ContainerDetailIcon>
      </ContainerIconMobile>
      <ContainerIconMobile>
        {item.policies && item.policies.length > 1 &&
          <ContainerDetailIcon key={'user-shield'}>
            <CustomIcon icon={['fas', 'user-shield']} />
            <InfoIcon
              dangerouslySetInnerHTML={dangerText(
                `${
                item.category === 'non_life'
                  ? `ไม่พ่วง<br/>ประกันชีวิต`
                  : `พ่วง<br/>ประกันชีวิต`
                }`,
              )}
            />
          </ContainerDetailIcon>
        }
        <ContainerDetailIcon key={'money-bag'} isLast>
          <CustomIcon icon={['fas', 'money-bag']} />
          <InfoIcon
            dangerouslySetInnerHTML={dangerText(
              `เบี้ยประกันรวม<br/>${displayNumber(
                parseInt(item.totalPremium),
              )} บาท/ ปี`,
            )}
          />
        </ContainerDetailIcon>
      </ContainerIconMobile>
    </React.Fragment>
  )
}

function dangerText(value) {
  return { __html: value }
}

const DetailTop = ({
  item = {},
  isFavorite,
  setIsFavorite,
  isShowModal,
  setIsShowModal,
  resetCatalogueSaleRep,
  params,
  category,
  paramsEncode,
  id,
  favorite,
  isLoading,
}) => (
    <Container>
      <ContainerDetail>
        <CompanyLogo src={item.companyOfficialLogoUrl} />
        <ContainerInfo>
          <Header>{(item.policies && item.policies[0].name) || '-'}</Header>
          <SmallText>{item.companyName}</SmallText>
          <StarContainer>
            {/* {starRate.map(
              value =>
                value.id <= item.popularRate && (
                  <StarIcon key={value.id} icon={['fas', 'star']} />
                ),
            )} */}
          </StarContainer>
          {/* <HashtagContainer>
            {item.hashTag.map(value => (
              <Hashtag key={value.name}>{value.name}</Hashtag>
            ))}
          </HashtagContainer> */}
        </ContainerInfo>
        <ContainerFavorite>
          {!isLoading && (
            <Favorite
              category={category}
              id={id}
              paramsEncode={paramsEncode}
              favorite={item.favorite}
            />
          )}
          <BuyCatalogue
            params={params}
            category={category}
            productName={(item.policies && item.policies[0].name) || '-'}
          />
        </ContainerFavorite>
      </ContainerDetail>
      <ContainerInfoMobile>
        <Header>{(item.policies && item.policies[0].name) || '-'}</Header>
        <SmallText>{item.companyName}</SmallText>
        <StarContainer>
          {/* {starRate.map(
              value =>
                value.id <= item.popularRate && (
                  <StarIcon key={value.id} icon={['fas', 'star']} />
                ),
            )} */}
        </StarContainer>
        {/* <HashtagContainer>
            {item.hashTag.map(value => (
              <Hashtag key={value.name}>{value.name}</Hashtag>
            ))}
          </HashtagContainer> */}
      </ContainerInfoMobile>
      <ContainerSecond>
        <IconList item={item} />
      </ContainerSecond>
    </Container>
  )

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
)

export default enhancer(DetailTop)
