import React, {useState, useEffect, useMemo} from 'react'
import {connect} from 'react-redux'
import styled from 'react-emotion'

import CollapseField from './CollapseField'
import {CustomCheckboxs} from './StyleComponents'
import {fetchFundCompanyOptions} from '../../../../../ducks/fund'
import {GroupSideBar} from './StyleComponents'
import defaultCompanyLogo from '../../../../../static/images/default-company-logo.png'

const CompanyName = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-align: left;
`
const CompanyLogo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 5px;
`
const WrapLabelAll = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-between;
`
const SpanTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #265fa7;
`

export default connect(
  state => {
    const {fundCompanyOptions} = state.fund
    return {fundCompanyOptions}
  },
  {fetchFundCompanyOptions},
)(props => {
  const [collapseActive, setCollapseActive] = useState(true)
  const companySymbolsFieldName = 'companySymbols'
  const {fetchFundCompanyOptions, fundCompanyOptions} = props

  useEffect(() => {
    fetchFundCompanyOptions()
  }, [])

  return (
    <GroupSideBar>
      <CollapseField
        title={collapseActive ? 'ตัวเลือกเพิ่มเติม' : 'ย่อตัวเลือก'}
        setCollapseActive={setCollapseActive}
        collapseActive={collapseActive}>
        <CustomCheckboxs
          name={companySymbolsFieldName}
          type="checkboxes"
          labelAll={
            <WrapLabelAll>
              <SpanTitle>บลจ.</SpanTitle>
              <span>เลือกทั้งหมด</span>
            </WrapLabelAll>
          }
          checkAll
          options={
            fundCompanyOptions
              ? fundCompanyOptions.map(({logo, label, value}) => {
                  const customLabel = (
                    <CompanyName key={value}>
                      <CompanyLogo src={logo || defaultCompanyLogo} />
                      {label}
                    </CompanyName>
                  )
                  return {label: customLabel, value}
                })
              : []
          }
          themeColor="orange"
          collapseActive={collapseActive}
        />
      </CollapseField>
    </GroupSideBar>
  )
})
