import React, {useEffect, useState, useCallback, useMemo} from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {navigate} from '@reach/router'
import {initialize} from 'redux-form'
import InfiniteScroll from 'react-infinite-scroller'
import useWindowSize from '@rehooks/window-size'
import {getFormValues} from 'redux-form'
import SearchFund from './SearchFund'
import ReactDOM from 'react-dom'

import {
  fetchFunds,
  fetchAdditionalFunds,
  setIsShowModalFavorite,
  fetchFavoriteFundRefIds,
} from '../../../ducks/fund'

import Loading from '../../share/Loading'
import FundCard from '../components/FundCard'
import {obj2Url, url2Obj} from '../../../core/helper'
import Sidebar from './Sidebar'
import FundsCompare from './FundsCompare'
import Button from '../../share/Button'
import {responsive} from '../../../core/style'
import {Container} from './components/StyleComponents'
import Form, {formId} from './components/Form'
import ModalFavorite from '../../share/ModalFavorite'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import LoadingAnimation from '../../../static/images/loading.gif'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Body = styled.div`
  display: flex;
  width: 100%;
  max-width: 1190px;
  margin-top: 20px;
  padding: 0 20px;

  ${props =>
    responsive.tablet(css`
      width: 750px;
      margin-top: 0;
      padding: 0;
    `)};
`
const Mask = styled.div`
  display: none;
  ${props =>
    responsive.extra(css`
      display: ${props.activeSideBar ? 'flex' : 'none'};
      position: ${props.activeSideBar ? 'fixed' : 'fixed'};
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100vw;
      height: ${props.activeSideBar ? '100vh' : '100%'};
      z-index: ${props.activeSideBar ? '6' : '1'};
      opacity: ${props.activeSideBar ? '0.9' : '0'};
      transition: opacity 0.5s;
      cursor: pointer;
    `)};
`
const FundsCardContainer = styled.ul`
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 0;
  list-style-type: none;
  li {
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-of-type(3n) {
      margin-right: 0px;
    }
  }
  ${props =>
    responsive.desktop(css`
      li {
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-of-type(3n) {
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
    `)};
  ${props =>
    responsive.mobile(css`
      margin: 0;
      margin-bottom: 10px;
      &:nth-of-type(2n) {
        margin-right: 0px;
      }
      &:nth-of-type(3n) {
        margin-right: 0px;
      }
    `)};
`
const FundsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  ${props =>
    responsive.mobile(css`
      padding-left: 0px;
    `)};
`
const PeriodClearParamsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 17px 0px 20px 0;
  ${props =>
    responsive.mobile(css`
      align-content: space-between;
      margin: 0 20px 20px;
      padding-top: 10px;
    `)};
`
const PeriodSortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
`
const PeriodSortTitle = styled.p`
  color: #333333;
  font-size: 12px;
  margin: 0 20px 0 0;
  ${props =>
    responsive.mobile(css`
      margin: 0 10px 0 0;
      display: none;
    `)};
`
const PeriodButtonWrapper = styled.div`
  margin-right: 15px;
  &:nth-last-of-type(1) {
    margin-right: 0px;
  }
  ${props =>
    responsive.mobile(css`
      margin-right: 5px;
    `)};
`
const PeriodButton = styled(Button)`
  width: 50px;
  height: 25px;
  border-radius: 5px;
  background-color: ${props =>
    props.isactive === 'true' ? props.theme.ORANGE : '#e5e5e5'};
  cursor: pointer;
  font-size: 12px;
  ${props =>
    responsive.mobile(css`
      width: 50px !important;
      margin-left: 10px;
    `)};
`
const ParamsClearCustomButton = styled(Button)`
  ${props =>
    responsive.mobile(css`
      width: 100%;
      margin-bottom: 10px;
    `)};
`
const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
`
const BaseSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    responsive.extra(css`
      cursor: pointer;
    `)};
`
const FilterSection = styled(BaseSection)`
  display: none;

  ${props =>
    responsive.extra(css`
      display: flex;
    `)};
`
const FilterIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 20px;

  ${props =>
    responsive.mobile(css`
      margin: 0 11px 0 5px;
    `)};
`
const FilterAndSortText = styled.span`
  font-size: 12px;
`
const WrapperFund = styled.div`
  display: flex;
  width: 100vw;
  height: 80px;
  align-items: center;
  justify-content: space-around;
  background-color: #093771;
  z-index: 7;
  margin-top: 80px;
  padding: 20px 35px;
  justify-content: center;
  ${props =>
    responsive.desktop(css`
      padding: 20px 136px;
      justify-content: flex-end;
    `)};
  ${props =>
    responsive.tablet(css`
      padding: 0 50px;
      margin-top: 0px;
      justify-content: center;
    `)};
  ${props =>
    responsive.mobile(css`
      justify-content: center;
      padding: 0;
    `)};
`
const WrapLoader = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`
const LoadingGif = styled.img``

const ReturnPeriodSort = props => {
  const {routeParams, handleFilterChange} = props
  const fieldName = 'returnPeriod'
  const returnPeriod = useMemo(() => {
    const defaultReturnPeriod = '1y'
    return routeParams && routeParams.returnPeriod
      ? routeParams.returnPeriod
      : defaultReturnPeriod
  }, [routeParams])

  return (
    <PeriodSortWrapper>
      <PeriodSortTitle>เรียงตามผลตอบแทนย้อนหลัง</PeriodSortTitle>
      <PeriodButtonWrapper>
        <PeriodButton
          isactive={(returnPeriod === '1y').toString()}
          onClick={() => {
            handleFilterChange(fieldName, '1y')
          }}>
          1 ปี
        </PeriodButton>
      </PeriodButtonWrapper>
      <PeriodButtonWrapper>
        <PeriodButton
          isactive={(returnPeriod === '3y').toString()}
          onClick={() => {
            handleFilterChange(fieldName, '3y')
          }}>
          3 ปี
        </PeriodButton>
      </PeriodButtonWrapper>
      <PeriodButtonWrapper>
        <PeriodButton
          isactive={(returnPeriod === '5y').toString()}
          onClick={() => {
            handleFilterChange(fieldName, '5y')
          }}>
          5 ปี
        </PeriodButton>
      </PeriodButtonWrapper>
    </PeriodSortWrapper>
  )
}

const ParamsClearButton = connect(
  null,
  {initialize},
)(props => {
  const {routeParams, initialize} = props

  const hideButton = useMemo(
    () =>
      Object.keys(routeParams).length === 0 ||
      (Object.keys(routeParams).length === 1 &&
        Object.keys(routeParams)[0] === 'returnPeriod'),
    [routeParams],
  )

  const handleClick = useCallback(() => {
    initialize(formId, {})
    navigate('/fund')
  }, [])

  if (hideButton) return <div />

  return (
    <ParamsClearCustomButton
      type="border"
      icons={['fa', 'trash-alt']}
      onClick={handleClick}>
      ล้างค่าค้นหา
    </ParamsClearCustomButton>
  )
})

export default connect(
  state => ({
    funds: state.fund.all,
    favoriteRefIds: state.fund.favoriteRefIds,
    allFetching: state.fund.allFetching,
    additionalFetching: state.fund.additionalFetching,
    paging: state.fund.paging,
    isShowModalFavorite: state.fund.isShowModalFavorite,
    isLogin: state.auth.isLogin,
    formValues: getFormValues(formId)(state),
  }),
  {
    fetchFunds,
    fetchAdditionalFunds,
    fetchFavoriteFundRefIds,
    setIsShowModalFavorite,
  },
)(props => {
  const [activeSideBar, setActiveSideBar] = useState(false)
  const {
    fetchFunds,
    fetchAdditionalFunds,
    fetchFavoriteFundRefIds,
    setIsShowModalFavorite,
    isShowModalFavorite,
    funds,
    paging,
    location,
    allFetching,
    additionalFetching,
    isLogin,
    formValues,
  } = props
  const {page, totalPages} = paging

  const routeParams = useMemo(() => {
    return url2Obj(location.search)
  }, [location.search])

  const handleSidebarFormSubmit = useCallback(values => {
    const newRouteParams = Object.keys(values)
      .filter(key => {
        if (values[key] === null) {
          return false
        } else if (values[key] === undefined) {
          return false
        } else if (values[key] === '') {
          return false
        } else if (values[key] instanceof Array && values[key].length === 0) {
          return false
        }
        return true
      })
      .reduce((res, key) => ((res[key] = values[key]), res), {})
    const routeParamsString = obj2Url({
      ...newRouteParams,
      returnPeriod: routeParams.returnPeriod,
    })

    if (Object.keys(newRouteParams).length === 0) {
      navigate(`/fund`)
    } else {
      navigate(`/fund?${routeParamsString}`)
    }
  })

  const handleFilterChange = useCallback(
    (fieldKey, value) => {
      if (value === '' || value === null || value === undefined) {
        if (routeParams && routeParams[fieldKey]) {
          const newRouteParams = Object.keys(routeParams)
            .filter(key => key !== fieldKey)
            .reduce((res, key) => ((res[key] = routeParams[key]), res), {})
          navigate(`/fund?${obj2Url({...newRouteParams, ...formValues})}`)
        }
      } else {
        navigate(
          `/fund?${obj2Url({
            ...routeParams,
            ...formValues,
            [fieldKey]: value,
          })}`,
        )
      }
    },
    [routeParams, formValues],
  )

  const windowSize = useWindowSize()
  const perPage = windowSize.innerWidth <= 990 ? 8 : 9

  const loadMore = useCallback(() => {
    if (!additionalFetching) {
      const page = paging.page + 1
      const include = ['prices', 'return_period']
      fetchAdditionalFunds({...routeParams, page, perPage, include})
    }
  }, [routeParams, paging, additionalFetching])

  useEffect(() => {
    const include = ['prices', 'return_period']
    fetchFunds({...routeParams, perPage, include})
  }, [routeParams])

  useEffect(() => {
    isLogin && fetchFavoriteFundRefIds()
  }, [isLogin])

  return (
    <Loading isLoading={allFetching}>
      <Form onSubmit={handleSidebarFormSubmit} routeParams={routeParams}>
        {document.getElementById('main_header')
          ? ReactDOM.createPortal(
              <WrapperFund>
                <SearchFund
                  {...props}
                  routeParams={routeParams}
                  formId={formId}
                />
              </WrapperFund>,
              document.getElementById('main_header'),
            )
          : ''}
        <Wrapper>
          <Container>
            <Mask
              activeSideBar={activeSideBar}
              onClick={() => {
                handleSidebarFormSubmit(formValues)
                setActiveSideBar(false)
              }}
              routeParams={routeParams}
            />
            <Body>
              <Sidebar
                activeSideBar={activeSideBar}
                setActiveSideBar={setActiveSideBar}
                routeParams={routeParams}
              />
              <FundsContent>
                <PeriodClearParamsWrapper>
                  <FilterSection
                    onClick={() => {
                      setActiveSideBar(!activeSideBar)
                    }}>
                    <FilterIcon icon={['fas', 'filter']} />
                    <FilterAndSortText>ข้อมูลจาก กลต.</FilterAndSortText>
                  </FilterSection>
                  <div />
                  <ReturnPeriodSort
                    routeParams={routeParams}
                    handleFilterChange={handleFilterChange}
                  />
                </PeriodClearParamsWrapper>
                <InfiniteScroll
                  key={`${page}-InfiniteScroll`}
                  pageStart={0}
                  loadMore={loadMore}
                  hasMore={page < totalPages}
                  loader={
                    <WrapLoader key={`${page}-loader`}>
                      <LoadingGif src={LoadingAnimation} />
                    </WrapLoader>
                  }>
                  <FundsCardContainer>
                    {funds.map(fund => (
                      <li key={`${fund.refId}`}>
                        <FundCard {...fund} />
                      </li>
                    ))}
                  </FundsCardContainer>
                </InfiniteScroll>
              </FundsContent>
            </Body>
          </Container>
          <FundsCompare />
        </Wrapper>
      </Form>
      <ModalFavorite
        isShowModal={isShowModalFavorite}
        setIsShowModal={setIsShowModalFavorite}
        isReload
      />
    </Loading>
  )
})
