import React from 'react'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const FileInputContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #a1a1a1;
`

const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

// const FilePlacholder = styled.span`
//   opacity: 0.5;
//   color: #d6d5d5;
//   font-size: 20px;
//   font-weight: lighter;
//   font-family: ${props => props.theme.headerFont};
//   text-indent: 10px;
// `

// export const UploadButton = styled.button`
//   display: none;
//   position: absolute;
//   top: 0;
//   right: 0;

//   width: 109px;
//   height: 100%;

//   color: white;
//   background-color: #a1a1a1;
//   border: none;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;

//   font-size: 20px;
//   font-family: ${props => props.theme.headerFont};
// `

// export const FileLabel = styled.div`
//   -webkit-appearance: none;
//   display: flex;
//   align-items: center;

//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   border: 1px solid #a1a1a1;
//   padding: 0 5px;

//   background: ${props => (props.disabled ? '#d6d5d5' : 'white')};

//   font-size: 20px;
//   font-weight: bold;
//   font-family: ${props => props.theme.headerFont};
//   text-indent: 10px;

//   cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
// `

const CameraIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 16px;
  cursor: pointer;
  color: #7f7f7f;
  top: 16px;
  left: 16px;
`

const CustomFileInput = ({input, ...otherProps}) => (
  <FileInputContainer>
    {/* <FileLabel for="file_upload">
      {input.value ? input.value.name : ''}
      {otherProps.placeholder && (!input.value || !input.value.name) && (
        <FilePlacholder>{otherProps.placeholder}</FilePlacholder>
      )}
    </FileLabel> */}
    <CameraIcon icon={['fas', 'camera']} />
    {/* <UploadButton>เลือกไฟล์</UploadButton> */}
    <FileInput
      id="file_upload"
      {...otherProps}
      onChange={e => {
        e.preventDefault()
        // convert files to an array
        const files = [...e.target.files]
        // fields.yourField.handleChange(files)
        if (files && files.length > 0) {
          otherProps.didChange(files[0])
          input.onChange(files[0])
        }
      }}
    />
  </FileInputContainer>
)

export default CustomFileInput
