import React from 'react'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'

import Button from '../share/Button'
import {responsive} from '../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.BLUE};
`

const Content = styled.div`
  margin-top: 20px;

  font-size: 12px;
  line-height: 18px;
`

const ConfirmButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  font-size: 12px;
  margin-top: 30px;

  ${props =>
    responsive.mobile(css`
      min-width: 280px;
      height: 40px;
      font-size: 16px;
    `)}
`

const ForgetPasswordResendEmail = () => (
  <Container>
    <Header>อีเมลถูกส่งแล้ว</Header>
    <Content>ลองเช็คในอินบอกซ์ของคุณสิ!</Content>
    <ConfirmButton
      onClick={() => {
        navigate(`/authorize/login`)
      }}
      icons={['fa', 'user-circle']}>
      ยืนยัน
    </ConfirmButton>
  </Container>
)

export default ForgetPasswordResendEmail
