import React from 'react'
import DateTime from 'react-datetime'
import styled from 'react-emotion'
import {
  compose,
  branch,
  defaultProps,
  withHandlers,
  withProps,
  withState,
  lifecycle,
} from 'recompose'
import {withSpidus, withCaption, withValue} from '../enhances'
import 'react-datetime/css/react-datetime.css'
import {compareAsc, format} from 'date-fns'
import thLocale from 'date-fns/locale/th'
import Select from '../../share/Field/Selector'
import moment from 'moment'

DateTime.prototype.componentDidUpdate = () => {
  const isTH = true

  const monthTargets = document.querySelectorAll('.rdtMonth')
  if (monthTargets) {
    if (isTH) {
      const monthsShort = 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split(
        '_',
      )
      monthTargets.forEach(target => {
        target.innerHTML = monthsShort.find(month =>
          month.startsWith(target.innerHTML),
        )
      })
    }
  }

  const yearSelectOptions = document.querySelectorAll('.rdtSwitch, .rdtYear')
  if (yearSelectOptions) {
    yearSelectOptions.forEach(option => {
      option.innerHTML = option.innerHTML.replace(/\d{4}/g, input => {
        const year = parseInt(input)

        if (isTH) {
          return year < 2300 ? year + 543 : year
        } else {
          return year > 2300 ? year - 543 : year
        }
      })
    })
  }

  const yearRangeTargets = document.querySelectorAll('.rdtYears .rdtSwitch')

  if (yearRangeTargets !== undefined && yearRangeTargets.length > 0) {
    const preparedTarget = yearRangeTargets[0].querySelectorAll(
      '.prepared-target',
    )
    if (preparedTarget.length === 0) {
      const yearMinMax = yearRangeTargets[0].innerHTML.split('-')
      const preparedMinYear = parseInt(yearMinMax[0]) - 1
      const preparedMaxYear = parseInt(yearMinMax[1]) + 1
      const preparedHTML = `<span class="prepared-target">${preparedMinYear}-${preparedMaxYear}</span>`
      yearRangeTargets[0].innerHTML = preparedHTML
    }
  }
}

const DateSelectWrapper = styled.div`
  display: flex;

  .Select {
    margin-left: 4px;
  }

  .Select:first-child {
    margin-left: 0;
  }
`
const DateSelect = styled(Select)``

const isLeapYear = year =>
  year % 100 === 0 ? year % 400 === 0 : year % 4 === 0

const genDayOptions = (monthIndex = null, year = null) => {
  let maxDay,
    dayOptions = []
  if (monthIndex === 1) {
    maxDay = isLeapYear(year) ? 29 : 28
  } else if ([3, 5, 8, 10].includes(monthIndex)) {
    maxDay = 30
  } else {
    maxDay = 31
  }

  for (let i = 1; i <= maxDay; i++) {
    dayOptions.push({value: i, label: i})
  }

  return dayOptions
}

const CustomDateTime = styled(DateTime)`
  color: black;
  width: 100%;

  input {
    text-align: left !important;
  }

  .rdtPicker {
    width: 250px !important;
  }

  .rdtDays,
  .rdtMonths,
  .rdtYears {
    width: 100% !important;
  }

  .rdtPicker thead tr:first-child th:hover {
    background: #ffd482;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #ffd482;
  }

  .rdtPicker td.rdtDay.rdtDisabled:hover,
  .rdtPicker td.rdtHour.rdtDisabled:hover,
  .rdtPicker td.rdtMinute.rdtDisabled:hover,
  .rdtPicker td.rdtSecond.rdtDisabled:hover,
  .rdtPicker .rdtTimeToggle.rdtDisabled:hover {
    background: none;
  }

  .rdtPicker td.rdtDisabled {
    opacity: 0.5;
  }

  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #ffb933;
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid #ffd482;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    font-family: 'Sarabun', sans-serif;
    color: #333333;
    text-align: right;
  }

  input[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const ReadOnlyDatePicker = styled.div`
  font-size: 16px;
  font-family: 'Sarabun', sans-serif;
  color: #333333;
  text-align: right;
`

const DatePicker = props =>
  props.readOnly ? (
    <ReadOnlyDatePicker>
      {props.value && props.mode === 'picker'
        ? props.valueToFomatWithLocaleYear()
        : props.value}
    </ReadOnlyDatePicker>
  ) : props.mode === 'picker' ? (
    <CustomDateTime
      className={props.customDateTimeClassName}
      closeOnSelect
      dateFormat={props.dateFormat}
      value={props.value}
      viewMode={props.viewMode || 'days'}
      viewDate={props.viewDate || props.value || new Date()}
      timeFormat={false}
      onChange={props.onChange}
      renderInput={(_inputProps, openCalendar) => (
        <input
          value={props.valueToFomatWithLocaleYear()}
          onChange={() => {}}
          onClick={e => {
            e.preventDefault()
            openCalendar()
          }}
          disabled={props.disabled}
        />
      )}
      isValidDate={props.isValidDate}
    />
  ) : (
    <DateSelectWrapper>
      <DateSelect
        name="day"
        options={props.dayOptions}
        onChange={props.onDayChange}
        value={props.dayValue}
        placeholder="วัน"
        disabled={props.disabled}
      />
      <DateSelect
        name="month"
        options={props.monthOptions}
        onChange={props.onMonthChange}
        value={props.monthValue}
        placeholder="เดือน"
        disabled={props.disabled}
      />
      <DateSelect
        name="year"
        options={props.yearOptions}
        onChange={props.onYearChange}
        value={props.yearValue}
        placeholder="ปี"
        disabled={props.disabled}
      />
    </DateSelectWrapper>
  )

const datePickerCompose = compose(
  withHandlers({
    onChange: props => value => {
      const result = value ? value.toDate() : null

      props.onChange && props.onChange(result)
      props.onBlur && props.onBlur(result)
    },
    valueToFomatWithLocaleYear: props => () => {
      if (!props.value) {
        return ''
      }
      return moment(props.value).xformat(props.dateFormat)
    },
    isValidDate: props => currentDate => {
      let isValid = true
      const current = currentDate.toDate()

      if (
        (props.minDate &&
          props.maxDate &&
          (compareAsc(current, props.minDate) === -1 ||
            compareAsc(props.maxDate, current) === -1)) ||
        (props.minDate && compareAsc(current, props.minDate) === -1) ||
        (props.maxDate && compareAsc(props.maxDate, current) === -1)
      ) {
        isValid = false
      }

      return isValid
    },
  }),
  withProps(props => ({
    viewDate: props.value || props.viewDate,
  })),
  withProps(props => ({
    viewMode: props.viewMode || (props.viewDate ? undefined : 'years'),
  })),
)

const dateSelectCompose = compose(
  withState('value', 'setValue', props => props.value),
  withState('dayValue', 'setDay', props =>
    props.value ? props.value.getDate() : null,
  ),
  withState('monthValue', 'setMonth', props =>
    props.value ? props.value.getMonth() : null,
  ),
  withState('yearValue', 'setYear', props =>
    props.value ? props.value.getFullYear() : null,
  ),
  withState('dayOptions', 'setDayOptions', props =>
    props.value
      ? genDayOptions(props.value.getMonth(), props.value.getFullYear())
      : genDayOptions(),
  ),
  lifecycle({
    componentWillReceivedProps(nextProps) {
      if (props.value !== nextProps.value) {
        nextProps.setValue(nextProps.value)
      }
    },
  }),
  withProps(_props => {
    let newProps = {
      monthOptions: [],
      yearOptions: [],
    }

    const monthNames = [
      ['มกราคม', 'ม.ค.'],
      ['กุมภาพันธ์', 'ก.พ.'],
      ['มีนาคม', 'มี.ค.'],
      ['เมษายน', 'เม.ย.'],
      ['พฤษภาคม', 'พ.ค.'],
      ['มิถุนายน', 'มิ.ย.'],
      ['กรกฎาคม', 'ก.ค.'],
      ['สิงหาคม', 'ส.ค.'],
      ['กันยายน', 'ก.ย.'],
      ['ตุลาคม', 'ต.ค.'],
      ['พฤศจิกายน', 'พ.ย.'],
      ['ธันวาคม', 'ธ.ค.'],
    ]

    monthNames.map((v, i) => {
      newProps.monthOptions.push({value: i, label: v[1]})
    })

    const currentYear = new Date().getFullYear()
    const minimumYear = currentYear - 120

    for (let year = currentYear; year >= minimumYear; year--) {
      newProps.yearOptions.push({value: year, label: year})
    }

    return newProps
  }),
  withHandlers({
    onValueChange: props => (value, name) => {
      let updatedValue = null,
        updatedDayOptions = props.dayOptions
      const {dayValue, monthValue, yearValue} = props

      if (dayValue && monthValue && yearValue) {
        try {
          switch (name) {
            case 'day':
              updatedValue = new Date(yearValue, monthValue, value)
              break
            case 'month':
              updatedValue = new Date(yearValue, value, dayValue)
              updatedDayOptions = genDayOptions(value, yearValue)
              break
            case 'year':
              updatedValue = new Date(value, monthValue, dayValue)
              updatedDayOptions = genDayOptions(monthValue, value)
              break
            default:
              // Do nothing
              break
          }
        } catch (_error) {
          // Do nothing
        }
      }

      if (dayValue > updatedDayOptions.length) {
        props.setDay(null)
        updatedValue = null
      }
      if (updatedValue !== props.value) {
        props.setValue(updatedValue)
      }
      if (updatedDayOptions.length !== props.dayOptions.length) {
        props.setDayOptions(updatedDayOptions)
      }
      props.onChange && props.onChange(updatedValue)
    },
  }),
  withHandlers({
    onDayChange: props => ({_label, value}) => {
      props.setDay(value)
      props.onValueChange(value, 'day')
    },
    onMonthChange: props => ({_label, value}) => {
      props.setMonth(value)
      props.onValueChange(value, 'month')
    },
    onYearChange: props => ({_label, value}) => {
      props.setYear(value)
      props.onValueChange(value, 'year')
    },
  }),
  withCaption(),
)

export const toDate = value => {
  if (value && typeof value === 'string') {
    if (/^\d+\/\d+\/\d+$/.test(value)) {
      let [day, month, year] = value.split('/')

      if (year > 2400) {
        year = year - 543
      }

      value = new Date(year, month, day)
    } else if (/^\d+\-\d+\-\d+/.test(value)) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth()
      let year = date.getFullYear()

      if (year > 2400) {
        year = year - 543
      }

      value = new Date(year, month, day)
    }
  }
  return value
}

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  branch(
    props => !props.noCaption,
    withCaption(),
    withProps(props => ({customDateTimeClassName: props.className})),
  ),
  defaultProps({
    locale: 'th',
    dateFormat: 'DD MMMM YYYY',
    mode: 'picker',
    disabled: false,
  }),
  branch(
    props => props.answerName,
    compose(
      withProps(props => {
        let value = null

        if (props.value && /^\d+\/\d+\/\d+$/.test(props.value)) {
          const [day, month, year] = props.value.split('/')
          value = new Date(year, month - 1, day)
        }

        return {
          value,
        }
      }),
      withHandlers({
        onChange: props => value => {
          if (value && value) {
            const day = value.getDate()
            const month = value.getMonth() + 1
            const year = value.getFullYear()
            value = `${day}/${month}/${year}`
          }
          props.onChange(value)
        },
      }),
    ),
    withProps(props => ({value: toDate(props.value)})),
  ),
  branch(
    props => props.mode === 'picker',
    datePickerCompose,
    dateSelectCompose,
  ),
)
export default enhance(DatePicker)
