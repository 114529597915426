import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import HeaderDetail from '../HeaderDetail'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 44px;
`
const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 777px;
  width: 100%;
  font-size: 16px;
`
const Detail = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 50px;
`

const HealthCI = ({
  selectedCancer,
  setSelectedCancer,
  selectedDiabetes,
  setSelectedDiabetes,
  selectedBrain,
  setSelectedBrain,
  selectedHeart,
  setSelectedHeart,
  selectedInternalOrgrans,
  setSelectedInternalOrgrans,
  selectedExternalOrgrans,
  setSelectedExternalOrgrans,
  selectedSurgery,
  setSelectedSurgery,
  selectedDisability,
  setSelectedDisability,
  item,
}) => (
  <Container>
    <Header>รายละเอียดความคุ้มครองรักษาพยาบาล</Header>
    <HeaderDetail
      selected={selectedCancer}
      setSelected={setSelectedCancer}
      header={'มะเร็ง / เนื้องอก'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedCancer && (
      <React.Fragment>
        <DetailContainer>
          {item[0].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}
    <HeaderDetail
      selected={selectedDiabetes}
      setSelected={setSelectedDiabetes}
      header={'เบาหวาน'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedDiabetes && (
      <React.Fragment>
        <DetailContainer>
          {item[1].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}
    <HeaderDetail
      selected={selectedBrain}
      setSelected={setSelectedBrain}
      header={'สมอง / ระบบประสาท'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedBrain && (
      <React.Fragment>
        <DetailContainer>
          {item[2].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}
    <HeaderDetail
      selected={selectedHeart}
      setSelected={setSelectedHeart}
      header={'หัวใจและหลอดเลือด'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedHeart && (
      <React.Fragment>
        <DetailContainer>
          {item[3].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}
    <HeaderDetail
      selected={selectedInternalOrgrans}
      setSelected={setSelectedInternalOrgrans}
      header={'อวัยวะภายในอื่นๆ'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedInternalOrgrans && (
      <React.Fragment>
        <DetailContainer>
          {item[4].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}

    <HeaderDetail
      selected={selectedExternalOrgrans}
      setSelected={setSelectedExternalOrgrans}
      header={'อวัยวะภายนอก'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedExternalOrgrans && (
      <React.Fragment>
        <DetailContainer>
          {item[5].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}

    <HeaderDetail
      selected={selectedSurgery}
      setSelected={setSelectedSurgery}
      header={'ผ่าตัด'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedSurgery && (
      <React.Fragment>
        <DetailContainer>
          {item[6].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}
    <HeaderDetail
      selected={selectedDisability}
      setSelected={setSelectedDisability}
      header={'พิการ / ทุพพลภาพ'}
      bgColor={'rgba(159,209,52,0.2)'}
      maxWidth="777px"
    />
    {selectedDisability && (
      <React.Fragment>
        <DetailContainer>
          {item[7].childs.map((value, index) => (
            <Detail key={index}>{value}</Detail>
          ))}
        </DetailContainer>
      </React.Fragment>
    )}
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
  withState('selectedCancer', 'setSelectedCancer', true),
  withState('selectedDiabetes', 'setSelectedDiabetes', true),
  withState('selectedBrain', 'setSelectedBrain', true),
  withState('selectedHeart', 'setSelectedHeart', true),
  withState('selectedInternalOrgrans', 'setSelectedInternalOrgrans', true),
  withState('selectedExternalOrgrans', 'setSelectedExternalOrgrans', true),
  withState('selectedSurgery', 'setSelectedSurgery', true),
  withState('selectedDisability', 'setSelectedDisability', true),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(HealthCI)
