import {keyframes} from 'react-emotion'

export const textFly = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
`

export const fly = keyframes` 
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(200vw);
  }
`

export const cloudFly = keyframes`
  0% {
    left: -100%;
  }
  90% {
    left: 20px;
  }
  100% {
    left: -10px;
  }
`
