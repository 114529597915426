import React, {useCallback} from 'react'
import styled from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {connect} from 'react-redux'

import Button from '../../share/Button'
import Tooltip from '../../share/Tooltip'
import LineChart from './LineChart'
import FavoriteButton from './FavoriteButton'
import {pushToFundsCompare, removeFromFundsCompare} from '../../../ducks/fund'
import {getThaiDate} from '../../../core/helper'
import defaultCompanyLogo from '../../../static/images/default-company-logo.png'
import RiskLavel from './RiskLevel'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #7f7f7f;
  border-radius: 5px;
  overflow: hidden;
  color: #333333;
  padding: 20px;
  min-width: 280px;
  width: 280px;
  height: 400px;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const BottomContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 13px;
  bottom: 0;
  margin-bottom: 8px;
  padding-right: 12px;
`
const CardContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 20px;
`
const NameThai = styled.p`
  font-size: 10px;
  font-weight: ${props => props.theme.SEMI};
  margin: 0;
`
const CompanyLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
  margin-bottom: 5px;
  overflow: hidden;
  margin-right: 10px;
`
const CompanyName = styled.div`
  font-size: 10px;
  text-align: center;
  margin: 0;
`
const Symbol = styled.h4`
  font-size: 16px;
  margin: 0;
  font-weight: ${props => props.theme.BOLD};
`
const Nav = styled.h4`
  font-size: 16px;
  font-weight: ${props => props.theme.BOLD};
  margin: 0;
  margin-right: 3px;
`
const WrapLableNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const NavUpdateAt = styled.p`
  font-size: 8px;
  margin: 0;
`
const ReturnRate = styled.p`
  font-size: 12px;
  color: rgb(127, 127, 127);
  margin: 0;
  margin-right: 5px;
`
const Hashtags = styled.div`
  display: flex;
  margin-top: 5px;
`
const Hashtag = styled.div`
  font-size: 12px;
  color: #265fa7;
  margin: 0 2.5px;
`
const CustomButton = styled(Button)`
  min-width: 100px;
  margin: 0 7.5px;
  font-size: 12px;
  background: #ffb933;
  height: 30px;
`
const ButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 11px;
  bottom: 33px;
`
const CompareContainerIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => (props.unclickable ? 'not-allowed' : 'pointer')};
`
const CompareIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #333333;
  margin-right: 7.25px;
`
const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #9fd134;
  margin-right: 7.25px;
`
const CompareText = styled.span`
  font-size: 14px;
  color: ${props => (props.isAdded ? '#9FD134' : '#333333')};
  margin-right: 20.75px;
  font-weight: ${props => props.theme.MEDIUM};
`
const TootipContentWrapper = styled.div`
  width: 150px;
  padding: 5px;
  display: flex;
  flex-direction: column;
`
const ReturnRateWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  &:nth-last-of-type(1) {
    margin-bottom: 0px;
  }
`
const ContainerChart = styled.div`
  background: #fbfbfb;
  border-radius: 5px;
  overflow: hidden;
  height: 86px;
  width: 237px;
  canvas {
    height: 86px !important;
    width: 237px; !important;
  }
`
const SmallLabel = styled.span`
  font-size: 10px;
`
const WrapperReturn = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 20px;
`
const ProductSubDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 198px;
  padding-top: 10px;
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => (props.disable ? '#E5E5E5' : '#9FD134')};
  // margin-right: 9px;
  width: 1.0625em !important;
`
const ProductSubDetailsPart = styled.div`
  width: 100%;
  display: flex;
  font-weight: ${props => props.theme.MEDIUM};
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  color: #333333;
  > ${Icon} {
    color: ${props => (props.disable ? '#E5E5E5' : '#9FD134')};
  }
`
const LabelReturn = styled.h3`
  color: ${props => (props.positive ? '#9FD134' : 'red')};
  cursor: pointer;
  font-size: 16px;
  font-weight: ${props => props.theme.BOLD};
  > ${Icon} {
    font-size: 16px;
    color: ${props => (props.positive ? '#9FD134' : 'red')};
  }
`
const WrapText = styled.div`
  ${NameThai} {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .tooltip {
    width: 100%;
    visibility: hidden;
    position: absolute;
    // position: absolute;
    left: 0;
    bottom: 40px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    text-align: left;
    z-index: 199;
  }
  ${NameThai}:hover {
    cursor: pointer;
  }
  ${NameThai}:hover + .tooltip {
    visibility: visible;
    transition: opacity 0.5s ease;
    opacity: 0.8;
  }
`
const WrapRow = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 15px;
`
const WrapColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 180px;
`
const RowInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const RiskLabel = styled.div`
  font-size: 12px;
  font-weight: ${props => props.theme.MEDIUM};
`
const CustomTagA = styled.a``

const CompareButton = connect(
  state => {
    const fundsCompare = state.fund.fundsCompare
    return {fundsCompare}
  },
  {pushToFundsCompare, removeFromFundsCompare},
)(props => {
  const {
    id,
    isCompare,
    fundsCompare,
    pushToFundsCompare,
    removeFromFundsCompare,
  } = props
  const handleCompareClick = useCallback(() => {
    isCompare ? removeFromFundsCompare(id) : pushToFundsCompare(id)
  }, [id, isCompare])

  return (
    <CompareContainerIcon
      unclickable={fundsCompare.length === 5 && !isCompare}
      onClick={handleCompareClick}>
      {isCompare ? (
        <CheckIcon icon={['fas', 'check-circle']} />
      ) : (
        <CompareIcon icon={['far', 'circle']} />
      )}
      <CompareText isAdded={isCompare}>เปรียบเทียบ</CompareText>
    </CompareContainerIcon>
  )
})

const ReturnRateTooltip = props => {
  const {oneYearReturn, threeYearsReturn, fiveYearsReturn} = props
  return (
    <TootipContentWrapper>
      <ReturnRateWrapper>
        <span>1ปี</span>
        <span>{oneYearReturn}%</span>
      </ReturnRateWrapper>
      <ReturnRateWrapper>
        <span>3ปี</span>
        <span>{threeYearsReturn}%</span>
      </ReturnRateWrapper>
      <ReturnRateWrapper>
        <span>5ปี</span>
        <span>{fiveYearsReturn}%</span>
      </ReturnRateWrapper>
    </TootipContentWrapper>
  )
}

export default connect(null)(props => {
  const {
    refId,
    favorited,
    fundCompany,
    symbol,
    nameTh,
    isCompare,
    aimcCategory,
    yieldPolicy,
    taxSavingType,
    latestNav,
    latestNavDate,
    returnPeriod = 1,
    fiveYearsReturn,
    oneYearReturn,
    threeYearsReturn,
    prices,
    comparable = true,
    riskLevel,
  } = props
  const shortNameTh =
    fundCompany && fundCompany.shortNameTh ? fundCompany.shortNameTh : ''
  const logoThumb =
    fundCompany && fundCompany.logoThumb ? fundCompany.logoThumb : ''
  const nav = latestNav || 'N/A'
  const updatedAt = latestNavDate ? getThaiDate(latestNavDate) : 'N/A'
  const selectReturnPeriod = {
    1: oneYearReturn,
    3: threeYearsReturn,
    5: fiveYearsReturn,
  }
  const yearlyReturn = selectReturnPeriod[returnPeriod]
    ? parseFloat(selectReturnPeriod[returnPeriod]).toFixed(2)
    : 'N/A'
  const isPositive = yearlyReturn > 0
  const isTaxSavingType = !!taxSavingType
  const isYieldPolicy = yieldPolicy === 'จ่าย'

  return (
    <Container>
      <IconContainer>
        {comparable ? (
          <CompareButton id={refId} isCompare={isCompare} />
        ) : (
          <div />
        )}
        <FavoriteButton refId={refId} />
      </IconContainer>
      <CardContentContainer>
        <WrapRow>
          <CompanyLogo src={logoThumb || defaultCompanyLogo} />
          <WrapColumn>
            <Symbol>{symbol}</Symbol>
            <WrapText>
              <NameThai>{nameTh}</NameThai>
              <div className="tooltip">
                {symbol}: {nameTh}
              </div>
            </WrapText>
            <CompanyName>{shortNameTh}</CompanyName>
          </WrapColumn>
        </WrapRow>
        <RowInfo>
          <RiskLabel>ระดับความเสี่ยง</RiskLabel>
          <RiskLavel risk={riskLevel} />
        </RowInfo>
        <RowInfo>
          <RiskLabel>ราคา</RiskLabel>
          <WrapLableNav>
            <Nav>{nav}</Nav> <SmallLabel>บาท/หน่วย</SmallLabel>
          </WrapLableNav>
        </RowInfo>
        <ProductSubDetailsPart disable={!isTaxSavingType}>
          ลดหย่อนภาษี
          <Icon
            icon={['fas', isTaxSavingType ? 'check-circle' : 'times-circle']}
          />
        </ProductSubDetailsPart>
        <ProductSubDetailsPart disable={!isYieldPolicy}>
          จ่ายปันผล
          <Icon
            icon={['fas', isYieldPolicy ? 'check-circle' : 'times-circle']}
          />
        </ProductSubDetailsPart>
        <RowInfo>
          <div>
            <RiskLabel>ผลตอบแทนย้อนหลัง {returnPeriod} ปี </RiskLabel>
          </div>
          <WrapperReturn>
            <Tooltip
              placement="right"
              title={
                <ReturnRateTooltip
                  oneYearReturn={oneYearReturn}
                  threeYearsReturn={threeYearsReturn}
                  fiveYearsReturn={fiveYearsReturn}
                />
              }>
              <LabelReturn positive={isPositive}>
                {yearlyReturn}%
                <Icon
                  icon={
                    isPositive ? ['fas', 'caret-up'] : ['fas', 'caret-down']
                  }
                />
              </LabelReturn>
            </Tooltip>
          </WrapperReturn>
        </RowInfo>

        <ContainerChart>
          <LineChart symbol={symbol} prices={prices} />
        </ContainerChart>

        {/* <Hashtags>
          <Hashtag>
            {aimcCategory ? `#${aimcCategory} ` : ''}
            {taxSavingType ? `#${taxSavingType.toUpperCase()}` : ''}
          </Hashtag>
        </Hashtags> */}
      </CardContentContainer>
      <ButtonsWrapper>
        <CustomTagA href={`/fund/products/${refId}`} target="_blank">
          <CustomButton type="border" icons={['fa', 'search']}>
            รายละเอียด
          </CustomButton>
        </CustomTagA>
      </ButtonsWrapper>
      <BottomContainer>
        <NavUpdateAt>ข้อมูล ณ วันที่ {updatedAt}</NavUpdateAt>
      </BottomContainer>
    </Container>
  )
})
