import React from 'react'
import styled from 'react-emotion'
import {compose, branch, withProps, withHandlers, lifecycle} from 'recompose'
import {withSpidus, withValue, withCaption} from '../enhances/index'
import Currency from './Currency'
import Dropdown from './Dropdown'
import spidusStore from '../core/spidus/spidusStore'

const Layout = styled.div`
  display: flex;
  border: 1px solid #ffd482;
  border-radius: 5px;
`
const CustomCurrency = styled(Currency)`
  > div {
    > input {
      border: none;
      border-radius: 4px 0px 0px 4px;
    }
  }
`
const CustomDropdown = styled(Dropdown)`
  > div {
    > div {
      min-width: 98px;
      border: none;
      border-radius: 0px 4px 4px 0px;
      background-color: #ffd482;
      color: #333333 !important;
    }
  }

  ${withCaption.Right} {
    .Select-arrow-zone {
      color: #333333 !important;
    }

    .is-focused,
    .is-open {
      border-radius: 0px 4px 4px 0px !important;
    }

    .Select-value,
    .Select-value-label,
    div {
      font-family: Roboto, Prompt, sans-serif !important;
    }
  }
`

const optionsPeriod = [
  {label: 'ต่อเดือน', value: 'month'},
  {label: 'ต่อปี', value: 'year'},
]

const CurrencyWithPeriod = props => (
  <Layout className={props.className}>
    <CustomCurrency
      onChange={props.onCurrencyChange}
      placeholder={props.placeholder}
      value={props.value.value}
    />
    <CustomDropdown
      onChange={props.onDropdownChange}
      options={optionsPeriod}
      value={props.value.period}
      placeholder={'ระบุ'}
    />
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withCaption(),
  withHandlers({
    onCurrencyChange: props => value => {
      const newValue = {period: props.value.period, value: value}
      spidusStore.updateAnswer(
        props.configName,
        `${props.answerName}`,
        newValue,
      )
    },
    onDropdownChange: props => value => {
      const newValue = {period: value, value: props.value.value}
      spidusStore.updateAnswer(
        props.configName,
        `${props.answerName}`,
        newValue,
      )
    },
    onBlur: props => async e => {
      await (props.onBlur && props.onBlur(props.value))
    },
  }),
)

export default enhance(CurrencyWithPeriod)
