import React from 'react'
import {compose, withProps} from 'recompose'
import {reduxForm} from 'redux-form'
import styled from 'react-emotion'

const Container = styled.form``

export const formId = 'LandingForm'

export default compose(
  withProps(props => {
    const {routeParams} = props
    return {initialValues: {...routeParams}}
  }),
  reduxForm({form: formId}),
)(props => {
  const {children, handleSubmit} = props
  return <Container onSubmit={handleSubmit}>{children}</Container>
})
