import React, {Fragment} from 'react'
import styled from 'react-emotion'

const Wrapper = styled.div`
  padding-bottom: 10.5px;
  color: #333333;
`
const ContentWrapper = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 26px 34px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    width: 746px;
    padding: 26px 24px;
  }
  @media (max-width: 425px) {
    width: 320px;
    padding: 21px 22px;
  }
`
const Row = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 15px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const Caption = styled.div`
  flex: 1;
  font-weight: ${props => props.theme.SEMI};
  font-size: 16px;
`
const Value = styled.div`
  flex: 1;
  font-size: 16px;
`

const DisplayArray = props => {
  const {data} = props
  if (data && data.length > 0) {
    return data.map(person => <div>{person}</div>)
  } else {
    return <div>-</div>
  }
}

export default props => {
  if (!props.secRelatedPersons) return null
  const {secRelatedPersons, fundClasses} = props

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <Row>
            <Caption>
              <div>ผู้สอบบัญชี</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.auditor} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้จัดจำหน่าย</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.distributor} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้สนับสนุนการขายและรับซื้อคืน</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.fundTradingSupporter} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>นายทะเบียนหน่วยลงทุน</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.investmentUnitRegistrar} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้ดูแลผลประโยชน์</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.trustee} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ที่ปรึกษาการลงทุน</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.investmentAdvisors} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้รับมอบหมายงานด้านการจัดการลงทุน</div>
            </Caption>
            <Value>
              <DisplayArray
                data={secRelatedPersons.investmentManagementAssignee}
              />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้ลงทุนรายใหญ่</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.bigInvestor} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้ดูแลสภาพคล่อง</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.marketMaker} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ที่ปรึกษาทางการเงิน</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.financialAdvisor} />
            </Value>
          </Row>
          <Row>
            <Caption>
              <div>ผู้จัดการกองทุน</div>
            </Caption>
            <Value>
              <DisplayArray data={secRelatedPersons.fundManager} />
            </Value>
          </Row>
        </ContentWrapper>
        {fundClasses && (
          <ContentWrapper>
            <Row>
              <Caption>
                <div>ประเภทหน่วยลงทุนที่มีในกองทุน</div>
              </Caption>
              <Value>
                <DisplayArray data={fundClasses} />
              </Value>
            </Row>
          </ContentWrapper>
        )}
      </Wrapper>
    </Fragment>
  )
}
