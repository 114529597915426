import React from 'react'
import styled from 'react-emotion'
import {compose, withState, withHandlers, lifecycle} from 'recompose'
import {connect} from 'react-redux'

import Filter from './Filter'
import OrderList from './OrderList'

import {fetchOrder} from '../../../ducks/profile'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Header = styled.h1`
  margin: 0;

  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.BLUE};
  line-height: 30px;
`

const Order = ({filter, onFilterChange}) => (
  <Container>
    <Header>ประวัติการส่งคำสั่งซื้อ</Header>
    {/* <Filter value={filter} onChange={onFilterChange} /> */}
    <OrderList filter={filter} />
  </Container>
)

const enhancer = compose(
  connect(
    state => ({}),
    {fetchOrder},
  ),
  withState('filter', 'setFilterValue', 'all'),
  withHandlers({
    onFilterChange: props => e => {
      const {filter, setFilterValue} = props
      const inputValue = e.value
      if (filter !== inputValue) {
        setFilterValue(inputValue)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchOrder()
    },
  }),
)

export default enhancer(Order)
