import styled from 'react-emotion'
import {Input} from 'antd'

const {TextArea} = Input

export const CustomTextArea = styled(TextArea)`
  padding: 17px 20px;
  display: block;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ffd482;
  color: black;

  background: ${props => (props.disabled ? '#d6d5d5' : 'white')};

  font-size: 16px;
  font-family: 'Prompt', 'Roboto', sans-serif;
  text-indent: 10px;

  ::-webkit-input-placeholder {
    color: #333333;
    text-indent: 10px;
    font-weight: normal;
    font-family: 'Prompt', 'Roboto', sans-serif;
    opacity: 0.5;
  }
  :-ms-input-placeholder {
    color: #333333;
    text-indent: 10px;
    font-weight: normal;
    font-family: 'Prompt', 'Roboto', sans-serif;
    opacity: 0.5;
  }
  ::-moz-placeholder {
    color: #333333;
    text-indent: 10px;
    font-weight: normal;
    font-family: 'Prompt', 'Roboto', sans-serif;
    opacity: 0.5;
  }
  :-moz-placeholder {
    color: #333333;
    text-indent: 10px;
    font-weight: normal;
    font-family: 'Prompt', 'Roboto', sans-serif;
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    border: 1px solid #ffd482;
    box-shadow: none;
  }
`

export const TextAreaAutoResize = styled(CustomTextArea)`
  min-height: 40px;
  overflow: hidden;
`
