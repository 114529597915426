import React from 'react'
import styled from 'react-emotion'
import {WhiteCard} from '../../components'
import SearchBox from './SearchBox'
import SearchResult from './SearchResult'
import Recommended from './Recommended/index'
import {compose, withState, withHandlers} from 'recompose'
import {withAnswers} from '../../enhances'
import Category from './Recommended/Category'
import {spidusStore} from '../../core/spidus'
import Button from '../../../share/Button'
import {uniq, uniqBy} from 'lodash'

const MainLayout = styled(WhiteCard)`
  flex: 1;
  max-width: 500px;
  min-height: 700px;
`
const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9dc;
  padding: 10px 0px;
  justify-self: flex-end;
`

const SearchConsole = props => (
  <MainLayout>
    <SearchBox answerName="search_query" />
    <Category answerName="search_category" />
    <Recommended
      answerName="recommend_items"
      onSelectItem={props.onSelectItem}
      selectedItems={props.selectedItems}
    />
    <SearchResult
      answerName="search_result_items"
      onSelectItem={props.onSelectItem}
      selectedItems={props.selectedItems}
    />
    <AddButtonContainer>
      <Button onClick={props.onAdd}>
        Add{' '}
        {props.selectedItems.length > 0 && `(${props.selectedItems.length})`}{' '}
      </Button>
    </AddButtonContainer>
  </MainLayout>
)

const enhance = compose(
  withAnswers('noontax_config_web_pro', {
    profileDetails: 'profile_details.value',
  }),
  withState('selectedItems', 'setSelectedItem', []),
  withHandlers({
    onSelectItem: props => item => checked => {
      let selectedItems = [...props.selectedItems]
      if (checked) {
        selectedItems = [...selectedItems, item]
      } else {
        selectedItems = selectedItems.filter(
          selectedItem => selectedItem.type !== item.type,
        )
      }
      props.setSelectedItem(uniqBy(selectedItems, 'type'))
    },
    // onCheckboxChecked: props => ({type, category, value}) => {
    //   if (value) {
    //     if (props.selectedItems.length > 0) {
    //       const hasDuplicatedValue = props.selectedItems.find(item => {
    //         if (item.type === type) {
    //           return true
    //         }
    //       })

    //       if (!hasDuplicatedValue) {
    //         props.setSelectedItem([
    //           ...props.selectedItems,
    //           {type: type, category: category, value: value},
    //         ])
    //       }
    //     } else {
    //       props.setSelectedItem([
    //         ...props.selectedItems,
    //         {type: type, category: category, value: value},
    //       ])
    //     }
    //   } else {
    //     if (props.selectedItems.length > 0) {
    //       const removedItemArray = props.selectedItems.filter(item => {
    //         if (item.type !== type) {
    //           return item
    //         }
    //       })

    //       console.log(removedItemArray)
    //       props.setSelectedItem(removedItemArray)
    //     }
    //   }
    // },
    // onSearchIconClicked: props => value => {
    //   const {setItems} = props
    //   if (value) {
    //     /// spidus
    //     setItems(resultIncomeItems)
    //   } else {
    //     setItems([])
    //   }
    // },
    onAdd: props => () => {
      const existTypes = props.profileDetails.map(
        profileDetail => profileDetail.type,
      )
      const addingTypes = props.selectedItems.map(
        selectedItem => selectedItem.type,
      )
      spidusStore.updateAnswer(
        'noontax_config_web_pro',
        'profile_details',
        uniq([...existTypes, ...addingTypes]),
      )
    },
  }),
)

export default enhance(SearchConsole)
