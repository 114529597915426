import React from 'react'
import {compose, withState} from 'recompose'
import styled, {css} from 'react-emotion'
import {navigate} from '@reach/router'

import Navigator from './Navigator'
import MotorStarter from './Motor/Starter'
import MotorIntermediate from './Motor/Intermediate'
import MotorBannerCover from './Motor/Intermediate/MotorBannerCover'
import LifeStarter from './Life/Starter'
import LifeIntermediate from './Life/Intermediate'
import LifeAdvance from './Life/Advance'
import HealthIntermediate from './Health/Intermediate'
import HealthBannerCover from './Health/Intermediate/HealthBannerCover'
import LifeBannerCover from './Life/Intermediate/LifeBannerCover'

import BannerMobile from '../../static/images/BannerMobile.jpg'
import Banner from '../../static/images/Banner.png'
import CategoryIcon from '../share/CategoryIcon'
import Advance from '../../static/images/Choose-Advance.svg'
import Starter from '../../static/images/Choose-Starter.svg'
import Intermediate from '../../static/images/Choose-Intermediate.svg'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {responsive} from '../../core/style'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0;
`

const NoonSmartContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 80px;
  left: calc(50%);

  ${props =>
    responsive.tablet(css`
      left: calc(40%);
    `)};

  ${props =>
    responsive.mobile(css`
      top: 10%;
    `)};
`

const LandingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const NoonSmartHeader = styled.div`
  font-size: 57px;
  font-weight: bold;
  color: white;

  ${props =>
    responsive.mobile(css`
      font-size: 27px;
    `)};
`

const NoonSubSmartHeader = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 24px;

  ${props =>
    responsive.mobile(css`
      font-size: 9px;
    `)};
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  justify-content: center;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      margin-top: 21px;
      margin-bottom: 40px;
    `)};
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 410px;
  height: 140px;
  background-color: #6a94cb;
  border-radius: 5px;
  color: white;
  margin-bottom: 15px;
  overflow: hidden;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  ${props =>
    props.disabled &&
    css`
      display: none;
      pointer-events: none;
    `}

  :hover {
    background-color: ${props => (props.disabled ? '#6a94cb' : '#265fa7')};
    opacity: ${props => (props.disabled ? '0.5' : '1')};
    color: ${props => (props.disabled ? 'white' : 'white')};

    .starter,
    .intermediate,
    .advance {
      background: linear-gradient(to left, #ffb933, #b27500);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  ${props =>
    responsive.mobile(css`
      width: 170px;
      height: 35px;
      margin: 5px 0;

      background-color: #265fa7;
    `)};
`
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  max-width: 290px;
`

const CardTopic = styled.div`
  font-size: 30px;
  text-align: left;
  line-height: 35px;

  &.customWord {
    display: flex;
  }
  &.customWordMobile {
    display: none;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      min-width: 85px;
      &.customWord {
        display: none;
      }
      &.customWordMobile {
        display: flex;
      }
    `)};
`

const CardDetails = styled.div`
  width: 290px;
  font-size: 12px;
  text-align: left;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const CardImage = styled.img`
  width: 79px;
  height: 79px;
  z-index: 1;
`

const NoonSmartBanner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${Banner});
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  overflow: scroll;
  ${props =>
    responsive.mobile(css`
      background-image: url(${BannerMobile});
      height: calc(100vh - 80px);
      background-position-x: calc(50% - 80px);
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  z-index: 1;
  margin-right: 35px;

  &.starter {
    > path {
      transform: translate(-40.164px, -24.944px);
    }
  }

  &.intermediate {
    > path {
      transform: translate(-12.164px, -24.944px);
    }
  }

  &.advance {
    > path {
      transform: translate(-25.164px, -24.944px);
    }
  }

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
      margin-right: 20px;
    `)};
`

const getHeaderByType = type => {
  switch (type) {
    case 'life':
      return 'แบบประกันชีวิต'
    case 'health':
      return 'แบบประกันสุขภาพ'
    case 'motor':
      return 'แบบประกันรถยนต์'
    default:
      return 'แบบประกันที่ใช่'
  }
}

const getSubHeaderByType = type => {
  switch (type) {
    case 'life':
      return 'วางแผนคุ้มครองทุกความเสี่ยงด้วยประกันชีวิต'
    case 'health':
      return 'สร้างหลักประกันสุขภาพที่ดีวันนี้เพื่อความยั่นยืนในวันข้างหน้า'
    case 'motor':
      return 'อุ่นใจทุกการเดินทางเมื่อมีประกันรถยนต์เคียงข้าง'
    default:
      return 'ใครๆ ก็ชอบ'
  }
}

const Landing = ({category, level}) => (
  <LandingContainer>
    <NoonSmartBanner>
      <NoonSmartContainer>
        <NoonSmartHeader>{getHeaderByType(category)}</NoonSmartHeader>
        <NoonSubSmartHeader>{getSubHeaderByType(category)}</NoonSubSmartHeader>
        <Navigator id={`noon-smart-insurance-${category}-back`} />
        <CardContainer>
          <Card
            id={`noon-smart-insurance-${category}`}
            onClick={() => {
              if (category !== 'health') {
                navigate(`/smart/InsuranceCategory/${category}/starter`)
              }
            }}
            disabled={category === 'health'}>
            <CustomIcon className="starter" icon={['fas', 'starter']} />
            <CardContent>
              <CardTopic className="customWord">วางแผนก่อนซื้อประกัน</CardTopic>
              <CardTopic className="customWordMobile">มือใหม่หัดซื้อ</CardTopic>
              <CardDetails>
                อยากซื้อประกัน แต่ไม่รู้ว่าต้องซื้อเท่าไรถึงจะเหมาะสม
                ต้องการให้นูนเป็นผู้ช่วยในการวางแผนเลือกซื้อแบบประกัน ให้กับคุณ
              </CardDetails>
            </CardContent>
          </Card>
          <Card
            id={`noon-smart-insurance-${category}-intermediate`}
            onClick={() =>
              navigate(`/smart/InsuranceCategory/${category}/intermediate`)
            }>
            <CustomIcon
              className="intermediate"
              icon={['fas', 'intermediate']}
            />
            <CardContent>
              <CardTopic className="customWord">
                เลือกแบบประกันที่ ต้องการซื้อ
              </CardTopic>
              <CardTopic className="customWordMobile">มือสมัครเล่น</CardTopic>
              <CardDetails>
                มีความรู้พื้นฐานทางด้านประกันมีวัตถุประสงค์ชัดเจน
                ต้องการผู้ช่วยในการตัดสินใจเลือกซื้อ
              </CardDetails>
            </CardContent>
          </Card>
          <Card
            id={`noon-smart-insurance-${category}-advance`}
            onClick={() => {
              if (category === 'life') {
                navigate(`/smart/InsuranceCategory/${category}/advance`)
              }
            }}
            disabled={
              category === 'life' ||
              category === 'motor' ||
              category === 'health'
            }
            // disabled={true}
          >
            <CustomIcon className="advance" icon={['fas', 'advance']} />
            <CardContent>
              <CardTopic>มือเก๋า</CardTopic>
              <CardDetails>
                อยากรู้ว่าแบบประกันที่อยู่ในใจ เป็นยังไงเมื่อเทียบกับแบบอื่น
                ให้นูนช่วยจัดอันดับแบบประกันของคุณเทียบกับทุกแบบที่มีในตลาด
              </CardDetails>
            </CardContent>
          </Card>
        </CardContainer>
      </NoonSmartContainer>
    </NoonSmartBanner>
  </LandingContainer>
)

const SubCategory = ({category, level, ...props}) => (
  <Container>
    {!level && <Landing category={category} />}
    {category === 'motor' && level === 'starter' && <MotorStarter {...props} />}
    {category === 'motor' && level === 'intermediate' && (
      <MotorBannerCover {...props} />
    )}
    {category === 'life' && level === 'starter' && <LifeStarter {...props} />}
    {category === 'life' && level === 'intermediate' && (
      <LifeBannerCover {...props} />
    )}
    {category === 'life' && level === 'advance' && (
      <LifeAdvance {...props} level={level} />
    )}
    {category === 'health' && level === 'intermediate' && (
      <HealthBannerCover {...props} />
    )}
  </Container>
)

const enhancer = compose(withState('isActive', 'setActive', false))

export default enhancer(SubCategory)
