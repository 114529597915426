import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {deleteProductCompare, resetCompareList} from '../../../ducks/product'
import {responsive} from '../../../core/style'

import Button from '../../share/Button'
import {displayNumber, obj2Url} from '../../../core/helper'
const Container = styled.div`
  position: sticky;
  position: -webkit-sticky;
  display: ${props => (props.isShow ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: #e5e5e5;
  height: 100px;
  bottom: 0;
  z-index: 7;

  ${props =>
    responsive.tablet(css`
      z-index: 5;
    `)};

  ${props =>
    responsive.tablet(css`
      justify-content: space-between;
      padding: 0;
    `)};

  ${props =>
    responsive.mobile(css`
      justify-content: center;
      padding: 0 30px;
    `)};
`
const ProductSlotContainer = styled.div`
  width: 140px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  opacity: 0.5;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const CustomButton = styled(Button)`
  font-size: 12px;
  height: 40px;
  margin: 0 10px;
`

const ProductInSlotContainer = styled.div`
  position: relative;
  display: flex;
  width: 140px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  justify-content: flex-start;
  align-items: center;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const CompanyLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8.68px;
  margin-left: 11px;
`

const ContentOfProductInSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 54px;
  width: 68px;
  white-space: nowrap;
`
const ContentOfProductInSlot = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`
const CustomIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: -10px;
  font-size: 25px;
  cursor: pointer;
  color: #7f7f7f;
  background-color: white;
  border-radius: 50%;
  border: 3px solid white;
`

const MobileCompare = styled.div`
  display: none;
  text-decoration: underline;
  font-size: 12px;
  color: black;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const CompareCard = ({compareList, index, deleteProductCompare}) => (
  <ProductInSlotContainer>
    <CustomIcon
      icon={['fas', 'times-circle']}
      onClick={() => deleteProductCompare(index, compareList)}
    />
    <CompanyLogo src={compareList[index].companyOfficialLogoUrl} />
    <ContentOfProductInSlotContainer>
      <ContentOfProductInSlot>
        {compareList[index].productName}
      </ContentOfProductInSlot>
      <ContentOfProductInSlot>
        {compareList[index].companyName}
      </ContentOfProductInSlot>
      <ContentOfProductInSlot>
        {displayNumber(
          +compareList[index].amountNet || compareList[index].premium,
        )}{' '}
        บ./ปี
      </ContentOfProductInSlot>
    </ContentOfProductInSlotContainer>
  </ProductInSlotContainer>
)
const slotID = [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}]

const ProductCompare = ({
  compareList,
  deleteProductCompare,
  category,
  params,
  level,
}) => (
  <Container isShow={compareList.length}>
    {/* <MobileCompare
      onClick={() =>
        window.open(
          `/smart/${category}/${level}/product/compare?` +
            compareList.reduce(
              (mem, value) =>
                mem +
                `ids=${category === 'motor' ? value.promotionCode : value.id}&`,
              '',
            ) +
            `${obj2Url(params)}`,
          '_blank',
        )
      }>
      ดูรายการเปรียบเทียบ
    </MobileCompare> */}
    {slotID.map((value, index) =>
      value.id <= compareList.length ? (
        <CompareCard
          key={value.id}
          compareList={compareList}
          index={index}
          deleteProductCompare={deleteProductCompare}
        />
      ) : (
        <ProductSlotContainer key={value.id} />
      ),
    )}
    <CustomButton
      id={`noon-${category}-compare`}
      disabled={compareList.length === 1}
      onClick={() =>
        window.open(
          `/smart/${category}/${level}/product/compare?` +
            compareList.reduce(
              (mem, value) =>
                mem +
                `ids=${category === 'motor' ? value.promotionCode : value.id}&`,
              '',
            ) +
            `${obj2Url(params)}`,
          '_blank',
        )
      }
      icons={['fa', 'search']}>
      เปรียบเทียบ
    </CustomButton>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      compareList: state.product.compareList,
    }),
    {deleteProductCompare, resetCompareList},
  ),

  lifecycle({
    componentDidMount() {
      this.props.resetCompareList()
    },
  }),
)

export default enhancer(ProductCompare)
