import React from 'react'
import Chart from './Chart'
import {min, max} from 'lodash'

export default props => {
  const {prices, symbol} = props
  if (!prices) return null
  const dataChart = []
  const labels = []
  if (prices !== []) {
    prices.forEach(price => {
      dataChart.push(price.nav)
      labels.push(price.priceDate)
    })
  }

  const config = {
    title: {
      text: '',
    },
    chart: {
      height: '86', // 16:9 ratio
      useHTML: true,
      backgroundColor: '#FBFBFB',
      style: {
        textOutline: '',
      },
      spacing: [0, 0, 0, 0],
    },
    scrollbar: {enabled: false},
    yAxis: {
      visible: false,
      min: min(dataChart) - 1,
      max: max(dataChart) + 1,
    },
    xAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        turboThreshold: 2000,
      },
    },
    series: [
      {
        type: 'spline',
        data: dataChart,
        color: '#FFB933',
        pointStart: 0,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
  }
  return <Chart id={symbol} key={symbol} config={config} />
}
