export const careerContentDeatailsList = [
  {
    id: 0,
    headerText: 'CAPTAIN AMERICA',
    description: 'เป็นทัพหน้า ปกป้องคนในทีม',
    skill: 'โล่กัปตัน ชุดกัปตัน',
  },
  {
    id: 1,
    headerText: 'WOLVERINE',
    description: 'มือขวา ข่วนไม่เลี้ย',
    skill: ' กรงเล็บ เลือดไม่อั้น',
  },
  {
    id: 2,
    headerText: 'LORD VOLDEMORT',
    description: 'มือซ้าย ทำงานสกปรกทุกชนิด',
    skill: 'ไม้คทากายสิทธิ์ พลังเวทย์ไม่อั้น',
  },
  {
    id: 3,
    headerText: '7 DWARFS',
    description: 'สมุนทั้งเจ็ด',
    skill: 'ขวาน ความบ้าคลั่ง ติดหญิง',
  },
]
