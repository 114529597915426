import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'

import Button from '../share/Button'
import joinOurTeam from '../../static/images/joinourteam.jpg'
import {careerContentDeatailsList} from './CareerDetails'
import CareerModal from './CareerModal'
import Modal from '../share/Modal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const AboutUsImage = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-image: url(${joinOurTeam});
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position-y: 490px;
`

const CareerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  height: 120px;
`

const CareerContentPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div:nth-child(even) {
    background-color: rgba(106, 148, 203, 0.1);
  }
`

const CareerContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
`

const CareerContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 400px;
`

const CareerContentHeader = styled.div`
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-right: 30px;
`

const CareerContentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const CareerContentDetailsText = styled.div`
  font-size: 16px;
  &.skill {
    font-weight: bold;
  }
`

const FooterCareerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 81px;
  background-color: #6a94cb;
`

const FooterCareerText = styled.div`
  font-size: 16px;
  color: white;
  margin-right: 30px;
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-left: 10px;
  span {
    font-size: 12px;
  }
`

const CustomModal = styled(Modal)`
  &.ant-modal {
    width: unset !important;
    display: flex;
    justify-content: center;
    margin: 0 calc((100vw - 800px) / 2);
  }
  .ant-modal-content {
    width: 600px;
    padding: 50px 50px 50px 50px;
  }
`

const Header = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 34px;
`

const Career = ({isShowModal, setIsShowModal}) => (
  <Container>
    <AboutUsImage />
    <CareerHeader>เรากำลังมองหา..</CareerHeader>
    <CareerContentPart>
      {careerContentDeatailsList.map(value => (
        <CareerContentContainer key={value.id}>
          <CareerContentWrapper>
            <CareerContentHeader>{value.headerText}</CareerContentHeader>
            <CareerContentDetailsContainer>
              <CareerContentDetailsText>
                {value.description}
              </CareerContentDetailsText>
              <CareerContentDetailsText className="skill">
                ทักษะที่ต้องการ
              </CareerContentDetailsText>
              <CareerContentDetailsText>{value.skill}</CareerContentDetailsText>
            </CareerContentDetailsContainer>
          </CareerContentWrapper>
        </CareerContentContainer>
      ))}
    </CareerContentPart>
    <FooterCareerContainer>
      <FooterCareerText>ต้องการสอบถามเพิ่มเติม?</FooterCareerText>
      <CustomButton
        onClick={() => {
          setIsShowModal(true)
        }}
        icons={['fa', 'pen']}>
        ติดต่อเรา
      </CustomButton>
    </FooterCareerContainer>
    <CustomModal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
      <Header>สนใจสอบถามตำแหน่งงาน</Header>
      <CareerModal setIsShowModal={setIsShowModal} />
    </CustomModal>
  </Container>
)

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  connect(
    (state, props) => ({}),
    {},
  ),
)

export default enhancer(Career)
