import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withState} from 'recompose'
import HeaderDetail from './HeaderDetail'
import {responsive} from '../../../../core/style'

const Footer = styled.div`
  font-size: 16px;
  max-width: 666px;
  margin: 30px 0 40px;

  ${props =>
    responsive.mobile(css`
      font-size: 12px;
      width: 280px;
    `)};
`
const ItemCenter = styled.div`
  display: flex;
  justify-content: center;
`
const FooterDetail = ({selectedNote, setSelectedNote, category}) => (
  <React.Fragment>
    <HeaderDetail
      selected={selectedNote}
      setSelected={setSelectedNote}
      header={'หมายเหตุ'}
    />
    <ItemCenter>
      {category === 'motor' && selectedNote && (
        <Footer>
          *เบี้ยประกันที่แสดง เป็นเบี้ยประกันมาตรฐาน
          สำหรับผู้ที่มีอาชีพและประวัติการขับขี่ในเกณฑ์ความเสี่ยงปกติ
          ซึ่งอาจไม่ตรงกับเบี้ยประกันที่คุณต้องจ่ายจริง
          ซึ่งต้องพิจารณาความเสี่ยงของคุณอย่างละเอียดจากตัวแทนและบริษัทประกันวินาศภัยอีกครั้งหนึ่ง
          <br />
          <br />
          คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันวินาศภัยที่เสนอนั้น
          ไม่ใช่คำแนะนำให้ลงทุนหรือเอาประกันภัยตาม
          เนื่องจากการจัดแบบกรมธรรม์ประกันวินาศภัยให้เหมาะสมกับผู้เอาประกันภัยแต่ละรายนั้น
          นอกจากจะขึ้นอยู่กับเงื่อนไขรายบุคคลเกี่ยวกับสถานภาพทางการเงิน ความมั่น
          คงของรายได้แล้วยังขึ้นอยู่กับเงื่อนไขอื่นๆ อีก เช่น
          ประวัติการขับขี่ของผู้เอาประกันภัย
          ข้อมูลที่ต้องมีการรับรองเพื่อให้ผู้รับประกันภัยประกอบการตัดสินใจรับประกันภัย
          <br />
          <br />
          ความสมบูรณ์ ความถูกต้องของข้อมูล
          และคำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันวินาศภัยที่เสนอนั้น
          ผู้จัดทำได้รับข้อมูลจากบุคคลที่สาม ซึ่งอาจจะมีการเปลี่ยนแปลงได้
          และทางผู้จัดทำจะไม่รับผิดชอบต่อความเสียหายใดๆ
          อันเกิดขึ้นจากการใช้งานของโปรแกรมนี้ ไม่ว่าโดยทางตรงหรือทางอ้อม
          ดังนั้นการตัดสินใจเอาประกันภัยจึงขึ้นกับวิจารณญาณของผู้เอาประกันภัยเป็นสำคัญ
          <br />
          <br />
          กรุณาติดต่อสอบถามตัวแทนประกันวินาศภัย นายหน้าประกันวินาศภัย
          หรือบริษัทประกันวินาศภัย
          เพื่อตรวจสอบความถูกต้องสมบูรณ์ของข้อมูลแบบกรมธรรม์ประกันวินาศภัยก่อนการเอาประกันภัย
          และโปรดศึกษาข้อมูล นโยบาย ความเสี่ยง ปัจจัยที่เกี่ยวข้อง
          ก่อนการเอาประกันภัย
        </Footer>
      )}
      {category === 'health' && selectedNote && (
        <Footer>
          *เบี้ยประกันที่แสดง เป็นเบี้ยประกันมาตรฐาน
          สำหรับผู้ที่มีอาชีพและประวัติด้านสุขภาพในเกณฑ์ความเสี่ยงปกติ
          ซึ่งอาจไม่ตรงกับเบี้ยประกันที่คุณต้องจ่ายจริง
          ซึ่งต้องพิจารณาความเสี่ยงของคุณอย่างละเอียดจากตัวแทนและบริษัทประกันชีวิตอีกครั้งหนึ่ง
          <br />
          <br />
          คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น
          ไม่ใช่คำแนะนำให้ลงทุนหรือเอาประกันภัยตาม
          เนื่องจากการจัดแบบกรมธรรม์ประกันชีวิตให้เหมาะสมกับผู้เอาประกันภัยแต่ละรายนั้น
          นอกจากจะขึ้นอยู่กับเงื่อนไขรายบุคคลเกี่ยวกับสถานภาพทางการเงิน
          ความมั่นคงของรายได้แล้วยังขึ้นอยู่กับเงื่อนไขอื่นๆ อีก เช่น
          สุขภาพของผู้เอาประกันภัย
          ข้อมูลที่ต้องมีการรับรองเพื่อให้ผู้รับประกันภัยประกอบการตัดสินใจรับประกันภัย
          การคาดการณ์อัตราผลตอบแทนและความเสี่ยงต่างๆ
          <br />
          <br />
          คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น
          ผู้จัดทำได้รับข้อมูลจากบุคคลที่สาม
          ผู้จัดทำจึงไม่สามารถรับประกันความสมบูรณ์ และถูกต้องของข้อมูล
          ซึ่งอาจจะมีการเปลี่ยนแปลงได้
          และทางผู้จัดทำจะไม่รับผิดชอบต่อความเสียหายใดๆ
          อันเกิดขึ้นจากการใช้งานของโปรแกรมนี้ ไม่ว่าโดยทางตรงหรือทางอ้อม
          ดังนั้นการตัดสินใจเอาประกันภัยจึงขึ้นกับวิจารณญาณของผู้เอาประกันภัยเป็นสำคัญ
          <br />
          <br />
          กรุณาติดต่อสอบถามตัวแทนประกันชีวิต นายหน้าประกันชีวิต
          หรือบริษัทประกันชีวิต
          เพื่อตรวจสอบความถูกต้องสมบูรณ์ของข้อมูลแบบกรมธรรม์ประกันชีวิตก่อนการเอาประกันภัย
          และโปรดศึกษาข้อมูล นโยบาย ความเสี่ยง ปัจจัยที่เกี่ยวข้อง
          ก่อนการเอาประกันภัย
        </Footer>
      )}
      {category === 'life' && selectedNote && (
        <Footer>
          *เบี้ยประกันที่แสดง เป็นเบี้ยประกันมาตรฐาน
          สำหรับผู้ที่มีอาชีพและประวัติด้านสุขภาพในเกณฑ์ความเสี่ยงปกติ
          ซึ่งอาจไม่ตรงกับเบี้ยประกันที่คุณต้องจ่ายจริง
          ซึ่งต้องพิจารณาความเสี่ยงของคุณอย่างละเอียดจากตัวแทนและบริษัทประกันชีวิตอีกครั้งหนึ่ง
          <br />
          <br />
          คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น
          ไม่ใช่คำแนะนำให้ลงทุนหรือเอาประกันภัยตาม
          เนื่องจากการจัดแบบกรมธรรม์ประกันชีวิตให้เหมาะสมกับผู้เอาประกันภัยแต่ละรายนั้น
          นอกจากจะขึ้นอยู่กับเงื่อนไขรายบุคคลเกี่ยวกับสถานภาพทางการเงิน
          ความมั่นคงของรายได้แล้วยังขึ้นอยู่กับเงื่อนไขอื่นๆ อีก เช่น
          สุขภาพของผู้เอาประกันภัย
          ข้อมูลที่ต้องมีการรับรองเพื่อให้ผู้รับประกันภัยประกอบการตัดสินใจรับประกันภัย
          การคาดการณ์อัตราผลตอบแทนและความเสี่ยงต่างๆ
          <br />
          <br />
          คำอธิบายเกี่ยวกับแบบกรมธรรม์ประกันชีวิตที่เสนอนั้น
          ผู้จัดทำได้รับข้อมูลจากบุคคลที่สาม
          ผู้จัดทำจึงไม่สามารถรับประกันความสมบูรณ์ และถูกต้องของข้อมูล
          ซึ่งอาจจะมีการเปลี่ยนแปลงได้
          และทางผู้จัดทำจะไม่รับผิดชอบต่อความเสียหายใดๆ
          อันเกิดขึ้นจากการใช้งานของโปรแกรมนี้ ไม่ว่าโดยทางตรงหรือทางอ้อม
          ดังนั้นการตัดสินใจเอาประกันภัยจึงขึ้นกับวิจารณญาณของผู้เอาประกันภัยเป็นสำคัญ
          <br />
          <br />
          กรุณาติดต่อสอบถามตัวแทนประกันชีวิต นายหน้าประกันชีวิต
          หรือบริษัทประกันชีวิต
          เพื่อตรวจสอบความถูกต้องสมบูรณ์ของข้อมูลแบบกรมธรรม์ประกันชีวิตก่อนการเอาประกันภัย
          และโปรดศึกษาข้อมูล นโยบาย ความเสี่ยง ปัจจัยที่เกี่ยวข้อง
          ก่อนการเอาประกันภัย
        </Footer>
      )}
    </ItemCenter>
  </React.Fragment>
)

const enhancer = compose(withState('selectedNote', 'setSelectedNote', true))

export default enhancer(FooterDetail)
