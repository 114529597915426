import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled, {css} from 'react-emotion'
import {compose, withProps, withHandlers, lifecycle, withState} from 'recompose'
import * as path from '../common/path'
import {withAnswers} from '../enhances'
import {spidusStore} from '../core/spidus'
import {reduxForm} from 'redux-form'
import {
  Field,
  Button,
  Switch,
  Currency,
  CurrencyWithPeriod,
} from '../../NoonQuestion/components/index'
import {isEqual} from 'lodash'
import TooltipDonateModal from './TooltipDonateModal'
import {isClient} from '../../NoonQuestion/common/helper'

const Layout = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  width: 100%;
  height: 100%;
`
const WrapperLayout = styled.div`
  margin-top: 10px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    max-width: 320px;
    padding: 0px 20px;
  }
`
const GoBack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  > svg {
    margin-right: 12px;
  }
  margin-bottom: 30px;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
`
const Topic = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: Prompt;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
`
const Description = styled.div`
  font-size: 12px;
  font-family: Prompt;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
`
const Body = styled.div`
  max-width: 250px;
`
const CustomSwitch = styled(Switch.Gender)`
  color: #ffffff;
  margin-bottom: 16px;
  flex-direction: column;
  min-width: 250px;
  > div:first-child {
    margin-bottom: 6px;
    > div {
      color: #ffffff;
      font-family: Prompt !important;
    }
  }
  > div:last-child {
    width: 100%;
  }
`
const CustomCurrency = styled(Currency)`
  color: #ffffff;
  margin-bottom: 16px;
  flex-direction: column;
  min-width: 250px;
  > div:first-child {
    margin-bottom: 6px;
    > div {
      color: #ffffff;
      font-family: Prompt !important;
    }
  }
  > div:last-child {
    width: 100%;
    > input {
      ::placeholder {
        text-align: left;
        font-family: Prompt;
      }
    }
  }
`
const CustomCurrencyWithPeriod = styled(CurrencyWithPeriod)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  width: 100%;
  align-items: flex-start;

  > div:first-child {
    margin-bottom: 10px;
    > div {
      color: #ffffff;
      font-family: Prompt !important;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;

      > input:first-child {
        max-width: calc(100% - 98px);
        min-width: calc(100% - 98px);
        border-radius: 4px 0px 0px 4px;
        ::placeholder {
          text-align: left;
          font-family: Prompt;
        }
      }

      > div:last-child {
        min-width: 98px;
        max-width: 98px;
        background-color: #ffd482;
        .Select-arrow-zone {
          color: #333333;
        }
        border-radius: 0px 4px 4px 0px !important;

        .Select-value-label {
          margin-left: -10px;
          font-size: 16px;
        }
        .Select-option {
          color: #333333 !important;
        }
      }
    }
  }
`

const WrapperYear = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: Prompt;
  color: #ffffff;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 25px;
`
const MobileCutWord = styled.br`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`
const Titie = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: Prompt;
  color: #ffffff;
  text-align: left;
  margin-bottom: 6px;
  margin-top: 45px;
`
const Footer = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-bottom: 15px;
  /* background-color: #4375b5; */
`
const WrapperFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 250px;
`
const ResetValue = styled.div`
  width: 83px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  ${props =>
    props.disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`
const SaveButton = styled(Button)`
  min-width: 167px;
  max-width: 167px;
  min-height: 38px;
  ${props =>
    props.disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const EditPlanningDetails = props => (
  <Layout>
    <WrapperLayout>
      <GoBack onClick={props.onGoBack}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        กลับ
      </GoBack>

      <Header>
        <Topic>แก้ไขและกรอกรายละเอียด</Topic>
        <Description>
          กรอกละเอียดเพื่อความแม่นยำในการคำนวณภาษีของคุณ
        </Description>
      </Header>

      <Body>
        <Field
          name="gender"
          caption="เพศ"
          component={CustomSwitch}
          marginBottom="16px"
        />
        <Field
          name="age"
          caption="อายุ"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          min={0}
          max={100}
        />
        <Field
          name="income_salary"
          caption="รายได้"
          component={CustomCurrencyWithPeriod}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />

        <WrapperYear>
          รายการที่สามารถนำมาลด
          <MobileCutWord />
          หย่อนภาษีได้ในปี 2562
        </WrapperYear>
        <Titie>ประกันที่ซื้อไปแล้ว</Titie>
        <Field
          name="deduct_life_insurance"
          caption="ประกันชีวิต"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
        <Field
          name="deduct_pension_insurance"
          caption="ประกันบำนาญ"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
        <Field
          name="deduct_health_insurance"
          caption="ประกันสุขภาพ"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />

        <Titie>กองทุนที่ซื้อไปแล้ว</Titie>
        <Field
          name="deduct_ltf"
          caption="LTF"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
        <Field
          name="deduct_rmf"
          caption="RMF"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />

        <Titie>เงินบริจาค</Titie>
        <Field
          name="deduct_special_donate"
          caption="บริจาคเพื่อการศึกษา / โรงพยาบาล"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
        <Field
          name="deduct_general_donate"
          caption={
            <div>
              บริจาคทั่วไป{' '}
              <FontAwesomeIcon
                style={{color: '#C1C1C1'}}
                onClick={props.onTooltipDonate}
                icon={['fas', 'info-circle']}
              />
            </div>
          }
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />

        <Titie>ลดหย่อนอื่นๆ</Titie>
        <Field
          name="deduct_sso"
          caption="ประกันสังคมรายปี"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
        <Field
          name="deduct_provident_fund"
          caption="กองทุนสำรองเลี้ยงชีพ"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
        <Field
          name="deduct_homeloan_interest"
          caption="ดอกเบี้ยบ้าน"
          component={CustomCurrency}
          placeholder="กรุณาระบุ"
          max={100000000000}
        />
      </Body>
    </WrapperLayout>

    <Footer>
      <WrapperFooter>
        {/* <ResetValue disable={props.pristine} onClick={props.onReset}>
          รีเซ็ต
        </ResetValue> */}
        <SaveButton disable={props.pristine} onClick={props.onSave}>
          บันทึก
        </SaveButton>
      </WrapperFooter>
    </Footer>

    <TooltipDonateModal
      isShowModal={props.tooltipDonate}
      setIsShowModal={props.setTooltipDonate}
    />
  </Layout>
)

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    age: 'age.value',
    gender: 'gender.value',
    incomeSalary: 'income_salary.value',
    deductLifeInsurance: 'deduct_life_insurance.value',
    deductPensionInsurance: 'deduct_pension_insurance.value',
    deductHealthInsurance: 'deduct_health_insurance.value',
    deductLtf: 'deduct_ltf.value',
    deductRmf: 'deduct_rmf.value',
    deductSpecialDonate: 'deduct_special_donate.value',
    deductGeneralDonate: 'deduct_general_donate.value',
    deductSso: 'deduct_sso.value',
    deductProvidentFund: 'deduct_provident_fund.value',
    deductHomeloanInterest: 'deduct_homeloan_interest.value',
  }),
  withProps(props => {
    return {
      initialValues: {
        age: props.age,
        gender: props.gender,
        income_salary: props.incomeSalary,
        deduct_life_insurance: props.deductLifeInsurance,
        deduct_pension_insurance: props.deductPensionInsurance,
        deduct_health_insurance: props.deductHealthInsurance,
        deduct_ltf: props.deductLtf,
        deduct_rmf: props.deductRmf,
        deduct_special_donate: props.deductSpecialDonate,
        deduct_general_donate: props.deductGeneralDonate,
        deduct_sso: props.deductSso,
        deduct_provident_fund: props.deductProvidentFund,
        deduct_homeloan_interest: props.deductHomeloanInterest,
      },
    }
  }),
  withState('tooltipDonate', 'setTooltipDonate', false),
  reduxForm({form: 'EditPlanningDetailsForm'}),
  withHandlers({
    onTooltipDonate: props => () => {
      props.setTooltipDonate(true)
    },
    onReset: props => async () => {
      await props.reset()
    },
    onSave: props =>
      props.handleSubmit(async values => {
        const data = {
          ...values,
        }
        await spidusStore.updateAnswers('noontax_config_web_tax_planning', data)

        await props.history.push(path.planningPath())
        if (isClient) {
          JSON.parse(window.scrollTo(0, 0))
        }
      }),
  }),
  withHandlers({
    onGoBack: props => () => {
      if (!props.pristine) {
        if (confirm('คุณมีการแก้ไขบันทึกข้อมูลก่อนไหม')) {
        } else {
          props.history.push(path.planningPath())
        }
      } else {
        props.history.push(path.planningPath())
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
        this.props.initialize(this.props.initialValues)
      }
    },
  }),
)

export default enhancer(EditPlanningDetails)
