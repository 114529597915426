import {withProps} from 'recompose'

const withClassName = (conditionFunc, extendedStyleClassName) =>
  withProps(props => {
    if (conditionFunc(props)) {
      const classNames = []
      if (props.className) {
        classNames.push(props.className)
      }
      if (extendedStyleClassName) {
        classNames.push(extendedStyleClassName)
      }
      return {
        className: classNames.join(' '),
      }
    }
  })

export default withClassName
