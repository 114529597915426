import Button from '../../share/Button'
import {compose, withHandlers, withState, withProps, branch} from 'recompose'
import {omitProps, withRouter} from '../enhances'

export const enhancer = compose(
  branch(
    props => props.to,
    compose(
      withRouter(),
      withHandlers({
        onClick: props => () => {
          if (props.onClick) {
            props.onClick(props)
          }
          props.history.push(props.to)
        },
      }),
    ),
    compose(
      withState('eventLoading', 'setEventLoading', false),
      withHandlers({
        onClick: ({onClick, setEventLoading}) => async e => {
          if (onClick) {
            setEventLoading(true)

            try {
              await onClick(e)
            } catch (e) {
              // console.log(e)
            }
            setEventLoading(false)
          }
        },
      }),
      withProps(({loading, eventLoading}) => ({
        loading: loading || eventLoading,
      })),
      omitProps('setEventLoading', 'eventLoading'),
    ),
  ),
)

export default enhancer(Button)
