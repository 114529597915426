import React from 'react'
import styled from 'react-emotion'
import {getThaiDate} from '../../../core/helper'
const DateInfo = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
  padding: 15px 15px 0 0;
`

const Date = ({date}) => {
  const dateLabel = date ? getThaiDate(date, 'LL') : 'N/A'

  return <DateInfo>ข้อมูล ณ วันที่ {dateLabel}</DateInfo>
}

export default Date
