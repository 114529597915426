import React from 'react'
import styled from 'react-emotion'
import {Location, Link} from '@reach/router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const LeftArrow = styled(FontAwesomeIcon)`
  color: ${props => props.theme.ORANGE};
  font-size: 25px;
`
const Back = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  :hover {
    background: #265fa7;
    ${LeftArrow} {
      color: white;
    }
  }
`
const getBackPath = path =>
  path.length <= 3 ? '/smart' : path.slice(0, path.length - 1).join('/')

const IconContainer = styled.div`
  display: flex;
`
const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.ORANGE};
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;

  background: white;
  border-radius: 50%;

  > .starter {
    > path {
      transform: translate(-45.364px, -24.944px);
    }
  }

  > .intermediate {
    > path {
      transform: translate(-15.464px, -24.944px);
    }
  }

  > .advance {
    > path {
      transform: translate(-27.164px, -24.944px);
    }
  }
  :hover {
    background: #265fa7;
    ${Icon} {
      color: white;
    }
  }
`

const getIcon = name => {
  switch (name) {
    case 'motor':
      return ['fas', 'car']
    case 'life':
      return ['fas', 'user-shield']
    case 'health':
      return ['fas', 'briefcase-medical']
    case 'starter':
      return ['fas', 'starter']
    case 'intermediate':
      return ['fas', 'intermediate']
    case 'advance':
      return ['fas', 'advance']
    default:
      return []
  }
}

const Category = ({path}) => {
  var categories = []
  if (path.length > 3) {
    if (path[3]) categories.push(path[3])
    if (path[4]) categories.push(path[4])
  }
  return (
    <IconContainer>
      {categories.map(category => (
        <IconWrapper key={category}>
          <Icon className={category} icon={getIcon(category)} />
        </IconWrapper>
      ))}
    </IconContainer>
  )
}

const Navigator = ({className, id}) => (
  <Location>
    {({location}) => {
      const splitedPath = location.pathname.split('/')
      const backLink =
        location.pathname === '/smart/InsuranceCategory/health/intermediate'
          ? '/smart/InsuranceCategory'
          : getBackPath(splitedPath)
      return (
        splitedPath.length > 2 && (
          <Container className={className}>
            <Back id={id} to={backLink}>
              <LeftArrow icon={['fas', 'angle-left']} />
            </Back>
            <Category path={splitedPath} />
          </Container>
        )
      )
    }}
  </Location>
)

export default Navigator
