import React, {Fragment} from 'react'
import styled from 'react-emotion'
import {compose, withProps, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {displayNumber, obj2Url} from '../../../../core/helper'
import Button from '../../../share/Button'
import ModalFavorite from '../../../share/ModalFavorite'
import ModalCatalogue from '../ModalCatalogue'

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 420px;
  background-color: white;
  border: 1px solid #7f7f7f;
  border-radius: 5px;
  min-width: 300px;
  margin: 0 10px 20px 10px;
  padding: 20px 20px 29px 20px;
`

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: red;
  cursor: pointer;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 33px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const Logo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 17px;
  min-width: 50px;
`

export const CompanyLogo = styled.img`
  width: 100%;
  height: 100%;
  /* TODO: Remove this to use official logo */
  border-radius: 50px;
`

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProductName = styled.div`
  font-size: 12px;
  font-weight: 600;
`

export const CompanyName = styled.div`
  font-size: 10px;
`

export const RowInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  align-items: baseline;
`

export const Content = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  margin-right: 5px;
  min-width: fit-content;
`

export const ContentRegular = styled(Content)`
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  margin-right: 0;
  white-space: nowrap;
  min-width: unset;
`

export const StarContainer = styled.div`
  display: flex;
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: black;
`

export const IconCheckCircle = styled(Icon)`
  color: #9fd134;
`

export const IconTimesCircle = styled(Icon)`
  color: #e5e5e5;
`

export const Premium = styled.div`
  > span {
    font-size: 16px;
    font-family: 'Prompt';
    font-weight: 700;
    color: #333333;
  }

  text-align: left;
  font-size: 10px;
  font-weight: 500;
  color: #333333;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`

export const CustomTagA = styled.a``
export const CustomButton = styled(Button)`
  min-width: 100px;
  margin: 0 7.5px;
  font-size: 12px;
`

const StarIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: #ffd900;
`

export const renderStar = noonStar => {
  const arrayStar = []
  for (var i = 1; i <= noonStar; i++) {
    arrayStar.push(<StarIcon key={i} icon={['fas', 'star']} />)
  }
  return arrayStar
}

const Life = props => (
  <Layout>
    {props.tag ? <Fragment>{props.tag}</Fragment> : null}

    <ActionWrapper>
      {props.SpecialActionComponent ? (
        <props.SpecialActionComponent {...props} />
      ) : (
        <div />
      )}

      <HeartIcon
        id={
          props.localFavorite
            ? `noon-life-product-unfavorite-${props.data.id}`
            : `noon-life-product-favorite-${props.data.id}`
        }
        onClick={() => {
          props.isLogin
            ? props.onFavorite(
                !props.localFavorite,
                props.data.id,
                'life',
                props.data.paramsEncode,
              )
            : props.setIsShowModalFavorite(!props.isShowModalFavorite)
        }}
        icon={props.localFavorite ? ['fas', 'heart'] : ['far', 'heart']}
      />
    </ActionWrapper>

    <DescriptionWrapper>
      <Body>
        <Title>
          <Logo>
            <CompanyLogo src={props.data.companyOfficialLogoUrl} />
          </Logo>
          <WrapperTitle>
            <ProductName>{props.data.productName}</ProductName>
            <CompanyName>{props.data.companyName}</CompanyName>
          </WrapperTitle>
        </Title>

        <RowInfo>
          {props.data.noonStar !== 0 && (
            <Fragment>
              <Content>noon star</Content>
              <StarContainer>{renderStar(props.data.noonStar)}</StarContainer>
            </Fragment>
          )}
        </RowInfo>

        <RowInfo>
          <Content>เบี้ยปีละ</Content>
          <Premium>
            <span>{displayNumber(parseInt(props.data.premium))}</span>
            &nbsp;&nbsp;บาท/ปี
          </Premium>
        </RowInfo>

        {props.data.payPeriod && (
          <RowInfo>
            <Content>จ่ายเบี้ย</Content>
            <ContentRegular>{`${displayNumber(
              props.data.payPeriod,
            )}  ปี `}</ContentRegular>
          </RowInfo>
        )}

        <RowInfo>
          <Content>คุ้มครอง</Content>
          <ContentRegular>{`${props.data.protectionPeriod} ปี (จนถึง อายุ ${props.data.protectionMaxAge} ปี)`}</ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ได้รับเมื่อเสียชีวิต</Content>
          <ContentRegular>
            {`เริ่มต้นที่ ${displayNumber(props.data.firstYearSumInsured)} บาท`}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>ลดหย่อนภาษี</Content>
          <ContentRegular>
            {props.data.isTaxSaving ? (
              <IconCheckCircle icon={['fas', 'check-circle']} />
            ) : (
              <IconTimesCircle icon={['fas', 'times-circle']} />
            )}
          </ContentRegular>
        </RowInfo>

        <RowInfo>
          <Content>นโยบายจ่ายปันผล</Content>
          <ContentRegular>
            {props.data.hasDividend ? (
              <IconCheckCircle icon={['fas', 'check-circle']} />
            ) : (
              <IconTimesCircle icon={['fas', 'times-circle']} />
            )}
          </ContentRegular>
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA href={props.pathUrl} target="_blank">
          <CustomButton
            className="noon-life-interest"
            type="border"
            icons={['fa', 'search']}>
            รายละเอียด
          </CustomButton>
        </CustomTagA>
        <CustomButton
          className="noon-life-buy"
          onClick={() => {
            props.setIsShowModal(true)
          }}
          icons={['fa', 'shopping-cart']}>
          สนใจซื้อ
        </CustomButton>
      </Footer>
    </DescriptionWrapper>

    <ModalFavorite
      isShowModal={props.isShowModalFavorite}
      setIsShowModal={props.setIsShowModalFavorite}
      isReload
    />
    <ModalCatalogue
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      category="life"
      params={props.params}
      premium={props.data.premium}
      id={props.data.id}
      companyId={props.data.companyId}
      paramsEncode={props.data.paramsEncode}
      productName={props.data.productName}
    />
  </Layout>
)

export const pathUrlLife = props => {
  return `/smart/life/${
    props.data.level === undefined ? 'allLevel' : props.data.level
  }/product/${props.data.id}?${obj2Url({
    ...props.params,
    premium: parseInt(props.data.premium),
    sumInsured: parseInt(props.data.sumInsured),
    id: props.data.id,
    encodeFlag: props.fromFavoriteProfile ? 'true' : 'false',
    paramsEncode: props.data.paramsEncode,
    companyId: props.data.companyId,
  })}`
}

const enhance = compose(
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withState('isShowModal', 'setIsShowModal', false),
  withProps(props => ({
    pathUrl: `${pathUrlLife(props)}`,
  })),
)

export default enhance(Life)
