import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import Button from '../../../share/Button'
import ModalCatalogue from '../ModalCatalogue'

const CustomButton = styled(Button)`
  font-size: 12px;
  width: 100px;
  height: 30px;
  margin-top: 12px;
  margin-left: 55px;
`

const BuyCatalogue = ({
  isShowModal,
  setIsShowModal,
  params,
  category,
  productName,
}) => (
  <React.Fragment>
    <CustomButton
      id="noon-buy-catalogue-buy"
      onClick={() => {
        setIsShowModal(true)
      }}
      icons={['fa', 'shopping-cart']}>
      สนใจซื้อ
    </CustomButton>
    <ModalCatalogue
      isShowModal={isShowModal}
      setIsShowModal={setIsShowModal}
      {...params}
      params={params}
      category={category}
      productName={productName}
    />
  </React.Fragment>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLogin: state.auth.isLogin,
    }),
    {},
  ),
  withState('isShowModal', 'setIsShowModal', false),
)

export default enhancer(BuyCatalogue)
