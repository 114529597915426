import {takeLatest, put} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {navigate} from '@reach/router'
import {httpGet, httpPost, httpPut, API_HOST} from '../../core/httpClient'

const LOGIN = 'LOGIN'
const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN'
const LOGOUT = 'LOGOUT'
const REGISTER = 'REGISTER'
const GET_USER_INFO = 'GET_USER_INFO'
const FORGET_PASSWORD = 'FORGET_PASSWORD'
const SET_DATA = 'SET_DATA'
const RESET_PASSWORD = 'RESET_PASSWORD'
const CHECK_TOKEN = 'CHECK_TOKEN'
const RESEND_EMAIL = 'RESEND_EMAIL'
const INTEREST = 'INTEREST'
const NOTIFICATION = 'NOTIFICATION'
const GOOGLE_LOGIN = 'GOOGLE_LOGIN'

export const setData = Creator(SET_DATA, 'data')
export const login = Creator(LOGIN, 'data', 'modal', 'callback', 'isReload')
export const facebookLogin = Creator(
  FACEBOOK_LOGIN,
  'data',
  'modal',
  'callback',
)
export const logout = Creator(LOGOUT)
export const getUserInfo = Creator(GET_USER_INFO)
export const register = Creator(REGISTER, 'data', 'modal', 'callback')
export const forgetPassword = Creator(FORGET_PASSWORD, 'data')
export const resetPassword = Creator(RESET_PASSWORD, 'data', 'token')
export const checkToken = Creator(CHECK_TOKEN, 'token', 'type')
export const resendEmail = Creator(RESEND_EMAIL, 'data')
export const interest = Creator(INTEREST, 'data')
export const getNotification = Creator(NOTIFICATION)
export const googleLogin = Creator(GOOGLE_LOGIN, 'data', 'modal', 'callback')

export function* loginSaga({
  payload: {data, modal, callback = () => {}, isReload},
}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}auth/sign_in`
  try {
    const response = yield httpPost(url, data)
    window.localStorage.setItem('Noon-Auth-Token', response.data.data.token)
    let isInterest = false
    if (!modal) {
      if (response.data.data.user.interestTags.length === 0) {
        isInterest = true
        navigate('/authorize/interest')
      } else {
        navigate('/')
      }
    } else {
      callback()
      isReload && window.location.reload()
    }
    yield put(
      setData({
        user: response.data.data.user,
        isLogin: true,
        isInterest,
        errorMessage: '',
      }),
    )
  } catch (error) {
    switch (error.response.status) {
      case 401:
        yield put(setData({errorMessage: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง'}))
        break
      case 402:
        yield put(setData({errorMessage: 'ยังไม่ได้ทำการยืนยันอีเมล'}))
        break
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  } finally {
    yield put(setData({isLoading: false}))
  }
}

export function* checkTokenSaga({payload: {token, type}}) {
  yield put(setData({isLoading: true}))

  if (type === 'email') {
    const url = `${API_HOST}auth/confirm`
    try {
      yield httpPost(url, token)
    } catch (error) {}
  }

  yield put(setData({isLoading: false}))
}

export function* logoutSaga() {
  // const url = `${API_HOST}auth/sign_out`
  try {
    // yield httpDelete(url)
    yield put(setData({isLogin: false}))
    window.localStorage.clear()
    window.location.reload()
  } catch (error) {}
}

export function* registerSaga({payload: {data, modal, callback = () => {}}}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}auth/sign_up`
  try {
    const response = yield httpPost(url, data)
    if (!modal) {
      navigate('/authorize/confirmEmail')
    } else {
      response.data.code === 'success' && callback()
    }
  } catch (error) {
    switch (error.response.status) {
      case 422:
        yield put(setData({errorMessage: 'อีเมลมีอยู่ในระบบอยู่แล้ว'}))
        break
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  } finally {
    yield put(setData({isLoading: false}))
  }
}

export function* getUserInfoSaga() {
  const url = `${API_HOST}auth/info`
  try {
    const response = yield httpGet(url)
    // ReactGA.initialize('UA-143287722-1', {
    //   gaOptions: {
    //     userId: response.data.data.currentProfile.analyticUserId,
    //   },
    // })
    let checkToken = window.localStorage.getItem('Noon-Auth-Token')
    if (!checkToken) {
      window.localStorage.setItem('Noon-Auth-Token', response.data.data.token)
    }

    if (response.data.data.user) {
      yield put(
        setData({
          user: response.data.data.user,
          isLogin: true,
          errorMessage: '',
          initialized: true,
        }),
      )
    }

    dataLayer.push({
      event: 'uid_available',
      userId: response.data.data.currentProfile.analyticUserId,
    })
  } catch (error) {
  } finally {
    yield put(
      setData({
        initialized: true,
      }),
    )
  }
}

export function* forgetPasswordSaga({payload: {data}}) {
  yield put(setData({isLoading: true}))

  const url = `${API_HOST}auth/send_reset_password_instructions`
  try {
    yield httpPost(url, data)
    navigate('/authorize/forgetPasswordResendEmail')
  } catch (error) {
    switch (error.response.status) {
      case 422:
        yield put(setData({errorMessage: 'ไม่พบอีเมลนี้อยู่ในระบบ'}))
        break
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setData({isLoading: false}))
}

export function* resendEmailSaga({payload: {data}}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}auth/resend_confirmation`
  try {
    yield httpPost(url, data)
    navigate('/authorize/resendEmailSuccess')
  } catch (error) {
    switch (error.response.status) {
      case 422:
        yield put(setData({errorMessage: 'อีเมลนี้ได้รับการยืนยันแล้ว'}))
        break
      default:
        yield put(setData({errorMessage: 'เซิฟเวอร์มีปัญหา'}))
        break
    }
  }
  yield put(setData({isLoading: false}))
}

export function* resetPasswordSaga({payload: {data, token}}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}auth/reset_password`
  try {
    yield httpPost(url, {...data, ...token})
    navigate('/authorize/resetPasswordSuccess')
  } catch (error) {
    console.error('error', error)
  }
  yield put(setData({isLoading: false}))
}

export function* interestSaga({payload: {data}}) {
  yield put(setData({isLoading: true}))
  const url = `${API_HOST}users/interest_tags`
  try {
    yield httpPut(url, {interest_tags: data})
    navigate('/')
  } catch (error) {
    console.error('error', error)
  }
  yield put(setData({isLoading: false}))
}

export function* notificationSaga() {
  const url = `${API_HOST}notification`

  try {
    const response = yield httpGet(url)
    yield put(setData({notification: response.data.data.articles}))
  } catch (error) {
    console.error('error', error)
  }
}

export function* facebookLoginSaga({
  payload: {data, modal, callback = () => {}},
}) {
  const url = `${API_HOST}auth/facebook_auth`
  try {
    const response = yield httpPost(url, {
      ...data,
    })
    window.localStorage.setItem('Noon-Auth-Token', response.data.data.token)
    yield put(
      setData({
        user: response.data.data.user,
        isLogin: true,
        errorMessage: '',
      }),
    )

    if (!modal) {
      if (response.data.data.user.interestTags.length === 0) {
        navigate('/authorize/interest')
      } else {
        navigate('/')
      }
    } else {
      callback()
    }
  } catch (error) {
    console.error('error', error)
  }
}

export function* googleLoginSaga({payload: {data, modal, callback}}) {
  try {
    const {tokenId, profileObj} = data
    const {email} = profileObj
    const url = `${API_HOST}auth/google_auth`
    const response = yield httpPost(url, {
      tokenId,
      email,
    })
    window.localStorage.setItem('Noon-Auth-Token', response.data.data.token)
    yield put(
      setData({
        user: response.data.data.user,
        isLogin: true,
        errorMessage: '',
      }),
    )
    if (!modal) {
      if (response.data.data.user.interestTags.length === 0) {
        navigate('/authorize/interest')
      } else {
        navigate('/')
      }
    } else {
      callback()
    }
  } catch (error) {
    console.error(error.response)
  }
}

export function* watchAuthSaga() {
  yield takeLatest(LOGIN, loginSaga)
  yield takeLatest(LOGOUT, logoutSaga)
  yield takeLatest(REGISTER, registerSaga)
  yield takeLatest(GET_USER_INFO, getUserInfoSaga)
  yield takeLatest(FORGET_PASSWORD, forgetPasswordSaga)
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga)
  yield takeLatest(CHECK_TOKEN, checkTokenSaga)
  yield takeLatest(RESEND_EMAIL, resendEmailSaga)
  yield takeLatest(INTEREST, interestSaga)
  yield takeLatest(NOTIFICATION, notificationSaga)
  yield takeLatest(FACEBOOK_LOGIN, facebookLoginSaga)
  yield takeLatest(GOOGLE_LOGIN, googleLoginSaga)
}

const initial = {
  isLogin: false,
  isInterest: false,
  errorMessage: '',
  user: {},
  isLoading: false,
  initialized: false,
  notification: [],
}

export default createReducer(initial, state => ({
  [SET_DATA]: ({data}) => ({...state, ...data}),
}))
