import {withRouter as withReactRouter} from 'react-router-dom'
import {compose, withProps} from 'recompose'
import qs from 'qs'
import humps from 'humps'

const withRouter = () =>
  compose(
    withReactRouter,
    withProps(props => ({
      location: {
        ...props.location,
        query: humps.camelizeKeys(
          qs.parse(props.location.search, {ignoreQueryPrefix: true}),
        ),
      },
    })),
  )

export default withRouter
