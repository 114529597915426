import React from 'react'
import styled from 'react-emotion'
import {compose, withHandlers} from 'recompose'
import {withSpidus} from '../../enhances'
import {Checkbox} from '../../components/index'

const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
`
const ResultLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 387px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`
const ItemGroupContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5px 5px 5px 5px;
`
const Caption = styled.span`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`
const ItemGroupLayout = styled.div`
  display: flex;
  margin: 5px;
  border: 1px solid #ffd482;
  border-radius: 4px;
  flex-direction: column;
`
const ItemGroupCaption = styled.span`
  font-size: 15px;
  margin-left: 5px;
`
const CustomCaption = styled(Caption)`
  color: #000;
`
export const ItemGroup = ({caption, items, ...props}) => (
  <ItemGroupLayout>
    <ItemGroupCaption>{caption}</ItemGroupCaption>
    <ItemGroupContainer key={caption}>
      {items.map(item => (
        <Checkbox
          key={`${item.type}-${props.itemIncluded(item) ? 'true' : 'false'}`}
          caption={item.caption}
          category={item.category}
          value={props.itemIncluded(item)}
          onSelect={props.onSelectItem(item)}
        />
      ))}
    </ItemGroupContainer>
  </ItemGroupLayout>
)

const SearchResult = props => (
  <Layout>
    <CustomCaption>Result</CustomCaption>
    <ResultLayout>
      {props.value.map(({caption, items}) => (
        <ItemGroup caption={caption} items={items} {...props} />
      ))}
    </ResultLayout>
  </Layout>
)

const enhance = compose(
  withSpidus(),
  withHandlers({
    itemIncluded: props => item =>
      !!props.selectedItems.find(
        selectedItem => selectedItem.type === item.type,
      ),
  }),
)

export default enhance(SearchResult)
