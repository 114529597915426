import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, defaultProps, branch, withProps} from 'recompose'

import {toCurrency} from '../core/helper'
import Slider from '../../share/Field/Slider'
import {
  withCaption,
  withDisabled,
  withSpidus,
  withValue,
  omitProps,
} from '../enhances/index'

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 60px;
`

const CustomSliderBackground = styled(Slider)`
  position: absolute;
  width: calc(100% - 12px);
  max-width: 100%;

  .ant-slider-step {
    background-color: #7f7f7f !important;
  }
  .ant-slider-dot {
    display: none;
  }
  .ant-slider-handle {
    display: none;
  }
`

const CustomSlider = styled(Slider)`
  position: absolute;
  width: calc(100% - 12px);
  max-width: ${props => props.maxWidth}%;

  > div:first-child {
    display: none;
  }
  .ant-slider-track {
    background-color: #265fa7;
  }
  .ant-slider-step {
    background-color: #6a94cb;
  }

  ${props =>
    !props.max &&
    css`
      .ant-slider-handle.ant-slider-handle-click-focused {
        display: none !important;
      }
    `}
`

const disabledStyled = css`
  opacity: 0.8;
`

const SliderBar = props => (
  <Layout>
    <CustomSliderBackground
      marks={props.marks}
      min={props.min}
      max={props.max}
      disabled
    />
    <CustomSlider
      {...props}
      marks={{}}
      max={props.maxCap}
      maxWidth={props.widthSlider}
    />
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  defaultProps({
    min: 0,
    max: 0,
  }),
  withProps(props => ({
    widthSlider: (1 / props.max) * props.maxCap * 100,
    marks: {
      0: {
        style: {
          fontSize: '14px',
          color: '#265FA7',
          left: '3px',
        },
        label: <span>0</span>,
      },
      [`${props.max}`]: {
        style: {
          fontSize: '14px',
          color: '#265FA7',
          left: 'unset',
          right: '-24px',
        },
        label: <span>{toCurrency(props.max, {prefix: ''})}</span>,
      },
    },
  })),
  withDisabled(disabledStyled),
  withHandlers({
    onAfterChange: props => e => {
      props.onBlur && props.onBlur(props.value)
    },
  }),
  omitProps('onBlur'),
)

export default enhance(SliderBar)
