import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {message} from 'antd'
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from 'react-share'

import Modal from '../../share/Modal'
import Button from '../../share/Button'
import {shareArticle} from '../../../ducks/noonSquare'
import {responsive} from '../../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 55px;
`

const ContainerHref = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 55px;
`

const TextHref = styled.div`
  display: flex;
  align-items: center;
  color: white;
  width: 400px;
  height: 50px;
  font-size: 16px;
  background-color: ${props => props.theme.BLUE};
  border-radius: 5px 0 0 5px;
  padding: 0 15px;

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const Href = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CopyButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100px;
  height: 50px;
  font-size: 16px;
  background-color: ${props => props.theme.DARKBLUE};
  border-radius: 0 5px 5px 0;
  :hover {
    background-color: ${props => props.theme.LIGHTBLUE};
    color: white;
  }
  :focus {
    background-color: ${props => props.theme.LIGHTBLUE};
    color: white;
  }

  ${props =>
    responsive.mobile(css`
      border-radius: 5px;
      margin-bottom: 30px;
    `)};
`

const ContainerShareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  margin: 21px 0 40px;
  .shareButton {
    margin-right: 20px;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }

  ${props =>
    responsive.mobile(css`
      flex-wrap: wrap;
      margin-bottom: 40px;
      padding: 0 20px;
      .shareButton {
        margin: 10px;
      }
    `)};
`

const Fackbook = styled.div``

const Twitter = styled.div``

const Line = styled.div``

const Email = styled.div``

const Linked = styled.div``

const getFacebookHashtags = hashtags =>
  [{name: '#noon'}, ...hashtags]
    .map(h => (h.name[0] === '#' ? h.name : `#${h.name}`))
    .join('')

const getTwitterHashtags = hashtags =>
  [{name: '#noon'}, ...hashtags].map(h =>
    h.name[0] === '#' ? h.name.replace('#', '') : h.name,
  )

const getEmailPattern = article =>
  `${article.topicName}\n${article.description}\nบทความโดย ${article.signatureSynonym}\nอ่านต่อได้ที่ ${window.location.host}/square/details/${article.id}`

const ModalShareSocial = ({
  isShowModal,
  setIsShowModal,
  hrefId,
  onShare,
  article,
}) => {
  const {topicName = '', hashtags = []} = article
  return (
    <Modal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
      <Container>
        <Header>แชร์บทความนี้</Header>
        <ContainerShareButton>
          <Fackbook onClick={() => onShare('facebook')}>
            <FacebookShareButton
              className="shareButton"
              quote={topicName}
              url={hrefId}
              hashtag={getFacebookHashtags(hashtags)}>
              <FacebookIcon size={50} round />
            </FacebookShareButton>
          </Fackbook>
          <Twitter onClick={() => onShare('twitter')}>
            <TwitterShareButton
              className="shareButton"
              title={topicName}
              url={hrefId}
              hashtags={getTwitterHashtags(hashtags)}>
              <TwitterIcon size={50} round />
            </TwitterShareButton>
          </Twitter>
          <Line onClick={() => onShare('line')}>
            <LineShareButton
              className="shareButton"
              title={topicName}
              url={hrefId}>
              <LineIcon size={50} round />
            </LineShareButton>
          </Line>
          <Email onClick={() => onShare('email')}>
            <EmailShareButton
              className="shareButton"
              url={hrefId}
              subject={topicName}
              body={getEmailPattern(article)}>
              <EmailIcon size={50} round />
            </EmailShareButton>
          </Email>
          <Linked onClick={() => onShare('linked')}>
            <LinkedinShareButton
              className="shareButton"
              title={topicName}
              url={hrefId}>
              <LinkedinIcon size={50} round />
            </LinkedinShareButton>
          </Linked>
        </ContainerShareButton>
        <ContainerHref>
          <TextHref>
            <Href>{hrefId}</Href>
          </TextHref>
          <CopyButton
            onClick={() => {
              onShare('copy_link')
              navigator.clipboard.writeText(hrefId)
              message.success('Copied Link to Clipboard', 2.5)
            }}>
            คัดลอก
          </CopyButton>
        </ContainerHref>
      </Container>
    </Modal>
  )
}

const enhancer = compose(
  connect(
    state => ({}),
    {shareArticle},
  ),
  withHandlers({
    onShare: ({article, shareArticle}) => type => {
      shareArticle(article.id, type)
    },
  }),
)

export default enhancer(ModalShareSocial)
