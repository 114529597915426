import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {
  displayNumber,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import Panel from '../PanelForm'
import {responsive, breakpoints} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'

import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
} from '../ShareFieldForm'

const CustomTextInput = styled(TextInput)`
  .label {
    padding-right: 45px;
  }

  ${props =>
    responsive.mobile(css`
      .label {
        padding-right: 0;
      }
    `)};
`

const CustomTextFooter = styled(TextFooter)`
  width: 655px;
`

const CustomText = styled.b`
  font-size: 20px;
`

const Summary = ({summary}) => (
  <CustomText>{displayNumber(Math.round(summary))} บาท</CustomText>
)

const FamilyIncomeProtectionForm = ({summary, onCalSummary, windowWidth}) => (
  <Panel>
    <div>
      <FieldContainer>
        <CustomTextInput
          name="moneyPrepared"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เงินที่อยากเตรียมไว้ทดแทนการขาดรายได้ ของครอบครัว (บาท)'
              : 'เงินที่อยากเตรียมไว้ทดแทนการขาดรายได้ ของครอบครัว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <CustomTextInput
          name="sumInsurance"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ทุนประกันชีวิตที่มีอยู่แล้ว (บาท)'
              : 'ทุนประกันชีวิตที่มีอยู่แล้ว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <CustomTextFooter>
        <SearchFactor
          type="FamilyIncomeProtection"
          windowWidth={windowWidth}
          searchValue={summary}
        />
        <CustomField name="searchValue" component={Summary} summary={summary} />
      </CustomTextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={summary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(state, 'moneyPrepared', 'sumInsurance'),
    }),
    {change},
  ),
  withState('summary', 'setSummary', 0),
  withHandlers({
    onCalSummary: ({totalList, setSummary, change}) => () => {
      const {moneyPrepared = '0', sumInsurance = '0'} = totalList

      let first = convertNaNtoZero(moneyPrepared)
      let second = convertNaNtoZero(sumInsurance)

      let summary = first - second
      setSummary(summary)
      change('Planning', 'searchValue', summary)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onCalSummary()
    },
  }),
)

export default enhancer(FamilyIncomeProtectionForm)
