import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, lifecycle} from 'recompose'
import {reduxForm, formValueSelector} from 'redux-form'
import {responsive} from '../../core/style'
import {message} from 'antd'

import CustomField from '../share/Field'
import Modal from '../share/Modal'
import Button from '../share/Button'
import {
  isEmail,
  phoneFormatter,
  ThaiDateFormatter,
  formatParser,
  isPhoneNumber,
} from '../../core/helper'

import {editProfile, getAvatar} from '../../ducks/profile'
import Loading from '../share/Loading'
import {toDate} from '../NoonTax/components/DatePicker'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;
    `)};
`

const Header = styled.div`
  margin-bottom: 30px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.BLUE};

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const HeaderMobile = styled.div`
  display: none;
  margin-top: 20px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  color: ${props => props.theme.BLUE};

  ${props =>
    responsive.mobile(css`
      display: block;
    `)};
`

const InputUserProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-right: 45px;

  ${props =>
    responsive.mobile(css`
      margin-top: 15px;
      margin-right: 0;
    `)};
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    responsive.mobile(css`
      flex-direction: row;
      justify-content: space-between;
      width: 250px;

      > div {
        width: 120px;
      }
    `)};
`

const GenderButton = styled(CustomField)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .label {
    min-width: fit-content;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      align-items: flex-start;

      .label {
        margin-bottom: 10px;
      }
      > div {
        > div {
          > div.male,
          div.female {
            width: 124px;
          }
        }
      }
    `)};
`

const TextInput = styled(CustomField)`
  position: relative;
  display: flex;
  flex-direction: column;

  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  input {
    width: 246px;
    text-align: center;
  }
`

const DatePickerInput = styled(CustomField)`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 246px;

  > div > div {
    width: 246px !important;

    > div:first-child {
      width: 246px !important;
    }
  }

  .label {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  input {
    width: 246px;
    text-align: center;
  }
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 20px;

  font-size: 12px;
  background-color: ${props => props.theme.ORANGE};

  cursor: ${props => (props.valid === 'true' ? 'pointer' : 'not-allowed')};
  opacity: ${props => (props.valid === 'true' ? 1 : 0.5)};

  &.mobileButton {
    display: none;
  }

  ${props =>
    responsive.mobile(css`
      display: none !important;

      &.mobileButton {
        display: flex !important;
        justify-content: center;

        min-width: 250px;
        height: 40px;

        font-size: 16px;
        line-height: 24px;
      }
    `)};
`

const UserIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const UserIcon = styled.img`
  width: 150px;
  height: 150px;

  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;

  ${props =>
    responsive.mobile(css`
      width: 80px;
      height: 80px;
    `)};
`

const CustomModal = styled(Modal)`
  &.ant-modal {
    width: unset !important;
    display: flex;
    justify-content: center;
    margin: 0 calc((100vw - 800px) / 2);
  }
  .ant-modal-content {
    width: 600px;
    padding: 45px 0 35px 0;
  }

  ${props =>
    responsive.mobile(css`
      &.ant-modal {
        width: 100% !important;
        margin: unset;
      }
    `)};
`

const ChooseLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ChooseLogoListContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 360px;
  flex-wrap: wrap;
`

const LogoInList = styled.img`
  width: 50px;
  height: 50px;
  margin: 10px 5px;

  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;

  :hover {
    box-shadow: 0 0 15px 3px ${props => props.theme.ORANGE};
  }
`

const SelectFile = styled(CustomField)`
  width: 50px;
  height: 50px;
  margin: 10px 5px;
`

const getBase64 = (file, callback) => {
  const fileSize = file.size / 1048576
  if (fileSize > 2) {
    message.error('ขนาดไฟล์ภาพไม่ควรเกิน 2 MB', 5)
  } else {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  }
}

const ChooseLogo = ({
  avartars,
  setAvatarId,
  setIsShowModal,
  onAvatarChange,
}) => (
  <ChooseLogoContainer>
    <Header>เลือก/ อัพโหลดรูปภาพ</Header>
    <ChooseLogoListContainer>
      <SelectFile
        name="avatarImage"
        type="file"
        didChange={file => {
          getBase64(file, item => {
            onAvatarChange(item)
          })
          setAvatarId('')
          setIsShowModal(false)
        }}
      />
      {avartars.map(value => (
        <LogoInList
          key={value.id}
          src={value.avatar}
          onClick={() => {
            onAvatarChange(value.avatar)
            setAvatarId(value.id)
            setIsShowModal(false)
          }}
        />
      ))}
    </ChooseLogoListContainer>
  </ChooseLogoContainer>
)

const UserProfile = ({
  handleSubmit,
  isShowModal,
  setIsShowModal,
  valid,
  editProfile,
  avartars = [],
  setAvatarId,
  avatarId,
  isLoading,
  pristine,
  reset,
  change,
  avatar,
}) => (
  <Loading isLoading={isLoading}>
    <Container
      onSubmit={handleSubmit(value => {
        editProfile(
          {
            ...value,
            avatarName: avatarId
              ? null
              : value.avatarImage
              ? value.avatarImage.name
              : null,
            avatarImage: avatarId ? null : value.avatar,
            avatarId: avatarId,
          },
          reset,
        )
      })}>
      <Header>{pristine ? 'ข้อมูลส่วนตัว' : 'แก้ไขข้อมูล'}</Header>
      <Wrapper>
        <InputUserProfileContainer>
          <GenderButton
            className="gender"
            name="gender"
            label="เพศ"
            type="switch"
          />
          <NameContainer>
            <TextInput
              name="firstNameTh"
              label="ชื่อ"
              type="text"
              placeholder="ชื่อ"
            />
            <TextInput
              name="lastNameTh"
              label="นามสกุล"
              type="text"
              placeholder="นามสกุล"
            />
          </NameContainer>
          <DatePickerInput
            name="birthdate"
            label="วันเกิด"
            type="datepicker"
            placeholder="DD/MM/YYYY"
          />
          <TextInput
            name="email"
            label="อีเมล"
            type="text"
            placeholder="email@examples.com"
            disabled
          />
          <TextInput
            name="phoneNumber"
            label="เบอร์ติดต่อ"
            type="text"
            placeholder="0XX-XXX-XXXX"
            format={phoneFormatter}
            parse={formatParser}
          />
          <TextInput
            name="topicName"
            label="นามปากกาสำหรับใช้แสดงบนกระทู้"
            type="text"
          />
          <CustomButton
            className="mobileButton"
            htmlType="submit"
            valid={valid.toString()}
            icons={['fa', 'save']}
            disabled={pristine}>
            บันทึก
          </CustomButton>
          {!pristine && (
            <CustomButton
              className="mobileButton"
              valid="true"
              icons={['fa', 'times-circle']}
              onClick={reset}>
              ยกเลิก
            </CustomButton>
          )}
        </InputUserProfileContainer>
        <HeaderMobile>
          {pristine ? 'ข้อมูลส่วนตัว' : 'แก้ไขข้อมูล'}
        </HeaderMobile>
        <UserIconContainer>
          <UserIcon src={avatar} onClick={() => setIsShowModal(true)} />
          <CustomButton
            htmlType="submit"
            valid={valid.toString()}
            icons={['fa', 'save']}
            disabled={pristine}>
            บันทึก
          </CustomButton>
          {!pristine && (
            <CustomButton
              valid="true"
              icons={['fa', 'times-circle']}
              onClick={reset}>
              ยกเลิก
            </CustomButton>
          )}
        </UserIconContainer>
      </Wrapper>
      <CustomModal isShowModal={isShowModal} setIsShowModal={setIsShowModal}>
        <ChooseLogo
          setIsShowModal={setIsShowModal}
          avartars={avartars}
          setAvatarId={setAvatarId}
          onAvatarChange={value => change('avatar', value)}
        />
      </CustomModal>
    </Container>
  </Loading>
)

const validate = values => {
  const errors = {}

  if (!values.firstNameTh) {
    errors.firstNameTh = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastNameTh) {
    errors.lastNameTh = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = 'รูปแบบหมายเลขไม่ถูกต้อง'
  }

  if (!values.birthdate) {
    errors.birthdate = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (values.birthdate.length < 10) {
    errors.birthdate = 'กรุณากรอกวันเดือนปี (วว/ดด/ปปปป)'
  }

  if (!values.topicName) {
    errors.topicName = 'ต้องใส่ข้อมูลในช่องนี้'
  }

  return errors
}

const selector = formValueSelector('userProfile')

const enhancer = compose(
  connect(
    state => ({
      logo: selector(state, 'selectFile'),
      user: state.auth.user,
      avartars: state.profile.avartars,
      isLoading: state.profile.isLoading,
      avatar: selector(state, 'avatar'),
      initialValues: {
        gender: 'male',
        ...state.auth.user,
        birthdate: toDate(state.auth.user.birthdate),
        avatarImage: state.auth.user.avatar,
      },
    }),
    {editProfile, getAvatar},
  ),
  withState('isShowModal', 'setIsShowModal', false),
  withState('avatarId', 'setAvatarId', ''),
  reduxForm({
    form: 'userProfile',
    validate,
    enableReinitialize: true,
  }),
  lifecycle({
    componentDidMount() {
      const {getAvatar} = this.props
      getAvatar()
    },
  }),
)

export default enhancer(UserProfile)
