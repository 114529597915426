import React, {useState, useMemo} from 'react'

import CollapseField from './CollapseField'
import CustomRadios from './CustomRadios'
import {TitleSideBar, GroupSideBar} from './StyleComponents'

export default props => {
  const [collapseActive, setCollapseActive] = useState(false)
  const taxSavingTypeFieldName = 'taxSavingType'
  const {formId} = props

  const options = useMemo(
    () => [
      {
        label: 'LTF',
        value: 'ltf',
      },
      {
        label: 'RMF',
        value: 'rmf',
      },
      {
        label: 'ไม่ลดหย่อนภาษี',
        value: 'none',
      },
    ],
    [],
  )

  return (
    // <CollapseField
    //   title="ลดหย่อนภาษี"
    //   collapseActive={collapseActive}
    //   setCollapseActive={setCollapseActive}>
    //   <CustomRadios
    //     name={taxSavingTypeFieldName}
    //     type="checkboxes"
    //     formId={formId}
    //     options={options}
    //     collapseActive={collapseActive}
    //   />
    // </CollapseField>
    <GroupSideBar>
      <TitleSideBar>ลดหย่อนภาษี</TitleSideBar>
      <CustomRadios
        name={taxSavingTypeFieldName}
        type="checkboxes"
        formId={formId}
        options={options}
        collapseActive={collapseActive}
      />
    </GroupSideBar>
  )
}
