export const sort = [
  {
    label: 'เรียง ก-ฮ',
    value: 'a-z',
  },
  {
    label: 'ใหม่ล่าสุด',
    value: 'new',
  },
  {
    label: 'ถูกใจสูงสุด',
    value: 'like',
  },
]
