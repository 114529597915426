import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import HeaderDetail from '../HeaderDetail'
import FooterDetail from '../FooterDetail'
import {displayNumber} from '../../../../../core/helper'
import {responsive} from '../../../../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
const HeaderBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: rgba(106, 148, 203, 0.2);
  margin: 10px 0;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 666px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  ${props =>
    responsive.mobile(css`
      max-width: 280px;
    `)};
`

const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 666px;
  width: 100%;
  font-size: 16px;
  ${props =>
    responsive.mobile(css`
      max-width: 280px;
      padding: 10px 0;
    `)};
`
const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  ${props =>
    responsive.mobile(css`
      max-width: 150px;
      height: unset;
      align-items: unset;
      line-height: 24px;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${props => (props.correct ? props.theme.GREEN : props.theme.PINKRED)};
`

const CorrectIcon = ({correct}) => (
  <Detail>
    <CustomIcon
      correct={correct}
      icon={['fas', correct ? 'check-circle' : 'times-circle']}
    />
  </Detail>
)

const DetailBody = ({
  selectedMotor,
  setSelectedMotor,
  selectedThirdParty,
  setSelectedThirdParty,
  selectedOther,
  setSelectedOther,
  correct,
  item,
  category,
}) => (
  <Container>
    <HeaderBackground>
      <HeaderContainer>รายละเอียดบริษัทประกัน</HeaderContainer>
    </HeaderBackground>
    <DetailContainer>
      <Detail>ชื่อบริษัท</Detail>
      <Detail>{(item && item.companyName) || '-'}</Detail>
    </DetailContainer>
    <DetailContainer>
      <Detail>จำนวนอู่</Detail>
      <Detail>{displayNumber(parseInt(item && item.numGarage)) || '-'}</Detail>
    </DetailContainer>
    <HeaderDetail
      selected={selectedMotor}
      setSelected={setSelectedMotor}
      header={'ความคุ้มครองรถยนต์'}
    />
    {selectedMotor && (
      <React.Fragment>
        <DetailContainer>
          <Detail>ทุนประกัน</Detail>
          <Detail>{displayNumber(parseInt(item && item.cover)) || '-'}</Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>คุ้มครองไฟไหม้และโจรกรรม</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.coverLostFire)) || '-'}
          </Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>ค่าเสียหายส่วนแรก</Detail>
          <Detail>{displayNumber(parseInt(item && item.deduct)) || '-'}</Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>ภัยก่อการร้าย</Detail>
          <CorrectIcon correct={item && item.coverTerrorism === 'Y'} />
        </DetailContainer>
        <DetailContainer>
          <Detail>น้ำท่วม</Detail>
          <CorrectIcon correct={item && item.coverFlood === 'Y'} />
        </DetailContainer>
        <DetailContainer>
          <Detail>ตรวจสุขภาพ</Detail>
          <CorrectIcon correct={item && item.carCheck === 'Y'} />
        </DetailContainer>
        <DetailContainer>
          <Detail>ซ่อม</Detail>
          <Detail>
            {item && item.typeGarage === '0' ? 'อู่' : 'ห้าง' || '-'}
          </Detail>
        </DetailContainer>
      </React.Fragment>
    )}
    <HeaderDetail
      selected={selectedThirdParty}
      setSelected={setSelectedThirdParty}
      header={'ความคุ้มครองบุคคลภายนอก'}
    />
    {selectedThirdParty && (
      <React.Fragment>
        <DetailContainer>
          <Detail>ค่าชดเชยชีวิตบุคคลภายนอกต่อคน</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.cover3rd)) || '-'}
          </Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>ค่าชดเชยชีวิตบุคคลภายนอกต่อครั้ง</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.cover3rdTime)) || '-'}
          </Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>ค่าชดเชยทรัพย์สินบุคคลภายนอก</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.cover3rdAsset)) || '-'}
          </Detail>
        </DetailContainer>
      </React.Fragment>
    )}

    <HeaderDetail
      selected={selectedOther}
      setSelected={setSelectedOther}
      header={'ความคุ้มครองเพิ่มเติม'}
    />
    {selectedOther && (
      <React.Fragment>
        <DetailContainer>
          <Detail>อุบัติเหตุส่วนบุคคล</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.coverAcc)) || '-'}
          </Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>ประกันตัวผู้ขับขี่</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.coverLegal)) || '-'}
          </Detail>
        </DetailContainer>
        <DetailContainer>
          <Detail>ค่ารักษาพยาบาล</Detail>
          <Detail>
            {displayNumber(parseInt(item && item.coverMed)) || '-'}
          </Detail>
        </DetailContainer>
      </React.Fragment>
    )}
    <FooterDetail category={category} />
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      correct: true,
    }),
    {},
  ),
  withState('selectedMotor', 'setSelectedMotor', true),
  withState('selectedThirdParty', 'setSelectedThirdParty', true),
  withState('selectedOther', 'setSelectedOther', true),
  lifecycle({
    componentDidMount() {},
  }),
)

export default enhancer(DetailBody)
