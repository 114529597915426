import {injectGlobal} from 'emotion'

injectGlobal`
  body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow-x: hidden;
    color: black;

    #root{
      height:100%;
    }
  }

  button {
    cursor: pointer;
  }

  input:-webkit-autofill: white;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
  }

  * {
    font-family: Roboto,Prompt,sans-serif;

    :focus{
      outline: 0;
    }

    ::-webkit-scrollbar {
      position: fixed;
      width: 8px;
      height: 8px;
      background-color: transparent;
    }

    ::-moz-scrollbar {
      position: fixed;
      width: 8px;
      height: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, 0.09);
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(0,0,0,0.5);
        -webkit-border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb:active {
        background: rgba(0,0,0,0.61); /* Some darker color when you click it */
        -webkit-border-radius: 100px;
    }

    /* add vertical min-height & horizontal min-width */
    ::-webkit-scrollbar-thumb:vertical {
      min-height: 10px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      min-width: 10px;
    }
  }

  #loadOverlay {
    display: none;
  }

  .square-content * {
    font-family: Sarabun, san-sarif;
  }
  .square-content img {
    max-width: 100%;
    height: auto;
    max-height: unset;
  }
`
