import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {reduxForm, formValueSelector} from 'redux-form'
import {obj2Url, url2Obj} from '../../../../../core/helper'
import {responsive, breakpoints} from '../../../../../core/style'
import windowSize from '../../../../../core/react-window-size'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'
import DebtProtection from './DebtProtectionForm/'
import FamilyIncomeProtection from './FamilyIncomeProtectionForm/'
import Retirement from './RetirementForm/'
import Child from './ChildForm/'
import TaxReduction from './TaxReductionForm/'
import Saving from './SavingForm/'
import PersonalForm from '../../../../share/PersonalForm'
import {savePlanning} from '../../../../../ducks/product'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  ${props =>
    responsive.mobile(css`
      margin-top: 60px;
    `)};
`
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  height: 40px;

  ${props =>
    responsive.tablet(css`
      width: 380px;
    `)};
  ${props =>
    responsive.mobile(css`
      width: 230px;
    `)};
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 40px;
  height: 41px;
  color: #333333;
  margin-right: 22px;
  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      margin-bottom: 10px;
    `)};
`
const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  ${props =>
    responsive.tablet(css`
      text-align: center;
    `)};

  ${props =>
    responsive.mobile(css`
      font-size: 16px;
      width: 160px;
    `)};
`
const HeaderColumn = styled(Header)`
  display: flex;
  flex-direction: column;
`
const HeaderTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  ${props =>
    responsive.tablet(css`
      padding: 0 25px;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      padding 0 20px;
    `)};
`

const ChevronArrow = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #333333;
  cursor: pointer;
`

export const Form = ({
  type,
  BoxList,
  hideAvatar,
  onBackClick = () =>
    BoxIndex !== 0
      ? navigate(
          `/smart/InsuranceCategory/life/starter?type=${BoxList[BoxIndex - 1].type}`,
        )
      : navigate(
          `/smart/InsuranceCategory/life/starter?type=${BoxList[BoxList.length - 1].type}`,
        ),
  onNextClick = () =>
    BoxIndex !== BoxList.length - 1
      ? navigate(
          `/smart/InsuranceCategory/life/starter?type=${BoxList[BoxIndex + 1].type}`,
        )
      : navigate(
          `/smart/InsuranceCategory/life/starter?type=${BoxList[0].type}`,
        ),

  change,
  onSubmit,
  windowWidth,
}) => {
  const BoxIndex = BoxList.findIndex(i => i.type === type)
  return (
    <Container onSubmit={onSubmit}>
      <HeaderContainer>
        <ChevronArrow
          id="noon-smart-insurance-life-starter-search-insurance-product-back"
          icon={['fas', 'chevron-circle-left']}
          onClick={() => {
            change('isSimple', true)
            type === 'Child' && change('isSelectSubHeader', true)
            onBackClick()
          }}
        />
        {BoxList.map(
          i =>
            i.type === type && (
              <HeaderTop key={i.type}>
                <CustomIcon icon={[i.iconTag, i.iconName]} />
                {windowWidth <= breakpoints.mobile ? (
                  <HeaderColumn>
                    <span>{i.infoTopMobile}</span>
                    <span>{i.infoMidMobile}</span>
                    <span>{i.infoBottomMobile}</span>
                  </HeaderColumn>
                ) : windowWidth <= breakpoints.tablet ? (
                  <HeaderColumn>
                    <span>{i.infoTopTablet}</span>
                    <span>{i.infoMidTablet}</span>
                    <span>{i.infoBottomTablet}</span>
                  </HeaderColumn>
                ) : (
                  <Header>{`${i.infoTop}${i.infoBottom}`}</Header>
                )}
              </HeaderTop>
            ),
        )}

        <ChevronArrow
          id="noon-smart-insurance-life-starter-search-insurance-product-forward"
          icon={['fas', 'chevron-circle-right']}
          onClick={() => {
            change('isSimple', true)
            type === 'Child' && change('isSelectSubHeader', true)
            onNextClick()
          }}
        />
      </HeaderContainer>
      <PersonalForm hideAvatar={hideAvatar} />
      {type === 'DebtProtection' && (
        <DebtProtection windowWidth={windowWidth} />
      )}
      {type === 'FamilyIncomeProtection' && (
        <FamilyIncomeProtection windowWidth={windowWidth} />
      )}
      {type === 'Retirement' && <Retirement windowWidth={windowWidth} />}
      {type === 'Child' && <Child windowWidth={windowWidth} />}
      {type === 'TaxReduction' && <TaxReduction windowWidth={windowWidth} />}
      {type === 'Saving' && <Saving windowWidth={windowWidth} />}
    </Container>
  )
}

const PlanningForm = ({
  type,
  isSimple,
  isSelectSubHeader,
  childSimple,
  childAdvanceNoon,
  childAdvance,
  childAdvanceCustom,
  debtProtectionSimple,
  debtProtectionAdvance,
  familyIncomeProtectionSimple,
  familyIncomeProtectionAdvance,
  retirementSimple,
  retirementAdvance,
  saving,
  taxReduction,
  age,
  gender,
  handleSubmit,
  change,
  BoxList,
  savePlanning,
  windowWidth,
}) => (
  <Form
    type={type}
    BoxList={BoxList}
    change={change}
    windowWidth={windowWidth}
    onSubmit={handleSubmit(() => {
      let valueForm = {}
      let planningForm = {}

      if (type === 'DebtProtection') {
        if (isSimple) {
          valueForm = {
            ...debtProtectionSimple,
            searchValue:
              debtProtectionSimple.searchValue > 100000
                ? debtProtectionSimple.searchValue
                : 100000,
            searchBy: 'sum_insured',
            weightType: 5,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'easy',
            cal_info: {
              load_control: debtProtectionSimple.loadControl,
              liquidity_asset: debtProtectionSimple.liquidityAsset,
              sum_insurance: debtProtectionSimple.sumInsurance,
            },
            cal_result: {
              sub_types: ['whole_life', 'term', 'unit_link'],
              value: debtProtectionSimple.searchValue,
            },
          }
        } else {
          valueForm = {
            ...debtProtectionAdvance,
            searchValue:
              debtProtectionAdvance.searchValue > 100000
                ? debtProtectionAdvance.searchValue
                : 100000,
            searchBy: 'sum_insured',
            weightType: 5,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'complicate',
            cal_info: {
              loads: {
                house_remain: debtProtectionAdvance.houseRemain,
                auto_remain: debtProtectionAdvance.autoRemain,
                person_remain: debtProtectionAdvance.personRemain,
                business_remain: debtProtectionAdvance.businessRemain,
                etc_remain: debtProtectionAdvance.etcRemain,
                summary: debtProtectionAdvance.summaryLoads,
              },
              assets: {
                deposit: debtProtectionAdvance.deposit,
                liquidity_asset: debtProtectionAdvance.liquidityAsset,
                sum_insurance: debtProtectionAdvance.sumInsurance,
                summary: debtProtectionAdvance.summaryAssets,
              },
            },
            cal_result: {
              sub_types: ['whole_life', 'term', 'unit_link'],
              value: debtProtectionAdvance.searchValue,
            },
          }
        }
      } else if (type === 'FamilyIncomeProtection') {
        if (isSimple) {
          valueForm = {
            ...familyIncomeProtectionSimple,
            searchValue:
              familyIncomeProtectionSimple.searchValue > 100000
                ? familyIncomeProtectionSimple.searchValue
                : 100000,
            searchBy: 'sum_insured',
            weightType: 4,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'easy',
            cal_info: {
              money_prepared: familyIncomeProtectionSimple.moneyPrepared,
              sum_insurance: familyIncomeProtectionSimple.sumInsurance,
            },
            cal_result: {
              sub_types: ['whole_life', 'saving', 'term', 'unit_link'],
              value: familyIncomeProtectionSimple.searchValue,
            },
          }
        } else {
          valueForm = {
            ...familyIncomeProtectionAdvance,
            searchValue:
              familyIncomeProtectionAdvance.searchValue > 100000
                ? familyIncomeProtectionAdvance.searchValue
                : 100000,
            searchBy: 'sum_insured',
            weightType: 4,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'complicate',
            cal_info: {
              inflation_rate: familyIncomeProtectionAdvance.inflationRate,
              family_cost: familyIncomeProtectionAdvance.familyCost,
              care_years: familyIncomeProtectionAdvance.careYears,
              money_before_inflation_merged:
                familyIncomeProtectionAdvance.moneyBeforeInflationMerged,
              money_after_inflation_merged:
                familyIncomeProtectionAdvance.moneyAfterInflationMerged,
              sum_insurance: familyIncomeProtectionAdvance.sumInsurance,
            },
            cal_result: {
              sub_type: ['whole_life', 'saving', 'term', 'unit_link'],
              value: familyIncomeProtectionAdvance.searchValue,
            },
          }
        }
      } else if (type === 'Retirement') {
        if (isSimple) {
          valueForm = {
            ...retirementSimple,
            searchValue:
              retirementSimple.searchValue > 60000
                ? retirementSimple.searchValue
                : 60000,
            searchBy: 'annuity_refund',
            weightType: 3,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'easy',
            cal_info: {
              prepared_expense: retirementSimple.preparedExpense,
              expected_money: retirementSimple.expectedMoney,
            },
            cal_result: {
              sub_types: ['saving', 'annuity'],
              value: retirementSimple.searchValue,
            },
          }
        } else {
          valueForm = {
            ...retirementAdvance,
            searchValue:
              retirementAdvance.searchValue > 60000
                ? retirementAdvance.searchValue
                : 60000,
            searchBy: 'annuity_refund',
            weightType: 3,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'complicate',
            cal_info: {
              assumption: {
                inflation_rate: retirementAdvance.inflationRate,
                present_cost: retirementAdvance.presentCost,
                expected_retire_age: retirementAdvance.expectedRetireAge,
                cost_rate_after_retire: retirementAdvance.costRateAfterRetire,
                year_before_retire: retirementAdvance.yearBeforeRetire,
                expected_dead_age: retirementAdvance.expectedDeadAge,
              },
              retire_predict: {
                cost_after_retire: retirementAdvance.costAfterRetire,
                income_rate: retirementAdvance.incomeRate,
                cost_required: retirementAdvance.costRequired,
                cost_at_dead_age: retirementAdvance.costAtDeadAge,
              },
              cost_prepared: {
                cost_should_prepared: retirementAdvance.costShouldPrepared,
                pension: retirementAdvance.pension,
                etc_income: retirementAdvance.etcIncome,
              },
            },
            cal_result: {
              sub_types: ['saving', 'annuity'],
              value: retirementAdvance.searchValue,
            },
          }
        }
      } else if (type === 'Child') {
        if (isSimple) {
          valueForm = {
            ...childSimple,
            searchValue:
              childSimple.searchValue > 100000
                ? childSimple.searchValue
                : 100000,
            searchBy: 'sum_insured',
            weightType: 2,
          }
          planningForm = {
            gender,
            age,
            cal_type: 'easy',
            cal_info: {
              money_must_prepared: childSimple.moneyMustPrepared,
              money_prepared: childSimple.moneyPrepared,
            },
            cal_result: {
              sub_types: ['saving', 'unit_link'],
              value: childSimple.searchValue,
            },
          }
        } else {
          if (isSelectSubHeader) {
            const obj2String = JSON.stringify(childAdvanceNoon)
            valueForm = {
              ...childAdvance,
              searchValue:
                childAdvance.searchValue > 100000
                  ? childAdvance.searchValue
                  : 100000,
              noon: obj2String,
              searchBy: 'sum_insured',
              weightType: 2,
            }
            planningForm = {
              gender,
              age,
              cal_type: 'complicate',
              cal_info: {
                assumption: {
                  type: childAdvance.radioChild,
                  year:
                    childAdvance.radioChild === 'plan_child'
                      ? childAdvance.assumptionYearPlanChild
                      : childAdvance.assumptionYearChild,
                },

                expected_education_level: {
                  type: 'noon',
                  education_levels: [
                    {
                      level: 'primary',
                      sector: childAdvanceNoon.noon.selector0,
                      fee: childAdvanceNoon.noon.checkbox0
                        ? childAdvance.listSummaryNoon[0]
                        : -1,
                    },
                    {
                      level: 'j_high_school',
                      sector: childAdvanceNoon.noon.selector1,
                      fee: childAdvanceNoon.noon.checkbox1
                        ? childAdvance.listSummaryNoon[1]
                        : -1,
                    },
                    {
                      level: 'high_school',
                      sector: childAdvanceNoon.noon.selector2,
                      fee: childAdvanceNoon.noon.checkbox2
                        ? childAdvance.listSummaryNoon[2]
                        : -1,
                    },
                    {
                      level: 'bachelor',
                      sector: childAdvanceNoon.noon.selector3,
                      fee: childAdvanceNoon.noon.checkbox3
                        ? childAdvance.listSummaryNoon[3]
                        : -1,
                    },
                    {
                      level: 'master',
                      sector: childAdvanceNoon.noon.selector4,
                      fee: childAdvanceNoon.noon.checkbox4
                        ? childAdvance.listSummaryNoon[4]
                        : -1,
                    },
                    {
                      level: 'doctor',
                      sector: childAdvanceNoon.noon.selector5,
                      fee: childAdvanceNoon.noon.checkbox5
                        ? childAdvance.listSummaryNoon[5]
                        : -1,
                    },
                  ],
                },
                cost_prepared: {
                  money_must_prepared: childAdvance.moneyMustPreparedAdvance,
                  money_prepared: childAdvance.moneyPreparedAdvance,
                },
              },
              cal_result: {
                sub_types: ['saving', 'unit_link'],
                value: childAdvance.searchValue,
              },
            }
          } else {
            const obj2String = JSON.stringify(childAdvanceCustom)

            valueForm = {
              ...childAdvance,
              searchValue:
                childAdvance.searchValue > 100000
                  ? childAdvance.searchValue
                  : 100000,
              custom: obj2String,
              searchBy: 'sum_insured',
              weightType: 2,
            }
            planningForm = planningForm = {
              gender,
              age,
              cal_type: 'complicate',
              cal_info: {
                assumption: {
                  type: childAdvance.radioChild,
                  year:
                    childAdvance.radioChild === 'plan_child'
                      ? childAdvance.assumptionYearPlanChild
                      : childAdvance.assumptionYearChild,
                },

                expected_education_level: {
                  type: 'manual',
                  inflation_rate: childAdvanceCustom.custom.inflationRate,
                  education_levels: [
                    {
                      level: 'primary',
                      sector: 'normal',
                      fee: childAdvanceCustom.custom.textAdvance0
                        ? childAdvanceCustom.custom.textAdvance0
                        : -1,
                      fee_future: childAdvanceCustom.custom.checkboxAdvance0
                        ? childAdvance.listSummaryCustom[0]
                        : -1,
                      age_min: childAdvanceCustom.custom.smallTextLeft0,
                      age_max: childAdvanceCustom.custom.smallTextRight0,
                    },
                    {
                      level: 'j_high_school',
                      sector: 'normal',
                      fee: childAdvanceCustom.custom.textAdvance1
                        ? childAdvanceCustom.custom.textAdvance1
                        : -1,
                      fee_future: childAdvanceCustom.custom.checkboxAdvance1
                        ? childAdvance.listSummaryCustom[1]
                        : -1,
                      age_min: childAdvanceCustom.custom.smallTextLeft1,
                      age_max: childAdvanceCustom.custom.smallTextRight1,
                    },
                    {
                      level: 'high_school',
                      sector: 'normal',
                      fee: childAdvanceCustom.custom.textAdvance2
                        ? childAdvanceCustom.custom.textAdvance2
                        : -1,
                      fee_future: childAdvanceCustom.custom.checkboxAdvance2
                        ? childAdvance.listSummaryCustom[2]
                        : -1,
                      age_min: childAdvanceCustom.custom.smallTextLeft2,
                      age_max: childAdvanceCustom.custom.smallTextRight2,
                    },
                    {
                      level: 'bachelor',
                      sector: 'normal',
                      fee: childAdvanceCustom.custom.textAdvance3
                        ? childAdvanceCustom.custom.textAdvance3
                        : -1,
                      fee_future: childAdvanceCustom.custom.checkboxAdvance3
                        ? childAdvance.listSummaryCustom[3]
                        : -1,
                      age_min: childAdvanceCustom.custom.smallTextLeft3,
                      age_max: childAdvanceCustom.custom.smallTextRight3,
                    },
                    {
                      level: 'master',
                      sector: 'normal',
                      fee: childAdvanceCustom.custom.textAdvance4
                        ? childAdvanceCustom.custom.textAdvance4
                        : -1,
                      fee_future: childAdvanceCustom.custom.checkboxAdvance4
                        ? childAdvance.listSummaryCustom[4]
                        : -1,
                      age_min: childAdvanceCustom.custom.smallTextLeft4,
                      age_max: childAdvanceCustom.custom.smallTextRight4,
                    },
                    {
                      level: 'doctor',
                      sector: 'normal',
                      fee: childAdvanceCustom.custom.textAdvance5
                        ? childAdvanceCustom.custom.textAdvance5
                        : -1,
                      fee_future: childAdvanceCustom.custom.checkboxAdvance5
                        ? childAdvance.listSummaryCustom[5]
                        : -1,
                      age_min: childAdvanceCustom.custom.smallTextLeft5,
                      age_max: childAdvanceCustom.custom.smallTextRight5,
                    },
                  ],
                },
                cost_prepared: {
                  money_must_prepared: childAdvance.moneyMustPreparedAdvance,
                  money_prepared: childAdvance.moneyPreparedAdvance,
                },
              },
              cal_result: {
                sub_types: ['saving', 'unit_link'],
                value: childAdvance.searchValue,
              },
            }
          }
        }
      } else if (type === 'Saving') {
        valueForm = {
          ...saving,
          searchValue:
            saving.searchValue > 100000 ? saving.searchValue : 100000,
          searchBy: 'annuity_refund_total',
          weightType: 1,
        }
        planningForm = {
          gender,
          age,
          cal_info: {
            inflation_rate: saving.inflationRate,
            expected_saving: saving.expectedSaving,
            spend_time: saving.spendTime,
            get_money_when_dead: saving.getMoneyWhenDead,
            get_rebate: saving.getRebate,
            rebate_money: saving.rebateMoney,
          },
          cal_result: {
            sub_types: ['saving', 'unit_link'],
            values: {
              sum_insurance: saving.sumInsured,
              sum_rebate: saving.searchValue,
            },
          },
        }
      } else if (type === 'TaxReduction') {
        valueForm = {
          ...taxReduction,
          searchValue:
            taxReduction.searchValue > 1000 ? taxReduction.searchValue : 1000,
          searchBy: 'premium',
          weightType: 1,
        }
        planningForm = {
          gender,
          age,
          cal_info: {
            income: taxReduction.income,
            life_premium_cost: taxReduction.lifePremiumCost,
            health_premium_cost: taxReduction.healthPremiumCost,
            summary_1: taxReduction.summary_1,
            premium_retire: taxReduction.premiumRetire,
            rmf: taxReduction.rmf,
            provident_fund: taxReduction.providentFund,
            summary_2: taxReduction.summary_2,
          },
          cal_result: {
            sub_types: ['whole_life', 'saving', 'annuity', 'unit_link'],
            values: {
              health: taxReduction.health,
              life: taxReduction.searchValue,
              retire: taxReduction.retire,
            },
          },
        }
      }
      savePlanning(planningForm, type, 'life')
      window.open(
        `/smart/life/starter/product?${obj2Url({
          ...valueForm,
          sortBy: 'min_premium',
          noonStar: 3,
          isSimple,
          age,
          gender,
          type,
        })}`,
        '_self',
      )
    })}
  />
)

const selectorForm = formValueSelector('Planning')

const getInitailValue = (type, search) => {
  if (type === 'Child') {
    return {
      isSelectSubHeader: search.isSelectSubHeader || true,
      radioChild: 'plan_child',
      insuranceType: ['saving', 'unit_link'],
      noon: {
        selector0: 'normal',
        selector1: 'normal',
        selector2: 'normal',
        selector3: 'normal',
        selector4: 'normal',
        selector5: 'normal',
        checkbox0: false,
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
      },
      custom: {
        inflationRate: 4,
        smallTextLeft0: 6,
        smallTextRight0: 11,
        smallTextLeft1: 12,
        smallTextRight1: 14,
        smallTextLeft2: 15,
        smallTextRight2: 17,
      },
    }
  } else if (type === 'Retirement') {
    return {costRateAfterRetire: 50, insuranceType: ['saving', 'annuity']}
  } else if (type === 'Saving') {
    return {
      getMoneyWhenDead: true,
      getRebate: true,
      insuranceType: ['saving', 'unit_link'],
    }
  } else if (type === 'DebtProtection') {
    return {insuranceType: ['whole_life', 'term', 'unit_link']}
  } else if (type === 'FamilyIncomeProtection') {
    return {insuranceType: ['whole_life', 'saving', 'term', 'unit_link']}
  } else if (type === 'TaxReduction') {
    return {insuranceType: ['whole_life', 'saving', 'annuity', 'unit_link']}
  }
}

const enhancer = compose(
  connect(
    (state, props) => {
      const search = url2Obj(props.search)
      return {
        initialValues: {
          ...search,
          age: search.age,
          gender: search.gender,
          isSimple: search.isSimple || true,
          inflationRate: search.inflationRate || 2,
          ...getInitailValue(search.type, search),
        },
        isSimple: selectorForm(state, 'isSimple'),
        isSelectSubHeader: selectorForm(state, 'isSelectSubHeader'),
        age: selectorForm(state, 'age'),
        gender: selectorForm(state, 'gender'),
        childSimple: selectorForm(
          state,
          'moneyMustPrepared',
          'moneyPrepared',
          'searchValue',
        ),
        childAdvance: selectorForm(
          state,
          'radioChild',
          'assumptionYearPlanChild',
          'assumptionYearChild',
          'moneyMustPreparedAdvance',
          'moneyPreparedAdvance',
          'searchValue',
          'listSummaryNoon',
          'listSummaryCustom',
        ),
        childAdvanceNoon: selectorForm(
          state,
          'noon.checkbox0',
          'noon.checkbox1',
          'noon.checkbox2',
          'noon.checkbox3',
          'noon.checkbox4',
          'noon.checkbox5',
          'noon.selector0',
          'noon.selector1',
          'noon.selector2',
          'noon.selector3',
          'noon.selector4',
          'noon.selector5',
        ),
        childAdvanceCustom: selectorForm(
          state,
          'custom.inflationRate',
          'custom.checkboxAdvance0',
          'custom.checkboxAdvance1',
          'custom.checkboxAdvance2',
          'custom.checkboxAdvance3',
          'custom.checkboxAdvance4',
          'custom.checkboxAdvance5',
          'custom.smallTextLeft0',
          'custom.smallTextLeft1',
          'custom.smallTextLeft2',
          'custom.smallTextLeft3',
          'custom.smallTextLeft4',
          'custom.smallTextLeft5',
          'custom.smallTextRight0',
          'custom.smallTextRight1',
          'custom.smallTextRight2',
          'custom.smallTextRight3',
          'custom.smallTextRight4',
          'custom.smallTextRight5',
          'custom.textAdvance0',
          'custom.textAdvance1',
          'custom.textAdvance2',
          'custom.textAdvance3',
          'custom.textAdvance4',
          'custom.textAdvance5',
        ),
        debtProtectionSimple: selectorForm(
          state,
          'loadControl',
          'liquidityAsset',
          'sumInsurance',
          'searchValue',
          'insuranceType',
        ),
        debtProtectionAdvance: selectorForm(
          state,
          'houseRemain',
          'autoRemain',
          'personRemain',
          'businessRemain',
          'etcRemain',
          'deposit',
          'liquidityAsset',
          'sumInsurance',
          'summaryLoads',
          'summaryAssets',
          'searchValue',
          'insuranceType',
        ),
        familyIncomeProtectionSimple: selectorForm(
          state,
          'moneyPrepared',
          'sumInsurance',
          'searchValue',
          'insuranceType',
        ),
        familyIncomeProtectionAdvance: selectorForm(
          state,
          'inflationRate',
          'familyCost',
          'careYears',
          'sumInsurance',
          'moneyBeforeInflationMerged',
          'moneyAfterInflationMerged',
          'searchValue',
          'insuranceType',
        ),
        retirementSimple: selectorForm(
          state,
          'preparedExpense',
          'expectedMoney',
          'searchValue',
          'insuranceType',
        ),
        retirementAdvance: selectorForm(
          state,
          'inflationRate',
          'presentCost',
          'expectedRetireAge',
          'costRateAfterRetire',
          'expectedDeadAge',
          'incomeRate',
          'costRequired',
          'pension',
          'etcIncome',
          'yearBeforeRetire',
          'costAfterRetire',
          'costAtDeadAge',
          'costShouldPrepared',
          'searchValue',
          'insuranceType',
        ),
        saving: selectorForm(
          state,
          'inflationRate',
          'expectedSaving',
          'spendTime',
          'getMoneyWhenDead',
          'getRebate',
          'rebateMoney',
          'sumInsured',
          'searchValue',
          'insuranceType',
        ),
        taxReduction: selectorForm(
          state,
          'income',
          'lifePremiumCost',
          'healthPremiumCost',
          'premiumRetire',
          'rmf',
          'providentFund',
          'summary_1',
          'summary_2',
          'health',
          'searchValue',
          'retire',
          'insuranceType',
        ),
      }
    },
    {savePlanning},
  ),
  reduxForm({
    form: 'Planning',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)

export default windowSize(enhancer(PlanningForm))
