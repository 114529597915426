import React from 'react'
import styled from 'react-emotion'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CollapseFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const CollapseTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #ffb933;
  min-width: fit-content;
  margin-right: 5px;
`

const Line = styled.div`
  background-color: #ffb933;
  height: 2px;
  width: 100%;
  margin-left: 20px;
  margin-right: 17px;
`
const ChevronUpIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  color: #ffb933;
`

export default props => {
  const {children, collapseActive, setCollapseActive, title} = props
  return (
    <CollapseFieldWrapper>
      {children}
      <CollapseHeader onClick={() => setCollapseActive(!collapseActive)}>
        <CollapseTitle>{title}</CollapseTitle>
        {/* <Line /> */}
        <ChevronUpIcon
          icon={collapseActive ? ['fas', 'angle-down'] : ['fas', 'angle-up']}
        />
      </CollapseHeader>
    </CollapseFieldWrapper>
  )
}
