import React from 'react'
import {connect} from 'react-redux'
import {compose, withState, withHandlers} from 'recompose'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import TimeAgo from 'react-timeago'

import CommentBox from './CommentBox'
import ReplyBox from './ReplyBox'
import EditBox from './EditComment'
import Button from '../../share/Button'
import Modal from '../../share/Modal'
import Auth from '../../Auth'
import Delete from './DeleteModal'
import UserIcon from '../../../static/images/avatar-02.svg'
import {addReplyComment} from '../../../ducks/noonSquare'
import {responsive} from '../../../core/style'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 57px auto 30px;
`

const ReplyContainer = styled(Container)`
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
`

const CommentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-bottom: ${props => (props.isShowReply ? '1px solid #ffd482' : 'none')};
  margin-bottom: ${props => (props.isShowReply ? '20.5px' : '10px')};
`

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;

  border-radius: 50%;
  object-fit: cover;
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MessageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const UserName = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  margin-right: 10px;
`

const TimeAgoStyled = styled(TimeAgo)`
  margin-bottom: 5px;

  font-size: 12px;
`

const Message = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  white-space: pre-wrap;
  max-width: 540px;
`

const Reply = styled.div`
  font-size: 12px;
  margin-bottom: 22.5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ChevronUpIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  cursor: pointer;
  color: ${props => props.theme.FONT};
  margin-left: 10px;
`

const More = styled(Button)`
  height: 20px;
  margin-bottom: 10px;

  background-color: transparent;
  font-size: 12px;
  color: ${props => props.theme.FONT};

  :hover,
  :focus {
    background-color: transparent;
    color: ${props => props.theme.FONT};
  }
`

const replyEnhancer = compose(
  withState('isReplyActive', 'setReplyActive', false),
  withState('isMore', 'setMore', false),
  connect(
    state => ({
      user: state.auth.user,
      isLogin: state.auth.isLogin,
    }),
    {addReplyComment},
  ),
)

const Replies = replyEnhancer(
  ({
    isReplyActive,
    setReplyActive,
    replyComment,
    commentId,
    isShowReply,
    isLogin,
    isMore,
    setMore,
  }) => (
    <ReplyContainer>
      {isShowReply && (
        <Reply
          disabled={replyComment.length === 0 && !isLogin}
          onClick={() =>
            !(replyComment.length === 0 && !isLogin) &&
            setReplyActive(!isReplyActive)
          }>
          {replyComment.length === 0
            ? 'Reply'
            : isReplyActive === false
            ? `View ${replyComment.length} reply`
            : 'Replies'}
          {isReplyActive && <ChevronUpIcon icon={['fas', 'angle-down']} />}
        </Reply>
      )}
      {isReplyActive && (
        <React.Fragment>
          {replyComment.map((comment, index) => {
            if (isMore) {
              return (
                <Comment key={index} comment={comment} isShowReply={false} />
              )
            } else if (index < 2) {
              return (
                <Comment key={index} comment={comment} isShowReply={false} />
              )
            }
          })}
          {replyComment.length > 2 && (
            <More onClick={() => setMore(!isMore)}>
              {isMore ? 'ซ่อนความคิดเห็น' : 'ความคิดเห็นเพิ่มเติม'}
              <ChevronUpIcon
                icon={['fas', `${isMore ? 'angle-up' : 'angle-down'}`]}
              />
            </More>
          )}
          {isLogin && <ReplyBox commentId={commentId} />}
        </React.Fragment>
      )}
    </ReplyContainer>
  ),
)

const IconContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const EditIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-left: 10px;

  cursor: pointer;
`
const DeleteIcon = styled(EditIcon)``

const commentEnhancer = compose(
  connect(
    state => ({
      user: state.auth.user,
      articleId: state.noonSquare.article.article.id,
    }),
    {},
  ),
  withState('isEditing', 'setEditing', false),
  withState('isDelete', 'setDelete', false),
  withHandlers({
    toggleEdit: ({isEditing, setEditing}) => () => {
      setEditing(!isEditing)
    },
    onDeleteClick: ({setDelete}) => () => {
      setDelete(true)
    },
  }),
)

const Comment = commentEnhancer(
  ({
    isEditing,
    toggleEdit,
    articleId,
    comment,
    isShowReply = true,
    user = {},
    onDeleteClick,
    isDelete,
    setDelete,
  }) => {
    const commentName = `comment${comment.id}`
    return (
      <CommentContainer isShowReply={isShowReply}>
        <UserImage src={comment.user.avatar || UserIcon} />
        <MessageContainer>
          <MessageHeaderContainer>
            <UserName>{comment.user.topicName}</UserName>
            <TimeAgoStyled date={comment.createdAt} />
            {user.id === comment.user.id && (
              <IconContainer>
                <EditIcon icon={['far', 'edit']} onClick={toggleEdit} />
                <DeleteIcon
                  icon={['far', 'trash-alt']}
                  onClick={onDeleteClick}
                />
              </IconContainer>
            )}
          </MessageHeaderContainer>
          {isEditing ? (
            <EditBox
              name={commentName}
              commentId={comment.id}
              initialValues={{[commentName]: comment.comment}}
              callback={toggleEdit}
            />
          ) : (
            <Message>{comment.comment}</Message>
          )}
          <Replies
            isShowReply={isShowReply}
            replyComment={comment.childComments || []}
            commentId={comment.id}
            articleId={articleId}
          />
        </MessageContainer>
        <Delete
          isShow={isDelete}
          setIsShowModal={setDelete}
          commentId={comment.id}
        />
      </CommentContainer>
    )
  },
)

const CommentBoxDummyContainer = styled.div`
  position: relative;

  width: 600px;
  height: 130px;
  padding: 17px 30px;
  margin: 0 0 15px;
  overflow: hidden;

  border-radius: 5px;
  border: 1px solid #ffd482;

  font-size: 16px;
  text-align: left;

  ${props =>
    responsive.mobile(css`
      width: 100%;
    `)};
`

const LoginContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.7);
`

const LoginButton = styled(Button)`
  height: 30px;
  padding: 0 20px;

  font-size: 12px;
`

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: ${props =>
      props.category === 'login'
        ? '20px 0'
        : props.category === 'register'
        ? '0 0 60px'
        : '20px 0 60px'};
  }
`

const CommentBoxDummy = ({onLoginClick}) => (
  <CommentBoxDummyContainer>
    <span>{'แสดงความคิดเห็น...'}</span>
    <LoginContainer>
      <LoginButton onClick={onLoginClick} icons={['fa', 'user-circle']}>
        เข้าสู่ระบบเพื่อแสดงความคิดเห็น
      </LoginButton>
    </LoginContainer>
  </CommentBoxDummyContainer>
)

const Comments = ({
  comments,
  category,
  setCategory,
  isShowModal,
  setIsShowModal,
  onLoginClick,
  isLogin,
  isMore,
  toggleMore,
  onHeaderRegister,
  onHeaderLogin,
  onSetConfirmEmail,
  onSetForgetPassword,
  onCloseModal,
}) => (
  <Container>
    {isLogin ? <CommentBox /> : <CommentBoxDummy onLoginClick={onLoginClick} />}
    {comments.map((comment, index) => {
      if (isMore) {
        return <Comment key={index} comment={comment} />
      } else if (index < 2) {
        return <Comment key={index} comment={comment} />
      }
    })}
    {comments.length > 2 && (
      <More onClick={toggleMore}>
        {isMore ? 'ซ่อนความคิดเห็น' : 'ความคิดเห็นเพิ่มเติม'}
        <ChevronUpIcon
          icon={['fas', `${isMore ? 'angle-up' : 'angle-down'}`]}
        />
      </More>
    )}
    <CustomModal
      key={isShowModal}
      setIsShowModal={setIsShowModal}
      isShowModal={isShowModal}
      category={category}>
      <Auth
        modal
        setCategory={setCategory}
        category={category}
        onHeaderRegister={onHeaderRegister}
        onHeaderLogin={onHeaderLogin}
        onSetConfirmEmail={onSetConfirmEmail}
        onSetForgetPassword={onSetForgetPassword}
        closeModal={onCloseModal}
      />
    </CustomModal>
  </Container>
)

const enhancer = compose(
  connect(
    state => ({
      comments: state.noonSquare.article.comments || [],
      isLogin: state.auth.isLogin,
    }),
    {},
  ),
  withState('category', 'setCategory', 'login'),
  withState('isShowModal', 'setIsShowModal', false),
  withState('isMore', 'setMore', false),
  withHandlers({
    onLoginClick: ({setIsShowModal}) => () => {
      setIsShowModal(true)
    },
    toggleMore: ({isMore, setMore}) => () => {
      setMore(!isMore)
    },
    onHeaderRegister: ({setCategory}) => () => setCategory('register'),
    onHeaderLogin: ({setCategory}) => () => setCategory('login'),
    onSetConfirmEmail: ({setCategory}) => () => setCategory('confirmEmail'),
    onSetForgetPassword: ({setCategory}) => () => setCategory('forgetPassword'),
    onCloseModal: ({setIsShowModal}) => () => setIsShowModal(false),
  }),
)

export default enhancer(Comments)
