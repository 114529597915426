import React from 'react'
import Modal from '../../share/Modal'
import {compose, withProps, withHandlers} from 'recompose'
import styled from 'react-emotion'
import CustomField from '../../share/Field'
import {obj2Url, displayNumber} from '../../../core/helper'
import Button from '../../share/Button'
import {withForm} from '../enhancers'
import {Field, PenCurrency} from '../components/index'

const WrapContentModal = styled.form`
  padding: 60px;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  display: flex;
  flex-direction: column;
`
const Title = styled.h2`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`
const Select = styled(CustomField)`
  justify-content: space-between;
  width: 300px;
  .label {
    justify-content: flex-start;
    margin-right: 0;
  }
  input {
    text-align: center;
    flex: 1;
  }
  .Select-control {
    text-align: center;
  }
  @media (max-width: 425px) {
    width: 193px;
    flex-direction: column;
    .label {
      margin-right: 0;
      justify-content: center;
    }
  }
`
const Slider = styled(CustomField)`
  height: unset;
  width: 280px;
  margin: 0;
  .ant-slider {
    width: 100%;
    margin-left: unset;
  }
`
const WrapTextSlider = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
const ConfirmButton = styled(Button)`
  margin-top: 20px;
  &:focus,
  &:active {
    color: #333333;
  }
`
const SliderValue = styled.div`
  font-size: 24px;
`
const WrapInfo = styled.div``
const Span = styled.span`
  color: #265fa7;
`
const WrapValue = styled.div``
const lable = {
  female: 'หญิง',
  male: 'ชาย',
}
const typeOptions = [
  {label: 'ตลอดชีพ', value: 'whole_life', noonStar: 5},
  {label: 'ชั่วระยะเวลา', value: 'term', noonStar: 5},
  {label: 'บำนาญ', value: 'annuity', noonStar: 3},
  {label: 'สะสมทรัพย์', value: 'saving', noonStar: 1},
]
const noonStar = {
  whole_life: 1,
  term: 1,
  annuity: 1,
  saving: 1,
}
const BuyLifeModal = props => (
  <Modal isShowModal={props.isShowModal} setIsShowModal={props.onClose}>
    <WrapContentModal>
      <Title>ลองมาเลือกดูประกันชีวิต</Title>
      <WrapInfo>
        เพศ <Span>{lable[props.gender] || ''}</Span> อายุ{' '}
        <Span>{props.age || ''}</Span> ปี
      </WrapInfo>

      <Field
        name="sumInsured"
        component={PenCurrency}
        marginBottom="16px"
        min={0}
        max={props.max}
        minValue={0}
      />
      <Slider type="slider" name="sumInsured" min={0} max={props.max} />
      <WrapTextSlider>
        <WrapValue>0</WrapValue>
        <WrapValue>{displayNumber(props.max)}</WrapValue>
      </WrapTextSlider>
      <Select
        name="insuranceType"
        type="selector"
        label="เลือกดูจากแบบ"
        options={typeOptions}
        placeholder="กรุณาเลือก"
      />
      <div>แบบที่เน้นความคุ้มครองสูงสุด</div>
      <a href={props.pathUrl} target="_blank">
        <ConfirmButton disabled={props.invalid}>ดูแบบประกัน</ConfirmButton>
      </a>
    </WrapContentModal>
  </Modal>
)

const enhancer = compose(
  withProps(props => ({
    initialValues: {
      sumInsured: props.values ? props.values.sumInsured : 0,
    },
  })),
  withForm({
    form: 'NoonQuestionBuyLifeModalForm',
  }),
  withProps(props => ({
    sumInsured: props.values.sumInsured || 0,
    invalid:
      !props.values.insuranceType ||
      !props.values.sumInsured ||
      props.values.sumInsured === 0,
    pathUrl: `/smart/life/intermediate/product?${obj2Url({
      age: props.age,
      gender: props.gender,
      insuranceType: props.values.insuranceType,
      noonStar: noonStar[props.values.insuranceType],
      searchBy: 'sum_insured',
      searchValue: props.values.sumInsured,
      sortBy: 'min_premium',
      weightType: 5,
    })}`,
  })),
  withHandlers({
    onClose: props => () => {
      props.setIsShowModal(false)
      props.reset()
    },
  }),
)

export default enhancer(BuyLifeModal)
