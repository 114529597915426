import React from 'react'
import styled from 'react-emotion'
import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'

import Modal from '../../share/Modal'
import Button from '../../share/Button'
import {deleteComment} from '../../../ducks/noonSquare'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  color: #333333;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`

const CustomButton = styled(Button)`
  height: 30px;
  margin: 0 10px;

  font-size: 12px;
`

const Delete = ({isShow, setIsShowModal, onConfirm, onClose}) => (
  <Modal isShowModal={isShow} setIsShowModal={setIsShowModal}>
    <Container>
      <Header>{'ต้องการที่จะลบความคิดเห็นนี้หรือไม่ ?'}</Header>
      <ButtonContainer>
        <CustomButton onClick={onConfirm} icons={['fa', 'check-circle']}>
          ยืนยัน
        </CustomButton>
        <CustomButton onClick={onClose} icons={['fa', 'times-circle']}>
          ยกเลิก
        </CustomButton>
      </ButtonContainer>
    </Container>
  </Modal>
)

const enhancer = compose(
  connect(
    state => ({
      article: state.noonSquare.article.article || {},
    }),
    {deleteComment},
  ),
  withHandlers({
    onConfirm: ({article, commentId, deleteComment}) => () => {
      deleteComment(article.id, commentId)
    },
    onClose: ({setIsShowModal}) => () => {
      setIsShowModal(false)
    },
  }),
)

export default enhancer(Delete)
