import React from 'react'
import {compose, withHandlers, withState, withProps} from 'recompose'
import styled from 'react-emotion'
import {Modal} from 'antd'
import Button from '../../../share/Button'
import {spidusStore, withAnswers} from '../../core/spidus'
import DynamicInput from '../../components/DynamicInput'

const DetailCardLayout = styled.div`
  padding: 10px;
  border: 1px solid #ffb933;
  border-radius: 5px;

  > div {
    margin-top: 5px;
    width: auto;
  }
  > div:first-child {
    margin-top: 0;
  }
`
const DetailCardHeader = styled.div`
  display: flex;
  flex-direction: row;
`
const DetailCardHeaderCaption = styled.div`
  flex: 2;
`
const DetailCardHeaderAction = styled.div`
  flex: 1;

  > div {
    align-items: flex-end;
  }
`
const DetailCardFooterActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    margin-left: 10px;
  }

  > div:first-child {
    margin-left: 0;
  }
`
const EditButton = styled(Button)``
const CancelButton = styled(Button)`
  background-color: white;
  border: 1px solid #ffb933;

  &:hover {
    border: 1px solid #ffb933;
  }
`
const SaveButton = styled(Button)``
const DeleteButton = styled(Button)`
  background-color: #f1767b;
  color: white;

  &:hover {
    background-color: #d3676b;
    color: white;
  }
`

const DetailCard = ({
  editing,
  type,
  category,
  caption,
  details,
  onEdit,
  onCancel,
  onDelete,
  onSave,
}) => (
  <DetailCardLayout category={category} type={type}>
    <DetailCardHeader>
      <DetailCardHeaderCaption>{caption}</DetailCardHeaderCaption>
      <DetailCardHeaderAction>
        {editing ? (
          <DeleteButton onClick={onDelete}>Delete</DeleteButton>
        ) : (
          <EditButton onClick={onEdit}>Edit</EditButton>
        )}
      </DetailCardHeaderAction>
    </DetailCardHeader>
    {details.map(({componentType, answerName, ...restProps}) => (
      <DynamicInput
        type={componentType}
        answerName={answerName}
        readOnly={!editing}
        autoSave={false}
        {...restProps}
      />
    ))}
    {editing && (
      <DetailCardFooterActions>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
      </DetailCardFooterActions>
    )}
  </DetailCardLayout>
)

const enhance = compose(
  withAnswers('noontax_config_web_pro', {
    profileDetails: 'profile_details.value',
  }),
  withState('editing', 'setEditing', false),
  withProps(({editing, inputFields, displayFields}) => ({
    details: editing ? inputFields : displayFields,
    inputAnswerNames: inputFields.map(field => field.answerName),
  })),
  withHandlers({
    onEdit: props => () => {
      props.setEditing(true)
    },
    onCancel: props => async () => {
      await spidusStore.loadAnswers(
        'noontax_config_web_pro',
        ...props.inputAnswerNames,
      )
      props.setEditing(false)
    },
    onSave: props => async () => {
      await spidusStore.updateAnswers(
        'noontax_config_web_pro',
        ...props.inputAnswerNames,
      )
      props.setEditing(false)
    },
    onDelete: props => async () => {
      Modal.confirm({
        title: 'คุณต้องการลบรายการนี้ใช่หรือไม่',
        content: 'โปรดยืนยันการทำรายการ',
        okText: 'ใช่',
        cancelText: 'ไม่',
        async onOk() {
          const existTypes = props.profileDetails.map(
            profileDetail => profileDetail.type,
          )
          const removedTypes = existTypes.filter(type => type !== props.type)
          await spidusStore.updateAnswer(
            'noontax_config_web_pro',
            'profile_details',
            removedTypes,
          )
          await props.setEditing(false)
        },
        onCancel() {
          // console.log('Cancel');
        },
        footer: [],
      })
    },
  }),
)

export default enhance(DetailCard)
