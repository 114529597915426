import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, lifecycle} from 'recompose'
import moment from 'moment'

import {displayNumber, obj2Url} from '../../core/helper'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getCompareList, deleteCompare} from '../../ducks/profile'

import {responsive} from '../../core/style'

moment.locale('th')

const Container = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin: 20px 0 30px;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const HeaderSub = styled.span`
  font-size: 16px;
  font-weight: ${props => props.selectedSubHeader && 'bold'};
  cursor: ${props => !props.selectedSubHeader && 'pointer'};
  margin: 0 5px;
`
const Or = styled.span`
  font-size: 16px;
  margin: 0 10px;
`

const ProductInSlotContainer = styled.div`
  position: relative;
  display: flex;
  min-width: 140px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  justify-content: flex-start;
  align-items: center;
  background-color: #e5e5e5;
`

const CompanyLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8.68px;
  margin-left: 11px;
`

const ContentOfProductInSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 54px;
  width: 68px;
  white-space: nowrap;
`
const ContentOfProductInSlot = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

const CompareCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  width: 600px;
  height: 130px;
  overflow: hidden;
  padding: 16px 18px 16px 14px;
  border-radius: 5px;
  margin: 10px 0;

  ${props =>
    responsive.tablet(css`
      width: 495px;
    `)};

  ${props =>
    responsive.mobile(css`
      width: 280px;
      padding: 0 18px 0 14px;
    `)};
`

const YellowTab = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  background-color: #ffb933;
  height: 100%;
`

const CustomCompareCard = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: fit-content;
  height: 70px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`
const CompareCardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 14px;
  margin-bottom: 10px;
`

const DateAndTimeHeader = styled.div`
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      max-width: 115px;
    `)};
`

const CustomLinkComtainer = styled.div`
  display: flex;
  margin-right: 18px;
  align-items: center;
  justify-content: center;
`
const CompareLinkHeader = styled.div`
  font-size: 12px;
  color: #265fa7;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 15px;
`

const BinIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: #7f7f7f;
  cursor: pointer;
`

const MobileCustom = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    responsive.mobile(css`
      overflow: hidden;
    `)};
`
const CompareCard = ({value}) => (
  <ProductInSlotContainer>
    <CompanyLogo src={value.companyOfficialLogoUrl} />
    <ContentOfProductInSlotContainer>
      <ContentOfProductInSlot>{value.companyName}</ContentOfProductInSlot>
      <ContentOfProductInSlot>
        {value.insuranceType ? value.companyShortName : value.companyId}
      </ContentOfProductInSlot>
      <ContentOfProductInSlot>
        {displayNumber(
          value.insuranceType ? value.sumInsured : value.amountNet,
        )}
        บ./ปี
      </ContentOfProductInSlot>
    </ContentOfProductInSlotContainer>
  </ProductInSlotContainer>
)

const CompareList = ({compareList = [], type = '', deleteCompare}) =>
  compareList.length > 0
    ? compareList.map(value => (
        <CompareCardContainer key={value.cAt}>
          <YellowTab />
          <CompareCardHeaderContainer>
            <DateAndTimeHeader>
              {moment(value.cAt).format('วันที่ lll น.')}
            </DateAndTimeHeader>
            <CustomLinkComtainer>
              <CompareLinkHeader
                onClick={() =>
                  window.open(
                    `/smart/${type}/starter/product/compare?` +
                      value.policyList.reduce(
                        (mem, value, index) => mem + `ids=${value.id}&`,
                        '',
                      ) +
                      value.policyList.reduce((mem, value, index) =>
                        obj2Url(JSON.parse(atob(value.paramsEncode))),
                      ) +
                      `&compareHistoryId=${value.compareHistoryId}`,
                    '_blank',
                  )
                }>
                ดูเปรียบเทียบแบบ
              </CompareLinkHeader>
              <BinIcon
                icon={['far', 'trash-alt']}
                onClick={() =>
                  deleteCompare(compareList[0].compareHistoryId, type)
                }
              />
            </CustomLinkComtainer>
          </CompareCardHeaderContainer>
          <MobileCustom>
            <CustomCompareCard>
              {(value.policyList || []).map((value, index) => (
                <CompareCard
                  value={value}
                  category={type}
                  key={value.id}
                  index={index}
                />
              ))}
            </CustomCompareCard>
          </MobileCustom>
        </CompareCardContainer>
      ))
    : `ไม่มีแบบประกัน${type === 'motor' ? 'รถยนตร์' : 'ชีวิต'}ที่เปรียบเทียบ`

const CompareInsurance = ({
  selectedSubHeader,
  setSelectedSubHeader,
  compareMotor,
  compareLife,
  deleteCompare,
}) => (
  <Container>
    <Header>เปรียบเทียบแบบประกัน</Header>
    <HeaderOption>
      <HeaderSub
        selectedSubHeader={selectedSubHeader === 'motor'}
        onClick={() => setSelectedSubHeader('motor')}>
        ประกันรถยนต์
      </HeaderSub>
      <Or>|</Or>
      <HeaderSub
        selectedSubHeader={selectedSubHeader === 'life'}
        onClick={() => setSelectedSubHeader('life')}>
        ประกันชีวิต
      </HeaderSub>
    </HeaderOption>
    <CompareList
      compareList={selectedSubHeader === 'motor' ? compareMotor : compareLife}
      type={selectedSubHeader}
      deleteCompare={deleteCompare}
    />
  </Container>
)

const enhancer = compose(
  withState('selectedSubHeader', 'setSelectedSubHeader', 'motor'),
  connect(
    state => ({
      compareMotor: state.profile.compareMotor,
      compareLife: state.profile.compareLife,
    }),
    {getCompareList, deleteCompare},
  ),

  lifecycle({
    componentDidMount() {
      this.props.getCompareList()
    },
  }),
)
export default enhancer(CompareInsurance)
