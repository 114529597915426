import {takeLatest, put} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'
import {message} from 'antd'
import {httpPost, API_HOST} from '../../core/httpClient'

const SET_DATA = 'SET_DATA_EVENT'
const REGISTER = 'REGISTER_EVENT'

export const setData = Creator(SET_DATA, 'data')
export const register = Creator(REGISTER, 'data', 'callback', 'lang')

export function* registerSaga({payload: {data, callback = () => {}, lang}}) {
  try {
    yield put(setData({isLoading: true}))
    const {name, ...filteredData} = data
    const splitedName = name.split(' ')
    const modifiedData = {
      ...filteredData,
      firstName: splitedName[0] || '',
      lastName: splitedName[1] || '',
      eventName: 'cebit_2019',
    }
    yield httpPost(`${API_HOST}marketing_events`, modifiedData)
    message.success(
      lang === 'th'
        ? 'ลงทะเบียบสำเร็จแล้ว'
        : 'Your registration was successful.',
      5,
    )
    callback()
  } catch (error) {
    message.error(
      (error.response || {}).status || lang === 'th'
        ? 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง'
        : 'Something went wrong. Please try again.',
      5,
    )
  } finally {
    yield put(setData({isLoading: false}))
  }
}

export function* watchEventRegisteringSaga() {
  yield takeLatest(REGISTER, registerSaga)
}

const initial = {
  isLoading: false,
}

export default createReducer(initial, state => ({
  [SET_DATA]: ({data}) => ({...state, ...data}),
}))
