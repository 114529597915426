import React from 'react'
import {connect} from 'react-redux'
import {
  compose,
  withHandlers,
  withState,
  withProps,
  renderNothing,
  branch,
} from 'recompose'
import {Field, getFormValues, change} from 'redux-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Tooltip, Button, Modal, Input, Row, Col} from 'antd'
import styled from 'react-emotion'
import spidusStore, {jsonPrettyPrint} from './spidusStore'

const Wrapper = styled.div``

const ActionButton = styled(Button)`
  z-index: 10;
  position: fixed;
  bottom: 45px;
  left: 24px;
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18),
    0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  width: 56px;
  height: 56px;
  transition: all 0.1s ease-out;
  background-color: #222 !important;
  border-color: #222 !important;
  color: white !important;

  :hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.28);
    background-color: #222 !important;
    border-color: #b11313 !important;
    color: #b11313 !important;
  }

  > svg {
    font-size: 30px;
    margin-top: 3px;
  }
`

const TextArea = withProps(props => ({
  onChange: props.input.onChange,
  value: props.input.value,
}))(Input.TextArea)

const InputRow = styled(Row)`
  textarea {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #333 !important;
    border-color: #333 !important;
    color: white !important;

    :hover {
      background-color: #333 !important;
      border-color: #b11313 !important;
      color: #b11313 !important;
    }
  }
`

const Description = styled.div`
  margin: 10px 0;
  font-style: italic;
  color: #447bbe;
`

const toMs = time => (time ? `${Math.round(time * 100) / 100} ms` : null)

const SpidusConsole = props => (
  <Wrapper>
    <Tooltip
      placement="right"
      title={toMs(props.lastExecutionTime)}
      visible={props.lastExecutionTime}
      overlayStyle={{
        position: 'fixed',
        zIndex: 1,
      }}>
      <ActionButton onClick={props.show} shape="circle">
        <FontAwesomeIcon
          icon={['fas', 'spider']}
          color={props.lastExecutionError ? 'red' : undefined}
          spin={props.executioning}
        />
      </ActionButton>
    </Tooltip>
    <Modal
      title="Spidus Console"
      visible={props.visible}
      footer={false}
      maskClosable={false}
      width={750}
      onCancel={props.hide}>
      <InputRow>
        <Col span={20}>
          <Field
            name="lastSessionId"
            component={TextArea}
            disabled={props.executioning}
            style={{marginBottom: 8}}
            placeholder="session id"
          />
        </Col>
        <Col span={4}>
          <Button onClick={props.loadSessionId} loading={props.reloading}>
            RELOAD
          </Button>
        </Col>
      </InputRow>
      <InputRow>
        <Col span={20}>
          <Field
            name="lastExecutionFormula"
            component={TextArea}
            enterButton="Execute"
            size="large"
            autosize
            disabled={props.executioning}
          />
        </Col>
        <Col span={4}>
          <Button onClick={props.execute} loading={props.executioning}>
            EXECUTE
          </Button>
        </Col>
      </InputRow>
      <Description>
        Execution time: {toMs(props.lastExecutionTime)}{' '}
      </Description>
      <Field
        name="lastExecutionResult"
        component={TextArea}
        autosize={{minRows: 10}}
        disabled={props.executioning}
        onKeyDown={props.formatPrettyResultWhenShift}
      />
    </Modal>
  </Wrapper>
)

const enhance = compose(
  branch(props => {
    try {
      return (
        new URLSearchParams(window.location.search).get('debug') !== 'heyspidy'
      )
    } catch (e) {
      return true
    }
  }, renderNothing),
  connect(state => ({...getFormValues(spidusStore.STORE_NAME)(state)})),
  withState('visible', 'setVisible', false),
  withState('reloading', 'setReloading', false),
  withHandlers({
    show: props => () => props.setVisible(true),
    hide: props => () => props.setVisible(false),
    execute: props => () => {
      spidusStore.execute(props.lastExecutionFormula)
    },
    loadSessionId: props => async () => {
      props.setReloading(true)

      spidusStore.setCustomSessionId(props.lastSessionId)
      const answerNames = spidusStore.getRegistedNames().filter(name => !!name)
      await spidusStore.loadAnswers(props.configName, ...answerNames)

      props.setReloading(false)
    },
    formatPrettyResultWhenShift: props => e => {
      if (e.keyCode === 16) {
        props.change(
          'lastExecutionResult',
          jsonPrettyPrint(props.lastExecutionResult),
        )
      }
    },
  }),
)

export default enhance(SpidusConsole)
