import React, {useState} from 'react'
import styled, {css} from 'react-emotion'
import {compose} from 'recompose'
import {Field} from 'redux-form'
import {connect} from 'react-redux'
import {Select} from 'antd'

import {responsive} from '../../../../core/style'
import {Label} from '../../../share/Field'
import {fetchInsurancePolicyName} from '../../../../ducks/product'

const Container = styled.div`
  display: flex;
  margin-right: 40px;

  ${props =>
    responsive.mobile(css`
      margin-right: 0;
      margin-bottom: 23px;
      flex-direction: column;

      input {
        width: 280px;
      }
    `)};
`

const CustomLabel = styled(Label)`
  min-width: unset;

  ${props =>
    responsive.mobile(css`
      margin-bottom: 15px;
      margin-right: 0;
      width: 100%;
      justify-content: flex-start;
    `)}
`

const Selector = styled(Select)`
  width: 300px !important;

  > div.ant-select-selection,
  .ant-select-selection--single,
  .ant-select-focused,
  .ant-select-no-arrow {
    height: 40px;

    border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
    box-shadow: none;

    :hover,
    :focus {
      border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
      box-shadow: none;
    }
    > div.ant-select-selection__rendered {
      height: 40px;
      > div.ant-select-selection__placeholder {
        text-align: center;
      }
      > div.ant-select-selection-selected-value {
        line-height: 40px;
      }
    }
  }
  ${props =>
    responsive.mobile(css`
      width: 280px !important;
    `)}
`

const {Option} = Select

let timeout

const SearchPolicy = ({
  input,
  insurancePolicyNameList,
  fetchInsurancePolicyName,
}) => {
  const fetch = value => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }

    timeout = setTimeout(
      () => fetchInsurancePolicyName({userQuery: value}),
      500,
    )
  }

  const handleSearch = value => {
    if (value) {
      fetch(value)
    }
  }

  return (
    <Container>
      <CustomLabel>ชื่อแบบประกัน</CustomLabel>
      <Selector
        {...input}
        showSearch
        placeholder="ชื่อแบบประกัน"
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={null}
        showArrow={false}>
        {insurancePolicyNameList.map(option => (
          <Option {...option}>{option.label}</Option>
        ))}
      </Selector>
    </Container>
  )
}

const SearchField = props => (
  <Field name="lifeInsurancePolicyId" component={SearchPolicy} {...props} />
)

const enhancer = compose(
  connect(
    state => ({
      insurancePolicyNameList: state.product.insurancePolicyNameList,
    }),
    {fetchInsurancePolicyName},
  ),
)

export default enhancer(SearchField)
