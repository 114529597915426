import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, defaultProps, branch, withProps} from 'recompose'
import {toCurrency} from '../core/helper'
import Slider from '../../share/Field/Slider'
import {withDisabled, withSpidus, withValue, omitProps} from '../enhances/index'

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 60px;
`

const CustomSliderBackground = styled(Slider)`
  position: absolute;
  width: calc(100% - 12px);
  max-width: 100%;

  > div:first-child {
    display: none;
  }
  .ant-slider-track {
    background-color: #265fa7;
  }
  .ant-slider-step {
    background-color: #6a94cb;
  }
  .ant-slider-dot {
    display: none;
  }
`

const NormalSlider = props => (
  <Layout>
    <CustomSliderBackground
      {...props}
      marks={props.marks}
      min={props.min}
      max={props.max}
    />
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  defaultProps({
    min: 0,
    max: 0,
  }),
  withProps(props => ({
    marks: {
      0: {
        style: {
          fontSize: '12px',
          color: '#6A94CB',
          left: '5px',
        },
        label: <span>฿0</span>,
      },
      [`${props.max}`]: {
        style: {
          fontSize: '12px',
          color: '#6A94CB',
          left: 'unset',
          right: '-30px',
        },
        label: <span>{toCurrency(props.max, {prefix: '฿'})}</span>,
      },
    },
  })),
  withHandlers({
    onAfterChange: props => e => {
      props.onBlur && props.onBlur(props.value)
    },
  }),
  omitProps('onBlur'),
)

export default enhance(NormalSlider)
