import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, branch, withProps, defaultProps} from 'recompose'
import {withSpidus, withValue} from '../enhances'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {toCurrency} from '../core/helper'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    text-align: center;
  }
`
const Value = styled.div`
  display: flex;
  min-height: 39px;
  align-items: center;
  justify-content: center;
`
const Caption = styled.div`
  text-align: center;
  color: #7f7f7f;

  @media (max-width: 600px) {
    font-size: 14px;
    ${props =>
      props.shortCaption &&
      css`
        > span {
          display: none;
        }
        &:after{
          content: '${props.shortCaption}';
        }
      `}
  }
`

const CurrencyStyle = styled.div`
  font-size: 26px;
  font-family: 'Prompt';
  font-weight: 700;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`
const WrapperTax = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Tax = props => (
  <Container className={props.className}>
    <Caption shortCaption={props.shortCaption}>
      <span>{props.caption}</span>
    </Caption>
    <Value>
      <FontAwesomeIcon
        icon={['fas', 'spinner']}
        spin
        hidden={!props.loading}
        style={{color: '#333333', fontSize: 26}}
      />
      <WrapperTax>
        <CurrencyStyle style={{color: props.valueColor}} hidden={props.loading}>
          {props.displayValue}
        </CurrencyStyle>
      </WrapperTax>
    </Value>
  </Container>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  defaultProps({
    value: 0,
  }),
  withProps(props => {
    const {positivePrefix, negativePrefix, positiveColor, negativeColor} = props
    let displayValue = toCurrency(props.value, {prefix: ''})
    let valueColor = '#333333'

    if (positivePrefix) {
      if (displayValue.charAt(0) !== '-') {
        valueColor = positiveColor
        displayValue = `${positivePrefix} ${displayValue}`
      }
    }
    if (negativePrefix) {
      if (displayValue.charAt(0) === '-') {
        valueColor = negativeColor
      }
      displayValue = displayValue.replace(/^-/, `${negativePrefix} `)
    }

    return {
      displayValue: displayValue,
      valueColor: valueColor,
    }
  }),
)
export default enhance(Tax)
