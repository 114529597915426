import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import AnswerGroup from './AnswerGroup'
import {Button, SpidusManager, TextInput} from '../../components'
import Modal from '../../../share/Modal'
import {withCaption} from '../../enhances'
import {Checkbox} from './index'

const CommingSoon = styled(TextInput)`
  opacity: 0.6;
`

// export const Checkbox = styled(OldCheckbox)`
//   > div {
//     > div {
//       display: flex;
//       flex-direction: row-reverse;
//       justify-content: space-between;
//       width: 100%;
//     }
//   }
// `

const LayoutSpidusManager = styled(SpidusManager)`
  padding: 30px 20px 28px 20px;
`

const Header = styled.div``

const Title = styled.div`
  margin: 0 -5px 0 -5px;
  font-size: 30px;
  font-weight: bold;
  font-family: Prompt;
  color: #333333;
  text-align: center;
`

const Body = styled.div`
  margin-top: 32px;

  > div:not(:first-child) {
    margin-top: 48px;
  }

  ${AnswerGroup.Body} {
    > ${withCaption.Layout}.checkbox {
      flex-wrap: unset;
      position: relative;
      > ${withCaption.Left} {
        flex: 1;
        margin-bottom: 0px;

        svg {
          position: absolute;
          right: 0px;
        }
      }
      > ${withCaption.Right} {
        margin-right: 30px;
        width: auto;
        text-align: right;

        > div {
          margin-bottom: -2px;
        }
      }
    }
  }
`

const Footer = styled.div`
  margin-top: 64px;

  > div {
    width: 100%;
    max-width: 324px;
    margin: 0 auto;

    button {
      width: 100% !important;
    }
  }
`

const AddButton = styled(Button)`
  max-width: 324px;
  width: 100%;
  height: 48px;
  background: #ffb933;
  border-radius: 50px;
  font-size: 20px;
  font-family: Prompt;
  color: #ffffff;

  &:hover,
  &:focus {
    color: #ffffff !important;
  }
`

const CustomModal = styled(Modal)`
  max-width: 550px;
`

const CustomAnswerGroup = styled(AnswerGroup)`
  ${AnswerGroup.Body} {
    padding-left: 15px;
  }
`

const DeductSelector = props => (
  <CustomModal
    isShowModal={props.isShowModal}
    setIsShowModal={props.setIsShowModal}>
    <LayoutSpidusManager configName="noontax_config_web_prepare_tax">
      <Header>
        <Title>เลือกค่าลดหย่อนปี 2562</Title>
      </Header>
      <Body>
        <CustomAnswerGroup
          caption="ลดหย่อนจากบุคคลในครอบครัว"
          spaceBetweenLine="16px">
          <Checkbox
            answerName="use_deduct_spouse"
            caption="คู่สมรส"
            tooltip={['จดทะเบียนสมรสถูกต้อง', 'คู่สมรสไม่มีรายได้']}
          />
          <Checkbox
            answerName="use_deduct_children"
            caption="ลูก"
            tooltip={[
              'เป็นลูกแท้ๆ (คุณจดทะเบียนสมรส)/ลูกบุญธรรม (จดทะเบียนรับเป็นบุตรบุญธรรมแล้ว)',
              'ลูกมีรายได้ไม่ถึง ฿30,000 ในปี 62',
              'ลูกอายุไม่ถึง 20 และยังไม่แต่งงาน/อายุ 20-25 และยังศึกษาอยู่/ศาลสั่งให้ลูกเป็นคนไร้ความสามารถ',
            ]}
          />
          <Checkbox
            answerName="use_deduct_dad"
            caption="พ่อ"
            tooltip={[
              'พ่ออายุมากกว่า 60 ปี',
              'พ่อมีรายได้ไม่เกิน ฿30,000 ในปี 62',
            ]}
          />
          <Checkbox
            answerName="use_deduct_mom"
            caption="แม่"
            tooltip={[
              'แม่อายุมากกว่า 60 ปี',
              'แม่มีรายได้ไม่เกิน ฿30,000 ในปี 62',
            ]}
          />
          <Checkbox
            answerName="use_deduct_sdad"
            caption="พ่อคู่สมรส"
            tooltip={[
              'พ่อคู่สมรสอายุมากกว่า 60 ปี',
              'พ่อคู่สมรสมีรายได้ไม่เกิน ฿30,000 ในปี 62',
              'คู่สมรสไม่มีรายได้',
            ]}
          />
          <Checkbox
            answerName="use_deduct_smom"
            caption="แม่คู่สมรส"
            tooltip={[
              'แม่คู่สมรสอายุมากกว่า 60 ปี',
              'แม่คู่สมรสมีรายได้ไม่เกิน ฿30,000 บาทในปี 62',
              'คู่สมรสไม่มีรายได้',
            ]}
          />
          <Checkbox
            answerName="use_deduct_disabled"
            caption="อุปการะผู้พิการ/ทุพพลภาพ"
            tooltip={[
              'มีหนังสือรับรองการดูแลผู้พิการ/ทุพพลภาพ',
              'ผู้พิการ/ทุพพลภาพมีรายได้ไม่เกิน ฿30,000 ในปี 62',
              'ไม่ใช่ตนเอง/คู่สมรส/ลูก/พ่อแม่/พ่อแม่คู่สมรส',
            ]}
          />
          <Checkbox
            answerName="use_deduct_pregnancy"
            caption="ค่าฝากครรภ์และทำคลอด"
            tooltip={[
              'ค่าฝากครรภ์/ค่าบำบัดทางการแพทย์/ค่ายา/ค่าทำคลอด/ค่ากินอยู่ในโรงพยาบาล',
            ]}
          />
        </CustomAnswerGroup>
        <CustomAnswerGroup
          caption="เบี้ยประกันและกองทุนรวม"
          spaceBetweenLine="16px">
          <Checkbox
            answerName="use_deduct_life_insurance"
            caption="เบี้ยประกันชีวิตตนเอง"
            tooltip={[
              'กรมธรรม์คุ้มครองตั้งแต่ 10 ปีขึ้นไป',
              'ถ้ามีเงินคืนระหว่างสัญญา ต้องไม่เกิน 20% ของเบี้ยที่จ่ายไปแล้ว',
            ]}
          />
          <Checkbox
            answerName="use_deduct_annuity_insurance"
            caption="เบี้ยประกันชีวิตแบบบำนาญตนเอง"
            tooltip={['กรมธรรม์คุ้มครองตั้งแต่ 10 ปีขึ้นไป']}
          />
          <Checkbox
            answerName="use_deduct_health_insurance"
            caption="เบี้ยประกันสุขภาพตนเอง"
          />
          <Checkbox
            answerName="use_deduct_parent_health_insurance"
            caption="เบี้ยประกันสุขภาพพ่อแม่"
            tooltip={[
              'พ่อแม่เราหรือพ่อแม่คู่สมรสมีรายได้ไม่เกิน ฿30,000 บาทในปี 62',
              'ถ้าใช้สิทธิ์ของพ่อแม่คู่สมรส คู่สมรสต้องไม่มีรายได้',
            ]}
          />
          <Checkbox
            answerName="use_deduct_ltf"
            caption="กองทุนรวม LTF"
            tooltip={['ต้องถือหน่วยลงทุนไว้โดยไม่ขายอย่างน้อย 7 ปีปฏิทิน']}
          />
          <Checkbox
            answerName="use_deduct_rmf"
            caption="กองทุนรวม RMF"
            tooltip={[
              'ต้องซื้อหน่วยลงทุนทุกปี',
              'ซื้อขั้นต่ำ 3% ของเงินได้ที่ต้องเสียภาษีหรือ ฿5,000',
              'ต้องถือหน่วยลงทุนอย่างน้อย 5 ปี และอายุครบ 55 จึงจะขายได้',
            ]}
          />
        </CustomAnswerGroup>
        <CustomAnswerGroup
          caption="เงินสะสมในกองทุนเพื่อการเกษียณ"
          spaceBetweenLine="16px"
          tooltip={['เฉพาะส่วนที่คุณจ่าย ไม่รวมส่วนที่นายจ้างสมทบ']}>
          <Checkbox
            answerName="use_deduct_sso"
            caption="เงินสมทบกองทุนประกันสังคม"
          />
          <Checkbox
            answerName="use_deduct_official_fund"
            caption="เงินสะสม กบข."
          />
          <Checkbox
            answerName="use_deduct_teacher_fund"
            caption="เงินสะสมกองทุนครูเอกชน"
          />
          <Checkbox
            answerName="use_deduct_provident_fund"
            caption="เงินสะสมกองทุนสำรองเลี้ยงชีพ"
            tooltip={['เฉพาะส่วนที่คุณจ่าย ไม่รวมส่วนที่นายจ้างสมทบ']}
          />
          <Checkbox
            answerName="use_deduct_nsf"
            caption="เงินสะสมกองทุนการออมแห่งชาติ"
          />
        </CustomAnswerGroup>
        <CustomAnswerGroup caption="เงินบริจาค" spaceBetweenLine="16px">
          <Checkbox
            answerName="use_deduct_party_donate"
            caption="เงินบริจาคพรรคการเมือง"
          />
          <Checkbox
            answerName="use_deduct_special_donate"
            caption="เงินบริจาคสนับสนุนการศึกษา/กีฬา"
            tooltip={[
              'บริจาคแก่สถานศึกษา/โรงพยาบาลรัฐ/เพื่อการกีฬา/เพื่อสาธารณประโยชน์',
              'ถ้าบริจาคให้สถานศึกษาต้องทำผ่านระบบ e-Donation',
            ]}
          />
          <Checkbox
            answerName="use_deduct_general_donate"
            caption="เงินบริจาคอื่น"
            tooltip={[
              'บริจาคเพื่อสาธารณกุศล เช่น วัด/มูลนิธิ /สมาคม/ช่วยอุทกภัย',
            ]}
          />
        </CustomAnswerGroup>
        <CustomAnswerGroup
          caption="ลดหย่อนจากเหตุสาธารณะภัย"
          spaceBetweenLine="16px">
          <Checkbox
            answerName="use_deduct_flood_damage_house_1"
            caption="ค่าซ่อมบ้าน (3 ม.ค. - 31 มี.ค. 62)"
            tooltip={['บ้านเสียหายจากอุทกภัยพายุปาบึก']}
          />
          <Checkbox
            answerName="use_deduct_flood_damage_house_2"
            caption="ค่าซ่อมบ้าน (29 ส.ค. - 30 พ.ย. 62)"
            tooltip={[
              'บ้านได้รับความเสียหายจากอุทกภัย',
              'อยู่ในเขตภัยพิบัติจากพายุโพดุล พายุคาจิกิ และมรสุมตะวันตกเฉียงใต้',
            ]}
          />
          <Checkbox
            answerName="use_deduct_flood_damage_car_1"
            caption="ค่าซ่อมรถ (3 ม.ค. - 31 มี.ค. 62)"
            tooltip={['รถเสียหายจากอุทกภัยพายุปาบึก']}
          />
          <Checkbox
            answerName="use_deduct_flood_damage_car_2"
            caption="ค่าซ่อมรถ (29 ส.ค. - 30 พ.ย. 62)"
            tooltip={[
              'รถได้รับความเสียหายจากอุทกภัย',
              'อยู่ในเขตภัยพิบัติจากพายุโพดุล พายุคาจิกิ และมรสุมตะวันตกเฉียงใต้',
            ]}
          />
        </CustomAnswerGroup>
        <CustomAnswerGroup
          caption="ค่าลดหย่อนจากการซื้อสินค้า/บริการ/ที่อยู่อาศัย"
          spaceBetweenLine="16px">
          <Checkbox
            answerName="use_deduct_shopping"
            caption="ช้อปช่วยชาติ (1 ม.ค. - 16 ม.ค. 62)"
            tooltip={[
              'ยางรถที่ผลิตในประเทศ / หนังสือรวมถึง e-book / สินค้า OTOP',
            ]}
          />
          <Checkbox
            answerName="use_deduct_buy_sport_and_edu"
            caption="ค่าซื้อสินค้าการศึกษาและอุปกรณ์กีฬา (30 เม.ย. - 30 มิ.ย. 62)"
            tooltip={[
              'เครื่องเขียน/เครื่องแต่งกาย/อุปกรณ์กีฬา',
              'ไม่รวมอุปกรณ์อิเล็กทรอนิกส์',
            ]}
          />
          <Checkbox
            answerName="use_deduct_buy_otop"
            caption="ค่าซื้อสินค้า OTOP (30 เม.ย. - 30 มิ.ย. 62)"
          />
          <Checkbox
            answerName="use_deduct_buy_ebook"
            caption="ค่าซื้อหนังสือและ e-book"
          />
          <Checkbox
            answerName="use_deduct_travel_primary"
            caption="เที่ยวเมืองหลัก (30 เม.ย. - 30 มิ.ย. 62)"
            tooltip={['ค่าที่พัก/ค่าบริการนำเที่ยวและมัคคุเทศก์']}
          />
          <Checkbox
            answerName="use_deduct_travel_secondary"
            caption="เที่ยวเมืองรอง (30 เม.ย. - 30 มิ.ย. 62)"
            tooltip={['ค่าที่พัก/ค่าบริการนำเที่ยวและมัคคุเทศก์']}
          />
          <Checkbox
            answerName="use_deduct_homeloan_interest"
            caption="ดอกเบี้ยกู้ยืมเพื่อที่อยู่อาศัย"
            tooltip={[
              'ต้องอาศัยอยู่ในบ้านหลังนี้',
              'เจ้าหนี้ต้องเป็นสถาบันการเงินหรือผู้ที่กฎหมายอนุญาต',
              'ถ้ามีการกู้ร่วมต้องหารเฉลี่ยตามจำนวนผู้กู้',
            ]}
          />
          <Checkbox
            answerName="use_deduct_first_house"
            caption="สิทธิลดหย่อนบ้านหลังแรก"
            tooltip={['ต้องอาศัยอยู่ในบ้านหลังนี้']}
          />
          <Checkbox
            answerName="use_deduct_startup"
            caption="เงินลงทุนธุรกิจ startup"
            tooltip={['ต้องถือหุ้นไม่น้อยกว่า 2 ปีต่อเนื่องกัน']}
          />
        </CustomAnswerGroup>
      </Body>
      <Footer>
        <AddButton onClick={props.onAdd}>ปิด</AddButton>
      </Footer>
    </LayoutSpidusManager>
  </CustomModal>
)

const enhancer = compose()

export default enhancer(DeductSelector)
