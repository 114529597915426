export const FILTER_OPTIONS = [
  {label: 'ทั้งหมด', value: 'all'},
  // {label: 'ประกันตลอดชีพ', value: 'whole_life'},
  // {label: 'ประกันชั่วระยะเวลา', value: 'term'},
  {label: 'ประกันบำนาญ', value: 'annuity'},
  {label: 'ประกันออมทรัพย์', value: 'saving'},
  // {label: 'ประกันสุขภาพ', value: 'health'},
  // {label: 'ประกันรถยนต์', value: 'motor'},
]

export const getProductType = (type, subType) => {
  if (type === 'LifeInsurancePolicy') {
    switch (subType) {
      case 'whole_life':
        return 'ประกันตลอดชีพ'
      case 'term':
        return 'ประกันชั่วระยะเวลา'
      case 'annuity':
        return 'ประกันบำนาญ'
      case 'saving':
        return 'ประกันออมทรัพย์'
      default:
        break
    }
  }
  if (type === 'health') {
    return 'ประกันสุขภาพ'
  }
  if (type === 'motor') {
    return 'ประกันรถยนต์'
  }
}

export const getStatusText = status => {
  switch (status) {
    case 'leaded':
      return 'รอการติดต่อกลับ'
    case 'converted':
      return 'สำเร็จ'
    default:
      break
  }
}
