import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled, {css} from 'react-emotion'
import {
  reset,
  change,
  formValueSelector,
  FormSection,
  resetSection,
  Field as CustomField,
} from 'redux-form'
import {
  displayNumber,
  inRange,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import Field from '../../../../../share/Field'
import SubFormNoon from './SubFormNoon'
import SubFormNoonAdvance from './SubFormNoonAdvance'
import Panel from '../PanelForm'
import {responsive, breakpoints} from '../../../../../../core/style'
import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
  Summary,
  Label,
  LabelSummary,
} from '../ShareFieldForm'
import SearchFactor from '../SearchFactor'

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 85px;
  margin-bottom: 30px;
  height: 100px;
  .ant-radio-wrapper {
    margin-bottom: 35px;
  }
  ${props =>
    responsive.mobile(css`
      position: relative;
      flex-direction: column;
      height: unset;
      margin: 0;
    `)};
`

const ContainerTextRadio = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #6a94cb;
  margin-bottom: 30px;
`

const CustomText = styled.b`
  font-size: 20px;
`
const CustomTextFooter = styled(TextFooter)`
  width: unset;
  height: unset;
`

const Radios = styled(Field)`
  width: 320px;
  margin: 0;

  ${props =>
    responsive.mobile(css`
      width: 240px;
      .input-radios-container {
        .ant-radio-group {
          label {
            margin-bottom: 66px;
          }
        }
      }
    `)};
`
const TextInputRadio = styled(Field)`
  display: flex;
  align-items: center;
  width: 160px;
  margin: 0;

  .input-text-container {
    width: unset;
  }

  input {
    width: 160px;
    font-size: 16px;
  }

  ${props =>
    responsive.mobile(css`
      width: 100%;
      input {
        width: 240px;
        text-align: center;
        margin-bottom: 30px;
      }
    `)};
`

const RowRadioUnit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
`
const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .or {
    cursor: unset;
  }
`

const SubHeader = styled.div`
  color: ${props => props.theme.BLUE};
  font-size: 16px;
  font-weight: ${props => props.isSelectSubHeader && 'bold'};
  cursor: ${props => !props.isSelectSubHeader && 'pointer'};
  margin: 0 3px;
`

const SummaryAdvance = ({finalSummary}) => (
  <CustomText>{displayNumber(Math.round(finalSummary))} บาท</CustomText>
)
const moneyMustPrepared = ({summary}) => (
  <LabelSummary>{displayNumber(Math.round(summary))}</LabelSummary>
)

const Nothing = () => <div />

const ChildAdvanceForm = ({
  onSummary,
  totalList,
  firstSummary,
  secondSummary,
  thirdSummary,
  forthSummary,
  fiveSummary,
  sixSummary,
  onCheckSummary,
  summary,
  finalSummary,
  onFinalSummary,
  onAdvanceSummary,
  firstSummaryAdvance,
  secondSummaryAdvance,
  thirdSummaryAdvance,
  forthSummaryAdvance,
  fiveSummaryAdvance,
  sixSummaryAdvance,
  setSummary,
  setFinalSummary,
  change,
  isSelectSubHeader,
  resetSection,
  windowWidth,
  timeOut,
  initTimeOut,
}) => (
  <Panel>
    <div>
      <Header>สมมติฐาน</Header>
      <RadioContainer>
        <Radios
          name="radioChild"
          type="radios"
          options={[
            {
              label: `${
                windowWidth <= breakpoints.mobile
                  ? 'วางแผนว่าจะมีบุตรในอีก (ปี)'
                  : 'วางแผนว่าจะมีบุตรในอีก'
              }`,
              value: 'plan_child',
            },
            {
              label: `${
                windowWidth <= breakpoints.mobile
                  ? 'มีบุตรแล้ว อายุบุตรปัจจุบัน (ปี)'
                  : 'มีบุตรแล้ว อายุบุตรปัจจุบัน'
              }`,
              value: 'child',
            },
          ]}
          didChange={() => {
            isSelectSubHeader ? onSummary() : onAdvanceSummary()
            isSelectSubHeader && onCheckSummary()
            onFinalSummary()
          }}
        />
        <ContainerTextRadio>
          <RowRadioUnit>
            <TextInputRadio
              name="assumptionYearPlanChild"
              type="text"
              placeholder="0"
              normalize={inRange(0, 100)}
              parse={numberParser}
              didChange={() => {
                if (timeOut) clearTimeout(timeOut)
                initTimeOut(
                  setTimeout(() => {
                    isSelectSubHeader ? onSummary() : onAdvanceSummary()
                    isSelectSubHeader && onCheckSummary()
                    onFinalSummary()
                  }, 500),
                )
              }}
              disabled={totalList.radioChild !== 'plan_child'}
            />
            <Unit>ปี</Unit>
          </RowRadioUnit>
          <RowRadioUnit>
            <TextInputRadio
              name="assumptionYearChild"
              type="text"
              placeholder="0"
              normalize={inRange(0, 100)}
              parse={numberParser}
              didChange={() => {
                if (timeOut) clearTimeout(timeOut)
                initTimeOut(
                  setTimeout(() => {
                    isSelectSubHeader ? onSummary() : onAdvanceSummary()
                    isSelectSubHeader && onCheckSummary()
                    onFinalSummary()
                  }, 500),
                )
              }}
              disabled={totalList.radioChild === 'plan_child'}
            />
            <Unit>ปี</Unit>
          </RowRadioUnit>
        </ContainerTextRadio>
      </RadioContainer>
      <Divider />
      <Header>ระดับการศึกษาที่คาดหวัง</Header>
      <SubHeaderContainer>
        <SubHeader
          onClick={() => {
            change('Planning', 'isSelectSubHeader', true)
            setSummary(0)
            setFinalSummary(0)
            resetSection('Planning', 'custom')
          }}
          isSelectSubHeader={isSelectSubHeader}>
          NOON แนะนำ
        </SubHeader>
        <SubHeader className="or">|</SubHeader>
        <SubHeader
          onClick={() => {
            change('Planning', 'isSelectSubHeader', false)
            setSummary(0)
            setFinalSummary(0)
            resetSection('Planning', 'noon')
          }}
          isSelectSubHeader={!isSelectSubHeader}>
          คุณเลือกเอง
        </SubHeader>
      </SubHeaderContainer>
      {isSelectSubHeader ? (
        <FormSection name="noon">
          <SubFormNoon
            onSummary={onSummary}
            onFinalSummary={onFinalSummary}
            onCheckSummary={onCheckSummary}
            listSummary={[
              firstSummary,
              secondSummary,
              thirdSummary,
              forthSummary,
              fiveSummary,
              sixSummary,
            ]}
            totalList={totalList}
            windowWidth={windowWidth}
          />
        </FormSection>
      ) : (
        <FormSection name="custom">
          <SubFormNoonAdvance
            onAdvanceSummary={onAdvanceSummary}
            onFinalSummary={onFinalSummary}
            listSummary={[
              firstSummaryAdvance,
              secondSummaryAdvance,
              thirdSummaryAdvance,
              forthSummaryAdvance,
              fiveSummaryAdvance,
              sixSummaryAdvance,
            ]}
            change={change}
            totalList={totalList}
            windowWidth={windowWidth}
            initTimeOut={initTimeOut}
            timeOut={timeOut}
          />
        </FormSection>
      )}

      <Divider />
      <Header>เตรียมพร้อมค่าใช้จ่าย</Header>
      <FieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? `จำนวนเงินที่ต้องเตรียม (บาท)`
              : `จำนวนเงินที่ต้องเตรียม`}
          </Label>
          <CustomField
            name="moneyMustPreparedAdvance"
            component={moneyMustPrepared}
            summary={summary}
          />
        </Summary>
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="moneyPreparedAdvance"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินที่เตรียมไว้แล้ว (บาท)'
              : 'จำนวนเงินที่เตรียมไว้แล้ว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onFinalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <CustomTextFooter>
        <SearchFactor
          type="Child"
          windowWidth={windowWidth}
          searchValue={finalSummary}
        />
        <CustomField
          name="searchValue"
          component={SummaryAdvance}
          finalSummary={finalSummary}
        />
        <CustomField name="listSummaryNoon" component={Nothing} />
        <CustomField name="listSummaryCustom" component={Nothing} />
      </CustomTextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={finalSummary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(
        state,
        'radioChild',
        'assumptionYearPlanChild',
        'assumptionYearChild',
        'moneyPreparedAdvance',
        'noon.checkbox0',
        'noon.checkbox1',
        'noon.checkbox2',
        'noon.checkbox3',
        'noon.checkbox4',
        'noon.checkbox5',
        'noon.selector0',
        'noon.selector1',
        'noon.selector2',
        'noon.selector3',
        'noon.selector4',
        'noon.selector5',
        'custom.inflationRate',
        'custom.checkboxAdvance0',
        'custom.checkboxAdvance1',
        'custom.checkboxAdvance2',
        'custom.checkboxAdvance3',
        'custom.checkboxAdvance4',
        'custom.checkboxAdvance5',
        'custom.smallTextLeft0',
        'custom.smallTextLeft1',
        'custom.smallTextLeft2',
        'custom.smallTextLeft3',
        'custom.smallTextLeft4',
        'custom.smallTextLeft5',
        'custom.smallTextRight0',
        'custom.smallTextRight1',
        'custom.smallTextRight2',
        'custom.smallTextRight3',
        'custom.smallTextRight4',
        'custom.smallTextRight5',
        'custom.textAdvance0',
        'custom.textAdvance1',
        'custom.textAdvance2',
        'custom.textAdvance3',
        'custom.textAdvance4',
        'custom.textAdvance5',
      ),
      isSelectSubHeader: selector(state, 'isSelectSubHeader'),
    }),
    {reset, change, resetSection},
  ),
  withState('summary', 'setSummary', 0),
  withState('finalSummary', 'setFinalSummary', 0),
  withState('firstSummary', 'setFirstSummary', 0),
  withState('secondSummary', 'setSecondSummary', 0),
  withState('thirdSummary', 'setThirdSummary', 0),
  withState('forthSummary', 'setForthSummary', 0),
  withState('fiveSummary', 'setFiveSummary', 0),
  withState('sixSummary', 'setSixSummary', 0),
  withState('timeOut', 'initTimeOut', null),

  withState('firstSummaryAdvance', 'setFirstSummaryAdvance', 0),
  withState('secondSummaryAdvance', 'setSecondSummaryAdvance', 0),
  withState('thirdSummaryAdvance', 'setThirdSummaryAdvance', 0),
  withState('forthSummaryAdvance', 'setForthSummaryAdvance', 0),
  withState('fiveSummaryAdvance', 'setFiveSummaryAdvance', 0),
  withState('sixSummaryAdvance', 'setSixSummaryAdvance', 0),
  withHandlers({
    onSummary: ({
      totalList,
      setFirstSummary,
      setSecondSummary,
      setThirdSummary,
      setForthSummary,
      setFiveSummary,
      setSixSummary,
      change,
    }) => () => {
      const {
        assumptionYearPlanChild,
        assumptionYearChild,
        radioChild,
        noon: {
          selector0,
          selector1,
          selector2,
          selector3,
          selector4,
          selector5,
        },
      } = totalList

      let first = convertNaNtoZero(assumptionYearPlanChild)
      let second = convertNaNtoZero(assumptionYearChild)

      let sum0 = 0
      let sum1 = 0
      let sum2 = 0
      let sum3 = 0
      let sum4 = 0
      let sum5 = 0
      let i0 = 0
      let i1 = 0
      let i2 = 0
      let i3 = 0
      let i4 = 0
      let i5 = 0
      let cost0 = 0
      let cost1 = 0
      let cost2 = 0
      let cost3 = 0
      let cost4 = 0
      let cost5 = 0
      let func0 = 0
      let func1 = 0
      let func2 = 0
      let func3 = 0
      let func4 = 0
      let func5 = 0
      let count = 0

      const ArraySelector = [
        selector0,
        selector1,
        selector2,
        selector3,
        selector4,
        selector5,
      ]
      while (count < ArraySelector.length) {
        if (ArraySelector[count] === 'normal') {
          if (count === 0) cost0 = 50000
          if (count === 1) cost1 = 50000
          if (count === 2) cost2 = 60000
          if (count === 3) cost3 = 100000
          if (count === 4) cost4 = 200000
          if (count === 5) cost5 = 300000
        } else if (ArraySelector[count] === 'national') {
          if (count === 0) cost0 = 100000
          if (count === 1) cost1 = 100000
          if (count === 2) cost2 = 120000
          if (count === 3) cost3 = 200000
          if (count === 4) cost4 = 400000
          if (count === 5) cost5 = 500000
        } else {
          if (count === 0) cost0 = 400000
          if (count === 1) cost1 = 400000
          if (count === 2) cost2 = 480000
          if (count === 3) cost3 = 800000
          if (count === 4) cost4 = 1600000
          if (count === 5) cost5 = 1800000
        }
        count++
      }

      if (radioChild === 'plan_child') {
        func0 = first + 6
        func1 = first + 12
        func2 = first + 15
        func3 = first + 18
        func4 = first + 22
        func5 = first + 24
      } else {
        func0 = 6 - second
        func1 = 12 - second
        func2 = 15 - second
        func3 = 18 - second
        func4 = 22 - second
        func5 = 24 - second
      }

      while (i0 < 6) {
        sum0 += cost0 * Math.pow(1.04, func0 + i0)
        i0++
      }
      while (i1 < 3) {
        sum1 += cost1 * Math.pow(1.04, func1 + i1)
        i1++
      }
      while (i2 < 3) {
        sum2 += cost2 * Math.pow(1.04, func2 + i2)
        i2++
      }
      while (i3 < 4) {
        sum3 += cost3 * Math.pow(1.04, func3 + i3)
        i3++
      }
      while (i4 < 2) {
        sum4 += cost4 * Math.pow(1.04, func4 + i4)
        i4++
      }
      while (i5 < 2) {
        sum5 += cost5 * Math.pow(1.04, func5 + i5)
        i5++
      }

      setFirstSummary(sum0)
      setSecondSummary(sum1)
      setThirdSummary(sum2)
      setForthSummary(sum3)
      setFiveSummary(sum4)
      setSixSummary(sum5)

      change('Planning', 'listSummaryNoon', [
        convertNaNtoZero(Math.round(sum0)),
        convertNaNtoZero(Math.round(sum1)),
        convertNaNtoZero(Math.round(sum2)),
        convertNaNtoZero(Math.round(sum3)),
        convertNaNtoZero(Math.round(sum4)),
        convertNaNtoZero(Math.round(sum5)),
      ])
    },
    onCheckSummary: ({
      totalList,
      setSummary,
      firstSummary,
      secondSummary,
      thirdSummary,
      forthSummary,
      fiveSummary,
      sixSummary,
      change,
    }) => () => {
      const {
        noon: {
          checkbox0 = false,
          checkbox1 = false,
          checkbox2 = false,
          checkbox3 = false,
          checkbox4 = false,
          checkbox5 = false,
        },
      } = totalList
      let totalSum = 0
      if (checkbox0) totalSum += firstSummary
      if (checkbox1) totalSum += secondSummary
      if (checkbox2) totalSum += thirdSummary
      if (checkbox3) totalSum += forthSummary
      if (checkbox4) totalSum += fiveSummary
      if (checkbox5) totalSum += sixSummary

      setSummary(totalSum)
      change(
        'Planning',
        'moneyMustPreparedAdvance',
        convertNaNtoZero(Math.round(totalSum)),
      )
    },
    onFinalSummary: ({totalList, summary, setFinalSummary, change}) => () => {
      const {moneyPreparedAdvance} = totalList
      let totalSum = summary - convertNaNtoZero(moneyPreparedAdvance)
      setFinalSummary(totalSum)
      change('Planning', 'searchValue', convertNaNtoZero(Math.round(totalSum)))
    },
    onAdvanceSummary: ({
      totalList,
      setFirstSummaryAdvance,
      setSecondSummaryAdvance,
      setThirdSummaryAdvance,
      setForthSummaryAdvance,
      setFiveSummaryAdvance,
      setSixSummaryAdvance,
      setSummary,
      change,
    }) => () => {
      const {
        radioChild,
        assumptionYearPlanChild,
        assumptionYearChild,
        custom: {
          inflationRate,
          smallTextLeft0,
          smallTextLeft1,
          smallTextLeft2,
          smallTextLeft3,
          smallTextLeft4,
          smallTextLeft5,
          smallTextRight0,
          smallTextRight1,
          smallTextRight2,
          smallTextRight3,
          smallTextRight4,
          smallTextRight5,
          textAdvance0 = 0,
          textAdvance1 = 0,
          textAdvance2 = 0,
          textAdvance3 = 0,
          textAdvance4 = 0,
          textAdvance5 = 0,
          checkboxAdvance0 = false,
          checkboxAdvance1 = false,
          checkboxAdvance2 = false,
          checkboxAdvance3 = false,
          checkboxAdvance4 = false,
          checkboxAdvance5 = false,
        },
      } = totalList

      let first = convertNaNtoZero(assumptionYearPlanChild)
      let second = convertNaNtoZero(assumptionYearChild)
      let third = convertNaNtoZero(inflationRate)
      let sum0 = 0
      let sum1 = 0
      let sum2 = 0
      let sum3 = 0
      let sum4 = 0
      let sum5 = 0
      let i0 = 0
      let i1 = 0
      let i2 = 0
      let i3 = 0
      let i4 = 0
      let i5 = 0
      let cost0 = convertNaNtoZero(textAdvance0)
      let cost1 = convertNaNtoZero(textAdvance1)
      let cost2 = convertNaNtoZero(textAdvance2)
      let cost3 = convertNaNtoZero(textAdvance3)
      let cost4 = convertNaNtoZero(textAdvance4)
      let cost5 = convertNaNtoZero(textAdvance5)
      let func0 = 0
      let func1 = 0
      let func2 = 0
      let func3 = 0
      let func4 = 0
      let func5 = 0
      if (radioChild === 'plan_child') {
        func0 = first + convertNaNtoZero(smallTextLeft0)
        func1 = first + convertNaNtoZero(smallTextLeft1)
        func2 = first + convertNaNtoZero(smallTextLeft2)
        func3 = first + convertNaNtoZero(smallTextLeft3)
        func4 = first + convertNaNtoZero(smallTextLeft4)
        func5 = first + convertNaNtoZero(smallTextLeft5)
      } else {
        func0 = convertNaNtoZero(smallTextLeft0) - second
        func1 = convertNaNtoZero(smallTextLeft1) - second
        func2 = convertNaNtoZero(smallTextLeft2) - second
        func3 = convertNaNtoZero(smallTextLeft3) - second
        func4 = convertNaNtoZero(smallTextLeft4) - second
        func5 = convertNaNtoZero(smallTextLeft5) - second
      }

      while (i0 < smallTextRight0 - smallTextLeft0 + 1) {
        sum0 += cost0 * Math.pow(1 + third / 100, func0 + i0)
        i0++
      }
      while (i1 < smallTextRight1 - smallTextLeft1 + 1) {
        sum1 += cost1 * Math.pow(1 + third / 100, func1 + i1)
        i1++
      }
      while (i2 < smallTextRight2 - smallTextLeft2 + 1) {
        sum2 += cost2 * Math.pow(1 + third / 100, func2 + i2)
        i2++
      }
      while (i3 < smallTextRight3 - smallTextLeft3 + 1) {
        sum3 += cost3 * Math.pow(1 + third / 100, func3 + i3)
        i3++
      }
      while (i4 < smallTextRight4 - smallTextLeft4 + 1) {
        sum4 += cost4 * Math.pow(1 + third / 100, func4 + i4)
        i4++
      }
      while (i5 < smallTextRight5 - smallTextLeft5 + 1) {
        sum5 += cost5 * Math.pow(1 + third / 100, func5 + i5)
        i5++
      }

      setFirstSummaryAdvance(sum0)
      setSecondSummaryAdvance(sum1)
      setThirdSummaryAdvance(sum2)
      setForthSummaryAdvance(sum3)
      setFiveSummaryAdvance(sum4)
      setSixSummaryAdvance(sum5)

      change('Planning', 'listSummaryCustom', [
        convertNaNtoZero(Math.round(sum0)),
        convertNaNtoZero(Math.round(sum1)),
        convertNaNtoZero(Math.round(sum2)),
        convertNaNtoZero(Math.round(sum3)),
        convertNaNtoZero(Math.round(sum4)),
        convertNaNtoZero(Math.round(sum5)),
      ])

      let totalSum = 0
      if (checkboxAdvance0) totalSum += sum0
      if (checkboxAdvance1) totalSum += sum1
      if (checkboxAdvance2) totalSum += sum2
      if (checkboxAdvance3) totalSum += sum3
      if (checkboxAdvance4) totalSum += sum4
      if (checkboxAdvance5) totalSum += sum5

      setSummary(totalSum)
      change(
        'Planning',
        'moneyMustPreparedAdvance',
        convertNaNtoZero(Math.round(totalSum)),
      )
    },
  }),
  lifecycle({
    async componentDidMount() {
      try {
        if (this.props.isSelectSubHeader) {
          await this.props.onSummary()
          await this.props.onCheckSummary()
          await this.props.onFinalSummary()
        } else {
          await this.props.onSummary()
          await this.props.onAdvanceSummary()
          await this.props.onFinalSummary()
        }
      } catch (error) {
        console.error('error', error)
      }
    },
  }),
)

export default enhancer(ChildAdvanceForm)
