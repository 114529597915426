import React from 'react'
import styled from 'react-emotion'
import {compose, withState, withProps, withHandlers} from 'recompose'
import {Button, Checkbox} from '../../components'
import Modal from '../../../share/Modal'

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 55px 30px;
  align-items: center;

  @media screen and (max-width: 350px) {
    padding: 30px 20px 55px 20px;
  }
`
export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: Prompt;
  color: #333333;
`
export const Body = styled.div`
  margin-top: 28px;
  font-size: 14px;
  color: #333333;

  * {
    font-family: 'Sarabun', sans-serif !important;
  }
`
export const SendButton = styled(Button)`
  margin-top: 25px;
  max-width: 199px;
  height: 38px;
  background: #ffb933;
  border-radius: 19px;
  min-width: 200px;
  line-height: 1;
  color: white;
  font-size: 16px;

  &:hover,
  &:focus {
    color: #ffffff !important;
  }
`

const AgreeCheckbox = styled(Checkbox.Circle)`
  margin-top: 30px;

  label {
    font-family: 'Prompt', sans-serif !important;
  }
`

const TermAndCondition = props => (
  <Modal isShowModal={props.isShowModal} setIsShowModal={props.setIsShowModal}>
    <Layout>
      <Title>ข้อตกลงและเงื่อนไข</Title>
      <Body>
        <div>
          <div>
            การใช้บริการเตรียมข้อมูลสำหรับยื่นภาษีผ่าน Noon
            ผู้ใช้งานต้องยอมรับข้อตกลงและเงื่อนไขการใช้บริการดังต่อไปนี้
          </div>
          <div>
            <u>
              (Noon
              จะไม่ส่งข้อมูลให้กรมสรรพากรจนกว่าคุณจะกดยอมรับข้อตกลงและเงื่อนไขการใช้งาน)
            </u>
          </div>
          <br />
          <div>
            1. ผู้ใช้งานยอมรับว่า Noon
            เป็นเพียงผู้เตรียมข้อมูลสำหรับการยื่นภาษีให้ผู้ใช้งาน Noon
            ไม่สามารถยืนยันความถูกต้องของข้อมูลที่ผู้ใช้งานกรอกเองได้
            ผู้ใช้งานต้องตรวจสอบความถูกต้องของข้อมูลด้วยตนเองทุกครั้งก่อนยื่นภาษี
          </div>
          <br />
          <div>
            2.
            ผู้ใช้งานต้องลงทะเบียนใช้งานระบบการยื่นภาษีผ่านระบบอิเล็กทรอนิกส์ของกรมสรรพากร
            (RD E-Filing)
            และยอมรับข้อตกลงการยื่นภาษีและเงื่อนไขการใช้บริการของแอป RD Smart
            Tax ด้วย
          </div>
          <br />
          <div>
            3. ผู้ใช้งานยินยอมให้ Noon เปิดเผยข้อมูลที่กรอกไว้ในระบบ Noon
            ให้เข้าสู่ระบบกรมสรรพากรเพื่อประโยชน์สำหรับการยื่นภาษีผ่านระบบของกรมสรรพากร
          </div>
          <br />
          <div>
            4.
            ผู้ใช้งานยอมรับว่าข้อมูลที่ปรากฏเมื่อยื่นภาษีจริงบนระบบของกรมสรรพากรอาจแตกต่างจากที่ปรากฏอยู่บนระบบ
            Noon ซึ่งอาจเกิดจากการตรวจสอบเงื่อนไขการคำนวณภาษีของระบบกรมสรรพากร
            อาทิ การตรวจสอบอายุบิดามารดาจากทะเบียนราษฏร์ กรมการปกครอง
            กระทรวงมหาดไทย รวมถึงข้อมูลอื่นๆ
            ที่ปรากฏอยู่ในเครือข่ายฐานข้อมูลของกรมสรรพากร
          </div>
          <br />
          <div>
            5. ผู้ใช้งานยอมรับการใช้งานบนระบบ Noon
            ยังไม่ใช่การยื่นภาษีที่สมบูรณ์ตามกฎหมาย ผู้ใช้งานยังต้องนำตัวเลข 6
            หลักที่แสดงบนระบบ Noon ไปยืนยันการยื่นภาษีด้วยตนเองบนแอป RD Smart
            Tax และดำเนินการตามกระบวนการในแอป RD Smart Tax
            จึงจะเป็นการยื่นภาษีที่สมบูรณ์ตามกฎหมาย
          </div>
          <br />
          <div>
            6. ในกรณีที่เกิดเหตุขัดข้อง หรือเหตุสุดวิสัย
            จนไม่สามารถใช้บริการเตรียมข้อมูลสำหรับยื่นภาษีผ่าน Noon ได้
            ผู้ใช้งานตกลงที่จะดำเนินการยื่นภาษีด้วยวิธีอื่นตามช่องทางที่กรมสรรพากรกำหนดด้วยตนเองต่อไป
          </div>
          <br />
          <div>
            7. Noon
            ขอสงวนสิทธิ์ในการระงับการให้บริการเตรียมข้อมูลสำหรับยื่นภาษีผ่าน
            Noon
            นี้ให้แก่ผู้ใช้งานรายใดรายหนึ่งหรือทุกรายเมื่อพ้นกำหนดการยื่นภาษี
            หรือด้วยเหตุจำเป็นอื่นใดตามที่ Noon
            กำหนดโดยไม่ได้แจ้งให้ทราบล่วงหน้า
          </div>
        </div>
      </Body>
      <AgreeCheckbox onChange={props.setAgree} style={{margin: '0 auto'}}>
        ยอมรับข้อตกลงและเงื่อนไข
      </AgreeCheckbox>
      <SendButton onClick={props.onConfirm} disabled={!props.agree}>
        ยืนยัน
      </SendButton>
    </Layout>
  </Modal>
)

const enhancer = compose(
  withState('agree', 'setAgree', false),
  withHandlers({
    onAgreeChange: props => value => {
      if (value) {
        dataLayer.push({event: 'accept_terms_and_conditions'})
      } else {
        dataLayer.push({event: 'unaccept_terms_and_conditions'})
      }

      props.setAgree(value)
    },
  }),
)

export default enhancer(TermAndCondition)
