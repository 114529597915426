import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import Button from '../share/Button'
import oic from '../../static/images/OIC-01.svg'
import thaiFintech from '../../static/images/Thai Association Fintech Logo.png'
import nia from '../../static/images/nia_logo_color_png.png'
import fintechLogo from '../../static/images/Fintech Logo.svg'
import AppearanceComponent from '../share/AppearanceComponent'
import {compose, withState, lifecycle} from 'recompose'
import {navigate} from '@reach/router'
import {getFreeCount} from '../../ducks/landing'
import {responsive, breakpoints} from '../../core/style'
import {Carousel} from 'antd'
import windowSize from '../../core/react-window-size'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 70px;
  ${props =>
    responsive.mobile(css`
      background-color: white;
      margin-top: 0;
      padding-top: 40px;
    `)};
`
const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    responsive.mobile(css`
      max-width: 320px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    `)};
`
const CustomCarousel = styled(Carousel)`
  width: 100vw;
  height: 80px;
  ${props =>
    responsive.mobile(css`
      height: 130px;
    `)};
`

const Topic = styled.div`
  font-size: 20px;
  margin-right: 120px;
  font-family: 'Promp';
  font-weight: 700;
  color: #333;
  line-height: 30px;
  ${props =>
    responsive.mobile(css`
      margin: 0;
      padding-top: 40px;
      text-align: left;
    `)};
`
const InfoTopic = styled.div`
  font-size: 12px;
  width: 152px;
  margin-bottom: 10px;
`
const Oic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  width: 100%;
  height: 80px;
  background-color: #e5e5e5;
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;
      justify-content: space-between;
      padding: 25px 0;
      height: 130px;
    `)};
`
const FreeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: rgba(255, 255, 255, 0.8);

  ${props =>
    responsive.mobile(css`
      background-color: rgba(229, 229, 229, 0.2);

      flex-direction: column;
      height: unset;
    `)};
`
const CustomButton = styled(Button)`
  width: 80px;
  height: 30px;
  margin-bottom: 12px;

  ${props =>
    responsive.mobile(css`
      margin-top: 15px;
    `)};
`
const CountFree = styled.div`
  width: ${props => (props.isFree ? props.countFree / 10 : 0)}%;
  height: 5px;
  background-color: ${props => props.theme.ORANGE};
  border-radius: 20px;
  transition: width 3s;
`
const CountFreeRemain = styled(CountFree)`
  border-radius: 0 20px 20px 0;
  opacity: 0.3;
`
const ContainerCountFree = styled.div`
  display: flex;
  flex-direction: row;
  width: 650px;
  background-color: rgba(255, 185, 51, 0.1);
  border-radius: 5px;
  ${props =>
    responsive.mobile(css`
      width: 280px;
    `)};
`
const OicImg = styled.img`
  width: 170px;
  margin-left: 30px;
  height: 90px;
  ${props =>
    responsive.mobile(css`
      margin: 0;
    `)};
`
const ThaiFintechImg = styled(OicImg)`
  height: 40px;
  width: 165px;
`
const NiaImg = styled(OicImg)`
  height: 40px;
  width: 87px;
`
const FintechImg = styled(OicImg)`
  height: 70px;
  width: fit-content;
  margin-left: 0;
`

const OicSandbox = styled.div``

const Free = ({isFree, setIsFree, countFree, windowWidth}) => (
  <React.Fragment>
    {/* <Container>
      <FreeContainer>
        <TopicContainer>
          <Topic>
            ฟรี! {windowWidth <= breakpoints.mobile && <br />}
            สรุปแบบประกันชีวิตและสุขภาพปี 2019 สำหรับคุณโดยเฉพาะ
          </Topic>

          <InfoTopic>เพียงลงทะเบียนบนเว็บไซต์เรา</InfoTopic>
          <ContainerCountFree>
            <AppearanceComponent
              componentDidAppear={() => setIsFree(true)}
              componentDidDisappear={() => setIsFree(false)}>
              <CountFree isFree={isFree} countFree={countFree} />
            </AppearanceComponent>
            <CountFreeRemain />
          </ContainerCountFree>
        </TopicContainer>
        <CustomButton
          onClick={() => navigate(`/free-form`)}
          icons={['fas', 'pen']}>
          ลงทะเบียน
        </CustomButton>
      </FreeContainer>
    </Container> */}
    <CustomCarousel autoplay dots={false} autoplaySpeed={2000}>
      <div>
        <Oic>
          <OicSandbox>ภายใต้การดูแลของ Insurance Regulatory Sandbox</OicSandbox>
          <OicImg src={oic} />
        </Oic>
      </div>
      <div>
        <Oic>
          <OicSandbox>สนับสนุน โดยสมาคมฟินเทคประเทศไทย</OicSandbox>
          <ThaiFintechImg src={thaiFintech} />
        </Oic>
      </div>
      <div>
        <Oic>
          <OicSandbox>สนับสนุนโดย สำนักงานนวัตกรรมแห่งชาติ</OicSandbox>
          <NiaImg src={nia} />
        </Oic>
      </div>
      <div>
        <Oic>
          <OicSandbox>ได้รับรางวัล Rising Star FinTech 2017</OicSandbox>
          <FintechImg src={fintechLogo} />
        </Oic>
      </div>
    </CustomCarousel>
  </React.Fragment>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      countFree: state.landing.countFree,
    }),
    {getFreeCount},
  ),
  withState('isFree', 'setIsFree', false),
  lifecycle({
    componentDidMount() {
      const {getFreeCount} = this.props
      getFreeCount()
    },
  }),
)
export default windowSize(enhancer(Free))
