import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import {compose, withProps, lifecycle, withState, withHandlers} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  GoBack,
  SelectionSliderBar,
  InsuranceType,
  TopicType,
  DescriptionType,
  Budget,
  CustomCurrency,
} from '../annuity/index'
import TooltipBudgetModal from '../annuity/TooltipBudgetModal'
import shield from '../../../../../static/images/security-on.svg'
import * as path from '../../../common/path'
import {NormalSlider} from '../../../components/index'
import {withAnswers} from '../../../enhances'
import defaultLogo from '../../../../../static/images/default-company-logo.png'
import itaxApi from '../../../common/api'
import {toCurrency} from '../../../core/helper'
import numeral from 'numeral'
import {spidusStore} from '../../../core/spidus'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`
const Card = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 10px;
  min-height: 400px;
  border: 1px solid ${props => (props.suggest ? '#9FD134' : '#C1C1C1')};
  border-radius: 5px;
  padding: 30px 15px;
  margin-top: ${props => props.suggest && '0'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333;
  font-family: 'Prompt', 'Roboto', sans-serif;
  cursor: pointer;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Mid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
`
const Footer = styled.div``
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 2;
`
const Label = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`
const Value = styled.div`
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceValue = styled.div`
  font-size: 42px;
  color: #265fa7;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const SmallText = styled.div`
  font-size: 14px;
  color: #6a94cb;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceText = styled.div`
  color: #6a94cb;
  font-size: 16px;
  font-weight: 700;
`
const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Symbol = styled.div`
  font-size: 12px;
`
const Img = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 5px;
`
const Shield = styled.img`
  width: 50px;
  height: 50px;
`
const WrapText = styled.div`
  padding: 0 10px;
  flex: 1;
`
const MoneySymbol = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #265fa7;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 3px;
  margin-right: 5px;
`
const Wrap = styled.div`
  position: relative;
`
const Point = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  font-family: 'Prompt', 'Roboto', sans-serif;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 20px;
  left: 0;
`
const SuggestSign = styled.div`
  border: 1px solid #9fd134;
  border-bottom: 0;
  padding: 5px 10px;
  background: #9fd134;
  border-radius: 5px 5px 0 0;
  color: white;
  width: 73px;
  margin: 0 35px;
  text-align: center;
`
const WrapSign = styled.div`
  width: 100%;
  max-width: 330px;
`
const Saving = props => {
  const {productList = []} = props
  return (
    <Layout>
      <GoBack onClick={() => props.history.push(path.planningPath())}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        ประกันออมทรัพย์
      </GoBack>

      <SelectionSliderBar>
        <InsuranceType>
          <FontAwesomeIcon icon={['fas', 'coins']} />
          <TopicType>ประกันออมทรัพย์</TopicType>
          <DescriptionType>
            ลดภาษีได้สูงสุด{' '}
            {toCurrency(props.maxSavingInsuranceReducedTax, {prefix: ''})} บาท
            เมื่อซื้อประกันออมทรัพย์เป็นเงิน{' '}
            {toCurrency(props.maxSavingInsuranceInvest, {prefix: ''})} บาท
            ได้เงินออมที่แน่นอน พร้อมได้ความคุ้มชีวิต
          </DescriptionType>
        </InsuranceType>

        <Budget onClick={props.onTooltipBudget}>
          งบประมาณที่จะใช้ซื้อประกัน
          <FontAwesomeIcon
            onClick={props.tooltipReduceTax}
            icon={['fas', 'info-circle']}
          />
        </Budget>

        <CustomCurrency
          answerName="planning_deduct_life_insurance"
          withPrefix
          onAfterBlur={props.fetchProductList}
        />
        <NormalSlider
          answerName="planning_deduct_life_insurance"
          onAfterBlur={props.fetchProductList}
        />
      </SelectionSliderBar>

      {productList.map((product, index) => {
        if (index < 5) {
          const irr = product.irr || 0
          const protectionPeriod = product.protectionPeriod || 0
          const yieldToMaturity = product.yieldToMaturity || 0
          const sumInsured = product.sumInsured || 0
          const totalBenefit =
            ((irr / 100) * protectionPeriod +
              parseFloat(yieldToMaturity) / 100) *
              sumInsured || 0
          const payPeriod =
            product.paidPremiumType === 'period'
              ? `จ่าย ${toCurrency(product.payPeriod, {prefix: ''})} ปี`
              : `จ่ายถึงอายุ ${toCurrency(
                  product.payPeriod
                    ? product.payPeriod
                    : product.minAgeAnnuity - 1,
                  {prefix: ''},
                )} ปี`
          return (
            <Fragment key={`Annuity-${index}`}>
              {index === 0 && (
                <WrapSign>
                  <SuggestSign>แนะนำ</SuggestSign>
                </WrapSign>
              )}
              <Card
                key={`saving-${index}`}
                suggest={index === 0}
                onClick={() =>
                  props.history.push({
                    pathname: path.productDetailPath('saving', product.id),
                    state: {suggest: index === 0},
                  })
                }>
                <Top>
                  <Img src={product.companyOfficialLogoUrl || defaultLogo} />
                  <WrapText>
                    <Name>{product.name}</Name>
                    <Symbol>{product.companyName}</Symbol>
                  </WrapText>
                  <Wrap>
                    <Shield src={shield} />
                    <Point>{numeral(product.score).format('0[.]0')}</Point>
                  </Wrap>
                </Top>
                <Mid>
                  <InsuranceText>
                    เงินที่ต้องจ่ายต่อปี (เบี้ยประกัน)
                  </InsuranceText>
                  <InsuranceValue>
                    <MoneySymbol>฿ </MoneySymbol>
                    {toCurrency(product.premium, {prefix: ''})}
                  </InsuranceValue>
                  <SmallText>{payPeriod}</SmallText>
                </Mid>
                <Footer>
                  <Row>
                    <Label>
                      <Icon icon={['fas', 'shield-alt']} />
                      ได้เงินคืนทั้งหมด
                    </Label>
                    <MoneySymbol>
                      {toCurrency(totalBenefit, {prefix: '฿'})}
                    </MoneySymbol>
                  </Row>
                  <Row>
                    <Label>
                      <Icon icon={['fas', 'undo-alt']} />
                      ปันผลเฉลี่ยปีละ
                    </Label>
                    <Value>
                      {toCurrency(product.sumInsured * (product.irr / 100), {
                        prefix: '฿',
                      })}
                    </Value>
                  </Row>
                  <Row>
                    <Label>
                      <Icon icon={['fas', 'coins']} />
                      รับเงินก้อนเมื่อครบสัญญา
                    </Label>
                    {toCurrency(product.yieldToMaturityAmount, {prefix: '฿'})}
                  </Row>
                  <Row>
                    <Label>
                      <Icon icon={['fas', 'hand-holding-usd']} />
                      ได้รับเงินเมื่อเสียชีวิต
                    </Label>
                    เริ่มที่ {toCurrency(product.sumInsured, {prefix: '฿'})}
                  </Row>
                  <Row>
                    <Label>
                      <Icon icon={['fas', 'calculator']} />
                      ลดภาษีปีนี้ได้
                    </Label>
                    <Value>{toCurrency(product.premium, {prefix: '฿'})}</Value>
                  </Row>
                </Footer>
              </Card>
            </Fragment>
          )
        }
      })}

      <TooltipBudgetModal
        isShowModal={props.tooltipBudget}
        setIsShowModal={props.setTooltipBudget}
      />
    </Layout>
  )
}

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    age: 'age.value',
    gender: 'gender.value',
    additoinalInsuranceLife: 'planning_deduct_life_insurance',
    maxSavingInsuranceInvest: 'summary_data.value.maxSavingInsuranceInvest',
    maxSavingInsuranceReducedTax:
      'summary_data.value.maxSavingInsuranceReducedTax',
  }),
  withState('productList', 'setProductList', []),
  withState('tooltipBudget', 'setTooltipBudget', false),
  withHandlers({
    onTooltipBudget: props => () => {
      props.setTooltipBudget(true)
    },
    fetchProductList: props => async () => {
      const response = await itaxApi.get(
        '/partner/api/v1/life_insurance_policies/top',
        {
          premium: props.additoinalInsuranceLife
            ? props.additoinalInsuranceLife.value
            : 0,
          age: props.age,
          gender: props.gender,
          insurance_type: 'saving',
          weight_type: 1,
        },
      )
      props.setProductList(response.data.data.lifeInsurancePolicies)
    },
  }),
  lifecycle({
    async componentDidMount() {
      const names = ['planning_deduct_life_insurance']
      // await spidusStore.loadAnswers('noontax_config_web_tax_planning', ...names)
      this.props.fetchProductList()
    },
  }),
)

export default enhancer(Saving)
