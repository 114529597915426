import {Axios} from 'axios'
import {camelizeKeys, decamelizeKeys, decamelize} from 'humps'
import jsonToFormData from './jsonToFormData'

const setAppError = (e, message) => {
  console.log(message, e)
  return Promise.reject({...e, message})
}

class BaseApi extends Axios {
  constructor(config) {
    super(config)

    this.interceptors.request.use(
      async config => ({
        ...config,
        headers: this.prepareRequestHeaders(config.headers),
        params: this.prepareRequestParams(config.params, config.isFormData),
        data: this.prepareRequestParams(config.data, config.isFormData),
      }),
      null,
    )

    this.interceptors.response.use(
      response => this.handleSuccess(response),
      error => this.handleError(error),
    )
  }

  prepareRequestHeaders(headers) {
    return {...headers, 'X-Auth-Token': localStorage.getItem('authToken')}
  }
  prepareRequestParams = (params, isFormData) => {
    if (!params) return
    if (isFormData)
      return jsonToFormData(params, {transfromPropName: key => decamelize(key)})
    return decamelizeKeys(params)
  }
  prepareResponseData(data) {
    return camelizeKeys(data)
  }
  handleSuccess(response) {
    return {...response, data: this.prepareResponseData(response.data)}
  }
  onTokenExpired(e) {
    return setAppError(e, 'Token Expired.')
  }
  onUnauthorized(e) {
    return setAppError(e, 'Unauthorized.')
  }
  onServerError(e) {
    return setAppError(e, 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
  }
  onRequestTimeout(e) {
    return setAppError(e, 'INTERNET ล่าช้า กรุณาลองใหม่อีกครั้ง')
  }
  onNetworkError(e) {
    return setAppError(
      e,
      'การเชื่อมต่อกับเซิฟเวอร์มีปัญหา กรุณาลองใหม่อีกครั้งภายหลัง',
    )
  }
  handleError(e) {
    const {response} = e

    if (response) {
      const {code, message} = response.data
      if (code === 'token_expired') return this.onTokenExpired(e)
      if (code === 'unauthorized' && message === 'Unauthorized.')
        return this.onUnauthorized(e)
      if (code === 'server_error') return this.onServerError(e)

      const error = {...e, response: this.prepareResponseData(response)}
      return Promise.reject(error)
    } else {
      if (/timeout/.test(e.message)) return this.onRequestTimeout(e)
      if (/Network/.test(e.message)) return this.onNetworkError(e)

      return Promise.reject(e)
    }
  }

  //
  // Override method interfaces
  //
  get(path, params, options = {}) {
    return super.get(path, {params}, options)
  }
  delete(path, params, options = {}) {
    return super.delete(path, {params}, options)
  }
}

const baseURL = `${process.env.REACT_APP_FUNDEE_API_HOST}/api/fundee/v1`
const fundeeApi = new BaseApi({baseURL})

export default fundeeApi
