import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, lifecycle, withState} from 'recompose'
import {withRouter} from '../../enhances'
import * as paths from '../../common/paths'
import {spidusStore} from '../../core/spidus'

const Layout = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 464px;
  height: 55px;

  @media screen and (max-width: 700px) {
    width: 150px;
    height: 16px;

    > div:not(:first-child) {
      > div:nth-child(1) {
      }
      > div:nth-child(2) {
        display: none;
      }
      > div:nth-child(3) {
        display: none;
      }
    }
  }
`

const Line = styled.div`
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 9px;
`

const ProgressLine = styled(Line)`
  background: #ffb933;
  right: ${props => 100 - props.progress}%;
`

const StepLayout = styled.div`
  position: relative;

  > :nth-child(1) {
    background: ${props => (props.checked ? '#ffb933' : '#ffffff')};
  }
  > :nth-child(2) {
    color: ${props => (props.checked ? '#ffffff' : '#ffb933')};
  }
  > :nth-child(3) {
  }

  cursor: pointer;

  :hover {
    > :nth-child(1) {
      background: ${props => (props.checked ? '#e79a07' : '#e5e5e5')};
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;

      > :nth-child(1) {
        background: #ffffff;
        border: 1px solid #e5e5e5;
      }
      > :nth-child(2) {
        color: #e5e5e5;
      }
      > :nth-child(3) {
      }
    `}
`

const Dot = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #ffb933;
  z-index: 1;
`

const Number = styled.div`
  position: absolute;
  left: 6px;
  top: 0px;
  font-size: 13px;
  font-weight: bold;
  font-family: Prompt;
  color: #ffffff;
  z-index: 1;
`

const Caption = styled.div`
  position: absolute;
  white-space: nowrap;
  overflow: visible;
  top: 30px;
  font-size: 16px;
  font-family: Prompt;
  color: #333333;
`

const State = props => (
  <Layout>
    <Line />
    <ProgressLine progress={(Math.max(props.maxStep - 1, 0) / 2.0) * 100} />
    <StepLayout
      checked={props.step >= 1}
      onClick={props.gotoStep1}
      disabled={1 > props.maxStep}>
      <Dot />
      <Number style={{left: 6.5}}>1</Number>
      <Caption style={{left: -80}}>กรอกข้อมูลรายได้-ลดหย่อน</Caption>
    </StepLayout>
    <StepLayout
      checked={props.step >= 2}
      onClick={props.gotoStep2}
      disabled={2 > props.maxStep}>
      <Dot />
      <Number>2</Number>
      <Caption style={{left: -30}}>คำนวณภาษี</Caption>
    </StepLayout>
    <StepLayout
      checked={props.step >= 3}
      onClick={props.gotoStep3}
      disabled={3 > props.maxStep}>
      <Dot />
      <Number>3</Number>
      <Caption style={{left: -93}}>กรอกข้อมูลเพิ่มเติมเพื่อยื่นภาษี</Caption>
    </StepLayout>
  </Layout>
)

export const getMaxStep = () => {
  const MAX_STEP_KEY = `NOON-TAX-MAX-STEP-FOR-${spidusStore.getSessionId()}`
  const maxStep = localStorage.getItem(MAX_STEP_KEY)
  return parseInt(maxStep) || 0
}

const enhancer = compose(
  withRouter(),
  withState('maxStep', 'setMaxStep', 0),
  withHandlers({
    updateMaxStep: props => () => {
      const MAX_STEP_KEY = `NOON-TAX-MAX-STEP-FOR-${spidusStore.getSessionId()}`
      const maxStep = Math.max(localStorage.getItem(MAX_STEP_KEY), props.step)

      localStorage.setItem(MAX_STEP_KEY, maxStep)
      props.setMaxStep(maxStep)
    },
    gotoStep1: props => () => {
      dataLayer.push({event: 'goto_personal_income_deduct_page'})
      props.history.push(paths.prepareTaxStep1Path())
    },
    gotoStep2: props => () => {
      dataLayer.push({event: 'goto_personal_tax_summary_page'})
      props.history.push(paths.prepareTaxStep2Path())
    },
    gotoStep3: props => () => {
      dataLayer.push({event: 'goto_personal_tax_filing_require_data_page'})
      props.history.push(paths.prepareTaxStep3Path())
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateMaxStep()
    },
  }),
)

export default enhancer(State)
