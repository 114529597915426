import React from 'react'
import styled, {css} from 'react-emotion'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import FooterMenuList from './FooterMenuList'
import windowSize from '../../core/react-window-size'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'
import {responsive} from '../../core/style'

const Container = styled.footer`
  display: ${props => (props.isNoFooter ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #093771;
  color: white;
  padding: 0 0 36px;

  ${props =>
    responsive.tablet(css`
      padding: 0;
    `)};
`

const CopyrightPart = styled.div`
  margin-top: 35px;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  max-width: 915px;
  justify-content: space-between;
  align-items: center;

  ${props =>
    responsive.tablet(css`
      margin-top: 5px;
      padding: 0 25px;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: column-reverse;
      min-height: 159px;
      justify-content: center;
    `)};
`

const CopyrightContent = styled.div`
  width: 400px;
  font-size: 12px;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      margin-top: 30px;
      max-width: 280px;
      padding: 0 20px;
      text-align: center;
    `)};
`

const IconPart = styled.div``

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      font-size: 30px;
    `)};
`

const Footer = ({windowWidth, isNoFooter}) => (
  <Container id="footer" isNoFooter={isNoFooter}>
    <FooterMenuList />
    <CopyrightPart>
      {windowWidth <= 425 ? (
        <CopyrightContent onClick={() => navigate('/policy')}>
          COPYRIGHT © 2020 ALL RIGHTS RESERVED <br />
          นโยบายและเงื่อนไขการใช้งาน <br />
          EMPOWERED BY TQLD
        </CopyrightContent>
      ) : (
        <CopyrightContent onClick={() => navigate('/policy')}>
          COPYRIGHT © 2020 ALL RIGHTS RESERVED | นโยบายและเงื่อนไขการใช้งาน
          EMPOWERED BY TQLD
        </CopyrightContent>
      )}
      <IconPart>
        <CustomIcon
          icon={['fab', 'facebook']}
          onClick={() =>
            window.open('https://www.facebook.com/Nooninsure/', '_blank')
          }
        />
        <CustomIcon
          icon={['fab', 'twitter']}
          onClick={() => window.open('https://twitter.com/nooninth', '_blank')}
        />
        <CustomIcon
          icon={['fab', 'youtube']}
          onClick={() =>
            window.open(
              'https://www.youtube.com/channel/UC27zHa7O3PaHOBOb34QHGwQ',
              '_blank',
            )
          }
        />
      </IconPart>
    </CopyrightPart>
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isNoFooter: state.notFound.isNoFooter,
    }),
    {},
  ),
)

export default windowSize(enhancer(Footer))
