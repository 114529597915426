import React, {Fragment} from 'react'
import {compose, withProps, withState} from 'recompose'

import {displayNumber, obj2Url} from '../../../../core/helper'
import ModalFavorite from '../../../share/ModalFavorite'
import ModalCatalogue from '../ModalCatalogue'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Tooltip from '../../../share/Tooltip'

import {
  Layout,
  ActionWrapper,
  HeartIcon,
  DescriptionWrapper,
  Body,
  Title,
  Logo,
  CompanyLogo,
  WrapperTitle,
  ProductName,
  CompanyName,
  RowInfo,
  Content,
  ContentRegular,
  IconCheckCircle,
  IconTimesCircle,
  Premium,
  Footer,
  CustomTagA,
  CustomButton,
} from './Life'
import styled from 'react-emotion'

const TagRider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #265fa7;
  max-width: 114px;
  height: 25px;
  border-radius: 5px;
`
const TextRiderLifeInsuran = styled.div`
  color: white;
  font-size: 12px;
`
const CustomTitle = styled(Title)`
  margin-bottom: 10px;
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: #7f7f7f;
  margin-left: 1px;
`
const CustomTooltip = styled(Tooltip)`
  .ant-tooltip-arrow {
    right: 3px;
  }
`

const Rider = props => (
  <Layout>
    {props.tag ? <Fragment>{props.tag}</Fragment> : null}

    <ActionWrapper>
      {props.SpecialActionComponent ? (
        <props.SpecialActionComponent {...props} />
      ) : (
        <div />
      )}

      {/* <HeartIcon
        onClick={() => {
          props.isLogin
            ? props.onFavorite(
                !props.localFavorite,
                props.data.id,
                'life',
                props.data.paramsEncode,
              )
            : props.setIsShowModalFavorite(!props.isShowModalFavorite)
        }}
        icon={props.localFavorite ? ['fas', 'heart'] : ['far', 'heart']}
      /> */}
    </ActionWrapper>

    <DescriptionWrapper>
      <Body>
        <CustomTitle>
          <Logo>
            <CompanyLogo src={props.data.companyOfficialLogoUrl} />
          </Logo>
          <WrapperTitle>
            <ProductName>{props.data.policies[0].name}</ProductName>
            <CompanyName>{props.data.companyName}</CompanyName>
          </WrapperTitle>
        </CustomTitle>

        <TagRider>
          <TextRiderLifeInsuran>พ่วงประกันชีวิต</TextRiderLifeInsuran>
        </TagRider>

        <RowInfo>
          <Content>เบี้ยประกัน ณ ปีปัจจุบัน</Content>
          <Premium>
            <span>
              {displayNumber(parseInt(props.data.policies[0].premium))}
            </span>
            &nbsp;&nbsp;บาท/ปี
            <CustomTooltip
              placement="bottomRight"
              title={'ยังไม่รวมค่าเบี้ยประกันชีวิต'}>
              <CustomIcon icon={['fas', 'info-circle']} />
            </CustomTooltip>
          </Premium>
        </RowInfo>

        <RowInfo>
          <Content>เสียชีวิตจากโรคร้ายแรง</Content>
          {`${displayNumber(props.data.totalDeadBenefit)} บาท/ปี`}
        </RowInfo>

        <RowInfo>
          <Content>
            ผลประโยชน์ระยะ
            <br />
            เริ่มต้น-ปานกลาง
          </Content>
          {`${displayNumber(props.data.totalBeginingMiddleBenefit)} บาท`}
        </RowInfo>

        <RowInfo>
          <Content>ผลประโยชน์ระยะรุนแรง</Content>
          {`${displayNumber(props.data.totalSevereBenefit)} บาท/คืน`}
        </RowInfo>

        <RowInfo>
          <Content>จำนวนโรคที่คุ้มครอง</Content>
          {`${displayNumber(props.data.totalDiseasesCount)} โรค`}
        </RowInfo>
      </Body>

      <Footer>
        <CustomTagA href={props.pathUrl} target="_blank">
          <CustomButton type="border" icons={['fa', 'search']}>
            รายละเอียด
          </CustomButton>
        </CustomTagA>
        <CustomButton
          onClick={() => {
            props.setIsShowModal(true)
          }}
          icons={['fa', 'shopping-cart']}>
          สนใจซื้อ
        </CustomButton>
      </Footer>
    </DescriptionWrapper>

    <ModalFavorite
      isShowModal={props.isShowModalFavorite}
      setIsShowModal={props.setIsShowModalFavorite}
      isReload
    />
    <ModalCatalogue
      isShowModal={props.isShowModal}
      setIsShowModal={props.setIsShowModal}
      category="health"
      params={props.params}
      premium={props.data.policies[0].premium}
      id={props.data.id}
      packageId={props.data.packageId}
      paramsEncode={props.data.paramsEncode}
      productName={props.data.policies[0].name}
    />
  </Layout>
)

export const pathUrlHealth = props => {
  return `/smart/health/${
    props.data.level === undefined ? 'allLevel' : props.data.level
  }/product/${props.data.policies[0].id}?${obj2Url({
    ...props.params,
    premium: parseInt(props.data.policies[0].premium),
    sumInsured: parseInt(props.data.policies[0].sumInsured),
    packageId: props.data.packageId,
    id: props.data.id,
    encodeFlag: props.fromFavoriteProfile ? 'true' : 'false',
    paramsEncode: props.data.paramsEncode,
  })}`
}

const enhance = compose(
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withState('isShowModal', 'setIsShowModal', false),
  withProps(props => {
    console.log('props -> ', props)

    return {
      pathUrl: `${pathUrlHealth(props)}`,
      // totalAnnualBenefits: totalAnnualBenefits,
      // totalBenefits: totalBenefits,
      // room: room,
      // doctorVisit: doctorVisit,
      // surgeon: surgeon,
      // otherOpdBenefit: otherOpdBenefit,
    }
  }),
)
export default enhance(Rider)
