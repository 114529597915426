import React from 'react'
import styled, {css} from 'react-emotion'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {compose, withHandlers} from 'recompose'

import Button from '../share/Button'
import Field from '../share/Field'

import {isEmail} from '../../core/helper'
import {resendEmail, setData} from '../../ducks/auth'
import {responsive} from '../../core/style'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`

const CustomButton = styled(Button)`
  min-width: 100px;
  height: 30px;
  font-size: 12px;
  margin-top: 30px;

  ${props =>
    responsive.mobile(css`
      min-width: 280px;
      height: 40px;
      font-size: 16px;
    `)}
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.BLUE};

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const HeaderMobile = styled(Header)`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 20px 0 0 0;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }

  ${props =>
    responsive.mobile(css`
      .input-googleInput-container {
        input {
          width: 280px;
        }
      }
    `)}
`

const ResendEmail = ({onSubmit, errorMessage}) => (
  <Container onSubmit={onSubmit} noValidate>
    <Header>กรุณากรอกอีเมลเพื่อทำการยืนยันใหม่อีกครั้ง</Header>
    <HeaderMobile>กรุณากรอกอีเมล</HeaderMobile>
    <HeaderMobile>เพื่อทำการยืนยันใหม่อีกครั้ง</HeaderMobile>
    <GoogleInput
      name="email"
      type="googleInput"
      placeholder="อีเมล"
      hideError
    />
    <CustomButton
      htmlType="submit"
      errorMessage={errorMessage}
      icons={['fa', 'check-circle']}>
      ยืนยัน
    </CustomButton>
  </Container>
)

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  return errors
}

const enhancer = compose(
  connect(
    (state, props) => ({
      errorMessage: state.auth.errorMessage,
    }),
    {resendEmail, setData},
  ),
  reduxForm({
    form: 'resendEmail',
    validate,
    onChange: (values, _, props) => {
      props.errorMessage !== '' && props.setData({errorMessage: ''})
    },
  }),
  withHandlers({
    onSubmit: ({handleSubmit, resendEmail}) =>
      handleSubmit(values => resendEmail(values)),
  }),
)

export default enhancer(ResendEmail)
