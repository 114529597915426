import React, {Fragment} from 'react'
import Chart from '../../components/Chart'
import styled, {css} from 'react-emotion'

const WrapChart = styled.div`
  position: absolute;
  top: 53px;
`
const WrapLegend = styled.div`
  position: absolute;
  width: 100%;
  top: 389px;
  display: flex;
  justify-content: center;
`
const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
`
const Div = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  @media (max-width: 500px) {
    width: 224px;
  }
  .tooltip {
    position: absolute;
    bottom: 30px;
    background-color: #555;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
  }
  :hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`
const Symbol = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => props.color};
  border-radius: 20px;
  margin-right: 10px;
`
const Span = styled.span`
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const LegendLabel = props => {
  return <WrapLegend>1234</WrapLegend>
}
export default props => {
  const {data = []} = props
  let colors = props.colors || [
    '#093771',
    '#0E4791',
    '#265FA7',
    '#4375B5',
    '#6A94CB',
    '#E5E5E5',
  ]
  if (data.length > 0 && colors.length > data.length) {
    colors = colors.slice(colors.length - data.length, colors.length)
  }

  const title = props.title.replace(' ', '<br />')
  const config = {
    title: {
      text: title,
      align: 'center',
      verticalAlign: 'middle',
      style: {color: '#333333', fontSize: '16px'},
      y: 0,
    },
    chart: {
      type: 'pie',
      useHTML: true,
      style: {
        textOutline: '',
      },
      width: '292',
      height: '292',
    },
    legend: {
      enabled: false,
      // layout: 'vertical',
      // align: 'left',
      // floating: true,
      // x: 20,
      // y: 100,
      // itemStyle: {fontSize: '14px', fontWeight: 'normal'},
      // labelFormatter: function() {
      //   return this.y + '% ' + this.name
      // },
      // maxHeight: '1000',
      // itemMarginBottom: 5,
      // itemMarginTop: 5,
      // symbolHeight: 20,
      // symbolWidth: 20,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        colors: colors,
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        borderColor: 'transparent',
        borderWidth: 0,
        lineWidth: 0,
        innerSize: '80%',
        data: data,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
  }
  return (
    <Fragment>
      <WrapChart>
        <Chart config={config} />
      </WrapChart>
      <WrapLegend>
        <WrapItems>
          {data.map((d, i) => {
            return (
              <Div key={d.name}>
                <Symbol color={colors[i]} />
                <div class="tooltip">{d.name}</div>
                <Span>
                  {d.y}% {d.name}
                </Span>
              </Div>
            )
          })}
        </WrapItems>
      </WrapLegend>
    </Fragment>
  )
}
