import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {reduxForm, formValueSelector} from 'redux-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import windowSize from '../../core/react-window-size'

import Field from '../share/Field'
import Button from '../share/Button'
import Loading from '../share/Loading'

import {responsive} from '../../core/style'
import {searchBox} from '../../ducks/landing'
import {inRange, numberParser} from '../../core/helper'

const INSURANCE_TYPE_OPTIONS = [
  {
    label: 'วางแผน - ปกป้องภาระครอบครัว',
    value: 'DebtProtection',
  },
  {
    label: 'วางแผน - รายได้ครอบครัว',
    value: 'FamilyIncomeProtection',
  },
  {
    label: 'วางแผน - มีเงินใช้หลังเกษียณ',
    value: 'Retirement',
  },
  {label: 'วางแผน - ค่าเทอมลูก', value: 'Child'},
  {label: 'วางแผน - ลดหย่อนภาษี', value: 'TaxReduction'},
  {label: 'วางแผน - สะสมเงิน', value: 'Saving'},
  {
    label: 'ประกันชีวิต - ตลอดชีพ',
    value: 'whole_life',
  },
  {
    label: 'ประกันชีวิต - สะสมทรัพย์',
    value: 'saving',
  },
  {
    label: 'ประกันชีวิต - ชั่วระยะเวลา',
    value: 'term',
  },
  {
    label: 'ประกันชีวิต - บำนาญ',
    value: 'annuity',
  },
  {
    label: 'ประกันสุขภาพ - ค่ารักษาพยาบาล (HS)',
    value: 'hs',
  },
  {
    label: 'ประกันสุขภาพ - โรคร้ายแรง (CI)',
    value: 'ci',
  },
  {
    label: 'ประกันรถยนต์',
    value: 'motor',
  },
]

const STATUS_OPTIONS = [
  {
    label: 'โสด',
    value: 'single',
  },
  {
    label: 'แต่งงาน',
    value: 'married',
  },
]

const CustomField = styled(Field)`
  margin: 0 10px;
  input {
    background-color: white;
    border: unset;
    height: 30px;
    width: 70px;
    text-align: center;
    color: ${props => (props.error ? '#FF0034' : 'black')};

    -webkit-appearance: none;
  }

  .input-selector-container {
    margin: 0;

    .Select {
      width: 100px;
      height: 30px;
      background-color: white;
    }
    .Select-menu-outer {
      > .Select-menu {
        background-color: white;
        outline: 1px solid #ffd482;

        > .Select-option {
          color: rgba(51, 51, 51, 0.5);
          padding: 13px 15px;
          line-height: 20px;
          text-align: left;
          word-break: break-word;
          :hover {
            background-color: rgba(255, 212, 130, 0.5);
            color: black;
          }
        }
      }
    }

    .Select-control {
      > .Select-multi-value-wrapper {
        > .Select-placeholder {
          color: black;
          opacity: 0.6;
        }
      }
      > .Select-arrow-zone {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .error-container {
    z-index: 0;

    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    span,
    svg {
      color: ${props => props.theme.PINKRED} !important;
    }
    .error {
      position: unset;
      line-height: 22px;
      width: fit-content;
    }
  }

  ${props =>
    responsive.mobile(css`
      input {
        /* width: 240px; */
        width: 100%;
        padding: 0 16px;

        text-align: left;
        font-size: 16px;
      }

      .input-selector-container {
        margin: 0;

        .Select {
          /* width: 240px; */
          width: 100%;
          height: 35px;

          .Select-control {
            > .Select-multi-value-wrapper {
              > .Select-placeholder {
                padding: 0 24px 0 16px;

                text-align: left;
                font-size: 16px;
              }
              > .Select-value {
                > .Select-value-label {
                  text-align: left;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .error-container {
        .error {
          width: 100%;
        }
      }
    `)};
`

const AgeField = styled(CustomField)`
  input[name='age'] {
    border: 1px solid #ffd482;
  }

  .error-container {
    z-index: 0;
  }

  ${props =>
    responsive.mobile(css`
      input[name='age'] {
        height: 33px;
      }
    `)}
`

const Container = styled.div`
  width: 600px;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  ${props =>
    responsive.mobile(css`
      width: 100%;
      padding: 0 20px;
    `)};
`

const CustomGenderIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  width: 11px;
  height: 31px;
  margin-right: 15px;
  color: ${props => props.theme.ORANGE};
  opacity: ${props => (props.active === 'true' ? 1 : 0.5)};

  cursor: pointer;
`

const Topic = styled.h1`
  margin: 84px 0 50px 0;
  font-size: 26px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;

  ${props =>
    responsive.mobile(css`
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 20px;
    `)};
`

const Search = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 120px;

  border-radius: 5px;
  border: 1px solid #265fa7;

  ${props =>
    responsive.mobile(css`
      height: auto;
      padding: 0 20px;
      justify-content: unset;
    `)};
`

const ContainerHeadSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 5px 20px 10px;
  .insuranceType {
    width: unset;
    /* width: 100%; */
    margin: 0;
    .input-selector-container {
      .Select {
        width: 360px;
      }

      .Select-control {
        > .Select-multi-value-wrapper {
          > .Select-placeholder {
            color: black;
            opacity: 0.6;
          }
        }
      }
    }
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin: 0;
      padding: 0;

      .insuranceType {
        width: 100%;
        .input-selector-container {
          .Select {
            /* width: 240px; */
            width: 100%;
          }
        }
      }
    `)};
`

const Head = styled.div`
  font-weight: bold;
  font-size: 16px;

  ${props =>
    responsive.mobile(css`
      margin: 20px 0 15px;
    `)};
`

const ContainerHeadInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .gender {
    margin-right: 25px;
  }
  .classFemale {
    margin-right: 0;
  }
  .age {
    margin-left: 9px;
  }

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      /* width: 240px; */
      width: 100%;

      .gender {
        margin-right: 0;
      }
      .age {
        margin-left: 0;
      }
    `)};
`

const CustomButton = styled(Button)`
  width: 67px;
  height: 30px;

  ${props =>
    responsive.mobile(css`
      /* min-width: 240px; */
      height: 38px;
      margin: 20px auto;

      line-height: 38px;
    `)};
`

const MobileCutWord = styled.br`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const validate = values => {
  const errors = {}
  if (!values.age) {
    errors.age = 'กรุณาระบุอายุ'
  }
  if (!values.insuranceType) {
    errors.insuranceType = 'กรุณาเลือกประกันที่สนใจ'
  }
  if (!values.status) {
    errors.status = 'กรุณาเลือกสถานะ'
  }
  if (values.insuranceType === 'motor' && parseInt(values.age) < 18) {
    errors.age = 'ต้องมีอายุ 18 ปีขึ้นไปเท่านั้น'
  }
  return errors
}

const SearchBox = ({
  gender,
  invalid,
  onSubmit,
  isLoading,
  setGender,
  windowWidth,
}) => (
  <Loading isLoading={isLoading}>
    <Container>
      <Topic>
        ค้นหาแบบประกันที่ใช่ <MobileCutWord />
        ตามไลฟ์สไตล์ของคุณ
      </Topic>
      <Search onSubmit={onSubmit}>
        <ContainerHeadSearch>
          <Head>เลือกประกันที่สนใจ</Head>
          <CustomField
            className="insuranceType"
            name="insuranceType"
            placeholder={windowWidth <= 425 ? 'กรุณาเลือก' : 'เลือก'}
            type="selector"
            options={INSURANCE_TYPE_OPTIONS}
          />
        </ContainerHeadSearch>
        <ContainerHeadSearch>
          <ContainerHeadInfo>
            <Head className="gender">เพศ</Head>
            <div>
              <CustomGenderIcon
                onClick={() => {
                  setGender('male')
                }}
                active={gender === 'male' ? 'true' : 'false'}
                icon={['fas', 'male']}
              />
              <CustomGenderIcon
                className="classFemale"
                onClick={() => {
                  setGender('female')
                }}
                active={gender === 'female' ? 'true' : 'false'}
                icon={['fas', 'female']}
              />
            </div>
          </ContainerHeadInfo>
          <ContainerHeadInfo>
            <Head className="age">อายุ</Head>
            <AgeField
              name="age"
              placeholder={windowWidth <= 425 ? 'กรุณาระบุ' : 'ระบุ'}
              normalize={inRange(0, 100)}
              parse={numberParser}
            />
          </ContainerHeadInfo>
          <ContainerHeadInfo>
            <Head>สถานะ</Head>
            <CustomField
              name="status"
              placeholder={windowWidth <= 425 ? 'กรุณาเลือก' : 'เลือก'}
              type="selector"
              options={STATUS_OPTIONS}
            />
          </ContainerHeadInfo>
          <CustomButton
            htmlType="submit"
            icons={['fas', 'search']}
            disabled={invalid}>
            ค้นหา
          </CustomButton>
        </ContainerHeadSearch>
      </Search>
    </Container>
  </Loading>
)

const selector = formValueSelector('search')

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.landing.isLoading,
      insuranceTypeSelected: selector(state, 'insuranceType'),
      age: selector(state, 'age'),
    }),
    {searchBox},
  ),
  reduxForm({form: 'search', validate}),
  withHandlers({
    onSubmit: ({handleSubmit, gender, searchBox}) =>
      handleSubmit(value => {
        dataLayer.push({
          event: 'insurance_search',
          searchInsuranceType: value.insuranceType,
          searchGender: gender,
          serchAge: value.age,
          searchMarriageStatus: value.status,
        })

        return searchBox({...value, gender})
      }),
  }),
)
export default windowSize(enhancer(SearchBox))
