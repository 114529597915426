import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {Button, Collapse} from '../../components'
import {navigate} from '@reach/router'
import {compose, withProps, lifecycle, withHandlers, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import shield from '../../../../static/images/security-on.svg'
import LeadModal from './LeadModal'
import {withAnswers} from '../../enhances'
import {httpGet} from '../../../../core/httpClient'
import defaultLogo from '../../../../static/images/default-company-logo.png'
import {toCurrency} from '../../core/helper'
import {fetchProductDetail} from '../../../../ducks/product'
import {url2Obj} from '../../../../core/helper'
import {GoBack} from '../planning/annuity'
import * as path from '../../common/path'
import LeadSuccessModal from './LeadSuccessModal'
import itaxApi from '../../common/api'
import GraphImg from './GraphImg'
import {isEqual} from 'lodash'
import LoginModal from '../../../NoonQuestion/Summary/LoginModal.js'
import numeral from 'numeral'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`
const NextButton = styled(Button)`
  min-width: 250px;
  max-width: 250px;
  min-height: 38px;
  margin-top: 35px;
`
const Card = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 30px 25px;
  min-height: 400px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333;
  font-family: 'Prompt', 'Roboto', sans-serif;
  margin-bottom: 48px;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Mid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
`
const Footer = styled.div``
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 2;
`
const Label = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`
const Value = styled.div`
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceValue = styled.div`
  font-size: 42px;
  color: #265fa7;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const SmallText = styled.div`
  font-size: 14px;
  color: #6a94cb;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const InsuranceText = styled.div`
  color: #6a94cb;
  font-size: 16px;
  font-weight: 700;
`
const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Symbol = styled.div`
  font-size: 12px;
`
const Img = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 5px;
`
const Shield = styled.img`
  width: 50px;
  height: 50px;
`
const WrapText = styled.div`
  padding: 0 10px;
  flex: 1;
`
const MoneySymbol = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #265fa7;
  font-family: 'Prompt', 'Roboto', sans-serif;
`
const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 3px;
  margin-right: 5px;
`
const Wrap = styled.div`
  position: relative;
`
const Point = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  font-family: 'Prompt', 'Roboto', sans-serif;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 20px;
  left: 0;
`
const LabelTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`
const Line = styled.div`
  border-top: 1px solid #e5e5e5;
  margin: 20px 0;
`
const WrapButton = styled.div`
  display: flex;
  justify-content: center;
`
const LinkToTabel = styled.div`
  color: #ffb933;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`
const Span = styled.span`
  text-decoration: underline;
`
const SuggestSignIndex = styled.div`
  border: 1px solid #9fd134;
  border-bottom: 0;
  padding: 5px 10px;
  background: #9fd134;
  border-radius: 5px;
  color: white;
  width: 73px;
  text-align: center;
`
const WrapSuggestSign = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
  margin-top: 0;
`

const ProductDetail = props => {
  const {productLifeDetail, match} = props
  const {policyType} = match.params
  const suggest = !!(
    props.location &&
    props.location.state &&
    props.location.state.suggest
  )

  let totalBenefit = 0
  if (policyType === 'saving') {
    const irr = productLifeDetail.irr || 0
    const protectionPeriod = productLifeDetail.protectionPeriod || 0
    const yieldToMaturity = productLifeDetail.yieldToMaturity || 0
    const sumInsured = productLifeDetail.sumInsured || 0
    totalBenefit =
      ((irr / 100) * protectionPeriod + parseFloat(yieldToMaturity) / 100) *
        sumInsured || 0
  }
  const payPeriod =
    productLifeDetail.paidPremiumType === 'period'
      ? `จ่าย ${toCurrency(productLifeDetail.payPeriod, {prefix: ''})} ปี`
      : `จ่ายถึงอายุ ${toCurrency(
          productLifeDetail.payPeriod
            ? productLifeDetail.payPeriod
            : productLifeDetail.minAgeAnnuity - 1,
          {prefix: ''},
        )} ปี`
  return (
    <Layout>
      <GoBack
        onClick={() =>
          props.history.push(
            match.params.policyType === 'annuity'
              ? path.annuityPlanningPath()
              : path.savingPlanningPath(),
          )
        }>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        รายละเอียดแบบประกัน
      </GoBack>
      <Card>
        {suggest && (
          <WrapSuggestSign>
            <SuggestSignIndex>แนะนำ</SuggestSignIndex>
          </WrapSuggestSign>
        )}
        <Top>
          <Img src={productLifeDetail.companyLogoUrl || defaultLogo} />
          <WrapText>
            <Name>{productLifeDetail.name}</Name>
            <Symbol>{productLifeDetail.companyName}</Symbol>
          </WrapText>
          <Wrap>
            <Shield src={shield} />
            <Point>{numeral(productLifeDetail.score).format('0[.]0')}</Point>
          </Wrap>
        </Top>
        {match.params.policyType === 'annuity' ? (
          <>
            <Mid>
              <InsuranceText>เงินที่ต้องจ่ายต่อปี (เบี้ยประกัน)</InsuranceText>
              <InsuranceValue>
                <MoneySymbol>฿</MoneySymbol>{' '}
                {toCurrency(productLifeDetail.premium, {prefix: ''})}
              </InsuranceValue>
              <SmallText>{payPeriod}</SmallText>
            </Mid>
            <Footer>
              <LabelTitle>ผลประโยชน์</LabelTitle>
              <Row>
                <Label>
                  <Icon icon={['fas', 'shield-alt']} />
                  ได้เงินคืนหลังเกษียณต่อปี
                </Label>
                <MoneySymbol>
                  ฿{' '}
                  {toCurrency(productLifeDetail.annuityRefundPerYear, {
                    prefix: '',
                  })}
                </MoneySymbol>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'undo-alt']} />
                  ได้เงินคืนตั้งแต่อายุ
                </Label>
                <Value>
                  {productLifeDetail.minAgeAnnuity} -{' '}
                  {productLifeDetail.maxAgeAnnuity} ปี
                </Value>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'hand-holding-usd']} />
                  ได้รับเงินเมื่อเสียชีวิต
                </Label>
                <Value>
                  เริ่มที่ ฿{' '}
                  {toCurrency(productLifeDetail.sumInsured, {prefix: ''})}
                </Value>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'calculator']} />
                  ลดภาษีปีนี้ได้
                </Label>
                <Value>
                  ฿{toCurrency(productLifeDetail.premium, {prefix: ''})}
                </Value>
              </Row>
              {productLifeDetail.graphImageUrl && (
                <LinkToTabel>
                  <Icon icon={['fas', 'table']} />
                  <Span onClick={() => props.setOpenLightBox(true)}>
                    ดูตารางผลประโยชน์
                  </Span>
                </LinkToTabel>
              )}
              <GraphImg
                openLightBox={props.openLightBox}
                setOpenLightBox={props.setOpenLightBox} // productLifeDetail.graphImageUrl ||
                imgPath={productLifeDetail.graphImageUrl}
              />
              <Line />
              <LabelTitle>เงื่อนไข</LabelTitle>
              <Row>
                <Label>
                  <Icon icon={['fas', 'check']} />
                  อายุที่รับสมัคร
                </Label>
                <Value>
                  {productLifeDetail.minAgeInsured}
                  {' - '}
                  {productLifeDetail.maxAgeInsured} ปี
                </Value>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'check']} />
                  ระยะเวลาคุ้มครอง
                </Label>
                <Value>คุ้มครองจนถึงอายุ 64 ปี</Value>
              </Row>
              <WrapButton>
                <NextButton onClick={() => props.openModalLeadOrLogin()}>
                  สนใจซื้อ
                </NextButton>
              </WrapButton>
            </Footer>
          </>
        ) : (
          <>
            <Mid>
              <InsuranceText>เงินที่ต้องจ่ายต่อปี (เบี้ยประกัน)</InsuranceText>
              <InsuranceValue>
                <MoneySymbol>฿</MoneySymbol>{' '}
                {toCurrency(productLifeDetail.premium, {prefix: ''})}
              </InsuranceValue>
              <SmallText>{payPeriod}</SmallText>
            </Mid>
            <Footer>
              <LabelTitle>ผลประโยชน์</LabelTitle>
              <Row>
                <Label>
                  <Icon icon={['fas', 'shield-alt']} />
                  ได้เงินคืนทั้งหมด
                </Label>
                <MoneySymbol>
                  {toCurrency(totalBenefit, {prefix: '฿'})}
                </MoneySymbol>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'undo-alt']} />
                  ปันผลเฉลี่ยปีละ
                </Label>
                <Value>
                  {toCurrency(
                    productLifeDetail.sumInsured *
                      (productLifeDetail.irr / 100),
                    {
                      prefix: '฿',
                    },
                  )}{' '}
                </Value>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'coins']} />
                  รับเงินก้อนเมื่อครบสัญญา
                </Label>
                {toCurrency(productLifeDetail.yieldToMaturityAmount, {
                  prefix: '฿',
                })}
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'hand-holding-usd']} />
                  ได้รับเงินเมื่อเสียชีวิต
                </Label>
                เริ่มที่{' '}
                {toCurrency(productLifeDetail.sumInsured, {prefix: '฿'})}
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'calculator']} />
                  ลดภาษีปีนี้ได้
                </Label>
                <Value>
                  {toCurrency(productLifeDetail.premium, {prefix: '฿'})}
                </Value>
              </Row>
              <GraphImg
                openLightBox={props.openLightBox}
                setOpenLightBox={props.setOpenLightBox} // productLifeDetail.graphImageUrl ||
                imgPath={productLifeDetail.graphImageUrl}
              />
              <Line />
              <LabelTitle>เงื่อนไข</LabelTitle>
              <Row>
                <Label>
                  <Icon icon={['fas', 'check']} />
                  อายุที่รับสมัคร
                </Label>
                <Value>
                  {productLifeDetail.minAgeInsured}
                  {' - '}
                  {productLifeDetail.maxAgeInsured} ปี
                </Value>
              </Row>
              <Row>
                <Label>
                  <Icon icon={['fas', 'check']} />
                  ระยะเวลาคุ้มครอง
                </Label>
                <Value>คุ้มครองจนถึงอายุ 64 ปี</Value>
              </Row>
              <WrapButton>
                <NextButton onClick={() => props.openModalLeadOrLogin()}>
                  สนใจซื้อ
                </NextButton>
              </WrapButton>
            </Footer>
          </>
        )}
      </Card>
      {props.openLeadModal && (
        <LeadModal
          isShowModal={props.openLeadModal}
          setIsShowModal={props.setOpenLeadModal}
          productLifeDetail={productLifeDetail}
          openSuccessModal={props.openSuccessModal}
          setOpenSuccessModal={props.setOpenSuccessModal}
          setCustomerInfo={props.setCustomerInfo}
          setOpenLightBox={props.setOpenLightBox}
          setAgent={props.setAgent}
        />
      )}
      {props.openModalLogin && !props.skip && (
        <LoginModal
          isShowModal={props.openModalLogin}
          setIsShowModal={props.setOpenModalLogin}
          isReload={false}
          onSkip={props.onSkip}
        />
      )}
      {props.openSuccessModal && (
        <LeadSuccessModal
          isShowModal={props.openSuccessModal}
          setIsShowModal={props.setOpenSuccessModal}
          customerInfo={props.customerInfo}
          agent={props.agent}
        />
      )}
    </Layout>
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({
      isLogin: state.auth.isLogin,
    }),
    {fetchProductDetail},
  ),
  withAnswers('noontax_config_web_tax_planning', {
    age: 'age.value',
    gender: 'gender.value',
    additoinalInsuranceLife: 'planning_deduct_life_insurance',
    additoinalInsurancePension: 'planning_deduct_pension_insurance',
  }),
  withState('productLifeDetail', 'setProductLifeDetail', {}),
  withState('agent', 'setAgent', {}),
  withHandlers({
    fetchProductDetails: props => async () => {
      const {match} = props
      const {policyId, policyType} = match.params
      let premium = 0
      if (policyType === 'saving') {
        premium = props.additoinalInsuranceLife
          ? props.additoinalInsuranceLife.value
          : 0
      }
      if (policyType === 'annuity') {
        premium = props.additoinalInsurancePension
          ? props.additoinalInsurancePension.value
          : 0
      }

      const response = await itaxApi.get(
        `/partner/api/v1/life_insurance_policies/${policyId}`,
        {
          insurance_type: policyType,
          weight_type: 1,
          premium: premium,
          age: props.age,
          gender: props.gender,
        },
      )
      props.setProductLifeDetail(response.data.data.lifeInsurancePolicy)
    },
  }),
  withState('openLeadModal', 'setOpenLeadModal', false),
  withState('openModalLogin', 'setOpenModalLogin', false),
  withState('openSuccessModal', 'setOpenSuccessModal', false),
  withState('customerInfo', 'setCustomerInfo', {}),
  withState('openLightBox', 'setOpenLightBox', false),
  withState('skip', 'setSkip', false),
  withHandlers({
    openModalLeadOrLogin: props => () => {
      if (props.isLogin || props.skip) {
        props.setOpenLeadModal(true)
      } else {
        props.setOpenModalLogin(true)
      }
    },
    onSkip: props => () => {
      props.setSkip(true)
      props.setOpenModalLogin(false)
      props.setOpenLeadModal(true)
    },
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.fetchProductDetails()
    },
    async componentDidUpdate(prevProps) {
      if (
        !isEqual(prevProps.age, this.props.age) ||
        !isEqual(prevProps.gender, this.props.gender) ||
        !isEqual(
          prevProps.additoinalInsuranceLife,
          this.props.additoinalInsuranceLife,
        ) ||
        !isEqual(
          prevProps.additoinalInsurancePension,
          this.props.additoinalInsurancePension,
        )
      ) {
        await this.props.fetchProductDetails()
      }
    },
  }),
)
export default enhancer(ProductDetail)
