import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import windowSize from '../../../../../core/react-window-size'
import HeaderDetail from '../HeaderDetail'
import {responsive, breakpoints} from '../../../../../core/style'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 44px;
`
const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 777px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  .IPD {
    width: 50%;
    padding-right: 10px;
  }

  ${props =>
    responsive.tablet(css`
      max-width: 600px;
    `)};

  ${props =>
    responsive.mobile(css`
      max-width: 280px;
    `)};
`
const Detail = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  margin-bottom: 13px;
  text-align: left;
  max-width: 388px;
  ${props =>
    responsive.tablet(css`
      max-width: 300px;
    `)};

  ${props =>
    responsive.mobile(css`
      max-width: 140px;
    `)};
`

const ContainerDetailValue = styled.div`
  display: flex;
  flex-direction: column;
`
const DetailValue = styled(Detail)`
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 5px;
  padding-left: 10px;
`

const HealthHS = ({
  selectedMostMoney,
  setSelectedMostMoney,
  selectedIPD,
  setSelectedIPD,
  selectedOPD,
  setSelectedOPD,
  selectedOther,
  setSelectedOther,
  item,
  windowWidth,
}) => {
  const maxWidth = windowWidth <= breakpoints.tablet ? '600px' : '777px'
  const fontSizeTopic = windowWidth <= breakpoints.mobile ? '13' : '16'
  return (
    <Container>
      <Header>รายละเอียดความคุ้มครองรักษาพยาบาล</Header>
      <HeaderDetail
        selected={selectedMostMoney}
        setSelected={setSelectedMostMoney}
        header={'วงเงินสูงสุดต่อการนอนโรงพยาบาลใน 1 ครั้ง'}
        bgColor={'rgba(159,209,52,0.2)'}
        maxWidth={maxWidth}
        fontSizeTopic={fontSizeTopic}
      />
      {selectedMostMoney && (
        <React.Fragment>
          {item.general.map(value => (
            <DetailContainer key={value.key}>
              <Detail>{value.label}</Detail>
              <ContainerDetailValue>
                {value.displayValue &&
                  value.displayValue.map(val => (
                    <DetailValue>{val}</DetailValue>
                  ))}
              </ContainerDetailValue>
            </DetailContainer>
          ))}
        </React.Fragment>
      )}
      <HeaderDetail
        selected={selectedIPD}
        setSelected={setSelectedIPD}
        header={'ความคุ้มครองค่ารักษาพยาบาลผู้ป่วยใน (IPD)'}
        bgColor={'rgba(159,209,52,0.2)'}
        maxWidth={maxWidth}
        fontSizeTopic={fontSizeTopic}
      />
      {selectedIPD && (
        <React.Fragment>
          {item.ipd.map(value => (
            <DetailContainer key={value.key}>
              <Detail className={'IPD'}>{value.label}</Detail>
              <ContainerDetailValue>
                {value.displayValue &&
                  value.displayValue.map(val => (
                    <DetailValue>{val}</DetailValue>
                  ))}
              </ContainerDetailValue>
            </DetailContainer>
          ))}
        </React.Fragment>
      )}
      <HeaderDetail
        selected={selectedOPD}
        setSelected={setSelectedOPD}
        header={'ความคุ้มครองค่ารักษาพยาบาลผู้ป่วยนอก (OPD)'}
        bgColor={'rgba(159,209,52,0.2)'}
        maxWidth={maxWidth}
        fontSizeTopic={fontSizeTopic}
      />
      {selectedOPD && (
        <React.Fragment>
          {item.opd.map(value => (
            <DetailContainer key={value.key}>
              <Detail>{value.label}</Detail>
              <ContainerDetailValue>
                {value.displayValue &&
                  value.displayValue.map(val => (
                    <DetailValue>{val}</DetailValue>
                  ))}
              </ContainerDetailValue>
            </DetailContainer>
          ))}
        </React.Fragment>
      )}
      <HeaderDetail
        selected={selectedOther}
        setSelected={setSelectedOther}
        header={'ความคุ้มครองอื่นๆ'}
        bgColor={'rgba(159,209,52,0.2)'}
        maxWidth={maxWidth}
        fontSizeTopic={fontSizeTopic}
      />
      {selectedOther && (
        <React.Fragment>
          {item.others.map(value => (
            <DetailContainer key={value.key}>
              <Detail>{value.label}</Detail>
              <ContainerDetailValue>
                {value.displayValue &&
                  value.displayValue.map(val => (
                    <DetailValue className={'IPDValue'}>{val}</DetailValue>
                  ))}
              </ContainerDetailValue>
            </DetailContainer>
          ))}
        </React.Fragment>
      )}
    </Container>
  )
}

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
  withState('selectedMostMoney', 'setSelectedMostMoney', true),
  withState('selectedIPD', 'setSelectedIPD', true),
  withState('selectedOPD', 'setSelectedOPD', true),
  withState('selectedOther', 'setSelectedOther', true),
  lifecycle({
    componentDidMount() {},
  }),
)

export default windowSize(enhancer(HealthHS))
