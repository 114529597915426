import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, withHandlers} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import ModalFavorite from '../share/ModalFavorite'
import {favoriteArticle, unFavoriteArticle} from '../../ducks/noonSquare'
import {responsive} from '../../core/style'

const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: red;
  margin-left: 34px;
  cursor: pointer;

  ${props =>
    responsive.mobile(css`
      margin-left: 0;
    `)};
`

const Favorite = ({
  id,
  isShowModalFavorite,
  setIsShowModalFavorite,
  isLogin,
  onFavorite,
  onUnFavorite,
  article,
}) => (
  <React.Fragment>
    <HeartIcon
      id={
        isLogin && article.favorite
          ? `square-article-favorite-${id}`
          : `square-article-unfavorite-${id}`
      }
      onClick={() => {
        isLogin
          ? article.favorite
            ? onUnFavorite()
            : onFavorite()
          : setIsShowModalFavorite(!isShowModalFavorite)
      }}
      icon={[article.favorite ? 'fas' : 'far', 'heart']}
    />
    <ModalFavorite
      callback={() => window.location.reload()}
      isShowModal={isShowModalFavorite}
      setIsShowModal={setIsShowModalFavorite}
    />
  </React.Fragment>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      id: props.article.id,
      isLogin: state.auth.isLogin,
    }),
    {favoriteArticle, unFavoriteArticle},
  ),
  withState('isShowModalFavorite', 'setIsShowModalFavorite', false),
  withHandlers({
    onFavorite: ({id, type, from, favoriteArticle}) => () => {
      favoriteArticle(id, type, from)
    },
    onUnFavorite: ({id, type, from, unFavoriteArticle}) => () => {
      unFavoriteArticle(id, type, from)
    },
  }),
)

export default enhancer(Favorite)
