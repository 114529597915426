import {takeLatest, put} from 'redux-saga/effects'
import {createReducer, Creator} from '../helper'

const SET_DATA = 'SET_DATA'

export const setData = Creator(SET_DATA, 'data')

export function* watch404Saga() {}

const initial = {
  isNoHeader: false,
  isNoFooter: false,
}

export default createReducer(initial, state => ({
  [SET_DATA]: ({data}) => ({...state, ...data}),
}))
