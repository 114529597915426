import React, {useEffect, useMemo, useCallback} from 'react'
import {connect} from 'react-redux'
import styled from 'react-emotion'
import {getFormValues, change} from 'redux-form'

import {fetchFundCategoryOptions} from '../../../../../ducks/fund'
import {TitleSideBar, CustomSelector} from './StyleComponents'

const Wrapper = styled.div`
  margin-left: 28px;
  margin-right: 22px;
  margin-bottom: 30px;
`

export default connect(
  (state, props) => {
    const {fundCategoryOptions} = state.fund
    const {formId} = props
    const formValues = getFormValues(formId)(state)
    return {formValues, fundCategoryOptions}
  },
  {fetchFundCategoryOptions, change},
)(props => {
  const {
    formValues,
    fundCategoryOptions,
    fetchFundCategoryOptions,
    change,
    formId,
  } = props

  const categoryFieldName = 'category'
  const subCategortFieldName = 'subCategory'

  const fundSubCategoryOptions = useMemo(() => {
    const symbol = formValues ? formValues[categoryFieldName] : ''
    const category = fundCategoryOptions.find(option => option.value === symbol)
    return category ? category.subCategoryOptions : []
  }, [formValues, fundCategoryOptions])

  const handleCategoryChange = useCallback(
    symbol => {
      const category = fundCategoryOptions.find(
        option => option.value === symbol,
      )
      if (category && category.subCategoryOptions.length === 0) {
        change(formId, subCategortFieldName, null)
      }
    },
    [fundCategoryOptions],
  )

  useEffect(() => {
    fetchFundCategoryOptions()
  }, [])

  return (
    <Wrapper>
      <TitleSideBar>ประเภทกองทุน</TitleSideBar>
      <CustomSelector
        name={categoryFieldName}
        type="selector"
        placeholder="ประเภทกองทุน"
        options={fundCategoryOptions}
        didChange={handleCategoryChange}
        themeColor="orange"
      />
      {fundSubCategoryOptions && fundSubCategoryOptions.length !== 0 && (
        <CustomSelector
          name={subCategortFieldName}
          type="selector"
          placeholder="หมวดหมู่ย่อย"
          options={fundSubCategoryOptions}
          themeColor="orange"
        />
      )}
    </Wrapper>
  )
})
