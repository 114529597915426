import React, {useState, useEffect} from 'react'
import Chart from '../../components/Chart'
import styled, {css} from 'react-emotion'
import {responsive} from '../../../../core/style'
import Button from '../../../share/Button'
import moment from '../../../../core/moment-buddhist'
import {uniq, toInteger} from 'lodash'
import Select from 'react-select'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

moment.locale('th')

const CustomStyleSelect = styled(Select)`
  position: relative;

  width: 122px;
  height: 30px;
  border: 1px solid rgb(255, 212, 130);
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  font-size: 14px;
  font-family: ${props => props.theme.headerFont};
  background-color: white;
  border-radius: 5px;
  @media (max-width: 800px) {
    font-size: 12px;
    width: 100px;
  }
  &.is-focused > .Select-control {
    box-shadow: none;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }

  &.is-open .Select-control .Select-arrow-zone {
    > .anticon {
      transform: scaleY(-1);
    }
  }
  *:focus {
    outline: none;
    color: transparent;
  }
  &.is-open {
    border-radius: 5px 5px 0 0 !important;
  }

  > :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  > .Select-control {
    position: relative;

    height: 100%;
    background-color: ${props => props.disabled && '#E5E5E5'};
    color: black;
    padding: 12px 0;

    > .Select-multi-value-wrapper {
      > .Select-placeholder {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;

        color: black;
        opacity: 0.6;

        width: 100%;
        text-align: center;
      }

      > .Select-input {
        position: absolute;
        top: 0;
        left: 10px;

        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;

        > input {
          height: 100%;
          border: none;
          background-color: transparent;
        }
      }

      > .Select-value > .Select-value-label {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: calc(100% - 40px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: wrap;
        text-align: center;
      }
    }

    > .Select-clear-zone {
      display: none;
    }

    > .Select-arrow-zone {
      position: absolute;
      top: 0;
      bottom: 0;

      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: 10px;
      color: #000000;
     > svg{
       color:#ffd482;
     }
    }
  }

  > .Select-menu-outer {
    position: absolute;
    z-index: 1;

    width: 100%;
    border: none;

    > .Select-menu {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 220px;
      align-items: flex-start;
      border-radius: 0 0 5px 5px;
      background-color: ${props =>
        props.themeColor === 'orange' ? 'white' : props.theme.DARKBLUE};
      outline: ${props =>
        props.themeColor === 'orange' &&
        `1px solid ${props.theme.EXTRALIGHTORANGE}`};
      font-size: 16px;
      line-height: 20px;

      > .Select-noresults {
        color: white;
      }

      > .Select-option {
        color: ${props =>
          props.themeColor === 'orange' ? 'rgba(51, 51, 51, 0.5)' : 'white'};
        padding: 13px 15px;
        line-height: 20px;
        text-align: left;
        word-break: break-word;
        :hover {
          background-color: ${props =>
            props.themeColor === 'orange'
              ? 'rgba(255, 212, 130, 0.5)'
              : 'rgba(106, 148, 203, 0.5)'};
          color: ${props =>
            props.themeColor === 'orange' ? 'black' : 'white'};
        }
      }

      /* > .Select-option.is-focused {
        color: ${props => (props.themeColor === 'orange' ? 'black' : 'white')};
        background-color: ${props =>
          props.themeColor === 'orange'
            ? 'rgba(255, 212, 130, 0.5)'
            : 'rgba(106, 148, 203, 0.5)'};
      }

      > .Select-option.is-selected {
        color: ${props => (props.themeColor === 'orange' ? 'black' : 'white')};
        background-color: ${props =>
          props.themeColor === 'orange'
            ? 'rgba(255, 212, 130, 0.5)'
            : 'rgba(106, 148, 203, 0.5)'};
      } */
    }
  }
`

const CustomOptionContainer = styled.div`
  display: flex;

  .header {
    width: ${props => (props.haveSubmenu ? '127px' : '100%')};
  }

  .sub-menu {
    width: ${props => (props.haveSubmenu ? '322px' : '0')};
  }

  :hover {
    .header {
      background-color: rgba(106, 148, 203, 0.5);
    }
    .sub-menu {
      display: flex;
    }
  }
`

const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px;
`
const SubMenu = styled.div`
  display: none;
  flex-direction: column;
`
const WrapSelect = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const Label = styled.div`
  margin-right: 10px;
`
const CustomSubmenu = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 21px;

  background-color: rgba(106, 148, 203, 0.15);
  color: rgba(255, 255, 255, 0.5);

  :hover {
    background-color: rgba(106, 148, 203, 0.5);
    color: white;
  }
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
`

const SessionChart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 25px;
  ${props => responsive.tablet(css``)};

  ${props =>
    responsive.mobile(css`
      margin-top: 20px;
      margin-bottom: 0px;
    `)};
`
const WraperChart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const HighChart = styled.div`
  width: 800px;
  border-radius: 5px;
  overflow: hidden;
  .highcharts-tooltip > span {
    background: rgba(255, 255, 255, 0.85);
    border: 1px double #ffb933;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #888;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .highcharts-tooltip {
    color: #ffb933 !important;
  }
  @media (max-width: 1024px) {
    width: 700px;
  }
  @media (max-width: 800px) {
    width: 700px;
  }
  @media (max-width: 500px) {
    width: 340px;
    margin-bottom: 20px;
  }
  @media (max-width: 375px) {
    width: 300px;
    margin-bottom: 20px;
  }
  @media (max-width: 320px) {
    width: 280px;
    margin-bottom: 20px;
  }
`

const selectPrices = (prices, returnPeriod) => {
  let data = []
  let point = []
  let label = []

  const key = {
    '1M': {begin: prices.length - 30 * 1, end: prices.length},
    '3M': {begin: prices.length - 30 * 3, end: prices.length},
    '6M': {begin: prices.length - 30 * 6, end: prices.length},
    '1Y': {begin: prices.length - 365 * 1, end: prices.length},
    '3Y': {begin: prices.length - 365 * 3, end: prices.length},
    '5Y': {begin: 0, end: prices.length},
  }

  const {begin = 0, end = prices.length} = key[returnPeriod || '5Y']
  if (prices !== []) {
    for (let i = begin; i < end; i++) {
      point.push({
        name: prices[i].priceDate,
        y: prices[i].nav,
        x: i,
        key: i,
      })
    }
  }

  return {data, label: uniq(label), point}
}

const CustomOption = ({
  option: {header, submenu = []},
  selectValue,
  onSelect,
}) => (
  <CustomOptionContainer haveSubmenu={submenu.length > 0}>
    <OptionHeader className="header">{header}</OptionHeader>
    <SubMenu className="sub-menu">
      {submenu &&
        submenu.map(item => (
          <CustomSubmenu
            key={item.value}
            onClick={() => {
              onSelect(item)
            }}>
            {item.label}
          </CustomSubmenu>
        ))}
    </SubMenu>
  </CustomOptionContainer>
)
export default props => {
  const {fund} = props
  const {prices = []} = fund
  if (fund === {} || prices === []) return null
  const [returnPeriod, setReturnPeriod] = useState('5Y')
  const [tickInterval, setTickInterval] = useState([365])
  const [points, setPoints] = useState([])
  const [value, setValue] = useState({label: '5 ปี', value: '5Y'})
  const handleClickReturnPeriod = selected => {
    setValue(selected)
    setReturnPeriod(selected.value)
  }

  useEffect(() => {
    const {point} = selectPrices(prices, returnPeriod)
    setPoints(point)
    switch (returnPeriod) {
      case '1M':
        setTickInterval(3)
        break
      case '3M':
        setTickInterval(2)
        break
      case '6M':
        setTickInterval(5)
        break
      case '1Y':
        setTickInterval(11)
        break
      case '3Y':
        setTickInterval(2)
        break
      case '5Y':
        setTickInterval(4)
        break
      default:
        setTickInterval(4)
    }
    clearInterval()
  }, [returnPeriod, prices])

  const config = {
    title: {
      text: '',
    },
    time: {
      timezone: moment.locale('th'),
    },
    chart: {
      animation: false,
      height: (9 / 16) * 100 + '%', // 16:9 ratio
      useHTML: true,
      spacing: [11, 13, 16, 16],
      backgroundColor: '#FFFAEF',
      style: {
        textOutline: '',
      },
    },
    data: {
      dateFormat: 'dd/mm/YYYY',
    },
    scrollbar: {enabled: false},
    yAxis: {
      gridLineWidth: 0,
      lineWidth: 1,
      lineColor: '#E5E5E5',
      title: {
        enabled: false,
      },
    },
    xAxis: {
      tickWidth: 1,
      lineColor: '#E5E5E5',
      tickPositioner: function() {
        const positions = []
        const dataMax = this.dataMax
        const dataMin = this.dataMin
        let recentTick = dataMin
        let distance = toInteger((dataMax - dataMin - 2) / tickInterval)
        for (let i = dataMin; i <= dataMax; i++) {
          if (i === recentTick + distance && dataMax - i > distance - 5) {
            positions.push(i)
            recentTick = i
          }
          if (i === dataMin || i === dataMax) {
            positions.push(i)
          }
        }
        return positions
      },
      labels: {
        enabled: true,
        useHTML: true,
        padding: 0,
        formatter: function() {
          if (!prices) return
          var index = this.value
          var xAxisName = prices[index]
            ? moment(prices[index].priceDate)
            : moment()
          var labelName = ''
          var date = xAxisName.date()
          var year = xAxisName.year() + 543
          var month = xAxisName.format('MMM')
          switch (returnPeriod) {
            case '1M':
              labelName = `${date} ${month}`
              break
            default:
              labelName = `${month} ${year - 2500}`
          }
          return labelName
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'spline',
        data: points,
        color: '#FFB933',
        pointStart: 0,
        marker: {
          enabled: false,
        },
      },
    ],
    plotOptions: {
      series: {
        turboThreshold: 2000,
      },
    },
    tooltip: {
      crosshairs: true,
      backgroundColor: 'transparent',
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      split: false,
      useHTML: true,
      formatter: function(tooltip) {
        var name = this.point.name
        var value = this.point.y
        return `
            <p style="color: #FFB933;">${moment(name).format('ll')} <br/>
             Nav ${value}</p> `
      },
    },
    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }
  const options = [
    {label: '1 เดือน', value: '1M'},
    {label: '3 เดือน', value: '3M'},
    {label: '6 เดือน', value: '6M'},
    {label: '1 ปี', value: '1Y'},
    {label: '3 ปี', value: '3Y'},
    {label: '5 ปี', value: '5Y'},
  ]
  return (
    <SessionChart>
      <WraperChart>
        <WrapSelect>
          <Label>มูลค่าหน่วยลงทุนย้อนหลัง</Label>
          <CustomStyleSelect
            value={value}
            isSearchable={false}
            onChange={value => handleClickReturnPeriod(value)}
            options={options}
            arrowRenderer={() => <CustomIcon icon={['fa', 'angle-down']} />}
            optionComponent={props.isCustomOption ? CustomOption : undefined}
            themeColor="orange"
          />
        </WrapSelect>
        <HighChart>
          <Chart config={config} />
        </HighChart>
      </WraperChart>
    </SessionChart>
  )
}
