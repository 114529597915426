import React from 'react'
import styled from 'react-emotion'
import Select from 'react-select'
import {Icon} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CustomStyleSelect = styled(Select)`
  position: relative;
  height: 40px;
  width: 100%;

  border-radius: 5px;
  font-family: ${props => props.theme.headerFont};
  background-color: white;

  color: black;
  text-align: center;

  font-size: 16px;
  padding: 10px 10px 10px 10px;
  display: block;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};

  &.is-focused > .Select-control {
    box-shadow: none;
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }

  &.is-open .Select-control .Select-arrow-zone {
    > .anticon {
      transform: scaleY(-1);
    }
  }

  > :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  > .Select-control {
    position: relative;

    height: calc(100%);
    background-color: ${props => props.disabled && '#E5E5E5'};
    color: black;

    > .Select-multi-value-wrapper {
      > .Select-placeholder {
        display: none;
      }

      > .Select-input {
        position: absolute;
        top: 0;
        left: 10px;

        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;

        > input {
          height: 100%;
          border: none;
          background-color: transparent;
        }
      }
    }

    > .Select-clear-zone {
      display: none;
    }

    > .Select-arrow-zone {
      position: absolute;
      top: 0;
      bottom: 0;

      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: 10px;

      color: #ffd482;
    }
  }

  > .Select-menu-outer {
    position: absolute;
    z-index: 1;

    width: 100%;
    margin-top: 1px;
    border: none;

    > .Select-menu {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 220px;
      border-radius: 5px;
      align-items: flex-start;

      border-radius: 0 0 5px 5px;
      background-color: white;
      border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
      border-top: 0;
      padding: 0;

      font-size: 14px;
      line-height: 20px;

      > .Select-noresults {
        color: white;
      }

      > .Select-option {
        display: flex;
        align-items: center;
        color: white;

        line-height: 20px;
        text-align: left;
        word-break: break-word;

        color: rgba(51, 51, 51, 0.5);
        font-size: 16px;
        padding: 8px 0 8px 15px;
        width: 250px;
        height: 40px;
        :hover {
          background-color: rgba(255, 212, 130, 0.5);
          color: black;
        }
      }

      > .Select-option.is-focused {
        color: ${props => props.theme.GOLD};
      }

      > .Select-option.is-selected {
        color: ${props => props.theme.GOLD};
      }
    }
  }

  &.is-open {
    outline: none;
    border: 1px solid ${props => props.theme.ORANGE};
    border-radius: 5px 5px 0 0 !important;
  }

  &.is-open ~ label,
  &.is-focused ~ label,
  &.has-value ~ label {
    left: 15px;
    padding: 0 5px;
    top: 0;
    font-size: 14px;
    background: white;
    color: ${props => props.theme.ORANGE};
    transform: translate(0, -50%);
  }
`

const Container = styled.div`
  width: fit-content;
  position: relative;
  margin: 0 auto;
`
const Label = styled.label`
  width: fit-content;
  color: rgba(112, 112, 112, 0.5);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: 0.15s ease all;
  -moz-transition: 0.15s ease all;
  -webkit-transition: 0.15s ease all;
`

const ContainerErrorMessage = styled.div`
  position: absolute;
  color: ${props => props.theme.PINKRED};
  font-size: 12px;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => props.theme.PINKRED};
`

export default ({placeholder, errorMessage, ...props}) => (
  <Container>
    <CustomStyleSelect
      {...props}
      arrowRenderer={() => <Icon type="caret-down" theme="outlined" />}
    />
    <Label>{placeholder}</Label>
    {errorMessage && (
      <ContainerErrorMessage>
        <ExclamationCircle icon={['fas', 'exclamation-circle']} />{' '}
        {errorMessage}
      </ContainerErrorMessage>
    )}
  </Container>
)
