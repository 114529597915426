import React from 'react'
import styled from 'react-emotion'
import moment from 'moment'
import {navigate} from '@reach/router'

import Shield from '../../../static/images/security-on.svg'
import CompanyDefaultLogo from '../../../static/images/default-company-logo.png'
import {getProductType, getStatusText} from './utils'
import {toCurrency} from '../../NoonTax/core/helper'

const Container = styled.div`
  margin-bottom: 20px;
`

const Header = styled.div``

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  line-height: 24px;
`

const DateLabel = styled.div``

const DateValue = styled.div``

const Card = styled.div`
  display: flex;
  padding: 15px 20px;

  border-radius: 5px;
  border: 1px solid ${props => props.theme.EXTRALIGHTBLUE};

  cursor: pointer;
`
const WrapInfo = styled.div`
  width: 100%;
  padding-left: 10px;
`
const CompanyLogo = styled.img`
  min-width: 30px;
  max-width: 30px;
  height: 30px;

  border-radius: 3px;
  object-fit: cover;
`

const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 2;
`

const ProductName = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`

const CompanyName = styled.div`
  font-size: 12px;
  line-height: 18px;
`

const Status = styled.div`
  width: fit-content;
  margin-top: 10px;
  padding: 0 12px;

  background-color: ${props =>
    props.status === 'leaded'
      ? props.theme.GREY10
      : props.status === 'converted'
      ? props.theme.GREEN
      : props.theme.GREY10};
  border-radius: 5px;
  font-size: 12px;
  line-height: 30px;
`

const Score = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
`

const BlueShield = styled.img``

const NoonScore = styled.span`
  position: absolute;

  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: white;
`
const Label = styled.div`
  color: #333333;
  font-size: 12px;
`
const Value = styled.div`
  font-size: 12px;
  color: #333333;
  span {
    font-size: 12px;
    font-weight: 600;
  }
`
const OrderCard = ({
  state,
  noonScore,
  createdAt,
  policyType,
  quotationId,
  insuranceName,
  insuranceType = '',
  premium,
  insuranceCompany: {officialLogoUrl = '', shortName},
}) => (
  <Container>
    <Header>
      <DateContainer>
        <DateLabel>วันที่ทำรายการ</DateLabel>
        <DateValue>{moment(createdAt).xformat('DD MMM YY')}</DateValue>
      </DateContainer>
    </Header>
    <Card onClick={() => navigate(`order/${quotationId}`)}>
      <CompanyLogo
        alt={shortName}
        src={officialLogoUrl || CompanyDefaultLogo}
      />
      <WrapInfo>
        <Info>
          <div>
            <ProductName>{insuranceName}</ProductName>
            <CompanyName>{shortName}</CompanyName>
          </div>
          <Score>
            <BlueShield alt={noonScore} src={Shield} />
            <NoonScore>{noonScore}</NoonScore>
          </Score>
        </Info>
        <Info>
          <Label>เบี้ยประกันต่อปี</Label>
          <Value>
            <span>
              {toCurrency(premium, {
                prefix: '',
              })}
            </span>{' '}
            บาท
          </Value>
        </Info>
        <Status status={state}>{getStatusText(state)}</Status>
      </WrapInfo>
    </Card>
  </Container>
)

export default OrderCard
