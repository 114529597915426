import numeral from 'numeral'

export const toCurrency = (amount, options = {}) => {
  if (amount !== null && amount !== undefined && amount !== '') {
    let {format = '$0,0[.]00', prefix = '฿', precision = null} = options
    if (precision !== null && precision !== undefined) {
      format = format.replace(/\.00$/, `.${'0'.repeat(precision)}`)
    }

    let currency = numeral(amount)
      .format(format)
      .replace('$', prefix)

    if (precision === null || precision === undefined) {
      currency = currency.replace(/\.0*$/, '')
    }

    return currency
  } else {
    return ''
  }
}
