import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import {
  Layout,
  Header,
  HeaderTitle,
  BodyTitle,
  BodyDescription,
  Footer,
  NextButton,
} from '../Step1'
import {SpidusManager, Currency} from '../../components'
import State, {getMaxStep} from '../Step1/State'
import * as paths from '../../common/paths'
import AnswerGroup from '../Step1/AnswerGroup'
import {range} from 'lodash'

export const BackButton = styled(NextButton)`
  background: #ffffff;
  line-height: 1;
  border: 1px solid #ffb933;
  border-radius: 50px;
  color: #ffb933;

  :hover {
    background: #e79a07;
    border: 1px solid #ffb933;
  }
`

export const Space = styled.div`
  width: 25px !important;
  flex: unset !important;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding: 0 10px;

  > :nth-child(4) {
    margin-top: 48px;
  }

  ${AnswerGroup.Body} {
    > div {
      > :first-child {
        flex: 1;
        margin-right: 10px;
      }
      > :last-child {
        flex: unset;
      }
    }
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #ffb933;
  margin-top: 32px;
  margin-bottom: 24px;
`

const MainAnswerGroup = styled(AnswerGroup)`
  ${AnswerGroup.Header} {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Sarabun', sans-serif;
    color: #333333;
  }

  ${AnswerGroup.Body} {
    margin-left: 16px;

    > * {
      margin-top: 16px;
    }
  }
`

const Step2 = props => (
  <SpidusManager configName="noontax_config_web_prepare_tax" enableLoading>
    <Layout>
      <Header>
        <HeaderTitle>เตรียมข้อมูลเพื่อยื่นภาษี</HeaderTitle>
        <State step={2} />
      </Header>
      <Body>
        <BodyTitle>สรุปภาษีประจำปี 2562</BodyTitle>
        <BodyDescription></BodyDescription>
        <AnswerGroup spaceBetweenLine="16px">
          <Currency
            answerName="total_income"
            caption="เงินได้ทั้งหมด"
            readOnly
          />
          <Currency
            answerName="total_expense_and_deduct"
            caption="หัก ค่าใช้จ่ายและค่าลดหย่อน"
            readOnly
          />
          <Currency
            answerName="total_expense"
            caption="- ค่าใช้จ่าย"
            readOnly
          />
          <Currency
            answerName="total_deduct_personal"
            caption="- ค่าลดหย่อนส่วนตัว"
            readOnly
          />
          <Currency
            answerName="total_deduct_elig_65up"
            caption="- ค่าลดหย่อนผู้สูงอายุและผู้พิการ"
            readOnly
          />
          <MainAnswerGroup answerName="total_deduct_spouse" caption="- คู่สมรส">
            <Currency
              answerName="total_deduct_spouse_used"
              caption="- ลดหย่อนคู่สมรส"
              readOnly
            />
            <Currency
              answerName="total_deduct_spouse_handicap"
              caption="- คู่สมรสพิการ/ทุพพลภาพ"
              readOnly
            />
          </MainAnswerGroup>
          {range(1, 11).map(i => (
            <MainAnswerGroup
              key={i}
              answerName={`total_deduct_child_${i}`}
              caption={`- ลูกคนที่ ${i}`}>
              <Currency
                answerName={`total_deduct_child_${i}_used`}
                caption="- ลดหย่อนลูก"
                readOnly
              />
              <Currency
                answerName={`total_deduct_child_${i}_handicap`}
                caption="- ลูกพิการ/ทุพพลภาพ"
                readOnly
              />
            </MainAnswerGroup>
          ))}
          <MainAnswerGroup answerName="total_deduct_dad" caption="- พ่อ">
            <Currency
              answerName="total_deduct_dad_used"
              caption="- ลดหย่อนพ่อ"
              readOnly
            />
            <Currency
              answerName="total_deduct_dad_handicap"
              caption="- พ่อพิการ/ทุพพลภาพ"
              readOnly
            />
          </MainAnswerGroup>
          <MainAnswerGroup answerName="total_deduct_mom" caption="- แม่">
            <Currency
              answerName="total_deduct_mom_used"
              caption="- ลดหย่อนแม่"
              readOnly
            />
            <Currency
              answerName="total_deduct_mom_handicap"
              caption="- แม่พิการ/ทุพพลภาพ"
              readOnly
            />
          </MainAnswerGroup>
          <MainAnswerGroup
            answerName="total_deduct_sdad"
            caption="- พ่อคู่สมรส">
            <Currency
              answerName="total_deduct_sdad_used"
              caption="- ลดหย่อนพ่อคู่สมรส"
              readOnly
            />
            <Currency
              answerName="total_deduct_sdad_handicap"
              caption="- พ่อคู่สมรสพิการ/ทุพพลภาพ"
              readOnly
            />
          </MainAnswerGroup>
          <MainAnswerGroup
            answerName="total_deduct_smom"
            caption="- แม่คู่สมรส">
            <Currency
              answerName="total_deduct_smom_used"
              caption="- ลดหย่อนแม่คู่สมรส"
              readOnly
            />
            <Currency
              answerName="total_deduct_smom_handicap"
              caption="- แม่คู่สมรสพิการ/ทุพพลภาพ"
              readOnly
            />
          </MainAnswerGroup>
          <Currency
            answerName="total_deduct_disabled"
            caption="- อุปการะผู้พิการ/ทุพพลภาพ"
            readOnly
          />
          <Currency
            answerName="total_deduct_pregnancy"
            caption="- ค่าฝากครรภ์และทำคลอด"
            readOnly
          />
          <Currency
            answerName="total_deduct_life_insurance"
            caption="- เบี้ยประกันชีวิตตนเอง"
            readOnly
          />
          <Currency
            answerName="total_deduct_annuity_insurance"
            caption="- เบี้ยประกันชีวิตแบบบำนาญตนเอง"
            readOnly
          />
          <Currency
            answerName="total_deduct_health_insurance"
            caption="- เบี้ยประกันสุขภาพตนเอง"
            readOnly
          />
          <MainAnswerGroup
            answerName="total_deduct_parent_health_insurance"
            caption="- เบี้ยประกันสุขภาพพ่อแม่">
            <Currency
              answerName="total_deduct_parent_health_insurance_dad"
              caption="- เบี้ยประกันสุขภาพพ่อ"
              readOnly
            />
            <Currency
              answerName="total_deduct_parent_health_insurance_mom"
              caption="- เบี้ยประกันสุขภาพแม่"
              readOnly
            />
            <Currency
              answerName="total_deduct_parent_health_insurance_sdad"
              caption="- เบี้ยประกันสุขภาพพ่อคู่สมรส"
              readOnly
            />
            <Currency
              answerName="total_deduct_parent_health_insurance_smom"
              caption="- เบี้ยประกันสุขภาพแม่คู่สมรส"
              readOnly
            />
          </MainAnswerGroup>
          <Currency
            answerName="total_deduct_ltf"
            caption="- กองทุนรวม LTF"
            readOnly
          />
          <Currency
            answerName="total_deduct_rmf"
            caption="- กองทุนรวม RMF"
            readOnly
          />
          <Currency
            answerName="total_deduct_sso"
            caption="- เงินสมทบกองทุนประกันสังคม"
            readOnly
          />
          <Currency
            answerName="total_deduct_official_fund"
            caption="- เงินสะสม กบข."
            readOnly
          />
          <Currency
            answerName="total_deduct_teacher_fund"
            caption="- เงินสะสมกองทุนครูเอกชน"
            readOnly
          />
          <Currency
            answerName="total_deduct_provident_fund"
            caption="- เงินสะสมกองทุนสำรองเลี้ยงชีพ"
            readOnly
          />
          <Currency
            answerName="total_deduct_nsf"
            caption="- เงินสะสมกองทุนการออมแห่งชาติ"
            readOnly
          />
          <Currency
            answerName="total_deduct_party_donate"
            caption="- เงินบริจาคพรรคการเมือง"
            readOnly
          />
          <Currency
            answerName="total_deduct_special_donate"
            caption="- เงินบริจาคสนับสนุนการศึกษา/กีฬา"
            readOnly
          />
          <Currency
            answerName="total_deduct_general_donate"
            caption="- เงินบริจาคอื่น"
            readOnly
          />
          <Currency
            answerName="total_deduct_flood_damage_house_1"
            caption="- ค่าซ่อมบ้าน (3 ม.ค. - 31 มี.ค. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_flood_damage_house_2"
            caption="- ค่าซ่อมบ้าน (29 ส.ค. - 30 พ.ย. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_flood_damage_car_1"
            caption="- ค่าซ่อมรถ (3 ม.ค. - 31 มี.ค. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_flood_damage_car_2"
            caption="- ค่าซ่อมรถ (29 ส.ค. - 30 พ.ย. 62)"
            readOnly
          />
          <MainAnswerGroup
            answerName="total_deduct_shopping"
            caption="- ช้อปช่วยชาติ (1 ม.ค. - 16 ม.ค. 62)">
            <Currency
              answerName="total_deduct_shopping_tire"
              caption="- ยางรถยนต์"
              readOnly
            />
            <Currency
              answerName="total_deduct_shopping_ebook"
              caption="- หนังสือและ e-book"
              readOnly
            />
            <Currency
              answerName="total_deduct_shopping_otop"
              caption="- สินค้า OTOP"
              readOnly
            />
          </MainAnswerGroup>
          <Currency
            answerName="total_deduct_buy_sport_and_edu"
            caption="- ค่าซื้อสินค้าการศึกษาและอุปกรณ์กีฬา (30 เม.ย. - 30 มิ.ย. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_buy_otop"
            caption="- ค่าซื้อสินค้า OTOP (30 เม.ย. - 30 มิ.ย. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_buy_ebook"
            caption="- ค่าซื้อหนังสือและ e-book"
            readOnly
          />
          <Currency
            answerName="total_deduct_travel_primary"
            caption="- เที่ยวเมืองหลัก (30 เม.ย. - 30 มิ.ย. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_travel_secondary"
            caption="- เที่ยวเมืองรอง (30 เม.ย. - 30 มิ.ย. 62)"
            readOnly
          />
          <Currency
            answerName="total_deduct_homeloan_interest"
            caption="- ดอกเบี้ยกู้ยืมเพื่อที่อยู่อาศัย"
            readOnly
          />
          <Currency
            answerName="total_deduct_first_house"
            caption="- ค่าซื้อบ้านหลังแรก"
            readOnly
          />
          <Currency
            answerName="total_deduct_startup"
            caption="- เงินลงทุนธุรกิจ startup"
            readOnly
          />
          <Line />
          <Currency
            answerName="total_income_net"
            caption="เงินได้สุทธิ"
            readOnly
          />
          <Line />
          <Currency
            answerName="total_tax"
            caption="คำนวณภาษีจากเงินได้สุทธิ"
            readOnly
          />
          <Currency
            answerName="total_first_house_credit"
            caption="หัก ภาษีเงินได้จากบ้านหลังแรก"
            readOnly
          />
          <Currency
            answerName="total_wht"
            caption="หัก ภาษีเงินได้หัก ณ ที่จ่าย"
            readOnly
          />
          <Line />
          <Currency
            answerName="total_tax_net"
            caption={props =>
              props.value <= 0 ? 'ภาษีที่ต้องจ่ายเพิ่ม' : 'ได้เงินคืนภาษี'
            }
            style={props => ({color: props.value <= 0 ? '#ff0034' : '#9fd134'})}
            absolute
            readOnly
          />
        </AnswerGroup>
      </Body>
      <Footer>
        <BackButton
          to={paths.prepareTaxStep1Path()}
          onClick={() =>
            dataLayer.push({event: 'back_to_personal_income_deduct'})
          }>
          กลับ
        </BackButton>
        <Space />
        <NextButton
          to={paths.prepareTaxStep3Path()}
          onClick={() =>
            dataLayer.push({event: 'confirm_personal_tax_summary'})
          }>
          ต่อไป
        </NextButton>
      </Footer>
    </Layout>
  </SpidusManager>
)

const enhancer = compose()

export default enhancer(Step2)
