import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import styled from 'react-emotion'
import {reduxForm} from 'redux-form'

import CustomField from '../../share/Field'
import {addComment} from '../../../ducks/noonSquare'
import {isBlank} from '../../../core/helper'

const CommentBox = styled(CustomField)`
  height: unset;
  textarea {
    width: 100%;
    height: 130px;
    text-align: left;
  }
`

const Comment = ({id, addComment, reset}) => (
  <CommentBox
    onEnter={comment =>
      !isBlank(comment) && addComment(id, comment, () => reset())
    }
    name="comment"
    placeholder="แสดงความคิดเห็น..."
    type="autotextarea"
    autoTextarea={{minRows: 4}}
  />
)

const enhancer = compose(
  connect(
    state => ({
      id: state.noonSquare.article.article.id || '',
    }),
    {addComment},
  ),
  reduxForm({
    form: 'comment',
  }),
)

export default enhancer(Comment)
