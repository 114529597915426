import {inRange, numberParser, displayNumber} from '../../../../core/helper'

export const PERSONAL_FIELDS = [
  {
    name: 'age',
    label: 'อายุ (ปี)',
    type: 'number',
    parse: numberParser,
    normalize: inRange(0, 99),
  },
  {
    name: 'retireAge',
    label: 'อายุเกษียณ (ปี)',
    type: 'number',
    parse: numberParser,
    normalize: inRange(0, 99),
  },
]

export const INCOME_FIELDS = [
  {
    name: 'salary',
    label: 'รายได้ต่อเดือน (บาท)',
    placeholder: 'กรุณากรอกข้อมูล',
    format: displayNumber,
    parse: numberParser,
  },
  {
    name: 'revenueGrowth',
    label: 'อัตราการเพิ่มของรายได้/ปี (%)',
    placeholder: 'กรุณากรอกข้อมูล',
    format: displayNumber,
    parse: numberParser,
  },
  {
    name: 'expense',
    label: 'ค่าใช้จ่ายส่วนตัวรายเดือน (บาท)',
    placeholder: 'กรุณากรอกข้อมูล',
    format: displayNumber,
    parse: numberParser,
  },
  {
    name: 'investmentRisk',
    label: 'ระดับความเสี่ยงในการลงทุนที่รับได้',
    placeholder: 'กรุณาเลือก',
    type: 'selector',
    options: [],
  },
]

export const HISTORY_FIELDS = [
  {
    name: 'hasSocialSecurity',
    label: 'สิทธิการรักษาพยาบาลประกันสังคม',
    placeholder: 'กรุณาเลือก',
    type: 'selector',
    options: [],
  },
  {
    name: 'providentFund',
    label: `อัตราเงินสะสมกองทุนสำรองเลี้ยงชีพ (%)`,
    placeholder: 'กรุณากรอกข้อมูล',
    format: displayNumber,
    parse: numberParser,
  },
  {
    name: 'hospitalId',
    label: 'โรงพยาบาลที่รักษา',
    placeholder: 'กรุณาเลือก',
    type: 'selector',
    options: [],
  },
  {
    name: 'injuredFrequency',
    label: `ประวัติการเจ็บป่วยโดยไม่เข้าโรงพยาบาล (ครั้ง/ปี)`,
    placeholder: 'กรุณากรอกข้อมูล',
    format: displayNumber,
    parse: numberParser,
  },
]

export const TOPIC = [
  ...PERSONAL_FIELDS,
  ...INCOME_FIELDS,
  ...HISTORY_FIELDS,
].map(field => ({key: field.name, label: field.label}))
