import React from 'react'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '../../share/Button'
import {displayNumber} from '../../../core/helper'
import {compose, lifecycle, withState} from 'recompose'
import {connect} from 'react-redux'
import {getPort, deletePort} from '../../../ducks/profile'
import {responsive} from '../../../core/style'

import InsurancePortModal from './InsurancePortModal'

const EditIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  cursor: pointer;
`

const ContainerAllDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 604px;
  height: auto;

  @media screen and (max-width: 900px) {
    width: 515px;
  }
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const ContainerAllDetailMobile = styled.div`
  display: none;

  ${props =>
    responsive.mobile(css`
      display: flex;
      width: 320px;
      height: 190px;
      flex-direction: column;
      align-items: center;
      width: 604px;
      height: auto;
    `)};
`

const ContainerPortDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 150px;
  border-radius: 3px;
  background-color: white;
  font-size: 12px;
  margin: 15px 0px 0px 0px;

  padding: 13px 15px;

  @media screen and (max-width: 900px) {
    width: 470px;
  }

  ${props =>
    responsive.mobile(css`
      width: 320px;
      height: 190px;
    `)};
`

const LogoImage = styled.img`
  width: 30px;
  height: 30px;

  border-radius: 10%;
  object-fit: cover;
`
const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const NameCompany = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: Prompt;
  text-align: center;

  margin-top: 10px;
  display: flex;
  align-self: flex-end;
`

const TextHead = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  font-family: Prompt;
`

const TextNormal = styled.div`
  font-size: 12px;

  text-align: center;
`

const TextSub = styled.div`
  font-size: 12px;

  text-align: center;

  ${props =>
    responsive.mobile(css`
      margin-top: 10px;
    `)};
`

const TextBigRight = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: flex-end;

  text-align: center;

  width: 100px;
`
const TextBigBoldRight = styled.div`
  font-size: 12px;
  display: flex;
  font-family: Prompt;
  justify-content: flex-end;
  font-weight: bold;
  text-align: center;

  width: 100px;
`

const PaymentDateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`

const PremiunDetailContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`
const AnnualpremiumContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: -7px;

  ${props =>
    responsive.mobile(css`
      margin-top: 10px;
      width: 100%;
      justify-content: space-between;
    `)};
`
const TextOwner = styled.div`
  size: 8px;
`
const ContainerTextOwnerAndIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 210px;
`

const Coverage = ({portData, deletePort, isShowModal, setIsShowModal}) => (
  <>
    <ContainerAllDetail>
      <ContainerPortDetail>
        <Description>
          <LogoImage src={portData.insuranceCompanyLogo} />
          <ContainerTextOwnerAndIcon>
            <TextOwner>เจ้าของกรมธรรม์: {portData.name}</TextOwner>
            <EditIcon
              icon={['far', 'edit']}
              onClick={() => setIsShowModal(true)}
            />
            <EditIcon
              icon={['far', 'trash-alt']}
              onClick={() => {
                deletePort(portData.id)
              }}
            />
          </ContainerTextOwnerAndIcon>
        </Description>
        <Description>
          <NameCompany>MTL</NameCompany>
          <PaymentDateContainer>
            <TextHead>วันที่ต้องจ่ายในครั้งถัดไป</TextHead>
            <TextBigRight>01/01/2561</TextBigRight>
          </PaymentDateContainer>
        </Description>
        <Description>
          <TextSub>เมืองไทยประกันชีวิต</TextSub>
        </Description>
        <Description>
          <div></div>
          <AnnualpremiumContainer>
            <TextHead>เบี้ยรวม รายปี</TextHead>
            <TextBigBoldRight>
              {displayNumber(portData.sumInsured)} บาท
            </TextBigBoldRight>
          </AnnualpremiumContainer>
        </Description>
        <Description>
          <TextHead>ตลอดชีพ</TextHead>
          <PremiunDetailContainer>
            <TextNormal>ประกัน</TextNormal>
            <TextBigRight>{displayNumber(portData.premium)} บาท</TextBigRight>
          </PremiunDetailContainer>
        </Description>
        <Description>
          <TextNormal>{displayNumber(5000000)} บาท</TextNormal>
          <PremiunDetailContainer>
            <TextNormal>อนุสัญญา</TextNormal>
            <TextBigRight>{displayNumber(10000)} บาท</TextBigRight>
          </PremiunDetailContainer>
        </Description>{' '}
      </ContainerPortDetail>
    </ContainerAllDetail>

    <ContainerAllDetailMobile>
      <ContainerPortDetail>
        <Description>
          <LogoImage />
          <ContainerTextOwnerAndIcon>
            <TextOwner>เจ้าของกรมธรรม์: {portData.name}</TextOwner>
            <EditIcon
              icon={['far', 'edit']}
              onClick={() => setIsShowModal(true)}
            />
            <EditIcon icon={['far', 'trash-alt']} />
          </ContainerTextOwnerAndIcon>
        </Description>
        <Description>
          <NameCompany>MTL</NameCompany>
          <TextSub>เมืองไทยประกันชีวิต</TextSub>
        </Description>
        <Description>
          <TextHead>ตลอดชีพ</TextHead>
          <TextNormal>{displayNumber(5000000)} บาท</TextNormal>
        </Description>
        <Description>
          <TextHead>วันที่ต้องจ่ายในครั้งถัดไป</TextHead>
          <TextBigRight>01/01/2561</TextBigRight>
        </Description>
        <Description>
          <AnnualpremiumContainer>
            <TextHead>เบี้ยรวม รายปี</TextHead>
            <TextBigBoldRight>
              {displayNumber(portData.sumInsured)} บาท
            </TextBigBoldRight>
          </AnnualpremiumContainer>
        </Description>
        <Description>
          {' '}
          <TextNormal>ประกัน</TextNormal>
          <TextBigRight>{displayNumber(portData.premium)} บาท</TextBigRight>
        </Description>
        <Description>
          <TextNormal>อนุสัญญา</TextNormal>
          <TextBigRight>{displayNumber(10000)} บาท</TextBigRight>
        </Description>{' '}
      </ContainerPortDetail>
    </ContainerAllDetailMobile>
    <InsurancePortModal
      isShowModal={isShowModal}
      setIsShowModal={setIsShowModal}
      id={portData.id}
      portData={portData}
    />
  </>
)

const enhancer = compose(
  withState('isShowModal', 'setIsShowModal', false),
  connect(
    state => ({}),
    {getPort, deletePort},
  ),
  lifecycle({
    componentDidMount() {
      const {getPort} = this.props
      getPort()
    },
  }),
)

export default enhancer(Coverage)
