import React from 'react'
import {compose, withProps, withState, withHandlers} from 'recompose'
import styled, {css} from 'react-emotion'
import successImage from '../../assets/images/success.svg'
import overviewImage from '../../assets/images/overview.svg'
import rdSmartTaxAppImage from '../../assets/images/rd-smart-tax-app.png'

import {Icon, TextInput, Button} from '../../components'
import {Line} from '../Step1'
import Clipboard from 'react-clipboard.js'
import {withRouter} from '../../enhances'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const RdCodeLayout = styled.div`
  display: flex;
  width: 100%;
  max-width: 650px;
  padding: 35px 20px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    padding: 16px 20px 47px 20px;
  }
`

const Left = styled.div``

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessImage = styled.img`
  height: 390px;

  @media screen and (max-width: 550px) {
    height: 150px;
  }
`

const SuccessMesssageLayout = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 550px) {
    margin-top: 20px;
  }
`

const SuccessMessageIcon = styled(Icon)`
  font-size: 20px;
  color: #9fd134;
`

const SuccessMessageText = styled.div`
  font-size: 20px;
  color: #333333;
  margin-left: 20px;

  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
`

const CodeLayout = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 550px) {
    margin-top: 30px;
  }
`

const CodeTitle = styled.div`
  font-size: 30px;
  color: #ffb933;

  @media screen and (max-width: 550px) {
    font-size: 20px;
  }
`

const CodeMessageLayout = styled.div`
  display: flex;
  align-items: center;
`

const CodeMessage = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #ffb933;
  height: 61px;
`

const CopyButton = styled(Clipboard)`
  margin-left: 22px;
  border: none;
  background: none;
  padding: 0;
`

const CopyIcon = styled(Icon)`
  font-size: 30px;
  color: #ffb933;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`

const SendEmailLayout = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;

  > div:last-child {
    max-width: 250px !important;
    width: 100% !important;

    > button {
      min-width: 100%;
    }
  }
`

const SendEmailTitle = styled.div`
  font-size: 16px;
  color: #333333;
  text-align: center;
`

const SendEmailInput = styled(TextInput)`
  margin-top: 14px;
  width: 250px;
  height: 40px;
  border-radius: 5px;

  input {
    text-align: center !important;
  }
`

const SendEmailButton = styled(Button)`
  margin-top: 20px;
  width: 250px;
  height: 38px;
  background: #ffb933;
  border-radius: 19px;
`

const RdInstructionLayout = styled.div``

const RdInstructionCard = styled.div`
  max-width: 650px;
  min-width: 300px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 70px 10px 123px 10px;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RdInstructionIconLayout = styled.div`
  position: relative;
`

const RdInstructionIcon = styled(Icon)`
  font-size: 100px;
`

const RdInstructionSymbol = styled(Icon)`
  position: absolute;
  top: 30px;
  left: 35px;
  font-size: 40px;
`

const RdInstructionTitle = styled.div`
  margin-top: 6px;
  font-size: 30px;
  color: #333333;
  text-align: center;
`

const RdInstructionDescription = styled.div`
  font-size: 16px;
  color: #333333;
  font-family: 'Sarabun', sans-serif;
  text-align: center;
  max-width: 550px;
`

const RdInstructionOverviewImage = styled.img`
  max-width: 484px;
  margin-top: 45px;
  width: 100%;
`

const SuggestRdAppTitle = styled.div`
  font-size: 16px;
  color: #333333;
  font-family: 'Sarabun', sans-serif;
  margin-top: 70px;
`

const RdSmartTaxAppImage = styled.img`
  margin-top: 14px;
  max-width: 210px;
  cursor: pointer;
`

const Notification = styled.div`
  color: white;
  font-size: 16px;
  background: #9fd134;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: absolute;
  top: 0px;

  @media screen and (min-width: 469px) {
    position: fixed;
    top: 80px;
  }

  z-index: 40;
  font-weight: 400;
  overflow: hidden;
  height: 50px;
  ${props =>
    props.visibility
      ? css`
          transition: all 0.3s ease-in-out;
          opacity: 0.8;
        `
      : css`
          transition: all 0.5s ease-in-out;
          opacity: 0;
        `}
`

const Success = props => (
  <Layout>
    <Notification visibility={props.notificationVisibility}>
      คัดลอกสำเร็จ!
    </Notification>
    <RdCodeLayout>
      <Left>
        <SuccessImage src={successImage} />
      </Left>
      <Right>
        <SuccessMesssageLayout>
          <SuccessMessageIcon icon="check-circle" />
          <SuccessMessageText>เตรียมยื่นแบบภาษีสำเร็จ</SuccessMessageText>
        </SuccessMesssageLayout>
        <CodeLayout>
          <CodeTitle>รหัสความปลอดภัย 6 หลัก</CodeTitle>
          <CodeMessageLayout>
            <CodeMessage>{`${props.code}`.split('').join(' ')}</CodeMessage>
            <CopyButton data-clipboard-text={props.code}>
              <CopyIcon onClick={props.clickCopy} icon={['far', 'copy']} />
            </CopyButton>
          </CodeMessageLayout>
        </CodeLayout>
        <SendEmailLayout>
          <SendEmailTitle>
            ระบุอีเมลของคุณ สำหรับเก็บข้อมูลรหัสความปลอดภัย
          </SendEmailTitle>
          <SendEmailInput placeholder="อีเมล" />
          <SendEmailButton>ส่ง</SendEmailButton>
        </SendEmailLayout>
      </Right>
    </RdCodeLayout>
    <Line />
    <RdInstructionLayout>
      <RdInstructionCard>
        <RdInstructionIconLayout>
          <RdInstructionIcon icon="comment" color="#6A94CB" />
          <RdInstructionSymbol icon="question" color="white" />
        </RdInstructionIconLayout>
        <RdInstructionTitle>
          เตรียมยื่นแบบภาษีแล้ว ต้องทำอะไรต่อ?
        </RdInstructionTitle>
        <RdInstructionDescription>
          นำรหัสความปลอดภัย 6 หลักไปกรอกใส่แอพพลิเคชั่น RD Smart Tax
          ของกรมสรรพากร
          เพื่อตรวจสอบความถูกต้องและดูว่าปีนี้คุณต้องเสียภาษีหรือไม่
        </RdInstructionDescription>
        <RdInstructionOverviewImage src={overviewImage} />
        <SuggestRdAppTitle>
          ใครยังไม่มีแอพฯ ดาวน์โหลดได้ที่นี่เลย
        </SuggestRdAppTitle>
        <a href="http://onelink.to/b2vfb9" target="_blank">
          <RdSmartTaxAppImage
            onClick={() => dataLayer.push({event: 'goto_rd_smart_tax_app'})}
            src={rdSmartTaxAppImage}
          />
        </a>
      </RdInstructionCard>
    </RdInstructionLayout>
  </Layout>
)

const enhancer = compose(
  withRouter(),
  withProps(props => ({code: props.match.params.code})),
  withState('notificationVisibility', 'setNotificationVisibility', false),
  withState('notificationTask', 'setNotificationTask', null),
  withHandlers({
    clickCopy: props => () => {
      dataLayer.push({event: 'copy_tax_filing_security_code'})

      props.setNotificationVisibility(true)

      if (props.notificationTask) {
        clearTimeout(props.notificationTask)
      }

      props.setNotificationTask(
        setTimeout(() => {
          props.setNotificationVisibility(false)
        }, 3000),
      )
    },
  }),
)

export default enhancer(Success)
