import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Favorite from '../Favorite'
import BuyCatalogue from '../BuyCatalogue'
import {displayNumber} from '../../../../../core/helper'
import {responsive} from '../../../../../core/style'
import {
  ContainerInfo,
  ContainerInfoMobile,
  ContainerDetailIcon,
  CompanyLogo,
  InfoIcon,
} from '../TopInfo'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: row;
`
const StarContainer = styled.div`
  display: flex;
`
const StarIcon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: #ffd900;
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: #333333;
  font-size: 40px;
  width: 40px;
  height: 40px;
`

const SmallText = styled.div`
  font-size: 12px;
  margin: 10px 0;
`

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
`

const Hashtag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 25px;
  padding: 5px 15px 5px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #e5e5e5;
  font-size: 12px;
  color: black;
`

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  color: ${props => (props.isBenefit ? props.theme.GREEN : 'red')};

  ${props =>
    responsive.mobile(css`
      margin-right: 15px;
    `)};
`

const ContainerFavorite = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const ContainerSecond = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 45px;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      margin-top: 35px;
      width: 290px;
    `)};
`

const ContainerThird = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 117px;
  background-color: rgba(106, 148, 203, 0.1);
  margin-top: 44px;
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
      height: 226px;
    `)};
`
const HeaderThird = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 91px;

  ${props =>
    responsive.tablet(css`
      margin-left: 30px;
    `)};

  ${props =>
    responsive.mobile(css`
      flex-direction: row;
      margin-left: 0;
      margin-top: 15px;
    `)};
`
const CheckIconInfo = styled.div`
  font-size: 16px;
  color: ${props => (props.isBenefit ? props.theme.GREEN : 'red')};
  margin-top: 15px;

  ${props =>
    responsive.mobile(css`
      margin-top: 0;
    `)};
`
const ContainerIconMobile = styled.div`
  display: flex;
  ${props =>
    responsive.mobile(css`
      justify-content: space-between;
      margin-top: 10px;
    `)};
`

const ContainerBenefitInfo = styled.div`
  display: flex;
  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const IconList = ({item, category}) => {
  switch (category) {
    case 'life':
      return (
        <React.Fragment>
          <ContainerIconMobile>
            <ContainerDetailIcon key={'shield-alt'}>
              <CustomIcon icon={['fas', 'shield-alt']} />
              <InfoIcon
                dangerouslySetInnerHTML={dangerText(
                  `${(item.protectionPeriodText || {}).protectPeriodYear ||
                    ''}<br/>${(item.protectionPeriodText || {})
                    .protectPeriodAge || ''}`,
                )}
              />
            </ContainerDetailIcon>
            <ContainerDetailIcon key={'hand-holding-usd'}>
              <CustomIcon icon={['fas', 'hand-holding-usd']} />
              <InfoIcon
                dangerouslySetInnerHTML={dangerText(
                  `ได้รับเมื่อเสียชีวิต<br/>เริ่มต้นที่ ${displayNumber(
                    item.firstYearSumInsured,
                  )} บาท`,
                )}
              />
            </ContainerDetailIcon>
          </ContainerIconMobile>
          <ContainerIconMobile>
            <ContainerDetailIcon key={'money-bag'}>
              <CustomIcon icon={['fas', 'money-bag']} />
              <InfoIcon
                dangerouslySetInnerHTML={dangerText(
                  `${item.payPeriodText}<br/>เบี้ยปีละ ${displayNumber(
                    parseInt(item.premium),
                  )} บาท`,
                )}
              />
            </ContainerDetailIcon>
            <ContainerDetailIcon key={'exchange-alt'} isLast>
              <CustomIcon icon={['fas', 'exchange-alt']} />
              <InfoIcon
                dangerouslySetInnerHTML={dangerText(
                  `เงินคืนระหว่างสัญญา/ปี<br/>${
                    item.annuityRefundPerYear === null
                      ? 'ไม่มี'
                      : displayNumber(parseInt(item.annuityRefundPerYear))
                  }`,
                )}
              />
            </ContainerDetailIcon>
          </ContainerIconMobile>
        </React.Fragment>
      )

    default:
      return null
  }
}

const renderStar = noonStar => {
  const arrayStar = []
  for (var i = 1; i <= noonStar; i++) {
    arrayStar.push(<StarIcon key={i} icon={['fas', 'star']} />)
  }
  return arrayStar
}

const Benefitinfo = ({item}) => (
  <ContainerBenefitInfo>
    <CheckContainer>
      <CheckIcon
        isBenefit={item.hasRefund}
        icon={
          item.hasRefund ? ['fas', 'check-circle'] : ['fas', 'times-circle']
        }
      />
      <CheckIconInfo isBenefit={item.hasRefund}>
        เงินคืนเมื่อครบสัญญา
      </CheckIconInfo>
    </CheckContainer>
    <CheckContainer>
      <CheckIcon
        isBenefit={item.hasDividend}
        icon={
          item.hasDividend ? ['fas', 'check-circle'] : ['fas', 'times-circle']
        }
      />
      <CheckIconInfo isBenefit={item.hasDividend}>เงินปันผล</CheckIconInfo>
    </CheckContainer>
    <CheckContainer>
      <CheckIcon
        isBenefit={item.isTaxSaving}
        icon={
          item.isTaxSaving ? ['fas', 'check-circle'] : ['fas', 'times-circle']
        }
      />
      <CheckIconInfo isBenefit={item.isTaxSaving}>
        สิทธิในการลดหย่อนภาษี
      </CheckIconInfo>
    </CheckContainer>
  </ContainerBenefitInfo>
)

function dangerText(value) {
  return {__html: value}
}

const DetailTop = ({
  item = [],
  params,
  category,
  paramsEncode,
  id,
  favorite,
  isLoading,
}) => (
  <Container>
    <ContainerDetail>
      <CompanyLogo src={item.companyOfficialLogoUrl} />
      <ContainerInfo>
        <Header>{item.productName}</Header>
        <SmallText>{item.companyName}</SmallText>
        <StarContainer>{renderStar(item.noonStar)}</StarContainer>
        {/* <HashtagContainer>
            {item.hashTag.map(value => (
              <Hashtag key={value.name}>{value.name}</Hashtag>
            ))}
          </HashtagContainer> */}
      </ContainerInfo>
      <ContainerFavorite>
        {!isLoading && (
          <Favorite
            category={category}
            id={id}
            paramsEncode={paramsEncode}
            favorite={item.favorite}
          />
        )}
        <BuyCatalogue
          params={params}
          category={category}
          productName={item.productName}
        />
      </ContainerFavorite>
    </ContainerDetail>
    <ContainerInfoMobile>
      <Header>{item.productName}</Header>
      <SmallText>{item.companyName}</SmallText>
      <StarContainer>{renderStar(item.noonStar)}</StarContainer>
      {/* <HashtagContainer>
            {item.hashTag.map(value => (
              <Hashtag key={value.name}>{value.name}</Hashtag>
            ))}
          </HashtagContainer> */}
    </ContainerInfoMobile>
    <ContainerSecond>
      <IconList category={category} item={item} />
    </ContainerSecond>
    <ContainerThird>
      <HeaderThird>ผลประโยชน์จากกรมธรรม์</HeaderThird>
      <Benefitinfo item={item} />
    </ContainerThird>
    <br />
    <br />
    <br />
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({}),
    {},
  ),
)

export default enhancer(DetailTop)
