import React, {useState, useMemo} from 'react'

import CollapseField from './CollapseField'
import CustomRadios from './CustomRadios'
import {TitleSideBar, GroupSideBar} from './StyleComponents'

export default props => {
  const [collapseActive, setCollapseActive] = useState(false)
  const options = useMemo(
    () => [
      {
        label: 'จ่ายปันผล',
        value: 'true',
      },
      {
        label: 'ไม่จ่ายปันผล',
        value: 'false',
      },
    ],
    [],
  )
  const hasDividendFieldName = 'hasDividend'
  const {formId} = props
  return (
    <GroupSideBar>
      <TitleSideBar>ปันผล</TitleSideBar>
      <CustomRadios
        name={hasDividendFieldName}
        type="radios"
        formId={formId}
        options={options}
        collapseActive={collapseActive}
      />
    </GroupSideBar>
  )
}
