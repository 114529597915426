import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import TimeAgo from 'react-timeago'

import {responsive} from '../../core/style'
import {obj2Url} from '../../core/helper'
import notificationLogo from '../../static/images/notification-default.jpg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #4375b5;
  min-height: 50px;
`
const Header = styled.div`
  font-size: 16px;
  color: white;
  opacity: ${props => (props.favariteState ? '1' : '0.5')};
  font-weight: ${props => (props.favariteState ? '600' : '400')};
  width: 100%;
  text-align: center;
  cursor: pointer;
  :hover {
    opacity: 1;
    font-weight: bold;
  }

  ${props =>
    responsive.mobile(css`
      font-size: 12px;
    `)};
`
const FavoriteArticlesContainer = styled.div`
  padding: 0 12px 0 29px;
  display: flex;
  width: 100%;
  align-items: center;
  height: 60px;

  ${props =>
    responsive.mobile(css`
      padding: 0 12px 0 10px;
    `)};
`
const FavoriteArticlesIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 18.5px;

  ${props =>
    responsive.mobile(css`
      margin-left: 12px;
    `)};
`
const FavoriteArticlesContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
`
const FavoriteArticlesContentTopic = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 425) {
    width: 260px;
  }
  @media (max-width: 375px) {
    width: 200px;
  }
  @media (max-width: 320px) {
    width: 180px;
  }
`
const FavoriteArticlesContentWriter = styled.div`
  font-size: 8px;
`
const FavoriteArticlesContentTimes = styled(TimeAgo)`
  font-size: 8px;
`
const FavoriteIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: #ffffff;
`
const CustomLayout = styled.div`
  display: flex;
  align-items: center;
`
const CustomContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const AgeLine = styled.div`
  font-size: 8px;
  margin: 0 3px;
`
const CustomAllLayout = styled.div`
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 120px);
`
const FavoriteInsurancePoliciesContainer = styled.div`
  padding: 0 20px;
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 60px;

  ${props =>
    responsive.mobile(css`
      padding: 0 12px 0 10px;
    `)};
`
const FavoriteInsurancePoliciesContainerLink = styled.a`
  display: flex;
  width: 100%;

  align-items: flex-start;

  ${props => responsive.mobile(css``)};
`
const FavoriteInsurancePoliciesIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 18.5px;

  ${props =>
    responsive.mobile(css`
      margin-left: 12px;
    `)};
`
const FavoriteInsurancePoliciesContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
  padding-right: 5px;
`
const FavoriteInsurancePoliciesContentTopic = styled.div`
  font-size: 12px;
`
const FavoriteInsurancePoliciesContent = styled.div`
  font-size: 8px;
`

const FavoriteArticles = ({articles}) => (
  <CustomContainer>
    {articles.map(value => (
      <FavoriteArticlesContainer
        key={value.id}
        onClick={() => window.open(`/square/details/${value.id}`, '_blank')}>
        <FavoriteArticlesIcon
          src={value.avatarSignatureSynonym || notificationLogo}
        />
        <FavoriteArticlesContentContainer>
          <FavoriteArticlesContentTopic>
            {value.topicName}
          </FavoriteArticlesContentTopic>
          <FavoriteArticlesContentWriter>
            {value.signatureSynonym}
          </FavoriteArticlesContentWriter>
          <FavoriteArticlesContentTimes date={value.updatedAt} />
        </FavoriteArticlesContentContainer>
        <FavoriteIcon icon={['fas', 'heart']} />
      </FavoriteArticlesContainer>
    ))}
  </CustomContainer>
)

const FavoriteInsurancePolicies = ({insurancePolicies}) => (
  <CustomContainer>
    {insurancePolicies.map(value => (
      <FavoriteInsurancePoliciesContainerLink
        key={value.insurancePolicyType}
        href={`/smart/${
          value.insurancePolicyType === 'AutoInsurancePolicy'
            ? 'motor'
            : value.insurancePolicyType === 'LifeInsurancePolicy'
            ? 'life'
            : 'health'
        }/${value.level === undefined ? 'allLevel' : value.level}/product/${
          value.id
        }?${obj2Url({
          // ...params,
          ...JSON.parse(atob(value.paramsEncode)),
          premium: parseInt(value.premium),
          sumInsured: parseInt(value.sumInsured),
          packageId: value.packageId,
          promotionCode: value.promotionCode,
          id: value.id,
          encodeFlag: value.fromFavoriteProfile ? 'true' : 'false',
          paramsEncode: value.paramsEncode,
          vat: value.vat,
          amount: value.amount,
          amountNet: value.amountNet,
          insureLevel: value.insureLevel,
          companyId: value.companyId,
          cover: value.cover,
        })}`}
        target="_blank">
        <FavoriteInsurancePoliciesContainer key={value.id}>
          <FavoriteInsurancePoliciesIcon src={value.companyOfficialLogoUrl} />
          <FavoriteInsurancePoliciesContentContainer>
            <FavoriteInsurancePoliciesContentTopic>
              {value.companyName}
            </FavoriteInsurancePoliciesContentTopic>
            {value.insureLevel && (
              <FavoriteInsurancePoliciesContent>
                ประกันชั้น{' '}
                {value.insureLevel === '20'
                  ? '2+'
                  : value.insureLevel === '30'
                  ? '3+'
                  : value.insureLevel}
              </FavoriteInsurancePoliciesContent>
            )}
            <CustomLayout>
              {value.age && (
                <FavoriteInsurancePoliciesContent>
                  อายุ {value.age} ปี
                </FavoriteInsurancePoliciesContent>
              )}
              {value.age && <AgeLine>|</AgeLine>}
              {value.gender && (
                <FavoriteInsurancePoliciesContent>
                  เพศ {value.gender === 'male' ? 'ชาย' : 'หญิง'}
                </FavoriteInsurancePoliciesContent>
              )}
            </CustomLayout>
          </FavoriteInsurancePoliciesContentContainer>
          <FavoriteIcon icon={['fas', 'heart']} />
        </FavoriteInsurancePoliciesContainer>
      </FavoriteInsurancePoliciesContainerLink>
    ))}
  </CustomContainer>
)

const Favorite = ({
  setFavoriteState,
  favariteState,
  articles,
  insurancePolicies,
}) => (
  <Container>
    <HeaderContainer onClick={e => e.stopPropagation()}>
      <Header
        onClick={() => setFavoriteState('article')}
        favariteState={favariteState === 'article'}>
        บทความ
      </Header>
      <Header
        onClick={() => setFavoriteState('insure')}
        favariteState={favariteState === 'insure'}>
        แบบประกัน
      </Header>
      <Header
        onClick={() => setFavoriteState('all')}
        favariteState={favariteState === 'all'}>
        ดูทั้งหมด
      </Header>
    </HeaderContainer>
    {favariteState === 'article' && (
      <CustomAllLayout>
        <FavoriteArticles articles={articles} />
      </CustomAllLayout>
    )}
    {favariteState === 'insure' && (
      <CustomAllLayout>
        <FavoriteInsurancePolicies insurancePolicies={insurancePolicies} />
      </CustomAllLayout>
    )}
    {favariteState === 'all' && (
      <CustomAllLayout>
        <FavoriteArticles articles={articles} />
        <FavoriteInsurancePolicies insurancePolicies={insurancePolicies} />
      </CustomAllLayout>
    )}
  </Container>
)

const enhancer = compose(
  withState('favariteState', 'setFavoriteState', 'article'),
  connect(state => ({
    articles: state.profile.articles || [],
    insurancePolicies: state.profile.insurancePolicies || [],
  })),
)

export default enhancer(Favorite)
