import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import styled, {css} from 'react-emotion'
import {navigate, Link} from '@reach/router'
import {responsive} from '../../core/style'

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  width: 100%;
  max-width: 915px;

  ${props =>
    responsive.tablet(css`
      position: relative;
      font-size: 12px;
      padding: 0 25px;
      padding-bottom: 35px;
    `)};

  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`
const FooterMenulistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 50px;
  &.loginAndRegister {
    margin-left: auto;
    margin-right: 0;
  }

  ${props =>
    responsive.tablet(css`
      margin-right: 30px;
      &.loginAndRegister {
        position: absolute;
        right: 30px;
        top: 0;
        margin-right: 0;
      }

      &.aboutUs {
        margin-right: 19px;
      }
    `)};
`

const Topic = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
  cursor: pointer;
`

const List = styled.div`
  font-size: 12px;
  cursor: pointer;
  margin: 2.5px 0;
`
const LoginAndRegisterPart = styled.div`
  display: flex;
`

const Line = styled.div`
  margin: 0 5px;
`

const CustomLink = styled(Link)`
  color: white;
  :active,
  :hover {
    color: white;
  }
  :focus {
    color: white;
    text-decoration: none;
  }
`

const FooterMenuList = ({isLogin}) => (
  <Container>
    <FooterMenulistContainer>
      <CustomLink to="/smart">
        <Topic>NOON SMART</Topic>
      </CustomLink>
      <CustomLink to="/smart/InsuranceCategory/life">
        <List>ประกันชีวิต</List>
      </CustomLink>
      <CustomLink to="/smart/InsuranceCategory/health/intermediate">
        <List>ประกันสุขภาพ</List>
      </CustomLink>
      <CustomLink to="/smart/InsuranceCategory/motor">
        <List>ประกันรถยนต์</List>
      </CustomLink>
      {/* <CustomLink to="/tax">
        <List>วางแผนภาษี</List>
      </CustomLink> */}
    </FooterMenulistContainer>
    {/* <FooterMenulistContainer>
      <CustomLink to="/fund">
        <Topic>NOON WEALTH</Topic>
      </CustomLink>
      <CustomLink to="/fund">
        <List>กองทุนรวม</List>
      </CustomLink>
    </FooterMenulistContainer> */}

    <FooterMenulistContainer>
      <CustomLink to="/tax">
        <Topic>NOON TAX</Topic>
      </CustomLink>
      <CustomLink to="/tax">
        <List>วางแผนภาษี</List>
      </CustomLink>
    </FooterMenulistContainer>
    <FooterMenulistContainer>
      <CustomLink to="/square">
        <Topic>NOON SQUARE</Topic>
      </CustomLink>
      <CustomLink to="/square">
        <List>อ่านบทความ</List>
      </CustomLink>
    </FooterMenulistContainer>
    <FooterMenulistContainer>
      <CustomLink to="/lab">
        <Topic>NOON LAB</Topic>
      </CustomLink>
    </FooterMenulistContainer>
    {/* <FooterMenulistContainer className="aboutUs">
      <CustomLink to="/about-us">
        <Topic>ABOUT US</Topic>
      </CustomLink>
      <CustomLink to="/about-us">
        <List>เราคือใคร</List>
      </CustomLink>
      <CustomLink to="/about-us">
        <List>บริการของเรา</List>
      </CustomLink>
      <CustomLink to="/about-us">
        <List>ผลิตภัณฑ์ของเรา</List>
      </CustomLink>
      <CustomLink to="/about-us">
        <List>ผู้สนับสนุนและรางวัลที่ได้รับ</List>
      </CustomLink>
    </FooterMenulistContainer> */}
    <FooterMenulistContainer>
      <CustomLink to="/contact-us">
        <Topic>CONTACT US</Topic>
      </CustomLink>
      {/* <List onClick={() => navigate('/contact-us-salerep')}>
        นายหน้า/ ตัวแทน
      </List> */}
    </FooterMenulistContainer>
    {!isLogin && (
      <FooterMenulistContainer className="loginAndRegister">
        <LoginAndRegisterPart>
          <CustomLink to="/authorize/login">
            <Topic>LOG IN </Topic>
          </CustomLink>
          <Line>|</Line>
          <CustomLink to="/authorize/register">
            <Topic>REGISTER</Topic>
          </CustomLink>
        </LoginAndRegisterPart>
      </FooterMenulistContainer>
    )}
  </Container>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLogin: state.auth.isLogin,
    }),
    {},
  ),
)
export default enhancer(FooterMenuList)
