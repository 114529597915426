import React from 'react'
import styled from 'react-emotion'

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .progress {
    transform: rotate(-90deg);
  }

  .progress__value {
    stroke-dasharray: ${props => props.radius};
    stroke-dashoffset: ${props =>
      props.radius - (props.radius * props.count) / 10};
  }

  @keyframes progress {
    from {
      stroke-dashoffset: ${props => props.radius};
    }
    to {
      stroke-dashoffset: ${props =>
        props.radius - (props.radius * props.count) / 10};
    }
  }
`

const CountScore = styled.div`
  position: absolute;
  font-weight: bold;
  font-size: 26px;
  color: ${props => props.theme.BLUE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TopicNoonScore = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
`
function dangerText(value) {
  return {__html: value}
}

const CircleSvg = ({count, topic, score, radius, className}) => (
  <Circle className={className} count={count} radius={radius}>
    <svg className="progress" width="150" height="150" viewBox="-5 -5 130 130">
      <circle
        cx="60"
        cy="60"
        r="54"
        fill="none"
        stroke="rgba(38, 95, 167, 0.5)"
        strokeWidth="3"
      />
      <circle
        className="progress__value"
        cx="60"
        cy="60"
        r="60"
        fill="none"
        stroke="rgba(38, 95, 167, 1)"
        strokeWidth="3"
      />
    </svg>
    <CountScore className="score">
      {score}
      <TopicNoonScore
        className="topic"
        dangerouslySetInnerHTML={dangerText(topic)}
      />
    </CountScore>
  </Circle>
)

export default CircleSvg
