import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import Landing from './Landing'
import {setDataProduct} from '../../ducks/product'

export default connect(
  state => {},
  {setDataProduct},
)(props => {
  const {setDataProduct} = props

  useEffect(() => {
    setDataProduct({noOpacityHeader: true})
    return () => {
      setDataProduct({noOpacityHeader: false})
    }
  }, [])

  return <Landing {...props} />
})
