import React from 'react'
import {compose, withProps} from 'recompose'
import {Currency, DatePicker, ButtonGroup} from './'
import {omitProps} from '../enhances'

// TODO: Implement for all shared input

const inputComponents = {
  Currency,
  DatePicker,
  ButtonGroup,
}

const DynamicInput = ({InputComponent, ...restOptions}) => (
  <InputComponent {...restOptions} />
)

const enhance = compose(
  withProps(props => ({
    InputComponent: inputComponents[props.type],
  })),
  omitProps('type'),
)

export default enhance(DynamicInput)
