import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withProps, withHandlers, withState} from 'recompose'
import {withField} from '../enhancers/index'
import {displayNumber, numberParser} from '../../../core/helper'
import AutosizeInput from 'react-input-autosize'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  > div {
    font-weight: 600;
  }
`

const GroupField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  max-height: 56px;
  min-height: 56px;
  > svg {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`

const CustomCurrency = css`
  max-width: 250px;
  border: none;
  border-radius: 0;
  font-family: 'Prompt';
  font-weight: 700;

  font-size: 30px;
  background-color: transparent;
  padding: 0;
  > input {
    background-color: transparent;
    border: none;
    padding: 5px 0px;
    :focus {
      border-bottom: 1px solid;
    }
  }
`

const PenCurrency = props => (
  <Layout>
    <GroupField>
      <AutosizeInput
        className={CustomCurrency}
        ref={props.setInputRef}
        {...props}
      />
    </GroupField>
  </Layout>
)

const enhance = compose(
  withField(),
  withState('displayValue', 'setDisplayValue', props =>
    displayNumber(props.value),
  ),
  withHandlers({
    onFocus: props => e => {
      props.setDisplayValue(displayNumber(props.value))
      props.onFocus && props.onFocus(props.value)
    },
    onChange: props => e => {
      let value = e.target.value ? numberParser(e.target.value) : null
      let prevValue = value

      if (value !== null && value !== undefined) {
        if (props.min) {
          value = Math.max(value, props.min)
        }
        if (props.max) {
          value = Math.min(value, props.max)
        }
        if (!value && value !== 0 && value !== '0') {
          value = null
        }
      } else if (props.minValue || props.minValue === 0) {
        value = props.minValue
      }

      props.onChange && props.onChange(value)

      if (prevValue !== value) {
        props.setDisplayValue(value)
      } else if (/^0(\d)$/.test(e.target.value)) {
        const [_, digit] = e.target.value.match(/^0(\d)$/)
        props.setDisplayValue(digit)
      } else if (/^(0|[1-9][\d,]*)?(\.[\d]*)?$/.test(e.target.value)) {
        const [_, digit = '', precision = ''] = e.target.value.match(
          /^(0|[1-9][\d,]*)?(\.[\d]*)?$/,
        )
        const displayValue = `${displayNumber(digit)}${precision}`
        props.setDisplayValue(displayValue)
      }
    },
    onBlur: props => e => {
      props.setDisplayValue(displayNumber(props.value))
      props.onBlur && props.onBlur(props.value)
    },
  }),
  withProps(props => ({value: displayNumber(props.value)})),
)

export default enhance(PenCurrency)
