import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import {Field} from 'redux-form'
import {connect} from 'react-redux'
import {Select} from 'antd'

import {Label} from '../../share/Field'
import {getInsuranceSearch} from '../../../ducks/profile'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
`

const CustomLabel = styled(Label)`
  min-width: unset;
  justify-content: flex-start;
  margin-bottom: 10px;
`

const Selector = styled(Select)`
  width: 246px !important;
  color: #333333;

  > div.ant-select-selection,
  .ant-select-no-arrow {
    height: 40px;

    border: 1px solid ${props => props.theme.EXTRALIGHTORANGE};
    box-shadow: none;

    > div.ant-select-selection__rendered {
      height: 40px;
      padding: 0 10px;
      > div.ant-select-selection__placeholder {
        text-align: center;
        padding: 0 10px;
      }
      > div.ant-select-selection-selected-value {
        line-height: 40px;
      }
    }
  }
`

export const ErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: -20px;
`

export const ErrorText = styled.span`
  position: absolute;
  white-space: nowrap;

  width: 100%;
  margin-top: 2px;

  color: #ff0034;

  font-size: 12px;
  text-align: left;
`

const ExclamationCircle = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${props => props.theme.PINKRED};
  margin-right: 5px;
`

const {Option} = Select

let timeout

const SearchPolicy = props => {
  const {
    input,
    policiesSearch,
    getInsuranceSearch,
    insuranceType,
    insuranceCompanyName,
    defaultValue,
    defaultLabel,
  } = props

  const fetch = value => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }

    timeout = setTimeout(
      () =>
        getInsuranceSearch({
          insuranceCompanyId: insuranceCompanyName,
          insuranceType: insuranceType,
          userQuery: value,
        }),
      500,
    )
  }

  const handleSearch = value => {
    if (value) {
      fetch(value)
    }
  }

  return (
    <Container>
      <CustomLabel>แบบประกัน</CustomLabel>
      <Selector
        {...input}
        showSearch
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={null}
        showArrow={false}>
        {policiesSearch.length ? (
          policiesSearch.map(option => (
            <Option {...option}>{option.label}</Option>
          ))
        ) : (
          <Option value={defaultValue}> {defaultLabel} </Option>
        )}
      </Selector>
      {props.meta.touched && !props.meta.valid && (
        <ErrorContainer>
          <ExclamationCircle icon={['fas', 'exclamation-circle']} />
          <ErrorText errorColor="red">
            {props.meta.error || props.meta.asyncError}
          </ErrorText>
        </ErrorContainer>
      )}
    </Container>
  )
}

const SearchField = props => (
  <Field name="policiesSearch" component={SearchPolicy} {...props} />
)

const enhancer = compose(
  connect(
    state => ({
      policiesSearch: state.profile.insuranceSearchList || [],
    }),
    {getInsuranceSearch},
  ),
)

export default enhancer(SearchField)
