import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form'
import {compose, lifecycle} from 'recompose'
import FieldNoon from '../../../share/Field'
import Button from '../../../share/Button'
import {setLoading} from '../../../../ducks/profile'
import {responsive} from '../../../../core/style'

const TextHead = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 28px;
  font-family: Prompt;
  font-weight: bold;
`

const Select = styled(FieldNoon)`
  flex-direction: column;
  margin-top: 19px;
  height: 40px;
  width: 246px;
`

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 129px;
  ${props =>
    responsive.mobile(css`
      margin-bottom: 35px;
    `)}
`
const validate = values => {
  const errors = {}

  if (!values.AgentName) {
    errors.AgentName = 'ต้องเลือกข้อมูลในช่องนี้'
  }

  return errors
}
const StepOneCompose = compose(
  connect(
    (state, props) => ({
      agentOptions: state.profile.agentOption[props.id],
    }),
    {setLoading},
  ),
  reduxForm({
    form: 'StepOneSelectAgent',
    validate,
  }),
  lifecycle({
    componentDidMount() {},
  }),
)

const StepOne = StepOneCompose(props => (
  <form
    onSubmit={props.handleSubmit(value => {
      props.Summit({...value})
    })}>
    <TextHead>ระบุข้อมูลตัวแทนของคุณ</TextHead>
    <Select
      name="AgentName"
      type="selector"
      placeholder="ชื่อ - สกุล ตัวแทน"
      options={props.agentOptions}
    />
    <CustomButton htmlType="submit">ยืนยัน</CustomButton>
  </form>
))

export default StepOne
