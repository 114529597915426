import React from 'react'
import styled from 'react-emotion'
import {compose, branch, withProps} from 'recompose'
import {withSpidus} from '../../enhances'
import {spidusStore} from '../../core/spidus'
import {Icon} from '../../components'

const Layout = styled.div`
  width: 100%;
  position: relative;
`

const Header = styled.div`
  text-align: left;
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #265fa7;
`

const Body = styled.div`
  > * {
    margin-top: ${props => props.spacebetweenline};
  }
`

const DeleteIcon = styled(Icon)`
  font-size: 16px;
  color: #333333;
  margin-left: 10px;
  cursor: pointer;

  :hover {
    color: red;
  }
`

const AnswerGroup = props => (
  <Layout className={props.className} style={{marginTop: props.marginTop}}>
    <Header>
      {props.caption}
      {props.deleteAnswerName && (
        <DeleteIcon
          icon={['far', 'trash-alt']}
          onClick={() =>
            spidusStore.updateAnswer(
              props.configName,
              props.deleteAnswerName,
              false,
            )
          }
        />
      )}
    </Header>
    <Body spacebetweenline={props.spaceBetweenLine}>
      {props.children instanceof Function
        ? props.children(props)
        : props.children}
    </Body>
  </Layout>
)

const enhancer = compose(
  branch(
    props => props.answerName,
    compose(
      withSpidus(),
      withProps(props => ({
        deleteAnswerName:
          props.deleteAnswerName === true
            ? `use_${props.answerName}`
            : props.deleteAnswerName,
      })),
    ),
  ),
)

const EnhancedAnswerGroup = enhancer(AnswerGroup)

EnhancedAnswerGroup.Layout = Layout
EnhancedAnswerGroup.Header = Header
EnhancedAnswerGroup.Body = Body

export default EnhancedAnswerGroup
