import React from 'react'
import {breakpoints} from '../../../../../core/style'

const SearchFactor = ({type, windowWidth, searchValue}) => {
  switch (type) {
    case 'DebProtection':
      if (searchValue < 0) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
              <br />
            </React.Fragment>
          )
        }
      } else if (searchValue >= 0 && searchValue < 100000) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        }
      } else {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท
              <br />
              <b>ตลอดชีพ</b>
              <br />
              หรือ
              <b> ชั่วระยะเวลา</b>
              <br />
              {/* หรือ <b>ควบการลงทุน</b>  */}
              <br /> <br />
              ในการสร้างความคุ้มครองนี้
              <br />
              โดยทุนประกันจะอยู่ในช่วง
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <b>ตลอดชีพ</b> หรือ
              <b> ชั่วระยะเวลา</b>
              {/* หรือ <b>ควบการลงทุน</b> */}
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุนประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        }
      }
    case 'FamilyIncomeProtection':
      if (searchValue < 0) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              {' '}
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              สินทรัพย์และทุนประกันที่คุณมีครอบคลุมภาระที่คุณต้องการคุ้มครองแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณได้อีก
              <br />
            </React.Fragment>
          )
        }
      } else if (searchValue >= 0 && searchValue < 100000) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        }
      } else {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <br />
              <b>ตลอดชีพ</b>
              <br /> หรือ <b> สะสมทรัพย์</b>
              <br />
              หรือ
              <b> ชั่วระยะเวลา</b>
              <br />
              {/* หรือ <b>ควบการลงทุน</b> */}
              <br />
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุน
              <br />
              ประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <b>ตลอดชีพ</b> หรือ <b> สะสมทรัพย์</b>{' '}
              หรือ
              <b> ชั่วระยะเวลา</b>
              {/* หรือ <b>ควบการลงทุน</b> */}
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุนประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        }
      }

    case 'Retirement':
      if (searchValue < 0) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              เงินได้ประจำที่คุณจะได้รับครอบคลุมภาระค่าใช้จ่ายหลังเกษียณอายุแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเงินบำนาญของคุณได้อีก
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              เงินได้ประจำที่คุณจะได้รับครอบคลุมภาระค่าใช้จ่ายหลังเกษียณอายุแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเงินบำนาญของคุณได้อีก
              <br />
            </React.Fragment>
          )
        }
      } else if (searchValue >= 0 && searchValue < 60000) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              เงินบำนาญที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่สามารถทำได้โดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเงินบำนาญขั้นต่ำที่ 60,000
              บาท/ปี แทน
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              เงินบำนาญที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่สามารถทำได้โดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเงินบำนาญขั้นต่ำที่ 60,000
              บาท/ปี แทน
              <br />
            </React.Fragment>
          )
        }
      } else {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <br />
              <b>สะสมทรัพย์</b>
              <br /> หรือ
              <b> บำนาญ</b>
              <br />
              <br />
              ในการสร้างความคุ้มครองนี้โดยได้รับเงิน
              <br />
              บำนาญต่อปีอย่างน้อย <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <b>สะสมทรัพย์</b> หรือ
              <b> บำนาญ</b>
              <br />
              ในการสร้างความคุ้มครองนี้โดยได้รับเงินบำนาญต่อปีอย่างน้อย <br />
            </React.Fragment>
          )
        }
      }

    case 'TaxReduction':
      if (searchValue < 0) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณได้ใช้สิทธิลดหย่อนภาษีจากประกันชีวิต/ประกันสุขภาพจนเต็มจำนวนแล้ว
              <br />
              แต่คุณยังสามารถเลือกดูแบบประกันเพื่อสร้างความคุ้มครองเพิ่มเติมได้อีก
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณได้ใช้สิทธิลดหย่อนภาษีจากประกันชีวิต/ประกันสุขภาพจนเต็มจำนวนแล้ว
              <br />
              แต่คุณยังสามารถเลือกดูแบบประกันเพื่อสร้างความคุ้มครองเพิ่มเติมได้อีก
              <br />
            </React.Fragment>
          )
        }
      } else if (searchValue >= 0 && searchValue < 1000) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              เบี้ยประกันที่สามารถนำมาใช้สิทธิลดหย่อนภาษีได้พอดีนั้นยังน้อยเกินไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเบี้ยประกันขั้นต่ำที่ 1,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              เบี้ยประกันที่สามารถนำมาใช้สิทธิลดหย่อนภาษีได้พอดีนั้นยังน้อยเกินไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเบี้ยประกันขั้นต่ำที่ 1,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        }
      } else {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <br />
              <b>ตลอดชีพ</b> <br /> หรือ
              <b> สะสมพทรัพย์</b> <br /> หรือ <b> บำนาญ</b> <br />
              {/* หรือ{' '}
            <b> ควบการลงทุน</b> */}
              <br />
              <br />
              ในการสร้างความคุ้มครองนี้โดยเบี้ย <br />
              ประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <b>ตลอดชีพ</b> หรือ
              <b> สะสมพทรัพย์</b> หรือ <b> บำนาญ</b>
              {/* หรือ <b> ควบการลงทุน</b> */}
              <br />
              ในการสร้างความคุ้มครองนี้โดยเบี้ยประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        }
      }

    case 'Saving':
      if (searchValue >= 0 && searchValue < 100000) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              เงินคืนรวมตลอดอายุสัญญาที่คุณต้องการน้อยกว่าขั้นต่ำที่หาได้จากแบบประกันโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเงินคืนรวมตลอดอายุสัญญาขั้นต่ำที่
              100,000 บาทแทน
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              เงินคืนรวมตลอดอายุสัญญาที่คุณต้องการน้อยกว่าขั้นต่ำที่หาได้จากแบบประกันโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากเงินคืนรวมตลอดอายุสัญญาขั้นต่ำที่
              100,000 บาทแทน
              <br />
            </React.Fragment>
          )
        }
      } else {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <br />
              <b>สะสมทรัพย์</b>
              <br />
              {/* หรือ
            <b> ควบการลงทุน</b> */}
              <br />
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุน
              <br />
              ประกันเริ่มต้นจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <b>สะสมทรัพย์</b>
              {/* หรือ
            <b> ควบการลงทุน</b> */}
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุนประกันเริ่มต้นจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        }
      }

    case 'Child':
      if (searchValue < 0) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              เงินที่เตรียมไว้และทุนประกันที่คุณมีครอบคลุมภาระค่าใช้จ่ายเพื่อการศึกษาบุตรของคุณแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณนี้ได้อีก
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              เงินที่เตรียมไว้และทุนประกันที่คุณมีครอบคลุมภาระค่าใช้จ่ายเพื่อการศึกษาบุตรของคุณแล้ว
              <br />
              แต่คุณสามารถค้นหาแบบประกันที่ช่วยเพิ่มเติมความคุ้มครองภาระของคุณนี้ได้อีก
              <br />
            </React.Fragment>
          )
        }
      } else if (searchValue >= 0 && searchValue < 100000) {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              ทุนประกันที่คุณต้องการน้อยกว่าขั้นต่ำของแบบประกันที่มีโดยทั่วไป
              <br />
              เราจะค้นหาแบบประกันให้คุณโดยเลือกจากทุนประกันขั้นต่ำที่ 100,000
              บาทแทน
              <br />
            </React.Fragment>
          )
        }
      } else {
        if (windowWidth <= breakpoints.mobile) {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท
              <br /> <b>สะสมทรัพย์</b>
              {/* <br /> หรือ
            <b> ควบการลงทุน</b> */}
              <br />
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุน
              <br />
              ประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              คุณสามารถใช้แบบประกันประเภท <b>สะสมทรัพย์</b>
              {/* หรือ
            <b> ควบการลงทุน</b> */}
              <br />
              ในการสร้างความคุ้มครองนี้โดยทุนประกันจะอยู่ในช่วง <br />
            </React.Fragment>
          )
        }
      }
  }
}

export default SearchFactor
