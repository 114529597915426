import React from 'react'
import styled from 'react-emotion'
import {reduxForm, formValueSelector} from 'redux-form'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import Button from '../share/Button'
import Field from '../share/Field'
import {isEmail} from '../../core/helper'
import Customfield from '../share/Field'

const CustomButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;
`

const GoogleInput = styled(Field)`
  flex-direction: column;
  margin: 0 0 30px;
  .input-googleInput-container {
    input {
      width: 250px;
      height: 40px;
    }
  }
  &.password {
    .input-googleInput-container {
      input {
        font-size: 16px;
      }
    }
  }
`

const ContainerField = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Selector = styled(Customfield)`
  width: 250px;
  margin-bottom: 30px;
  .Select-placeholder {
    font-size: 16px;
    font-weight: bold;
  }
`

const interestRole = [
  {label: 'CAPTAIN AMERICA', value: '0'},
  {label: 'WOLVERINE', value: '1'},
  {label: 'LORD VOLDEMORT', value: '2'},
  {label: '7 DWARFS', value: '3'},
]

const CareerModal = ({handleSubmit, setIsShowModal}) => {
  return (
    <ContainerField
      onSubmit={handleSubmit(value => setIsShowModal(false))}
      noValidate>
      <Selector
        name="interestRole"
        type="selector"
        placeholder="ตำแหน่งที่สนใจ"
        options={interestRole}
        hideError
      />
      <GoogleInput
        name="name"
        type="googleInput"
        placeholder="ชื่อ"
        hideError
      />
      <GoogleInput
        name="lastname"
        type="googleInput"
        placeholder="นามสกุล"
        hideError
      />
      <GoogleInput
        name="email"
        type="googleInput"
        placeholder="อีเมล"
        hideError
      />
      <GoogleInput
        name="telephone"
        type="googleInput"
        placeholder="เบอร์ติดต่อ"
        hideError
      />
      <CustomButton htmlType="submit" icons={['fa', 'check-circle']}>
        ยืนยัน
      </CustomButton>
    </ContainerField>
  )
}

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.lastname) {
    errors.lastname = 'ต้องใส่ข้อมูลในช่องนี้'
  }
  if (!values.email) {
    errors.email = 'ต้องใส่ข้อมูลในช่องนี้'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }

  return errors
}

const selector = formValueSelector('register')

const enhancer = compose(
  connect(
    (state, props) => ({
      name: selector(state, 'name'),
      lastname: selector(state, 'lastname'),
      email: selector(state, 'email'),
    }),
    {},
  ),
  reduxForm({
    form: 'career',
    validate,
  }),
)

export default enhancer(CareerModal)
