import {injectGlobal} from 'emotion'
import {css} from 'react-emotion'

import {theme} from '../../../core/style'

injectGlobal`
  .ant-calendar {
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.8);

    .ant-calendar-footer-btn,
    .ant-calendar-footer,
    .ant-calendar-input-wrap,
    .ant-calendar-body,
    .ant-calendar-range .ant-calendar-body,
    .ant-calendar-range .ant-calendar-month-panel-body,
    .ant-calendar-range .ant-calendar-year-panel-body,
    .ant-calendar-year-panel-body,
    .ant-calendar-decade-panel-header,
    .ant-calendar-month-panel-body {
      border: none;
    }

    .ant-calendar-input,
    .ant-calendar-range-middle,
    .ant-calendar-today-btn  {
      color: white;
      background-color: transparent;
    }

    .ant-calendar-month-panel,
    .ant-calendar-decade-panel,
    .ant-calendar-year-panel {
      background-color: rgba(0, 0, 0, 0.8);
    }

    .ant-calendar-decade-panel-decade,
    .ant-calendar-year-panel-year,
    .ant-calendar-month-panel-month,
    .ant-calendar-header .ant-calendar-next-century-btn,
    .ant-calendar-header .ant-calendar-next-decade-btn,
    .ant-calendar-header .ant-calendar-next-year-btn,
    .ant-calendar-header .ant-calendar-next-month-btn,
    .ant-calendar-header .ant-calendar-prev-century-btn,
    .ant-calendar-header .ant-calendar-prev-decade-btn,
    .ant-calendar-header .ant-calendar-prev-year-btn,
    .ant-calendar-header .ant-calendar-prev-month-btn,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
    .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select,
    .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select{
      color: white;
    }
    .ant-calendar-header a:hover,
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
    .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
    .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
    .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
      background: #B2D234;
      border-radius: 5px;
      color: white;
    }

    .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
    .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover,
    .ant-calendar-disabled-cell .ant-calendar-date,
    .ant-calendar-disabled-cell .ant-calendar-date:hover {
      background: #D6D5D5;
    }

    .ant-calendar-selected-start-date:not(.ant-calendar-selected-end-date) {
      position: relative;

      ::before {
        content: "";
        display: block;
        background: #ecf6fd;
        border-radius: 0;
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
        border: 0;
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 3px;
        right: 0;
        background: #B2D234;
        opacity: 0.4;
      }
    }

    .ant-calendar-selected-end-date:not(.ant-calendar-selected-start-date) {
      position: relative;

      ::before {
        content: "";
        display: block;
        background: #ecf6fd;
        border-radius: 0;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        border: 0;
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 0;
        right: 3px;
        background: #B2D234;
        opacity: 0.4;
      }
    }

    .ant-calendar-today > .ant-calendar-date {
      border-color: #B2D234;
    }

    .ant-calendar-date {
      color: white;
      font-size: 20px;
      font-family: ${theme.headerFont};

      width: 25px;
      height: 25px;
      line-height: 23px;
      border-radius: 18px;

      :hover {
        background: #B2D234;
      }
    }

    .ant-calendar-column-header-inner {
      color: white;
      font-size: 20px;
      font-family: ${theme.headerFont};
    }

    .ant-calendar-month-select {
      color: white !important;
      font-size: 20px;
      font-family: ${theme.headerFont};
    }

    .ant-calendar-year-select {
      color: white !important;
      font-size: 20px;
      font-family: ${theme.headerFont};
    }

    .ant-calendar-selected-day > .ant-calendar-date {
      background: #B2D234;
    }

    .ant-calendar-in-range-cell:before {
        background: #B2D234;
        opacity: 0.4;
    }
  }
`

export const calendarCss = css`
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #c5aa73;

  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
    font-size: 14px;
    font-family: ${theme.headerFont};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #c5aa73;
    border-radius: 20px;
    color: black;
  }

  .react-datepicker__day:hover {
    background-color: #c5aa73;
    border-radius: 20px;
    color: black;
  }
  .react-datepicker__day--disabled {
    color: white;
    opacity: 0.4;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: white;
    font-size: 14px;
    font-weight: normal;
    font-family: ${theme.headerFont};
    margin-bottom: 15px;
  }
  .react-datepicker__day--outside-month {
    color: #888888;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation {
    margin-top: 7px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #c5aa73;
    border-radius: 20px;
    color: black;
  }
  .react-datepicker__header__dropdown {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-around;
    padding: 0px 15px;
  }
  select {
    height: 28px;
    line-height: 25px;
    padding: 0 30px 0 10px;
    background-color: transparent;
    color: white;
    font-size: 14px;
    font-family: ${theme.headerFont};
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 5px;
    border: solid 1px white;

    background-image: linear-gradient(45deg, transparent 50%, white 50%),
      linear-gradient(135deg, white 50%, transparent 50%);
    background-position: calc(100% - 15px), calc(100% - 10px);
    background-size: 7px 6px, 6px 6px;
    background-repeat: no-repeat;

    option {
      color: white;
      background-color: black;
      box-shadow: 0 0 10px 100px #000 inset;
    }
    option {
      :checked,
      :hover {
        color: black;
        background: #c5aa73;
        box-shadow: 0 0 10px 100px #c5aa73 inset;
      }
    }
  }
`
