import React from 'react'
import styled, { css } from 'react-emotion'
import { compose, withState, withHandlers } from 'recompose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../share/Button'

const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 12.5px;

  @keyframes rollUp {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  @keyframes rollDown {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  > svg {
    ${props =>
    props.isOpen
      ? css`
            transform: rotate(0deg);
            animation: rollUp 0.5s;
          `
      : css`
            transform: rotate(180deg);
            animation: rollDown 0.5s;
          `}
  }
`

const Caption = styled.div`
  display: flex;
  text-align: left;
  color: #333333;
  font-family: Prompt;
  font-size: 18px;
  font-weight: 500;
`

const Children = styled.div`
  width: 100%;
  transition: 0.5s;

  ${props =>
    props.isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`

const CustomButton = styled(Button)`
  margin-bottom: 0;
  margin-left: 20px;
  width: fit-content !important;
  height: 22px;
  font-size: 12px;
  padding: 0 12px;
`

const Collapse = (props) => (
  <MainLayout className={props.className}>
    {props.titleToBottom && <Children isOpen={props.isOpen}>{props.children}</Children>}
    <Title onClick={props.onHandler} isOpen={props.isOpen} className={props.customTitle}>
      <Caption>
        {props.caption}
        {props.button && (
          <CustomButton onClick={props.button.onClick}>
            {props.button.name}
          </CustomButton>
        )}
      </Caption>
      {!props.hideIcon && <FontAwesomeIcon icon={['fas', 'chevron-up']} />}
    </Title>
    {!props.titleToBottom && <Children isOpen={props.isOpen}>{props.children}</Children>}
  </MainLayout>
)

const enhance = compose(
  withHandlers({
    onHandler: props => () => {
      props.setIsOpen(!props.isOpen)
    },
  }),
)

export default enhance(Collapse)
