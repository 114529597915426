import moment from 'moment'

export const patchBuddhistEra = () => {
  const format = moment.fn.format
  moment.fn.xformat = function(token) {
    if (moment.locale() === 'th' && token) {
      const year = this.year() + 543
      token = token.replace('YYYY', year)
      token = token.replace('YY', year % 100)
    }
    return format.call(this, token)
  }
}

patchBuddhistEra()
