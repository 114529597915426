export const using = [
  {label: 'ส่วนตัว', value: '110'},
  {label: 'สาธารณะ', value: '120'},
]

export const redLicense = [
  {label: 'ใช่', value: true},
  {label: 'ไม่ใช่', value: false},
]

export const carFixing = [
  {label: 'ใช่', value: true},
  {label: 'ไม่ใช่', value: false},
]

export const vahicleYear = [
  {label: '2017', value: '2017'},
  {label: '2018', value: '2018'},
]

export const vehicleBrand = [{label: 'Mercedes-Benz', value: 'mercedesBenz'}]

export const vehicleSubBrand = [
  {
    label:
      'C205 Coupe 2dr Edition 1 G-Tronic Plus 7sp Rear Wheel Drive 2.0Ti (CBU) 3,390,000 บาท',
    value:
      'C205 Coupe 2dr Edition 1 G-Tronic Plus 7sp Rear Wheel Drive 2.0Ti (CBU) 3,390,000 บาท',
  },
]

export const vehicleSize = [{label: 'มากกว่า 2,000 cc', value: 'more2000'}]

export const vehicleModel = [{label: 'C250', value: 'C250'}]

export const assignParties = [
  {label: 'ต้องระบุ', value: true},
  {label: 'ไม่ต้องระบุก็ได้', value: false},
]

export const fixing = [
  {label: 'ซ่อมอู่', value: 'garage'},
  {label: 'ซ่อมห้าง', value: 'service_center'},
]

export const firstDamage = [
  {label: 'จ่ายเอง', value: 'Y'},
  {label: 'บริษัทประกันจ่าย', value: 'N'},
]

export const otherDamage = [
  {label: 'กรณีสูญหาย หรือ ไฟไหม้', value: 'cover_lost_fire'},
  {label: 'กรณีน้ำท่วม', value: 'cover_flood'},
  {label: 'กรณีก่อการร้าย', value: 'cover_terrorism'},
]

export const gender = [
  {label: 'ชาย', value: 'male'},
  {label: 'หญิง', value: 'female'},
]

export const planing = [
  {label: 'วางแผนปกป้องภาระที่อาจจะตกถึงครอบครัว', value: 'DebtProtection'},
  {
    label: 'วางแผนเพื่อสร้างความคุ้มครองด้านรายได้ของครอบครัว',
    value: 'FamilyIncomeProtection',
  },
  {label: 'วางแผนเงินเกษียณที่ใช้อยู่ดูแลเราไปจนแก่เฒ่า', value: 'Retirement'},
  {label: 'วางแผนคุ้มครองค่าใช้จ่ายการศึกษาบุตร', value: 'Child'},
  {label: 'วางแผนลดหย่อนภาษีด้วยประกัน', value: 'TaxReduction'},
  {label: 'วางแผนสะสมเงินพร้อมทุนประกัน', value: 'Saving'},
]

export const type = [
  {label: 'ตลอดชีพ', value: 'whole_life'},
  {label: 'ชั่วระยะเวลา', value: 'term'},
  {label: 'บำนาญ', value: 'annuity'},
  {label: 'สะสมทรัพย์', value: 'saving'},
  // {label: 'Unit Link', value: 'unit_link'},
]

export const others = [
  {label: 'เงินปันผล', value: 'has_dividend'},
  {label: 'ลดหย่อนภาษี', value: 'is_tax_saving'},
  // {label: 'แนบสัญญาเพิ่มเติมสุขภาพ', value: 'has_health'},
  // {label: 'โปรโมชั่นจากบริษัท / ตัวแทน', value: 'has_agent_promotion'},
]

export const InsuranceCompany = [
  {label: 'เมืองไทยประกันชีวิต', value: 'MTL'},
  {label: 'เอไอเอ', value: 'AIA'},
]

export const premiumPay = [
  {label: 'ไม่ระบุ', value: ''},
  {label: '1 ปี', value: '1'},
  {label: 'ไม่เกิน 5 ปี', value: '5'},
  {label: '6 - 10 ปี', value: '6-10'},
  {label: '11 - 15 ปี', value: '11-15'},
  {label: '16 - 20 ปี', value: '16-20'},
  {label: '20 ปีขึ้นไป', value: '20'},
]

export const coveragePeriod = [
  {label: 'ไม่ระบุ', value: ''},
  {label: '10 ปี', value: 10},
  {label: '15 ปี', value: 15},
  {label: '20 ปี', value: 20},
  {label: '25 ปี', value: 25},
  {label: '30 ปี', value: 30},
]

export const noneStar = [{label: 'ไม่ระบุ', value: 0}]

export const carType = [
  {label: 'ประเภท 1', value: '1'},
  {label: 'ประเภท 2+', value: '20'},
  {label: 'ประเภท 2', value: '2'},
  {label: 'ประเภท 3+', value: '30'},
  {label: 'ประเภท 3', value: '3'},
]

export const HSOptions = [
  {label: 'วงเงินประกันค่ารักษาพยาบาลรวมสูงสุด/ ปี', value: 'sum_insured'},
  {label: 'วงเงินค่าห้องสูงสุด/ คืน', value: 'room'},
]

export const CIOptions = [
  {label: 'ระยะเริ่มต้นและปานกลาง', value: 'middle'},
  {label: 'รุนแรง', value: 'severe'},
  {label: 'กรณีเสียชีวิต', value: 'dead'},
]

export const choosingCoverage = [
  {label: 'มะเร็ง/ เนื้องอก', value: '0'},
  {label: 'เบาหวาน', value: '1'},
  {label: 'สมอง/ ระบบประสาท', value: '2'},
  {label: 'เลือด/ กระดูก', value: '3'},
  {label: 'หัวใจและหลอดเลือด', value: '4'},
  {label: 'อวัยวะภายในอื่นๆ', value: '5'},
  {label: 'อวัยวะภายนอก', value: '6'},
  {label: 'ผ่าตัด', value: '7'},
  {label: 'พิการ/ ทุพพลภาพ', value: '8'},
  {label: 'อื่นๆ', value: '9'},
]
