import React from 'react'
import styled from 'react-emotion'
import {getFormValues} from 'redux-form'
import {compose, withState, withHandlers, lifecycle} from 'recompose'
import {connect} from 'react-redux'

import Summary from './Summary'
import WelcomePage from './WelcomePage'
import Loading from '../../../share/Loading'
import {PERSONAL_FIELDS, INCOME_FIELDS, HISTORY_FIELDS} from './utils'

import {getPortOptions, sendEvaluateData} from '../../../../ducks/profile'

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

import {RenderField} from './share'

const PersonalPage = styled(RenderField)`
  > div.button-container {
    > div {
      > button {
        min-width: 200px;
      }
    }
  }
`

const IncomePage = styled(RenderField)``

const HistoryPage = styled(RenderField)``

const EvaluationForm = ({
  page,
  onSubmit,
  nextPage,
  isLoading,
  formValues,
  previousPage,
  hospitalsOptions,
  hasSocialSecurity,
  investmentRiskOptions,
}) => (
  <Loading isLoading={isLoading}>
    <Container>
      {page === 0 && <WelcomePage onSubmit={nextPage} />}
      {page === 1 && (
        <PersonalPage
          onSubmit={nextPage}
          formValues={formValues}
          fields={PERSONAL_FIELDS}
        />
      )}
      {page === 2 && (
        <IncomePage
          onSubmit={nextPage}
          previousPage={previousPage}
          fields={INCOME_FIELDS.map(field =>
            field.name === 'investmentRisk'
              ? {
                  ...field,
                  options: investmentRiskOptions,
                }
              : field,
          )}
          formValues={formValues}
        />
      )}
      {page === 3 && (
        <HistoryPage
          onSubmit={nextPage}
          previousPage={previousPage}
          fields={HISTORY_FIELDS.map(field => {
            if (field.name === 'hospitalId') {
              return {
                ...field,
                options: hospitalsOptions,
              }
            } else if (field.name === 'hasSocialSecurity') {
              return {
                ...field,
                options: hasSocialSecurity,
              }
            } else {
              return field
            }
          })}
          formValues={formValues}
        />
      )}
      {page === 4 && (
        <Summary
          onSubmit={onSubmit}
          formValues={formValues}
          previousPage={previousPage}
          hospitalsOptions={hospitalsOptions}
          hasSocialSecurity={hasSocialSecurity}
          investmentRiskOptions={investmentRiskOptions}
        />
      )}
    </Container>
  </Loading>
)

const enhancer = compose(
  connect(
    state => ({
      isLoading: state.profile.isLoading,
      formValues: getFormValues('port_evaluation')(state),
      hospitalsOptions: state.profile.portOptions.hospitals || [],
      investmentRiskOptions: state.profile.portOptions.investmentRisk || [],
      hasSocialSecurity: [
        {label: 'มี', value: true},
        {label: 'ไม่มี', value: false},
      ],
    }),
    {getPortOptions, sendEvaluateData},
  ),
  withState('page', 'setPage', 0),
  withHandlers({
    previousPage: ({page, setPage}) => () => {
      setPage(page - 1)
    },
    nextPage: ({page, setPage}) => () => {
      setPage(page + 1)
    },
    onSubmit: ({formValues, sendEvaluateData}) => () => {
      sendEvaluateData(formValues)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getPortOptions()
    },
  }),
)

export default enhancer(EvaluationForm)
