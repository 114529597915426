import React from 'react'
import {connect} from 'react-redux'
import {compose, withHandlers, withState, lifecycle} from 'recompose'
import styled from 'react-emotion'
import {formValueSelector, Field as CustomField, change} from 'redux-form'
import {
  displayNumber,
  numberParser,
  convertNaNtoZero,
} from '../../../../../../core/helper'

import Panel from '../PanelForm'
import {breakpoints} from '../../../../../../core/style'
import SearchFactor from '../SearchFactor'

import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
} from '../ShareFieldForm'

const CustomText = styled.b`
  font-size: 20px;
`

const CustomTextFooter = styled(TextFooter)`
  width: unset;
  height: unset;
`

const Summary = ({summary}) => (
  <CustomText>{displayNumber(Math.round(summary))} บาท</CustomText>
)

const ChildForm = ({summary, onCalSummary, windowWidth}) => (
  <Panel>
    <div>
      <FieldContainer>
        <TextInput
          name="moneyMustPrepared"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินที่ต้องเตรียม (บาท)'
              : 'จำนวนเงินที่ต้องเตรียม'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
      <FieldContainer>
        <TextInput
          name="moneyPrepared"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'จำนวนเงินที่เตรียมไว้แล้ว (บาท)'
              : 'จำนวนเงินที่เตรียมไว้แล้ว'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onCalSummary}
        />
        <Unit>บาท</Unit>
      </FieldContainer>
    </div>
    <FooterFormContainer>
      <CustomTextFooter>
        <SearchFactor
          type="Child"
          windowWidth={windowWidth}
          searchValue={summary}
        />
        <CustomField name="searchValue" component={Summary} summary={summary} />
      </CustomTextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={summary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(state, 'moneyMustPrepared', 'moneyPrepared'),
    }),
    {change},
  ),
  withState('summary', 'setSummary', 0),
  withHandlers({
    onCalSummary: ({totalList, setSummary, change}) => () => {
      const {moneyMustPrepared = '0', moneyPrepared = '0'} = totalList

      let first = convertNaNtoZero(moneyMustPrepared)

      let second = convertNaNtoZero(moneyPrepared)

      let summary = first - second
      setSummary(summary)
      change('Planning', 'searchValue', summary)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.onCalSummary()
    },
  }),
)

export default enhancer(ChildForm)
