import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import {Popover} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getNotification} from '../../ducks/auth'
import TimeAgo from 'react-timeago'
import notificationLogo from '../../static/images/notification-default.jpg'

const CustomNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 5px;
`
const CustomNotificationCententContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 19px;
`
const NotificationContentIcon = styled.img`
  margin-left: 21px;
  width: 30px;
  height: 30px;
`
const NotificationTopic = styled.span`
  font-size: 12px;
`
const NotificationLastUpdate = styled.span`
  font-size: 8px;
`
const CustomPopover = styled(Popover)`
  display: flex;

  .ant-popover-inner div > .ant-popover-inner-content div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const TimeAgoStyled = styled(TimeAgo)`
  font-size: 12px;
`
const SadTearIcon = styled(FontAwesomeIcon)`
  font-size: 48px;
  margin-bottom: 10px;
  color: white;
  opacity: 0.6;
`
const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  margin: 0 5px;
  margin-left: 10;
`
const NottingText = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  opacity: 0.6;
`
const NottingContainer = styled.div`
  display: flex;
  justify-content: center;
`
const NottingContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30.5px;
`

const Notification = ({notification}) => (
  <CustomPopover
    placement="bottomLeft"
    content={
      <div className="bellIcon">
        {notification.length > 0 ? (
          notification.map(value => (
            <CustomNotificationContainer
              key={value.id}
              className="notificationContent"
              onClick={() =>
                window.open(`/square/details/${value.id}`, '_blank')
              }>
              <NotificationContentIcon src={value.avatar || notificationLogo} />
              <CustomNotificationCententContainer>
                <NotificationTopic>{value.topicName}</NotificationTopic>
                <NotificationLastUpdate>
                  <TimeAgoStyled date={value.createdAt} />
                </NotificationLastUpdate>
              </CustomNotificationCententContainer>
            </CustomNotificationContainer>
          ))
        ) : (
          <NottingContainer>
            <NottingContent>
              <SadTearIcon icon={['fas', 'sad-tear']} />
              <NottingText>ไม่มีอะไรอัพเดต</NottingText>
            </NottingContent>
          </NottingContainer>
        )}
      </div>
    }
    trigger="click">
    <CustomIcon icon={['far', 'bell']} />
  </CustomPopover>
)

const enhancer = compose(
  connect(
    state => ({
      notification: state.auth.notification,
    }),
    {getNotification},
  ),
  lifecycle({
    componentDidMount() {
      this.props.getNotification()
      clearInterval()
      setInterval(() => this.props.getNotification(), 300000)
    },
  }),
)

export default enhancer(Notification)
