import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import styled from 'react-emotion'
import {reduxForm} from 'redux-form'

import CustomField from '../../share/Field'
import {editComment} from '../../../ducks/noonSquare'
import {isBlank} from '../../../core/helper'

const EditCommentBox = styled(CustomField)`
  margin: 10px 0;
  height: unset;
  textarea {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 7px 20px;
  }
`

const EditComment = ({id, commentId, name, editComment, callback}) => (
  <EditCommentBox
    onEnter={comment =>
      !isBlank(comment) && editComment(id, commentId, comment, callback)
    }
    name={name}
    placeholder="แสดงความคิดเห็น..."
    type="autotextarea"
  />
)

const enhancer = compose(
  connect(
    state => ({
      id: state.noonSquare.article.article.id || '',
    }),
    {editComment},
  ),
  reduxForm({
    form: 'editComment',
    enableReinitialize: true,
  }),
)

export default enhancer(EditComment)
