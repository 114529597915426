import React from 'react'
import styled from 'react-emotion'
import {compose} from 'recompose'
import {reduxForm} from 'redux-form'

import ShareField from '../../../share/Field'
import Button from '../../../share/Button'
import validate from './validate'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  text-align: center;
`

export const FieldsContainer = styled.div`
  width: 100%;
  max-width: 246px;
  margin: 0 0 10px;
`

export const Topic = styled.h1`
  margin-bottom: 30px;

  font-size: 20px;
  font-family: 'Prompt';
  font-weight: 700;
  line-height: 30px;
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const SubmitButton = styled(Button)`
  min-width: 110px;
  height: 30px;

  > svg.icon {
    transform-origin: center;
    transform: rotate(180deg);
  }
`

export const PreviousButton = styled(Button)`
  flex-direction: row-reverse;
  min-width: 110px;
  margin-right: 26px;

  background-color: ${props => props.theme.GREY10};

  :hover {
    background-color: ${props => props.theme.GREY10};

    > svg.icon {
      margin-left: 0;
      margin-right: 10px;
    }
  }
`

const CustomField = styled(ShareField)`
  flex-direction: column;
  margin: 0 0 20px;

  > span.label {
    justify-content: flex-start;
    margin: 0 0 10px;
    text-align: left;
  }

  > div {
    > input {
      text-align: center;
    }
    > input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  > div.error-container {
    text-align: left;
  }
`

const formEnhancer = compose(
  reduxForm({
    form: 'port_evaluation',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    initialValues: {
      age: 0,
      retireAge: 1,
    },
  }),
)

export const RenderField = formEnhancer(
  ({className, onSubmit, previousPage, fields = [], valid}) => (
    <Form className={className} onSubmit={onSubmit}>
      <Topic>ประเมินความคุ้มครองที่แนะนำ</Topic>
      <FieldsContainer className="fields-container">
        {fields.map(field => (
          <CustomField
            key={field.name}
            type={field.type || 'text'}
            {...field}
          />
        ))}
      </FieldsContainer>
      <ButtonContainer className="button-container">
        {previousPage && (
          <PreviousButton onClick={previousPage} icons={['fas', 'angle-left']}>
            ย้อนกลับ
          </PreviousButton>
        )}
        <SubmitButton
          disabled={!valid}
          htmlType="submit"
          icons={['fas', 'angle-left']}>
          ถัดไป
        </SubmitButton>
      </ButtonContainer>
    </Form>
  ),
)
