import React from 'react'
import HighChartsData from 'highcharts/modules/data'
import HighChartsAnnotations from 'highcharts/modules/annotations'
import HighChartsMore from 'highcharts/highcharts-more'
import ReactHighcharts from 'react-highcharts'

let component = null

if (process.browser) {
  HighChartsData(ReactHighcharts.Highcharts)
  HighChartsAnnotations(ReactHighcharts.Highcharts)
  HighChartsMore(ReactHighcharts.Highcharts)
  component = ({config}) => <ReactHighcharts config={config} />
}

export default component
