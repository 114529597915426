import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ModalFavorite from '../../../share/ModalFavorite'

import {setFavoriteProduct} from '../../../../ducks/product'

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: row;
`

const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: red;
  margin-right: 22px;
  cursor: pointer;
`

const SmallText = styled.div`
  font-size: 12px;
`

const Favorite = ({
  isLogin,
  isShowModalFavorite,
  setIsShowModalFavorite,
  setFavoriteProduct,
  id,
  category,
  paramsEncode,
  favorite,
}) => (
  <ContainerDetail>
    {isLogin ? (
      <HeartIcon
        onClick={() => {
          setFavoriteProduct(!favorite, id, category, paramsEncode)
        }}
        icon={favorite ? ['fas', 'heart'] : ['far', 'heart']}
      />
    ) : (
      <React.Fragment>
        <HeartIcon
          onClick={() => {
            setFavoriteProduct(!favorite, id, category, paramsEncode)
            setIsShowModalFavorite(!isShowModalFavorite)
          }}
          icon={favorite ? ['fas', 'heart'] : ['far', 'heart']}
        />
        <ModalFavorite
          isShowModal={isShowModalFavorite}
          setIsShowModal={setIsShowModalFavorite}
        />
      </React.Fragment>
    )}{' '}
    <SmallText>บันทึกเป็นรายการโปรด</SmallText>
  </ContainerDetail>
)

const enhancer = compose(
  connect(
    state => ({
      isLogin: state.auth.isLogin,
      favorite: state.product.detailFavorite,
    }),
    {setFavoriteProduct},
  ),
)

export default enhancer(Favorite)
