import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, lifecycle} from 'recompose'
import {connect} from 'react-redux'
import jump from 'jump.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import withSEO from '../share/withSEO'

import ContentCard from './Card'
import SearchAndSortBox from './SearchAndSortBox'
import SuggestTag from './SuggestTag'
import Loading from '../share/Loading'

import {fetchArticles} from '../../ducks/noonSquare'
import noonSquareBanner from '../../static/images/Square-Banner.jpg'
import {responsive} from '../../core/style'
import {url2Obj} from '../../core/helper'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0 30px 0;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1260px;
`

const NoonSquareBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url(${noonSquareBanner});
  width: 100%;
  height: 300px;
  background-size: cover;
  margin-bottom: 32px;
  background-position-y: center;

  ${props =>
    responsive.mobile(css`
      height: calC(100vh - 80px);
      background-position-x: center;
    `)};
`
const ChevronDownIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 0;
  font-size: 40px;
  cursor: pointer;
  color: white;
  opacity: 0.8;
  margin-top: 10px;
`

const NoonSquare = ({isLoading, articles, fetchArticles, ...otherProps}) => (
  <Loading isLoading={isLoading}>
    <Container>
      <NoonSquareBanner>
        <SearchAndSortBox fetchArticles={fetchArticles} {...otherProps} />
        <SuggestTag fetchArticles={fetchArticles} {...otherProps} />
        <ChevronDownIcon
          id="content"
          icon={['fas', 'angle-down']}
          onClick={() => jump('#content')}
        />
      </NoonSquareBanner>
      <CardContainer>
        {articles.map(value => (
          <ContentCard key={value.id} value={value} from="square" />
        ))}
      </CardContainer>
    </Container>
  </Loading>
)

const enhancer = compose(
  withSEO(
    'ความรู้เกี่ยวกับประกัน',
    'noon Square ข้อมูลและบทความที่หลากหลายทั้งที่เกี่ยวข้องกับประกันภัย และไลฟ์สไตล์อื่นๆ ที่รวบรวมไว้ให้คุณได้มาค้นหากันที่นี่',
    'บทความ,ข้อมูล,ศึกษา,รายละเอียด,ประกันภัย,เดินทาง,ท่องเที่ยว,อาหาร,สุขภาพ,เม้ามอย,วิเคราะห์',
  ),
  connect(
    (state, props) => ({
      isLoading: state.noonSquare.isLoading,
      articles: state.noonSquare.articles,
      params: url2Obj(props.location.search),
    }),
    {fetchArticles},
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchArticles(this.props.params)
    },
  }),
)

export default enhancer(NoonSquare)
