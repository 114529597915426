import styled from 'react-emotion'

import DatePicker from 'react-datepicker'

const BirthDatePicker = styled(DatePicker)`
  border: none;
  height: 40px;
  border: 1px solid #a1a1a1;
  background: ${props => (props.disabled ? '#d6d5d5' : 'white')};
  width: 100%;
  border-radius: 5px;
  padding: 8px 5px;

  font-size: 20px;
  font-weight: bold;
  text-indent: 10px;
  font-family: ${props => props.theme.headerFont};

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #d6d5d5;
    font-weight: normal;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #d6d5d5;
    font-weight: normal;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #d6d5d5;
    font-weight: normal;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #d6d5d5;
    font-weight: normal;
  }
`

export default BirthDatePicker
