import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import PropTypes from 'prop-types'

const Row = styled.div`
  flex: 1;
  display: flex;
  height: 40px;
  display: flex;
  justify-content: ${props => (props.header ? 'center' : 'flex-start')};
  align-items: center;
  :nth-child(odd) {
    background: ${props =>
      props.header ? 'rgba(255, 212, 129, 1)' : 'rgba(255, 212, 129, 0.2)'};
  }
  ${props => props.classNameHeader};
`
const Col = styled.div`
  flex: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  opacity: 1;
  ${props => props.className};
`
const WrapTabel = styled.div`
  border-radius: 5px;
  overflow: hidden;
`

const Table = props => {
  const {dataSource, columns, header = true, classNameHeader = ''} = props

  return (
    <WrapTabel>
      {header && (
        <Row header={header} classNameHeader={classNameHeader}>
          {columns.map(col => {
            return (
              <Col className={col.className || ''} key={col.key}>
                {col.title}
              </Col>
            )
          })}
        </Row>
      )}
      {dataSource.map(data => (
        <Row key={data.key}>
          {columns.map(col => {
            return (
              <Col
                className={col.className || ''}
                key={`${data.key}-${col.key}`}>
                {data[col.dataIndex]}
              </Col>
            )
          })}
        </Row>
      ))}
    </WrapTabel>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
}

export default Table
