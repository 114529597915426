import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PieChart from './PieChart'
import DateLable from '../DateLabel'
import PeriodLable from '../PeriodLabel'
import {displayCurrency, displayPercentage} from '../../../../core/helper'

const WrapPort = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  width: 900px;

  @media (max-width: 1024px) {
    width: 746px;
    margin-bottom: 0px;
  }
  @media (max-width: 500px) {
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }
`
const WrapPortDetails = styled.div`
  width: 420px;
  height: 636px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: center;
  :first-child {
    margin-right: 60px;
  }
  @media (max-width: 1024px) {
    width: 350px;
    margin-bottom: 20px;
    :first-child {
      margin-right: 40px;
    }
  }
  @media (max-width: 500px) {
    width: 320px;
    :first-child {
      margin-right: 0px;
    }
  }
`
const WrapDetailsWithBorder = styled.div`
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  position: relative;
  width: 900px;
  h3 {
    margin-bottom: 20px;
  }
  @media (max-width: 1024px) {
    width: 746px;
  }
  @media (max-width: 500px) {
    width: 320px;
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 30px;
  padding-top: 0px;
  h3,
  span {
    flex: 1;
    margin-bottom: 0px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Label = styled.h3`
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 30px;
`
const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  padding-top: 0px;
`
const Div = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  height: 40px;
  :nth-child(odd) {
    background: rgba(255, 212, 129, 0.2);
  }
  .tooltip {
    position: absolute;
    bottom: 30px;
    background-color: #555;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
  }
  :hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`
const WrapLabelRow = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 500px) {
    width: 100%;
  }
`
const Span = styled.div`
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 36px;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    padding: 0 20px;
  }
`
const WrapPercent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default props => {
  if (!props.portfolio || !props.assets) return null
  const {fund, portfolio, assets} = props
  const {secAssetUpdDate} = fund
  const {
    secInvestmentSecuritiesUpdPeriod,
    secInvestmentSecurities = [],
    secTopFiveHoldingUpdPeriod,
    secTopFiveHoldings = [],
    secPortfolioTurnoverRatio = [],
  } = portfolio

  const assetDataSource =
    assets &&
    assets.map(asset => ({
      name: asset.name,
      y: asset.ratio ? Number(parseFloat(asset.ratio).toFixed(2)) : 0,
    }))

  const investmentDataSource =
    secInvestmentSecurities &&
    secInvestmentSecurities
      .filter(asset => asset.name != 'รวม')
      .map(asset => ({
        name: asset.name,
        y: asset.percent ? Number(parseFloat(asset.percent).toFixed(2)) : 0,
      }))

  return (
    <Fragment>
      <WrapPort>
        <WrapPortDetails>
          <DateLable date={secAssetUpdDate} />
          <PieChart
            data={assetDataSource}
            title="สัดส่วนประเภททรัพย์สิน ที่ลงทุนของกองทุน"
          />
        </WrapPortDetails>
        <WrapPortDetails>
          <PeriodLable period={secInvestmentSecuritiesUpdPeriod} />
          <PieChart
            data={investmentDataSource}
            title="สัดส่วนของการลงทุน ของกองทุนรวม"
            colors={[
              '#3AC0B7',
              '#4FC7BF',
              '#65CEC7',
              '#7BD5CF',
              '#91DCD7',
              '#A7E3DF',
              '#BDEAE7',
              '#D3F1EF',
              '#E5E5E5',
            ]}
          />
        </WrapPortDetails>
      </WrapPort>
      <WrapDetailsWithBorder>
        <PeriodLable period={secTopFiveHoldingUpdPeriod} />
        <Label>หลักทรัพย์ 5 อันดับแรกที่ลงทุน</Label>
        <div>
          <WrapItems>
            {secTopFiveHoldings.map((data, index) => {
              return (
                <Div key={`${index + 1}-${data.name}`}>
                  <div class="tooltip">{data.name}</div>
                  <WrapLabelRow>
                    <Span>
                      {index + 1}. {data.name}
                    </Span>
                    <WrapPercent>
                      {data.percent ? displayPercentage(data.percent) : 0}
                    </WrapPercent>
                  </WrapLabelRow>
                </Div>
              )
            })}
          </WrapItems>
        </div>
      </WrapDetailsWithBorder>
      <WrapDetailsWithBorder>
        <DateLable date={secPortfolioTurnoverRatio.lastUpdDate} />
        <Label>อัตราส่วนหมุนเวียนการลงทุนของกองทุนรวม</Label>
        <Row>
          <span>
            {secPortfolioTurnoverRatio
              ? displayCurrency(
                  secPortfolioTurnoverRatio.portfolioTurnoverRatio,
                )
              : 'N/A'}
          </span>
        </Row>
      </WrapDetailsWithBorder>
    </Fragment>
  )
}
