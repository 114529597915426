import React from 'react'
import {compose, withState} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled, {css} from 'react-emotion'
import windowSize from '../../core/react-window-size'
import {navigate} from '@reach/router'
import {responsive} from '../../core/style'

import Advance from '../../static/images/Choose-Advance.svg'
import Starter from '../../static/images/Choose-Starter.svg'
import Intermediate from '../../static/images/Choose-Intermediate.svg'

const Container = styled.div`
  position: relative;

  width: 100%;

  ${props =>
    responsive.mobile(css`
      display: flex;
      flex-direction: row;
      z-index: ${props.isShow ? '100' : '10'};
    `)};
`

const CategoryIconContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 25px;
  right: 35px;
  width: 60px;
  height: 60px;
  transition-duration: 0.5s;
  overflow: hidden;

  z-index: 10;
  cursor: pointer;

  :hover {
    height: 210px;
  }

  ${props =>
    responsive.mobile(css`
      position: relative;
      flex-direction: row;
      justify-content: flex-end;
      height: fit-content;
      width: 200px;
      right: 0;
      :hover {
        height: fit-content;
      }
    `)};
`

const Mask = styled.div`
  ${props =>
    responsive.mobile(css`
      position: fixed;
      top: 0;

      width: 100vw;
      height: 100vh;

      opacity: 0.9;
      background-color: black;
      transform: ${props.isShow ? 'translateX(0)' : 'translateX(100vw)'};
      transition: transform 0.5s;
      z-index: 9;
    `)};
`

const CategoryIconPart = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => (props.isSelectedCategory ? '60px' : '55px')};
  height: ${props => (props.isSelectedCategory ? '60px' : '55px')};
  background-color: #ffb933;
  border-radius: 50%;
  color: black;
  margin-bottom: 10px;
  transition: top 0.5s;
  opacity: ${props => (props.isSelectedCategory ? '1' : '0.5')};
  z-index: ${props => (props.isSelectedCategory ? '2' : '1')};

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: ${props => (props.isHoverCategory ? '33%' : '0')};
  }
  &:nth-child(3) {
    top: ${props => (props.isHoverCategory ? '66%' : '0')};
  }

  cursor: pointer;

  :hover {
    background-color: #ffb933;
    color: #333333;
    width: 60px;
    height: 60px;
    opacity: 1;
    transition: 0.1s;
  }

  ${props =>
    responsive.mobile(css`
      position: relative;
      transition: right 0.5s;
      top: unset !important;
      margin: 0 5px;
    `)}
`

const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`

const CategoryText = styled.div`
  font-size: 8px;
  text-align: center;
`

const LevelIconContainer = styled(CategoryIconContainer)`
  top: ${props => (props.isHoverCategory ? '235px' : '95px')};
  height: ${props => (props.isLevelActive ? '210px' : '60px')};
  cursor: pointer;

  display: ${props => (props.isHide ? 'none' : 'flex')};
  align-items: center;

  :hover {
    top: ${props => (props.isHoverCategory ? '235px' : '95px')};
    height: ${props => (props.isLevelActive ? '210px' : '60px')};
  }

  ${props =>
    responsive.mobile(css`
      transition: right 0.5s;
      top: 25px;
      height: fit-content;
      width: fit-content;
      :hover {
        top: 25px;
        height: fit-content;
      }
    `)};
`

const IconContainer = styled.div`
  position: absolute;
  width: ${props => (props.isLevelActive ? '60px' : '55px')};
  height: ${props => (props.isLevelActive ? '60px' : '55px')};
  opacity: ${props => (props.isLevelActive ? '1' : '0.5')};
  z-index: ${props => (props.isLevelActive ? '2' : '1')};

  cursor: pointer;
  margin-bottom: 10px;
  transition: top 0.5s;

  :hover {
    width: 60px;
    height: 60px;
    opacity: 1;
    transition: 0.1s;
  }

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: ${props => (props.isLevel ? '33% ' : '0')};
  }
  &:nth-child(3) {
    top: ${props => (props.isLevel ? '66% ' : '0')};
  }

  ${props =>
    responsive.mobile(css`
      position: relative;
      transition: right 0.5s;
      top: unset !important;
      margin: 0 5px;
    `)}
`

const Icon = styled.img``

const MobileClickContainer = styled.div`
  display: none;
  position: absolute;
  right: 0px;
  top: 25px;
  align-items: center;
  justify-content: center;
  background-color: #ffb933;
  width: 30px;
  height: 60px;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  z-index: 10;

  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const ClickIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: white;
`

const CustomCategotyLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 35px;

  ${props =>
    responsive.mobile(css`
      padding-top: 80px;
    `)};
`

const selectCategoryType = [
  {icon: ['fas', 'car'], text: 'ประกันรถยนต์', type: 'motor'},
  {icon: ['fas', 'user-shield'], text: 'ประกันชีวิต', type: 'life'},
  {icon: ['fas', 'briefcase-medical'], text: 'ประกันสุขภาพ', type: 'health'},
]

const selectLevelType = [
  {src: Starter, type: 'starter'},
  {src: Intermediate, type: 'intermediate'},
  {src: Advance, type: 'advance'},
]

const Category = ({
  isHoverCategory,
  icon,
  text,
  isCategoryActive,
  type,
  isLevelActive,
  isHide,
  isSelectedCategory,
  childCount,
  setActiveCategory,
}) => (
  <CategoryIconPart
    onClick={() => {
      navigate('/smart/' + type)
      setActiveCategory(false)
    }}
    isHoverCategory={isHoverCategory}
    isCategoryActive={isCategoryActive}
    isLevelActive={isLevelActive}
    isSelectedCategory={isSelectedCategory}
    isHide={isHide}
    childCount={childCount}>
    <CustomIcon isHoverCategory={isHoverCategory} icon={icon} />
    <CategoryText isHoverCategory={isHoverCategory}>{text}</CategoryText>
  </CategoryIconPart>
)

const CategoryIcon = ({
  setHoverCategory,
  isHoverCategory,
  isLevelActive,
  setLevelActive,
  isHide,
  category,
  level,
  isActiveCategory,
  setActiveCategory,
}) => (
  <Container isShow={isHoverCategory}>
    <Mask isShow={isActiveCategory}>
      <CustomCategotyLayout>
        <CategoryIconContainer
          isHoverCategory={isHoverCategory}
          onMouseOver={() => setHoverCategory(true)}
          onMouseOut={() => setHoverCategory(false)}>
          {selectCategoryType.map(value => (
            <Category
              childCount={
                value.type === 'motor' ? 2 : value.type === 'health' ? 1 : 3
              }
              type={value.type}
              key={value.type}
              isHoverCategory={isHoverCategory}
              isSelectedCategory={category === value.type}
              icon={value.icon}
              text={value.text}
              isLevelActive={isLevelActive}
              isHide={isHide}
              setActiveCategory={setActiveCategory}
            />
          ))}
        </CategoryIconContainer>
        <LevelIconContainer
          isHide={isHide}
          isLevelActive={isLevelActive}
          isHoverCategory={isHoverCategory}
          onMouseOver={() => setLevelActive(true)}
          onMouseOut={() => setLevelActive(false)}>
          {selectLevelType.map((value, index) =>
            (category === 'motor' && value.type !== 'advance') ||
            category === 'life' ||
            (category === 'health' &&
              value.type !== 'starter' &&
              value.type !== 'advance') ? (
              <IconContainer
                index={index + 1}
                key={value.type}
                isLevel={isLevelActive}
                isHoverCategory={isHoverCategory}
                isLevelActive={level === value.type}
                onClick={() => {
                  navigate(`/smart/${category}/${value.type}`)
                  setActiveCategory(!isActiveCategory)
                }}>
                <Icon src={value.src} />
              </IconContainer>
            ) : (
              ''
            ),
          )}
        </LevelIconContainer>
      </CustomCategotyLayout>
    </Mask>
    <MobileClickContainer onClick={() => setActiveCategory(!isActiveCategory)}>
      <ClickIcon
        icon={['fas', isActiveCategory ? 'chevron-right' : 'chevron-left']}
      />
    </MobileClickContainer>
  </Container>
)

const enhancer = compose(
  withState('isHoverCategory', 'setHoverCategory', false),
  withState('isActiveCategory', 'setActiveCategory', false),
  withState('isLevelActive', 'setLevelActive', false),
)

export default windowSize(enhancer(CategoryIcon))
