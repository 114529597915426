import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withProps, withHandlers, withState, lifecycle} from 'recompose'
import {
  Field,
  Button,
  Switch,
  Currency,
  CurrencyWithPeriod,
} from '../../NoonQuestion/components/index'
import StepCalculateTaxModal from './StepCalculateTaxModal'
import NotFoundProductModal from './NotFoundProductModal'
import ImageThree from '../../../static/images/Tax_img1@2x.png'
import ImageOne from '../../../static/images/Tax_img2@2x.png'
import ImageTwo from '../../../static/images/Tax_img3@2x.png'
import Cloud from '../../../static/images/Group_639.svg'
import * as path from '../common/path'
// import {Currency, ButtonGroup, CurrencyWithPeriod} from '../components/index'
import {withAnswers} from '../enhances'
import {spidusStore} from '../core/spidus'
import {isClient} from '../../NoonQuestion/common/helper'
import {withCaption} from '../../NoonQuestion/enhancers/index'

import {reduxForm} from 'redux-form'

const Layout = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`

const WatchAgain = styled.div`
  color: #c1c1c1;
  text-decoration: underline;
  font-size: 16px;
`
const Container = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 250px;
  }
`

const Topic = styled.h1`
  margin: 50px 0 40px 0;
  font-size: 26px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
`
const MobileCutWord = styled.br`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`
const CustomSwitch = styled(Switch.Gender)`
  color: #ffffff;
  margin-bottom: 16px;
  flex-direction: column;
  min-width: 250px;
  > div:first-child {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    > div:first-child {
      font-family: Prompt !important;
      width: fit-content;
    }
  }
  > div:last-child {
    width: 100%;
  }
`
const CustomCurrency = styled(Currency)`
  color: #ffffff;
  margin-bottom: 16px;
  flex-direction: column;
  min-width: 250px;
  > div:first-child {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    > div:first-child {
      font-family: Prompt !important;
      width: fit-content;
    }
  }
  > div:last-child {
    width: 100%;
    > input {
      ::placeholder {
        text-align: left;
        font-family: Prompt;
      }
    }
  }
`
const CustomCurrencyWithPeriod = styled(CurrencyWithPeriod)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  width: 100%;

  > div:first-child {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    > div:first-child {
      font-family: Prompt !important;
      width: fit-content;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;

      > input:first-child {
        max-width: calc(100% - 98px);
        min-width: calc(100% - 98px);
        border-radius: 4px 0px 0px 4px;
        ::placeholder {
          text-align: left;
          font-family: Prompt;
        }
      }

      > div:last-child {
        min-width: 98px;
        max-width: 98px;
        background-color: #ffd482;
        .Select-arrow-zone {
          color: #333333;
        }
        border-radius: 0px 4px 4px 0px !important;

        .Select-value-label {
          margin-left: -10px;
          font-size: 16px;
        }
        .Select-option {
          color: #333333 !important;
        }
      }
    }
  }

  @media (max-width: 425px) {
    align-items: flex-start;
  }
`

const NextButton = styled(Button)`
  min-width: 250px;
  max-width: 250px;
  min-height: 38px;
  margin-top: 14px;
`
const GetFromNoon = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  width: 228px;
  height: 123px;
  margin: 60px 0 30px;

  font-size: 20px;
  background: url(${Cloud}) no-repeat center;
  background-size: contain;
`
const GetFromNoonText = styled.div`
  position: absolute;
  top: 43px;

  font-size: 20px;
  font-weight: bold;
  color: white;
  line-height: 30px;
`
const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  > div:first-child {
    width: 320px;
    height: 200px;
    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  > div:last-child {
    padding: 0px 17px;
    margin-top: 20px;
    margin-bottom: 60px;
  }
`
const TopicImage = styled.div`
  color: #333333;
  font-size: 20px;
  font-family: Prompt;
  font-weight: 700;
`
const DescriptionImage = styled.div`
  color: #333333;
  font-size: 16px;
  font-family: Sarabun;
`

const validate = values => {
  const errors = {}
  if (!values.gender) {
    errors.gender = 'กรุณาระบุเพศ'
  }
  if (values.age === null || typeof values.age === 'undefined') {
    errors.age = 'กรุณาระบุอายุ'
  }
  if (
    values.income_salary === null ||
    typeof values.income_salary === 'undefined'
  ) {
    errors.income_salary = 'กรุณาระบุรายได้'
  }
  if (values.income_salary) {
    if (!values.income_salary.period) {
      errors.income_salary = 'กรุณาระบุรายได้'
    }
    if (
      values.income_salary.value === null ||
      typeof values.income_salary.value === 'undefined'
    ) {
      errors.income_salary = 'กรุณาระบุรายได้'
    }
  }

  return errors
}

const Landing = props => (
  <Layout>
    <WatchAgain onClick={props.onWatchAgain}>ดูขั้นตอนอีกครั้ง</WatchAgain>
    <Topic>
      ค้นหาแบบประกันที่ใช่ <MobileCutWord />
      ตามไลฟ์สไตล์คุณ
    </Topic>
    <Container>
      <Field
        name="gender"
        caption="เพศ"
        component={CustomSwitch}
        marginBottom="16px"
      />
      <Field
        name="age"
        caption="อายุ"
        component={CustomCurrency}
        placeholder="กรุณาระบุ"
        min={0}
        max={100}
      />
      <Field
        name="income_salary"
        caption="รายได้"
        component={CustomCurrencyWithPeriod}
        placeholder="กรุณาระบุ"
      />
    </Container>

    <NextButton onClick={props.onSubmit}>ถัดไป</NextButton>

    <GetFromNoon>
      <GetFromNoonText>คุณจะได้อะไรจากนูน?</GetFromNoonText>
    </GetFromNoon>

    <WrapperImage>
      <div>
        <img src={ImageOne} />
      </div>
      <div>
        <TopicImage>ใช้เวลารวดเร็ว</TopicImage>
        <DescriptionImage>
          NOON ได้เลือกสรรแบบประกันบำนาญ
          และออมทรัพย์ที่เข้ากับทุกไลฟ์สไตล์พร้อมให้นำไปลดหย่อนภาษีได้อย่างมีสเต็ป
        </DescriptionImage>
      </div>
    </WrapperImage>

    <WrapperImage>
      <div>
        <img src={ImageTwo} />
      </div>
      <div>
        <TopicImage>เลือกแบบประกันที่เหมาะสมกับคุณ</TopicImage>
        <DescriptionImage>
          NOON
          มีระบบคำนวณภาษีที่ได้มาตราฐานอ้างอิงตามหลักเกณฑ์ของสรรพากรจึงมั่นใจได้ว่าแบบประกันที่แนะนำสามารถนำไปลดหย่อนภาษีได้อย่างคุ้มค่าและเป็นไปตามที่กฏหมายกำหนด
        </DescriptionImage>
      </div>
    </WrapperImage>

    <WrapperImage>
      <div>
        <img src={ImageThree} />
      </div>
      <div>
        <TopicImage>มีตัวแทนสำหรับสอบถามได้</TopicImage>
        <DescriptionImage>
          NOON
          มีระบบคัดกรองตัวแทนที่จึงมั่นใจได้ว่าบริการที่ได้รับจะเป็นไปอย่างมืออาชีพ
          และมีประสิทธิภาพ
        </DescriptionImage>
      </div>
    </WrapperImage>

    <StepCalculateTaxModal
      isShowModal={props.stepCalculateTax}
      setIsShowModal={props.setStepCalculateTax}
      value={props.valueCheckbox}
    />
    <NotFoundProductModal
      isShowModal={props.notFoundProduct}
      setIsShowModal={props.setNotFoundProduct}
      errorLists={props.errorLists}
    />
  </Layout>
)

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    age: 'age.value',
    gender: 'gender.value',
    summaryData: 'summary_data.value',
    income_salary: 'income_salary.value',
  }),
  withProps(props => {
    const income_salary = props.income_salary.period
      ? props.income_salary
      : {...props.income_salary, period: 'month'}
    return {
      initialValues: {
        age: props.age,
        gender: props.gender,
        income_salary: income_salary,
      },
    }
  }),
  reduxForm({
    form: 'LandingForm',
    validate,
  }),
  withProps(props => ({
    valueCheckbox:
      (isClient &&
        JSON.parse(
          window.localStorage.getItem('Noon-Is-Close-Step-Calculate'),
        )) ||
      false,
  })),
  withState(
    'stepCalculateTax',
    'setStepCalculateTax',
    props => !props.valueCheckbox,
  ),
  withState('notFoundProduct', 'setNotFoundProduct', false),
  withState('errorLists', 'setErrorLists', []),
  withHandlers({
    onWatchAgain: props => () => {
      props.setStepCalculateTax(true)
    },
    onSubmit: props =>
      props.handleSubmit(async values => {
        const errorText = []

        let totalSalary
        if (values.income_salary.period === 'month') {
          totalSalary = values.income_salary.value * 12
        }
        if (values.income_salary.period === 'year') {
          totalSalary = values.income_salary.value
        }

        if ((values.age === 0 || values.age < 65) && totalSalary < 310000) {
          errorText.push('คุณไม่อยู่ในเกณฑ์เสียภาษี')
          errorText.push('อายุไม่ตรงกับเงื่อนไข ของแบบประกัน')
        }

        if (values.age >= 65 && totalSalary < 500000) {
          errorText.push('คุณไม่อยู่ในเกณฑ์เสียภาษี')
          errorText.push('อายุไม่ตรงกับเงื่อนไข ของแบบประกัน')
        }

        if (errorText.length > 0) {
          props.setErrorLists(errorText)
          props.setNotFoundProduct(true)
        } else {
          const data = {
            summary_data: {
              age: values.age,
              gender: values.gender,
              income_salary: values.income_salary,
            },
            ...values,
          }
          await spidusStore.updateAnswers(
            'noontax_config_web_tax_planning',
            data,
          )

          const names = [
            'age',
            'gender',
            'income_salary',
            'summary_data',
            'planning_deduct_pension_insurance',
            'planning_deduct_life_insurance',
            'deduct_life_insurance',
            'deduct_pension_insurance',
            'deduct_health_insurance',
            'deduct_ltf',
            'deduct_rmf',
            'deduct_special_donate',
            'deduct_general_donate',
            'deduct_sso',
            'deduct_provident_fund',
            'deduct_homeloan_interest',
          ]
          await spidusStore.loadAnswers(
            'noontax_config_web_tax_planning',
            ...names,
          )
          props.history.push(path.planningPath())
        }
      }),
  }),
)

export default enhancer(Landing)
