import React, {Fragment} from 'react'
import styled, {css} from 'react-emotion'
import Table from '../../components/Table'
import DateLable from '../DateLabel'
import {displayPercentage} from '../../../../core/helper'

const Wrapper = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 0 40px;
  padding-top: 0px;
  margin-bottom: 30px;
  :last-child {
    padding-top: 40px;
  }
  @media (max-width: 1024px) {
    width: 746px;
    padding: 20px;
  }
  @media (max-width: 500px) {
    width: calc(100vw - 10%);
  }
`
const Row = styled.div`
  display: flex;
  margin: 15px 40px;
  margin-bottom: 0;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`
const fontBold = css`
  font-weight: bold;
`
const Col = styled.div`
  flex: 1;
  :nth-child(odd) {
    font-weight: bold;
  }
`
const Label = styled.h3`
  padding: 0 40px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 20px;
`
const titleRow = css`
  font-weight: bold;
  flex: 2;
  @media (max-width: 500px) {
    flex: 1;
  }
`
const WrapMoblie = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`
const WrapTabel = styled.div`
  padding: 0 40px;
  @media (max-width: 500px) {
    display: none;
  }
`

const columns = [
  {
    title: 'ผลตอบแทน',
    dataIndex: 'name',
    key: 'name',
    className: titleRow,
  },
  {
    title: 'YTD',
    dataIndex: 'YTD',
    key: 'YTD',
  },
  {
    title: '3M',
    dataIndex: '3M',
    key: '3M',
  },
  {
    title: '6M',
    dataIndex: '6M',
    key: '6M',
  },
  {
    title: '1Y',
    dataIndex: '1Y',
    key: '1Y',
  },
  {
    title: '3Y',
    dataIndex: '3Y',
    key: '3Y',
  },
  {
    title: '5Y',
    dataIndex: '5Y',
    key: '5Y',
  },
  {
    title: '10Y',
    dataIndex: '10Y',
    key: '10Y',
  },
  {
    title: 'SI',
    dataIndex: 'SI',
    key: 'SI',
  },
]
const columns2 = [
  {
    title: 'ความผันผวน',
    dataIndex: 'name',
    key: 'name',
    className: titleRow,
  },
  {
    title: 'YTD',
    dataIndex: 'YTD',
    key: 'YTD',
  },
  {
    title: '3M',
    dataIndex: '3M',
    key: '3M',
  },
  {
    title: '6M',
    dataIndex: '6M',
    key: '6M',
  },
  {
    title: '1Y',
    dataIndex: '1Y',
    key: '1Y',
  },
  {
    title: '3Y',
    dataIndex: '3Y',
    key: '3Y',
  },
  {
    title: '5Y',
    dataIndex: '5Y',
    key: '5Y',
  },
  {
    title: '10Y',
    dataIndex: '10Y',
    key: '10Y',
  },
  {
    title: 'SI',
    dataIndex: 'SI',
    key: 'SI',
  },
]

export default props => {
  if (!props.performance || !props.performance.secBenchmarks) return null
  const {symbol, performance} = props
  const {
    secBenchmarks,
    secPerformanceDate,
    secYearToDateReturn,
    secThreeMonthsReturn,
    secSixMonthsReturn,
    secOneYearReturn,
    secThreeYearsReturn,
    secFiveYearsReturn,
    secTenYearsReturn,
    secSinceInceptionReturn,
    secYearToDateBenchmarkReturn,
    secThreeMonthsBenchmarkReturn,
    secSixMonthsBenchmarkReturn,
    secOneYearBenchmarkReturn,
    secThreeYearsBenchmarkReturn,
    secFiveYearsBenchmarkReturn,
    secTenYearsBenchmarkReturn,
    secSinceInceptionBenchmarkReturn,
    secYearToDateVolatility,
    secThreeMonthsVolatility,
    secSixMonthsVolatility,
    secOneYearVolatility,
    secThreeYearsVolatility,
    secFiveYearsVolatility,
    secTenYearsVolatility,
    secSinceInceptionVolatility,
    secYearToDateBenchmarkVolatility,
    secThreeMonthsBenchmarkVolatility,
    secSixMonthsBenchmarkVolatility,
    secOneYearBenchmarkVolatility,
    secThreeYearsBenchmarkVolatility,
    secFiveYearsBenchmarkVolatility,
    secTenYearsBenchmarkVolatility,
    secSinceInceptionBenchmarkVolatility,
    fiveYearsLossPercent,
    fiveYearsLossLastUpdDate,
  } = performance

  const dataReturn = [
    {
      key: '1',
      name: symbol,
      YTD: displayPercentage(secYearToDateReturn),
      '3M': displayPercentage(secThreeMonthsReturn),
      '6M': displayPercentage(secSixMonthsReturn),
      '1Y': displayPercentage(secOneYearReturn),
      '3Y': displayPercentage(secThreeYearsReturn),
      '5Y': displayPercentage(secFiveYearsReturn),
      '10Y': displayPercentage(secTenYearsReturn),
      SI: displayPercentage(secSinceInceptionReturn),
    },
    {
      key: '2',
      name: 'ดัชนีชี้วัด',
      YTD: displayPercentage(secYearToDateBenchmarkReturn),
      '3M': displayPercentage(secThreeMonthsBenchmarkReturn),
      '6M': displayPercentage(secSixMonthsBenchmarkReturn),
      '1Y': displayPercentage(secOneYearBenchmarkReturn),
      '3Y': displayPercentage(secThreeYearsBenchmarkReturn),
      '5Y': displayPercentage(secFiveYearsBenchmarkReturn),
      '10Y': displayPercentage(secTenYearsBenchmarkReturn),
      SI: displayPercentage(secSinceInceptionBenchmarkReturn),
    },
  ]
  const dataSource = [
    {
      key: '1',
      name: symbol,
      YTD: displayPercentage(secYearToDateVolatility),
      '3M': displayPercentage(secThreeMonthsVolatility),
      '6M': displayPercentage(secSixMonthsVolatility),
      '1Y': displayPercentage(secOneYearVolatility),
      '3Y': displayPercentage(secThreeYearsVolatility),
      '5Y': displayPercentage(secFiveYearsVolatility),
      '10Y': displayPercentage(secTenYearsVolatility),
      SI: displayPercentage(secSinceInceptionVolatility),
    },
    {
      key: '2',
      name: 'ดัชนีชี้วัด',
      YTD: displayPercentage(secYearToDateBenchmarkVolatility),
      '3M': displayPercentage(secThreeMonthsBenchmarkVolatility),
      '6M': displayPercentage(secSixMonthsBenchmarkVolatility),
      '1Y': displayPercentage(secOneYearBenchmarkVolatility),
      '3Y': displayPercentage(secThreeYearsBenchmarkVolatility),
      '5Y': displayPercentage(secFiveYearsBenchmarkVolatility),
      '10Y': displayPercentage(secTenYearsBenchmarkVolatility),
      SI: displayPercentage(secSinceInceptionBenchmarkVolatility),
    },
  ]

  const columnsMobile = [
    {
      title: 'ผลตอบแทน',
      dataIndex: 'name',
      key: 'name-return',
      className: titleRow,
    },
    {
      title: symbol,
      dataIndex: 'value',
      key: symbol,
    },
    {
      title: 'ดัชนีชี้วัด',
      dataIndex: 'indicator',
      key: 'indicator-return',
    },
  ]

  const returnDataMobile = [
    {
      name: 'YTD',
      value: displayPercentage(secYearToDateReturn),
      indicator: displayPercentage(secYearToDateBenchmarkReturn),
    },
    {
      name: '3M',
      value: displayPercentage(secThreeMonthsReturn),
      indicator: displayPercentage(secThreeMonthsBenchmarkReturn),
    },
    {
      name: '6M',
      value: displayPercentage(secSixMonthsReturn),
      indicator: displayPercentage(secSixMonthsBenchmarkReturn),
    },
    {
      name: '1Y',
      value: displayPercentage(secOneYearReturn),
      indicator: displayPercentage(secOneYearBenchmarkReturn),
    },
    {
      name: '3Y',
      value: displayPercentage(secThreeYearsReturn),
      indicator: displayPercentage(secThreeYearsBenchmarkReturn),
    },
    {
      name: '5Y',
      value: displayPercentage(secFiveYearsReturn),
      indicator: displayPercentage(secFiveYearsBenchmarkReturn),
    },
    {
      name: '10Y',
      value: displayPercentage(secTenYearsReturn),
      indicator: displayPercentage(secTenYearsBenchmarkReturn),
    },
    {
      name: 'SI',
      value: displayPercentage(secSinceInceptionReturn),
      indicator: displayPercentage(secSinceInceptionBenchmarkReturn),
    },
  ]

  const volatilityMobile = [
    {
      title: 'ความผันผวน',
      dataIndex: 'name',
      key: 'name-volatility',
      className: titleRow,
    },
    {
      title: symbol,
      dataIndex: 'value',
      key: `${symbol}-volatility`,
    },
    {
      title: 'ดัชนีชี้วัด',
      dataIndex: 'indicator',
      key: 'indicator-volatility',
    },
  ]

  const volatilityDataMobile = [
    {
      name: 'YTD',
      value: displayPercentage(secYearToDateVolatility),
      indicator: displayPercentage(secYearToDateBenchmarkVolatility),
    },
    {
      name: '3M',
      value: displayPercentage(secThreeMonthsVolatility),
      indicator: displayPercentage(secThreeMonthsBenchmarkVolatility),
    },
    {
      name: '6M',
      value: displayPercentage(secSixMonthsVolatility),
      indicator: displayPercentage(secSixMonthsBenchmarkVolatility),
    },
    {
      name: '1Y',
      value: displayPercentage(secOneYearVolatility),
      indicator: displayPercentage(secOneYearBenchmarkVolatility),
    },
    {
      name: '3Y',
      value: displayPercentage(secThreeYearsVolatility),
      indicator: displayPercentage(secThreeYearsBenchmarkVolatility),
    },
    {
      name: '5Y',
      value: displayPercentage(secFiveYearsVolatility),
      indicator: displayPercentage(secFiveYearsBenchmarkVolatility),
    },
    {
      name: '10Y',
      value: displayPercentage(secTenYearsVolatility),
      indicator: displayPercentage(secTenYearsBenchmarkVolatility),
    },
    {
      name: 'SI',
      value: displayPercentage(secSinceInceptionVolatility),
      indicator: displayPercentage(secSinceInceptionBenchmarkVolatility),
    },
  ]

  return (
    <Fragment>
      <Wrapper>
        <DateLable date={secPerformanceDate} />
        <Label>ผลการดำเนินงานย้อนหลัง</Label>
        <WrapTabel>
          <Table
            id="return"
            dataSource={dataReturn}
            columns={columns}
            classNameHeader={fontBold}
          />
        </WrapTabel>
        <WrapMoblie>
          <Table
            id="return-moblie"
            dataSource={returnDataMobile}
            columns={columnsMobile}
            classNameHeader={fontBold}
          />
        </WrapMoblie>
      </Wrapper>
      <Wrapper>
        <DateLable date={secPerformanceDate} />
        <Label>ความผันผวนของราคาย้อนหลัง</Label>
        <WrapTabel>
          <Table
            id="volatility"
            dataSource={dataSource}
            columns={columns2}
            classNameHeader={fontBold}
          />
        </WrapTabel>
        <WrapMoblie>
          <Table
            id="volatility-moblie"
            dataSource={volatilityDataMobile}
            columns={volatilityMobile}
            classNameHeader={fontBold}
          />
        </WrapMoblie>
      </Wrapper>
      <Wrapper>
        <DateLable date={fiveYearsLossLastUpdDate} />
        <Row>
          <Col>
            <div>ผลขาดทุนสูงสุดในช่วง 5 ปี (Maximum Drawdown)</div>
          </Col>
          <Col>
            <div>{displayPercentage(fiveYearsLossPercent)}</div>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row>
          <Col>
            <div>ดัชนีชี้วัดกองทุน</div>
          </Col>
          <Col>
            {secBenchmarks ? (
              secBenchmarks.map(benchmark => (
                <div key={benchmark}>{benchmark}</div>
              ))
            ) : (
              <div key="N/A">{'N/A'}</div>
            )}
          </Col>
        </Row>
      </Wrapper>
    </Fragment>
  )
}
