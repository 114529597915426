import React from 'react'
import styled from 'react-emotion'

export const HolddingTagContainer = styled.div`
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  right: 3px;
  top: -31px;
  height: 30px;
  border-radius: 5px 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFB933')};
  padding: 6px 20px;
  color: #333333;
`

const TagContaniner = props => (
  <HolddingTagContainer clasaName={props.clasaName} bgColor={props.bgColor}>
    {props.caption}
  </HolddingTagContainer>
)

export default TagContaniner
