import React from 'react'
import styled, {css} from 'react-emotion'
import {compose} from 'recompose'
import {ButtonGroup} from '../../../components/index'
import {withSpidus} from '../../../enhances'

const Layout = styled.div`
  display: flex;
  width: 100%;
`
const CustomButtonGroup = styled(ButtonGroup)`
  width: 100%;
`

const customStyle = css`
  width: 100%;
  margin-right: 0px;

  :first-child {
    border-radius: 0px;
    margin-right: 0.5px;
  }

  :last-child {
    border-radius: 0px;
    margin-left: 0.5px;
  }
`
const Category = props => (
  <Layout>
    <CustomButtonGroup customStyle={customStyle} {...props} />
  </Layout>
)

const enhance = compose(withSpidus())

export default enhance(Category)
