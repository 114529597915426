import React from 'react'
import styled from 'react-emotion'

const ContainerPercent = styled.div`
  position: relative;
`

const Percent = styled.span`
  position: absolute;
  font-size: 16px;
  top: 8px;
  right: 8px;
  color: black;
`

export const TextInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  border: 1px solid #a1a1a1;
  padding: 0 20px 0 10px;

  border: ${props =>
    props.disabled ? '1px solid rgba(255,212,130,0.5)' : '1px solid #ffd482'};
  background-color: white;

  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${props => (props.isNoWrap ? 'nowrap' : 'unset')};

  border-radius: 5px;
  color: black;

  text-align: right;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

  ::-webkit-input-placeholder {
    color: black;
    opacity: 0.6;
  }
  :-ms-input-placeholder {
    color: black;
    opacity: 0.6;
  }
  ::-moz-placeholder {
    color: black;
    opacity: 0.6;
  }
  :-moz-placeholder {
    color: black;
    opacity: 0.6;
  }
  > :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

export default props => (
  <ContainerPercent>
    <TextInput {...props} />
    <Percent>%</Percent>
  </ContainerPercent>
)
