import React from 'react'
import {compose, defaultProps, withProps} from 'recompose'
import {withSpidus} from '../../enhances'
import styled from 'react-emotion'
import DetailCard from './DetailCard'

const DetailPanelLayout = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  > div {
    margin-top: 10px;
  }
`

const DetailPanel = props => (
  <DetailPanelLayout>
    {props.value.map(detailGroup => (
      <DetailCard {...detailGroup} />
    ))}
  </DetailPanelLayout>
)

const enhance = compose(withSpidus())
export default enhance(DetailPanel)
