import React from 'react'
import styled, {css} from 'react-emotion'
import {
  compose,
  branch,
  withProps,
  withHandlers,
  defaultProps,
  withState,
  lifecycle,
} from 'recompose'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {displayNumber, numberParser} from '../../../core/helper'
import {withSpidus, withValue, withDisabled, omitProps} from '../enhances/index'
import AutosizeInput from 'react-input-autosize'

const disabledStyled = css`
  ${'' /* opacity: 0.8; */}
  ${'' /* background-color: #e5e5e5; */}
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  > div {
    color: #265fa7;
    font-weight: 600;
  }
`

const GroupField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  max-height: 56px;
  min-height: 56px;
  > svg {
    margin-left: 10px;
    color: #265fa7;
    font-size: 20px;
    cursor: pointer;
  }
`

const CustomCurrency = css`
  max-width: 250px;
  border: none;
  border-radius: 0;
  color: #265fa7;
  font-family: 'Prompt';
  font-weight: 700;

  font-size: 30px;
  background-color: transparent;
  padding: 0;
  > input {
    background-color: transparent;
    border: none;
    padding: 5px 0px;
    border-bottom: 1px solid #265fa7;
  }
`

const ReadOnlyInput = styled.div`
  color: #265fa7;
  font-family: 'Prompt';
  font-weight: 700;
  font-size: 30px;
`

const PenCurrency = props => (
  <Layout className={props.className}>
    {/* <div>เบี้ยประกัน (บาท)</div> */}
    {props.isEdit ? (
      <GroupField>
        <AutosizeInput
          className={CustomCurrency}
          ref={props.setInputRef}
          {...props}
        />
        <FontAwesomeIcon icon={['fas', 'save']} />
      </GroupField>
    ) : (
      <GroupField>
        <ReadOnlyInput>{props.value}</ReadOnlyInput>
        <FontAwesomeIcon
          icon={['fas', 'pen']}
          onClick={() => props.setIsEdit(true)}
        />
      </GroupField>
    )}
  </Layout>
)

const enhance = compose(
  branch(props => props.answerName, withSpidus(), withValue()),
  withState('isEdit', 'setIsEdit', props => props.readOnly || false),
  withState('inputRef', 'setInputRef', null),
  withDisabled(disabledStyled),
  defaultProps({maxLength: 8}),
  withHandlers({
    onChange: props => e => {
      const value = e.target.value ? numberParser(e.target.value) : null
      if (!value || value < 10 ** props.maxLength) {
        props.onChange && props.onChange(value)
      }
    },
    onBlur: props => e => {
      props.onBlur && props.onBlur(props.value)
      props.setIsEdit(false)
      props.setInputRef(null)
    },
  }),
  withProps(props => ({
    value:
      props.value === undefined || props.value === null
        ? ''
        : displayNumber(props.value),
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.inputRef) {
        this.props.inputRef.focus()
      }
    },
  }),
  omitProps('maxLength', 'answerName', 'inputRef'),
)

export default enhance(PenCurrency)
