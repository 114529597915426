import React from 'react'
import styled from 'react-emotion'
import {Icon, Checkbox} from 'antd'

const CheckboxGroup = ({...input}) => (
  <Checkbox.Group
    {...input}
    value={input.value}
    onChange={value => input.onChange(value)}
    onBlur={() => input.onBlur()}
  />
)

export const CustomCheckbox = styled(CheckboxGroup)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .ant-checkbox-wrapper {
    color: black;
    font-size: 16px;
    width: 100%;
    margin: 0 0 20px;
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 2px solid black;
        border-radius: 50%;
        background-color: transparent;
        ::after {
          top: 1px;
          left: 5px;
          width: 6px;
          height: 12px;
        }
      }
    }
  }
  .ant-checkbox-wrapper-checked,
  .ant-checkbox-wrapper-checked:hover {
    .ant-checkbox-checked {
      color: white;
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-inner {
        background-color: #9fd134;
        border-color: #9fd134;
      }
      :after {
        border-color: #9fd134;
        border-radius: 50%;
      }
    }
  }

  .ant-checkbox-disabled + span {
    color: black;
  }
`
export const CheckboxContainer = styled.div`
  margin-right: 18px;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

export const CheckboxInput = styled(Checkbox)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border: 2px solid black;
      border-radius: 50%;
      background-color: transparent;
      ::after {
        top: 1px;
        left: 5px;
        width: 6px;
        height: 12px;
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: #9fd134;
      background-color: #9fd134;
    }
    :after {
      border-color: #9fd134;
      border-radius: 50%;
    }
  }
`

export const CustomCheckboxInput = styled.div`
  position: absolute;
  top: 3px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CheckIcon = styled(Icon)`
  font-size: 14px;
`

export const Check = () => <CheckIcon type="check" theme="outlined" />
