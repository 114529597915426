import spidusStore from './spidusStore'
import {connect} from 'react-redux'
import {getFormValues} from 'redux-form'
import objectPath from 'object-path'
import {camelize} from 'humps'

const withAnswers = (configName, mapAnswerToProps) =>
  connect(state => {
    if (mapAnswerToProps) {
      return Object.entries(mapAnswerToProps).reduce(
        (props, [name, valuePath]) => ({
          ...props,
          [name]: objectPath.get(
            getFormValues(spidusStore.STORE_NAME)(state),
            `answers.${configName}.${valuePath}`,
            undefined,
          ),
        }),
        {},
      )
    } else {
      const answers = {
        ...objectPath.get(
          getFormValues(spidusStore.STORE_NAME)(state),
          `answers`,
          {},
        ),
      }

      answers.$ = (...args) => {
        objectPath.get(answers, ...args)
      }

      answers.updateAnswers = values => {
        if (values === undefined) {
          values = Object.keys(answers).reduce(
            (memo, key) => ({...memo, [key]: answers[key].value}),
            {},
          )
        }
        spidusStore.updateAnswers(configName, values)
      }

      return {
        [camelize(configName)]: answers,
      }
    }
  })

export default withAnswers
