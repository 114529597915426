import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withHandlers, withProps} from 'recompose'
import {withSpidus} from '../../../enhances'
import {Checkbox} from '../../../components/index'

const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #d9d9dc;
`
const CustomCaption = styled.span`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
`
const CustomCheckbox = styled(Checkbox)`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
`

const Recommended = props => (
  <Layout>
    <CustomCaption>{props.caption}</CustomCaption>
    <Container>
      {props.items.map(item => (
        <CustomCheckbox
          key={`${item.type}-${props.itemIncluded(item) ? 'true' : 'false'}`}
          caption={item.caption}
          category={item.category}
          value={props.itemIncluded(item)}
          onSelect={props.onSelectItem(item)}
        />
      ))}
    </Container>
  </Layout>
)

const enhance = compose(
  withSpidus(),
  withProps(props => ({...props.value})),
  withProps(props => ({
    items: props.items || [],
  })),
  withHandlers({
    itemIncluded: props => item =>
      !!props.selectedItems.find(
        selectedItem => selectedItem.type === item.type,
      ),
  }),
)

export default enhance(Recommended)
