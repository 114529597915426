import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withState} from 'recompose'
import styled, {css} from 'react-emotion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigate} from '@reach/router'
import Loading from '../../share/Loading'
import {displayNumber, url2Obj} from '../../../core/helper'

import Auth from '../../Auth'
import {login} from '../../../ducks/auth'
import {setProductCompareList} from '../../../ducks/product'
import {responsive} from '../../../core/style'

import RowTopic from './RowTopic'
import LifeColumnContent from './LifeColumnContent'
import MotorColumnContent from './MotorColumnContent'
import Button from '../../share/Button'
import Modal from '../../share/Modal'

import Cloud1Svg from '../../../static/images/1cloud-orange.svg'
import Cloud2Svg from '../../../static/images/2cloud-orange.svg'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  height: ${props => (props.isLogin ? 'unset' : '100vh')};
  overflow: ${props => (props.isLogin ? 'unset' : 'hidden')};
`

const HeaderTab = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  padding-left: 120px;
  align-items: center;
  justify-content: flex-start;
  background-color: #093771;
  z-index: 7;

  ${props =>
    responsive.tablet(css`
      padding: 0 30px;
    `)};
`

const HeaderText = styled.div`
  display: flex;
  width: 537px;
  font-size: 16px;
  color: white;
  flex-wrap: wrap;
`

const TableContainer = styled.div`
  display: flex;
  height: ${props => (props.isLogin ? 'unset' : 'calC(100vh - 130px)')};
  ${props =>
    responsive.tablet(css`
      width: 100%;
      padding-top: 10px;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    `)};
`

const SaveCompareContainer = styled.div`
  display: flex;
  width: 915px;
  margin: 12px 0 22px;
  align-items: center;
  justify-content: flex-end;
  min-height: 20px;
`

const LoginContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100vw;
  display: flex;
  height: 144.31px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );
`

const MobileSpan = styled.span`
  ${props =>
    responsive.mobile(css`
      display: none;
    `)};
`

const MobileBr = styled.br`
  display: none;
  ${props =>
    responsive.mobile(css`
      display: flex;
    `)};
`

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: ${props =>
      props.category === 'login'
        ? '20px 0'
        : props.category === 'register'
        ? '0 0 60px'
        : '20px 0 60px'};
  }
`

const Cloud1 = styled.img`
  position: absolute;
  top: 220px;
  left: -80px;
  width: 80px;
  height: 30px;
  animation-iteration-count: infinite;
  animation-duration: 60s;
  animation-name: cloud1;
  animation-timing-function: linear;
  z-index: 0;
  animation-delay: 1s;

  @keyframes cloud1 {
    0% {
      left: -80px;
    }
    100% {
      left: 100vw;
    }
  }
`

const Cloud2 = styled(Cloud1)`
  top: 270px;
  animation-duration: 40s;
  animation-delay: -25s;
`
const Cloud3 = styled(Cloud1)`
  top: 180px;
  animation-duration: 50s;
  animation-delay: -35s;
`
const Cloud4 = styled(Cloud1)`
  width: 60px;
  height: 36px;
  top: 180px;
  animation-duration: 80s;
  animation-delay: -50s;
`

const LoginButton = styled(Button)``

const gender = 'เพศ'
const age = '26'
const familyDebt = '8000000'
const planing = 'วางแผนปกป้องภาระที่อาจจะตกถึงครอบครัว'

const Compare = ({
  isSave,
  setSave,
  isLogin,
  login,
  compareList,
  category,
  isLoading,
  isShowModal,
  setIsShowModal,
  setCategory,
  iscategory,
  level,
  params,
  ...props
}) => (
  <Loading isLoading={isLoading}>
    <Container isLogin={isLogin}>
      <Cloud1 src={Cloud1Svg} />
      <Cloud2 src={Cloud2Svg} />
      <Cloud3 src={Cloud2Svg} />
      <Cloud4 src={Cloud1Svg} />
      {/* <HeaderTab>
        <HeaderText>
          <MobileSpan>สำหรับ</MobileSpan> เพศ {gender} อายุ {age} ปี{' '}
          <MobileSpan>โดยวางแผนว่าต้องการ</MobileSpan>
          <MobileBr />
          ทุนประกัน {displayNumber(familyDebt)} บาท <br />
          <MobileSpan>เพื่อ {planing}</MobileSpan>
        </HeaderText>
      </HeaderTab> */}
      <SaveCompareContainer onClick={() => setSave(!isSave)}>
        {/* <HeartIcon icon={[isSave ? 'fas' : 'far', 'heart']} />
      <ComparisonSavingText>บันทึกแบบเปรียบเทียบ</ComparisonSavingText> */}
      </SaveCompareContainer>
      <TableContainer isLogin={isLogin}>
        <RowTopic category={category} />
        {category === 'life' && (
          <LifeColumnContent
            category={category}
            level={level}
            params={params}
          />
        )}
        {category === 'motor' && (
          <MotorColumnContent
            category={category}
            level={level}
            params={params}
          />
        )}
      </TableContainer>
      {isLogin ? (
        ''
      ) : (
        <LoginContainer>
          <LoginButton
            onClick={() => setIsShowModal(!isShowModal)}
            icons={['fa', 'user-circle']}>
            เข้าสู่ระบบเพื่อดูข้อมูลแบบละเอียด
          </LoginButton>
        </LoginContainer>
      )}
      <CustomModal
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        category={category}>
        <Auth
          modal
          setCategory={setCategory}
          category={iscategory}
          onHeaderRegister={() => setCategory('register')}
          onHeaderLogin={() => setCategory('login')}
          onSetConfirmEmail={() => setCategory('confirmemail')}
          onSetForgetPassword={() => setCategory('forgetPassword')}
          closeModal={() => setIsShowModal(false)}
        />
      </CustomModal>
    </Container>
  </Loading>
)

const enhancer = compose(
  withState('isSave', 'setSave', false),
  withState('isShowModal', 'setIsShowModal', false),
  withState('iscategory', 'setCategory', 'login'),
  connect(
    (state, props) => ({
      isLogin: state.auth.isLogin,
      compareList: state.product.compareList,
      params: url2Obj(props.location.search),
      isLoading: state.product.isLoading,
    }),
    {login, setProductCompareList},
  ),
  lifecycle({
    componentDidMount() {
      this.props.setProductCompareList(this.props.params, this.props.category)
    },
  }),
)

export default enhancer(Compare)
