import React from 'react'
import styled, {css} from 'react-emotion'
import {compose, withProps} from 'recompose'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from '../../../NoonQuestion/components/index'
import {withAnswers} from '../../enhances'
import {toCurrency} from '../../core/helper'

import * as path from '../../common/path'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const MobileCutWord = styled.br`
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }
`
const ChooseProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
`
const Topic = styled.div`
  font-size: 20px;
  font-family: Prompt;
  font-weight: 700;
  color: #265fa7;
  text-align: center;
`
const ProductCard = styled.div`
  border: 1px solid #6a94cb;
  border-radius: 5px;
  max-width: 280px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  margin-top: 20px;
  color: #265fa7;
  font-size: 12px;
`
const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
`
const TopicTextInCard = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  > svg {
    font-size: 30px;
    margin-right: 15px;
  }
`
const TopicInCard = styled.div`
  font-weight: 700;
`
const ValueInCard = styled.div`
  display: flex;
  align-items: center;
  > div {
    font-size: 16px;
    color: #9fd134;
    font-weight: 700;
  }
`
const DescriptionTextInCard = styled.div`
  font-size: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > div:last-child {
    color: #888888;
  }
`
const WrapperRight = styled.div`
  > svg {
    color: #265fa7;
  }
`

const Product = props => (
  <Layout>
    <ChooseProduct>
      <Topic>
        เลือกประเภทประกัน <MobileCutWord /> ที่คุณจะใช้ลดหย่อนภาษี
      </Topic>
      <ProductCard
        onClick={() => props.history.push(path.annuityPlanningPath())}>
        <WrapperLeft>
          <TopicTextInCard>
            <FontAwesomeIcon icon={['fas', 'man-with-cane']} />
            <div>
              <TopicInCard>ประกันบำนาญ</TopicInCard>
              <ValueInCard>
                ลดภาษีได้สูงสุด&nbsp;
                <div>
                  {toCurrency(props.maxAnnuityInsuranceReducedTax, {
                    prefix: '',
                  })}
                </div>{' '}
                &nbsp;บาท *
              </ValueInCard>
            </div>
          </TopicTextInCard>
          <DescriptionTextInCard>
            <div>มองอนาคต มีเงินใช้หลังเกษียณอย่างสม่ำเสมอ</div>
            <div>
              * เมื่อซื้อประกันบำนาญเป็นเงิน{' '}
              {toCurrency(props.maxAnnuityInsuranceInvest, {prefix: ''})} บาท
            </div>
          </DescriptionTextInCard>
        </WrapperLeft>
        <WrapperRight>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </WrapperRight>
      </ProductCard>

      <ProductCard
        onClick={() => props.history.push(path.savingPlanningPath())}>
        <WrapperLeft>
          <TopicTextInCard>
            <FontAwesomeIcon icon={['fas', 'coins']} />
            <div>
              <TopicInCard>ประกันออมทรัพย์</TopicInCard>
              <ValueInCard>
                ลดภาษีได้สูงสุด &nbsp;
                <div>
                  {toCurrency(props.maxSavingInsuranceReducedTax, {prefix: ''})}
                </div>{' '}
                &nbsp;บาท *
              </ValueInCard>
            </div>
          </TopicTextInCard>
          <DescriptionTextInCard>
            <div>มีเงินออม แถมได้ความคุ้มครอง</div>
            <div>
              * เมื่อซื้อประกันออมทรัพย์เป็นเงิน{' '}
              {toCurrency(props.maxSavingInsuranceInvest, {prefix: ''})} บาท
            </div>
          </DescriptionTextInCard>
        </WrapperLeft>
        <WrapperRight>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </WrapperRight>
      </ProductCard>
    </ChooseProduct>
  </Layout>
)

const enhancer = compose(
  withAnswers('noontax_config_web_tax_planning', {
    maxAnnuityInsuranceInvest: 'summary_data.value.maxAnnuityInsuranceInvest',
    maxSavingInsuranceInvest: 'summary_data.value.maxSavingInsuranceInvest',
    maxAnnuityInsuranceReducedTax:
      'summary_data.value.maxAnnuityInsuranceReducedTax',
    maxSavingInsuranceReducedTax:
      'summary_data.value.maxSavingInsuranceReducedTax',
  }),
)

export default enhancer(Product)
