import React from 'react'
import styled, {css} from 'react-emotion'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import {navigate} from '@reach/router'

import Button from '../share/Button'
import Loading from '../share/Loading'

import {responsive} from '../../core/style'
import {checkToken} from '../../ducks/auth'
import {url2Obj} from '../../core/helper'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`

const LoginButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 30px;
  font-size: 12px;

  ${props =>
    responsive.mobile(css`
      min-width: 280px;
      height: 40px;
      font-size: 16px;
    `)}
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.BLUE};
`

const Success = ({name, isLoading, errorMessage}) => (
  <Loading isLoading={isLoading}>
    <Container>
      <Header>{name}</Header>
      <LoginButton
        onClick={() => {
          navigate(`/authorize/login`)
        }}
        icons={['fa', 'user-circle']}>
        เข้าสู่ระบบ
      </LoginButton>
    </Container>
  </Loading>
)

const enhancer = compose(
  connect(
    (state, props) => ({
      isLoading: state.auth.isLoading,
    }),
    {checkToken},
  ),
  lifecycle({
    componentDidMount() {
      const {
        checkToken,
        type,
        location: {search},
      } = this.props
      checkToken(url2Obj(search), type)
    },
  }),
)

export default enhancer(Success)
