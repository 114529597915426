import React from 'react'
import styled from 'react-emotion'
import {connect} from 'react-redux'
import {compose, withState, lifecycle} from 'recompose'
import {displayNumber} from '../../../core/helper'
import {getTotalDetail} from '../../../ducks/profile'

const Hr = styled.hr`
  background-color: #6a94cb;
  height: 1px;
  margin: 30px 0;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  background-color: white;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  opacity: 1;
  padding: 20px 15px;
  font-family: 'Prompt';
  * {
    font-family: 'Prompt';
  }
`

const ShowDetailHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: 'Prompt';

  display: flex;
  justify-content: flex-start;

  &.CustomSub {
    font-size: 14px;
    margin-top: 2px;
  }
`

const ShowDetailContainer = styled.div`
  display: flex;
  flex-direction: row;

  &.CustomBlackTopLine {
    color: #333333;
    justify-content: space-between;
  }

  &.CustomGrayTopLine {
    color: #7f7f7f;
    justify-content: flex-end;
  }

  &.CustomGrayButtomLine {
    color: #7f7f7f;
    margin-left: 37px;
  }
`

const ShowDetailNormalText = styled.div`
  font-size: 14px;
`

const ShowDetailNormalTextBath = styled.div`
  font-size: 14px;
  text-align: center;
  margin-left: 10px;

  &.CustomRecommend {
    color: #9fd134;
  }
`

const ShowDetailNormalNumber = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  text-align: center;

  &.CustomSumInsuredText {
    font-family: 'Prompt';
    min-width: 98px;
  }

  &.CustomPremiumText {
    font-family: 'Prompt';
    min-width: 69px;
  }

  &.CustomText {
    min-width: 69px;
  }

  &.CustomPayDaily {
    min-width: 277px;
  }

  &.CustomPayRoom {
    min-width: 314px;
  }

  &.CustomAllPay {
    min-width: 304px;
  }

  &.CustomPayExternalPatient {
    min-width: 247px;
  }

  &.CustomDeadlyDisease {
    min-width: 199px;
  }

  &.CustomDeadByCancer {
    min-width: 196px;
  }

  &.CustomPayCancer {
    min-width: 202px;
  }

  &.CustomSaveLife {
    min-width: 153px;
  }

  &.CustomDailyPayAccident {
    min-width: 190px;
  }
`

const ShowDetailNormalNumberCar = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  text-align: center;

  &.CustomCoverageFireNSteal {
    min-width: 193px;
  }

  &.CustomFirstPartDamages {
    min-width: 245px;
  }

  &.CustomForExPerPerson {
    min-width: 154px;
  }

  &.CustomForExPerTimes {
    min-width: 146px;
  }

  &.CustomThirdPartyProperty {
    min-width: 154px;
  }

  &.CustomPersonalAccident {
    min-width: 244px;
  }

  &.CustomDriverInsurance {
    min-width: 264px;
  }

  &.CustomMedicalFee {
    min-width: 259px;
  }
`

const DetailContainer = styled.div`
  display: flex;

  &.CustomTop {
    margin-top: 15px;
  }

  &.CustomButtom {
    margin-top: 10px;
    flex-direction: column;
  }
`

const ShowDetailSubHeader = styled.div`
  color: #333333;
  font-size: 14px;
  font-family: 'Prompt';
  margin: 15px 0 0 19px;

  &.CustomSubFirstCarHave {
    margin-left: 251px;
  }

  &.CustomSubFirstHave {
    margin-left: 283px;
  }

  &.CustomSubFirstRecommend {
    margin-left: 105px;
    color: #9fd134;
  }

  &.CustomSubNext {
    margin-top: 20px;
  }
`

const RecommendValue = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  min-width: 109px;
  color: #9fd134;
`

const FirstSubHeader = styled.div`
  display: flex;
  flex-direction: row;
  color: #9fd134;
`

const ShowPortDetail = props => (
  <Container>
    <ShowDetailHeader>ประกันชีวิต</ShowDetailHeader>

    <ShowDetailContainer className="CustomBlackTopLine">
      <DetailContainer className="CustomTop">
        <ShowDetailNormalText>ทุนประกัน รวม</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomSumInsuredText">
          {displayNumber(props.totalDetailLife.totalSumInsured)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
      </DetailContainer>

      <DetailContainer className="CustomTop">
        <ShowDetailNormalText>เบี้ยที่ต้องจ่ายรายปี รวม</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomPremiumText">
          {displayNumber(props.totalDetailLife.totalPremium)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
      </DetailContainer>
    </ShowDetailContainer>

    <ShowDetailContainer className="CustomGrayTopLine">
      <DetailContainer>
        <ShowDetailNormalText>
          เบี้ยประกันที่ต้องจ่ายรายปี รวม
        </ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomText">
          {displayNumber(79400)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
      </DetailContainer>
    </ShowDetailContainer>

    <ShowDetailContainer className="CustomGrayTopLine">
      <DetailContainer>
        <ShowDetailNormalText>
          เบี้ยอนุสัญญาที่ต้องจ่ายรายปี รวม
        </ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomText">
          {displayNumber(39023)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
      </DetailContainer>
    </ShowDetailContainer>

    <Hr></Hr>

    <ShowDetailHeader>
      ประกันสุขภาพ
      <ShowDetailHeader className="CustomSub">(จากอนุสัญญา)</ShowDetailHeader>
    </ShowDetailHeader>

    <FirstSubHeader>
      <ShowDetailSubHeader>ค่ารักษาพยาบาล</ShowDetailSubHeader>
      <ShowDetailSubHeader className="CustomSubFirstHave">
        มีอยู่
      </ShowDetailSubHeader>
      <ShowDetailSubHeader className="CustomSubFirstRecommend">
        แนะนำ
      </ShowDetailSubHeader>
    </FirstSubHeader>

    <DetailContainer className="CustomButtom">
      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ชดเชยรายวัน</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomPayDaily">
          {displayNumber(2000)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(2000)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ค่าห้อง</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomPayRoom">
          {displayNumber(3500)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(3500)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>เหมาจ่าย</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomAllPay">
          {displayNumber(1000000)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(1000000)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ค่ารักษาผู้ป่วยนอก</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomPayExternalPatient">
          {displayNumber(0)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>
    </DetailContainer>

    <ShowDetailSubHeader className="CustomSubNext">
      ทุนประกันโรคร้ายแรง
    </ShowDetailSubHeader>

    <DetailContainer className="CustomButtom">
      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>วงเงินคุ้มครองโรคร้ายแรง</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomDeadlyDisease">
          {displayNumber(3000000)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(3000000)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>กรณีเสียชีวิตด้วยโรคมะเร็ง</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomDeadByCancer">
          {displayNumber(0)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ค่ารักษามะเร็งต่อวัน (รวม)</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomPayCancer">
          {displayNumber(0)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>
    </DetailContainer>

    <ShowDetailSubHeader className="CustomSubNext">
      ประกันอุบัติเหตุ
    </ShowDetailSubHeader>

    <DetailContainer className="CustomButtom">
      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>
          วงเงินคุ้มครองชีวิตและทุพพลภาพ
        </ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomSaveLife">
          {displayNumber(0)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ค่าชดเชยรายวันจากอุบัติเหตุ</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomDailyPayAccident">
          {displayNumber(0)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>
    </DetailContainer>

    <Hr></Hr>

    <ShowDetailHeader>ประกันรถยนต์</ShowDetailHeader>

    <ShowDetailContainer className="CustomBlackTopLine">
      <DetailContainer className="CustomTop">
        <ShowDetailNormalText>ทุนประกัน รวม</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomSumInsuredText">
          {displayNumber(600000)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
      </DetailContainer>

      <DetailContainer className="CustomTop">
        <ShowDetailNormalText>เบี้ยที่ต้องจ่ายรายปี รวม</ShowDetailNormalText>
        <ShowDetailNormalNumber className="CustomPremiumText">
          {displayNumber(600000)}
        </ShowDetailNormalNumber>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
      </DetailContainer>
    </ShowDetailContainer>

    <FirstSubHeader>
      <ShowDetailSubHeader>ความคุ้มครองรถยนต์</ShowDetailSubHeader>
      <ShowDetailSubHeader className="CustomSubFirstCarHave">
        มีอยู่
      </ShowDetailSubHeader>
      <ShowDetailSubHeader className="CustomSubFirstRecommend">
        แนะนำ
      </ShowDetailSubHeader>
    </FirstSubHeader>

    <DetailContainer className="CustomButtom">
      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>คุ้มครองไฟไหม้และโจรกรรม</ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomCoverageFireNSteal">
          {displayNumber(600000)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(2000)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ค่าเสียหายส่วนแรก</ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomFirstPartDamages">
          {displayNumber(0)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(3500)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>
    </DetailContainer>

    <ShowDetailSubHeader className="CustomSubNext">
      ความคุ้มครองบุคคลภายนอก
    </ShowDetailSubHeader>

    <DetailContainer className="CustomButtom">
      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>
          ค่าชดเชยชีวิตบุคคลภายนอกต่อคน
        </ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomForExPerPerson">
          {displayNumber(600000)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(300000)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>
          ค่าชดเชยชีวิตบุคคลภายนอกต่อครั้ง
        </ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomForExPerTimes">
          {displayNumber(600000)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>
          ค่าชดเชยชีวิตบุคคลภายนอกต่อคน
        </ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomThirdPartyProperty">
          {displayNumber(0)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>
    </DetailContainer>

    <ShowDetailSubHeader className="CustomSubNext">
      ความคุ้มครองบุคคลภายนอก
    </ShowDetailSubHeader>

    <DetailContainer className="CustomButtom">
      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>อุบัติเหตุส่วนบุคคล</ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomPersonalAccident">
          {displayNumber(600000)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ประกันตัวผู้ขับขี่</ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomDriverInsurance">
          {displayNumber(600000)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>

      <ShowDetailContainer className="CustomGrayButtomLine">
        <ShowDetailNormalText>ค่ารักษาพยาบาล</ShowDetailNormalText>
        <ShowDetailNormalNumberCar className="CustomMedicalFee">
          {displayNumber(0)}
        </ShowDetailNormalNumberCar>
        <ShowDetailNormalTextBath>บาท</ShowDetailNormalTextBath>
        <RecommendValue>{displayNumber(0)}</RecommendValue>
        <ShowDetailNormalTextBath className="CustomRecommend">
          บาท
        </ShowDetailNormalTextBath>
      </ShowDetailContainer>
    </DetailContainer>
  </Container>
)

const enhancer = compose(
  connect(
    state => ({
      totalDetailLife:
        state.profile.totalDetailLife !== undefined &&
        state.profile.totalDetailLife,
    }),
    {getTotalDetail},
  ),
  lifecycle({
    componentDidMount() {
      const {getTotalDetail} = this.props
      getTotalDetail()
    },
  }),
)

export default enhancer(ShowPortDetail)
