import React from 'react'
import {connect} from 'react-redux'
import {compose, lifecycle, withHandlers, withState} from 'recompose'
import styled from 'react-emotion'
import {change, formValueSelector, Field as CustomField} from 'redux-form'
import {
  displayNumber,
  numberParser,
  inRange,
  convertNaNtoZero,
} from '../../../../../../core/helper'
import SearchFactor from '../SearchFactor'

import Panel from '../PanelForm'
import {breakpoints} from '../../../../../../core/style'
import {
  FooterFormContainer,
  FieldContainer,
  Unit,
  TextInput,
  TextFooter,
  CustomButton,
  Summary,
  Label,
  LabelSummary,
} from '../ShareFieldForm'

const CustomFieldContainer = styled(FieldContainer)`
  margin-left: 23px;

  .retire {
    .label {
      max-width: 230px;
    }
  }
`

const InfoUnit = styled.span`
  font-size: 12px;
`
const ContainerUnit = styled.div`
  display: flex;
  flex-direction: column;
`

const CustomTextFooter = styled(TextFooter)`
  height: unset;
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #6a94cb;
  margin-bottom: 30px;
`

const CustomText = styled.b`
  font-size: 20px;
`

const SummaryAdvance = ({finalSummary}) => (
  <CustomText>{displayNumber(Math.round(finalSummary))} บาท</CustomText>
)

const Nothing = () => <div />

const RetireForm = ({
  totalList,
  percent,
  onSummary,
  onPercentSummary,
  firstSummary,
  secondSummary,
  thirdSummary,
  finalSummary,
  age,
  windowWidth,
}) => (
  <Panel>
    <div>
      <Header>สมมติฐาน</Header>
      <CustomFieldContainer>
        <TextInput
          name="inflationRate"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ณ อัตราเงินเฟ้อ (ต่อปี)'
              : 'ณ อัตราเงินเฟ้อ'
          }`}
          type="percent"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />

        <Unit>ต่อปี</Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="presentCost"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ค่าใช้จ่ายปัจจุบัน (บาท/ เดือน)'
              : 'ค่าใช้จ่ายปัจจุบัน'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>บาท/เดือน</Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="expectedRetireAge"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'อายุที่คาดว่าจะเกษียณ (ปี)'
              : 'อายุที่คาดว่าจะเกษียณ'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>ปี</Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          className="retire"
          name="costRateAfterRetire"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'อัตราส่วนการใช้จ่ายหลังเกษียณ เทียบกับปัจจุบัน (% ของค่าใช้จ่ายในปัจจุบัน)'
              : 'อัตราส่วนการใช้จ่ายหลังเกษียณ เทียบกับปัจจุบัน'
          }`}
          type="percent"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <ContainerUnit>
          <Unit>ของค่าใช้จ่าย</Unit>
          <Unit>ในปัจจุบัน</Unit>
        </ContainerUnit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'จำนวนปีก่อนเกษียณ (ปี)'
              : 'จำนวนปีก่อนเกษียณ'}
          </Label>
          <LabelSummary>
            {firstSummary <= 0 ? 0 : displayNumber(Math.round(firstSummary))}
          </LabelSummary>
        </Summary>
        <Unit>ปี</Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="expectedDeadAge"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'อายุคาดหมาย (ปี)'
              : 'อายุคาดหมาย'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>ปี</Unit>
      </CustomFieldContainer>
      <Divider />
      <Header>คาดการณ์ช่วงเกษียณ</Header>
      <CustomFieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? `ค่าใช้จ่ายในช่วงเกษียณอายุ ณ วันที่เริ่มเกษียณ (บาท/ เดือน ที่อัตราเงินเฟ้อ ${
                  Number.isNaN(percent) ? '0' : percent
                }% ต่อปี)`
              : 'ค่าใช้จ่ายในช่วงเกษียณอายุ ณ วันที่เริ่มเกษียณ'}
          </Label>
          <LabelSummary>
            {displayNumber(Math.round(secondSummary))}
          </LabelSummary>
        </Summary>
        <Unit>
          บาท/เดือน
          <br />
          <InfoUnit>
            ที่อัตราเงินเฟ้อ {Number.isNaN(percent) ? '0' : percent}% ต่อปี
          </InfoUnit>
        </Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="incomeRate"
          label="สัดส่วนรายได้ที่จำเป็นต่อรายได้ทั้งหมด"
          type="percent"
          placeholder="0"
          normalize={inRange(0, 100)}
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="costRequired"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'ค่าใช้จ่ายจำเป็น (บาท/ เดือน)'
              : 'ค่าใช้จ่ายจำเป็น'
          }`}
          type="text"
          placeholder="0"
          normalize={inRange(0, Math.round(secondSummary))}
          format={displayNumber}
          parse={numberParser}
          didChange={onPercentSummary}
        />
        <Unit>บาท/เดือน</Unit>
      </CustomFieldContainer>

      <CustomFieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'ค่าใช้จ่ายจำเป็น ณ อายุคาดหมาย (บาท/ เดือน)'
              : 'ค่าใช้จ่ายจำเป็น ณ อายุคาดหมาย'}
          </Label>
          <LabelSummary>
            {Number.isNaN(thirdSummary)
              ? 0
              : displayNumber(Math.round(thirdSummary))}
          </LabelSummary>
        </Summary>
        <Unit>บาท/เดือน</Unit>
      </CustomFieldContainer>
      <Divider />
      <Header>เตรียมพร้อมค่าใช้จ่าย</Header>
      <CustomFieldContainer>
        <Summary>
          <Label>
            {windowWidth <= breakpoints.mobile
              ? 'ค่าใช้จ่ายจำเป็นที่ควรเตรียมไว้ (บาท/ เดือน)'
              : 'ค่าใช้จ่ายจำเป็นที่ควรเตรียมไว้'}
          </Label>
          <LabelSummary>
            {Number.isNaN(thirdSummary)
              ? 0
              : displayNumber(Math.round(thirdSummary))}
          </LabelSummary>
        </Summary>
        <Unit>บาท/เดือน</Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="pension"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เงินบำนาญที่คาดว่าจะได้รับ (บาท/ เดือน ตั้งแต่เกษียณจนอายุคาดหมาย)'
              : 'เงินบำนาญที่คาดว่าจะได้รับ'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>
          บาท/เดือน
          <br />
          <InfoUnit>ตั้งแต่เกษียณจนอายุคาดหมาย</InfoUnit>
        </Unit>
      </CustomFieldContainer>
      <CustomFieldContainer>
        <TextInput
          name="etcIncome"
          label={`${
            windowWidth <= breakpoints.mobile
              ? 'เงินได้ประจำอื่นที่คาดว่าจะได้รับ (บาท/ เดือน ตั้งแต่เกษียณจนอายุคาดหมาย)'
              : 'เงินได้ประจำอื่นที่คาดว่าจะได้รับ'
          }`}
          type="text"
          placeholder="0"
          format={displayNumber}
          parse={numberParser}
          didChange={onSummary}
        />
        <Unit>
          บาท/เดือน
          <br />
          <InfoUnit>ตั้งแต่เกษียณจนอายุคาดหมาย</InfoUnit>
        </Unit>
      </CustomFieldContainer>
    </div>
    <FooterFormContainer>
      <CustomTextFooter>
        <SearchFactor
          type="Retirement"
          windowWidth={windowWidth}
          searchValue={finalSummary}
        />
        <CustomField
          name="searchValue"
          component={SummaryAdvance}
          finalSummary={finalSummary}
        />
        <CustomField name="yearBeforeRetire" component={Nothing} />
        <CustomField name="costAfterRetire" component={Nothing} />
        <CustomField name="costAtDeadAge" component={Nothing} />
        <CustomField name="costShouldPrepared" component={Nothing} />
      </CustomTextFooter>
      <CustomButton
        htmlType="submit"
        icons={['fa', 'search']}
        disabled={finalSummary < 0}>
        เลือกแบบประกัน
      </CustomButton>
    </FooterFormContainer>
  </Panel>
)

const selector = formValueSelector('Planning')

const enhancer = compose(
  connect(
    (state, props) => ({
      totalList: selector(
        state,
        'inflationRate',
        'presentCost',
        'expectedRetireAge',
        'costRateAfterRetire',
        'expectedDeadAge',
        'incomeRate',
        'costRequired',
        'pension',
        'etcIncome',
      ),
      age: selector(state, 'age'),
    }),
    {change},
  ),
  withState('firstSummary', 'setFirstSummary', 0),
  withState('secondSummary', 'setSecondSummary', 0),
  withState('thirdSummary', 'setThirdSummary', 0),
  withState('finalSummary', 'setFinalSummary', 0),
  withState('percent', 'setPercent', 2),
  withHandlers({
    onSummary: ({
      totalList,
      setPercent,
      age,
      setFirstSummary,
      setSecondSummary,
      setThirdSummary,
      setFinalSummary,
      change,
    }) => () => {
      const {
        inflationRate = '0',
        presentCost = '0',
        expectedRetireAge = '0',
        costRateAfterRetire = '0',
        expectedDeadAge = '0',
        incomeRate = '0',
        pension = '0',
        etcIncome = '0',
      } = totalList

      let first = convertNaNtoZero(inflationRate)
      let second = convertNaNtoZero(presentCost)
      let third = convertNaNtoZero(expectedRetireAge)
      let forth = convertNaNtoZero(costRateAfterRetire)
      let five = convertNaNtoZero(expectedDeadAge)
      let six = convertNaNtoZero(incomeRate)
      let eight = convertNaNtoZero(pension)
      let nine = convertNaNtoZero(etcIncome)

      let summary3 =
        ((second * forth) / 100) * Math.pow(1 + first / 100, third - age)

      let summary4 = parseFloat(
        ((summary3 * six) / 100) * Math.pow(1 + first / 100, five - third),
      )
      setPercent(first)
      setFirstSummary(third - age)
      change('Planning', 'yearBeforeRetire', convertNaNtoZero(third - age))
      setSecondSummary(summary3)
      change('Planning', 'costAfterRetire', convertNaNtoZero(summary3))

      setThirdSummary(summary4)
      change('Planning', 'costAtDeadAge', convertNaNtoZero(summary4))
      change('Planning', 'costShouldPrepared', convertNaNtoZero(summary4))

      setFinalSummary((summary4 - eight - nine) * 12)
      change(
        'Planning',
        'costRequired',
        convertNaNtoZero((summary3 * six) / 100),
      )
      change(
        'Planning',
        'searchValue',
        convertNaNtoZero((summary4 - eight - nine) * 12),
      )
    },
    onPercentSummary: ({totalList, change, age, setThirdSummary}) => () => {
      const {
        inflationRate = '0',
        presentCost = '0',
        expectedRetireAge = '0',
        costRateAfterRetire = '0',
        expectedDeadAge = '0',
        costRequired = '0',
      } = totalList

      let first = convertNaNtoZero(inflationRate)
      let second = convertNaNtoZero(presentCost)
      let third = convertNaNtoZero(expectedRetireAge)
      let forth = convertNaNtoZero(costRateAfterRetire)
      let five = convertNaNtoZero(expectedDeadAge)
      let seven = convertNaNtoZero(costRequired)

      let summary3 = Math.round(
        ((second * forth) / 100) * Math.pow(1 + first / 100, third - age),
      )

      let summary4 = convertNaNtoZero(
        ((summary3 * (Math.round((seven * 100) / summary3) * 100)) /
          100 /
          100) *
          Math.pow(1 + first / 100, five - third),
      )
      change(
        'Planning',
        'incomeRate',
        Number.isNaN((Math.round((seven * 100) / summary3) * 100) / 100)
          ? 0
          : Math.round(((seven * 100) / summary3) * 100) / 100,
      )
      setThirdSummary(summary4)
      change('Planning', 'costAtDeadAge', convertNaNtoZero(summary4))
      change('Planning', 'costShouldPrepared', convertNaNtoZero(summary4))
    },
  }),

  lifecycle({
    componentDidMount() {
      this.props.onSummary()
      this.props.onPercentSummary()
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.age !== this.props.age) {
        nextProps.onSummary()
      }
    },
  }),
)

export default enhancer(RetireForm)
