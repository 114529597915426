import React from 'react'
import styled from 'react-emotion'
import classnames from 'classnames'

const TextInputLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ComponentContainer = styled.div`
  display: flex;
  width: 100%;
`
const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 10px;
  color: red;
`

const withError = validateErrorStyles => Component => ({...props}) => {
  const {error, touched} = props.meta || {}
  const className = classnames({
    [props.className]: !!props.className,
  })
  const validateClassName = classnames({
    [validateErrorStyles]: touched && error,
  })
  return (
    <TextInputLayout className={className}>
      <ComponentContainer>
        {Component && <Component {...props} className={validateClassName} />}
      </ComponentContainer>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </TextInputLayout>
  )
}

export default withError
