import React from 'react'
import {connect} from 'react-redux'
import styled, {css} from 'react-emotion'
import {compose, lifecycle} from 'recompose'

import Button from '../share/Button'
import LoadingAnimation from '../../static/images/loading.gif'

import {getQuestion, postQuestion} from '../../ducks/landing'
import {responsive} from '../../core/style'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${props => props.theme.BLUE};
  width: 600px;
  margin: 100px auto 70px;
  background-color: #ffffff;
  color: black;

  ${props =>
    responsive.mobile(css`
      width: 280px;
      margin: 0 auto 30px;
    `)};
`

const ContainerLoading = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
`

const LoadingGif = styled.img``

const TopicHow = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`

const InfoHow = styled.p`
  width: 100%;
  text-align: center;
  margin: 5px 0 15px 0;
  padding: 0 10px;
  line-height: 24px;
  font-size: 16px;
`

const ContainerButton = styled.div`
  display: flex;

  ${props =>
    responsive.mobile(css`
      flex-direction: column;
    `)};
`

const CustomButtonLong = styled(Button)`
  width: 140px;
  height: 30px;
  margin-right: 20px;

  ${props =>
    responsive.mobile(css`
      margin: 0;
    `)};
`

const CustomButtonShort = styled(CustomButtonLong)`
  width: 80px;
  ${props =>
    responsive.mobile(css`
      margin-top: 10px;
    `)};
`

const ContainerKnow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`

const DidYouKnow = ({
  postQuestion,
  questions = {
    question: 'ค่าเฉลี่ยเบี้ยประกันชีวิตคนไทยอยู่ที่ 8,501 บาท / คน / ปี เลยนะ',
  },
  getQuestion,
  isLoading,
}) => (
  <Container>
    {isLoading && (
      <ContainerLoading>
        <LoadingGif src={LoadingAnimation} />
      </ContainerLoading>
    )}
    <ContainerKnow>
      <TopicHow>รู้หรือไม่?</TopicHow>
      <InfoHow>{questions.question}</InfoHow>
      <ContainerButton>
        <CustomButtonLong
          id={`landing-do-not-know-yet-${questions.id}`}
          onClick={() => {
            dataLayer.push({
              event: 'answer_yes_for_did_you_know_question',
              didYouKnowQuestionId: questions.id,
            })
            postQuestion({questionId: questions.id, answer: false})
            getQuestion(questions.id)
          }}
          icons={['fas', 'dice']}>
          โห เพิ่งรู้เลยนะเนี่ย
        </CustomButtonLong>
        <CustomButtonShort
          id={`landing-already-know-${questions.id}`}
          type="border"
          onClick={() => {
            dataLayer.push({
              event: 'answer_no_for_did_you_know_question',
              didYouKnowQuestionId: questions.id,
            })
            postQuestion({questionId: questions.id, answer: true})
            getQuestion(questions.id)
          }}
          icons={['far', 'lightbulb']}>
          รู้อยู่แล้ว
        </CustomButtonShort>
      </ContainerButton>
    </ContainerKnow>
  </Container>
)

const enhancer = compose(
  connect(
    state => ({
      questions: state.landing.questions,
      isLoading: state.landing.isLoadingQuestion,
    }),
    {getQuestion, postQuestion},
  ),
  lifecycle({
    componentDidMount() {
      const {getQuestion} = this.props
      getQuestion()
    },
  }),
)

export default enhancer(DidYouKnow)
